const answers = [
  'אבטחה',
  'אבטלה',
  'אבנים',
  'אדירה',
  'אודות',
  'אזהרה',
  'אזרחי',
  'אחווה',
  'אחוזה',
  'אחסון',
  'איזון',
  'איפור',
  'אירעה',
  'איתות',
  'אלימה',
  'אפייה',
  'אצטלה',
  'ארובה',
  'ארוחה',
  'אשליה',
  'אשמים',
  'אשפוז',
  'אתמול',
  'בדיחה',
  'בהירה',
  'בודדה',
  'בוסתן',
  'בועות',
  'בחירה',
  'בידור',
  'בימוי',
  'ביקוש',
  'ביקרה',
  'בלבול',
  'בלילה',
  'בלימה',
  'בליעה',
  'במקום',
  'בננות',
  'בעיות',
  'ברווז',
  'ברזים',
  'בריאה',
  'בריחה',
  'בתולה',
  'גופים',
  'גזירה',
  'גידול',
  'גילוח',
  'גילתה',
  'גישור',
  'גרגיר',
  'גרורה',
  'גרושה',
  'דבורה',
  'דגלים',
  'דורבן',
  'דורות',
  'דחיפה',
  'דיקור',
  'דירוג',
  'דירות',
  'דתיים',
  'הארכה',
  'הבשלה',
  'הגדרה',
  'הגיבה',
  'הגרלה',
  'הדאיג',
  'הדליף',
  'הדפסה',
  'הדרים',
  'הובהר',
  'הוגבל',
  'הודיע',
  'הוכחה',
  'הונאה',
  'הוסיף',
  'הופרש',
  'הוצאה',
  'הוקצב',
  'הוראה',
  'הורחק',
  'הושמד',
  'הושעה',
  'החכרה',
  'החלטה',
  'החלמה',
  'הטבעה',
  'הילוך',
  'הכינה',
  'הכללה',
  'הכנסה',
  'הכשיל',
  'הכשיר',
  'הכתיב',
  'הכתרה',
  'המחשה',
  'הנעלה',
  'הנפקה',
  'הסריח',
  'הסתבך',
  'הסתכן',
  'הסתפר',
  'העמיק',
  'הערכה',
  'העתיק',
  'הפניה',
  'הפסקה',
  'הפעלה',
  'הפצצה',
  'הפקדה',
  'הפרדה',
  'הפתיע',
  'הצגות',
  'הצטער',
  'הצלחה',
  'הצללה',
  'הצרחה',
  'הרחבה',
  'הריון',
  'הריסה',
  'הרשאה',
  'השאלה',
  'השפיע',
  'השקעת',
  'השתמט',
  'השתקם',
  'התכבד',
  'התמדה',
  'התמסר',
  'התנדב',
  'התנפח',
  'התעלף',
  'התערב',
  'התפאר',
  'התפרק',
  'התקשר',
  'התרסק',
  'וודקה',
  'ויכוח',
  'זדוני',
  'זיהום',
  'זינוק',
  'זירוז',
  'חבילה',
  'חגורה',
  'חגיגה',
  'חדרים',
  'חוברת',
  'חוגים',
  'חוטים',
  'חולדה',
  'חולמת',
  'חולצה',
  'חופשה',
  'חופשי',
  'חורשה',
  'חזרות',
  'חטיפה',
  'חיבוק',
  'חיידק',
  'חיסור',
  'חישוב',
  'חיתול',
  'חלבון',
  'חליפה',
  'חלמון',
  'חלקלק',
  'חמישה',
  'חמשיר',
  'חצובה',
  'חקירה',
  'חריגה',
  'חשבון',
  'חשדות',
  'חשמלי',
  'חששות',
  'חתונה',
  'טבילה',
  'טבעית',
  'טיפלה',
  'טפסים',
  'יאפשר',
  'יבואו',
  'יהדות',
  'יוצרת',
  'ייעוד',
  'ינשוף',
  'יסמין',
  'יעבוד',
  'יצירה',
  'יצליח',
  'כבאית',
  'כבדים',
  'כינור',
  'כלכלי',
  'כלכלן',
  'כמיהה',
  'כפפות',
  'כרטיס',
  'כריכה',
  'כתמים',
  'לאחסן',
  'לאסוף',
  'לארגן',
  'לבבות',
  'לגבות',
  'לגזור',
  'לדקלם',
  'להזיע',
  'להזיק',
  'להפיל',
  'לוחמת',
  'לזהות',
  'לחייב',
  'לחפור',
  'לחשוד',
  'לייעל',
  'ליקוי',
  'לכופף',
  'לכעוס',
  'לכפתר',
  'לכרות',
  'ללחוץ',
  'למנות',
  'למתוח',
  'לסחוט',
  'לסייע',
  'לעזוב',
  'לעשות',
  'לפגוע',
  'לפוצץ',
  'לפעול',
  'לרדוף',
  'לרענן',
  'לרקוד',
  'לשדרג',
  'לשהות',
  'לשלוט',
  'לשלוף',
  'לשנות',
  'לתרגם',
  'מאובן',
  'מאוהב',
  'מאושר',
  'מאזין',
  'מבוטח',
  'מבוסס',
  'מביכה',
  'מבריק',
  'מגוון',
  'מגילה',
  'מגלשה',
  'מגרסה',
  'מגרפה',
  'מדובר',
  'מדויק',
  'מדחום',
  'מדפים',
  'מדפסת',
  'מדריך',
  'מובהק',
  'מודגש',
  'מודפס',
  'מוזמן',
  'מוחות',
  'מוכרח',
  'מומחה',
  'מועיל',
  'מועצה',
  'מופרד',
  'מופתע',
  'מוצדק',
  'מוצלח',
  'מוקדם',
  'מוקפד',
  'מורים',
  'מורכב',
  'מזוזה',
  'מזלזל',
  'מזרחי',
  'מחולק',
  'מחוקק',
  'מחזור',
  'מחזות',
  'מחזיר',
  'מחיקה',
  'מחלקה',
  'מחמאה',
  'מחסום',
  'מחצית',
  'מחצלת',
  'מחריד',
  'מחשבה',
  'מחשמל',
  'מטאור',
  'מטווח',
  'מטפלת',
  'מטריד',
  'מילון',
  'מילים',
  'מישהו',
  'מכבסה',
  'מכולת',
  'מכחול',
  'מכלאה',
  'מכפיל',
  'מכשיר',
  'מלאכה',
  'מלהיב',
  'ממוצע',
  'ממותג',
  'ממציא',
  'מנהרה',
  'מנחות',
  'מניפה',
  'מנעול',
  'מסועף',
  'מסטיק',
  'מסכות',
  'מסננת',
  'מעבדה',
  'מעודד',
  'מעושן',
  'מעטפה',
  'מעלות',
  'מעלית',
  'מעסיק',
  'מעצבת',
  'מערכת',
  'מפוצץ',
  'מפוקח',
  'מפליא',
  'מפסיק',
  'מפתיע',
  'מצגות',
  'מצוין',
  'מצולע',
  'מצופה',
  'מצורף',
  'מצטרף',
  'מצלמה',
  'מצעים',
  'מקביל',
  'מקולל',
  'מקומם',
  'מקורי',
  'מקושר',
  'מרגשת',
  'מרוחק',
  'מריצה',
  'מרענן',
  'מרפסת',
  'מרצים',
  'משאבה',
  'משוחד',
  'משולש',
  'משורר',
  'משושה',
  'משימה',
  'משלוח',
  'משמיע',
  'משתדל',
  'משתמש',
  'מתאים',
  'מתאמץ',
  'מתבגר',
  'מתגבר',
  'מתגלח',
  'מתכון',
  'מתכות',
  'מתמיד',
  'מתעלם',
  'מתעלף',
  'מתרחש',
  'נדידה',
  'נוהגת',
  'נוסעת',
  'נועדה',
  'נושאת',
  'ניהול',
  'נמוכה',
  'נעוצה',
  'נערות',
  'נפלאה',
  'נקודה',
  'נראתה',
  'נרקיס',
  'נשלחה',
  'סובלת',
  'סוגרת',
  'סוכות',
  'סוללה',
  'סחיטה',
  'סיבוב',
  'סיבות',
  'סידור',
  'סיכוי',
  'סיכום',
  'סיכון',
  'סיפור',
  'ספורט',
  'ספינה',
  'ספרות',
  'עברית',
  'עדכון',
  'עדשות',
  'עונות',
  'עופרת',
  'עוצמה',
  'עיגול',
  'עיסוק',
  'עכביש',
  'עמוסה',
  'עמלות',
  'עניבה',
  'עניבה',
  'עניים',
  'עסקים',
  'עצבים',
  'עצבני',
  'עקומה',
  'עקירה',
  'עשויה',
  'עששית',
  'פגמים',
  'פועלת',
  'פורחת',
  'פורקן',
  'פחדים',
  'פחדתי',
  'פיחות',
  'פלאים',
  'פליטה',
  'פלילי',
  'פלישה',
  'פסטות',
  'פסילה',
  'פסנתר',
  'פעולה',
  'פעמון',
  'פקדון',
  'פרוסה',
  'פרחים',
  'פרצוף',
  'פרשות',
  'צביטה',
  'צביעה',
  'צוללת',
  'ציבור',
  'ציוני',
  'ציפית',
  'צללית',
  'צנצנת',
  'צעצוע',
  'צפירה',
  'צפרדע',
  'קבועה',
  'קבעתי',
  'קדומה',
  'קהילה',
  'קופסה',
  'קוצים',
  'קטעים',
  'קינוח',
  'קישור',
  'קעקוע',
  'קערות',
  'קרנות',
  'קרקפת',
  'קשירה',
  'ראשון',
  'רגילה',
  'רופאה',
  'רחוקה',
  'רחמים',
  'ריבוע',
  'ריבית',
  'רמזור',
  'רמקול',
  'רציני',
  'רציתי',
  'רשימה',
  'רשמים',
  'שאלון',
  'שאלות',
  'שארית',
  'שוברת',
  'שולחן',
  'שיאים',
  'שיבוט',
  'שידור',
  'שידרה',
  'שיזוף',
  'שיחות',
  'שיכור',
  'שילוב',
  'שינוע',
  'שיעול',
  'שיעור',
  'שירות',
  'שיתוף',
  'שיתפה',
  'שניהם',
  'שקרים',
  'שרטוט',
  'שתיקה',
  'תבחרו',
  'תבשיל',
  'תגובה',
  'תדהמה',
  'תודעה',
  'תווים',
  'תוכנה',
  'תולעת',
  'תורים',
  'תזכור',
  'תזמון',
  'תחושה',
  'תחקור',
  'תחרות',
  'תחשיב',
  'תיצור',
  'תכנות',
  'תכשיט',
  'תכשיר',
  'תלמוד',
  'תמונה',
  'תמנון',
  'תמריץ',
  'תמשיך',
  'תסריט',
  'תעבור',
  'תעוזה',
  'תפילה',
  'תפירה',
  'תקליט',
  'תקנות',
  'תרדמת',
  'תרופה',
  'תרמיל',
  'תשאול',
]

export default answers
