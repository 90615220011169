const words = {
"אאבזר": true,
"אאבחן": true,
"אאבטח": true,
"אאביס": true,
"אאביק": true,
"אאגור": true,
"אאגרף": true,
"אאדים": true,
"אאדיר": true,
"אאהוד": true,
"אאהיב": true,
"אאהיל": true,
"אאובן": true,
"אאובק": true,
"אאוגד": true,
"אאוגף": true,
"אאודה": true,
"אאוזן": true,
"אאוחד": true,
"אאוחה": true,
"אאוחל": true,
"אאוחר": true,
"אאויד": true,
"אאוים": true,
"אאוין": true,
"אאויר": true,
"אאויש": true,
"אאוית": true,
"אאוכל": true,
"אאוכן": true,
"אאולף": true,
"אאולץ": true,
"אאומן": true,
"אאומץ": true,
"אאומת": true,
"אאונן": true,
"אאופס": true,
"אאופר": true,
"אאושר": true,
"אאושש": true,
"אאותר": true,
"אאותת": true,
"אאזוק": true,
"אאזור": true,
"אאזין": true,
"אאזכר": true,
"אאזרח": true,
"אאחזר": true,
"אאחיד": true,
"אאחסן": true,
"אאטום": true,
"אאייד": true,
"אאיים": true,
"אאיין": true,
"אאייר": true,
"אאייש": true,
"אאיית": true,
"אאכוף": true,
"אאכזב": true,
"אאכיל": true,
"אאכלס": true,
"אאלחש": true,
"אאלתר": true,
"אאמוד": true,
"אאמין": true,
"אאמיר": true,
"אאמלל": true,
"אאנוס": true,
"אאניש": true,
"אאנפף": true,
"אאסוף": true,
"אאסור": true,
"אאסלם": true,
"אאפוף": true,
"אאפיל": true,
"אאפיר": true,
"אאפנן": true,
"אאפשר": true,
"אאצור": true,
"אאציל": true,
"אארגן": true,
"אארוב": true,
"אארוג": true,
"אארוז": true,
"אאריך": true,
"אאריק": true,
"אארכב": true,
"אאשים": true,
"אאשפז": true,
"אאשרר": true,
"אאתגר": true,
"אאתחל": true,
"אבאיש": true,
"אבגוד": true,
"אבדון": true,
"אבדוק": true,
"אבדות": true,
"אבדיל": true,
"אבדים": true,
"אבדנה": true,
"אבדנו": true,
"אבדני": true,
"אבדנך": true,
"אבדנם": true,
"אבדנן": true,
"אבדתה": true,
"אבדתו": true,
"אבדתי": true,
"אבדתך": true,
"אבדתם": true,
"אבדתן": true,
"אבהות": true,
"אבהיל": true,
"אבהיק": true,
"אבהיר": true,
"אבהית": true,
"אבואב": true,
"אבואס": true,
"אבואר": true,
"אבובה": true,
"אבובו": true,
"אבובי": true,
"אבובך": true,
"אבובם": true,
"אבובן": true,
"אבודד": true,
"אבודה": true,
"אבודל": true,
"אבודר": true,
"אבוהב": true,
"אבוזה": true,
"אבוזר": true,
"אבוטא": true,
"אבוטח": true,
"אבוטל": true,
"אבויל": true,
"אבוים": true,
"אבויש": true,
"אבוית": true,
"אבוסה": true,
"אבוסו": true,
"אבוסי": true,
"אבוסך": true,
"אבוסם": true,
"אבוסן": true,
"אבוסס": true,
"אבוער": true,
"אבוצע": true,
"אבוצר": true,
"אבוקה": true,
"אבוקע": true,
"אבוקר": true,
"אבוקש": true,
"אבורא": true,
"אבורך": true,
"אבורר": true,
"אבושל": true,
"אבושם": true,
"אבושר": true,
"אבושש": true,
"אבותק": true,
"אבותר": true,
"אבזבז": true,
"אבזוז": true,
"אבזוק": true,
"אבזור": true,
"אבזיק": true,
"אבזמה": true,
"אבזמו": true,
"אבזמי": true,
"אבזמך": true,
"אבזמם": true,
"אבזמן": true,
"אבזרה": true,
"אבזרו": true,
"אבזרי": true,
"אבזרך": true,
"אבזרם": true,
"אבזרן": true,
"אבזרת": true,
"אבחון": true,
"אבחות": true,
"אבחזי": true,
"אבחיל": true,
"אבחין": true,
"אבחנה": true,
"אבחנו": true,
"אבחני": true,
"אבחנת": true,
"אבחתה": true,
"אבחתו": true,
"אבחתי": true,
"אבחתך": true,
"אבחתם": true,
"אבחתן": true,
"אבטוח": true,
"אבטוש": true,
"אבטחה": true,
"אבטחו": true,
"אבטחי": true,
"אבטחת": true,
"אבטיח": true,
"אבטלה": true,
"אביאל": true,
"אביבה": true,
"אביבו": true,
"אביבי": true,
"אביבך": true,
"אביבם": true,
"אביבן": true,
"אבידן": true,
"אביהו": true,
"אביהם": true,
"אביהן": true,
"אביון": true,
"אביזר": true,
"אביחי": true,
"אביטל": true,
"אביטן": true,
"אבייל": true,
"אביים": true,
"אבייץ": true,
"אבייש": true,
"אביית": true,
"אביכם": true,
"אביכן": true,
"אבינה": true,
"אבינו": true,
"אבינר": true,
"אביעד": true,
"אבירה": true,
"אבירו": true,
"אבירי": true,
"אבירך": true,
"אבירם": true,
"אבירן": true,
"אבישג": true,
"אבישי": true,
"אביתי": true,
"אביתם": true,
"אביתן": true,
"אביתר": true,
"אבכיר": true,
"אבלבל": true,
"אבלגן": true,
"אבלוט": true,
"אבלול": true,
"אבלום": true,
"אבלוס": true,
"אבלוש": true,
"אבלות": true,
"אבליג": true,
"אבליה": true,
"אבליו": true,
"אבליח": true,
"אבליט": true,
"אבליי": true,
"אבליך": true,
"אבלים": true,
"אבליע": true,
"אבלכם": true,
"אבלכן": true,
"אבלנה": true,
"אבלנו": true,
"אבלתי": true,
"אבלתם": true,
"אבלתן": true,
"אבנאם": true,
"אבנון": true,
"אבנטה": true,
"אבנטו": true,
"אבנטי": true,
"אבנטך": true,
"אבנטם": true,
"אבנטן": true,
"אבניה": true,
"אבניו": true,
"אבניי": true,
"אבניך": true,
"אבנים": true,
"אבנית": true,
"אבנכם": true,
"אבנכן": true,
"אבננו": true,
"אבנרי": true,
"אבסול": true,
"אבסור": true,
"אבעבע": true,
"אבעיר": true,
"אבעית": true,
"אבצבץ": true,
"אבצור": true,
"אבקבק": true,
"אבקות": true,
"אבקים": true,
"אבקיע": true,
"אבקכם": true,
"אבקכן": true,
"אבקנה": true,
"אבקנו": true,
"אבקני": true,
"אבקנך": true,
"אבקנם": true,
"אבקנן": true,
"אבקשך": true,
"אבקתה": true,
"אבקתו": true,
"אבקתי": true,
"אבקתך": true,
"אבקתם": true,
"אבקתן": true,
"אברבר": true,
"אברהם": true,
"אברון": true,
"אברות": true,
"אבריא": true,
"אבריג": true,
"אבריה": true,
"אבריו": true,
"אבריז": true,
"אבריח": true,
"אבריי": true,
"אבריך": true,
"אברים": true,
"אבריק": true,
"אבריש": true,
"אברכה": true,
"אברכו": true,
"אברכי": true,
"אברכך": true,
"אברכם": true,
"אברכן": true,
"אברמי": true,
"אברמק": true,
"אברנו": true,
"אברתה": true,
"אברתו": true,
"אברתי": true,
"אברתך": true,
"אברתם": true,
"אברתן": true,
"אבשיל": true,
"אגאית": true,
"אגבול": true,
"אגביה": true,
"אגביל": true,
"אגביר": true,
"אגבית": true,
"אגדום": true,
"אגדות": true,
"אגדיה": true,
"אגדיו": true,
"אגדיי": true,
"אגדיך": true,
"אגדיל": true,
"אגדים": true,
"אגדיר": true,
"אגדיש": true,
"אגדית": true,
"אגדכם": true,
"אגדכן": true,
"אגדנה": true,
"אגדנו": true,
"אגדתה": true,
"אגדתו": true,
"אגדתי": true,
"אגדתך": true,
"אגדתם": true,
"אגדתן": true,
"אגובב": true,
"אגובה": true,
"אגובן": true,
"אגובס": true,
"אגובש": true,
"אגודה": true,
"אגודל": true,
"אגודף": true,
"אגודר": true,
"אגודת": true,
"אגוהץ": true,
"אגוון": true,
"אגווע": true,
"אגוזה": true,
"אגוזו": true,
"אגוזי": true,
"אגוזך": true,
"אגוזם": true,
"אגוזן": true,
"אגוחך": true,
"אגויס": true,
"אגויר": true,
"אגולח": true,
"אגולל": true,
"אגולם": true,
"אגולף": true,
"אגומד": true,
"אגומר": true,
"אגונה": true,
"אגונן": true,
"אגופר": true,
"אגורה": true,
"אגורז": true,
"אגורף": true,
"אגורר": true,
"אגורש": true,
"אגושר": true,
"אגזוז": true,
"אגזול": true,
"אגזום": true,
"אגזור": true,
"אגזים": true,
"אגחיך": true,
"אגייס": true,
"אגייר": true,
"אגירה": true,
"אגלגל": true,
"אגלול": true,
"אגלוש": true,
"אגליד": true,
"אגליה": true,
"אגליו": true,
"אגליי": true,
"אגליך": true,
"אגלים": true,
"אגליש": true,
"אגלכם": true,
"אגלכן": true,
"אגלנו": true,
"אגלען": true,
"אגמגם": true,
"אגמול": true,
"אגמון": true,
"אגמור": true,
"אגמיה": true,
"אגמיו": true,
"אגמיי": true,
"אגמיך": true,
"אגמים": true,
"אגמיש": true,
"אגמכם": true,
"אגמכן": true,
"אגמנה": true,
"אגמנו": true,
"אגנדה": true,
"אגנוב": true,
"אגנוז": true,
"אגניב": true,
"אגניה": true,
"אגניו": true,
"אגניי": true,
"אגניך": true,
"אגנים": true,
"אגנכם": true,
"אגנכן": true,
"אגננו": true,
"אגסוס": true,
"אגסיה": true,
"אגסיו": true,
"אגסיי": true,
"אגסיך": true,
"אגסים": true,
"אגסכם": true,
"אגסכן": true,
"אגסנו": true,
"אגעגע": true,
"אגעיל": true,
"אגעיש": true,
"אגפיה": true,
"אגפיו": true,
"אגפיי": true,
"אגפיך": true,
"אגפים": true,
"אגפכם": true,
"אגפכן": true,
"אגפנה": true,
"אגפנו": true,
"אגרגר": true,
"אגרוב": true,
"אגרוד": true,
"אגרול": true,
"אגרום": true,
"אגרון": true,
"אגרוס": true,
"אגרוף": true,
"אגרור": true,
"אגרות": true,
"אגרטל": true,
"אגריל": true,
"אגרנו": true,
"אגרנט": true,
"אגרען": true,
"אגרפה": true,
"אגרפו": true,
"אגרפי": true,
"אגרפת": true,
"אגררי": true,
"אגרתה": true,
"אגרתו": true,
"אגרתי": true,
"אגרתך": true,
"אגרתם": true,
"אגרתן": true,
"אגשים": true,
"אדאיב": true,
"אדאיג": true,
"אדביק": true,
"אדביר": true,
"אדברר": true,
"אדגדג": true,
"אדגול": true,
"אדגום": true,
"אדגור": true,
"אדגים": true,
"אדגיר": true,
"אדגיש": true,
"אדגמן": true,
"אדהים": true,
"אדהיר": true,
"אדואר": true,
"אדובב": true,
"אדובג": true,
"אדובר": true,
"אדווה": true,
"אדווח": true,
"אדווש": true,
"אדוות": true,
"אדויק": true,
"אדוכא": true,
"אדולג": true,
"אדולל": true,
"אדולף": true,
"אדומה": true,
"אדומי": true,
"אדומם": true,
"אדונה": true,
"אדונו": true,
"אדוני": true,
"אדונך": true,
"אדונם": true,
"אדונן": true,
"אדופן": true,
"אדוקה": true,
"אדורג": true,
"אדורה": true,
"אדושן": true,
"אדחוף": true,
"אדחיק": true,
"אדיבה": true,
"אדיהם": true,
"אדיהן": true,
"אדייך": true,
"אדייק": true,
"אדיכם": true,
"אדיכן": true,
"אדינה": true,
"אדינו": true,
"אדירה": true,
"אדישה": true,
"אדכדך": true,
"אדלדל": true,
"אדלוף": true,
"אדלוק": true,
"אדליף": true,
"אדליק": true,
"אדמדם": true,
"אדמום": true,
"אדמור": true,
"אדמות": true,
"אדמים": true,
"אדמתה": true,
"אדמתו": true,
"אדמתי": true,
"אדמתך": true,
"אדמתם": true,
"אדמתן": true,
"אדנות": true,
"אדניה": true,
"אדניו": true,
"אדניי": true,
"אדניך": true,
"אדנים": true,
"אדנית": true,
"אדנכם": true,
"אדנכן": true,
"אדננו": true,
"אדסקס": true,
"אדפדף": true,
"אדפוק": true,
"אדפיס": true,
"אדקדק": true,
"אדקור": true,
"אדקלם": true,
"אדרבא": true,
"אדרבה": true,
"אדרבן": true,
"אדרדר": true,
"אדרוך": true,
"אדרוס": true,
"אדרוש": true,
"אדרות": true,
"אדריך": true,
"אדרים": true,
"אדרתה": true,
"אדרתו": true,
"אדרתי": true,
"אדרתך": true,
"אדרתם": true,
"אדרתן": true,
"אדשדש": true,
"אהבהב": true,
"אהבות": true,
"אהביה": true,
"אהביו": true,
"אהביי": true,
"אהביך": true,
"אהבים": true,
"אהבלה": true,
"אהבנה": true,
"אהבנו": true,
"אהבתה": true,
"אהבתו": true,
"אהבתי": true,
"אהבתך": true,
"אהבתם": true,
"אהבתן": true,
"אהדהד": true,
"אהדוף": true,
"אהדות": true,
"אהדיר": true,
"אהדנה": true,
"אהדנו": true,
"אהדתה": true,
"אהדתו": true,
"אהדתי": true,
"אהדתך": true,
"אהדתם": true,
"אהדתן": true,
"אהובה": true,
"אהובו": true,
"אהובי": true,
"אהובך": true,
"אהובם": true,
"אהובן": true,
"אהודה": true,
"אהודק": true,
"אהודר": true,
"אהווה": true,
"אהוון": true,
"אהולל": true,
"אהומם": true,
"אהוסה": true,
"אהילה": true,
"אהילו": true,
"אהילי": true,
"אהילך": true,
"אהילם": true,
"אהילן": true,
"אהלום": true,
"אהלים": true,
"אהמגן": true,
"אהמהם": true,
"אהנדס": true,
"אהנהן": true,
"אהפוך": true,
"אהפנט": true,
"אהראם": true,
"אהרהר": true,
"אהרוג": true,
"אהרון": true,
"אהרוס": true,
"אואבס": true,
"אואבק": true,
"אואדר": true,
"אואחד": true,
"אואיל": true,
"אואכל": true,
"אואמר": true,
"אואפל": true,
"אואצל": true,
"אוארך": true,
"אוארק": true,
"אואשם": true,
"אובאש": true,
"אובדל": true,
"אובדן": true,
"אובדת": true,
"אובהל": true,
"אובהר": true,
"אובות": true,
"אובזק": true,
"אובזר": true,
"אובחל": true,
"אובחן": true,
"אובטח": true,
"אוביל": true,
"אובים": true,
"אובכם": true,
"אובכן": true,
"אובלג": true,
"אובלט": true,
"אובלע": true,
"אובנה": true,
"אובנו": true,
"אובנת": true,
"אובער": true,
"אובקה": true,
"אובקו": true,
"אובקע": true,
"אובקת": true,
"אוברג": true,
"אוברז": true,
"אוברח": true,
"אוברק": true,
"אוברר": true,
"אוגבה": true,
"אוגבל": true,
"אוגבר": true,
"אוגדה": true,
"אוגדו": true,
"אוגדי": true,
"אוגדך": true,
"אוגדל": true,
"אוגדם": true,
"אוגדן": true,
"אוגדר": true,
"אוגדש": true,
"אוגדת": true,
"אוגזם": true,
"אוגים": true,
"אוגיע": true,
"אוגלה": true,
"אוגלש": true,
"אוגמש": true,
"אוגנב": true,
"אוגעל": true,
"אוגפה": true,
"אוגפו": true,
"אוגפת": true,
"אוגרה": true,
"אוגרו": true,
"אוגרי": true,
"אוגרך": true,
"אוגרל": true,
"אוגרם": true,
"אוגרן": true,
"אוגרף": true,
"אוגרת": true,
"אוגשם": true,
"אודאג": true,
"אודבק": true,
"אודבר": true,
"אודגם": true,
"אודגר": true,
"אודגש": true,
"אודהר": true,
"אודות": true,
"אודחק": true,
"אודיה": true,
"אודיו": true,
"אודיי": true,
"אודיך": true,
"אודים": true,
"אודיע": true,
"אודית": true,
"אודכם": true,
"אודכן": true,
"אודלף": true,
"אודלק": true,
"אודמה": true,
"אודמו": true,
"אודמי": true,
"אודמך": true,
"אודמם": true,
"אודמן": true,
"אודנו": true,
"אודסה": true,
"אודפס": true,
"אודרך": true,
"אודתה": true,
"אוהבה": true,
"אוהבו": true,
"אוהבי": true,
"אוהבך": true,
"אוהבם": true,
"אוהבן": true,
"אוהבת": true,
"אוהדה": true,
"אוהדו": true,
"אוהדי": true,
"אוהדך": true,
"אוהדם": true,
"אוהדן": true,
"אוהדר": true,
"אוהדת": true,
"אוהיו": true,
"אוהלה": true,
"אוהלו": true,
"אוהלי": true,
"אוהלך": true,
"אוהלם": true,
"אוהלן": true,
"אוודא": true,
"אוודה": true,
"אווזה": true,
"אווזו": true,
"אווזי": true,
"אווזך": true,
"אווזם": true,
"אווזן": true,
"אוויה": true,
"אוויל": true,
"אוויר": true,
"אווסט": true,
"אווסת": true,
"אוורר": true,
"אוושה": true,
"אוותה": true,
"אוותו": true,
"אוותי": true,
"אוותך": true,
"אוותם": true,
"אוותן": true,
"אוותר": true,
"אוזהב": true,
"אוזהר": true,
"אוזון": true,
"אוזיל": true,
"אוזים": true,
"אוזכר": true,
"אוזלף": true,
"אוזלת": true,
"אוזמן": true,
"אוזנה": true,
"אוזנו": true,
"אוזנח": true,
"אוזני": true,
"אוזנך": true,
"אוזנם": true,
"אוזנן": true,
"אוזנק": true,
"אוזנר": true,
"אוזנת": true,
"אוזעק": true,
"אוזקף": true,
"אוזקת": true,
"אוזרח": true,
"אוזרם": true,
"אוזרע": true,
"אוזרק": true,
"אוזרת": true,
"אוחבא": true,
"אוחדה": true,
"אוחדו": true,
"אוחדר": true,
"אוחדת": true,
"אוחזק": true,
"אוחזר": true,
"אוחזת": true,
"אוחטא": true,
"אוחיה": true,
"אוחיו": true,
"אוחיי": true,
"אוחיך": true,
"אוחיל": true,
"אוחים": true,
"אוחית": true,
"אוחכם": true,
"אוחכן": true,
"אוחכר": true,
"אוחלה": true,
"אוחלו": true,
"אוחלט": true,
"אוחלף": true,
"אוחלק": true,
"אוחלש": true,
"אוחלת": true,
"אוחמא": true,
"אוחמץ": true,
"אוחמר": true,
"אוחנה": true,
"אוחנו": true,
"אוחנק": true,
"אוחסן": true,
"אוחסר": true,
"אוחצן": true,
"אוחרב": true,
"אוחרג": true,
"אוחרד": true,
"אוחרה": true,
"אוחרו": true,
"אוחרם": true,
"אוחרף": true,
"אוחרש": true,
"אוחרת": true,
"אוחשד": true,
"אוחשך": true,
"אוחתה": true,
"אוחתם": true,
"אוטבה": true,
"אוטבל": true,
"אוטבע": true,
"אוטוא": true,
"אוטוב": true,
"אוטומ": true,
"אוטלא": true,
"אוטמה": true,
"אוטמו": true,
"אוטמי": true,
"אוטמך": true,
"אוטמם": true,
"אוטמן": true,
"אוטמע": true,
"אוטמת": true,
"אוטעה": true,
"אוטעם": true,
"אוטען": true,
"אוטרד": true,
"אוטרח": true,
"אויבה": true,
"אויבו": true,
"אויבי": true,
"אויבך": true,
"אויבם": true,
"אויבן": true,
"אויבת": true,
"אוידה": true,
"אוידו": true,
"אוידת": true,
"אויות": true,
"אוילר": true,
"אוימה": true,
"אוימו": true,
"אוימת": true,
"אוינה": true,
"אוינו": true,
"אוינת": true,
"אוירה": true,
"אוירו": true,
"אוירי": true,
"אוירת": true,
"אוישה": true,
"אוישו": true,
"אוישת": true,
"אויתה": true,
"אויתו": true,
"אויתי": true,
"אויתם": true,
"אויתן": true,
"אוכבד": true,
"אוכהה": true,
"אוכזב": true,
"אוכחד": true,
"אוכחש": true,
"אוכיח": true,
"אוכלב": true,
"אוכלה": true,
"אוכלו": true,
"אוכלי": true,
"אוכלל": true,
"אוכלם": true,
"אוכלס": true,
"אוכלת": true,
"אוכמן": true,
"אוכנה": true,
"אוכנו": true,
"אוכנס": true,
"אוכנע": true,
"אוכנת": true,
"אוכסף": true,
"אוכעס": true,
"אוכפה": true,
"אוכפו": true,
"אוכפי": true,
"אוכפך": true,
"אוכפל": true,
"אוכפם": true,
"אוכפן": true,
"אוכפף": true,
"אוכפש": true,
"אוכפת": true,
"אוכרז": true,
"אוכרח": true,
"אוכרע": true,
"אוכרת": true,
"אוכשל": true,
"אוכשר": true,
"אוכתב": true,
"אוכתם": true,
"אוכתר": true,
"אולאם": true,
"אולבן": true,
"אולבש": true,
"אולגה": true,
"אולהב": true,
"אולהט": true,
"אולחם": true,
"אולחן": true,
"אולחץ": true,
"אולחש": true,
"אוליד": true,
"אוליך": true,
"אולימ": true,
"אולין": true,
"אולמה": true,
"אולמו": true,
"אולמי": true,
"אולמך": true,
"אולמם": true,
"אולמן": true,
"אולעט": true,
"אולפה": true,
"אולפו": true,
"אולפן": true,
"אולפת": true,
"אולצה": true,
"אולצו": true,
"אולצת": true,
"אולקה": true,
"אולרה": true,
"אולרו": true,
"אולרי": true,
"אולרך": true,
"אולרם": true,
"אולרן": true,
"אולשן": true,
"אולתר": true,
"אומגא": true,
"אומגה": true,
"אומדן": true,
"אומדת": true,
"אומות": true,
"אומחז": true,
"אומחש": true,
"אומטר": true,
"אומיה": true,
"אומיו": true,
"אומיי": true,
"אומיך": true,
"אומים": true,
"אומכם": true,
"אומכן": true,
"אומלח": true,
"אומלט": true,
"אומלך": true,
"אומלל": true,
"אומלץ": true,
"אומנה": true,
"אומנו": true,
"אומני": true,
"אומנך": true,
"אומנם": true,
"אומנן": true,
"אומנת": true,
"אומעד": true,
"אומעט": true,
"אומצא": true,
"אומצה": true,
"אומצו": true,
"אומצי": true,
"אומצך": true,
"אומצם": true,
"אומצן": true,
"אומצת": true,
"אומרד": true,
"אומרה": true,
"אומרו": true,
"אומרי": true,
"אומרך": true,
"אומרם": true,
"אומרן": true,
"אומרץ": true,
"אומרת": true,
"אומשל": true,
"אומתה": true,
"אומתו": true,
"אומתי": true,
"אומתך": true,
"אומתם": true,
"אומתן": true,
"אומתק": true,
"אונבט": true,
"אונגר": true,
"אונהג": true,
"אונות": true,
"אונחה": true,
"אונחל": true,
"אונחת": true,
"אוניה": true,
"אוניו": true,
"אוניי": true,
"אוניך": true,
"אונים": true,
"אונכם": true,
"אונכן": true,
"אונלי": true,
"אונמך": true,
"אוננה": true,
"אוננו": true,
"אונני": true,
"אוננת": true,
"אונסה": true,
"אונסו": true,
"אונסי": true,
"אונסך": true,
"אונסם": true,
"אונסן": true,
"אונסק": true,
"אונסת": true,
"אונעם": true,
"אונפק": true,
"אונפש": true,
"אונצח": true,
"אונקל": true,
"אונרא": true,
"אונשם": true,
"אונתה": true,
"אונתו": true,
"אונתי": true,
"אונתך": true,
"אונתם": true,
"אונתן": true,
"אוסבר": true,
"אוסגר": true,
"אוסדר": true,
"אוסטר": true,
"אוסיף": true,
"אוסכם": true,
"אוסלו": true,
"אוסלם": true,
"אוסלק": true,
"אוסמה": true,
"אוסמך": true,
"אוסער": true,
"אוספג": true,
"אוספד": true,
"אוספה": true,
"אוספו": true,
"אוספי": true,
"אוספך": true,
"אוספם": true,
"אוספן": true,
"אוספת": true,
"אוסקה": true,
"אוסקי": true,
"אוסקר": true,
"אוסרט": true,
"אוסרת": true,
"אוסתר": true,
"אועבד": true,
"אועבר": true,
"אועדף": true,
"אועזב": true,
"אועיד": true,
"אועיל": true,
"אועלה": true,
"אועלם": true,
"אועמד": true,
"אועמס": true,
"אועמק": true,
"אוענק": true,
"אוענש": true,
"אועסק": true,
"אועצב": true,
"אועצם": true,
"אוערך": true,
"אוערם": true,
"אועשר": true,
"אועתק": true,
"אועתר": true,
"אופגז": true,
"אופגן": true,
"אופגש": true,
"אופהו": true,
"אופוס": true,
"אופות": true,
"אופחד": true,
"אופחת": true,
"אופטי": true,
"אופטר": true,
"אופיה": true,
"אופיו": true,
"אופיי": true,
"אופיך": true,
"אופים": true,
"אופיס": true,
"אופיע": true,
"אופיר": true,
"אופכם": true,
"אופכן": true,
"אופלג": true,
"אופלה": true,
"אופלט": true,
"אופלל": true,
"אופנה": true,
"אופנו": true,
"אופני": true,
"אופנך": true,
"אופנם": true,
"אופנן": true,
"אופנת": true,
"אופסה": true,
"אופסו": true,
"אופסק": true,
"אופסת": true,
"אופעל": true,
"אופעם": true,
"אופפת": true,
"אופצץ": true,
"אופקד": true,
"אופקה": true,
"אופקו": true,
"אופקי": true,
"אופקך": true,
"אופקם": true,
"אופקן": true,
"אופקע": true,
"אופקר": true,
"אופרד": true,
"אופרה": true,
"אופרו": true,
"אופרז": true,
"אופרח": true,
"אופרט": true,
"אופרך": true,
"אופרע": true,
"אופרש": true,
"אופרת": true,
"אופשט": true,
"אופשל": true,
"אופשר": true,
"אופתה": true,
"אופתו": true,
"אופתי": true,
"אופתך": true,
"אופתם": true,
"אופתן": true,
"אופתע": true,
"אוצבע": true,
"אוצדק": true,
"אוצהב": true,
"אוצהר": true,
"אוציא": true,
"אוצלב": true,
"אוצמד": true,
"אוצמת": true,
"אוצנה": true,
"אוצנח": true,
"אוצנע": true,
"אוצעד": true,
"אוצפן": true,
"אוצרה": true,
"אוצרו": true,
"אוצרי": true,
"אוצרך": true,
"אוצרם": true,
"אוצרן": true,
"אוצרת": true,
"אוקדח": true,
"אוקדם": true,
"אוקדש": true,
"אוקהה": true,
"אוקהל": true,
"אוקון": true,
"אוקטן": true,
"אוקטר": true,
"אוקיי": true,
"אוקיע": true,
"אוקיר": true,
"אוקלד": true,
"אוקלט": true,
"אוקנה": true,
"אוקנט": true,
"אוקסם": true,
"אוקפא": true,
"אוקפד": true,
"אוקפי": true,
"אוקפץ": true,
"אוקצב": true,
"אוקצה": true,
"אוקצן": true,
"אוקצע": true,
"אוקצף": true,
"אוקרא": true,
"אוקרב": true,
"אוקרם": true,
"אוקרן": true,
"אוקרש": true,
"אוקשה": true,
"אוקשח": true,
"אוראה": true,
"אוראל": true,
"אורבך": true,
"אורבע": true,
"אורבת": true,
"אורגד": true,
"אורגל": true,
"אורגן": true,
"אורגש": true,
"אורגת": true,
"אורדם": true,
"אורדן": true,
"אורון": true,
"אורות": true,
"אורזה": true,
"אורזו": true,
"אורזי": true,
"אורזך": true,
"אורזם": true,
"אורזן": true,
"אורזת": true,
"אורחב": true,
"אורחה": true,
"אורחו": true,
"אורחי": true,
"אורחך": true,
"אורחם": true,
"אורחן": true,
"אורחק": true,
"אורחת": true,
"אורטב": true,
"אורטל": true,
"אוריד": true,
"אוריה": true,
"אורים": true,
"אוריק": true,
"אוריש": true,
"אורית": true,
"אורכב": true,
"אורכה": true,
"אורכו": true,
"אורכי": true,
"אורכך": true,
"אורכם": true,
"אורכן": true,
"אורכת": true,
"אורלב": true,
"אורלי": true,
"אורמת": true,
"אורנה": true,
"אורנו": true,
"אורני": true,
"אורנך": true,
"אורנם": true,
"אורנן": true,
"אורעב": true,
"אורעד": true,
"אורעל": true,
"אורעף": true,
"אורעש": true,
"אורקד": true,
"אורקל": true,
"אורשה": true,
"אורשע": true,
"אורתח": true,
"אורתע": true,
"אושאל": true,
"אושאף": true,
"אושאר": true,
"אושבח": true,
"אושבע": true,
"אושבת": true,
"אושגח": true,
"אושהה": true,
"אושחז": true,
"אושחל": true,
"אושחם": true,
"אושחר": true,
"אושחת": true,
"אושיב": true,
"אושיה": true,
"אושיט": true,
"אושיע": true,
"אושכב": true,
"אושכח": true,
"אושכם": true,
"אושכן": true,
"אושכר": true,
"אושלה": true,
"אושלט": true,
"אושלך": true,
"אושלם": true,
"אושמד": true,
"אושמט": true,
"אושמע": true,
"אושמץ": true,
"אושמש": true,
"אושעה": true,
"אושען": true,
"אושפז": true,
"אושפל": true,
"אושפע": true,
"אושקה": true,
"אושקט": true,
"אושקע": true,
"אושרה": true,
"אושרו": true,
"אושרי": true,
"אושרך": true,
"אושרם": true,
"אושרן": true,
"אושרץ": true,
"אושרר": true,
"אושרש": true,
"אושרת": true,
"אוששה": true,
"אוששו": true,
"אוששי": true,
"אוששת": true,
"אושתל": true,
"אושתן": true,
"אושתק": true,
"אושתת": true,
"אותאם": true,
"אותגר": true,
"אותות": true,
"אותחל": true,
"אותיר": true,
"אותכם": true,
"אותכן": true,
"אותמר": true,
"אותנה": true,
"אותנו": true,
"אותנע": true,
"אותסס": true,
"אותפח": true,
"אותפל": true,
"אותקל": true,
"אותקן": true,
"אותקף": true,
"אותרה": true,
"אותרו": true,
"אותרת": true,
"אותתה": true,
"אותתו": true,
"אותתי": true,
"אותתם": true,
"אותתן": true,
"אזבסט": true,
"אזגזג": true,
"אזדהה": true,
"אזדהם": true,
"אזדחל": true,
"אזדכה": true,
"אזדכך": true,
"אזדמן": true,
"אזדמר": true,
"אזדנב": true,
"אזדעף": true,
"אזדעק": true,
"אזדקן": true,
"אזדקף": true,
"אזדקק": true,
"אזדקר": true,
"אזדרז": true,
"אזהיב": true,
"אזהיר": true,
"אזהרה": true,
"אזובה": true,
"אזובו": true,
"אזובי": true,
"אזובך": true,
"אזובל": true,
"אזובם": true,
"אזובן": true,
"אזוגג": true,
"אזוהה": true,
"אזוהם": true,
"אזווג": true,
"אזווד": true,
"אזוין": true,
"אזויף": true,
"אזוכה": true,
"אזוכך": true,
"אזומן": true,
"אזומר": true,
"אזופת": true,
"אזוקה": true,
"אזוקק": true,
"אזורה": true,
"אזורו": true,
"אזורז": true,
"אזורי": true,
"אזורך": true,
"אזורם": true,
"אזורן": true,
"אזיין": true,
"אזייף": true,
"אזיקה": true,
"אזיקו": true,
"אזיקי": true,
"אזיקך": true,
"אזיקם": true,
"אזיקן": true,
"אזירה": true,
"אזכור": true,
"אזכיר": true,
"אזכרה": true,
"אזכרו": true,
"אזכרי": true,
"אזכרת": true,
"אזלוג": true,
"אזלול": true,
"אזלזל": true,
"אזליו": true,
"אזליף": true,
"אזלנו": true,
"אזלתי": true,
"אזלתם": true,
"אזלתן": true,
"אזמום": true,
"אזמור": true,
"אזמזם": true,
"אזמין": true,
"אזמלה": true,
"אזמלו": true,
"אזמלי": true,
"אזמלך": true,
"אזמלם": true,
"אזמלן": true,
"אזניח": true,
"אזניק": true,
"אזנית": true,
"אזעזע": true,
"אזעים": true,
"אזעיף": true,
"אזעיק": true,
"אזעקה": true,
"אזקוף": true,
"אזקור": true,
"אזקין": true,
"אזקיף": true,
"אזקנו": true,
"אזקתי": true,
"אזקתם": true,
"אזקתן": true,
"אזרוח": true,
"אזרום": true,
"אזרוק": true,
"אזרחה": true,
"אזרחו": true,
"אזרחי": true,
"אזרחך": true,
"אזרחם": true,
"אזרחן": true,
"אזרחת": true,
"אזריה": true,
"אזרים": true,
"אזריע": true,
"אזריק": true,
"אזרית": true,
"אזרנו": true,
"אזרתי": true,
"אזרתם": true,
"אזרתן": true,
"אחבאר": true,
"אחבוט": true,
"אחבול": true,
"אחבוץ": true,
"אחבוק": true,
"אחבור": true,
"אחבוש": true,
"אחביא": true,
"אחגוג": true,
"אחגור": true,
"אחדור": true,
"אחדות": true,
"אחדים": true,
"אחדיר": true,
"אחדנה": true,
"אחובל": true,
"אחובק": true,
"אחובר": true,
"אחודד": true,
"אחודה": true,
"אחודש": true,
"אחווה": true,
"אחווט": true,
"אחווי": true,
"אחווך": true,
"אחוום": true,
"אחוון": true,
"אחוות": true,
"אחוזה": true,
"אחוזו": true,
"אחוזי": true,
"אחוזך": true,
"אחוזם": true,
"אחוזן": true,
"אחוזק": true,
"אחוזר": true,
"אחוזת": true,
"אחוטא": true,
"אחוטב": true,
"אחויב": true,
"אחויג": true,
"אחויט": true,
"אחויל": true,
"אחולט": true,
"אחולל": true,
"אחולץ": true,
"אחולק": true,
"אחומם": true,
"אחומש": true,
"אחונך": true,
"אחונן": true,
"אחוסל": true,
"אחוסם": true,
"אחוסן": true,
"אחוסר": true,
"אחופף": true,
"אחופש": true,
"אחוקק": true,
"אחורה": true,
"אחורי": true,
"אחורר": true,
"אחושב": true,
"אחושל": true,
"אחושק": true,
"אחותה": true,
"אחותו": true,
"אחותי": true,
"אחותך": true,
"אחותל": true,
"אחותם": true,
"אחותן": true,
"אחזור": true,
"אחזיק": true,
"אחזיר": true,
"אחזנה": true,
"אחזנו": true,
"אחזקה": true,
"אחזקת": true,
"אחזרה": true,
"אחזרו": true,
"אחזרי": true,
"אחזרת": true,
"אחזתי": true,
"אחזתם": true,
"אחזתן": true,
"אחטוב": true,
"אחטוף": true,
"אחטיא": true,
"אחטיף": true,
"אחידה": true,
"אחיהם": true,
"אחיהן": true,
"אחיות": true,
"אחיזה": true,
"אחיזת": true,
"אחייב": true,
"אחייג": true,
"אחייט": true,
"אחייך": true,
"אחייל": true,
"אחיין": true,
"אחיכם": true,
"אחיכן": true,
"אחינה": true,
"אחינו": true,
"אחיקם": true,
"אחכוך": true,
"אחכור": true,
"אחכים": true,
"אחכיר": true,
"אחלוב": true,
"אחלוט": true,
"אחלום": true,
"אחלוף": true,
"אחלוץ": true,
"אחלוק": true,
"אחלוש": true,
"אחלחל": true,
"אחליא": true,
"אחליד": true,
"אחליט": true,
"אחלים": true,
"אחליף": true,
"אחליק": true,
"אחליש": true,
"אחלמה": true,
"אחלנה": true,
"אחמדי": true,
"אחמוד": true,
"אחמול": true,
"אחמוס": true,
"אחמוק": true,
"אחמיא": true,
"אחמים": true,
"אחמיץ": true,
"אחמיר": true,
"אחמצן": true,
"אחנוט": true,
"אחנוך": true,
"אחנון": true,
"אחנוק": true,
"אחניף": true,
"אחניק": true,
"אחסוך": true,
"אחסום": true,
"אחסון": true,
"אחסיר": true,
"אחסנה": true,
"אחסנו": true,
"אחסני": true,
"אחסנת": true,
"אחספס": true,
"אחפון": true,
"אחפוף": true,
"אחפור": true,
"אחצוב": true,
"אחצוץ": true,
"אחצין": true,
"אחציף": true,
"אחצצר": true,
"אחקוק": true,
"אחקור": true,
"אחראי": true,
"אחרבן": true,
"אחרוב": true,
"אחרוג": true,
"אחרוז": true,
"אחרוט": true,
"אחרוך": true,
"אחרון": true,
"אחרוף": true,
"אחרוץ": true,
"אחרוק": true,
"אחרוש": true,
"אחרות": true,
"אחרחר": true,
"אחריב": true,
"אחריג": true,
"אחריד": true,
"אחריה": true,
"אחריו": true,
"אחריי": true,
"אחריך": true,
"אחרים": true,
"אחריף": true,
"אחריש": true,
"אחרית": true,
"אחרמן": true,
"אחרנה": true,
"אחשוב": true,
"אחשוד": true,
"אחשוף": true,
"אחשוק": true,
"אחשור": true,
"אחשוש": true,
"אחשיב": true,
"אחשיד": true,
"אחשיך": true,
"אחשמל": true,
"אחתוך": true,
"אחתום": true,
"אחתור": true,
"אחתים": true,
"אטאטא": true,
"אטבול": true,
"אטביה": true,
"אטביו": true,
"אטביי": true,
"אטביך": true,
"אטביל": true,
"אטבים": true,
"אטביע": true,
"אטבכם": true,
"אטבכן": true,
"אטבנו": true,
"אטדיה": true,
"אטדיו": true,
"אטדיי": true,
"אטדיך": true,
"אטדים": true,
"אטדכם": true,
"אטדכן": true,
"אטדנו": true,
"אטואן": true,
"אטובע": true,
"אטוגן": true,
"אטוהר": true,
"אטווה": true,
"אטווח": true,
"אטויב": true,
"אטויח": true,
"אטויל": true,
"אטומא": true,
"אטומה": true,
"אטומו": true,
"אטומי": true,
"אטומך": true,
"אטומם": true,
"אטומן": true,
"אטונף": true,
"אטופח": true,
"אטופל": true,
"אטופס": true,
"אטופף": true,
"אטיאס": true,
"אטיהם": true,
"אטיהן": true,
"אטיות": true,
"אטייב": true,
"אטייח": true,
"אטייך": true,
"אטייל": true,
"אטיים": true,
"אטיכם": true,
"אטיכן": true,
"אטילה": true,
"אטימה": true,
"אטינו": true,
"אטלטל": true,
"אטליא": true,
"אטליז": true,
"אטלסה": true,
"אטלסו": true,
"אטלסי": true,
"אטלסך": true,
"אטלסם": true,
"אטלסן": true,
"אטלפן": true,
"אטמון": true,
"אטמטם": true,
"אטמיה": true,
"אטמיו": true,
"אטמיי": true,
"אטמיך": true,
"אטמים": true,
"אטמין": true,
"אטמיע": true,
"אטמכם": true,
"אטמכן": true,
"אטמנו": true,
"אטמתי": true,
"אטמתם": true,
"אטמתן": true,
"אטעים": true,
"אטעין": true,
"אטפול": true,
"אטפטף": true,
"אטרוד": true,
"אטרון": true,
"אטרוף": true,
"אטרוק": true,
"אטרטר": true,
"אטריד": true,
"אטריה": true,
"אטריח": true,
"אטריל": true,
"אטריף": true,
"אטרפד": true,
"אטרקט": true,
"אטשטש": true,
"איאבק": true,
"איאגר": true,
"איאהב": true,
"איאהד": true,
"איאור": true,
"איאות": true,
"איאזק": true,
"איאזר": true,
"איאחז": true,
"איאטם": true,
"איאכל": true,
"איאכף": true,
"איאלם": true,
"איאלץ": true,
"איאמד": true,
"איאמן": true,
"איאמר": true,
"איאנח": true,
"איאנס": true,
"איאנק": true,
"איאסף": true,
"איאסר": true,
"איאפה": true,
"איאצר": true,
"איארג": true,
"איארז": true,
"איאשם": true,
"איבגי": true,
"איבדה": true,
"איבדו": true,
"איבדת": true,
"איבוד": true,
"איבון": true,
"איבוק": true,
"איבות": true,
"איבים": true,
"איבנה": true,
"איבנו": true,
"איבנת": true,
"איבקה": true,
"איבקו": true,
"איבקת": true,
"איברה": true,
"איברו": true,
"איברי": true,
"איברך": true,
"איברם": true,
"איברן": true,
"איבתה": true,
"איבתו": true,
"איבתי": true,
"איבתך": true,
"איבתם": true,
"איבתן": true,
"איגדה": true,
"איגדו": true,
"איגדת": true,
"איגוד": true,
"איגום": true,
"איגוף": true,
"איגור": true,
"איגלו": true,
"איגמה": true,
"איגמו": true,
"איגמת": true,
"איגפה": true,
"איגפו": true,
"איגפת": true,
"איגרא": true,
"איגרת": true,
"אידאה": true,
"אידאי": true,
"אידאל": true,
"אידוי": true,
"אידון": true,
"אידיא": true,
"אידים": true,
"אידיש": true,
"אידית": true,
"אידכם": true,
"אידכן": true,
"אידלל": true,
"אידמה": true,
"אידנא": true,
"אידנו": true,
"אידפק": true,
"אידתה": true,
"איהגה": true,
"איהדף": true,
"איהנה": true,
"איהפך": true,
"איהרג": true,
"איהרס": true,
"איובא": true,
"איובי": true,
"איובש": true,
"איודה": true,
"איודו": true,
"איודי": true,
"איודך": true,
"איודם": true,
"איודן": true,
"איודע": true,
"איוהד": true,
"איווה": true,
"איוזע": true,
"איוחד": true,
"איוחל": true,
"איוחם": true,
"איוחס": true,
"איולד": true,
"איומה": true,
"איומו": true,
"איומי": true,
"איומך": true,
"איומם": true,
"איומן": true,
"איונה": true,
"איונו": true,
"איוני": true,
"איונך": true,
"איונם": true,
"איונן": true,
"איוסד": true,
"איוסף": true,
"איוסר": true,
"איועד": true,
"איועל": true,
"איוער": true,
"איופה": true,
"איוצא": true,
"איוצב": true,
"איוצג": true,
"איוצר": true,
"איורה": true,
"איורו": true,
"איורט": true,
"איורי": true,
"איורך": true,
"איורם": true,
"איורן": true,
"איורר": true,
"איושב": true,
"איושה": true,
"איושו": true,
"איושי": true,
"איושך": true,
"איושם": true,
"איושן": true,
"איושר": true,
"איותה": true,
"איותו": true,
"איותי": true,
"איותך": true,
"איותם": true,
"איותן": true,
"איותר": true,
"איזבל": true,
"איזהו": true,
"איזום": true,
"איזון": true,
"איזור": true,
"איזנה": true,
"איזנו": true,
"איזנת": true,
"איזרח": true,
"איחבא": true,
"איחבט": true,
"איחבל": true,
"איחבש": true,
"איחגג": true,
"איחגר": true,
"איחדה": true,
"איחדו": true,
"איחדת": true,
"איחוג": true,
"איחוד": true,
"איחוי": true,
"איחול": true,
"איחור": true,
"איחזה": true,
"איחזו": true,
"איחזת": true,
"איחטף": true,
"איחית": true,
"איחכר": true,
"איחלב": true,
"איחלה": true,
"איחלו": true,
"איחלם": true,
"איחלץ": true,
"איחלק": true,
"איחלש": true,
"איחלת": true,
"איחמד": true,
"איחמץ": true,
"איחנט": true,
"איחנך": true,
"איחנק": true,
"איחסך": true,
"איחסם": true,
"איחפז": true,
"איחפף": true,
"איחפר": true,
"איחצב": true,
"איחצה": true,
"איחצץ": true,
"איחקק": true,
"איחקר": true,
"איחרב": true,
"איחרד": true,
"איחרה": true,
"איחרו": true,
"איחרט": true,
"איחרך": true,
"איחרץ": true,
"איחרש": true,
"איחרת": true,
"איחשב": true,
"איחשד": true,
"איחשף": true,
"איחשק": true,
"איחתה": true,
"איחתך": true,
"איחתם": true,
"איטהר": true,
"איטום": true,
"איטיב": true,
"איטית": true,
"איטלק": true,
"איטרת": true,
"אייאש": true,
"אייבא": true,
"אייבב": true,
"אייבם": true,
"אייבש": true,
"אייגע": true,
"איידה": true,
"איידו": true,
"איידי": true,
"איידס": true,
"איידע": true,
"איידת": true,
"אייהד": true,
"אייהם": true,
"אייהן": true,
"אייזע": true,
"אייזק": true,
"אייחד": true,
"אייחל": true,
"אייחם": true,
"אייחס": true,
"אייטם": true,
"אייכה": true,
"אייכם": true,
"אייכן": true,
"איילד": true,
"איילה": true,
"איילו": true,
"איילי": true,
"איילך": true,
"איילל": true,
"איילם": true,
"איילן": true,
"איילת": true,
"איימה": true,
"איימו": true,
"איימי": true,
"איימן": true,
"איימת": true,
"איינה": true,
"איינו": true,
"אייני": true,
"איינן": true,
"איינת": true,
"אייסד": true,
"אייסף": true,
"אייסר": true,
"אייעד": true,
"אייעל": true,
"אייעץ": true,
"אייער": true,
"אייפד": true,
"אייפה": true,
"אייפל": true,
"אייצא": true,
"אייצב": true,
"אייצג": true,
"אייצר": true,
"אייקר": true,
"איירה": true,
"איירו": true,
"איירט": true,
"איירי": true,
"איירס": true,
"איירת": true,
"איישב": true,
"איישה": true,
"איישו": true,
"איישי": true,
"איישם": true,
"איישן": true,
"איישר": true,
"איישת": true,
"אייתה": true,
"אייתו": true,
"אייתי": true,
"אייתם": true,
"אייתן": true,
"אייתר": true,
"איכול": true,
"איכון": true,
"איכות": true,
"איככה": true,
"איכלה": true,
"איכלו": true,
"איכלת": true,
"איכנה": true,
"איכנו": true,
"איכנת": true,
"איכסן": true,
"איכפת": true,
"איכרה": true,
"איכרו": true,
"איכרי": true,
"איכרך": true,
"איכרם": true,
"איכרן": true,
"אילוז": true,
"אילון": true,
"אילוף": true,
"אילוץ": true,
"אילות": true,
"איליה": true,
"איליו": true,
"איליי": true,
"איליך": true,
"אילים": true,
"אילכם": true,
"אילכן": true,
"אילמן": true,
"אילמת": true,
"אילנה": true,
"אילנו": true,
"אילני": true,
"אילנך": true,
"אילנם": true,
"אילנן": true,
"אילפה": true,
"אילפו": true,
"אילפת": true,
"אילצה": true,
"אילצו": true,
"אילצת": true,
"אילתי": true,
"אימאם": true,
"אימבר": true,
"אימהי": true,
"אימוח": true,
"אימול": true,
"אימון": true,
"אימוץ": true,
"אימות": true,
"אימיה": true,
"אימיו": true,
"אימיי": true,
"אימיך": true,
"אימים": true,
"אימנה": true,
"אימנו": true,
"אימנת": true,
"אימצה": true,
"אימצו": true,
"אימצת": true,
"אימרה": true,
"אימתה": true,
"אימתו": true,
"אימתי": true,
"אימתך": true,
"אימתם": true,
"אימתן": true,
"אינדי": true,
"אינון": true,
"אינוס": true,
"אינטג": true,
"אינטל": true,
"אינטר": true,
"איניק": true,
"אינכם": true,
"אינכן": true,
"איננה": true,
"איננו": true,
"אינני": true,
"אינסט": true,
"אינפו": true,
"אינקה": true,
"איסוד": true,
"איסוף": true,
"איסור": true,
"איסמן": true,
"איסתא": true,
"איעבר": true,
"איעגן": true,
"איעדר": true,
"איעור": true,
"איעזב": true,
"איעזר": true,
"איעטף": true,
"איעכר": true,
"איעלב": true,
"איעלם": true,
"איעמד": true,
"איענב": true,
"איענד": true,
"איענה": true,
"איענש": true,
"איעצב": true,
"איעצם": true,
"איעצר": true,
"איעקד": true,
"איעקף": true,
"איעקץ": true,
"איעקר": true,
"איערך": true,
"איערם": true,
"איערף": true,
"איערץ": true,
"איעשה": true,
"איעשק": true,
"איעתק": true,
"איעתר": true,
"איפאק": true,
"איפוא": true,
"איפול": true,
"איפוס": true,
"איפוק": true,
"איפור": true,
"איפות": true,
"איפכא": true,
"איפסה": true,
"איפסו": true,
"איפסת": true,
"איפרה": true,
"איפרו": true,
"איפרת": true,
"איפשר": true,
"איפתה": true,
"איפתו": true,
"איפתי": true,
"איפתך": true,
"איפתם": true,
"איפתן": true,
"איצוד": true,
"איציק": true,
"איקאה": true,
"איקוד": true,
"איקון": true,
"איראה": true,
"איראן": true,
"אירגם": true,
"אירגע": true,
"אירדם": true,
"אירדף": true,
"אירוח": true,
"אירוס": true,
"אירוע": true,
"אירחה": true,
"אירחו": true,
"אירחץ": true,
"אירחת": true,
"אירטב": true,
"אירטט": true,
"אירים": true,
"איריס": true,
"אירית": true,
"אירכס": true,
"אירכש": true,
"אירמז": true,
"אירמס": true,
"אירנא": true,
"אירנה": true,
"אירני": true,
"אירסה": true,
"אירסו": true,
"אירסת": true,
"אירעה": true,
"אירעו": true,
"אירעש": true,
"אירעת": true,
"אירפא": true,
"אירצה": true,
"אירצח": true,
"אירצץ": true,
"אירקב": true,
"אירקח": true,
"אירקם": true,
"אירשם": true,
"אירתם": true,
"אירתע": true,
"אישום": true,
"אישון": true,
"אישור": true,
"אישוש": true,
"אישות": true,
"אישיה": true,
"אישיו": true,
"אישיי": true,
"אישיך": true,
"אישים": true,
"אישיר": true,
"אישית": true,
"אישכם": true,
"אישכן": true,
"אישנו": true,
"אישפז": true,
"אישרה": true,
"אישרו": true,
"אישרת": true,
"איששה": true,
"איששו": true,
"איששת": true,
"אישתו": true,
"איתור": true,
"איתות": true,
"איתכם": true,
"איתמם": true,
"איתמר": true,
"איתנה": true,
"איתנו": true,
"איתני": true,
"איתנך": true,
"איתנם": true,
"איתנן": true,
"איתרה": true,
"איתרו": true,
"איתרע": true,
"איתרת": true,
"אכאיב": true,
"אכבול": true,
"אכבוש": true,
"אכביד": true,
"אכביר": true,
"אכדים": true,
"אכדית": true,
"אכדרר": true,
"אכובד": true,
"אכובה": true,
"אכובס": true,
"אכווה": true,
"אכוון": true,
"אכווץ": true,
"אכויל": true,
"אכויס": true,
"אכויר": true,
"אכולה": true,
"אכונה": true,
"אכונן": true,
"אכונס": true,
"אכוסה": true,
"אכוסח": true,
"אכוער": true,
"אכופה": true,
"אכופף": true,
"אכופר": true,
"אכושף": true,
"אכותב": true,
"אכותר": true,
"אכותת": true,
"אכזבה": true,
"אכזבו": true,
"אכזבי": true,
"אכזבת": true,
"אכזיב": true,
"אכזרי": true,
"אכחיד": true,
"אכחיל": true,
"אכחיש": true,
"אכחכח": true,
"אכייל": true,
"אכייס": true,
"אכייף": true,
"אכייר": true,
"אכילה": true,
"אכילס": true,
"אכילת": true,
"אכיפה": true,
"אכיפת": true,
"אכלול": true,
"אכלוס": true,
"אכליא": true,
"אכליב": true,
"אכליל": true,
"אכלים": true,
"אכלכל": true,
"אכלנה": true,
"אכלנו": true,
"אכלסה": true,
"אכלסו": true,
"אכלסי": true,
"אכלסת": true,
"אכלתי": true,
"אכלתם": true,
"אכלתן": true,
"אכמוש": true,
"אכמין": true,
"אכנוס": true,
"אכניס": true,
"אכניע": true,
"אכסאל": true,
"אכסון": true,
"אכסוס": true,
"אכסיף": true,
"אכעיס": true,
"אכפול": true,
"אכפוף": true,
"אכפור": true,
"אכפות": true,
"אכפיל": true,
"אכפיף": true,
"אכפיש": true,
"אכפנו": true,
"אכפתי": true,
"אכפתם": true,
"אכפתן": true,
"אכפתר": true,
"אכרבל": true,
"אכרוך": true,
"אכרות": true,
"אכרטס": true,
"אכריז": true,
"אכריח": true,
"אכריע": true,
"אכרית": true,
"אכרכר": true,
"אכרסם": true,
"אכשול": true,
"אכשיל": true,
"אכשיר": true,
"אכשכש": true,
"אכתוב": true,
"אכתוש": true,
"אכתיב": true,
"אכתים": true,
"אכתיף": true,
"אכתיר": true,
"אלאור": true,
"אלאים": true,
"אלבום": true,
"אלביט": true,
"אלבין": true,
"אלביש": true,
"אלבלב": true,
"אלבלח": true,
"אלבמה": true,
"אלבני": true,
"אלברט": true,
"אלבשן": true,
"אלגום": true,
"אלגלג": true,
"אלגלי": true,
"אלגנס": true,
"אלגרו": true,
"אלדין": true,
"אלהטט": true,
"אלהיב": true,
"אלהיט": true,
"אלהיך": true,
"אלהים": true,
"אלהרר": true,
"אלובה": true,
"אלובן": true,
"אלוהה": true,
"אלוהו": true,
"אלוהי": true,
"אלוהך": true,
"אלוהם": true,
"אלוהן": true,
"אלוהק": true,
"אלווה": true,
"אלוחח": true,
"אלוחך": true,
"אלוטן": true,
"אלוטף": true,
"אלוטש": true,
"אלוכד": true,
"אלומה": true,
"אלונה": true,
"אלונו": true,
"אלוני": true,
"אלונך": true,
"אלונם": true,
"אלונן": true,
"אלופה": true,
"אלופו": true,
"אלופי": true,
"אלופך": true,
"אלופם": true,
"אלופן": true,
"אלופף": true,
"אלופת": true,
"אלוקט": true,
"אלוקי": true,
"אלוקק": true,
"אלחוט": true,
"אלחוש": true,
"אלחים": true,
"אלחין": true,
"אלחיץ": true,
"אלחלח": true,
"אלחנן": true,
"אלחסן": true,
"אלחשה": true,
"אלחשו": true,
"אלחשי": true,
"אלחשש": true,
"אלחשת": true,
"אלטוש": true,
"אלטמן": true,
"אליאב": true,
"אליאס": true,
"אליבא": true,
"אליבי": true,
"אליבק": true,
"אליהו": true,
"אליהם": true,
"אליהן": true,
"אליוט": true,
"אליות": true,
"אליזם": true,
"אליטה": true,
"אלייך": true,
"אליים": true,
"אליכם": true,
"אליכן": true,
"אלילה": true,
"אלילו": true,
"אלילי": true,
"אלילך": true,
"אלילם": true,
"אלילן": true,
"אלימה": true,
"אלינו": true,
"אליפז": true,
"אלירן": true,
"אלישע": true,
"אלכוד": true,
"אלכלך": true,
"אלכסן": true,
"אלליי": true,
"אלמוג": true,
"אלמוז": true,
"אלמלא": true,
"אלמלי": true,
"אלמלם": true,
"אלמנה": true,
"אלמנו": true,
"אלמנט": true,
"אלמני": true,
"אלמנך": true,
"אלמנם": true,
"אלמנן": true,
"אלנבי": true,
"אלניה": true,
"אלנצר": true,
"אלנקס": true,
"אלסטי": true,
"אלסקה": true,
"אלעזר": true,
"אלעיג": true,
"אלעיז": true,
"אלעיט": true,
"אלפות": true,
"אלפיה": true,
"אלפיו": true,
"אלפיי": true,
"אלפיך": true,
"אלפים": true,
"אלפית": true,
"אלפכם": true,
"אלפכן": true,
"אלפלג": true,
"אלפנה": true,
"אלפנו": true,
"אלפסי": true,
"אלפקה": true,
"אלפרד": true,
"אלפרט": true,
"אלצנה": true,
"אלקבץ": true,
"אלקטר": true,
"אלקיך": true,
"אלקים": true,
"אלקין": true,
"אלקנה": true,
"אלקצה": true,
"אלרגי": true,
"אלרגן": true,
"אלרון": true,
"אלשיח": true,
"אלשיך": true,
"אלשין": true,
"אלתור": true,
"אלתית": true,
"אלתכם": true,
"אלתכן": true,
"אלתנו": true,
"אלתרה": true,
"אלתרו": true,
"אלתרי": true,
"אלתרת": true,
"אמאיס": true,
"אמבוש": true,
"אמבות": true,
"אמבטה": true,
"אמבטו": true,
"אמבטי": true,
"אמבטך": true,
"אמבטם": true,
"אמבטן": true,
"אמבתה": true,
"אמבתו": true,
"אמבתי": true,
"אמבתך": true,
"אמבתם": true,
"אמבתן": true,
"אמגנט": true,
"אמדבר": true,
"אמדוד": true,
"אמדנו": true,
"אמדתי": true,
"אמדתם": true,
"אמדתן": true,
"אמהות": true,
"אמהרי": true,
"אמוגן": true,
"אמוגר": true,
"אמודר": true,
"אמוזג": true,
"אמוטט": true,
"אמוין": true,
"אמוכן": true,
"אמולא": true,
"אמולל": true,
"אמומן": true,
"אמומש": true,
"אמונה": true,
"אמונו": true,
"אמוני": true,
"אמונך": true,
"אמונם": true,
"אמונן": true,
"אמונף": true,
"אמונת": true,
"אמוסד": true,
"אמוסה": true,
"אמוסך": true,
"אמוסס": true,
"אמוען": true,
"אמופה": true,
"אמוצה": true,
"אמוצע": true,
"אמוקד": true,
"אמוקם": true,
"אמוקש": true,
"אמורא": true,
"אמורה": true,
"אמורי": true,
"אמורק": true,
"אמושש": true,
"אמותג": true,
"אמותן": true,
"אמזוג": true,
"אמזון": true,
"אמזלג": true,
"אמזמז": true,
"אמזער": true,
"אמחזר": true,
"אמחיז": true,
"אמחיש": true,
"אמחשב": true,
"אמטיר": true,
"אמידה": true,
"אמייל": true,
"אמיין": true,
"אמילי": true,
"אמינה": true,
"אמינו": true,
"אמיני": true,
"אמיצה": true,
"אמירה": true,
"אמירו": true,
"אמירי": true,
"אמירך": true,
"אמירם": true,
"אמירן": true,
"אמירת": true,
"אמיתה": true,
"אמיתי": true,
"אמכור": true,
"אמלוך": true,
"אמלול": true,
"אמלוק": true,
"אמליח": true,
"אמליט": true,
"אמליך": true,
"אמליץ": true,
"אמלכד": true,
"אמללה": true,
"אמללו": true,
"אמללי": true,
"אמללת": true,
"אמלמל": true,
"אמלצר": true,
"אמנון": true,
"אמנות": true,
"אמניה": true,
"אמניו": true,
"אמניי": true,
"אמניך": true,
"אמנים": true,
"אמנית": true,
"אמנכם": true,
"אמנכן": true,
"אמננו": true,
"אמנתה": true,
"אמנתו": true,
"אמנתי": true,
"אמנתך": true,
"אמנתם": true,
"אמנתן": true,
"אמסגר": true,
"אמסוך": true,
"אמסוק": true,
"אמסור": true,
"אמסחר": true,
"אמסטל": true,
"אמסטר": true,
"אמסלם": true,
"אמסמס": true,
"אמסמר": true,
"אמספר": true,
"אמעיד": true,
"אמעיט": true,
"אמערי": true,
"אמפיר": true,
"אמפתי": true,
"אמצוץ": true,
"אמציא": true,
"אמציה": true,
"אמצמץ": true,
"אמצנה": true,
"אמצנו": true,
"אמצעה": true,
"אמצעו": true,
"אמצעי": true,
"אמצעך": true,
"אמצעם": true,
"אמצען": true,
"אמצתי": true,
"אמצתם": true,
"אמצתן": true,
"אמרגן": true,
"אמרוד": true,
"אמרוט": true,
"אמרות": true,
"אמריא": true,
"אמריד": true,
"אמריה": true,
"אמריו": true,
"אמריי": true,
"אמריך": true,
"אמרים": true,
"אמריץ": true,
"אמריק": true,
"אמרכז": true,
"אמרכל": true,
"אמרנו": true,
"אמרפק": true,
"אמרתה": true,
"אמרתו": true,
"אמרתי": true,
"אמרתך": true,
"אמרתם": true,
"אמרתן": true,
"אמשוך": true,
"אמשול": true,
"אמשיך": true,
"אמשיל": true,
"אמשכן": true,
"אמשמש": true,
"אמתות": true,
"אמתחת": true,
"אמתין": true,
"אמתיק": true,
"אמתית": true,
"אמתכם": true,
"אמתכן": true,
"אמתלה": true,
"אמתנה": true,
"אמתנו": true,
"אנאלי": true,
"אנבוט": true,
"אנבור": true,
"אנביט": true,
"אנגוס": true,
"אנגיד": true,
"אנגלה": true,
"אנגלו": true,
"אנגלז": true,
"אנגלי": true,
"אנגלס": true,
"אנדוד": true,
"אנדור": true,
"אנדים": true,
"אנדמי": true,
"אנדנד": true,
"אנדרה": true,
"אנדרו": true,
"אנדרי": true,
"אנהיג": true,
"אנואץ": true,
"אנואר": true,
"אנוגב": true,
"אנוגד": true,
"אנוגח": true,
"אנוגן": true,
"אנודב": true,
"אנודה": true,
"אנוהג": true,
"אנוהל": true,
"אנווט": true,
"אנוון": true,
"אנוחם": true,
"אנוחש": true,
"אנוטר": true,
"אנוכה": true,
"אנוכי": true,
"אנוכס": true,
"אנוכש": true,
"אנומק": true,
"אנונה": true,
"אנוני": true,
"אנוסה": true,
"אנוסו": true,
"אנוסח": true,
"אנוסי": true,
"אנוסך": true,
"אנוסם": true,
"אנוסן": true,
"אנוסר": true,
"אנוער": true,
"אנופה": true,
"אנופח": true,
"אנופף": true,
"אנופץ": true,
"אנופק": true,
"אנוצח": true,
"אנוצל": true,
"אנוקב": true,
"אנוקד": true,
"אנוקה": true,
"אנוקז": true,
"אנוקר": true,
"אנושה": true,
"אנושי": true,
"אנושל": true,
"אנושק": true,
"אנותב": true,
"אנותח": true,
"אנותץ": true,
"אנותק": true,
"אנזוף": true,
"אנזים": true,
"אנחות": true,
"אנחיל": true,
"אנחית": true,
"אנחנו": true,
"אנחתה": true,
"אנחתו": true,
"אנחתי": true,
"אנחתך": true,
"אנחתם": true,
"אנחתן": true,
"אנטבה": true,
"אנטון": true,
"אנטוף": true,
"אנטוש": true,
"אנטיב": true,
"אנטיש": true,
"אנטנה": true,
"אנטרל": true,
"אניון": true,
"אניות": true,
"אניטה": true,
"אנייד": true,
"אנייה": true,
"אניים": true,
"אנימה": true,
"אנינה": true,
"אניצה": true,
"אניצו": true,
"אניצי": true,
"אניצך": true,
"אניצם": true,
"אניצן": true,
"אנכיה": true,
"אנכיו": true,
"אנכיי": true,
"אנכיך": true,
"אנכים": true,
"אנכית": true,
"אנככם": true,
"אנככן": true,
"אנכנו": true,
"אנליט": true,
"אנמוך": true,
"אנמיה": true,
"אנמיך": true,
"אנמית": true,
"אנמנם": true,
"אננסה": true,
"אננסו": true,
"אננסי": true,
"אננסך": true,
"אננסם": true,
"אננסן": true,
"אננקס": true,
"אנסוך": true,
"אנסוק": true,
"אנסיה": true,
"אנסיו": true,
"אנסיי": true,
"אנסיך": true,
"אנסים": true,
"אנסכם": true,
"אנסכן": true,
"אנסנו": true,
"אנסתי": true,
"אנסתם": true,
"אנסתן": true,
"אנעים": true,
"אנענע": true,
"אנפוף": true,
"אנפוש": true,
"אנפות": true,
"אנפין": true,
"אנפיק": true,
"אנפיש": true,
"אנפנף": true,
"אנפפה": true,
"אנפפו": true,
"אנפפי": true,
"אנפפת": true,
"אנפתה": true,
"אנפתו": true,
"אנפתי": true,
"אנפתך": true,
"אנפתם": true,
"אנפתן": true,
"אנצוץ": true,
"אנצור": true,
"אנציח": true,
"אנציל": true,
"אנצנץ": true,
"אנקוט": true,
"אנקול": true,
"אנקוף": true,
"אנקור": true,
"אנקוש": true,
"אנקות": true,
"אנקרה": true,
"אנקתה": true,
"אנקתו": true,
"אנקתי": true,
"אנקתך": true,
"אנקתם": true,
"אנקתן": true,
"אנרגי": true,
"אנרים": true,
"אנרכי": true,
"אנרמל": true,
"אנשוך": true,
"אנשום": true,
"אנשוף": true,
"אנשור": true,
"אנשיה": true,
"אנשיו": true,
"אנשיי": true,
"אנשיך": true,
"אנשים": true,
"אנשנש": true,
"אסאמה": true,
"אסבול": true,
"אסבור": true,
"אסביר": true,
"אסבסד": true,
"אסבסט": true,
"אסברי": true,
"אסגוד": true,
"אסגור": true,
"אסגיר": true,
"אסגנן": true,
"אסדוק": true,
"אסדות": true,
"אסדיר": true,
"אסדרה": true,
"אסדתה": true,
"אסדתו": true,
"אסדתי": true,
"אסדתך": true,
"אסדתם": true,
"אסדתן": true,
"אסואב": true,
"אסובב": true,
"אסובך": true,
"אסובן": true,
"אסוגל": true,
"אסוגר": true,
"אסודר": true,
"אסווג": true,
"אסווה": true,
"אסויג": true,
"אסויד": true,
"אסוים": true,
"אסוכך": true,
"אסוכל": true,
"אסוכם": true,
"אסולא": true,
"אסולף": true,
"אסולק": true,
"אסומא": true,
"אסומל": true,
"אסומם": true,
"אסומן": true,
"אסומר": true,
"אסונה": true,
"אסונו": true,
"אסוני": true,
"אסונך": true,
"אסונם": true,
"אסונן": true,
"אסונף": true,
"אסופה": true,
"אסופח": true,
"אסופי": true,
"אסופק": true,
"אסופר": true,
"אסוקר": true,
"אסורב": true,
"אסורה": true,
"אסורו": true,
"אסורי": true,
"אסורך": true,
"אסורם": true,
"אסורן": true,
"אסורס": true,
"אסורק": true,
"אסותא": true,
"אסותת": true,
"אסחרר": true,
"אסטון": true,
"אסטור": true,
"אסטמה": true,
"אסטנה": true,
"אסטרט": true,
"אסייג": true,
"אסייד": true,
"אסיים": true,
"אסייע": true,
"אסייר": true,
"אסיפה": true,
"אסיפו": true,
"אסיפי": true,
"אסיפך": true,
"אסיפם": true,
"אסיפן": true,
"אסיפת": true,
"אסירה": true,
"אסירו": true,
"אסירי": true,
"אסירך": true,
"אסירם": true,
"אסירן": true,
"אסכוך": true,
"אסכום": true,
"אסכור": true,
"אסכים": true,
"אסכין": true,
"אסכית": true,
"אסכלה": true,
"אסכסך": true,
"אסכרה": true,
"אסלאם": true,
"אסלוד": true,
"אסלול": true,
"אסלום": true,
"אסלות": true,
"אסלים": true,
"אסליק": true,
"אסלמה": true,
"אסלמו": true,
"אסלמי": true,
"אסלמת": true,
"אסלסל": true,
"אסלתה": true,
"אסלתו": true,
"אסלתי": true,
"אסלתך": true,
"אסלתם": true,
"אסלתן": true,
"אסמוך": true,
"אסמור": true,
"אסמיה": true,
"אסמיו": true,
"אסמיי": true,
"אסמיך": true,
"אסמים": true,
"אסמיק": true,
"אסמכם": true,
"אסמכן": true,
"אסמכת": true,
"אסמלץ": true,
"אסמנו": true,
"אסנגר": true,
"אסנדל": true,
"אסנוט": true,
"אסניף": true,
"אסנתז": true,
"אסעיר": true,
"אספוג": true,
"אספוד": true,
"אספוק": true,
"אספור": true,
"אספות": true,
"אספיג": true,
"אספיד": true,
"אספיק": true,
"אספלט": true,
"אספנה": true,
"אספנו": true,
"אספני": true,
"אספנך": true,
"אספנם": true,
"אספנן": true,
"אספסר": true,
"אספסת": true,
"אספקה": true,
"אספקט": true,
"אספקת": true,
"אספרר": true,
"אספתא": true,
"אספתה": true,
"אספתו": true,
"אספתי": true,
"אספתך": true,
"אספתם": true,
"אספתן": true,
"אסקול": true,
"אסקור": true,
"אסקרן": true,
"אסרבל": true,
"אסרוג": true,
"אסרוק": true,
"אסרור": true,
"אסרטן": true,
"אסריח": true,
"אסריט": true,
"אסרנו": true,
"אסרסר": true,
"אסרתי": true,
"אסרתם": true,
"אסרתן": true,
"אסתאב": true,
"אסתבך": true,
"אסתבן": true,
"אסתבר": true,
"אסתגל": true,
"אסתגף": true,
"אסתגר": true,
"אסתדר": true,
"אסתום": true,
"אסתור": true,
"אסתטי": true,
"אסתיר": true,
"אסתכל": true,
"אסתכם": true,
"אסתכן": true,
"אסתלק": true,
"אסתמא": true,
"אסתמה": true,
"אסתמך": true,
"אסתמם": true,
"אסתמן": true,
"אסתמר": true,
"אסתנן": true,
"אסתעף": true,
"אסתער": true,
"אסתפח": true,
"אסתפק": true,
"אסתפר": true,
"אסתרק": true,
"אסתתם": true,
"אסתתר": true,
"אעבוד": true,
"אעבוט": true,
"אעבור": true,
"אעביד": true,
"אעביר": true,
"אעגוב": true,
"אעגון": true,
"אעדור": true,
"אעדיף": true,
"אעדכן": true,
"אעובד": true,
"אעובה": true,
"אעובר": true,
"אעוגל": true,
"אעוגן": true,
"אעודד": true,
"אעודן": true,
"אעווה": true,
"אעוור": true,
"אעוות": true,
"אעוטר": true,
"אעוכב": true,
"אעוכל": true,
"אעולל": true,
"אעומד": true,
"אעומם": true,
"אעומת": true,
"אעונג": true,
"אעונה": true,
"אעוסה": true,
"אעופף": true,
"אעופש": true,
"אעוצב": true,
"אעוקל": true,
"אעוקם": true,
"אעוקר": true,
"אעורב": true,
"אעורר": true,
"אעזוב": true,
"אעזור": true,
"אעזיב": true,
"אעטוף": true,
"אעטיר": true,
"אעיין": true,
"אעייף": true,
"אעכור": true,
"אעכיר": true,
"אעלוב": true,
"אעלוז": true,
"אעלוץ": true,
"אעליב": true,
"אעליל": true,
"אעלים": true,
"אעלעל": true,
"אעמוד": true,
"אעמול": true,
"אעמיד": true,
"אעמיס": true,
"אעמיק": true,
"אעמלן": true,
"אעמעם": true,
"אענוב": true,
"אענוד": true,
"אענטז": true,
"אעניק": true,
"אעניש": true,
"אעסוק": true,
"אעסיק": true,
"אעפיל": true,
"אעפעף": true,
"אעצבב": true,
"אעצבן": true,
"אעצום": true,
"אעצור": true,
"אעציב": true,
"אעצים": true,
"אעקוב": true,
"אעקוד": true,
"אעקוף": true,
"אעקוץ": true,
"אעקור": true,
"אעקצץ": true,
"אערבב": true,
"אערבל": true,
"אערגל": true,
"אערוב": true,
"אערוג": true,
"אערוך": true,
"אערום": true,
"אערוף": true,
"אערוק": true,
"אערטל": true,
"אעריב": true,
"אעריך": true,
"אערים": true,
"אעריף": true,
"אעריץ": true,
"אערער": true,
"אערפל": true,
"אעשוק": true,
"אעשיר": true,
"אעתור": true,
"אעתיק": true,
"אעתיר": true,
"אפברק": true,
"אפגום": true,
"אפגוש": true,
"אפגיז": true,
"אפגין": true,
"אפגיש": true,
"אפגני": true,
"אפואר": true,
"אפוגג": true,
"אפוגל": true,
"אפודה": true,
"אפודו": true,
"אפודי": true,
"אפודך": true,
"אפודם": true,
"אפודן": true,
"אפוזם": true,
"אפוזר": true,
"אפוחם": true,
"אפוחת": true,
"אפוטם": true,
"אפוטר": true,
"אפויה": true,
"אפויח": true,
"אפויס": true,
"אפולג": true,
"אפולו": true,
"אפולס": true,
"אפונה": true,
"אפונו": true,
"אפוני": true,
"אפונך": true,
"אפונם": true,
"אפונן": true,
"אפונק": true,
"אפוסה": true,
"אפוסו": true,
"אפוסי": true,
"אפוסך": true,
"אפוסל": true,
"אפוסם": true,
"אפוסן": true,
"אפוסק": true,
"אפופה": true,
"אפוצה": true,
"אפוצח": true,
"אפוצל": true,
"אפוצץ": true,
"אפוקח": true,
"אפורה": true,
"אפורז": true,
"אפורט": true,
"אפורק": true,
"אפורר": true,
"אפורש": true,
"אפושט": true,
"אפושל": true,
"אפושק": true,
"אפושר": true,
"אפותה": true,
"אפותח": true,
"אפותל": true,
"אפזול": true,
"אפחיד": true,
"אפחית": true,
"אפחלץ": true,
"אפטור": true,
"אפטיר": true,
"אפטפט": true,
"אפטרל": true,
"אפיהם": true,
"אפיהן": true,
"אפיון": true,
"אפיות": true,
"אפייה": true,
"אפייח": true,
"אפייט": true,
"אפייך": true,
"אפיים": true,
"אפיין": true,
"אפייס": true,
"אפיכם": true,
"אפיכן": true,
"אפילו": true,
"אפינה": true,
"אפינו": true,
"אפיסה": true,
"אפיקה": true,
"אפיקו": true,
"אפיקי": true,
"אפיקך": true,
"אפיקם": true,
"אפיקן": true,
"אפיתי": true,
"אפיתם": true,
"אפיתן": true,
"אפכור": true,
"אפכפך": true,
"אפלוט": true,
"אפלוש": true,
"אפלות": true,
"אפליא": true,
"אפליג": true,
"אפליה": true,
"אפליט": true,
"אפליל": true,
"אפלים": true,
"אפליץ": true,
"אפליק": true,
"אפללו": true,
"אפלמר": true,
"אפלפל": true,
"אפלתה": true,
"אפלתו": true,
"אפלתי": true,
"אפלתך": true,
"אפלתם": true,
"אפלתן": true,
"אפמפם": true,
"אפנדי": true,
"אפנון": true,
"אפנות": true,
"אפנטז": true,
"אפנים": true,
"אפננה": true,
"אפננו": true,
"אפנני": true,
"אפננת": true,
"אפנתה": true,
"אפנתו": true,
"אפנתי": true,
"אפנתך": true,
"אפנתם": true,
"אפנתן": true,
"אפסול": true,
"אפסוק": true,
"אפסות": true,
"אפסטר": true,
"אפסיד": true,
"אפסיה": true,
"אפסיו": true,
"אפסיי": true,
"אפסיך": true,
"אפסים": true,
"אפסיק": true,
"אפסית": true,
"אפסכם": true,
"אפסכן": true,
"אפסנה": true,
"אפסנו": true,
"אפספס": true,
"אפסתי": true,
"אפסתם": true,
"אפסתן": true,
"אפעיל": true,
"אפעים": true,
"אפענח": true,
"אפעפע": true,
"אפפנו": true,
"אפפתי": true,
"אפפתם": true,
"אפפתן": true,
"אפצור": true,
"אפציע": true,
"אפציץ": true,
"אפציר": true,
"אפצפץ": true,
"אפקוד": true,
"אפקוק": true,
"אפקטה": true,
"אפקטו": true,
"אפקטי": true,
"אפקטך": true,
"אפקטם": true,
"אפקטן": true,
"אפקטר": true,
"אפקיד": true,
"אפקיע": true,
"אפקיר": true,
"אפקסס": true,
"אפקפק": true,
"אפקשש": true,
"אפרגן": true,
"אפרוח": true,
"אפרוט": true,
"אפרום": true,
"אפרוס": true,
"אפרוף": true,
"אפרוץ": true,
"אפרוק": true,
"אפרוש": true,
"אפרזל": true,
"אפריד": true,
"אפריז": true,
"אפריח": true,
"אפריט": true,
"אפריך": true,
"אפריל": true,
"אפרים": true,
"אפריס": true,
"אפריע": true,
"אפריק": true,
"אפריש": true,
"אפרכם": true,
"אפרכן": true,
"אפרכס": true,
"אפרמט": true,
"אפרנה": true,
"אפרנו": true,
"אפרנס": true,
"אפרסם": true,
"אפרסק": true,
"אפרפר": true,
"אפרשן": true,
"אפרתה": true,
"אפרתי": true,
"אפשוט": true,
"אפשור": true,
"אפשיט": true,
"אפשיל": true,
"אפשיר": true,
"אפשפש": true,
"אפשרה": true,
"אפשרו": true,
"אפשרי": true,
"אפשרת": true,
"אפתור": true,
"אפתיה": true,
"אפתיע": true,
"אפתית": true,
"אצאים": true,
"אצבוט": true,
"אצבוע": true,
"אצבור": true,
"אצביע": true,
"אצבעה": true,
"אצבעו": true,
"אצבעי": true,
"אצבעך": true,
"אצבעם": true,
"אצבען": true,
"אצברח": true,
"אצדיע": true,
"אצדיק": true,
"אצהיב": true,
"אצהיר": true,
"אצודד": true,
"אצווה": true,
"אצווח": true,
"אצוות": true,
"אצוטט": true,
"אצויד": true,
"אצוין": true,
"אצויר": true,
"אצוית": true,
"אצולה": true,
"אצולם": true,
"אצומד": true,
"אצומק": true,
"אצונן": true,
"אצופה": true,
"אצופף": true,
"אצורה": true,
"אצורף": true,
"אצותת": true,
"אצחין": true,
"אצחיק": true,
"אצחצח": true,
"אצחקק": true,
"אצטבה": true,
"אצטבע": true,
"אצטבר": true,
"אצטדק": true,
"אצטון": true,
"אצטלב": true,
"אצטלה": true,
"אצטלל": true,
"אצטלם": true,
"אצטלק": true,
"אצטמג": true,
"אצטמק": true,
"אצטנן": true,
"אצטנע": true,
"אצטנף": true,
"אצטעף": true,
"אצטער": true,
"אצטפד": true,
"אצטקי": true,
"אצטרד": true,
"אצטרך": true,
"אצטרף": true,
"אצייד": true,
"אציין": true,
"אצייץ": true,
"אצייר": true,
"אציית": true,
"אצילה": true,
"אצילו": true,
"אצילי": true,
"אצילך": true,
"אצילם": true,
"אצילן": true,
"אצירה": true,
"אצלהם": true,
"אצלוב": true,
"אצלול": true,
"אצלוף": true,
"אצליב": true,
"אצליח": true,
"אצליף": true,
"אצלכם": true,
"אצלכן": true,
"אצלנו": true,
"אצלצל": true,
"אצמיא": true,
"אצמיד": true,
"אצמיח": true,
"אצמית": true,
"אצמצם": true,
"אצמרר": true,
"אצנזר": true,
"אצניה": true,
"אצניו": true,
"אצניח": true,
"אצניי": true,
"אצניך": true,
"אצנים": true,
"אצניע": true,
"אצנית": true,
"אצנכם": true,
"אצנכן": true,
"אצננו": true,
"אצנתר": true,
"אצעיד": true,
"אצעיר": true,
"אצפון": true,
"אצפור": true,
"אצפין": true,
"אצפצף": true,
"אצקצק": true,
"אצרוב": true,
"אצרוך": true,
"אצרום": true,
"אצרוף": true,
"אצרור": true,
"אצריד": true,
"אצריח": true,
"אצריך": true,
"אצרנו": true,
"אצרצר": true,
"אצרתי": true,
"אצרתם": true,
"אצרתן": true,
"אצתכם": true,
"אצתכן": true,
"אצתנו": true,
"אקבול": true,
"אקבור": true,
"אקביל": true,
"אקדוח": true,
"אקדחה": true,
"אקדחו": true,
"אקדחי": true,
"אקדחך": true,
"אקדחם": true,
"אקדחן": true,
"אקדיח": true,
"אקדים": true,
"אקדיש": true,
"אקדמי": true,
"אקהיל": true,
"אקובע": true,
"אקובץ": true,
"אקודד": true,
"אקודם": true,
"אקודש": true,
"אקווה": true,
"אקוור": true,
"אקוזז": true,
"אקוטב": true,
"אקוטי": true,
"אקוטע": true,
"אקוים": true,
"אקולח": true,
"אקולל": true,
"אקולף": true,
"אקומח": true,
"אקומט": true,
"אקומם": true,
"אקומר": true,
"אקונו": true,
"אקונח": true,
"אקונן": true,
"אקוסט": true,
"אקופד": true,
"אקופח": true,
"אקופל": true,
"אקוצץ": true,
"אקוצר": true,
"אקורב": true,
"אקורד": true,
"אקורץ": true,
"אקורר": true,
"אקושט": true,
"אקושר": true,
"אקושש": true,
"אקזמה": true,
"אקטול": true,
"אקטום": true,
"אקטוף": true,
"אקטיב": true,
"אקטין": true,
"אקטיר": true,
"אקטלג": true,
"אקטרג": true,
"אקייב": true,
"אקיים": true,
"אקייץ": true,
"אקלוד": true,
"אקלוט": true,
"אקלום": true,
"אקלוף": true,
"אקלטר": true,
"אקליד": true,
"אקליט": true,
"אקלים": true,
"אקליק": true,
"אקליש": true,
"אקלקל": true,
"אקמול": true,
"אקמוץ": true,
"אקנוס": true,
"אקנטר": true,
"אקניט": true,
"אקסום": true,
"אקסים": true,
"אקספל": true,
"אקעקע": true,
"אקפוץ": true,
"אקפיא": true,
"אקפיד": true,
"אקפיץ": true,
"אקצוב": true,
"אקצוף": true,
"אקצוץ": true,
"אקצור": true,
"אקציב": true,
"אקציה": true,
"אקצין": true,
"אקציע": true,
"אקציף": true,
"אקראי": true,
"אקרום": true,
"אקרון": true,
"אקרוס": true,
"אקרוץ": true,
"אקרטע": true,
"אקריא": true,
"אקריב": true,
"אקריח": true,
"אקרים": true,
"אקרין": true,
"אקריש": true,
"אקרית": true,
"אקרמן": true,
"אקרנה": true,
"אקרנו": true,
"אקרני": true,
"אקרנך": true,
"אקרנם": true,
"אקרנן": true,
"אקרצף": true,
"אקרקע": true,
"אקרקף": true,
"אקרקר": true,
"אקרקש": true,
"אקשור": true,
"אקשיב": true,
"אקשיח": true,
"אקשקש": true,
"אראלה": true,
"אראלו": true,
"אראלי": true,
"אראלך": true,
"אראלם": true,
"אראלן": true,
"אראמה": true,
"ארבוץ": true,
"ארבור": true,
"ארביב": true,
"ארביע": true,
"ארביץ": true,
"ארבנו": true,
"ארבעה": true,
"ארבעת": true,
"ארבתי": true,
"ארבתם": true,
"ארבתן": true,
"ארגוב": true,
"ארגוז": true,
"ארגום": true,
"ארגון": true,
"ארגוש": true,
"ארגזה": true,
"ארגזו": true,
"ארגזי": true,
"ארגזך": true,
"ארגזם": true,
"ארגזן": true,
"ארגיז": true,
"ארגיל": true,
"ארגיע": true,
"ארגיש": true,
"ארגמן": true,
"ארגנה": true,
"ארגנו": true,
"ארגני": true,
"ארגנת": true,
"ארגתי": true,
"ארגתם": true,
"ארגתן": true,
"ארדוף": true,
"ארדים": true,
"ארהיב": true,
"ארובב": true,
"ארובד": true,
"ארובה": true,
"ארובי": true,
"ארובע": true,
"ארוגה": true,
"ארוגש": true,
"ארודד": true,
"ארוהט": true,
"ארווה": true,
"ארווח": true,
"ארוזה": true,
"ארוחה": true,
"ארוחת": true,
"ארוטי": true,
"ארוטש": true,
"ארוכה": true,
"ארוכז": true,
"ארוכי": true,
"ארוכך": true,
"ארוכת": true,
"ארומה": true,
"ארומם": true,
"ארונה": true,
"ארונו": true,
"ארוני": true,
"ארונך": true,
"ארונם": true,
"ארונן": true,
"ארוסה": true,
"ארוסו": true,
"ארוסי": true,
"ארוסך": true,
"ארוסם": true,
"ארוסן": true,
"ארוסס": true,
"ארוסק": true,
"ארופא": true,
"ארופד": true,
"ארופף": true,
"ארוצה": true,
"ארוצף": true,
"ארוצץ": true,
"ארוקן": true,
"ארוקע": true,
"ארורה": true,
"ארושש": true,
"ארושת": true,
"ארותך": true,
"ארותק": true,
"ארזיה": true,
"ארזיו": true,
"ארזיי": true,
"ארזיך": true,
"ארזים": true,
"ארזכם": true,
"ארזכן": true,
"ארזנו": true,
"ארזתי": true,
"ארזתם": true,
"ארזתן": true,
"ארחיב": true,
"ארחיק": true,
"ארחנה": true,
"ארחרח": true,
"ארטאס": true,
"ארטוט": true,
"ארטון": true,
"ארטיב": true,
"ארטיט": true,
"ארטיל": true,
"ארטיק": true,
"ארטרא": true,
"אריאב": true,
"אריאל": true,
"אריגה": true,
"אריגו": true,
"אריגי": true,
"אריגך": true,
"אריגם": true,
"אריגן": true,
"אריות": true,
"אריזה": true,
"אריזת": true,
"אריחה": true,
"אריחו": true,
"אריחי": true,
"אריחך": true,
"אריחם": true,
"אריחן": true,
"ארייה": true,
"ארייו": true,
"ארייך": true,
"אריים": true,
"אריין": true,
"ארינה": true,
"ארינו": true,
"אריסה": true,
"אריסו": true,
"אריסי": true,
"אריסך": true,
"אריסם": true,
"אריסן": true,
"אריתי": true,
"אריתם": true,
"אריתן": true,
"ארכאי": true,
"ארכבה": true,
"ארכבו": true,
"ארכבי": true,
"ארכבת": true,
"ארכוב": true,
"ארכון": true,
"ארכוס": true,
"ארכוש": true,
"ארכות": true,
"ארכיא": true,
"ארכיב": true,
"ארכין": true,
"ארכנו": true,
"ארכני": true,
"ארכתה": true,
"ארכתו": true,
"ארכתי": true,
"ארכתך": true,
"ארכתם": true,
"ארכתן": true,
"ארלוז": true,
"ארמדה": true,
"ארמוז": true,
"ארמון": true,
"ארמוס": true,
"ארמזר": true,
"ארמיה": true,
"ארמים": true,
"ארמית": true,
"ארמני": true,
"ארנבה": true,
"ארנבו": true,
"ארנבי": true,
"ארנבך": true,
"ארנבם": true,
"ארנבן": true,
"ארנבת": true,
"ארנון": true,
"ארניה": true,
"ארנין": true,
"ארנסט": true,
"ארנקה": true,
"ארנקו": true,
"ארנקי": true,
"ארנקך": true,
"ארנקם": true,
"ארנקן": true,
"ארסיה": true,
"ארסיו": true,
"ארסיי": true,
"ארסיך": true,
"ארסים": true,
"ארסית": true,
"ארסכם": true,
"ארסכן": true,
"ארסנה": true,
"ארסנו": true,
"ארסנל": true,
"ארעיב": true,
"ארעיד": true,
"ארעיל": true,
"ארעים": true,
"ארעיף": true,
"ארעיש": true,
"ארעית": true,
"ארענן": true,
"ארפרף": true,
"ארצוץ": true,
"ארצות": true,
"ארצין": true,
"ארצית": true,
"ארצכם": true,
"ארצכן": true,
"ארצנו": true,
"ארצני": true,
"ארקדי": true,
"ארקוד": true,
"ארקום": true,
"ארקוק": true,
"ארקטי": true,
"ארקיב": true,
"ארקיד": true,
"ארקין": true,
"ארקיע": true,
"ארשום": true,
"ארשוף": true,
"ארשים": true,
"ארשיע": true,
"ארשרש": true,
"ארשתה": true,
"ארשתו": true,
"ארשתי": true,
"ארשתך": true,
"ארשתם": true,
"ארשתן": true,
"ארתום": true,
"ארתור": true,
"ארתיח": true,
"ארתיע": true,
"אשאיל": true,
"אשאיף": true,
"אשאיר": true,
"אשבוק": true,
"אשבור": true,
"אשבות": true,
"אשביח": true,
"אשביע": true,
"אשביר": true,
"אשבית": true,
"אשגוג": true,
"אשגיח": true,
"אשגשג": true,
"אשדוד": true,
"אשדות": true,
"אשדיה": true,
"אשדיו": true,
"אשדיי": true,
"אשדיך": true,
"אשדים": true,
"אשדכם": true,
"אשדכן": true,
"אשדנו": true,
"אשדרג": true,
"אשובב": true,
"אשובח": true,
"אשובט": true,
"אשובץ": true,
"אשובר": true,
"אשובש": true,
"אשוגע": true,
"אשוגר": true,
"אשודד": true,
"אשודך": true,
"אשודל": true,
"אשודר": true,
"אשווה": true,
"אשווע": true,
"אשווק": true,
"אשוחד": true,
"אשוחה": true,
"אשוחו": true,
"אשוחח": true,
"אשוחי": true,
"אשוחך": true,
"אשוחם": true,
"אשוחן": true,
"אשוחק": true,
"אשוטט": true,
"אשויך": true,
"אשויף": true,
"אשוכך": true,
"אשוכל": true,
"אשוכן": true,
"אשולב": true,
"אשולח": true,
"אשולם": true,
"אשולש": true,
"אשומן": true,
"אשומר": true,
"אשונה": true,
"אשונן": true,
"אשונע": true,
"אשוסה": true,
"אשוסע": true,
"אשוסף": true,
"אשוער": true,
"אשופד": true,
"אשופה": true,
"אשופע": true,
"אשופץ": true,
"אשופר": true,
"אשוקם": true,
"אשוקע": true,
"אשוקף": true,
"אשוקץ": true,
"אשורי": true,
"אשורש": true,
"אשותך": true,
"אשותף": true,
"אשותק": true,
"אשזוף": true,
"אשזור": true,
"אשחבר": true,
"אשחזר": true,
"אשחיז": true,
"אשחיל": true,
"אשחים": true,
"אשחיר": true,
"אשחית": true,
"אשחלף": true,
"אשחרר": true,
"אשטום": true,
"אשטוף": true,
"אשיות": true,
"אשייח": true,
"אשייט": true,
"אשייך": true,
"אשייף": true,
"אשכבה": true,
"אשכול": true,
"אשכון": true,
"אשכור": true,
"אשכחך": true,
"אשכיב": true,
"אשכיה": true,
"אשכיו": true,
"אשכיח": true,
"אשכיי": true,
"אשכיך": true,
"אשכיל": true,
"אשכים": true,
"אשכין": true,
"אשכיר": true,
"אשככם": true,
"אשככן": true,
"אשכלל": true,
"אשכנו": true,
"אשכנז": true,
"אשכנע": true,
"אשכפל": true,
"אשכשך": true,
"אשכתב": true,
"אשלגן": true,
"אשלהב": true,
"אשלוט": true,
"אשלול": true,
"אשלוף": true,
"אשלוק": true,
"אשלטט": true,
"אשליה": true,
"אשליו": true,
"אשליט": true,
"אשליי": true,
"אשליך": true,
"אשלים": true,
"אשלכם": true,
"אשלכן": true,
"אשלנו": true,
"אשלשל": true,
"אשמאה": true,
"אשמאו": true,
"אשמאי": true,
"אשמאך": true,
"אשמאם": true,
"אשמאן": true,
"אשמוט": true,
"אשמור": true,
"אשמות": true,
"אשמיד": true,
"אשמיט": true,
"אשמים": true,
"אשמין": true,
"אשמיע": true,
"אשמיץ": true,
"אשמיש": true,
"אשמנה": true,
"אשמנו": true,
"אשמתה": true,
"אשמתו": true,
"אשמתי": true,
"אשמתך": true,
"אשמתם": true,
"אשמתן": true,
"אשנבה": true,
"אשנבו": true,
"אשנבי": true,
"אשנבך": true,
"אשנבם": true,
"אשנבן": true,
"אשניא": true,
"אשעבד": true,
"אשעין": true,
"אשעמם": true,
"אשערך": true,
"אשעשע": true,
"אשעתק": true,
"אשפוז": true,
"אשפוט": true,
"אשפוך": true,
"אשפול": true,
"אשפות": true,
"אשפזה": true,
"אשפזו": true,
"אשפזי": true,
"אשפזת": true,
"אשפיל": true,
"אשפים": true,
"אשפיע": true,
"אשפית": true,
"אשפעל": true,
"אשפצר": true,
"אשפשף": true,
"אשפתה": true,
"אשפתו": true,
"אשפתי": true,
"אשפתך": true,
"אשפתם": true,
"אשפתן": true,
"אשצוף": true,
"אשקוד": true,
"אשקוט": true,
"אשקול": true,
"אשקיט": true,
"אשקיע": true,
"אשקיף": true,
"אשקלל": true,
"אשקשק": true,
"אשראה": true,
"אשראו": true,
"אשראי": true,
"אשראך": true,
"אשראם": true,
"אשראן": true,
"אשרבב": true,
"אשרבט": true,
"אשרוד": true,
"אשרוט": true,
"אשרוך": true,
"אשרוף": true,
"אשרוץ": true,
"אשרוק": true,
"אשרור": true,
"אשרות": true,
"אשרטט": true,
"אשריה": true,
"אשריו": true,
"אשריך": true,
"אשריץ": true,
"אשריש": true,
"אשרנה": true,
"אשררה": true,
"אשררו": true,
"אשררי": true,
"אשררת": true,
"אשרשר": true,
"אשרתה": true,
"אשרתו": true,
"אשרתי": true,
"אשרתך": true,
"אשרתם": true,
"אשרתן": true,
"אששנה": true,
"אשתאה": true,
"אשתבח": true,
"אשתבט": true,
"אשתבץ": true,
"אשתבר": true,
"אשתבש": true,
"אשתגע": true,
"אשתדך": true,
"אשתדל": true,
"אשתהה": true,
"אשתול": true,
"אשתוק": true,
"אשתות": true,
"אשתזף": true,
"אשתחל": true,
"אשתחץ": true,
"אשתטה": true,
"אשתטח": true,
"אשתיל": true,
"אשתין": true,
"אשתיק": true,
"אשתית": true,
"אשתכח": true,
"אשתכם": true,
"אשתכן": true,
"אשתכר": true,
"אשתלב": true,
"אשתלח": true,
"אשתלט": true,
"אשתלם": true,
"אשתמד": true,
"אשתמט": true,
"אשתמע": true,
"אשתמר": true,
"אשתמש": true,
"אשתנה": true,
"אשתנו": true,
"אשתנק": true,
"אשתעל": true,
"אשתפד": true,
"אשתפך": true,
"אשתפל": true,
"אשתפן": true,
"אשתפר": true,
"אשתקד": true,
"אשתקם": true,
"אשתקע": true,
"אשתקף": true,
"אשתרג": true,
"אשתרך": true,
"אשתרע": true,
"אשתרר": true,
"אשתרש": true,
"אשתתה": true,
"אשתתף": true,
"אשתתק": true,
"אתאבד": true,
"אתאבך": true,
"אתאבל": true,
"אתאבן": true,
"אתאבק": true,
"אתאגד": true,
"אתאדה": true,
"אתאהב": true,
"אתאזן": true,
"אתאזר": true,
"אתאחד": true,
"אתאחה": true,
"אתאחר": true,
"אתאים": true,
"אתאכל": true,
"אתאמן": true,
"אתאמץ": true,
"אתאמת": true,
"אתאסף": true,
"אתאפס": true,
"אתאפק": true,
"אתאפר": true,
"אתארח": true,
"אתארך": true,
"אתארס": true,
"אתאשר": true,
"אתבאס": true,
"אתבאר": true,
"אתבגר": true,
"אתבדה": true,
"אתבדח": true,
"אתבדל": true,
"אתבדר": true,
"אתבהם": true,
"אתבהר": true,
"אתבזה": true,
"אתבטא": true,
"אתבטל": true,
"אתבלה": true,
"אתבלט": true,
"אתבסם": true,
"אתבסס": true,
"אתבצע": true,
"אתבצר": true,
"אתבקע": true,
"אתבקש": true,
"אתברג": true,
"אתברך": true,
"אתברר": true,
"אתבשל": true,
"אתבשם": true,
"אתבשר": true,
"אתגאה": true,
"אתגבה": true,
"אתגבר": true,
"אתגבש": true,
"אתגדר": true,
"אתגור": true,
"אתגלה": true,
"אתגלח": true,
"אתגלם": true,
"אתגלע": true,
"אתגלש": true,
"אתגמד": true,
"אתגמל": true,
"אתגמש": true,
"אתגנב": true,
"אתגפף": true,
"אתגרד": true,
"אתגרה": true,
"אתגרו": true,
"אתגרי": true,
"אתגרך": true,
"אתגרם": true,
"אתגרן": true,
"אתגרש": true,
"אתגרת": true,
"אתגשם": true,
"אתדלק": true,
"אתדמה": true,
"אתדפק": true,
"אתדרך": true,
"אתהדק": true,
"אתהדר": true,
"אתהלך": true,
"אתהלל": true,
"אתהפך": true,
"אתואם": true,
"אתואר": true,
"אתובל": true,
"אתווה": true,
"אתווך": true,
"אתוזז": true,
"אתוחח": true,
"אתוחם": true,
"אתויג": true,
"אתויק": true,
"אתויר": true,
"אתוכן": true,
"אתונה": true,
"אתונו": true,
"אתוני": true,
"אתונך": true,
"אתונם": true,
"אתונן": true,
"אתוסה": true,
"אתוסו": true,
"אתוסי": true,
"אתוסך": true,
"אתוסם": true,
"אתוסן": true,
"אתוסף": true,
"אתועד": true,
"אתועל": true,
"אתועש": true,
"אתופף": true,
"אתוקל": true,
"אתוקן": true,
"אתורץ": true,
"אתזמן": true,
"אתזמר": true,
"אתחאד": true,
"אתחבא": true,
"אתחבב": true,
"אתחבט": true,
"אתחבל": true,
"אתחבק": true,
"אתחבר": true,
"אתחדד": true,
"אתחדש": true,
"אתחול": true,
"אתחום": true,
"אתחזה": true,
"אתחזק": true,
"אתחיל": true,
"אתחכך": true,
"אתחכם": true,
"אתחלה": true,
"אתחלו": true,
"אתחלי": true,
"אתחלף": true,
"אתחלק": true,
"אתחלת": true,
"אתחמם": true,
"אתחמן": true,
"אתחמק": true,
"אתחמש": true,
"אתחנך": true,
"אתחנן": true,
"אתחנף": true,
"אתחסד": true,
"אתחסל": true,
"אתחסן": true,
"אתחפף": true,
"אתחפר": true,
"אתחפש": true,
"אתחצף": true,
"אתחקה": true,
"אתחקר": true,
"אתחרד": true,
"אתחרה": true,
"אתחרז": true,
"אתחרט": true,
"אתחרש": true,
"אתחשב": true,
"אתחשל": true,
"אתחשק": true,
"אתחתן": true,
"אתחתר": true,
"אתיהם": true,
"אתיהן": true,
"אתיות": true,
"אתייג": true,
"אתייך": true,
"אתיים": true,
"אתייק": true,
"אתייר": true,
"אתיכם": true,
"אתיכן": true,
"אתינו": true,
"אתיקה": true,
"אתכבד": true,
"אתכבס": true,
"אתכדר": true,
"אתכהה": true,
"אתכחש": true,
"אתכלה": true,
"אתכנה": true,
"אתכנן": true,
"אתכנס": true,
"אתכנת": true,
"אתכסה": true,
"אתכסח": true,
"אתכער": true,
"אתכפר": true,
"אתכתב": true,
"אתכתש": true,
"אתלבט": true,
"אתלבן": true,
"אתלבש": true,
"אתלהב": true,
"אתלהט": true,
"אתלהם": true,
"אתלון": true,
"אתלוש": true,
"אתלחש": true,
"אתלטי": true,
"אתלטף": true,
"אתלטש": true,
"אתלכד": true,
"אתלמד": true,
"אתלפף": true,
"אתלקח": true,
"אתלקק": true,
"אתלתל": true,
"אתמגן": true,
"אתמוך": true,
"אתמול": true,
"אתמזג": true,
"אתמזל": true,
"אתמחה": true,
"אתמחר": true,
"אתמיד": true,
"אתמיה": true,
"אתמיר": true,
"אתמכן": true,
"אתמכר": true,
"אתמלא": true,
"אתמלל": true,
"אתממש": true,
"אתמנה": true,
"אתמנף": true,
"אתמסד": true,
"אתמסה": true,
"אתמסר": true,
"אתמעט": true,
"אתמצא": true,
"אתמצה": true,
"אתמצק": true,
"אתמצת": true,
"אתמקד": true,
"אתמקח": true,
"אתמקם": true,
"אתמרד": true,
"אתמרח": true,
"אתמרן": true,
"אתמרץ": true,
"אתמרק": true,
"אתמרר": true,
"אתמשך": true,
"אתמתח": true,
"אתמתן": true,
"אתנאה": true,
"אתנבא": true,
"אתנגב": true,
"אתנגד": true,
"אתנגח": true,
"אתנגן": true,
"אתנגש": true,
"אתנדב": true,
"אתנדף": true,
"אתנהג": true,
"אתנהל": true,
"אתנול": true,
"אתנזר": true,
"אתנחל": true,
"אתנחם": true,
"אתניע": true,
"אתנית": true,
"אתנכל": true,
"אתנכר": true,
"אתננה": true,
"אתננו": true,
"אתנני": true,
"אתננך": true,
"אתננם": true,
"אתננן": true,
"אתנסה": true,
"אתנסח": true,
"אתנער": true,
"אתנפח": true,
"אתנפל": true,
"אתנפץ": true,
"אתנצח": true,
"אתנצל": true,
"אתנצר": true,
"אתנקה": true,
"אתנקז": true,
"אתנקם": true,
"אתנקש": true,
"אתנשא": true,
"אתנשם": true,
"אתנשף": true,
"אתנשק": true,
"אתנתב": true,
"אתנתק": true,
"אתסוס": true,
"אתסיס": true,
"אתסכל": true,
"אתסרט": true,
"אתעבה": true,
"אתעבר": true,
"אתעגל": true,
"אתעדן": true,
"אתעדף": true,
"אתעטף": true,
"אתעטש": true,
"אתעכב": true,
"אתעכל": true,
"אתעלה": true,
"אתעלל": true,
"אתעלם": true,
"אתעלס": true,
"אתעלף": true,
"אתעלק": true,
"אתעמל": true,
"אתעמם": true,
"אתעמק": true,
"אתעמר": true,
"אתעמת": true,
"אתענג": true,
"אתענה": true,
"אתענן": true,
"אתעסק": true,
"אתעפש": true,
"אתעצב": true,
"אתעצל": true,
"אתעצם": true,
"אתעקל": true,
"אתעקם": true,
"אתעקר": true,
"אתעקש": true,
"אתערב": true,
"אתערה": true,
"אתעשר": true,
"אתעשת": true,
"אתעתד": true,
"אתעתע": true,
"אתעתק": true,
"אתפאר": true,
"אתפגל": true,
"אתפגר": true,
"אתפוס": true,
"אתפור": true,
"אתפוש": true,
"אתפזם": true,
"אתפזר": true,
"אתפחם": true,
"אתפחס": true,
"אתפטם": true,
"אתפטר": true,
"אתפיח": true,
"אתפיל": true,
"אתפכח": true,
"אתפלא": true,
"אתפלג": true,
"אתפלח": true,
"אתפלל": true,
"אתפלץ": true,
"אתפלש": true,
"אתפנה": true,
"אתפנק": true,
"אתפעל": true,
"אתפעם": true,
"אתפצח": true,
"אתפצל": true,
"אתפקד": true,
"אתפקע": true,
"אתפקר": true,
"אתפרס": true,
"אתפרע": true,
"אתפרץ": true,
"אתפרק": true,
"אתפרש": true,
"אתפשט": true,
"אתפשל": true,
"אתפשר": true,
"אתפתה": true,
"אתפתח": true,
"אתפתל": true,
"אתצפת": true,
"אתקבל": true,
"אתקבע": true,
"אתקבץ": true,
"אתקדם": true,
"אתקדר": true,
"אתקדש": true,
"אתקהל": true,
"אתקוף": true,
"אתקזז": true,
"אתקיל": true,
"אתקין": true,
"אתקיף": true,
"אתקלח": true,
"אתקלט": true,
"אתקלס": true,
"אתקלף": true,
"אתקמט": true,
"אתקמר": true,
"אתקנא": true,
"אתקפד": true,
"אתקפל": true,
"אתקצב": true,
"אתקצץ": true,
"אתקצר": true,
"אתקרא": true,
"אתקרב": true,
"אתקרח": true,
"אתקרר": true,
"אתקרש": true,
"אתקשה": true,
"אתקשח": true,
"אתקשט": true,
"אתקשר": true,
"אתקתק": true,
"אתראה": true,
"אתרבד": true,
"אתרבה": true,
"אתרבת": true,
"אתרגז": true,
"אתרגל": true,
"אתרגם": true,
"אתרגע": true,
"אתרגש": true,
"אתרדד": true,
"אתרהב": true,
"אתרוג": true,
"אתרום": true,
"אתרחב": true,
"אתרחץ": true,
"אתרחק": true,
"אתרחש": true,
"אתרטב": true,
"אתריה": true,
"אתריו": true,
"אתריי": true,
"אתריך": true,
"אתרים": true,
"אתריס": true,
"אתריע": true,
"אתרכב": true,
"אתרכז": true,
"אתרכך": true,
"אתרכם": true,
"אתרכן": true,
"אתרנה": true,
"אתרנו": true,
"אתרסן": true,
"אתרסק": true,
"אתרעם": true,
"אתרפא": true,
"אתרפה": true,
"אתרפט": true,
"אתרפס": true,
"אתרפק": true,
"אתרצה": true,
"אתרקם": true,
"אתרשל": true,
"אתרשם": true,
"אתרתח": true,
"אתשאל": true,
"אתתים": true,
"אתתית": true,
"באביב": true,
"באבני": true,
"באגיה": true,
"באגיו": true,
"באגיי": true,
"באגיך": true,
"באגים": true,
"באגכם": true,
"באגכן": true,
"באגנו": true,
"באדום": true,
"באדיס": true,
"באדמה": true,
"באהבה": true,
"באהבת": true,
"באואר": true,
"באובב": true,
"באוהל": true,
"באוזן": true,
"באוטו": true,
"באויר": true,
"באוכל": true,
"באולם": true,
"באומל": true,
"באומן": true,
"באומץ": true,
"באונס": true,
"באוסף": true,
"באופי": true,
"באופן": true,
"באופק": true,
"באוצר": true,
"באורח": true,
"באורך": true,
"באושה": true,
"באושר": true,
"באותה": true,
"באותו": true,
"באותם": true,
"באותן": true,
"באזור": true,
"באזני": true,
"באחוז": true,
"באטמן": true,
"באטנר": true,
"באיבו": true,
"באיזה": true,
"באיזו": true,
"באיים": true,
"באייר": true,
"באילו": true,
"באילת": true,
"באינד": true,
"באינט": true,
"באינס": true,
"באישה": true,
"באלול": true,
"באללה": true,
"באלמר": true,
"באלעד": true,
"באלפי": true,
"באמון": true,
"באמור": true,
"באמפי": true,
"באמצע": true,
"באנשי": true,
"באסון": true,
"באסטה": true,
"באסיה": true,
"באסנה": true,
"באסקי": true,
"באסרו": true,
"באפיק": true,
"באפלו": true,
"באפרי": true,
"באפשר": true,
"באצבע": true,
"בארבע": true,
"בארון": true,
"בארות": true,
"באריז": true,
"בארין": true,
"בארכם": true,
"בארכן": true,
"בארנה": true,
"בארנו": true,
"בארצו": true,
"באשיר": true,
"באשמת": true,
"באשנה": true,
"באשנו": true,
"באשתי": true,
"באשתם": true,
"באשתן": true,
"באתרי": true,
"בבאבי": true,
"בבאזל": true,
"בבגדי": true,
"בבואה": true,
"בבואו": true,
"בבונג": true,
"בבונה": true,
"בבונו": true,
"בבוני": true,
"בבונך": true,
"בבונם": true,
"בבונן": true,
"בבוקר": true,
"בבטון": true,
"בבטחה": true,
"בביהד": true,
"בביהמ": true,
"בביהס": true,
"בביצה": true,
"בבירה": true,
"בבירת": true,
"בביתה": true,
"בביתו": true,
"בביתי": true,
"בביתם": true,
"בביתן": true,
"בביתר": true,
"בבלוג": true,
"בבלים": true,
"בבלית": true,
"בבניה": true,
"בבנין": true,
"בבסיס": true,
"בבעיה": true,
"בבעלי": true,
"בבקעת": true,
"בבקשה": true,
"בבקשת": true,
"בבראד": true,
"בברור": true,
"בברזל": true,
"בברית": true,
"בברכה": true,
"בברכת": true,
"בבתים": true,
"בבתיס": true,
"בבתכם": true,
"בבתכן": true,
"בבתנו": true,
"בגאון": true,
"בגבול": true,
"בגביע": true,
"בגבעת": true,
"בגדאד": true,
"בגדוד": true,
"בגדול": true,
"בגדיה": true,
"בגדיו": true,
"בגדיי": true,
"בגדיך": true,
"בגדים": true,
"בגדכם": true,
"בגדכן": true,
"בגדנו": true,
"בגדרה": true,
"בגדתי": true,
"בגדתם": true,
"בגדתן": true,
"בגובה": true,
"בגוגל": true,
"בגודה": true,
"בגודל": true,
"בגוון": true,
"בגולה": true,
"בגולן": true,
"בגופה": true,
"בגופו": true,
"בגופי": true,
"בגופם": true,
"בגורל": true,
"בגזרה": true,
"בגזרת": true,
"בגטים": true,
"בגידה": true,
"בגיוס": true,
"בגילי": true,
"בגינה": true,
"בגינו": true,
"בגיני": true,
"בגינך": true,
"בגינם": true,
"בגינן": true,
"בגירה": true,
"בגישה": true,
"בגלגל": true,
"בגלוי": true,
"בגלות": true,
"בגליל": true,
"בגללה": true,
"בגללו": true,
"בגללי": true,
"בגללך": true,
"בגללם": true,
"בגללן": true,
"בגמרא": true,
"בגנום": true,
"בגנות": true,
"בגנים": true,
"בגסות": true,
"בגרון": true,
"בגרות": true,
"בגרנה": true,
"בגרנו": true,
"בגרסה": true,
"בגרסת": true,
"בגרתי": true,
"בגרתם": true,
"בגרתן": true,
"בדאגה": true,
"בדאהר": true,
"בדאים": true,
"בדאית": true,
"בדאצה": true,
"בדברי": true,
"בדואי": true,
"בדואר": true,
"בדויה": true,
"בדולח": true,
"בדומה": true,
"בדופן": true,
"בדוקה": true,
"בדורו": true,
"בדותה": true,
"בדחני": true,
"בדיאל": true,
"בדידה": true,
"בדיהם": true,
"בדיהן": true,
"בדיון": true,
"בדיוק": true,
"בדיור": true,
"בדיות": true,
"בדיחה": true,
"בדייה": true,
"בדייך": true,
"בדיכם": true,
"בדיכן": true,
"בדינה": true,
"בדינו": true,
"בדיני": true,
"בדיסק": true,
"בדיקה": true,
"בדיקת": true,
"בדירה": true,
"בדירת": true,
"בדיתי": true,
"בדיתם": true,
"בדיתן": true,
"בדליה": true,
"בדליו": true,
"בדליי": true,
"בדליך": true,
"בדלים": true,
"בדלכם": true,
"בדלכן": true,
"בדלנה": true,
"בדלנו": true,
"בדלני": true,
"בדלקת": true,
"בדמות": true,
"בדמשק": true,
"בדעות": true,
"בדעתו": true,
"בדעתי": true,
"בדעתם": true,
"בדפוס": true,
"בדקות": true,
"בדקים": true,
"בדקנו": true,
"בדקתי": true,
"בדקתם": true,
"בדקתן": true,
"בדראן": true,
"בדרגה": true,
"בדרגת": true,
"בדרום": true,
"בדרכה": true,
"בדרכו": true,
"בדרכי": true,
"בדרכם": true,
"בדרמה": true,
"בדרנה": true,
"בדרנו": true,
"בדרני": true,
"בדרנך": true,
"בדרנם": true,
"בדרנן": true,
"בהאים": true,
"בהאית": true,
"בהארץ": true,
"בהבדל": true,
"בהבנה": true,
"בהבנת": true,
"בהגיע": true,
"בהגנה": true,
"בהגשת": true,
"בהודו": true,
"בהווה": true,
"בהוטן": true,
"בהולה": true,
"בהונג": true,
"בהחבא": true,
"בהחלט": true,
"בהטכם": true,
"בהטכן": true,
"בהטנו": true,
"בהיבט": true,
"בהיות": true,
"בהייה": true,
"בהינה": true,
"בהינו": true,
"בהיסט": true,
"בהיצף": true,
"בהיקף": true,
"בהירה": true,
"בהישג": true,
"בהיתי": true,
"בהיתם": true,
"בהיתן": true,
"בהכנה": true,
"בהכנת": true,
"בהכרה": true,
"בהכרח": true,
"בהלות": true,
"בהליך": true,
"בהלכה": true,
"בהלתה": true,
"בהלתו": true,
"בהלתי": true,
"בהלתך": true,
"בהלתם": true,
"בהלתן": true,
"בהמון": true,
"בהמות": true,
"בהמית": true,
"בהמשך": true,
"בהמתה": true,
"בהמתו": true,
"בהמתי": true,
"בהמתך": true,
"בהמתם": true,
"בהמתן": true,
"בהנאה": true,
"בהנהג": true,
"בהנחה": true,
"בהסבר": true,
"בהסדר": true,
"בהסכם": true,
"בהספק": true,
"בהעדר": true,
"בהערה": true,
"בהפסד": true,
"בהפצת": true,
"בהפקה": true,
"בהפקת": true,
"בהפרש": true,
"בהצגה": true,
"בהצגת": true,
"בהצנע": true,
"בהצעה": true,
"בהצעת": true,
"בהקדם": true,
"בהקמה": true,
"בהקנה": true,
"בהקנו": true,
"בהקשר": true,
"בהקתי": true,
"בהקתם": true,
"בהקתן": true,
"בהרבה": true,
"בהרות": true,
"בהרים": true,
"בהרכב": true,
"בהרפס": true,
"בהרתה": true,
"בהרתו": true,
"בהרתי": true,
"בהרתך": true,
"בהרתם": true,
"בהרתן": true,
"בהשגת": true,
"בהתאם": true,
"בואכה": true,
"בואכם": true,
"בואכן": true,
"בואנה": true,
"בואנו": true,
"בואסה": true,
"בואסו": true,
"בואסת": true,
"בוארה": true,
"בוארו": true,
"בוארת": true,
"בואשה": true,
"בואשו": true,
"בואשי": true,
"בואשך": true,
"בואשם": true,
"בואשן": true,
"בואשת": true,
"בובות": true,
"בובתה": true,
"בובתו": true,
"בובתי": true,
"בובתך": true,
"בובתם": true,
"בובתן": true,
"בוגדה": true,
"בוגדו": true,
"בוגדי": true,
"בוגדך": true,
"בוגדם": true,
"בוגדן": true,
"בוגדת": true,
"בוגרה": true,
"בוגרו": true,
"בוגרי": true,
"בוגרך": true,
"בוגרם": true,
"בוגרן": true,
"בוגרת": true,
"בודאי": true,
"בודדה": true,
"בודדו": true,
"בודדי": true,
"בודדת": true,
"בודהה": true,
"בודות": true,
"בודיה": true,
"בודים": true,
"בודלה": true,
"בודלו": true,
"בודלת": true,
"בודנר": true,
"בודקת": true,
"בודרה": true,
"בודרו": true,
"בודרת": true,
"בוהות": true,
"בוהים": true,
"בוהמה": true,
"בוהמי": true,
"בוהנה": true,
"בוהנו": true,
"בוהני": true,
"בוהנך": true,
"בוהנם": true,
"בוהנן": true,
"בוהקת": true,
"בוולו": true,
"בוועד": true,
"בוורי": true,
"בוזבז": true,
"בוזזת": true,
"בוזית": true,
"בוזכם": true,
"בוזכן": true,
"בוזנה": true,
"בוזנו": true,
"בוזקת": true,
"בוזרה": true,
"בוזרו": true,
"בוזרת": true,
"בוזתה": true,
"בוחלת": true,
"בוחנת": true,
"בוחרה": true,
"בוחרו": true,
"בוחרי": true,
"בוחרך": true,
"בוחרם": true,
"בוחרן": true,
"בוחרת": true,
"בוחשת": true,
"בוטאה": true,
"בוטאו": true,
"בוטאת": true,
"בוטות": true,
"בוטחה": true,
"בוטחו": true,
"בוטחת": true,
"בוטים": true,
"בוטיק": true,
"בוטלה": true,
"בוטלו": true,
"בוטלת": true,
"בוטנה": true,
"בוטנו": true,
"בוטני": true,
"בוטנך": true,
"בוטנם": true,
"בוטנן": true,
"בוטקה": true,
"בוטשת": true,
"בויות": true,
"בויים": true,
"בוילה": true,
"בוילו": true,
"בוילר": true,
"בוילת": true,
"בוימה": true,
"בוימו": true,
"בוימת": true,
"בויסק": true,
"בוישה": true,
"בוישו": true,
"בוישת": true,
"בויתה": true,
"בויתו": true,
"בויתי": true,
"בויתם": true,
"בויתן": true,
"בוכות": true,
"בוכטה": true,
"בוכיה": true,
"בוכים": true,
"בוכנה": true,
"בוכרה": true,
"בוכרי": true,
"בולאי": true,
"בולבל": true,
"בולגן": true,
"בולות": true,
"בולטת": true,
"בוליה": true,
"בוליו": true,
"בוליי": true,
"בוליך": true,
"בולים": true,
"בולית": true,
"בולכם": true,
"בולכן": true,
"בוללת": true,
"בולמת": true,
"בולנד": true,
"בולנו": true,
"בולנס": true,
"בולסת": true,
"בולען": true,
"בולעת": true,
"בולשת": true,
"בומבי": true,
"בומבך": true,
"בומים": true,
"בונאם": true,
"בונדס": true,
"בונהו": true,
"בונוס": true,
"בונות": true,
"בוניה": true,
"בוניו": true,
"בוניי": true,
"בוניך": true,
"בונים": true,
"בונית": true,
"בונכם": true,
"בונכן": true,
"בוננו": true,
"בונפד": true,
"בונקר": true,
"בוסטר": true,
"בוסים": true,
"בוסית": true,
"בוסמה": true,
"בוסמו": true,
"בוסמת": true,
"בוסני": true,
"בוססה": true,
"בוססו": true,
"בוססת": true,
"בוסרה": true,
"בוסרו": true,
"בוסרי": true,
"בוסרך": true,
"בוסרם": true,
"בוסרן": true,
"בוסתן": true,
"בועדת": true,
"בועות": true,
"בועטת": true,
"בועים": true,
"בועית": true,
"בועלת": true,
"בוערה": true,
"בוערו": true,
"בוערת": true,
"בועתה": true,
"בועתו": true,
"בועתי": true,
"בועתך": true,
"בועתם": true,
"בועתן": true,
"בופור": true,
"בופלו": true,
"בוצות": true,
"בוצית": true,
"בוצכם": true,
"בוצכן": true,
"בוצנו": true,
"בוצעה": true,
"בוצעו": true,
"בוצעת": true,
"בוצרה": true,
"בוצרו": true,
"בוצרת": true,
"בוצתה": true,
"בוצתו": true,
"בוצתי": true,
"בוצתך": true,
"בוצתם": true,
"בוצתן": true,
"בוקדו": true,
"בוקסר": true,
"בוקעה": true,
"בוקעו": true,
"בוקעת": true,
"בוקקת": true,
"בוקרה": true,
"בוקרו": true,
"בוקרי": true,
"בוקרך": true,
"בוקרם": true,
"בוקרן": true,
"בוקרת": true,
"בוקשה": true,
"בוקשו": true,
"בוקשת": true,
"בוראה": true,
"בוראו": true,
"בוראי": true,
"בוראך": true,
"בוראם": true,
"בוראן": true,
"בוראק": true,
"בוראת": true,
"בורגה": true,
"בורגו": true,
"בורגי": true,
"בורגך": true,
"בורגם": true,
"בורגן": true,
"בורגר": true,
"בורדו": true,
"בורות": true,
"בורזה": true,
"בורחת": true,
"בוריו": true,
"בוריי": true,
"בורים": true,
"בוריס": true,
"בורית": true,
"בורכה": true,
"בורכו": true,
"בורכם": true,
"בורכן": true,
"בורכת": true,
"בורלא": true,
"בורמה": true,
"בורנה": true,
"בורנו": true,
"בורסה": true,
"בורקס": true,
"בורקת": true,
"בוררה": true,
"בוררו": true,
"בוררי": true,
"בוררך": true,
"בוררם": true,
"בוררן": true,
"בוררת": true,
"בורשט": true,
"בורתו": true,
"בושאר": true,
"בושהר": true,
"בושות": true,
"בושים": true,
"בושלה": true,
"בושלו": true,
"בושלת": true,
"בושמה": true,
"בושמו": true,
"בושמי": true,
"בושמך": true,
"בושמם": true,
"בושמן": true,
"בושמת": true,
"בושנה": true,
"בושנו": true,
"בושרה": true,
"בושרו": true,
"בושרת": true,
"בוששה": true,
"בוששו": true,
"בוששי": true,
"בוששת": true,
"בושתה": true,
"בושתו": true,
"בושתי": true,
"בושתך": true,
"בושתם": true,
"בושתן": true,
"בותקה": true,
"בותקו": true,
"בותקת": true,
"בותרה": true,
"בותרו": true,
"בותרת": true,
"בזאדה": true,
"בזארה": true,
"בזארו": true,
"בזארי": true,
"בזארך": true,
"בזארם": true,
"בזארן": true,
"בזבוז": true,
"בזבזה": true,
"בזבזו": true,
"בזבזי": true,
"בזבזן": true,
"בזבזת": true,
"בזהות": true,
"בזוהר": true,
"בזוזה": true,
"בזויה": true,
"בזוקה": true,
"בזזנו": true,
"בזזתי": true,
"בזזתם": true,
"בזזתן": true,
"בזיהם": true,
"בזיהן": true,
"בזיון": true,
"בזיזה": true,
"בזייך": true,
"בזיכם": true,
"בזיכן": true,
"בזינה": true,
"בזינו": true,
"בזיקה": true,
"בזירה": true,
"בזירת": true,
"בזכות": true,
"בזלתה": true,
"בזלתו": true,
"בזלתי": true,
"בזלתך": true,
"בזלתם": true,
"בזלתן": true,
"בזמנו": true,
"בזמני": true,
"בזנות": true,
"בזקנו": true,
"בזקתי": true,
"בזקתם": true,
"בזקתן": true,
"בזרנה": true,
"בחברה": true,
"בחברת": true,
"בחגים": true,
"בחדות": true,
"בחדרה": true,
"בחדרו": true,
"בחדרי": true,
"בחובה": true,
"בחובו": true,
"בחודש": true,
"בחווה": true,
"בחוות": true,
"בחוזה": true,
"בחולי": true,
"בחומר": true,
"בחוסר": true,
"בחופי": true,
"בחופש": true,
"בחוקי": true,
"בחורה": true,
"בחורו": true,
"בחורי": true,
"בחורך": true,
"בחורם": true,
"בחורן": true,
"בחורף": true,
"בחושה": true,
"בחושך": true,
"בחזון": true,
"בחזית": true,
"בחזקת": true,
"בחזרה": true,
"בחיוב": true,
"בחיוך": true,
"בחיות": true,
"בחייה": true,
"בחייו": true,
"בחייך": true,
"בחיים": true,
"בחילה": true,
"בחינה": true,
"בחינו": true,
"בחינם": true,
"בחינת": true,
"בחיפה": true,
"בחירה": true,
"בחירת": true,
"בחישה": true,
"בחכמה": true,
"בחלום": true,
"בחלון": true,
"בחלוף": true,
"בחלנה": true,
"בחלנו": true,
"בחלקה": true,
"בחלקו": true,
"בחלקי": true,
"בחלקם": true,
"בחלקן": true,
"בחלתי": true,
"בחלתם": true,
"בחלתן": true,
"בחמאס": true,
"בחנות": true,
"בחנים": true,
"בחנתי": true,
"בחנתם": true,
"בחנתן": true,
"בחסות": true,
"בחצור": true,
"בחצות": true,
"בחרדה": true,
"בחרות": true,
"בחרים": true,
"בחרנה": true,
"בחרנו": true,
"בחרתי": true,
"בחרתם": true,
"בחרתן": true,
"בחשאי": true,
"בחשמל": true,
"בחשנה": true,
"בחשנו": true,
"בחשתי": true,
"בחשתם": true,
"בחשתן": true,
"בטאבו": true,
"בטאון": true,
"בטאות": true,
"בטאנה": true,
"בטבלה": true,
"בטבלת": true,
"בטבעת": true,
"בטווח": true,
"בטוחה": true,
"בטופס": true,
"בטחון": true,
"בטחות": true,
"בטחים": true,
"בטחנה": true,
"בטחנו": true,
"בטחתה": true,
"בטחתו": true,
"בטחתי": true,
"בטחתך": true,
"בטחתם": true,
"בטחתן": true,
"בטטות": true,
"בטטתה": true,
"בטטתו": true,
"בטטתי": true,
"בטטתך": true,
"בטטתם": true,
"בטטתן": true,
"בטיבט": true,
"בטיול": true,
"בטיחו": true,
"בטיסה": true,
"בטירה": true,
"בטירת": true,
"בטישה": true,
"בטכני": true,
"בטלות": true,
"בטלים": true,
"בטלנה": true,
"בטלנו": true,
"בטלני": true,
"בטלנך": true,
"בטלנם": true,
"בטלנן": true,
"בטלתה": true,
"בטלתו": true,
"בטלתי": true,
"בטלתך": true,
"בטלתם": true,
"בטלתן": true,
"בטמפר": true,
"בטנות": true,
"בטניה": true,
"בטניו": true,
"בטניי": true,
"בטניך": true,
"בטנים": true,
"בטנכם": true,
"בטנכן": true,
"בטננו": true,
"בטנתה": true,
"בטנתו": true,
"בטנתי": true,
"בטנתך": true,
"בטנתם": true,
"בטנתן": true,
"בטעות": true,
"בטענה": true,
"בטענת": true,
"בטקסט": true,
"בטקסס": true,
"בטרור": true,
"בטריה": true,
"בטשנו": true,
"בטשתי": true,
"בטשתם": true,
"בטשתן": true,
"ביאוס": true,
"ביאור": true,
"ביאות": true,
"ביאים": true,
"ביאסה": true,
"ביאסו": true,
"ביאסי": true,
"ביאסת": true,
"ביארה": true,
"ביארו": true,
"ביארת": true,
"ביאתה": true,
"ביאתו": true,
"ביאתי": true,
"ביאתך": true,
"ביאתם": true,
"ביאתן": true,
"ביבוא": true,
"ביבות": true,
"ביביה": true,
"ביביו": true,
"ביביי": true,
"ביביך": true,
"ביבים": true,
"ביבכם": true,
"ביבכן": true,
"ביבלי": true,
"ביבנה": true,
"ביבנו": true,
"ביברה": true,
"ביברו": true,
"ביברי": true,
"ביברך": true,
"ביברם": true,
"ביברן": true,
"ביבשה": true,
"ביבשת": true,
"ביבתו": true,
"ביגוד": true,
"ביגרה": true,
"ביגרו": true,
"ביגרת": true,
"בידוד": true,
"בידוי": true,
"בידול": true,
"בידוק": true,
"בידור": true,
"בידיה": true,
"בידיו": true,
"בידית": true,
"בידכם": true,
"בידלה": true,
"בידלו": true,
"בידלת": true,
"בידרה": true,
"בידרו": true,
"בידרת": true,
"בידתה": true,
"ביהוד": true,
"ביהמש": true,
"ביובה": true,
"ביובו": true,
"ביובי": true,
"ביובך": true,
"ביובם": true,
"ביובן": true,
"ביוון": true,
"ביולה": true,
"ביולו": true,
"ביולי": true,
"ביולך": true,
"ביולם": true,
"ביולן": true,
"ביומה": true,
"ביומו": true,
"ביומי": true,
"ביומך": true,
"ביומם": true,
"ביומן": true,
"ביוני": true,
"ביוצה": true,
"ביוצו": true,
"ביוצי": true,
"ביוצך": true,
"ביוצם": true,
"ביוצן": true,
"ביוקר": true,
"ביושה": true,
"ביושו": true,
"ביושי": true,
"ביושך": true,
"ביושם": true,
"ביושן": true,
"ביושר": true,
"ביותה": true,
"ביותו": true,
"ביותי": true,
"ביותך": true,
"ביותם": true,
"ביותן": true,
"ביותר": true,
"ביזוי": true,
"ביזון": true,
"ביזור": true,
"ביזות": true,
"ביזטק": true,
"ביזיה": true,
"ביזית": true,
"ביזנס": true,
"ביזרה": true,
"ביזרו": true,
"ביזרי": true,
"ביזרת": true,
"ביזתה": true,
"ביזתו": true,
"ביזתי": true,
"ביזתך": true,
"ביזתם": true,
"ביזתן": true,
"ביחוד": true,
"ביחסי": true,
"ביטאה": true,
"ביטאו": true,
"ביטאת": true,
"ביטוח": true,
"ביטוי": true,
"ביטול": true,
"ביטון": true,
"ביטחה": true,
"ביטחו": true,
"ביטחת": true,
"ביטים": true,
"ביטלה": true,
"ביטלו": true,
"ביטלת": true,
"בייבי": true,
"בייגה": true,
"בייגל": true,
"ביידר": true,
"ביילה": true,
"ביילו": true,
"ביילי": true,
"ביילת": true,
"ביימה": true,
"ביימו": true,
"ביימי": true,
"ביימת": true,
"בייצה": true,
"בייצו": true,
"בייצי": true,
"בייצת": true,
"ביישה": true,
"ביישו": true,
"ביישי": true,
"ביישן": true,
"ביישת": true,
"בייתה": true,
"בייתו": true,
"בייתי": true,
"בייתם": true,
"בייתן": true,
"ביכול": true,
"ביכור": true,
"ביכית": true,
"ביכנס": true,
"ביכרה": true,
"ביכרו": true,
"ביכרת": true,
"ביכתה": true,
"בילוי": true,
"בילוף": true,
"בילוש": true,
"בילות": true,
"ביליג": true,
"ביליה": true,
"ביליז": true,
"בילים": true,
"בילית": true,
"בילפה": true,
"בילפו": true,
"בילפת": true,
"בילתה": true,
"בימוי": true,
"בימות": true,
"בימיו": true,
"בימים": true,
"בימין": true,
"ביממה": true,
"בימתה": true,
"בימתו": true,
"בימתי": true,
"בימתך": true,
"בימתם": true,
"בימתן": true,
"בינהם": true,
"בינוי": true,
"בינות": true,
"בינים": true,
"ביניש": true,
"בינלא": true,
"ביננו": true,
"בינרי": true,
"בינתה": true,
"בינתו": true,
"בינתי": true,
"בינתך": true,
"בינתם": true,
"בינתן": true,
"ביסוד": true,
"ביסום": true,
"ביסוס": true,
"ביסמה": true,
"ביסמו": true,
"ביסמת": true,
"ביססה": true,
"ביססו": true,
"ביססת": true,
"ביעור": true,
"ביעות": true,
"ביעזר": true,
"ביעים": true,
"ביערה": true,
"ביערו": true,
"ביערת": true,
"ביעתה": true,
"ביעתו": true,
"ביפרה": true,
"ביפרו": true,
"ביפרי": true,
"ביפרך": true,
"ביפרם": true,
"ביפרן": true,
"ביצוע": true,
"ביצור": true,
"ביצות": true,
"ביציה": true,
"ביציו": true,
"ביציי": true,
"ביציך": true,
"ביצים": true,
"ביצית": true,
"ביצעה": true,
"ביצעו": true,
"ביצעת": true,
"ביצרה": true,
"ביצרו": true,
"ביצרת": true,
"ביצתה": true,
"ביצתו": true,
"ביצתי": true,
"ביצתך": true,
"ביצתם": true,
"ביצתן": true,
"ביקום": true,
"ביקוע": true,
"ביקור": true,
"ביקוש": true,
"ביקעה": true,
"ביקעו": true,
"ביקעת": true,
"ביקרה": true,
"ביקרו": true,
"ביקרת": true,
"ביקשה": true,
"ביקשו": true,
"ביקשת": true,
"ביראה": true,
"ביראו": true,
"ביראת": true,
"בירדן": true,
"בירוא": true,
"בירוק": true,
"בירור": true,
"בירוש": true,
"בירות": true,
"בירזל": true,
"ביריד": true,
"בירים": true,
"בירית": true,
"בירכה": true,
"בירכו": true,
"בירכת": true,
"בירעם": true,
"ביררה": true,
"ביררו": true,
"ביררת": true,
"בירתה": true,
"בירתו": true,
"בירתי": true,
"בירתך": true,
"בירתם": true,
"בירתן": true,
"בישוב": true,
"בישול": true,
"בישום": true,
"בישוף": true,
"בישור": true,
"בישלה": true,
"בישלו": true,
"בישלת": true,
"בישמה": true,
"בישמו": true,
"בישמת": true,
"בישקק": true,
"בישרה": true,
"בישרו": true,
"בישרת": true,
"ביתוק": true,
"ביתור": true,
"ביתית": true,
"ביתכם": true,
"ביתכן": true,
"ביתנה": true,
"ביתנו": true,
"ביתני": true,
"ביתנך": true,
"ביתנם": true,
"ביתנן": true,
"ביתקה": true,
"ביתקו": true,
"ביתקת": true,
"ביתרה": true,
"ביתרו": true,
"ביתרי": true,
"ביתרת": true,
"בכאבי": true,
"בכבוד": true,
"בכביש": true,
"בכדור": true,
"בכובד": true,
"בכוחה": true,
"בכוחו": true,
"בכוחם": true,
"בכוכב": true,
"בכולם": true,
"בכורה": true,
"בכורו": true,
"בכורי": true,
"בכורך": true,
"בכורם": true,
"בכורן": true,
"בכושר": true,
"בכותל": true,
"בכזאת": true,
"בכחול": true,
"בכיות": true,
"בכייה": true,
"בכייך": true,
"בכיים": true,
"בכיין": true,
"בכיכר": true,
"בכינה": true,
"בכינו": true,
"בכירה": true,
"בכירו": true,
"בכירי": true,
"בכירך": true,
"בכירם": true,
"בכירן": true,
"בכיתי": true,
"בכיתם": true,
"בכיתן": true,
"בכליו": true,
"בכלים": true,
"בכללי": true,
"בכמות": true,
"בכנות": true,
"בכנסת": true,
"בכנרת": true,
"בכסלו": true,
"בכספי": true,
"בכפוף": true,
"בכפות": true,
"בכרמל": true,
"בכרנה": true,
"בכתבה": true,
"בכתבי": true,
"בלאות": true,
"בלאטה": true,
"בלבדי": true,
"בלבול": true,
"בלבוש": true,
"בלבלה": true,
"בלבלו": true,
"בלבלי": true,
"בלבלת": true,
"בלבני": true,
"בלגיה": true,
"בלגים": true,
"בלגית": true,
"בלגנה": true,
"בלגנו": true,
"בלגני": true,
"בלגנת": true,
"בלגרד": true,
"בלדות": true,
"בלדרה": true,
"בלדרו": true,
"בלדרי": true,
"בלדרך": true,
"בלדרם": true,
"בלדרן": true,
"בלדתה": true,
"בלדתו": true,
"בלדתי": true,
"בלדתך": true,
"בלדתם": true,
"בלדתן": true,
"בלהות": true,
"בלהקה": true,
"בלהקת": true,
"בלהתה": true,
"בלהתו": true,
"בלהתי": true,
"בלהתך": true,
"בלהתם": true,
"בלהתן": true,
"בלובי": true,
"בלוגה": true,
"בלוגו": true,
"בלוגי": true,
"בלוגך": true,
"בלוגם": true,
"בלוגן": true,
"בלוגר": true,
"בלוטה": true,
"בלוטו": true,
"בלוטי": true,
"בלוטך": true,
"בלוטם": true,
"בלוטן": true,
"בלוטת": true,
"בלויה": true,
"בלויי": true,
"בלולה": true,
"בלומה": true,
"בלונד": true,
"בלונה": true,
"בלונו": true,
"בלוני": true,
"בלונך": true,
"בלונם": true,
"בלונן": true,
"בלוסה": true,
"בלופה": true,
"בלופו": true,
"בלופי": true,
"בלופך": true,
"בלופם": true,
"בלופן": true,
"בלוקה": true,
"בלוקו": true,
"בלוקי": true,
"בלוקך": true,
"בלוקם": true,
"בלוקן": true,
"בלזאק": true,
"בלטות": true,
"בלטים": true,
"בלטית": true,
"בלטנו": true,
"בלטתה": true,
"בלטתו": true,
"בלטתי": true,
"בלטתך": true,
"בלטתם": true,
"בלטתן": true,
"בליאן": true,
"בליבה": true,
"בליבו": true,
"בליגה": true,
"בליגת": true,
"בלידה": true,
"בליות": true,
"בליזי": true,
"בליטה": true,
"בלייה": true,
"בלייז": true,
"בלייך": true,
"בליין": true,
"בלייר": true,
"בלילה": true,
"בלילו": true,
"בלילי": true,
"בלילך": true,
"בלילם": true,
"בלילן": true,
"בלילץ": true,
"בלימה": true,
"בלינה": true,
"בלינו": true,
"בליני": true,
"בלינץ": true,
"בלינק": true,
"בליסה": true,
"בליעה": true,
"בליתי": true,
"בליתם": true,
"בליתן": true,
"בללנו": true,
"בללתי": true,
"בללתם": true,
"בללתן": true,
"בלמיה": true,
"בלמיו": true,
"בלמיי": true,
"בלמיך": true,
"בלמים": true,
"בלמכם": true,
"בלמכן": true,
"בלמנו": true,
"בלמתי": true,
"בלמתם": true,
"בלמתן": true,
"בלנדר": true,
"בלנות": true,
"בלניה": true,
"בלניו": true,
"בלניי": true,
"בלניך": true,
"בלנים": true,
"בלנית": true,
"בלנכם": true,
"בלנכן": true,
"בלננו": true,
"בלנקה": true,
"בלנקו": true,
"בלסמי": true,
"בלסנו": true,
"בלסתי": true,
"בלסתם": true,
"בלסתן": true,
"בלעדי": true,
"בלעין": true,
"בלענה": true,
"בלענו": true,
"בלעתי": true,
"בלעתם": true,
"בלעתן": true,
"בלפור": true,
"בלפנה": true,
"בלפסט": true,
"בלקני": true,
"בלרוס": true,
"בלשון": true,
"בלשיה": true,
"בלשיו": true,
"בלשיי": true,
"בלשיך": true,
"בלשים": true,
"בלשית": true,
"בלשכה": true,
"בלשכם": true,
"בלשכן": true,
"בלשכת": true,
"בלשנה": true,
"בלשנו": true,
"בלשני": true,
"בלשנך": true,
"בלשנם": true,
"בלשנן": true,
"בלשצר": true,
"בלשתי": true,
"בלשתם": true,
"בלשתן": true,
"בלתכם": true,
"בלתכן": true,
"בלתנו": true,
"במאבק": true,
"במאגר": true,
"במאור": true,
"במאות": true,
"במאזן": true,
"במאיה": true,
"במאיו": true,
"במאיי": true,
"במאיך": true,
"במאים": true,
"במאית": true,
"במאכם": true,
"במאכן": true,
"במאמץ": true,
"במאמר": true,
"במאנו": true,
"במארס": true,
"במבוא": true,
"במבוק": true,
"במבחן": true,
"במבחר": true,
"במבנה": true,
"במבצע": true,
"במגדל": true,
"במגזר": true,
"במגמה": true,
"במגמת": true,
"במגרש": true,
"במדבר": true,
"במדור": true,
"במדיה": true,
"במדים": true,
"במדעי": true,
"במדרי": true,
"במדרש": true,
"במהות": true,
"במהלך": true,
"במהרה": true,
"במובן": true,
"במודל": true,
"במודע": true,
"במוות": true,
"במוחו": true,
"במונח": true,
"במוסד": true,
"במוסך": true,
"במוסף": true,
"במוסק": true,
"במועד": true,
"במופע": true,
"במוצר": true,
"במוקד": true,
"במורד": true,
"במושב": true,
"במושג": true,
"במותו": true,
"במזון": true,
"במזיד": true,
"במזרח": true,
"במחאה": true,
"במחבת": true,
"במחוז": true,
"במחזה": true,
"במחיצ": true,
"במחיר": true,
"במחלה": true,
"במחלת": true,
"במחנה": true,
"במחסן": true,
"במחקר": true,
"במחשב": true,
"במטבח": true,
"במטבע": true,
"במטוס": true,
"במטרה": true,
"במידה": true,
"במידע": true,
"במידת": true,
"במיזם": true,
"במיטב": true,
"במיטה": true,
"במייל": true,
"במילה": true,
"במילי": true,
"במינה": true,
"במינו": true,
"במיני": true,
"במכבי": true,
"במכון": true,
"במכנס": true,
"במכרז": true,
"במכתב": true,
"במלאי": true,
"במלאת": true,
"במלוא": true,
"במלון": true,
"במלים": true,
"בממשל": true,
"בממשק": true,
"במנהל": true,
"במנוע": true,
"במנות": true,
"במנזר": true,
"במסגת": true,
"במסחר": true,
"במסמך": true,
"במספר": true,
"במעבד": true,
"במעבר": true,
"במעגל": true,
"במעון": true,
"במעין": true,
"במעלה": true,
"במעמד": true,
"במענה": true,
"במעצר": true,
"במעקב": true,
"במערב": true,
"במערה": true,
"במערך": true,
"במערת": true,
"במעשה": true,
"במעשי": true,
"במפגש": true,
"במפעל": true,
"במפרץ": true,
"במצבה": true,
"במצבו": true,
"במצבי": true,
"במצות": true,
"במצעד": true,
"במצפה": true,
"במקדש": true,
"במקום": true,
"במקור": true,
"במקצת": true,
"במקרא": true,
"במקרה": true,
"במקרי": true,
"במקרר": true,
"במראה": true,
"במרוץ": true,
"במרחב": true,
"במרחק": true,
"במרכז": true,
"במרמה": true,
"במרקם": true,
"במרשם": true,
"במרתף": true,
"במשבר": true,
"במשהו": true,
"במשחק": true,
"במשטר": true,
"במשכן": true,
"במשמר": true,
"במשנה": true,
"במשפט": true,
"במשקל": true,
"במשרד": true,
"במשרה": true,
"במתחם": true,
"במתכם": true,
"במתכן": true,
"במתנה": true,
"במתנו": true,
"במתקן": true,
"בנאדם": true,
"בנאום": true,
"בנאות": true,
"בנאיה": true,
"בנאיו": true,
"בנאיי": true,
"בנאיך": true,
"בנאים": true,
"בנאית": true,
"בנאכם": true,
"בנאכן": true,
"בנאמה": true,
"בנאמו": true,
"בנאמי": true,
"בנאמת": true,
"בנאנו": true,
"בנגמש": true,
"בנדון": true,
"בנוגע": true,
"בנווה": true,
"בנויה": true,
"בנוסח": true,
"בנוסף": true,
"בנושא": true,
"בנזין": true,
"בנחלת": true,
"בניאס": true,
"בניהו": true,
"בניהם": true,
"בניהן": true,
"בניון": true,
"בניות": true,
"בנייד": true,
"בנייה": true,
"בנייך": true,
"בניין": true,
"בנייר": true,
"בניית": true,
"בניכם": true,
"בניכן": true,
"בנימה": true,
"בנינה": true,
"בנינו": true,
"בניסן": true,
"בניתי": true,
"בניתם": true,
"בניתן": true,
"בנלית": true,
"בנמצא": true,
"בננות": true,
"בננתה": true,
"בננתו": true,
"בננתי": true,
"בננתך": true,
"בננתם": true,
"בננתן": true,
"בנספח": true,
"בנעשה": true,
"בנפטא": true,
"בנפרד": true,
"בנפשו": true,
"בנקאה": true,
"בנקאו": true,
"בנקאי": true,
"בנקאך": true,
"בנקאם": true,
"בנקאן": true,
"בנקיה": true,
"בנקיו": true,
"בנקיי": true,
"בנקיך": true,
"בנקים": true,
"בנקכם": true,
"בנקכן": true,
"בנקנו": true,
"בנשים": true,
"בנשמה": true,
"בנתיב": true,
"בסאאל": true,
"בסביב": true,
"בסדנא": true,
"בסדנה": true,
"בסדנת": true,
"בסדרה": true,
"בסדרי": true,
"בסדרת": true,
"בסוגי": true,
"בסוכה": true,
"בסולם": true,
"בסונה": true,
"בסונו": true,
"בסוני": true,
"בסונך": true,
"בסונם": true,
"בסונן": true,
"בסופה": true,
"בסופו": true,
"בסופי": true,
"בסופר": true,
"בסוצי": true,
"בסזון": true,
"בסטות": true,
"בסיום": true,
"בסיוע": true,
"בסיור": true,
"בסימן": true,
"בסיני": true,
"בסיסה": true,
"בסיסו": true,
"בסיסט": true,
"בסיסי": true,
"בסיסך": true,
"בסיסם": true,
"בסיסן": true,
"בסירה": true,
"בסכום": true,
"בסכין": true,
"בסכנה": true,
"בסכנת": true,
"בסלול": true,
"בסלון": true,
"בסמוך": true,
"בסמים": true,
"בסמנה": true,
"בסנטה": true,
"בססנה": true,
"בסעיף": true,
"בסערה": true,
"בספרד": true,
"בספרה": true,
"בספרו": true,
"בספרי": true,
"בסצנה": true,
"בסקים": true,
"בסקית": true,
"בסרטו": true,
"בסרטי": true,
"בסרטן": true,
"בסריה": true,
"בסריו": true,
"בסריי": true,
"בסריך": true,
"בסרים": true,
"בסתיו": true,
"בעבוד": true,
"בעבוע": true,
"בעבור": true,
"בעבעה": true,
"בעבעו": true,
"בעבעי": true,
"בעבעת": true,
"בעברה": true,
"בעברו": true,
"בעגלה": true,
"בעדות": true,
"בעדכם": true,
"בעדכן": true,
"בעדנו": true,
"בעדני": true,
"בעובי": true,
"בעודה": true,
"בעודו": true,
"בעודי": true,
"בעודם": true,
"בעוטה": true,
"בעוטף": true,
"בעולה": true,
"בעולם": true,
"בעומס": true,
"בעומק": true,
"בעומר": true,
"בעונה": true,
"בעוני": true,
"בעונש": true,
"בעונת": true,
"בעורך": true,
"בעורף": true,
"בעזרה": true,
"בעזרת": true,
"בעטנה": true,
"בעטנו": true,
"בעטתי": true,
"בעטתם": true,
"בעטתן": true,
"בעידן": true,
"בעיון": true,
"בעיות": true,
"בעיטה": true,
"בעייה": true,
"בעיית": true,
"בעילה": true,
"בעיני": true,
"בעיקר": true,
"בעירה": true,
"בעכבר": true,
"בעלות": true,
"בעליה": true,
"בעליו": true,
"בעליי": true,
"בעליך": true,
"בעליל": true,
"בעלים": true,
"בעלכם": true,
"בעלכן": true,
"בעלמא": true,
"בעלנה": true,
"בעלנו": true,
"בעלתי": true,
"בעלתם": true,
"בעלתן": true,
"בעמדה": true,
"בעמדת": true,
"בעמוד": true,
"בענין": true,
"בענפי": true,
"בעסקה": true,
"בעסקי": true,
"בעסקת": true,
"בעצמה": true,
"בעצמו": true,
"בעצמי": true,
"בעצמך": true,
"בעצמם": true,
"בעצמן": true,
"בעצרת": true,
"בערבה": true,
"בערבי": true,
"בערוץ": true,
"בערות": true,
"בערים": true,
"בערכי": true,
"בערנה": true,
"בערנו": true,
"בערפל": true,
"בערתה": true,
"בערתו": true,
"בערתי": true,
"בערתך": true,
"בערתם": true,
"בערתן": true,
"בעשור": true,
"בעשרה": true,
"בעשרת": true,
"בעתות": true,
"בעתיד": true,
"בעתתה": true,
"בעתתו": true,
"בעתתי": true,
"בעתתך": true,
"בעתתם": true,
"בעתתן": true,
"בפאנל": true,
"בפארק": true,
"בפאתח": true,
"בפאתי": true,
"בפוסט": true,
"בפועל": true,
"בפורט": true,
"בפחות": true,
"בפיהם": true,
"בפינה": true,
"בפינת": true,
"בפלדה": true,
"בפלור": true,
"בפניה": true,
"בפניו": true,
"בפניי": true,
"בפניך": true,
"בפנים": true,
"בפנקס": true,
"בפסגת": true,
"בפסוק": true,
"בפסיכ": true,
"בפסקה": true,
"בפראג": true,
"בפרדס": true,
"בפרטי": true,
"בפריז": true,
"בפרקי": true,
"בפרשה": true,
"בפרשת": true,
"בצבוץ": true,
"בצבעי": true,
"בצבצה": true,
"בצבצו": true,
"בצבצי": true,
"בצבצת": true,
"בצהרי": true,
"בצוות": true,
"בצומת": true,
"בצועה": true,
"בצורה": true,
"בצורך": true,
"בצורת": true,
"בצחוק": true,
"בצידה": true,
"בצידו": true,
"בצידי": true,
"בציוד": true,
"בציון": true,
"בציור": true,
"בצימר": true,
"בציעה": true,
"בצירה": true,
"בצירו": true,
"בצירי": true,
"בצירך": true,
"בצירם": true,
"בצירן": true,
"בצלאל": true,
"בצלחת": true,
"בצליה": true,
"בצליו": true,
"בצליי": true,
"בצליך": true,
"בצלים": true,
"בצלכם": true,
"בצלכן": true,
"בצלנו": true,
"בצלצל": true,
"בצמוד": true,
"בצמרת": true,
"בצנרת": true,
"בצעדי": true,
"בצענה": true,
"בצענו": true,
"בצעתי": true,
"בצעתם": true,
"בצעתן": true,
"בצפון": true,
"בצקות": true,
"בצקיה": true,
"בצקיו": true,
"בצקיי": true,
"בצקיך": true,
"בצקים": true,
"בצקית": true,
"בצקכם": true,
"בצקכן": true,
"בצקנו": true,
"בצקתה": true,
"בצקתו": true,
"בצקתי": true,
"בצקתך": true,
"בצקתם": true,
"בצקתן": true,
"בצרות": true,
"בצרנה": true,
"בצרנו": true,
"בצרפת": true,
"בצרתי": true,
"בצרתם": true,
"בצרתן": true,
"בקאיי": true,
"בקבוק": true,
"בקבלה": true,
"בקבלת": true,
"בקבקה": true,
"בקבקו": true,
"בקבקי": true,
"בקבקת": true,
"בקדמת": true,
"בקהיר": true,
"בקובה": true,
"בקובץ": true,
"בקודש": true,
"בקווי": true,
"בקוטר": true,
"בקולג": true,
"בקולו": true,
"בקומה": true,
"בקומת": true,
"בקונס": true,
"בקונצ": true,
"בקופה": true,
"בקופת": true,
"בקוצר": true,
"בקורס": true,
"בקושי": true,
"בקטנה": true,
"בקיאה": true,
"בקיבה": true,
"בקיום": true,
"בקיעה": true,
"בקיעו": true,
"בקיעי": true,
"בקיעך": true,
"בקיעם": true,
"בקיען": true,
"בקלות": true,
"בקליפ": true,
"בקלפי": true,
"בקנדה": true,
"בקניה": true,
"בקעות": true,
"בקעיה": true,
"בקעיו": true,
"בקעיי": true,
"בקעיך": true,
"בקעים": true,
"בקעכם": true,
"בקעכן": true,
"בקענה": true,
"בקענו": true,
"בקערה": true,
"בקעתה": true,
"בקעתו": true,
"בקעתי": true,
"בקעתך": true,
"בקעתם": true,
"בקעתן": true,
"בקצרה": true,
"בקרבה": true,
"בקרבו": true,
"בקרבם": true,
"בקרבת": true,
"בקרוב": true,
"בקרות": true,
"בקריה": true,
"בקריו": true,
"בקריי": true,
"בקריך": true,
"בקרים": true,
"בקרית": true,
"בקרכם": true,
"בקרכן": true,
"בקרנה": true,
"בקרנו": true,
"בקרני": true,
"בקרצר": true,
"בקרקע": true,
"בקרתה": true,
"בקרתו": true,
"בקרתי": true,
"בקרתך": true,
"בקרתם": true,
"בקרתן": true,
"בקשות": true,
"בקשים": true,
"בקשיש": true,
"בקשנה": true,
"בקשרי": true,
"בקשתה": true,
"בקשתו": true,
"בקשתי": true,
"בקשתך": true,
"בקשתם": true,
"בקשתן": true,
"בקתות": true,
"בקתתה": true,
"בקתתו": true,
"בקתתי": true,
"בקתתך": true,
"בקתתם": true,
"בקתתן": true,
"בראון": true,
"בראות": true,
"בראיה": true,
"בראכה": true,
"בראלי": true,
"בראנה": true,
"בראנו": true,
"בראשה": true,
"בראשו": true,
"בראשי": true,
"בראשל": true,
"בראשם": true,
"בראתי": true,
"בראתם": true,
"בראתן": true,
"ברבור": true,
"ברבות": true,
"ברבים": true,
"ברברה": true,
"ברברו": true,
"ברברי": true,
"ברברת": true,
"ברגיה": true,
"ברגיו": true,
"ברגיי": true,
"ברגיך": true,
"ברגים": true,
"ברגלס": true,
"ברגמן": true,
"ברגנר": true,
"ברגעי": true,
"ברדיו": true,
"ברדכם": true,
"ברדכן": true,
"ברדלס": true,
"ברדנו": true,
"ברדסה": true,
"ברדסו": true,
"ברדסי": true,
"ברדסך": true,
"ברדסם": true,
"ברדסן": true,
"ברהמס": true,
"ברואה": true,
"ברואר": true,
"ברובד": true,
"ברובה": true,
"ברובו": true,
"ברובם": true,
"ברובן": true,
"ברובע": true,
"ברודט": true,
"ברודי": true,
"ברווז": true,
"ברווח": true,
"ברוזה": true,
"ברוחב": true,
"ברוטב": true,
"ברוטו": true,
"ברוכה": true,
"ברומא": true,
"ברומי": true,
"ברומן": true,
"ברונו": true,
"ברונר": true,
"ברוקי": true,
"ברוקר": true,
"ברורה": true,
"ברורו": true,
"ברורי": true,
"ברורך": true,
"ברורם": true,
"ברורן": true,
"ברושה": true,
"ברושו": true,
"ברושי": true,
"ברושך": true,
"ברושם": true,
"ברושן": true,
"ברותי": true,
"ברותם": true,
"ברותן": true,
"ברזיה": true,
"ברזיו": true,
"ברזיי": true,
"ברזיך": true,
"ברזיל": true,
"ברזים": true,
"ברזכם": true,
"ברזכן": true,
"ברזלה": true,
"ברזלו": true,
"ברזלי": true,
"ברזלך": true,
"ברזלם": true,
"ברזלן": true,
"ברזנו": true,
"ברחבי": true,
"ברחבת": true,
"ברחוב": true,
"ברחנה": true,
"ברחנו": true,
"ברחשה": true,
"ברחשו": true,
"ברחשי": true,
"ברחשך": true,
"ברחשם": true,
"ברחשן": true,
"ברחתי": true,
"ברחתם": true,
"ברחתן": true,
"ברטון": true,
"בריאה": true,
"בריאן": true,
"בריאת": true,
"ברידג": true,
"בריהם": true,
"בריהן": true,
"בריום": true,
"בריון": true,
"בריות": true,
"בריזה": true,
"בריחה": true,
"בריחו": true,
"בריחי": true,
"בריחך": true,
"בריחם": true,
"בריחן": true,
"בריחת": true,
"בריטי": true,
"ברייה": true,
"ברייך": true,
"ברייל": true,
"ברייר": true,
"בריכה": true,
"בריכם": true,
"בריכן": true,
"בריכת": true,
"ברינו": true,
"בריסל": true,
"בריצה": true,
"ברירה": true,
"ברירת": true,
"בריתה": true,
"בריתו": true,
"בריתי": true,
"בריתך": true,
"בריתם": true,
"בריתן": true,
"ברכבו": true,
"ברכבת": true,
"ברכוש": true,
"ברכות": true,
"ברכיה": true,
"ברכיו": true,
"ברכיי": true,
"ברכיך": true,
"ברכים": true,
"ברככם": true,
"ברככן": true,
"ברכנה": true,
"ברכנו": true,
"ברכתה": true,
"ברכתו": true,
"ברכתי": true,
"ברכתך": true,
"ברכתם": true,
"ברכתן": true,
"ברלין": true,
"ברלנד": true,
"ברמבי": true,
"ברמות": true,
"ברמלה": true,
"ברנדה": true,
"ברנדי": true,
"ברנרד": true,
"ברנשה": true,
"ברנשו": true,
"ברנשי": true,
"ברנשך": true,
"ברנשם": true,
"ברנשן": true,
"ברסלב": true,
"ברעהו": true,
"ברצון": true,
"ברקאי": true,
"ברקוד": true,
"ברקות": true,
"ברקיה": true,
"ברקיו": true,
"ברקיי": true,
"ברקיך": true,
"ברקים": true,
"ברקין": true,
"ברקכם": true,
"ברקכן": true,
"ברקלי": true,
"ברקנה": true,
"ברקנו": true,
"ברקני": true,
"ברקנך": true,
"ברקנם": true,
"ברקנן": true,
"ברקתה": true,
"ברקתו": true,
"ברקתי": true,
"ברקתך": true,
"ברקתם": true,
"ברקתן": true,
"בררות": true,
"בררנה": true,
"בררנו": true,
"בררני": true,
"בררתה": true,
"בררתו": true,
"בררתי": true,
"בררתך": true,
"בררתם": true,
"בררתן": true,
"ברשות": true,
"ברתית": true,
"בשאלה": true,
"בשאלת": true,
"בשבוע": true,
"בשביל": true,
"בשביע": true,
"בשבעה": true,
"בשבתו": true,
"בשגרה": true,
"בשגרת": true,
"בשדות": true,
"בשואה": true,
"בשוגג": true,
"בשוהם": true,
"בשווה": true,
"בשווי": true,
"בשולי": true,
"בשומן": true,
"בשונה": true,
"בשופר": true,
"בשורה": true,
"בשורש": true,
"בשורת": true,
"בשחור": true,
"בשטחה": true,
"בשטחי": true,
"בשיאה": true,
"בשיאו": true,
"בשיבא": true,
"בשיחה": true,
"בשיחת": true,
"בשיטה": true,
"בשיטת": true,
"בשימו": true,
"בשינה": true,
"בשיער": true,
"בשירה": true,
"בשירת": true,
"בשישה": true,
"בשישי": true,
"בשכבה": true,
"בשכבת": true,
"בשלבי": true,
"בשלהי": true,
"בשלום": true,
"בשלוש": true,
"בשלות": true,
"בשליט": true,
"בשלים": true,
"בשליש": true,
"בשלנה": true,
"בשמאל": true,
"בשמות": true,
"בשמחה": true,
"בשמחת": true,
"בשמיה": true,
"בשמיו": true,
"בשמיי": true,
"בשמיך": true,
"בשמים": true,
"בשמלה": true,
"בשמנה": true,
"בשנהל": true,
"בשנות": true,
"בשניה": true,
"בשנים": true,
"בשנית": true,
"בשעון": true,
"בשעות": true,
"בשערי": true,
"בשעתו": true,
"בשפדן": true,
"בשפות": true,
"בשפלה": true,
"בשקית": true,
"בשרון": true,
"בשרות": true,
"בשריה": true,
"בשריו": true,
"בשריי": true,
"בשריך": true,
"בשרים": true,
"בשרית": true,
"בשרכם": true,
"בשרכן": true,
"בשרנה": true,
"בשרנו": true,
"בשרני": true,
"בתאים": true,
"בתואר": true,
"בתובע": true,
"בתודה": true,
"בתוכה": true,
"בתוכו": true,
"בתוכי": true,
"בתוכם": true,
"בתוכן": true,
"בתולה": true,
"בתולי": true,
"בתוקף": true,
"בתורה": true,
"בתורו": true,
"בתורת": true,
"בתחום": true,
"בתחלה": true,
"בתחנה": true,
"בתחנת": true,
"בתיבה": true,
"בתיבת": true,
"בתיהם": true,
"בתיהן": true,
"בתייך": true,
"בתיכם": true,
"בתיכן": true,
"בתימן": true,
"בתינו": true,
"בתיעה": true,
"בתיקו": true,
"בתיקי": true,
"בתלמי": true,
"בתמוז": true,
"בתנאי": true,
"בתנור": true,
"בתקנה": true,
"בתרנה": true,
"בתשחץ": true,
"בתשעה": true,
"בתשרי": true,
"גאדיד": true,
"גאודי": true,
"גאווה": true,
"גאוות": true,
"גאולה": true,
"גאולת": true,
"גאונה": true,
"גאונו": true,
"גאוני": true,
"גאונך": true,
"גאונם": true,
"גאונן": true,
"גאוסי": true,
"גאותה": true,
"גאותו": true,
"גאותי": true,
"גאותך": true,
"גאותם": true,
"גאותן": true,
"גאינה": true,
"גאינו": true,
"גאיתי": true,
"גאיתם": true,
"גאיתן": true,
"גאלאל": true,
"גאללה": true,
"גאלנה": true,
"גאלנו": true,
"גאלתי": true,
"גאלתם": true,
"גאלתן": true,
"גאנון": true,
"גארוב": true,
"גבאיה": true,
"גבאיו": true,
"גבאיי": true,
"גבאיך": true,
"גבאים": true,
"גבאכם": true,
"גבאכן": true,
"גבאנו": true,
"גבארה": true,
"גבבות": true,
"גבבנה": true,
"גבבתה": true,
"גבבתו": true,
"גבבתי": true,
"גבבתך": true,
"גבבתם": true,
"גבבתן": true,
"גבהות": true,
"גבהיה": true,
"גבהיו": true,
"גבהיי": true,
"גבהיך": true,
"גבהים": true,
"גבהנה": true,
"גבהנו": true,
"גבהתי": true,
"גבהתם": true,
"גבהתן": true,
"גבוהה": true,
"גבויה": true,
"גבולה": true,
"גבולו": true,
"גבולי": true,
"גבולך": true,
"גבולם": true,
"גבולן": true,
"גבוני": true,
"גבורה": true,
"גבחות": true,
"גבחתה": true,
"גבחתו": true,
"גבחתי": true,
"גבחתך": true,
"גבחתם": true,
"גבחתן": true,
"גביהה": true,
"גביהם": true,
"גביהן": true,
"גביות": true,
"גבייה": true,
"גבייך": true,
"גביים": true,
"גביית": true,
"גביכם": true,
"גביכן": true,
"גבינה": true,
"גבינו": true,
"גבינת": true,
"גביעה": true,
"גביעו": true,
"גביעי": true,
"גביעך": true,
"גביעם": true,
"גביען": true,
"גבירה": true,
"גבירו": true,
"גבירי": true,
"גבירך": true,
"גבירם": true,
"גבירן": true,
"גבישה": true,
"גבישו": true,
"גבישי": true,
"גבישך": true,
"גבישם": true,
"גבישן": true,
"גביתי": true,
"גביתם": true,
"גביתן": true,
"גבליה": true,
"גבלנו": true,
"גבלתי": true,
"גבלתם": true,
"גבלתן": true,
"גבנון": true,
"גבסיה": true,
"גבסיו": true,
"גבסיי": true,
"גבסיך": true,
"גבסים": true,
"גבסכם": true,
"גבסכן": true,
"גבסנה": true,
"גבסנו": true,
"גבעול": true,
"גבעון": true,
"גבעות": true,
"גבעכם": true,
"גבעכן": true,
"גבענו": true,
"גבעתה": true,
"גבעתו": true,
"גבעתי": true,
"גבעתך": true,
"גבעתם": true,
"גבעתן": true,
"גברבר": true,
"גברות": true,
"גבריה": true,
"גבריו": true,
"גבריט": true,
"גבריי": true,
"גבריך": true,
"גבריל": true,
"גברים": true,
"גברית": true,
"גברכם": true,
"גברכן": true,
"גברנו": true,
"גברתה": true,
"גברתו": true,
"גברתי": true,
"גברתך": true,
"גברתם": true,
"גברתן": true,
"גבשוש": true,
"גבשנה": true,
"גבתכם": true,
"גבתכן": true,
"גבתנו": true,
"גגונה": true,
"גגונו": true,
"גגוני": true,
"גגונך": true,
"גגונם": true,
"גגונן": true,
"גדודה": true,
"גדודו": true,
"גדודי": true,
"גדודך": true,
"גדודם": true,
"גדודן": true,
"גדולה": true,
"גדולו": true,
"גדולי": true,
"גדולך": true,
"גדולם": true,
"גדולן": true,
"גדומה": true,
"גדועה": true,
"גדורה": true,
"גדושה": true,
"גדידה": true,
"גדידו": true,
"גדידי": true,
"גדידך": true,
"גדידם": true,
"גדידן": true,
"גדייה": true,
"גדייו": true,
"גדייך": true,
"גדיים": true,
"גדיין": true,
"גדילה": true,
"גדילו": true,
"גדילי": true,
"גדילך": true,
"גדילם": true,
"גדילן": true,
"גדימה": true,
"גדיעה": true,
"גדלות": true,
"גדליה": true,
"גדליו": true,
"גדליי": true,
"גדליך": true,
"גדלים": true,
"גדלנה": true,
"גדלנו": true,
"גדלתי": true,
"גדלתם": true,
"גדלתן": true,
"גדמיה": true,
"גדמיו": true,
"גדמיי": true,
"גדמיך": true,
"גדמים": true,
"גדמכם": true,
"גדמכן": true,
"גדמנו": true,
"גדמתי": true,
"גדמתם": true,
"גדמתן": true,
"גדעון": true,
"גדענה": true,
"גדענו": true,
"גדעתי": true,
"גדעתם": true,
"גדעתן": true,
"גדפנה": true,
"גדפני": true,
"גדרות": true,
"גדריה": true,
"גדריו": true,
"גדריי": true,
"גדריך": true,
"גדרים": true,
"גדרכם": true,
"גדרכן": true,
"גדרנה": true,
"גדרנו": true,
"גדשנו": true,
"גדשתי": true,
"גדשתם": true,
"גדשתן": true,
"גדתכם": true,
"גדתכן": true,
"גדתנו": true,
"גהותה": true,
"גהותו": true,
"גהותי": true,
"גהותך": true,
"גהותם": true,
"גהותן": true,
"גהירה": true,
"גהצנה": true,
"גהקנה": true,
"גהרנה": true,
"גהרנו": true,
"גהרתי": true,
"גהרתם": true,
"גהרתן": true,
"גואות": true,
"גואטה": true,
"גואים": true,
"גואלת": true,
"גובבה": true,
"גובבו": true,
"גובבת": true,
"גובהה": true,
"גובהו": true,
"גובהי": true,
"גובהך": true,
"גובהם": true,
"גובהן": true,
"גובהת": true,
"גובות": true,
"גוביה": true,
"גוביו": true,
"גוביי": true,
"גוביך": true,
"גובים": true,
"גובית": true,
"גובכם": true,
"גובכן": true,
"גובלן": true,
"גובלת": true,
"גובנה": true,
"גובנו": true,
"גובנת": true,
"גובסה": true,
"גובסו": true,
"גובסת": true,
"גוברת": true,
"גובשה": true,
"גובשו": true,
"גובשת": true,
"גובתה": true,
"גודלה": true,
"גודלו": true,
"גודלי": true,
"גודלך": true,
"גודלם": true,
"גודלן": true,
"גודלת": true,
"גודמת": true,
"גודעת": true,
"גודפה": true,
"גודפו": true,
"גודפת": true,
"גודרה": true,
"גודרו": true,
"גודרת": true,
"גודשת": true,
"גוהצה": true,
"גוהצו": true,
"גוהצת": true,
"גוהרת": true,
"גוויה": true,
"גוויו": true,
"גוויי": true,
"גוויך": true,
"גוויל": true,
"גווים": true,
"גווכם": true,
"גווכן": true,
"גוונה": true,
"גוונו": true,
"גווני": true,
"גוונת": true,
"גוועה": true,
"גוועו": true,
"גוועי": true,
"גוועת": true,
"גוזזה": true,
"גוזזו": true,
"גוזזי": true,
"גוזזך": true,
"גוזזם": true,
"גוזזן": true,
"גוזזת": true,
"גוזלה": true,
"גוזלו": true,
"גוזלי": true,
"גוזלך": true,
"גוזלם": true,
"גוזלן": true,
"גוזלת": true,
"גוזמה": true,
"גוזמת": true,
"גוזנה": true,
"גוזרת": true,
"גוחכה": true,
"גוחכו": true,
"גוחכת": true,
"גוחנת": true,
"גוטין": true,
"גוטמן": true,
"גויבה": true,
"גויות": true,
"גוייה": true,
"גוייו": true,
"גוייך": true,
"גויים": true,
"גויכם": true,
"גויכן": true,
"גוילי": true,
"גוינו": true,
"גוינט": true,
"גויסה": true,
"גויסו": true,
"גויסת": true,
"גויעד": true,
"גוירה": true,
"גוירו": true,
"גוירת": true,
"גולבר": true,
"גולגל": true,
"גולדה": true,
"גולדי": true,
"גולדן": true,
"גולהו": true,
"גולוב": true,
"גולות": true,
"גולחה": true,
"גולחו": true,
"גולחת": true,
"גוליה": true,
"גוליו": true,
"גוליי": true,
"גוליך": true,
"גולים": true,
"גוליס": true,
"גולכם": true,
"גולכן": true,
"גוללה": true,
"גוללו": true,
"גוללי": true,
"גוללת": true,
"גולמה": true,
"גולמו": true,
"גולמי": true,
"גולמך": true,
"גולמם": true,
"גולמן": true,
"גולמת": true,
"גולנו": true,
"גולני": true,
"גולען": true,
"גולפה": true,
"גולפו": true,
"גולפת": true,
"גולשה": true,
"גולשו": true,
"גולשי": true,
"גולשך": true,
"גולשם": true,
"גולשן": true,
"גולשת": true,
"גולתה": true,
"גולתו": true,
"גולתי": true,
"גולתך": true,
"גולתם": true,
"גולתן": true,
"גומאה": true,
"גומאו": true,
"גומאי": true,
"גומאך": true,
"גומאם": true,
"גומאן": true,
"גומגם": true,
"גומדה": true,
"גומדו": true,
"גומדת": true,
"גומות": true,
"גומחה": true,
"גומיה": true,
"גומלת": true,
"גומעת": true,
"גומרה": true,
"גומרו": true,
"גומרת": true,
"גומתה": true,
"גומתו": true,
"גומתי": true,
"גומתך": true,
"גומתם": true,
"גומתן": true,
"גונבת": true,
"גונגל": true,
"גונדה": true,
"גונדר": true,
"גונזת": true,
"גונחת": true,
"גונים": true,
"גונית": true,
"גונכם": true,
"גונכן": true,
"גוננה": true,
"גוננו": true,
"גונני": true,
"גוננת": true,
"גונתה": true,
"גונתן": true,
"גוססת": true,
"גועות": true,
"גועים": true,
"גועלה": true,
"גועלו": true,
"גועלי": true,
"גועלך": true,
"גועלם": true,
"גועלן": true,
"גוערת": true,
"גועשת": true,
"גופות": true,
"גופיה": true,
"גופיו": true,
"גופיי": true,
"גופיך": true,
"גופים": true,
"גופיף": true,
"גופית": true,
"גופכם": true,
"גופכן": true,
"גופנה": true,
"גופנו": true,
"גופני": true,
"גופנך": true,
"גופנם": true,
"גופנן": true,
"גופרה": true,
"גופרו": true,
"גופרת": true,
"גופתה": true,
"גופתו": true,
"גופתי": true,
"גופתך": true,
"גופתם": true,
"גופתן": true,
"גוצות": true,
"גוצים": true,
"גורבת": true,
"גורדי": true,
"גורדת": true,
"גורון": true,
"גורות": true,
"גורזה": true,
"גורזו": true,
"גורזת": true,
"גוריה": true,
"גוריו": true,
"גוריי": true,
"גוריך": true,
"גורים": true,
"גוריש": true,
"גורית": true,
"גורכם": true,
"גורכן": true,
"גורלה": true,
"גורלו": true,
"גורלי": true,
"גורלך": true,
"גורלם": true,
"גורלן": true,
"גורמה": true,
"גורמו": true,
"גורמי": true,
"גורמך": true,
"גורמם": true,
"גורמן": true,
"גורמת": true,
"גורנה": true,
"גורנו": true,
"גורני": true,
"גורנך": true,
"גורנם": true,
"גורנן": true,
"גורסת": true,
"גורען": true,
"גורעת": true,
"גורפה": true,
"גורפו": true,
"גורפת": true,
"גוררה": true,
"גוררו": true,
"גוררת": true,
"גורשה": true,
"גורשו": true,
"גורשת": true,
"גורתה": true,
"גורתו": true,
"גורתי": true,
"גורתך": true,
"גורתם": true,
"גורתן": true,
"גושיה": true,
"גושיו": true,
"גושיי": true,
"גושיך": true,
"גושים": true,
"גושית": true,
"גושכם": true,
"גושכן": true,
"גושנו": true,
"גושרה": true,
"גושרו": true,
"גושרת": true,
"גותים": true,
"גותית": true,
"גזברה": true,
"גזברו": true,
"גזברי": true,
"גזברך": true,
"גזברם": true,
"גזברן": true,
"גזוזה": true,
"גזולה": true,
"גזומה": true,
"גזורה": true,
"גזזנו": true,
"גזזתה": true,
"גזזתו": true,
"גזזתי": true,
"גזזתך": true,
"גזזתם": true,
"גזזתן": true,
"גזיהם": true,
"גזיהן": true,
"גזיות": true,
"גזיזה": true,
"גזייה": true,
"גזייך": true,
"גזיים": true,
"גזיכם": true,
"גזיכן": true,
"גזילה": true,
"גזינו": true,
"גזירה": true,
"גזירת": true,
"גזלות": true,
"גזלנו": true,
"גזלני": true,
"גזלתה": true,
"גזלתו": true,
"גזלתי": true,
"גזלתך": true,
"גזלתם": true,
"גזלתן": true,
"גזמנו": true,
"גזמתי": true,
"גזמתם": true,
"גזמתן": true,
"גזעיה": true,
"גזעיו": true,
"גזעיי": true,
"גזעיך": true,
"גזעים": true,
"גזעית": true,
"גזעכם": true,
"גזעכן": true,
"גזענו": true,
"גזעני": true,
"גזרון": true,
"גזרות": true,
"גזריה": true,
"גזריו": true,
"גזריי": true,
"גזריך": true,
"גזרים": true,
"גזרכם": true,
"גזרכן": true,
"גזרנו": true,
"גזרתה": true,
"גזרתו": true,
"גזרתי": true,
"גזרתך": true,
"גזרתם": true,
"גזרתן": true,
"גחונה": true,
"גחונו": true,
"גחוני": true,
"גחונך": true,
"גחונם": true,
"גחונן": true,
"גחינה": true,
"גחכנה": true,
"גחליה": true,
"גחליו": true,
"גחליי": true,
"גחליך": true,
"גחלים": true,
"גחלכם": true,
"גחלכן": true,
"גחלנו": true,
"גחלתה": true,
"גחלתו": true,
"גחלתי": true,
"גחלתך": true,
"גחלתם": true,
"גחלתן": true,
"גחמות": true,
"גחמני": true,
"גחמתה": true,
"גחמתו": true,
"גחמתי": true,
"גחמתך": true,
"גחמתם": true,
"גחמתן": true,
"גחנתי": true,
"גחנתם": true,
"גחנתן": true,
"גטאות": true,
"גטואה": true,
"גטואו": true,
"גטואי": true,
"גטואך": true,
"גטואם": true,
"גטואן": true,
"גטיהם": true,
"גטיהן": true,
"גטייך": true,
"גטיכם": true,
"גטיכן": true,
"גטינו": true,
"גיאהד": true,
"גיאכם": true,
"גיאכן": true,
"גיאנה": true,
"גיאנו": true,
"גיבבה": true,
"גיבבו": true,
"גיבבת": true,
"גיבוב": true,
"גיבוי": true,
"גיבון": true,
"גיבוס": true,
"גיבור": true,
"גיבוש": true,
"גיבחת": true,
"גיבית": true,
"גיבלי": true,
"גיבנה": true,
"גיבנו": true,
"גיבנת": true,
"גיבסה": true,
"גיבסו": true,
"גיבסת": true,
"גיבשה": true,
"גיבשו": true,
"גיבשת": true,
"גיבתה": true,
"גיגית": true,
"גידול": true,
"גידוף": true,
"גידור": true,
"גידיה": true,
"גידיו": true,
"גידיי": true,
"גידיך": true,
"גידים": true,
"גידכם": true,
"גידכן": true,
"גידלה": true,
"גידלו": true,
"גידלת": true,
"גידמת": true,
"גידנו": true,
"גידפה": true,
"גידפו": true,
"גידפת": true,
"גידרה": true,
"גידרו": true,
"גידרת": true,
"גיהאד": true,
"גיהוץ": true,
"גיהוק": true,
"גיהצה": true,
"גיהצו": true,
"גיהצת": true,
"גיהקה": true,
"גיהקו": true,
"גיהקת": true,
"גיוון": true,
"גיוסה": true,
"גיוסו": true,
"גיוסי": true,
"גיוסך": true,
"גיוסם": true,
"גיוסן": true,
"גיורא": true,
"גיורה": true,
"גיורו": true,
"גיורי": true,
"גיורך": true,
"גיורם": true,
"גיורן": true,
"גיורת": true,
"גיזום": true,
"גיזות": true,
"גיזתה": true,
"גיזתו": true,
"גיזתי": true,
"גיזתך": true,
"גיזתם": true,
"גיזתן": true,
"גיחוך": true,
"גיחות": true,
"גיחכה": true,
"גיחכו": true,
"גיחכת": true,
"גיחתה": true,
"גיחתו": true,
"גיחתי": true,
"גיחתך": true,
"גיחתם": true,
"גיחתן": true,
"גיטין": true,
"גיטרה": true,
"גייזר": true,
"גייטס": true,
"גיימס": true,
"גיימר": true,
"גייסה": true,
"גייסו": true,
"גייסי": true,
"גייסת": true,
"גיירה": true,
"גיירו": true,
"גיירי": true,
"גיירת": true,
"גיישה": true,
"גילאה": true,
"גילאו": true,
"גילאי": true,
"גילאך": true,
"גילאם": true,
"גילאן": true,
"גילדה": true,
"גילוח": true,
"גילוי": true,
"גילול": true,
"גילום": true,
"גילון": true,
"גילוף": true,
"גילחה": true,
"גילחו": true,
"גילחת": true,
"גיליה": true,
"גיליו": true,
"גיליי": true,
"גיליך": true,
"גילים": true,
"גילית": true,
"גילכם": true,
"גילכן": true,
"גילמה": true,
"גילמו": true,
"גילמת": true,
"גילנו": true,
"גילפה": true,
"גילפו": true,
"גילפת": true,
"גילתה": true,
"גימדה": true,
"גימדו": true,
"גימדת": true,
"גימוד": true,
"גימור": true,
"גימיק": true,
"גימרה": true,
"גימרו": true,
"גימרת": true,
"גינאה": true,
"גינאי": true,
"גינדי": true,
"גינוי": true,
"גינון": true,
"גינות": true,
"גינית": true,
"גיננה": true,
"גיננו": true,
"גיננת": true,
"גינתה": true,
"גינתו": true,
"גינתי": true,
"גינתך": true,
"גינתם": true,
"גינתן": true,
"גיסות": true,
"גיסיה": true,
"גיסיו": true,
"גיסיי": true,
"גיסיך": true,
"גיסים": true,
"גיסכם": true,
"גיסכן": true,
"גיסנו": true,
"גיסתה": true,
"גיסתו": true,
"גיסתי": true,
"גיסתך": true,
"גיסתם": true,
"גיסתן": true,
"גיעות": true,
"גיעים": true,
"גיפוף": true,
"גיפור": true,
"גיפפה": true,
"גיפפו": true,
"גיפפת": true,
"גיפרה": true,
"גיפרו": true,
"גיפרת": true,
"גירדה": true,
"גירדו": true,
"גירדת": true,
"גירוד": true,
"גירוז": true,
"גירוי": true,
"גירון": true,
"גירוף": true,
"גירור": true,
"גירוש": true,
"גירות": true,
"גירזה": true,
"גירזו": true,
"גירזת": true,
"גיריה": true,
"גיריו": true,
"גיריי": true,
"גיריך": true,
"גירים": true,
"גירית": true,
"גירכם": true,
"גירכן": true,
"גירנו": true,
"גירסה": true,
"גירסת": true,
"גירפה": true,
"גירפו": true,
"גירפת": true,
"גיררה": true,
"גיררו": true,
"גיררת": true,
"גירשה": true,
"גירשו": true,
"גירשת": true,
"גירתה": true,
"גירתו": true,
"גירתי": true,
"גירתך": true,
"גירתם": true,
"גירתן": true,
"גישור": true,
"גישוש": true,
"גישות": true,
"גישים": true,
"גישרה": true,
"גישרו": true,
"גישרת": true,
"גיששה": true,
"גיששו": true,
"גיששת": true,
"גישתה": true,
"גישתו": true,
"גישתי": true,
"גישתך": true,
"גישתם": true,
"גישתן": true,
"גלאון": true,
"גלאיה": true,
"גלאיו": true,
"גלאיי": true,
"גלאיך": true,
"גלאים": true,
"גלאכם": true,
"גלאכן": true,
"גלאנו": true,
"גלבוע": true,
"גלביה": true,
"גלביו": true,
"גלביי": true,
"גלביך": true,
"גלבים": true,
"גלבית": true,
"גלבכם": true,
"גלבכן": true,
"גלבנו": true,
"גלגול": true,
"גלגלה": true,
"גלגלו": true,
"גלגלי": true,
"גלגלך": true,
"גלגלם": true,
"גלגלן": true,
"גלגלת": true,
"גלגשת": true,
"גלדיה": true,
"גלדיו": true,
"גלדיי": true,
"גלדיך": true,
"גלדים": true,
"גלדכם": true,
"גלדכן": true,
"גלדנו": true,
"גלובל": true,
"גלובס": true,
"גלוון": true,
"גלוחה": true,
"גלוטן": true,
"גלויה": true,
"גלולה": true,
"גלומה": true,
"גלופה": true,
"גלותה": true,
"גלותו": true,
"גלותי": true,
"גלותך": true,
"גלותם": true,
"גלותן": true,
"גלזגו": true,
"גלחנה": true,
"גלידה": true,
"גלידו": true,
"גלידי": true,
"גלידך": true,
"גלידם": true,
"גלידן": true,
"גלידת": true,
"גליהם": true,
"גליהן": true,
"גליום": true,
"גליון": true,
"גליות": true,
"גלייך": true,
"גליים": true,
"גליכם": true,
"גליכן": true,
"גלילה": true,
"גלילו": true,
"גלילי": true,
"גלילך": true,
"גלילם": true,
"גלילן": true,
"גלימה": true,
"גלינה": true,
"גלינו": true,
"גלישה": true,
"גלישת": true,
"גליתי": true,
"גליתם": true,
"גליתן": true,
"גלליה": true,
"גלליו": true,
"גלליי": true,
"גלליך": true,
"גללים": true,
"גללכם": true,
"גללכן": true,
"גללנו": true,
"גללתי": true,
"גללתם": true,
"גללתן": true,
"גלמוד": true,
"גלמיה": true,
"גלמיו": true,
"גלמיי": true,
"גלמיך": true,
"גלמים": true,
"גלמנה": true,
"גלעדה": true,
"גלעדו": true,
"גלעדי": true,
"גלעדך": true,
"גלעדם": true,
"גלעדן": true,
"גלעון": true,
"גלעין": true,
"גלענה": true,
"גלענו": true,
"גלעני": true,
"גלענת": true,
"גלפנה": true,
"גלקטי": true,
"גלקסי": true,
"גלריה": true,
"גלשון": true,
"גלשנה": true,
"גלשנו": true,
"גלשני": true,
"גלשנך": true,
"גלשנם": true,
"גלשנן": true,
"גלשתי": true,
"גלשתם": true,
"גלשתן": true,
"גמאיה": true,
"גמאיו": true,
"גמאיי": true,
"גמאיך": true,
"גמאים": true,
"גמבות": true,
"גמביה": true,
"גמבתה": true,
"גמבתו": true,
"גמבתי": true,
"גמבתך": true,
"גמבתם": true,
"גמבתן": true,
"גמגום": true,
"גמגמה": true,
"גמגמו": true,
"גמגמי": true,
"גמגמן": true,
"גמגמת": true,
"גמדות": true,
"גמדיה": true,
"גמדיו": true,
"גמדיי": true,
"גמדיך": true,
"גמדים": true,
"גמדית": true,
"גמדכם": true,
"גמדכן": true,
"גמדנה": true,
"גמדנו": true,
"גמדתה": true,
"גמדתו": true,
"גמדתי": true,
"גמדתך": true,
"גמדתם": true,
"גמדתן": true,
"גמולה": true,
"גמולו": true,
"גמולי": true,
"גמולך": true,
"גמולם": true,
"גמולן": true,
"גמורה": true,
"גמיאה": true,
"גמילה": true,
"גמיעה": true,
"גמירה": true,
"גמישה": true,
"גמלאה": true,
"גמלאו": true,
"גמלאי": true,
"גמלאך": true,
"גמלאם": true,
"גמלאן": true,
"גמלון": true,
"גמלות": true,
"גמליה": true,
"גמליו": true,
"גמליי": true,
"גמליך": true,
"גמלים": true,
"גמלכם": true,
"גמלכן": true,
"גמלנו": true,
"גמלתה": true,
"גמלתו": true,
"גמלתי": true,
"גמלתך": true,
"גמלתם": true,
"גמלתן": true,
"גמענה": true,
"גמענו": true,
"גמעתי": true,
"גמעתם": true,
"גמעתן": true,
"גמרות": true,
"גמרנה": true,
"גמרנו": true,
"גמרתה": true,
"גמרתו": true,
"גמרתי": true,
"גמרתך": true,
"גמרתם": true,
"גמרתן": true,
"גנאדי": true,
"גנאים": true,
"גנאית": true,
"גנאכם": true,
"גנאכן": true,
"גנאנו": true,
"גנבות": true,
"גנביה": true,
"גנביו": true,
"גנביי": true,
"גנביך": true,
"גנבים": true,
"גנבכם": true,
"גנבכן": true,
"גנבנו": true,
"גנבתה": true,
"גנבתו": true,
"גנבתי": true,
"גנבתך": true,
"גנבתם": true,
"גנבתן": true,
"גנדהי": true,
"גנדרן": true,
"גנואה": true,
"גנובה": true,
"גנוזה": true,
"גנונה": true,
"גנונו": true,
"גנוני": true,
"גנונך": true,
"גנונם": true,
"גנונן": true,
"גנותה": true,
"גנותו": true,
"גנותי": true,
"גנותך": true,
"גנותם": true,
"גנותן": true,
"גנזכה": true,
"גנזכו": true,
"גנזכי": true,
"גנזכך": true,
"גנזכם": true,
"גנזכן": true,
"גנזנו": true,
"גנזתי": true,
"גנזתם": true,
"גנזתן": true,
"גנחנה": true,
"גנחנו": true,
"גנחתי": true,
"גנחתם": true,
"גנחתן": true,
"גנטית": true,
"גניבה": true,
"גניבת": true,
"גניהם": true,
"גניהן": true,
"גניוס": true,
"גניזה": true,
"גניחה": true,
"גנייך": true,
"גניכם": true,
"גניכן": true,
"גנינה": true,
"גנינו": true,
"גניפר": true,
"גנירם": true,
"גננות": true,
"גנניה": true,
"גנניו": true,
"גנניי": true,
"גנניך": true,
"גננים": true,
"גננכם": true,
"גננכן": true,
"גנננו": true,
"גננתה": true,
"גננתו": true,
"גננתי": true,
"גננתך": true,
"גננתם": true,
"גננתן": true,
"גנרית": true,
"גנרלי": true,
"גסותה": true,
"גסותו": true,
"גסותי": true,
"גסותך": true,
"גסותם": true,
"גסותן": true,
"גסטפו": true,
"גסיסה": true,
"גסליק": true,
"גססנו": true,
"גססתי": true,
"גססתם": true,
"גססתן": true,
"געגוע": true,
"געגעה": true,
"געגעו": true,
"געגעי": true,
"געגעת": true,
"געיות": true,
"געייה": true,
"געינה": true,
"געינו": true,
"געיתי": true,
"געיתם": true,
"געיתן": true,
"געפרי": true,
"גערות": true,
"גערנה": true,
"גערנו": true,
"גערתה": true,
"גערתו": true,
"גערתי": true,
"גערתך": true,
"גערתם": true,
"גערתן": true,
"געשית": true,
"געשכם": true,
"געשכן": true,
"געשנה": true,
"געשנו": true,
"געשתי": true,
"געשתם": true,
"געשתן": true,
"געתון": true,
"גפיהם": true,
"גפיהן": true,
"גפייך": true,
"גפיים": true,
"גפיכם": true,
"גפיכן": true,
"גפינו": true,
"גפניה": true,
"גפניו": true,
"גפניי": true,
"גפניך": true,
"גפנים": true,
"גפנכם": true,
"גפנכן": true,
"גפננו": true,
"גפפנה": true,
"גפרור": true,
"גפרית": true,
"גפרנה": true,
"גציהם": true,
"גציהן": true,
"גצייך": true,
"גציכם": true,
"גציכן": true,
"גצינו": true,
"גקוזי": true,
"גקרטה": true,
"גראוט": true,
"גראסו": true,
"גרביה": true,
"גרביו": true,
"גרביי": true,
"גרביך": true,
"גרביל": true,
"גרבכם": true,
"גרבכן": true,
"גרבנו": true,
"גרברה": true,
"גרבתי": true,
"גרבתם": true,
"גרבתן": true,
"גרגור": true,
"גרגיר": true,
"גרגרה": true,
"גרגרו": true,
"גרגרי": true,
"גרגרך": true,
"גרגרם": true,
"גרגרן": true,
"גרגרת": true,
"גרדום": true,
"גרדנה": true,
"גרדנו": true,
"גרדתי": true,
"גרדתם": true,
"גרדתן": true,
"גרהרד": true,
"גרובה": true,
"גרוטה": true,
"גרומה": true,
"גרונה": true,
"גרונו": true,
"גרוני": true,
"גרונך": true,
"גרונם": true,
"גרונן": true,
"גרונר": true,
"גרוסה": true,
"גרועה": true,
"גרופה": true,
"גרורה": true,
"גרושה": true,
"גרושו": true,
"גרושי": true,
"גרושך": true,
"גרושם": true,
"גרושן": true,
"גרותה": true,
"גרותו": true,
"גרותי": true,
"גרותך": true,
"גרותם": true,
"גרותן": true,
"גרזנה": true,
"גרזנו": true,
"גרזני": true,
"גרזנך": true,
"גרזנם": true,
"גרזנן": true,
"גריבה": true,
"גרידא": true,
"גרידה": true,
"גריהם": true,
"גריהן": true,
"גרייך": true,
"גרייס": true,
"גריכם": true,
"גריכן": true,
"גרילה": true,
"גרילו": true,
"גרילי": true,
"גרילך": true,
"גרילם": true,
"גרילן": true,
"גרימה": true,
"גרימת": true,
"גרינג": true,
"גרינה": true,
"גרינו": true,
"גריסה": true,
"גריסו": true,
"גריסי": true,
"גריסך": true,
"גריסם": true,
"גריסן": true,
"גריעה": true,
"גריפה": true,
"גרירה": true,
"גרירת": true,
"גרמיה": true,
"גרמיו": true,
"גרמיי": true,
"גרמיך": true,
"גרמים": true,
"גרמית": true,
"גרמכם": true,
"גרמכן": true,
"גרמנו": true,
"גרמני": true,
"גרמתי": true,
"גרמתם": true,
"גרמתן": true,
"גרנדה": true,
"גרנות": true,
"גרניט": true,
"גרנית": true,
"גרסבן": true,
"גרסות": true,
"גרסנו": true,
"גרסתה": true,
"גרסתו": true,
"גרסתי": true,
"גרסתך": true,
"גרסתם": true,
"גרסתן": true,
"גרעון": true,
"גרעין": true,
"גרענה": true,
"גרענו": true,
"גרעני": true,
"גרענת": true,
"גרעתי": true,
"גרעתם": true,
"גרעתן": true,
"גרפיה": true,
"גרפיו": true,
"גרפיט": true,
"גרפיי": true,
"גרפיך": true,
"גרפים": true,
"גרפיק": true,
"גרפית": true,
"גרפכם": true,
"גרפכן": true,
"גרפנה": true,
"גרפנו": true,
"גרפתי": true,
"גרפתם": true,
"גרפתן": true,
"גרציה": true,
"גררנה": true,
"גררנו": true,
"גררתי": true,
"גררתם": true,
"גררתן": true,
"גרשום": true,
"גרשון": true,
"גרשיה": true,
"גרשיו": true,
"גרשיי": true,
"גרשיך": true,
"גרשים": true,
"גרשכם": true,
"גרשכן": true,
"גרשנה": true,
"גרשנו": true,
"גשומה": true,
"גשושה": true,
"גשושו": true,
"גשושי": true,
"גשושך": true,
"גשושם": true,
"גשושן": true,
"גשמות": true,
"גשמיה": true,
"גשמיו": true,
"גשמיי": true,
"גשמיך": true,
"גשמים": true,
"גשמית": true,
"גשמכם": true,
"גשמכן": true,
"גשמנו": true,
"גשרון": true,
"גשריה": true,
"גשריו": true,
"גשריי": true,
"גשריך": true,
"גשרים": true,
"גשרכם": true,
"גשרכן": true,
"גשרנה": true,
"גשרנו": true,
"גששות": true,
"גששיה": true,
"גששיו": true,
"גששיי": true,
"גששיך": true,
"גששים": true,
"גששית": true,
"גששכם": true,
"גששכן": true,
"גששנה": true,
"גששנו": true,
"גששתה": true,
"גששתו": true,
"גששתי": true,
"גששתך": true,
"גששתם": true,
"גששתן": true,
"דאבון": true,
"דאבנה": true,
"דאבנו": true,
"דאבתי": true,
"דאבתם": true,
"דאבתן": true,
"דאגות": true,
"דאגלס": true,
"דאגנה": true,
"דאגנו": true,
"דאגני": true,
"דאגתה": true,
"דאגתו": true,
"דאגתי": true,
"דאגתך": true,
"דאגתם": true,
"דאגתן": true,
"דאהרן": true,
"דאובה": true,
"דאווד": true,
"דאונה": true,
"דאונו": true,
"דאוני": true,
"דאונך": true,
"דאונם": true,
"דאונן": true,
"דאיהו": true,
"דאיות": true,
"דאיזם": true,
"דאייה": true,
"דאינה": true,
"דאינו": true,
"דאיסט": true,
"דאיתי": true,
"דאיתם": true,
"דאיתן": true,
"דאלאס": true,
"דאריה": true,
"דאריו": true,
"דאריי": true,
"דאריך": true,
"דארים": true,
"דאתרא": true,
"דבבנה": true,
"דבגנה": true,
"דבוקה": true,
"דבורה": true,
"דבורו": true,
"דבורי": true,
"דבורך": true,
"דבורם": true,
"דבורן": true,
"דבילי": true,
"דביקה": true,
"דבלול": true,
"דבליה": true,
"דבליו": true,
"דבליי": true,
"דבליך": true,
"דבלים": true,
"דבלין": true,
"דבלתה": true,
"דבלתו": true,
"דבלתי": true,
"דבלתך": true,
"דבלתם": true,
"דבלתן": true,
"דבקון": true,
"דבקות": true,
"דבקיה": true,
"דבקיו": true,
"דבקיי": true,
"דבקיך": true,
"דבקים": true,
"דבקית": true,
"דבקכם": true,
"דבקכן": true,
"דבקנו": true,
"דבקתי": true,
"דבקתם": true,
"דבקתן": true,
"דברור": true,
"דבריה": true,
"דבריו": true,
"דבריי": true,
"דבריך": true,
"דברים": true,
"דברכם": true,
"דברכן": true,
"דברנה": true,
"דברנו": true,
"דברני": true,
"דבררה": true,
"דבררו": true,
"דבררי": true,
"דבררת": true,
"דברתה": true,
"דברתו": true,
"דברתי": true,
"דברתך": true,
"דברתם": true,
"דברתן": true,
"דבשות": true,
"דבשיה": true,
"דבשיו": true,
"דבשיי": true,
"דבשיך": true,
"דבשים": true,
"דבשית": true,
"דבשכם": true,
"דבשכן": true,
"דבשנו": true,
"דבשתה": true,
"דבשתו": true,
"דבשתי": true,
"דבשתך": true,
"דבשתם": true,
"דבשתן": true,
"דגדגה": true,
"דגדגו": true,
"דגדגי": true,
"דגדגן": true,
"דגדגת": true,
"דגדוג": true,
"דגולה": true,
"דגומה": true,
"דגושה": true,
"דגיגה": true,
"דגיגו": true,
"דגיגי": true,
"דגיגך": true,
"דגיגם": true,
"דגיגן": true,
"דגיהם": true,
"דגיהן": true,
"דגייך": true,
"דגיכם": true,
"דגיכן": true,
"דגימה": true,
"דגינו": true,
"דגירה": true,
"דגלון": true,
"דגליה": true,
"דגליו": true,
"דגליי": true,
"דגליך": true,
"דגלים": true,
"דגלכם": true,
"דגלכן": true,
"דגלנה": true,
"דגלנו": true,
"דגלני": true,
"דגלנך": true,
"דגלנם": true,
"דגלנן": true,
"דגלתי": true,
"דגלתם": true,
"דגלתן": true,
"דגמיה": true,
"דגמיו": true,
"דגמיי": true,
"דגמיך": true,
"דגמים": true,
"דגמכם": true,
"דגמכן": true,
"דגמנה": true,
"דגמנו": true,
"דגמני": true,
"דגמנת": true,
"דגמתי": true,
"דגמתם": true,
"דגמתן": true,
"דגניה": true,
"דגניו": true,
"דגניי": true,
"דגניך": true,
"דגנים": true,
"דגנית": true,
"דגנכם": true,
"דגנכן": true,
"דגננו": true,
"דגנרט": true,
"דגרנו": true,
"דגרתי": true,
"דגרתם": true,
"דגרתן": true,
"דגשיה": true,
"דגשיו": true,
"דגשיי": true,
"דגשיך": true,
"דגשים": true,
"דגשכם": true,
"דגשכן": true,
"דגשנו": true,
"דגתכם": true,
"דגתכן": true,
"דגתנו": true,
"דדיהם": true,
"דדיהן": true,
"דדייך": true,
"דדיכם": true,
"דדיכן": true,
"דדינה": true,
"דדינו": true,
"דדנית": true,
"דהויה": true,
"דהיום": true,
"דהיות": true,
"דהייה": true,
"דהינה": true,
"דהינו": true,
"דהירה": true,
"דהיתי": true,
"דהיתם": true,
"דהיתן": true,
"דהמשא": true,
"דהמשה": true,
"דהניה": true,
"דהרות": true,
"דהריה": true,
"דהרנה": true,
"דהרנו": true,
"דהרתה": true,
"דהרתו": true,
"דהרתי": true,
"דהרתך": true,
"דהרתם": true,
"דהרתן": true,
"דואבת": true,
"דואגת": true,
"דואות": true,
"דואים": true,
"דואלי": true,
"דוארה": true,
"דוארו": true,
"דוארי": true,
"דוארך": true,
"דוארם": true,
"דוארן": true,
"דובאי": true,
"דובבה": true,
"דובבו": true,
"דובבי": true,
"דובבת": true,
"דובגה": true,
"דובגו": true,
"דובגת": true,
"דובון": true,
"דובות": true,
"דוביה": true,
"דוביו": true,
"דוביי": true,
"דוביך": true,
"דובים": true,
"דובית": true,
"דובכם": true,
"דובכן": true,
"דובנו": true,
"דובער": true,
"דובקת": true,
"דוברה": true,
"דוברו": true,
"דוברי": true,
"דוברך": true,
"דוברם": true,
"דוברן": true,
"דוברר": true,
"דוברת": true,
"דובתה": true,
"דובתו": true,
"דובתי": true,
"דובתך": true,
"דובתם": true,
"דובתן": true,
"דוגדג": true,
"דוגים": true,
"דוגין": true,
"דוגית": true,
"דוגלת": true,
"דוגמא": true,
"דוגמה": true,
"דוגמן": true,
"דוגמת": true,
"דוגנה": true,
"דוגרת": true,
"דודאה": true,
"דודאו": true,
"דודאי": true,
"דודאך": true,
"דודאם": true,
"דודאן": true,
"דודות": true,
"דודיה": true,
"דודיו": true,
"דודיי": true,
"דודיך": true,
"דודים": true,
"דודכם": true,
"דודכן": true,
"דודנה": true,
"דודנו": true,
"דודני": true,
"דודנך": true,
"דודנם": true,
"דודנן": true,
"דודתה": true,
"דודתו": true,
"דודתי": true,
"דודתך": true,
"דודתם": true,
"דודתן": true,
"דוהות": true,
"דוהים": true,
"דוהרת": true,
"דווחה": true,
"דווחו": true,
"דווחי": true,
"דווחת": true,
"דוויה": true,
"דוויו": true,
"דוויי": true,
"דווים": true,
"דוויק": true,
"דווקא": true,
"דוורה": true,
"דוורו": true,
"דוורי": true,
"דוורך": true,
"דוורם": true,
"דוורן": true,
"דוושה": true,
"דוושו": true,
"דוושי": true,
"דוחות": true,
"דוחים": true,
"דוחכם": true,
"דוחכן": true,
"דוחנו": true,
"דוחסת": true,
"דוחפת": true,
"דוחקה": true,
"דוחקו": true,
"דוחקי": true,
"דוחקך": true,
"דוחקם": true,
"דוחקן": true,
"דוחקת": true,
"דוידי": true,
"דויות": true,
"דויטש": true,
"דויים": true,
"דויקה": true,
"דויקו": true,
"דויקת": true,
"דוכאה": true,
"דוכאו": true,
"דוכאת": true,
"דוכדך": true,
"דוכנה": true,
"דוכנו": true,
"דוכני": true,
"דוכנך": true,
"דוכנם": true,
"דוכנן": true,
"דוכסה": true,
"דוכסו": true,
"דוכסי": true,
"דוכסך": true,
"דוכסם": true,
"דוכסן": true,
"דולבה": true,
"דולבו": true,
"דולבי": true,
"דולבך": true,
"דולבם": true,
"דולבן": true,
"דולגה": true,
"דולגו": true,
"דולגת": true,
"דולדל": true,
"דולות": true,
"דולים": true,
"דוללה": true,
"דוללו": true,
"דוללת": true,
"דולנד": true,
"דולפת": true,
"דולקת": true,
"דולרי": true,
"דומהו": true,
"דומוס": true,
"דומות": true,
"דומיה": true,
"דומיו": true,
"דומיי": true,
"דומיך": true,
"דומים": true,
"דומינ": true,
"דומית": true,
"דומכם": true,
"דומכן": true,
"דוממה": true,
"דוממו": true,
"דוממי": true,
"דוממת": true,
"דומנו": true,
"דומני": true,
"דומעת": true,
"דומתה": true,
"דומתו": true,
"דומתי": true,
"דומתך": true,
"דומתם": true,
"דומתן": true,
"דונגה": true,
"דונגו": true,
"דונגי": true,
"דונגך": true,
"דונגם": true,
"דונגן": true,
"דונון": true,
"דונית": true,
"דונלד": true,
"דונמה": true,
"דונמו": true,
"דונמי": true,
"דונמך": true,
"דונמם": true,
"דונמן": true,
"דועכת": true,
"דופיו": true,
"דופיי": true,
"דופנה": true,
"דופנו": true,
"דופני": true,
"דופנך": true,
"דופנם": true,
"דופנן": true,
"דופנת": true,
"דופקה": true,
"דופקו": true,
"דופקי": true,
"דופקך": true,
"דופקם": true,
"דופקן": true,
"דופקת": true,
"דוקאן": true,
"דוקדק": true,
"דוקיה": true,
"דוקיו": true,
"דוקיי": true,
"דוקיך": true,
"דוקים": true,
"דוקכם": true,
"דוקכן": true,
"דוקלם": true,
"דוקנו": true,
"דוקרן": true,
"דוקרת": true,
"דוראן": true,
"דורבן": true,
"דורגה": true,
"דורגו": true,
"דורגת": true,
"דורדר": true,
"דורוב": true,
"דורון": true,
"דורות": true,
"דורים": true,
"דורית": true,
"דורכם": true,
"דורכן": true,
"דורכת": true,
"דורנה": true,
"דורנו": true,
"דורנר": true,
"דורסת": true,
"דורפר": true,
"דורשב": true,
"דורשת": true,
"דושנה": true,
"דושנו": true,
"דושנת": true,
"דחויה": true,
"דחוסה": true,
"דחופה": true,
"דחוקה": true,
"דחיות": true,
"דחייה": true,
"דחיית": true,
"דחילק": true,
"דחינה": true,
"דחינו": true,
"דחיסה": true,
"דחיפה": true,
"דחיקה": true,
"דחיתי": true,
"דחיתם": true,
"דחיתן": true,
"דחלאן": true,
"דחליל": true,
"דחסנה": true,
"דחסנו": true,
"דחסתי": true,
"דחסתם": true,
"דחסתן": true,
"דחפור": true,
"דחפיה": true,
"דחפיו": true,
"דחפיי": true,
"דחפיך": true,
"דחפים": true,
"דחפכם": true,
"דחפכן": true,
"דחפנה": true,
"דחפנו": true,
"דחפתי": true,
"דחפתם": true,
"דחפתן": true,
"דחקנה": true,
"דחקנו": true,
"דחקתי": true,
"דחקתם": true,
"דחקתן": true,
"דטורה": true,
"דיאטה": true,
"דיאטן": true,
"דיאטת": true,
"דיאנה": true,
"דיבבה": true,
"דיבבו": true,
"דיבבת": true,
"דיבגה": true,
"דיבגו": true,
"דיבוב": true,
"דיבוג": true,
"דיבוק": true,
"דיבור": true,
"דיבות": true,
"דיבנה": true,
"דיברה": true,
"דיברו": true,
"דיברי": true,
"דיברך": true,
"דיברם": true,
"דיברן": true,
"דיברת": true,
"דיבתה": true,
"דיבתו": true,
"דיבתי": true,
"דיבתך": true,
"דיבתם": true,
"דיבתן": true,
"דיגיה": true,
"דיגיו": true,
"דיגיט": true,
"דיגיי": true,
"דיגיך": true,
"דיגים": true,
"דיגכם": true,
"דיגכן": true,
"דיגנו": true,
"דידוי": true,
"דידות": true,
"דידית": true,
"דידכם": true,
"דידכן": true,
"דידנו": true,
"דידתה": true,
"דיודה": true,
"דיווה": true,
"דיווח": true,
"דיוור": true,
"דיווש": true,
"דיוות": true,
"דיוטה": true,
"דיויד": true,
"דיומא": true,
"דיונה": true,
"דיונו": true,
"דיוני": true,
"דיונך": true,
"דיונם": true,
"דיונן": true,
"דיוקה": true,
"דיוקו": true,
"דיוקי": true,
"דיוקך": true,
"דיוקם": true,
"דיוקן": true,
"דיורה": true,
"דיורו": true,
"דיורי": true,
"דיורך": true,
"דיורם": true,
"דיורן": true,
"דיותה": true,
"דיותו": true,
"דיותי": true,
"דיותך": true,
"דיותם": true,
"דיותן": true,
"דיחוי": true,
"דיחות": true,
"דייגה": true,
"דייגו": true,
"דייגי": true,
"דייגך": true,
"דייגם": true,
"דייגן": true,
"דייגת": true,
"דייטש": true,
"דייכם": true,
"דייכן": true,
"דיילה": true,
"דיילו": true,
"דיילי": true,
"דיילך": true,
"דיילם": true,
"דיילן": true,
"דיילת": true,
"דיינה": true,
"דיינו": true,
"דייני": true,
"דיינך": true,
"דיינם": true,
"דיינן": true,
"דייסה": true,
"דייקה": true,
"דייקו": true,
"דייקי": true,
"דייקן": true,
"דייקת": true,
"דיירה": true,
"דיירו": true,
"דיירי": true,
"דיירך": true,
"דיירם": true,
"דיירן": true,
"דיירת": true,
"דייתה": true,
"דייתו": true,
"דייתי": true,
"דייתך": true,
"דייתם": true,
"דייתן": true,
"דיכאה": true,
"דיכאו": true,
"דיכאת": true,
"דיכוי": true,
"דיכטר": true,
"דילגה": true,
"דילגו": true,
"דילגת": true,
"דילוג": true,
"דילול": true,
"דילים": true,
"דיללה": true,
"דיללו": true,
"דיללת": true,
"דילמה": true,
"דימוי": true,
"דימום": true,
"דימים": true,
"דימית": true,
"דיממה": true,
"דיממו": true,
"דיממת": true,
"דימתה": true,
"דינגה": true,
"דינגו": true,
"דינגת": true,
"דינוג": true,
"דינור": true,
"דינות": true,
"דיניה": true,
"דיניו": true,
"דיניי": true,
"דיניך": true,
"דינים": true,
"דינכם": true,
"דינכן": true,
"דינמו": true,
"דינמי": true,
"דיננו": true,
"דיסני": true,
"דיסקה": true,
"דיסקו": true,
"דיסקט": true,
"דיסקי": true,
"דיסקך": true,
"דיסקם": true,
"דיסקן": true,
"דיפון": true,
"דיפות": true,
"דיפים": true,
"דיפנה": true,
"דיפנו": true,
"דיפנת": true,
"דיצות": true,
"דיצתה": true,
"דיצתו": true,
"דיצתי": true,
"דיצתך": true,
"דיצתם": true,
"דיצתן": true,
"דיקור": true,
"דיקות": true,
"דיקים": true,
"דיקנה": true,
"דיקנו": true,
"דיקני": true,
"דיקנך": true,
"דיקנם": true,
"דיקנן": true,
"דירבן": true,
"דירגה": true,
"דירגו": true,
"דירגת": true,
"דירוג": true,
"דירות": true,
"דיריה": true,
"דיריו": true,
"דיריי": true,
"דיריך": true,
"דירים": true,
"דירכם": true,
"דירכן": true,
"דירנו": true,
"דירתה": true,
"דירתו": true,
"דירתי": true,
"דירתך": true,
"דירתם": true,
"דירתן": true,
"דישון": true,
"דישות": true,
"דישים": true,
"דישכם": true,
"דישכן": true,
"דישנה": true,
"דישנו": true,
"דישנת": true,
"דישתה": true,
"דישתו": true,
"דישתי": true,
"דישתך": true,
"דישתם": true,
"דישתן": true,
"דכאון": true,
"דכאנה": true,
"דכאני": true,
"דכדוך": true,
"דכדכה": true,
"דכדכו": true,
"דכדכי": true,
"דכדכת": true,
"דכיון": true,
"דכפין": true,
"דכתיב": true,
"דלביה": true,
"דלביו": true,
"דלביי": true,
"דלביך": true,
"דלבים": true,
"דלגנה": true,
"דלדול": true,
"דלדלה": true,
"דלדלו": true,
"דלדלי": true,
"דלדלת": true,
"דלוור": true,
"דלוחה": true,
"דלונו": true,
"דלוקה": true,
"דלותה": true,
"דלותו": true,
"דלותי": true,
"דלותך": true,
"דלותם": true,
"דלותן": true,
"דליות": true,
"דלייה": true,
"דלייו": true,
"דלייך": true,
"דליים": true,
"דליין": true,
"דלילה": true,
"דלינה": true,
"דלינו": true,
"דליפה": true,
"דליקה": true,
"דליתה": true,
"דליתו": true,
"דליתי": true,
"דליתך": true,
"דליתם": true,
"דליתן": true,
"דללנה": true,
"דלמטי": true,
"דלעיל": true,
"דלעתה": true,
"דלעתו": true,
"דלעתי": true,
"דלעתך": true,
"דלעתם": true,
"דלעתן": true,
"דלפון": true,
"דלפנו": true,
"דלפקה": true,
"דלפקו": true,
"דלפקי": true,
"דלפקך": true,
"דלפקם": true,
"דלפקן": true,
"דלפתי": true,
"דלפתם": true,
"דלפתן": true,
"דלקות": true,
"דלקיה": true,
"דלקיו": true,
"דלקיי": true,
"דלקיך": true,
"דלקים": true,
"דלקכם": true,
"דלקכן": true,
"דלקנו": true,
"דלקתה": true,
"דלקתו": true,
"דלקתי": true,
"דלקתך": true,
"דלקתם": true,
"דלקתן": true,
"דלתון": true,
"דלתות": true,
"דלתכם": true,
"דלתכן": true,
"דלתנו": true,
"דלתתה": true,
"דלתתו": true,
"דלתתי": true,
"דלתתך": true,
"דלתתם": true,
"דלתתן": true,
"דמארי": true,
"דמגוג": true,
"דמדום": true,
"דמויה": true,
"דמויי": true,
"דמוני": true,
"דמותה": true,
"דמותו": true,
"דמותי": true,
"דמותך": true,
"דמותם": true,
"דמותן": true,
"דמיהם": true,
"דמיהן": true,
"דמיון": true,
"דמייך": true,
"דמיין": true,
"דמיית": true,
"דמיכם": true,
"דמיכן": true,
"דמינה": true,
"דמינו": true,
"דמיעה": true,
"דמירל": true,
"דמיתי": true,
"דמיתם": true,
"דמיתן": true,
"דממות": true,
"דממנה": true,
"דממנו": true,
"דממתה": true,
"דממתו": true,
"דממתי": true,
"דממתך": true,
"דממתם": true,
"דממתן": true,
"דמנות": true,
"דמנטי": true,
"דמעות": true,
"דמענה": true,
"דמענו": true,
"דמעתה": true,
"דמעתו": true,
"דמעתי": true,
"דמעתך": true,
"דמעתם": true,
"דמעתן": true,
"דמקני": true,
"דנאות": true,
"דנגנה": true,
"דנובה": true,
"דנוור": true,
"דנורא": true,
"דנטלי": true,
"דניאל": true,
"דניות": true,
"דניים": true,
"דנינו": true,
"דנמרק": true,
"דנסקי": true,
"דסקוס": true,
"דסקות": true,
"דסקית": true,
"דסקסה": true,
"דסקסו": true,
"דסקסי": true,
"דסקסת": true,
"דסקתה": true,
"דסקתו": true,
"דסקתי": true,
"דסקתך": true,
"דסקתם": true,
"דסקתן": true,
"דסתרי": true,
"דעיכה": true,
"דעכנה": true,
"דעכנו": true,
"דעכתי": true,
"דעכתם": true,
"דעכתן": true,
"דעתכם": true,
"דעתכן": true,
"דעתנו": true,
"דעתני": true,
"דפדוף": true,
"דפדפה": true,
"דפדפו": true,
"דפדפי": true,
"דפדפן": true,
"דפדפת": true,
"דפוסה": true,
"דפוסו": true,
"דפוסי": true,
"דפוסך": true,
"דפוסם": true,
"דפוסן": true,
"דפופה": true,
"דפוקה": true,
"דפיהם": true,
"דפיהן": true,
"דפייך": true,
"דפיכם": true,
"דפיכן": true,
"דפילו": true,
"דפינו": true,
"דפיקה": true,
"דפנות": true,
"דפסחא": true,
"דפקטה": true,
"דפקטו": true,
"דפקטי": true,
"דפקטך": true,
"דפקטם": true,
"דפקטן": true,
"דפקיה": true,
"דפקיו": true,
"דפקיי": true,
"דפקיך": true,
"דפקים": true,
"דפקנו": true,
"דפקתי": true,
"דפקתם": true,
"דפקתן": true,
"דציבל": true,
"דצמבר": true,
"דקדוק": true,
"דקדנס": true,
"דקדקה": true,
"דקדקו": true,
"דקדקי": true,
"דקדקן": true,
"דקדקת": true,
"דקוטה": true,
"דקורה": true,
"דקותה": true,
"דקותו": true,
"דקותי": true,
"דקותך": true,
"דקותם": true,
"דקותן": true,
"דקיקה": true,
"דקירה": true,
"דקלום": true,
"דקליה": true,
"דקליו": true,
"דקליי": true,
"דקליך": true,
"דקלים": true,
"דקלכם": true,
"דקלכן": true,
"דקלמה": true,
"דקלמו": true,
"דקלמי": true,
"דקלמת": true,
"דקלנו": true,
"דקנות": true,
"דקסיה": true,
"דקרון": true,
"דקרנו": true,
"דקרתי": true,
"דקרתם": true,
"דקרתן": true,
"דקתכם": true,
"דקתכן": true,
"דקתנו": true,
"דראון": true,
"דרבון": true,
"דרבנה": true,
"דרבנו": true,
"דרבני": true,
"דרבנך": true,
"דרבנם": true,
"דרבנן": true,
"דרבנת": true,
"דרגות": true,
"דרגיה": true,
"דרגיו": true,
"דרגיי": true,
"דרגיך": true,
"דרגים": true,
"דרגכם": true,
"דרגכן": true,
"דרגנה": true,
"דרגנו": true,
"דרגשה": true,
"דרגשו": true,
"דרגשי": true,
"דרגשך": true,
"דרגשם": true,
"דרגשן": true,
"דרגתה": true,
"דרגתו": true,
"דרגתי": true,
"דרגתך": true,
"דרגתם": true,
"דרגתן": true,
"דרדור": true,
"דרדסה": true,
"דרדסו": true,
"דרדסי": true,
"דרדסך": true,
"דרדסם": true,
"דרדסן": true,
"דרדקה": true,
"דרדקו": true,
"דרדקי": true,
"דרדקך": true,
"דרדקם": true,
"דרדקן": true,
"דרדרה": true,
"דרדרו": true,
"דרדרי": true,
"דרדרך": true,
"דרדרם": true,
"דרדרן": true,
"דרדרת": true,
"דרוזי": true,
"דרוכה": true,
"דרומה": true,
"דרומו": true,
"דרומי": true,
"דרומך": true,
"דרומם": true,
"דרומן": true,
"דרוסה": true,
"דרוקר": true,
"דרורה": true,
"דרורו": true,
"דרורי": true,
"דרורך": true,
"דרורם": true,
"דרורן": true,
"דרושה": true,
"דרזדן": true,
"דרייב": true,
"דריכה": true,
"דריכל": true,
"דריסה": true,
"דרישה": true,
"דרישת": true,
"דרכון": true,
"דרכיה": true,
"דרכיו": true,
"דרכיי": true,
"דרכיך": true,
"דרכים": true,
"דרככם": true,
"דרככן": true,
"דרכמה": true,
"דרכנו": true,
"דרכתי": true,
"דרכתם": true,
"דרכתן": true,
"דרמות": true,
"דרמטי": true,
"דרמיס": true,
"דרמתה": true,
"דרמתו": true,
"דרמתי": true,
"דרמתך": true,
"דרמתם": true,
"דרמתן": true,
"דרסטי": true,
"דרסנו": true,
"דרסתי": true,
"דרסתם": true,
"דרסתן": true,
"דרפור": true,
"דרפלר": true,
"דרציה": true,
"דרקון": true,
"דררות": true,
"דררתה": true,
"דררתו": true,
"דררתי": true,
"דררתך": true,
"דררתם": true,
"דררתן": true,
"דרשות": true,
"דרשנה": true,
"דרשנו": true,
"דרשני": true,
"דרשנך": true,
"דרשנם": true,
"דרשנן": true,
"דרשתה": true,
"דרשתו": true,
"דרשתי": true,
"דרשתך": true,
"דרשתם": true,
"דרשתן": true,
"דשאיה": true,
"דשאיו": true,
"דשאיי": true,
"דשאיך": true,
"דשאים": true,
"דשאכם": true,
"דשאכן": true,
"דשאנו": true,
"דשדוש": true,
"דשדשה": true,
"דשדשו": true,
"דשדשי": true,
"דשדשת": true,
"דשיהם": true,
"דשיהן": true,
"דשייך": true,
"דשיכם": true,
"דשיכן": true,
"דשינו": true,
"דשמיא": true,
"דשנות": true,
"דשניה": true,
"דשניו": true,
"דשניי": true,
"דשניך": true,
"דשנים": true,
"דשנכם": true,
"דשנכן": true,
"דשננו": true,
"דתיות": true,
"דתייה": true,
"דתיים": true,
"האבוד": true,
"האבול": true,
"האבות": true,
"האביב": true,
"האביס": true,
"האביק": true,
"האבסה": true,
"האבסת": true,
"האבקה": true,
"האבקת": true,
"האגדה": true,
"האגדי": true,
"האגרה": true,
"האדום": true,
"האדון": true,
"האדים": true,
"האדיר": true,
"האדמה": true,
"האדמו": true,
"האדמת": true,
"האדרה": true,
"האדרי": true,
"האדרת": true,
"האהבה": true,
"האהבת": true,
"האהוב": true,
"האהיב": true,
"האהיל": true,
"האהלה": true,
"האהלת": true,
"האוהל": true,
"האוור": true,
"האוזן": true,
"האוטו": true,
"האויב": true,
"האויר": true,
"האוכל": true,
"האולם": true,
"האומה": true,
"האומל": true,
"האומן": true,
"האומץ": true,
"האומר": true,
"האונס": true,
"האונק": true,
"האוסט": true,
"האוסף": true,
"האוסר": true,
"האופט": true,
"האופי": true,
"האופל": true,
"האופן": true,
"האופק": true,
"האוצר": true,
"האורג": true,
"האורז": true,
"האורח": true,
"האורך": true,
"האורת": true,
"האושר": true,
"האזור": true,
"האזין": true,
"האזנה": true,
"האזנו": true,
"האזנת": true,
"האזרח": true,
"האחדה": true,
"האחדת": true,
"האחוז": true,
"האחות": true,
"האחיד": true,
"האחים": true,
"האחרת": true,
"האטום": true,
"האטות": true,
"האטנה": true,
"האטנו": true,
"האטתה": true,
"האטתו": true,
"האטתי": true,
"האטתך": true,
"האטתם": true,
"האטתן": true,
"האיבה": true,
"האיבר": true,
"האידי": true,
"האיום": true,
"האיור": true,
"האיטי": true,
"האיים": true,
"האילן": true,
"האימה": true,
"האינד": true,
"האינט": true,
"האינס": true,
"האיצה": true,
"האיצו": true,
"האיצי": true,
"האירה": true,
"האירו": true,
"האירי": true,
"האישה": true,
"האישי": true,
"האכיל": true,
"האכלה": true,
"האכלת": true,
"האלהה": true,
"האלוה": true,
"האלון": true,
"האלוף": true,
"האלים": true,
"האלמו": true,
"האלקט": true,
"האמון": true,
"האמור": true,
"האמין": true,
"האמיר": true,
"האמית": true,
"האמנה": true,
"האמנו": true,
"האמנם": true,
"האמנת": true,
"האמצע": true,
"האמרה": true,
"האמרת": true,
"האמתי": true,
"האנדר": true,
"האנוש": true,
"האנטי": true,
"האניש": true,
"האנרג": true,
"האנשה": true,
"האנשת": true,
"האסון": true,
"האסור": true,
"האסטר": true,
"האסיר": true,
"האפור": true,
"האפיל": true,
"האפיק": true,
"האפיר": true,
"האפלה": true,
"האפלת": true,
"האפקט": true,
"האפרה": true,
"האפרו": true,
"האפרי": true,
"האפרת": true,
"האפשר": true,
"האצבע": true,
"האצות": true,
"האציל": true,
"האצלה": true,
"האצלת": true,
"האצנה": true,
"האצנו": true,
"האצתה": true,
"האצתו": true,
"האצתי": true,
"האצתך": true,
"האצתם": true,
"האצתן": true,
"האקדח": true,
"האקשן": true,
"הארבע": true,
"הארוך": true,
"הארון": true,
"הארוע": true,
"הארות": true,
"הארחה": true,
"האריה": true,
"האריך": true,
"האריס": true,
"האריק": true,
"הארכה": true,
"הארכת": true,
"הארנה": true,
"הארנו": true,
"הארנק": true,
"הארצי": true,
"הארקה": true,
"הארקת": true,
"הארתה": true,
"הארתו": true,
"הארתי": true,
"הארתך": true,
"הארתם": true,
"הארתן": true,
"האשים": true,
"האשכנ": true,
"האשמה": true,
"האשמי": true,
"האשמת": true,
"האשפה": true,
"האתגר": true,
"האתיו": true,
"הבאות": true,
"הבאים": true,
"הבאיש": true,
"הבאנה": true,
"הבאנו": true,
"הבאשה": true,
"הבאשת": true,
"הבאתה": true,
"הבאתו": true,
"הבאתי": true,
"הבאתך": true,
"הבאתם": true,
"הבאתן": true,
"הבבלי": true,
"הבדיל": true,
"הבדים": true,
"הבדלה": true,
"הבדלו": true,
"הבדלי": true,
"הבדלך": true,
"הבדלם": true,
"הבדלן": true,
"הבדלת": true,
"הבהבה": true,
"הבהבו": true,
"הבהבי": true,
"הבהבת": true,
"הבהוב": true,
"הבהיל": true,
"הבהיק": true,
"הבהיר": true,
"הבהלה": true,
"הבהלת": true,
"הבהמה": true,
"הבהקת": true,
"הבהרה": true,
"הבהרת": true,
"הבוגר": true,
"הבודד": true,
"הבודה": true,
"הבוחן": true,
"הבוחר": true,
"הבולט": true,
"הבועה": true,
"הבוקר": true,
"הבורא": true,
"הבורג": true,
"הבורר": true,
"הבושה": true,
"הבושם": true,
"הבזיק": true,
"הבזקה": true,
"הבזקו": true,
"הבזקי": true,
"הבזקך": true,
"הבזקם": true,
"הבזקן": true,
"הבזקת": true,
"הבחור": true,
"הבחיל": true,
"הבחין": true,
"הבחלה": true,
"הבחלת": true,
"הבחנה": true,
"הבחנו": true,
"הבחנת": true,
"הבטוח": true,
"הבטון": true,
"הבטות": true,
"הבטחה": true,
"הבטחת": true,
"הבטיח": true,
"הבטנה": true,
"הבטנו": true,
"הבטתה": true,
"הבטתו": true,
"הבטתי": true,
"הבטתך": true,
"הבטתם": true,
"הבטתן": true,
"הביאה": true,
"הביאו": true,
"הביאי": true,
"הביוב": true,
"הביון": true,
"הביוץ": true,
"הביור": true,
"הביטה": true,
"הביטו": true,
"הביטח": true,
"הביטי": true,
"הביכה": true,
"הביכו": true,
"הביכי": true,
"הבילה": true,
"הבימה": true,
"הבינה": true,
"הבינו": true,
"הביני": true,
"הביסה": true,
"הביסו": true,
"הביסי": true,
"הביעה": true,
"הביעו": true,
"הביעי": true,
"הביצה": true,
"הבירה": true,
"הביתה": true,
"הביתי": true,
"הבכור": true,
"הבכות": true,
"הבכיר": true,
"הבכנה": true,
"הבכנו": true,
"הבכרה": true,
"הבכרת": true,
"הבכתה": true,
"הבכתו": true,
"הבכתי": true,
"הבכתך": true,
"הבכתם": true,
"הבכתן": true,
"הבלגה": true,
"הבלגת": true,
"הבלוג": true,
"הבלוט": true,
"הבלוק": true,
"הבלות": true,
"הבלחה": true,
"הבלחו": true,
"הבלחי": true,
"הבלחך": true,
"הבלחם": true,
"הבלחן": true,
"הבלחת": true,
"הבלטה": true,
"הבלטת": true,
"הבליג": true,
"הבליה": true,
"הבליו": true,
"הבליח": true,
"הבליט": true,
"הבליי": true,
"הבליך": true,
"הבלים": true,
"הבליע": true,
"הבלכם": true,
"הבלכן": true,
"הבלנו": true,
"הבלעה": true,
"הבלעת": true,
"הבלקן": true,
"הבלתי": true,
"הבמאי": true,
"הבמות": true,
"הבנוי": true,
"הבנות": true,
"הבניה": true,
"הבנים": true,
"הבנין": true,
"הבנית": true,
"הבנתה": true,
"הבנתו": true,
"הבנתי": true,
"הבנתך": true,
"הבנתם": true,
"הבנתן": true,
"הבסות": true,
"הבסיס": true,
"הבסנה": true,
"הבסנו": true,
"הבסתה": true,
"הבסתו": true,
"הבסתי": true,
"הבסתך": true,
"הבסתם": true,
"הבסתן": true,
"הבעות": true,
"הבעיה": true,
"הבעיר": true,
"הבעית": true,
"הבענה": true,
"הבענו": true,
"הבערה": true,
"הבערת": true,
"הבעשט": true,
"הבעתה": true,
"הבעתו": true,
"הבעתי": true,
"הבעתך": true,
"הבעתם": true,
"הבעתן": true,
"הבקיע": true,
"הבקעה": true,
"הבקעת": true,
"הבקרה": true,
"הבקשה": true,
"הבראה": true,
"הבראת": true,
"הברגה": true,
"הברגת": true,
"הברון": true,
"הברור": true,
"הברות": true,
"הברזה": true,
"הברזל": true,
"הברזת": true,
"הברחה": true,
"הברחת": true,
"הבריא": true,
"הבריג": true,
"הבריז": true,
"הבריח": true,
"הבריק": true,
"הבריש": true,
"הברית": true,
"הברכה": true,
"הברקה": true,
"הברקת": true,
"הברשה": true,
"הברשת": true,
"הברתה": true,
"הברתו": true,
"הברתי": true,
"הברתך": true,
"הברתם": true,
"הברתן": true,
"הבשור": true,
"הבשיל": true,
"הבשלה": true,
"הבשלת": true,
"הבתים": true,
"הגאון": true,
"הגאיה": true,
"הגאיו": true,
"הגאיי": true,
"הגאיך": true,
"הגאים": true,
"הגאית": true,
"הגבהה": true,
"הגבהת": true,
"הגבוה": true,
"הגבול": true,
"הגבות": true,
"הגביה": true,
"הגביל": true,
"הגביע": true,
"הגביר": true,
"הגבלה": true,
"הגבלו": true,
"הגבלי": true,
"הגבלך": true,
"הגבלם": true,
"הגבלן": true,
"הגבלת": true,
"הגבנה": true,
"הגבנו": true,
"הגבעה": true,
"הגברה": true,
"הגברי": true,
"הגברת": true,
"הגבתי": true,
"הגבתם": true,
"הגבתן": true,
"הגגות": true,
"הגדוד": true,
"הגדול": true,
"הגדות": true,
"הגדיל": true,
"הגדיר": true,
"הגדיש": true,
"הגדלה": true,
"הגדלת": true,
"הגדנה": true,
"הגדנו": true,
"הגדרה": true,
"הגדרת": true,
"הגדשת": true,
"הגדתה": true,
"הגדתו": true,
"הגדתי": true,
"הגדתך": true,
"הגדתם": true,
"הגדתן": true,
"הגהות": true,
"הגהנה": true,
"הגהנו": true,
"הגהתה": true,
"הגהתו": true,
"הגהתי": true,
"הגהתך": true,
"הגהתם": true,
"הגהתן": true,
"הגואל": true,
"הגובה": true,
"הגובר": true,
"הגודל": true,
"הגוון": true,
"הגויה": true,
"הגוים": true,
"הגולה": true,
"הגולן": true,
"הגולש": true,
"הגומי": true,
"הגונה": true,
"הגופה": true,
"הגורל": true,
"הגורם": true,
"הגותה": true,
"הגותו": true,
"הגותי": true,
"הגותך": true,
"הגותם": true,
"הגותן": true,
"הגזים": true,
"הגזמה": true,
"הגזמת": true,
"הגזרה": true,
"הגחות": true,
"הגחיך": true,
"הגחכה": true,
"הגחכת": true,
"הגחנה": true,
"הגחנו": true,
"הגחתה": true,
"הגחתו": true,
"הגחתי": true,
"הגחתך": true,
"הגחתם": true,
"הגחתן": true,
"הגיבה": true,
"הגיבו": true,
"הגיבי": true,
"הגיגה": true,
"הגיגו": true,
"הגיגי": true,
"הגיגך": true,
"הגיגם": true,
"הגיגן": true,
"הגידה": true,
"הגידו": true,
"הגידי": true,
"הגיהה": true,
"הגיהו": true,
"הגיהי": true,
"הגיון": true,
"הגיוס": true,
"הגיור": true,
"הגיות": true,
"הגיחה": true,
"הגיחו": true,
"הגיחי": true,
"הגייה": true,
"הגייו": true,
"הגייך": true,
"הגיים": true,
"הגיין": true,
"הגינה": true,
"הגינו": true,
"הגיעה": true,
"הגיעו": true,
"הגיעי": true,
"הגיפה": true,
"הגיפו": true,
"הגיפי": true,
"הגירה": true,
"הגירו": true,
"הגירי": true,
"הגישה": true,
"הגישו": true,
"הגישי": true,
"הגיתי": true,
"הגיתם": true,
"הגיתן": true,
"הגלגל": true,
"הגלדה": true,
"הגלדת": true,
"הגלוב": true,
"הגלוי": true,
"הגלום": true,
"הגלות": true,
"הגליד": true,
"הגליה": true,
"הגליל": true,
"הגלים": true,
"הגליש": true,
"הגלית": true,
"הגלשה": true,
"הגלשת": true,
"הגלתה": true,
"הגמון": true,
"הגמור": true,
"הגמיש": true,
"הגמרא": true,
"הגמשה": true,
"הגמשת": true,
"הגנבה": true,
"הגנבת": true,
"הגנוז": true,
"הגנות": true,
"הגנטי": true,
"הגניב": true,
"הגנים": true,
"הגננת": true,
"הגנרל": true,
"הגנתה": true,
"הגנתו": true,
"הגנתי": true,
"הגנתך": true,
"הגנתם": true,
"הגנתן": true,
"הגעות": true,
"הגעיל": true,
"הגעיש": true,
"הגעלה": true,
"הגעלת": true,
"הגענה": true,
"הגענו": true,
"הגעשה": true,
"הגעשת": true,
"הגעתה": true,
"הגעתו": true,
"הגעתי": true,
"הגעתך": true,
"הגעתם": true,
"הגעתן": true,
"הגפות": true,
"הגפנה": true,
"הגפנו": true,
"הגפתה": true,
"הגפתו": true,
"הגפתי": true,
"הגפתך": true,
"הגפתם": true,
"הגפתן": true,
"הגראי": true,
"הגרון": true,
"הגרוע": true,
"הגרות": true,
"הגריל": true,
"הגרים": true,
"הגרלה": true,
"הגרלת": true,
"הגרנה": true,
"הגרנו": true,
"הגרסה": true,
"הגרעי": true,
"הגרפי": true,
"הגרתה": true,
"הגרתו": true,
"הגרתי": true,
"הגרתך": true,
"הגרתם": true,
"הגרתן": true,
"הגשות": true,
"הגשים": true,
"הגשמה": true,
"הגשמי": true,
"הגשמת": true,
"הגשנה": true,
"הגשנו": true,
"הגשתה": true,
"הגשתו": true,
"הגשתי": true,
"הגשתך": true,
"הגשתם": true,
"הגשתן": true,
"הדאבת": true,
"הדאגה": true,
"הדאגת": true,
"הדאיב": true,
"הדאיג": true,
"הדביק": true,
"הדביר": true,
"הדבקה": true,
"הדבקת": true,
"הדברה": true,
"הדברת": true,
"הדגים": true,
"הדגיר": true,
"הדגיש": true,
"הדגמה": true,
"הדגמת": true,
"הדגרה": true,
"הדגרת": true,
"הדגשה": true,
"הדגשו": true,
"הדגשי": true,
"הדגשך": true,
"הדגשם": true,
"הדגשן": true,
"הדגשת": true,
"הדדית": true,
"הדהדה": true,
"הדהדו": true,
"הדהדי": true,
"הדהדת": true,
"הדהוד": true,
"הדהים": true,
"הדהיר": true,
"הדהמה": true,
"הדהמת": true,
"הדהרה": true,
"הדהרת": true,
"הדואר": true,
"הדובר": true,
"הדודה": true,
"הדוכן": true,
"הדולר": true,
"הדומה": true,
"הדומו": true,
"הדומי": true,
"הדומך": true,
"הדומם": true,
"הדומן": true,
"הדופה": true,
"הדופן": true,
"הדופק": true,
"הדוקה": true,
"הדורה": true,
"הדורש": true,
"הדחות": true,
"הדחיק": true,
"הדחנה": true,
"הדחנו": true,
"הדחקה": true,
"הדחקת": true,
"הדחתה": true,
"הדחתו": true,
"הדחתי": true,
"הדחתך": true,
"הדחתם": true,
"הדחתן": true,
"הדיאל": true,
"הדיהם": true,
"הדיהן": true,
"הדיוט": true,
"הדיון": true,
"הדיוק": true,
"הדיור": true,
"הדיחה": true,
"הדיחו": true,
"הדיחי": true,
"הדייך": true,
"הדיין": true,
"הדייר": true,
"הדיכם": true,
"הדיכן": true,
"הדינו": true,
"הדיסק": true,
"הדיפה": true,
"הדיפו": true,
"הדיפי": true,
"הדיפל": true,
"הדיקט": true,
"הדירה": true,
"הדירו": true,
"הדירי": true,
"הדליף": true,
"הדליק": true,
"הדלפה": true,
"הדלפת": true,
"הדלקה": true,
"הדלקת": true,
"הדמות": true,
"הדמיה": true,
"הדמים": true,
"הדממה": true,
"הדממת": true,
"הדסיה": true,
"הדסיו": true,
"הדסיי": true,
"הדסיך": true,
"הדסים": true,
"הדסכם": true,
"הדסכן": true,
"הדסנה": true,
"הדסנו": true,
"הדעות": true,
"הדפוס": true,
"הדפות": true,
"הדפים": true,
"הדפיס": true,
"הדפכם": true,
"הדפכן": true,
"הדפנה": true,
"הדפנו": true,
"הדפסה": true,
"הדפסו": true,
"הדפסי": true,
"הדפסך": true,
"הדפסם": true,
"הדפסן": true,
"הדפסת": true,
"הדפתה": true,
"הדפתו": true,
"הדפתי": true,
"הדפתך": true,
"הדפתם": true,
"הדפתן": true,
"הדקות": true,
"הדקיה": true,
"הדקיו": true,
"הדקיי": true,
"הדקיך": true,
"הדקים": true,
"הדקכם": true,
"הדקכן": true,
"הדקנה": true,
"הדקנו": true,
"הדרגה": true,
"הדרום": true,
"הדרוש": true,
"הדרות": true,
"הדריה": true,
"הדריו": true,
"הדריי": true,
"הדריך": true,
"הדרים": true,
"הדרכה": true,
"הדרכם": true,
"הדרכן": true,
"הדרכת": true,
"הדרמה": true,
"הדרמת": true,
"הדרנה": true,
"הדרנו": true,
"הדרני": true,
"הדרנך": true,
"הדרנם": true,
"הדרנן": true,
"הדרתה": true,
"הדרתו": true,
"הדרתי": true,
"הדרתך": true,
"הדרתם": true,
"הדרתן": true,
"הדתות": true,
"הדתית": true,
"ההבדל": true,
"ההבנה": true,
"ההגדה": true,
"ההגנה": true,
"ההגעה": true,
"ההגשה": true,
"ההדחה": true,
"ההדיר": true,
"ההדרת": true,
"ההודי": true,
"ההווה": true,
"ההולך": true,
"ההונג": true,
"ההוצל": true,
"ההורה": true,
"ההחזר": true,
"ההטבה": true,
"ההיבט": true,
"ההיכל": true,
"ההיכר": true,
"ההינה": true,
"ההינו": true,
"ההיני": true,
"ההיסט": true,
"ההיפך": true,
"ההיצע": true,
"ההיקף": true,
"ההישג": true,
"ההיתר": true,
"ההכנה": true,
"ההכרה": true,
"ההכרח": true,
"ההליך": true,
"ההלכה": true,
"ההמון": true,
"ההמרה": true,
"ההמשך": true,
"ההנאה": true,
"ההנות": true,
"ההנחה": true,
"ההנעה": true,
"ההנקה": true,
"ההנתה": true,
"ההנתו": true,
"ההנתי": true,
"ההנתך": true,
"ההנתם": true,
"ההנתן": true,
"ההסבר": true,
"ההסדר": true,
"ההסכם": true,
"ההסתה": true,
"ההערה": true,
"ההפוך": true,
"ההפסד": true,
"ההפצה": true,
"ההפקה": true,
"ההפרה": true,
"ההפרש": true,
"ההצגה": true,
"ההצלה": true,
"ההצעה": true,
"ההקמה": true,
"ההקשר": true,
"ההרים": true,
"ההרכב": true,
"ההתרח": true,
"הואבס": true,
"הואבק": true,
"הואדר": true,
"הואחד": true,
"הואטה": true,
"הואטו": true,
"הואטת": true,
"הואיל": true,
"הואכל": true,
"הואלה": true,
"הואלת": true,
"הואמר": true,
"הואפל": true,
"הואצה": true,
"הואצו": true,
"הואצל": true,
"הואצת": true,
"הוארה": true,
"הוארו": true,
"הוארך": true,
"הוארק": true,
"הוארת": true,
"הואשם": true,
"הובאה": true,
"הובאו": true,
"הובאש": true,
"הובאת": true,
"הובדל": true,
"הובהל": true,
"הובהק": true,
"הובהר": true,
"הובזק": true,
"הובחל": true,
"הובחן": true,
"הובטח": true,
"הובטל": true,
"הוביט": true,
"הוביל": true,
"הובכה": true,
"הובכו": true,
"הובכת": true,
"הובלג": true,
"הובלה": true,
"הובלו": true,
"הובלט": true,
"הובלע": true,
"הובלת": true,
"הובנה": true,
"הובנו": true,
"הובנת": true,
"הובסה": true,
"הובסו": true,
"הובסת": true,
"הובעה": true,
"הובעו": true,
"הובער": true,
"הובעת": true,
"הובקע": true,
"הוברג": true,
"הוברז": true,
"הוברח": true,
"הוברק": true,
"הוברר": true,
"הוברש": true,
"הוגבה": true,
"הוגבל": true,
"הוגבר": true,
"הוגדה": true,
"הוגדו": true,
"הוגדל": true,
"הוגדר": true,
"הוגדש": true,
"הוגדת": true,
"הוגהה": true,
"הוגהו": true,
"הוגהת": true,
"הוגות": true,
"הוגזם": true,
"הוגים": true,
"הוגיע": true,
"הוגלה": true,
"הוגלו": true,
"הוגלש": true,
"הוגמש": true,
"הוגנב": true,
"הוגנה": true,
"הוגנו": true,
"הוגנת": true,
"הוגעה": true,
"הוגעל": true,
"הוגעת": true,
"הוגפה": true,
"הוגפו": true,
"הוגפת": true,
"הוגרה": true,
"הוגרו": true,
"הוגרל": true,
"הוגרת": true,
"הוגשה": true,
"הוגשו": true,
"הוגשם": true,
"הוגשת": true,
"הודאג": true,
"הודאה": true,
"הודבק": true,
"הודבר": true,
"הודגם": true,
"הודגר": true,
"הודגש": true,
"הודהר": true,
"הודות": true,
"הודחה": true,
"הודחו": true,
"הודחק": true,
"הודחת": true,
"הודיה": true,
"הודים": true,
"הודיע": true,
"הודית": true,
"הודכם": true,
"הודכן": true,
"הודלף": true,
"הודלק": true,
"הודמם": true,
"הודנה": true,
"הודנו": true,
"הודעה": true,
"הודעת": true,
"הודפס": true,
"הודפת": true,
"הודקה": true,
"הודקו": true,
"הודקת": true,
"הודרה": true,
"הודרו": true,
"הודרך": true,
"הודרת": true,
"הודתה": true,
"הוהדר": true,
"הוואי": true,
"הוויה": true,
"הוויו": true,
"הוויז": true,
"הוויי": true,
"הוויר": true,
"הוונה": true,
"הוונו": true,
"הווני": true,
"הוונת": true,
"הוועד": true,
"הוורד": true,
"הוזהב": true,
"הוזהר": true,
"הוזות": true,
"הוזזה": true,
"הוזזו": true,
"הוזזת": true,
"הוזחה": true,
"הוזחו": true,
"הוזחת": true,
"הוזיל": true,
"הוזים": true,
"הוזכר": true,
"הוזלה": true,
"הוזלו": true,
"הוזלף": true,
"הוזלת": true,
"הוזמה": true,
"הוזמו": true,
"הוזמן": true,
"הוזמת": true,
"הוזנה": true,
"הוזנו": true,
"הוזנח": true,
"הוזנק": true,
"הוזנת": true,
"הוזעק": true,
"הוזקף": true,
"הוזרם": true,
"הוזרע": true,
"הוזרק": true,
"הוחבא": true,
"הוחדר": true,
"הוחזק": true,
"הוחזר": true,
"הוחטא": true,
"הוחיה": true,
"הוחיו": true,
"הוחיל": true,
"הוחכר": true,
"הוחלה": true,
"הוחלו": true,
"הוחלט": true,
"הוחלף": true,
"הוחלק": true,
"הוחלש": true,
"הוחלת": true,
"הוחמא": true,
"הוחמץ": true,
"הוחמר": true,
"הוחנה": true,
"הוחנו": true,
"הוחנק": true,
"הוחסר": true,
"הוחצן": true,
"הוחרב": true,
"הוחרג": true,
"הוחרד": true,
"הוחרם": true,
"הוחרף": true,
"הוחרש": true,
"הוחשד": true,
"הוחשה": true,
"הוחשו": true,
"הוחשך": true,
"הוחשת": true,
"הוחתם": true,
"הוטבה": true,
"הוטבו": true,
"הוטבל": true,
"הוטבע": true,
"הוטבת": true,
"הוטחה": true,
"הוטחו": true,
"הוטחת": true,
"הוטית": true,
"הוטלא": true,
"הוטלה": true,
"הוטלו": true,
"הוטלת": true,
"הוטמן": true,
"הוטמע": true,
"הוטסה": true,
"הוטסו": true,
"הוטסת": true,
"הוטעה": true,
"הוטעו": true,
"הוטעם": true,
"הוטען": true,
"הוטפה": true,
"הוטפו": true,
"הוטפת": true,
"הוטרד": true,
"הוטרח": true,
"הוטרי": true,
"הוטרף": true,
"הוטתה": true,
"הוילה": true,
"הוירט": true,
"הוכבד": true,
"הוכהה": true,
"הוכהו": true,
"הוכחד": true,
"הוכחה": true,
"הוכחו": true,
"הוכחש": true,
"הוכחת": true,
"הוכיח": true,
"הוכית": true,
"הוכלב": true,
"הוכלה": true,
"הוכלו": true,
"הוכלל": true,
"הוכלם": true,
"הוכלת": true,
"הוכמן": true,
"הוכנה": true,
"הוכנו": true,
"הוכנס": true,
"הוכנע": true,
"הוכנת": true,
"הוכסף": true,
"הוכעס": true,
"הוכפל": true,
"הוכפף": true,
"הוכפש": true,
"הוכרה": true,
"הוכרו": true,
"הוכרז": true,
"הוכרח": true,
"הוכרע": true,
"הוכרת": true,
"הוכשה": true,
"הוכשו": true,
"הוכשל": true,
"הוכשר": true,
"הוכשת": true,
"הוכתב": true,
"הוכתה": true,
"הוכתם": true,
"הוכתר": true,
"הולאם": true,
"הולבן": true,
"הולבש": true,
"הולדה": true,
"הולדת": true,
"הולהב": true,
"הולהט": true,
"הולוו": true,
"הולחם": true,
"הולחן": true,
"הולחץ": true,
"הולטה": true,
"הולטו": true,
"הולטת": true,
"הוליד": true,
"הוליו": true,
"הוליך": true,
"הולכה": true,
"הולכו": true,
"הולכי": true,
"הולכת": true,
"הוללה": true,
"הוללו": true,
"הוללת": true,
"הולמס": true,
"הולמת": true,
"הולנד": true,
"הולנה": true,
"הולנו": true,
"הולנת": true,
"הולעט": true,
"הולקה": true,
"הולקו": true,
"הומאס": true,
"הומגן": true,
"הומור": true,
"הומות": true,
"הומחז": true,
"הומחש": true,
"הומטה": true,
"הומטו": true,
"הומטר": true,
"הומטת": true,
"הומיה": true,
"הומים": true,
"הומלח": true,
"הומלט": true,
"הומלך": true,
"הומלס": true,
"הומלץ": true,
"הוממה": true,
"הוממו": true,
"הוממת": true,
"הומני": true,
"הומסה": true,
"הומסו": true,
"הומסת": true,
"הומעד": true,
"הומעט": true,
"הומצא": true,
"הומרד": true,
"הומרה": true,
"הומרו": true,
"הומרץ": true,
"הומרת": true,
"הומשל": true,
"הומתה": true,
"הומתו": true,
"הומתי": true,
"הומתם": true,
"הומתן": true,
"הומתק": true,
"הונאה": true,
"הונאו": true,
"הונאת": true,
"הונבט": true,
"הונדה": true,
"הונדס": true,
"הונהג": true,
"הונחה": true,
"הונחו": true,
"הונחל": true,
"הונחת": true,
"הונים": true,
"הונית": true,
"הונכם": true,
"הונכן": true,
"הונמך": true,
"הוננו": true,
"הונסה": true,
"הונסו": true,
"הונסת": true,
"הונעה": true,
"הונעו": true,
"הונעם": true,
"הונעת": true,
"הונפה": true,
"הונפו": true,
"הונפק": true,
"הונפש": true,
"הונפת": true,
"הונצח": true,
"הונקה": true,
"הונקו": true,
"הונקת": true,
"הונשם": true,
"הונתה": true,
"הוסבה": true,
"הוסבו": true,
"הוסבר": true,
"הוסבת": true,
"הוסגה": true,
"הוסגו": true,
"הוסגר": true,
"הוסגת": true,
"הוסדר": true,
"הוסוו": true,
"הוסחה": true,
"הוסחו": true,
"הוסחת": true,
"הוסטה": true,
"הוסטו": true,
"הוסטל": true,
"הוסטת": true,
"הוסיף": true,
"הוסית": true,
"הוסכם": true,
"הוסלם": true,
"הוסלק": true,
"הוסמך": true,
"הוסעה": true,
"הוסעו": true,
"הוסער": true,
"הוסעת": true,
"הוספג": true,
"הוספד": true,
"הוספה": true,
"הוספו": true,
"הוספת": true,
"הוסקה": true,
"הוסקו": true,
"הוסקת": true,
"הוסרה": true,
"הוסרו": true,
"הוסרט": true,
"הוסרת": true,
"הוסתה": true,
"הוסתו": true,
"הוסתי": true,
"הוסתם": true,
"הוסתן": true,
"הוסתר": true,
"הועבד": true,
"הועבה": true,
"הועבו": true,
"הועבר": true,
"הועבת": true,
"הועדה": true,
"הועדו": true,
"הועדף": true,
"הועדת": true,
"הועזב": true,
"הועיד": true,
"הועיל": true,
"הועלה": true,
"הועלו": true,
"הועלם": true,
"הועלת": true,
"הועמד": true,
"הועמה": true,
"הועמו": true,
"הועמס": true,
"הועמק": true,
"הועמת": true,
"הוענק": true,
"הוענש": true,
"הועסק": true,
"הועפה": true,
"הועפו": true,
"הועפת": true,
"הועצב": true,
"הועצם": true,
"הוערה": true,
"הוערו": true,
"הוערך": true,
"הוערם": true,
"הוערת": true,
"הועשר": true,
"הועתק": true,
"הועתר": true,
"הופגה": true,
"הופגו": true,
"הופגז": true,
"הופגן": true,
"הופגש": true,
"הופגת": true,
"הופחד": true,
"הופחה": true,
"הופחו": true,
"הופחת": true,
"הופטר": true,
"הופיע": true,
"הופכת": true,
"הופלג": true,
"הופלה": true,
"הופלו": true,
"הופלט": true,
"הופלל": true,
"הופלת": true,
"הופמן": true,
"הופנה": true,
"הופנו": true,
"הופנט": true,
"הופנם": true,
"הופסה": true,
"הופסו": true,
"הופסק": true,
"הופסת": true,
"הופעה": true,
"הופעל": true,
"הופעם": true,
"הופעת": true,
"הופצה": true,
"הופצו": true,
"הופצץ": true,
"הופצת": true,
"הופקד": true,
"הופקה": true,
"הופקו": true,
"הופקע": true,
"הופקר": true,
"הופקת": true,
"הופרד": true,
"הופרה": true,
"הופרו": true,
"הופרז": true,
"הופרח": true,
"הופרט": true,
"הופרך": true,
"הופרע": true,
"הופרש": true,
"הופרת": true,
"הופשט": true,
"הופשל": true,
"הופשר": true,
"הופתע": true,
"הוצאה": true,
"הוצאו": true,
"הוצאת": true,
"הוצבה": true,
"הוצבו": true,
"הוצבע": true,
"הוצבת": true,
"הוצגה": true,
"הוצגו": true,
"הוצגת": true,
"הוצדק": true,
"הוצהב": true,
"הוצהר": true,
"הוציא": true,
"הוצלב": true,
"הוצלה": true,
"הוצלו": true,
"הוצלת": true,
"הוצמד": true,
"הוצמת": true,
"הוצנח": true,
"הוצנע": true,
"הוצעד": true,
"הוצעה": true,
"הוצעו": true,
"הוצעת": true,
"הוצפה": true,
"הוצפו": true,
"הוצפן": true,
"הוצפת": true,
"הוצרה": true,
"הוצרו": true,
"הוצרך": true,
"הוצרת": true,
"הוצתה": true,
"הוצתו": true,
"הוצתי": true,
"הוצתם": true,
"הוצתן": true,
"הוקאה": true,
"הוקאו": true,
"הוקאת": true,
"הוקדח": true,
"הוקדם": true,
"הוקדש": true,
"הוקהה": true,
"הוקהו": true,
"הוקהל": true,
"הוקזה": true,
"הוקזו": true,
"הוקזת": true,
"הוקטן": true,
"הוקטר": true,
"הוקיע": true,
"הוקיר": true,
"הוקלד": true,
"הוקלה": true,
"הוקלו": true,
"הוקלט": true,
"הוקלת": true,
"הוקמה": true,
"הוקמו": true,
"הוקמת": true,
"הוקנה": true,
"הוקנו": true,
"הוקנט": true,
"הוקסם": true,
"הוקעה": true,
"הוקעו": true,
"הוקעת": true,
"הוקפא": true,
"הוקפד": true,
"הוקפה": true,
"הוקפו": true,
"הוקפץ": true,
"הוקפת": true,
"הוקצב": true,
"הוקצה": true,
"הוקצו": true,
"הוקצן": true,
"הוקצע": true,
"הוקצף": true,
"הוקצת": true,
"הוקרא": true,
"הוקרב": true,
"הוקרה": true,
"הוקרם": true,
"הוקרן": true,
"הוקרש": true,
"הוקרת": true,
"הוקשה": true,
"הוקשו": true,
"הוקשח": true,
"הוקשת": true,
"הוראה": true,
"הוראו": true,
"הוראת": true,
"הורבע": true,
"הורגה": true,
"הורגו": true,
"הורגי": true,
"הורגך": true,
"הורגל": true,
"הורגם": true,
"הורגן": true,
"הורגש": true,
"הורגת": true,
"הורדה": true,
"הורדו": true,
"הורדם": true,
"הורדת": true,
"הורהו": true,
"הורוו": true,
"הורות": true,
"הורחב": true,
"הורחק": true,
"הורטב": true,
"הוריד": true,
"הוריה": true,
"הוריו": true,
"הוריי": true,
"הוריך": true,
"הורים": true,
"הוריק": true,
"הוריש": true,
"הורית": true,
"הורכב": true,
"הורכם": true,
"הורכן": true,
"הורמה": true,
"הורמו": true,
"הורמת": true,
"הורנו": true,
"הורסת": true,
"הורעב": true,
"הורעד": true,
"הורעה": true,
"הורעו": true,
"הורעל": true,
"הורעף": true,
"הורעש": true,
"הורעת": true,
"הורצה": true,
"הורצו": true,
"הורצת": true,
"הורקד": true,
"הורקה": true,
"הורקו": true,
"הורקת": true,
"הורשה": true,
"הורשו": true,
"הורשע": true,
"הורשת": true,
"הורתה": true,
"הורתו": true,
"הורתח": true,
"הורתי": true,
"הורתך": true,
"הורתם": true,
"הורתן": true,
"הורתע": true,
"הושאה": true,
"הושאו": true,
"הושאל": true,
"הושאף": true,
"הושאר": true,
"הושאת": true,
"הושבה": true,
"הושבו": true,
"הושבח": true,
"הושבע": true,
"הושבת": true,
"הושגה": true,
"הושגו": true,
"הושגח": true,
"הושגת": true,
"הושהה": true,
"הושהו": true,
"הושוו": true,
"הושחז": true,
"הושחל": true,
"הושחם": true,
"הושחר": true,
"הושחת": true,
"הושטה": true,
"הושטו": true,
"הושטת": true,
"הושיב": true,
"הושיט": true,
"הושיע": true,
"הושכב": true,
"הושכח": true,
"הושכל": true,
"הושכם": true,
"הושכן": true,
"הושכר": true,
"הושלג": true,
"הושלה": true,
"הושלו": true,
"הושלט": true,
"הושלך": true,
"הושלם": true,
"הושלת": true,
"הושמד": true,
"הושמה": true,
"הושמו": true,
"הושמט": true,
"הושמע": true,
"הושמץ": true,
"הושמש": true,
"הושמת": true,
"הושעה": true,
"הושעו": true,
"הושען": true,
"הושעת": true,
"הושפל": true,
"הושפע": true,
"הושקה": true,
"הושקו": true,
"הושקט": true,
"הושקע": true,
"הושקת": true,
"הושרה": true,
"הושרו": true,
"הושרץ": true,
"הושרש": true,
"הושרת": true,
"הושתה": true,
"הושתו": true,
"הושתל": true,
"הושתן": true,
"הושתק": true,
"הושתת": true,
"הותאם": true,
"הותוו": true,
"הותזה": true,
"הותזו": true,
"הותזת": true,
"הותחל": true,
"הותיק": true,
"הותיר": true,
"הותכה": true,
"הותכו": true,
"הותכת": true,
"הותמר": true,
"הותנה": true,
"הותנו": true,
"הותנע": true,
"הותסס": true,
"הותפח": true,
"הותפל": true,
"הותקל": true,
"הותקן": true,
"הותקף": true,
"הותרה": true,
"הותרו": true,
"הותרת": true,
"הותשה": true,
"הותשו": true,
"הותשת": true,
"הזדהה": true,
"הזדהו": true,
"הזדהי": true,
"הזדהם": true,
"הזדחל": true,
"הזדכה": true,
"הזדכו": true,
"הזדכי": true,
"הזדכך": true,
"הזדמן": true,
"הזדמר": true,
"הזדנב": true,
"הזדנה": true,
"הזדנו": true,
"הזדעף": true,
"הזדעק": true,
"הזדקן": true,
"הזדקף": true,
"הזדקק": true,
"הזדקר": true,
"הזדרז": true,
"הזדתי": true,
"הזדתם": true,
"הזדתן": true,
"הזהבה": true,
"הזהבת": true,
"הזהות": true,
"הזהיב": true,
"הזהיר": true,
"הזהרה": true,
"הזהרת": true,
"הזוגי": true,
"הזוהר": true,
"הזויה": true,
"הזוכה": true,
"הזולה": true,
"הזולת": true,
"הזורע": true,
"הזזות": true,
"הזזנה": true,
"הזזנו": true,
"הזזתה": true,
"הזזתו": true,
"הזזתי": true,
"הזזתך": true,
"הזזתם": true,
"הזזתן": true,
"הזחות": true,
"הזחנה": true,
"הזחנו": true,
"הזחתה": true,
"הזחתו": true,
"הזחתי": true,
"הזחתך": true,
"הזחתם": true,
"הזחתן": true,
"הזידה": true,
"הזידו": true,
"הזידי": true,
"הזיון": true,
"הזיות": true,
"הזיזה": true,
"הזיזו": true,
"הזיזי": true,
"הזיחה": true,
"הזיחו": true,
"הזיחי": true,
"הזילה": true,
"הזילו": true,
"הזילי": true,
"הזינה": true,
"הזינו": true,
"הזיני": true,
"הזיעה": true,
"הזיעו": true,
"הזיעי": true,
"הזיקה": true,
"הזיקו": true,
"הזיקי": true,
"הזירה": true,
"הזיתי": true,
"הזיתם": true,
"הזיתן": true,
"הזכות": true,
"הזכיר": true,
"הזכרה": true,
"הזכרת": true,
"הזלות": true,
"הזליף": true,
"הזלנה": true,
"הזלנו": true,
"הזלפה": true,
"הזלפת": true,
"הזלתה": true,
"הזלתו": true,
"הזלתי": true,
"הזלתך": true,
"הזלתם": true,
"הזלתן": true,
"הזמות": true,
"הזמין": true,
"הזמנה": true,
"הזמנו": true,
"הזמני": true,
"הזמנת": true,
"הזמרת": true,
"הזמתה": true,
"הזמתו": true,
"הזמתי": true,
"הזמתך": true,
"הזמתם": true,
"הזמתן": true,
"הזנות": true,
"הזנחה": true,
"הזנחת": true,
"הזניה": true,
"הזניח": true,
"הזניק": true,
"הזנית": true,
"הזנקה": true,
"הזנקת": true,
"הזנתה": true,
"הזנתו": true,
"הזנתי": true,
"הזנתך": true,
"הזנתם": true,
"הזנתן": true,
"הזעות": true,
"הזעים": true,
"הזעיף": true,
"הזעיק": true,
"הזעיר": true,
"הזעמה": true,
"הזעמת": true,
"הזענה": true,
"הזענו": true,
"הזעפה": true,
"הזעפת": true,
"הזעקה": true,
"הזעקת": true,
"הזעתה": true,
"הזעתו": true,
"הזעתי": true,
"הזעתך": true,
"הזעתם": true,
"הזעתן": true,
"הזקות": true,
"הזקין": true,
"הזקיף": true,
"הזקנה": true,
"הזקנו": true,
"הזקנת": true,
"הזקפה": true,
"הזקפת": true,
"הזקתה": true,
"הזקתו": true,
"הזקתי": true,
"הזקתך": true,
"הזקתם": true,
"הזקתן": true,
"הזרוע": true,
"הזרות": true,
"הזרים": true,
"הזריע": true,
"הזריק": true,
"הזרמה": true,
"הזרמת": true,
"הזרעה": true,
"הזרעת": true,
"הזרקה": true,
"הזרקת": true,
"החבאה": true,
"החבאת": true,
"החביא": true,
"החביב": true,
"החברה": true,
"החגים": true,
"החדיר": true,
"החדרה": true,
"החדרת": true,
"החדשה": true,
"החובה": true,
"החודש": true,
"החווה": true,
"החווי": true,
"החוור": true,
"החוזה": true,
"החוזק": true,
"החוזר": true,
"החולה": true,
"החולף": true,
"החומה": true,
"החומר": true,
"החופה": true,
"החופש": true,
"החוצה": true,
"החוקה": true,
"החוקי": true,
"החוקר": true,
"החורג": true,
"החורף": true,
"החורש": true,
"החושך": true,
"החותם": true,
"החזון": true,
"החזיק": true,
"החזיר": true,
"החזית": true,
"החזקה": true,
"החזקת": true,
"החזרה": true,
"החזרו": true,
"החזרי": true,
"החזרך": true,
"החזרם": true,
"החזרן": true,
"החזרת": true,
"החטאה": true,
"החטאת": true,
"החטיא": true,
"החטיף": true,
"החטפת": true,
"החידה": true,
"החיוב": true,
"החיוך": true,
"החיות": true,
"החיטה": true,
"החייב": true,
"החייה": true,
"החייל": true,
"החיים": true,
"החיית": true,
"החילה": true,
"החילו": true,
"החילי": true,
"החישה": true,
"החישו": true,
"החישי": true,
"החכים": true,
"החכיר": true,
"החכמה": true,
"החכמת": true,
"החכרה": true,
"החכרת": true,
"החלאת": true,
"החלדה": true,
"החלדת": true,
"החלום": true,
"החלון": true,
"החלוץ": true,
"החלות": true,
"החלטה": true,
"החלטי": true,
"החלטת": true,
"החליא": true,
"החליד": true,
"החליט": true,
"החלים": true,
"החליף": true,
"החליק": true,
"החליש": true,
"החלמה": true,
"החלמת": true,
"החלנה": true,
"החלנו": true,
"החלפה": true,
"החלפת": true,
"החלקה": true,
"החלקת": true,
"החלשה": true,
"החלשת": true,
"החלתה": true,
"החלתו": true,
"החלתי": true,
"החלתך": true,
"החלתם": true,
"החלתן": true,
"החמאה": true,
"החמאס": true,
"החמאת": true,
"החמור": true,
"החמות": true,
"החמיא": true,
"החמים": true,
"החמיץ": true,
"החמיר": true,
"החממה": true,
"החמצה": true,
"החמצן": true,
"החמצת": true,
"החמרה": true,
"החמרת": true,
"החנות": true,
"החניה": true,
"החניף": true,
"החניק": true,
"החנית": true,
"החנפת": true,
"החנקה": true,
"החנקת": true,
"החנתה": true,
"החסות": true,
"החסיד": true,
"החסיר": true,
"החסרה": true,
"החסרת": true,
"החצין": true,
"החציף": true,
"החצנה": true,
"החצנו": true,
"החצנת": true,
"החצפה": true,
"החצפת": true,
"החקלא": true,
"החרבה": true,
"החרבת": true,
"החרגה": true,
"החרגת": true,
"החרדה": true,
"החרדי": true,
"החרדת": true,
"החריב": true,
"החריג": true,
"החריד": true,
"החרים": true,
"החריף": true,
"החריש": true,
"החרמה": true,
"החרמת": true,
"החרפה": true,
"החרפת": true,
"החרשה": true,
"החרשת": true,
"החשבה": true,
"החשבת": true,
"החשדה": true,
"החשדת": true,
"החשוב": true,
"החשוד": true,
"החשות": true,
"החשיב": true,
"החשיד": true,
"החשיה": true,
"החשיך": true,
"החשית": true,
"החשכה": true,
"החשכת": true,
"החשמל": true,
"החשנה": true,
"החשנו": true,
"החשתה": true,
"החשתו": true,
"החשתי": true,
"החשתך": true,
"החשתם": true,
"החשתן": true,
"החתול": true,
"החתום": true,
"החתים": true,
"החתלה": true,
"החתמה": true,
"החתמת": true,
"הטבור": true,
"הטבות": true,
"הטביל": true,
"הטביע": true,
"הטבלה": true,
"הטבלת": true,
"הטבנה": true,
"הטבנו": true,
"הטבעה": true,
"הטבעי": true,
"הטבעת": true,
"הטבתה": true,
"הטבתו": true,
"הטבתי": true,
"הטבתך": true,
"הטבתם": true,
"הטבתן": true,
"הטהור": true,
"הטובה": true,
"הטווח": true,
"הטוטו": true,
"הטוען": true,
"הטופס": true,
"הטחות": true,
"הטחנה": true,
"הטחנו": true,
"הטחתה": true,
"הטחתו": true,
"הטחתי": true,
"הטחתך": true,
"הטחתם": true,
"הטחתן": true,
"הטיבה": true,
"הטיבו": true,
"הטיבי": true,
"הטיול": true,
"הטיות": true,
"הטיחה": true,
"הטיחו": true,
"הטיחי": true,
"הטייס": true,
"הטילה": true,
"הטילו": true,
"הטילי": true,
"הטינה": true,
"הטינו": true,
"הטיסה": true,
"הטיסו": true,
"הטיסי": true,
"הטיפה": true,
"הטיפו": true,
"הטיפי": true,
"הטירה": true,
"הטיתי": true,
"הטיתם": true,
"הטיתן": true,
"הטכני": true,
"הטלאה": true,
"הטלאת": true,
"הטלות": true,
"הטליא": true,
"הטלנה": true,
"הטלנו": true,
"הטלתה": true,
"הטלתו": true,
"הטלתי": true,
"הטלתך": true,
"הטלתם": true,
"הטלתן": true,
"הטמון": true,
"הטמין": true,
"הטמיע": true,
"הטמנה": true,
"הטמנו": true,
"הטמנת": true,
"הטמעה": true,
"הטמעת": true,
"הטמפל": true,
"הטניס": true,
"הטסות": true,
"הטסנה": true,
"הטסנו": true,
"הטסתה": true,
"הטסתו": true,
"הטסתי": true,
"הטסתך": true,
"הטסתם": true,
"הטסתן": true,
"הטעות": true,
"הטעיה": true,
"הטעים": true,
"הטעין": true,
"הטעית": true,
"הטעמה": true,
"הטעמת": true,
"הטענה": true,
"הטענו": true,
"הטענת": true,
"הטעתה": true,
"הטפות": true,
"הטפנה": true,
"הטפנו": true,
"הטפתה": true,
"הטפתו": true,
"הטפתי": true,
"הטפתך": true,
"הטפתם": true,
"הטפתן": true,
"הטקסט": true,
"הטרדה": true,
"הטרדת": true,
"הטרור": true,
"הטרחה": true,
"הטרחת": true,
"הטריד": true,
"הטריח": true,
"הטריל": true,
"הטרים": true,
"הטריף": true,
"הטרנד": true,
"הטרנס": true,
"הטרפת": true,
"היאבק": true,
"היאגר": true,
"היאהב": true,
"היאהד": true,
"היאור": true,
"היאות": true,
"היאזק": true,
"היאזר": true,
"היאחז": true,
"היאטם": true,
"היאכל": true,
"היאכף": true,
"היאלם": true,
"היאלץ": true,
"היאמד": true,
"היאמן": true,
"היאמר": true,
"היאנח": true,
"היאנס": true,
"היאנק": true,
"היאסף": true,
"היאסר": true,
"היאפה": true,
"היאפו": true,
"היאפי": true,
"היאצר": true,
"היארג": true,
"היארז": true,
"היאשם": true,
"היבאש": true,
"היבגד": true,
"היבדל": true,
"היבדק": true,
"היבהל": true,
"היבוא": true,
"היבוך": true,
"היבזז": true,
"היבזק": true,
"היבחן": true,
"היבחר": true,
"היבחש": true,
"היבטה": true,
"היבטו": true,
"היבטי": true,
"היבטך": true,
"היבטם": true,
"היבטן": true,
"היבלל": true,
"היבלם": true,
"היבלע": true,
"היבנה": true,
"היבנו": true,
"היבני": true,
"היבעה": true,
"היבעו": true,
"היבעט": true,
"היבעי": true,
"היבעל": true,
"היבעת": true,
"היבצע": true,
"היבצר": true,
"היבקע": true,
"היברא": true,
"היברי": true,
"היבשה": true,
"היבשת": true,
"היגאל": true,
"היגבה": true,
"היגבו": true,
"היגבי": true,
"היגדה": true,
"היגדו": true,
"היגדי": true,
"היגדך": true,
"היגדם": true,
"היגדן": true,
"היגדע": true,
"היגוז": true,
"היגוי": true,
"היגול": true,
"היגזז": true,
"היגזל": true,
"היגזם": true,
"היגזר": true,
"היגלה": true,
"היגלו": true,
"היגלי": true,
"היגלל": true,
"היגמל": true,
"היגמע": true,
"היגמר": true,
"היגנב": true,
"היגנז": true,
"היגעל": true,
"היגרב": true,
"היגרה": true,
"היגרו": true,
"היגרם": true,
"היגרס": true,
"היגרע": true,
"היגרף": true,
"היגרר": true,
"היגרת": true,
"הידבק": true,
"הידבר": true,
"הידגם": true,
"הידהד": true,
"הידהם": true,
"הידוד": true,
"הידון": true,
"הידוס": true,
"הידוע": true,
"הידוק": true,
"הידור": true,
"הידחה": true,
"הידחו": true,
"הידחי": true,
"הידחס": true,
"הידחף": true,
"הידחק": true,
"הידית": true,
"הידלה": true,
"הידלו": true,
"הידלי": true,
"הידלל": true,
"הידלק": true,
"הידמה": true,
"הידמו": true,
"הידמי": true,
"הידסה": true,
"הידסו": true,
"הידסת": true,
"הידעת": true,
"הידפס": true,
"הידפק": true,
"הידקה": true,
"הידקו": true,
"הידקר": true,
"הידקת": true,
"הידרא": true,
"הידרה": true,
"הידרו": true,
"הידרך": true,
"הידרס": true,
"הידרש": true,
"הידרת": true,
"היהגה": true,
"היהגו": true,
"היהגי": true,
"היהדף": true,
"היהנה": true,
"היהנו": true,
"היהני": true,
"היהפך": true,
"היהרג": true,
"היהרס": true,
"היובל": true,
"היוגה": true,
"היודע": true,
"היווה": true,
"היוון": true,
"היולי": true,
"היומי": true,
"היומן": true,
"היועמ": true,
"היועץ": true,
"היופי": true,
"היוצא": true,
"היוצר": true,
"היוקר": true,
"היורד": true,
"היורה": true,
"היורו": true,
"היורש": true,
"היושב": true,
"היותה": true,
"היותו": true,
"היותי": true,
"היותך": true,
"היותם": true,
"היותן": true,
"היותר": true,
"היזבח": true,
"היזהר": true,
"היזון": true,
"היזכר": true,
"היזלל": true,
"היזמר": true,
"היזנח": true,
"היזעק": true,
"היזקף": true,
"היזקק": true,
"היזרע": true,
"היזרק": true,
"היחבא": true,
"היחבט": true,
"היחבל": true,
"היחבש": true,
"היחגג": true,
"היחגר": true,
"היחוג": true,
"היחוו": true,
"היחזה": true,
"היחזו": true,
"היחזי": true,
"היחטף": true,
"היחיד": true,
"היחכר": true,
"היחלב": true,
"היחלם": true,
"היחלץ": true,
"היחלק": true,
"היחלש": true,
"היחמד": true,
"היחמץ": true,
"היחנט": true,
"היחנך": true,
"היחנק": true,
"היחסי": true,
"היחסך": true,
"היחסם": true,
"היחפז": true,
"היחפף": true,
"היחפר": true,
"היחצב": true,
"היחצה": true,
"היחצו": true,
"היחצי": true,
"היחצץ": true,
"היחקק": true,
"היחקר": true,
"היחרב": true,
"היחרד": true,
"היחרט": true,
"היחרך": true,
"היחרץ": true,
"היחרש": true,
"היחרת": true,
"היחשב": true,
"היחשד": true,
"היחשף": true,
"היחשק": true,
"היחתך": true,
"היחתם": true,
"היטבח": true,
"היטבל": true,
"היטבע": true,
"היטבת": true,
"היטהר": true,
"היטוו": true,
"היטחן": true,
"היטיב": true,
"היטלה": true,
"היטלו": true,
"היטלי": true,
"היטלך": true,
"היטלם": true,
"היטלן": true,
"היטלר": true,
"היטמא": true,
"היטמן": true,
"היטמע": true,
"היטעם": true,
"היטען": true,
"היטפל": true,
"היטרד": true,
"היטרף": true,
"היטרק": true,
"הייטק": true,
"היילי": true,
"היימן": true,
"היינה": true,
"היינו": true,
"היירה": true,
"היירו": true,
"היירי": true,
"היישר": true,
"הייתה": true,
"הייתי": true,
"הייתם": true,
"הייתן": true,
"היכבה": true,
"היכבו": true,
"היכבי": true,
"היכבל": true,
"היכבש": true,
"היכוו": true,
"היכול": true,
"היכון": true,
"היכזב": true,
"היכחד": true,
"היכלא": true,
"היכלה": true,
"היכלו": true,
"היכלי": true,
"היכלך": true,
"היכלל": true,
"היכלם": true,
"היכלן": true,
"היכמר": true,
"היכנס": true,
"היכנע": true,
"היכסס": true,
"היכסף": true,
"היכפה": true,
"היכפו": true,
"היכפי": true,
"היכפל": true,
"היכפת": true,
"היכרה": true,
"היכרו": true,
"היכרי": true,
"היכרך": true,
"היכרם": true,
"היכרן": true,
"היכרת": true,
"היכשל": true,
"היכתב": true,
"היכתש": true,
"הילאה": true,
"הילאו": true,
"הילאי": true,
"הילבל": true,
"הילבש": true,
"הילגם": true,
"הילדה": true,
"הילוו": true,
"הילוך": true,
"הילות": true,
"הילחם": true,
"הילחץ": true,
"הילחש": true,
"הילכד": true,
"הילכה": true,
"הילכו": true,
"הילכת": true,
"היללה": true,
"היללו": true,
"היללת": true,
"הילמד": true,
"הילעס": true,
"הילפת": true,
"הילקח": true,
"הילרי": true,
"הילתה": true,
"הילתו": true,
"הילתי": true,
"הילתך": true,
"הילתם": true,
"הילתן": true,
"הימאס": true,
"הימדד": true,
"הימהל": true,
"הימוג": true,
"הימוח": true,
"הימול": true,
"הימור": true,
"הימזג": true,
"הימחא": true,
"הימחה": true,
"הימחו": true,
"הימחי": true,
"הימחל": true,
"הימחץ": true,
"הימחק": true,
"הימים": true,
"הימין": true,
"הימית": true,
"הימכר": true,
"הימלא": true,
"הימלט": true,
"הימלך": true,
"הימלק": true,
"הימלר": true,
"היממה": true,
"היממו": true,
"היממת": true,
"הימנה": true,
"הימנו": true,
"הימני": true,
"הימנע": true,
"הימסך": true,
"הימסק": true,
"הימסר": true,
"הימעך": true,
"הימען": true,
"הימצא": true,
"הימצץ": true,
"הימקו": true,
"הימקי": true,
"הימרה": true,
"הימרו": true,
"הימרח": true,
"הימרת": true,
"הימשה": true,
"הימשו": true,
"הימשח": true,
"הימשי": true,
"הימשך": true,
"הימשל": true,
"הימתח": true,
"הינאם": true,
"הינבט": true,
"הינגח": true,
"הינגס": true,
"הינגע": true,
"הינגף": true,
"הינגר": true,
"הינדה": true,
"הינדו": true,
"הינדי": true,
"הינדך": true,
"הינדם": true,
"הינדן": true,
"הינזף": true,
"הינזק": true,
"הינזר": true,
"הינחם": true,
"הינחת": true,
"הינטל": true,
"הינטע": true,
"הינטש": true,
"היניק": true,
"הינכם": true,
"הינעה": true,
"הינעו": true,
"הינעי": true,
"הינעך": true,
"הינעל": true,
"הינעם": true,
"הינען": true,
"הינעץ": true,
"הינפה": true,
"הינפו": true,
"הינפי": true,
"הינפך": true,
"הינפם": true,
"הינפן": true,
"הינצל": true,
"הינצר": true,
"הינקט": true,
"הינקם": true,
"הינקע": true,
"הינקת": true,
"הינשא": true,
"הינשך": true,
"הינשף": true,
"הינתז": true,
"הינתך": true,
"הינתן": true,
"הינתק": true,
"היסבו": true,
"היסבי": true,
"היסבל": true,
"היסגר": true,
"היסדק": true,
"היסוג": true,
"היסוד": true,
"היסוי": true,
"היסוס": true,
"היסחב": true,
"היסחה": true,
"היסחו": true,
"היסחט": true,
"היסחי": true,
"היסחך": true,
"היסחם": true,
"היסחן": true,
"היסחף": true,
"היסחר": true,
"היסטה": true,
"היסטו": true,
"היסטי": true,
"היסטך": true,
"היסטם": true,
"היסטן": true,
"היסית": true,
"היסכם": true,
"היסכר": true,
"היסלח": true,
"היסלל": true,
"היסמך": true,
"היססה": true,
"היססו": true,
"היססת": true,
"היסעה": true,
"היסעו": true,
"היסעי": true,
"היסעך": true,
"היסעם": true,
"היסען": true,
"היספג": true,
"היספה": true,
"היספו": true,
"היספח": true,
"היספי": true,
"היספר": true,
"היסקה": true,
"היסקו": true,
"היסקי": true,
"היסקך": true,
"היסקל": true,
"היסקם": true,
"היסקן": true,
"היסקר": true,
"היסרג": true,
"היסרק": true,
"היסתה": true,
"היסתם": true,
"היסתר": true,
"היעבר": true,
"היעגן": true,
"היעדר": true,
"היעור": true,
"היעזב": true,
"היעזר": true,
"היעטף": true,
"היעיל": true,
"היעכר": true,
"היעלב": true,
"היעלם": true,
"היעמד": true,
"היענב": true,
"היענד": true,
"היענה": true,
"היענו": true,
"היעני": true,
"היענש": true,
"היעצב": true,
"היעצם": true,
"היעצר": true,
"היעקד": true,
"היעקף": true,
"היעקץ": true,
"היעקר": true,
"היערך": true,
"היערם": true,
"היערף": true,
"היערץ": true,
"היעשה": true,
"היעשו": true,
"היעשי": true,
"היעשק": true,
"היעתק": true,
"היעתר": true,
"היפגם": true,
"היפגע": true,
"היפגש": true,
"היפדה": true,
"היפדו": true,
"היפדי": true,
"היפוך": true,
"היפוץ": true,
"היפות": true,
"היפטר": true,
"היפים": true,
"היפית": true,
"היפלא": true,
"היפלט": true,
"היפנה": true,
"היפנו": true,
"היפני": true,
"היפסל": true,
"היפסק": true,
"היפעם": true,
"היפער": true,
"היפצע": true,
"היפקד": true,
"היפקח": true,
"היפרד": true,
"היפרט": true,
"היפרם": true,
"היפרס": true,
"היפרע": true,
"היפרץ": true,
"היפרק": true,
"היפרש": true,
"היפתח": true,
"היפתר": true,
"היצבט": true,
"היצבע": true,
"היצבר": true,
"היצגה": true,
"היצגו": true,
"היצגי": true,
"היצגך": true,
"היצגם": true,
"היצגן": true,
"היצוא": true,
"היצוד": true,
"היצור": true,
"היציג": true,
"היצלב": true,
"היצלה": true,
"היצלו": true,
"היצלי": true,
"היצמד": true,
"היצמת": true,
"היצעה": true,
"היצעו": true,
"היצעי": true,
"היצעך": true,
"היצעם": true,
"היצען": true,
"היצפה": true,
"היצפו": true,
"היצפי": true,
"היצפך": true,
"היצפם": true,
"היצפן": true,
"היצפר": true,
"היצרב": true,
"היצרד": true,
"היצרך": true,
"היצרן": true,
"היצרר": true,
"היקבע": true,
"היקבץ": true,
"היקבר": true,
"היקדח": true,
"היקהל": true,
"היקוו": true,
"היקום": true,
"היקטל": true,
"היקטם": true,
"היקטע": true,
"היקטף": true,
"היקלה": true,
"היקלו": true,
"היקלט": true,
"היקלי": true,
"היקלע": true,
"היקנה": true,
"היקנו": true,
"היקני": true,
"היקנס": true,
"היקפה": true,
"היקפו": true,
"היקפי": true,
"היקפך": true,
"היקפם": true,
"היקפן": true,
"היקצב": true,
"היקצץ": true,
"היקצר": true,
"היקרא": true,
"היקרה": true,
"היקרו": true,
"היקרי": true,
"היקרע": true,
"היקרש": true,
"היקשה": true,
"היקשו": true,
"היקשי": true,
"היקשך": true,
"היקשם": true,
"היקשן": true,
"היקשר": true,
"היראה": true,
"היראו": true,
"היראי": true,
"הירגם": true,
"הירגע": true,
"הירדם": true,
"הירדן": true,
"הירדף": true,
"הירוק": true,
"הירוש": true,
"הירחץ": true,
"הירטב": true,
"הירטט": true,
"היריב": true,
"היריד": true,
"הירכס": true,
"הירכש": true,
"הירמז": true,
"הירמס": true,
"הירעש": true,
"הירפא": true,
"הירצה": true,
"הירצו": true,
"הירצח": true,
"הירצי": true,
"הירצץ": true,
"הירקב": true,
"הירקח": true,
"הירקם": true,
"הירשם": true,
"הירתם": true,
"הירתע": true,
"הישאב": true,
"הישאג": true,
"הישאל": true,
"הישאם": true,
"הישאף": true,
"הישאר": true,
"הישבה": true,
"הישבו": true,
"הישבי": true,
"הישבן": true,
"הישבע": true,
"הישבר": true,
"הישגב": true,
"הישגה": true,
"הישגו": true,
"הישגי": true,
"הישגך": true,
"הישגם": true,
"הישגן": true,
"הישדד": true,
"הישוב": true,
"הישות": true,
"הישזר": true,
"הישחט": true,
"הישחק": true,
"הישטף": true,
"הישיר": true,
"הישכב": true,
"הישכח": true,
"הישכר": true,
"הישלה": true,
"הישלו": true,
"הישלח": true,
"הישלט": true,
"הישלי": true,
"הישלל": true,
"הישלם": true,
"הישלף": true,
"הישלק": true,
"הישמד": true,
"הישמט": true,
"הישמע": true,
"הישמר": true,
"הישנה": true,
"הישנו": true,
"הישני": true,
"הישנק": true,
"הישען": true,
"הישפט": true,
"הישפך": true,
"הישקל": true,
"הישקף": true,
"הישרט": true,
"הישרך": true,
"הישרף": true,
"הישרק": true,
"הישרת": true,
"הישתל": true,
"היתבע": true,
"היתוך": true,
"היתול": true,
"היתחב": true,
"היתכן": true,
"היתלה": true,
"היתלו": true,
"היתלי": true,
"היתלש": true,
"היתלת": true,
"היתמך": true,
"היתמם": true,
"היתמר": true,
"היתפס": true,
"היתפר": true,
"היתפש": true,
"היתקל": true,
"היתקע": true,
"היתקף": true,
"היתרה": true,
"היתרו": true,
"היתרי": true,
"היתרך": true,
"היתרם": true,
"היתרן": true,
"הכאבת": true,
"הכאות": true,
"הכאיב": true,
"הכאתה": true,
"הכאתו": true,
"הכאתי": true,
"הכאתך": true,
"הכאתם": true,
"הכאתן": true,
"הכבדה": true,
"הכבדת": true,
"הכבוד": true,
"הכביד": true,
"הכביר": true,
"הכביש": true,
"הכברה": true,
"הכברת": true,
"הכדור": true,
"הכהיה": true,
"הכהית": true,
"הכהתה": true,
"הכובד": true,
"הכובע": true,
"הכובש": true,
"הכוון": true,
"הכוכב": true,
"הכולל": true,
"הכומר": true,
"הכונס": true,
"הכושר": true,
"הכותב": true,
"הכותל": true,
"הכזבת": true,
"הכזיב": true,
"הכחדה": true,
"הכחדת": true,
"הכחול": true,
"הכחיד": true,
"הכחיל": true,
"הכחיר": true,
"הכחיש": true,
"הכחלה": true,
"הכחלת": true,
"הכחשה": true,
"הכחשת": true,
"הכיור": true,
"הכיכר": true,
"הכילה": true,
"הכילו": true,
"הכילי": true,
"הכימי": true,
"הכינה": true,
"הכינו": true,
"הכיני": true,
"הכיסא": true,
"הכיפה": true,
"הכיצד": true,
"הכירה": true,
"הכירו": true,
"הכירי": true,
"הכישה": true,
"הכישו": true,
"הכישי": true,
"הכיתה": true,
"הכיתי": true,
"הכיתם": true,
"הכיתן": true,
"הכלאה": true,
"הכלאת": true,
"הכלבה": true,
"הכלבת": true,
"הכלול": true,
"הכלות": true,
"הכליא": true,
"הכליב": true,
"הכליה": true,
"הכליל": true,
"הכלים": true,
"הכלכל": true,
"הכללה": true,
"הכללי": true,
"הכללת": true,
"הכלמה": true,
"הכלמת": true,
"הכלנה": true,
"הכלנו": true,
"הכלרה": true,
"הכלתה": true,
"הכלתו": true,
"הכלתי": true,
"הכלתך": true,
"הכלתם": true,
"הכלתן": true,
"הכמות": true,
"הכמין": true,
"הכמנה": true,
"הכמנו": true,
"הכמנת": true,
"הכמעט": true,
"הכנות": true,
"הכניס": true,
"הכניע": true,
"הכנסה": true,
"הכנסו": true,
"הכנסת": true,
"הכנעה": true,
"הכנעת": true,
"הכנרת": true,
"הכנתה": true,
"הכנתו": true,
"הכנתי": true,
"הכנתך": true,
"הכנתם": true,
"הכנתן": true,
"הכסיף": true,
"הכספה": true,
"הכספי": true,
"הכספת": true,
"הכעיס": true,
"הכעסת": true,
"הכפול": true,
"הכפיל": true,
"הכפיף": true,
"הכפיש": true,
"הכפלה": true,
"הכפלת": true,
"הכפפה": true,
"הכפפת": true,
"הכפרי": true,
"הכפשה": true,
"הכפשת": true,
"הכרוך": true,
"הכרות": true,
"הכרזה": true,
"הכרזת": true,
"הכרחה": true,
"הכרחו": true,
"הכרחי": true,
"הכרחך": true,
"הכרחם": true,
"הכרחן": true,
"הכרחת": true,
"הכריז": true,
"הכריח": true,
"הכריע": true,
"הכרית": true,
"הכרמל": true,
"הכרנה": true,
"הכרנו": true,
"הכרעה": true,
"הכרעת": true,
"הכרתה": true,
"הכרתו": true,
"הכרתי": true,
"הכרתך": true,
"הכרתם": true,
"הכרתן": true,
"הכשות": true,
"הכשיל": true,
"הכשיר": true,
"הכשלה": true,
"הכשלת": true,
"הכשנה": true,
"הכשנו": true,
"הכשרה": true,
"הכשרו": true,
"הכשרי": true,
"הכשרך": true,
"הכשרם": true,
"הכשרן": true,
"הכשרת": true,
"הכשתה": true,
"הכשתו": true,
"הכשתי": true,
"הכשתך": true,
"הכשתם": true,
"הכשתן": true,
"הכתבה": true,
"הכתבת": true,
"הכתוב": true,
"הכתיב": true,
"הכתים": true,
"הכתיף": true,
"הכתיר": true,
"הכתמה": true,
"הכתמת": true,
"הכתפה": true,
"הכתפת": true,
"הכתרה": true,
"הכתרת": true,
"הלאום": true,
"הלאים": true,
"הלאית": true,
"הלאמה": true,
"הלאמת": true,
"הלאתה": true,
"הלבוש": true,
"הלבין": true,
"הלביש": true,
"הלבנה": true,
"הלבנו": true,
"הלבנת": true,
"הלבשה": true,
"הלבשת": true,
"הלגיט": true,
"הלהבה": true,
"הלהבת": true,
"הלהטת": true,
"הלהיב": true,
"הלהיט": true,
"הלהקה": true,
"הלובי": true,
"הלוגו": true,
"הלוגן": true,
"הלוהט": true,
"הלווה": true,
"הלווי": true,
"הלוחם": true,
"הלויה": true,
"הלוים": true,
"הלומד": true,
"הלומה": true,
"הלוקח": true,
"הלורד": true,
"הלזאת": true,
"הלחות": true,
"הלחים": true,
"הלחין": true,
"הלחיץ": true,
"הלחמה": true,
"הלחמו": true,
"הלחמי": true,
"הלחמך": true,
"הלחמם": true,
"הלחמן": true,
"הלחמת": true,
"הלחנה": true,
"הלחנו": true,
"הלחנת": true,
"הלחצה": true,
"הלחצת": true,
"הלטות": true,
"הלטנה": true,
"הלטנו": true,
"הלטתה": true,
"הלטתו": true,
"הלטתי": true,
"הלטתך": true,
"הלטתם": true,
"הלטתן": true,
"הליבה": true,
"הליבר": true,
"הליגה": true,
"הלידה": true,
"הליום": true,
"הליטה": true,
"הליטו": true,
"הליטי": true,
"הליכה": true,
"הליכו": true,
"הליכי": true,
"הליכך": true,
"הליכם": true,
"הליכן": true,
"הלילה": true,
"הלימה": true,
"הלינה": true,
"הלינו": true,
"הליני": true,
"הלינק": true,
"הלכות": true,
"הלכיה": true,
"הלכיו": true,
"הלכיי": true,
"הלכיך": true,
"הלכים": true,
"הלככם": true,
"הלככן": true,
"הלכנה": true,
"הלכנו": true,
"הלכתה": true,
"הלכתו": true,
"הלכתי": true,
"הלכתך": true,
"הלכתם": true,
"הלכתן": true,
"הללנה": true,
"הלמות": true,
"הלמיה": true,
"הלמיו": true,
"הלמיי": true,
"הלמיך": true,
"הלמים": true,
"הלמכם": true,
"הלמכן": true,
"הלמנו": true,
"הלמתי": true,
"הלמתם": true,
"הלמתן": true,
"הלנות": true,
"הלנית": true,
"הלנתה": true,
"הלנתו": true,
"הלנתי": true,
"הלנתך": true,
"הלנתם": true,
"הלנתן": true,
"הלעגת": true,
"הלעזה": true,
"הלעזת": true,
"הלעטה": true,
"הלעטת": true,
"הלעיג": true,
"הלעיז": true,
"הלעיט": true,
"הלפרט": true,
"הלצות": true,
"הלצתה": true,
"הלצתו": true,
"הלצתי": true,
"הלצתך": true,
"הלצתם": true,
"הלצתן": true,
"הלקאה": true,
"הלקוח": true,
"הלקית": true,
"הלקתה": true,
"הלשון": true,
"הלשין": true,
"הלשכה": true,
"הלשנה": true,
"הלשנו": true,
"הלשנת": true,
"המאבק": true,
"המאגר": true,
"המאוב": true,
"המאוד": true,
"המאוח": true,
"המאור": true,
"המאות": true,
"המאזן": true,
"המאיס": true,
"המאכל": true,
"המאמן": true,
"המאמץ": true,
"המאמר": true,
"המאסה": true,
"המאסר": true,
"המאסת": true,
"המארח": true,
"המבול": true,
"המבוס": true,
"המבוצ": true,
"המבוק": true,
"המבור": true,
"המבחן": true,
"המבחר": true,
"המבטא": true,
"המבטח": true,
"המביא": true,
"המבנה": true,
"המבצע": true,
"המבצר": true,
"המבקר": true,
"המבקש": true,
"המברי": true,
"המגדל": true,
"המגדר": true,
"המגון": true,
"המגזר": true,
"המגיד": true,
"המגיע": true,
"המגמה": true,
"המגנה": true,
"המגנו": true,
"המגני": true,
"המגנת": true,
"המגרש": true,
"המגשר": true,
"המדבר": true,
"המדגם": true,
"המדור": true,
"המדות": true,
"המדיה": true,
"המדים": true,
"המדמה": true,
"המדעי": true,
"המדען": true,
"המדרש": true,
"המהום": true,
"המהות": true,
"המהיר": true,
"המהלך": true,
"המהמה": true,
"המהמו": true,
"המהמי": true,
"המהמת": true,
"המהפך": true,
"המובא": true,
"המובה": true,
"המובן": true,
"המוגן": true,
"המוגש": true,
"המודל": true,
"המודע": true,
"המודר": true,
"המוות": true,
"המוזה": true,
"המוזר": true,
"המוטו": true,
"המוטל": true,
"המוכן": true,
"המוכר": true,
"המולד": true,
"המולה": true,
"המומה": true,
"המומל": true,
"המונה": true,
"המונו": true,
"המונח": true,
"המוני": true,
"המונך": true,
"המונם": true,
"המונן": true,
"המונע": true,
"המוסד": true,
"המוסך": true,
"המוסף": true,
"המוסר": true,
"המועד": true,
"המופע": true,
"המופק": true,
"המופר": true,
"המופת": true,
"המוצא": true,
"המוצב": true,
"המוצג": true,
"המוצה": true,
"המוצע": true,
"המוצר": true,
"המוקד": true,
"המורה": true,
"המושב": true,
"המושל": true,
"המושק": true,
"המושת": true,
"המותא": true,
"המותג": true,
"המותק": true,
"המותר": true,
"המזבח": true,
"המזגן": true,
"המזוי": true,
"המזון": true,
"המזיק": true,
"המזלג": true,
"המזרח": true,
"המזרן": true,
"המחאה": true,
"המחבל": true,
"המחבר": true,
"המחבת": true,
"המחדל": true,
"המחוז": true,
"המחול": true,
"המחזה": true,
"המחזת": true,
"המחיה": true,
"המחיז": true,
"המחיר": true,
"המחיש": true,
"המחלה": true,
"המחמד": true,
"המחנה": true,
"המחסן": true,
"המחקר": true,
"המחשב": true,
"המחשה": true,
"המחשת": true,
"המטבח": true,
"המטבע": true,
"המטוס": true,
"המטור": true,
"המטות": true,
"המטיר": true,
"המטנה": true,
"המטנו": true,
"המטען": true,
"המטפל": true,
"המטרה": true,
"המטרי": true,
"המטרת": true,
"המטתה": true,
"המטתו": true,
"המטתי": true,
"המטתך": true,
"המטתם": true,
"המטתן": true,
"המידה": true,
"המידע": true,
"המיוח": true,
"המיון": true,
"המיוע": true,
"המיוצ": true,
"המיות": true,
"המיזם": true,
"המיטב": true,
"המיטה": true,
"המיטו": true,
"המיטי": true,
"המייל": true,
"המייס": true,
"המייע": true,
"המייק": true,
"המיכל": true,
"המילה": true,
"המילי": true,
"המימד": true,
"המינה": true,
"המינו": true,
"המיני": true,
"המירה": true,
"המירו": true,
"המירי": true,
"המיתה": true,
"המיתו": true,
"המיתי": true,
"המיתם": true,
"המיתן": true,
"המכבי": true,
"המכהן": true,
"המכון": true,
"המכות": true,
"המכיל": true,
"המכיר": true,
"המכנה": true,
"המכסה": true,
"המכרז": true,
"המכתב": true,
"המכתש": true,
"המלאה": true,
"המלאי": true,
"המלאך": true,
"המלון": true,
"המלחה": true,
"המלחת": true,
"המלטה": true,
"המלטת": true,
"המליח": true,
"המליט": true,
"המליך": true,
"המלים": true,
"המליץ": true,
"המלכה": true,
"המלכת": true,
"המלמד": true,
"המלצה": true,
"המלצת": true,
"הממוז": true,
"הממון": true,
"הממוק": true,
"הממוש": true,
"הממנה": true,
"הממנו": true,
"הממסד": true,
"הממצא": true,
"הממשי": true,
"הממשל": true,
"הממשק": true,
"הממתי": true,
"הממתם": true,
"הממתן": true,
"המנדט": true,
"המנהג": true,
"המנהל": true,
"המנוה": true,
"המנוח": true,
"המנוי": true,
"המנון": true,
"המנוע": true,
"המנות": true,
"המנזר": true,
"המנחה": true,
"המניה": true,
"המניע": true,
"המנסה": true,
"המנצח": true,
"המנתח": true,
"המסגד": true,
"המסדר": true,
"המסוק": true,
"המסור": true,
"המסות": true,
"המסחר": true,
"המסים": true,
"המסכה": true,
"המסכן": true,
"המסמך": true,
"המסמל": true,
"המסנה": true,
"המסנו": true,
"המספק": true,
"המספר": true,
"המסתה": true,
"המסתו": true,
"המסתי": true,
"המסתך": true,
"המסתם": true,
"המסתן": true,
"המעבד": true,
"המעבר": true,
"המעגל": true,
"המעדה": true,
"המעדת": true,
"המעוד": true,
"המעור": true,
"המעטה": true,
"המעטת": true,
"המעיד": true,
"המעיט": true,
"המעיל": true,
"המעלה": true,
"המעמד": true,
"המענה": true,
"המענק": true,
"המעצב": true,
"המעצר": true,
"המעקב": true,
"המערב": true,
"המערה": true,
"המערך": true,
"המערע": true,
"המעשה": true,
"המעשי": true,
"המפגש": true,
"המפוא": true,
"המפוק": true,
"המפור": true,
"המפות": true,
"המפיק": true,
"המפנה": true,
"המפעל": true,
"המפקד": true,
"המפקח": true,
"המפרט": true,
"המפרי": true,
"המפרץ": true,
"המפרק": true,
"המפתח": true,
"המצאה": true,
"המצאת": true,
"המצבה": true,
"המצבר": true,
"המצגת": true,
"המצוה": true,
"המצוי": true,
"המצומ": true,
"המצור": true,
"המצות": true,
"המציא": true,
"המציג": true,
"המצעד": true,
"המצפה": true,
"המצרי": true,
"המקבל": true,
"המקדש": true,
"המקום": true,
"המקור": true,
"המקיף": true,
"המקלט": true,
"המקנה": true,
"המקרא": true,
"המקרה": true,
"המקרר": true,
"המקשר": true,
"המראה": true,
"המראת": true,
"המרבי": true,
"המרגש": true,
"המרדה": true,
"המרדת": true,
"המרוץ": true,
"המרוק": true,
"המרות": true,
"המרחב": true,
"המרחק": true,
"המריא": true,
"המריד": true,
"המריץ": true,
"המרית": true,
"המרכז": true,
"המרנה": true,
"המרנו": true,
"המרפא": true,
"המרצה": true,
"המרצת": true,
"המרקם": true,
"המרתה": true,
"המרתו": true,
"המרתי": true,
"המרתך": true,
"המרתם": true,
"המרתן": true,
"המרתף": true,
"המרתק": true,
"המשאב": true,
"המשבר": true,
"המשגה": true,
"המשדר": true,
"המשוב": true,
"המשול": true,
"המשות": true,
"המשחה": true,
"המשחק": true,
"המשטח": true,
"המשטר": true,
"המשיב": true,
"המשיח": true,
"המשיך": true,
"המשיל": true,
"המשכה": true,
"המשכו": true,
"המשכי": true,
"המשכך": true,
"המשכם": true,
"המשכן": true,
"המשכת": true,
"המשלב": true,
"המשלה": true,
"המשלת": true,
"המשמר": true,
"המשמש": true,
"המשנה": true,
"המשפח": true,
"המשפט": true,
"המשקה": true,
"המשקל": true,
"המשקף": true,
"המשרד": true,
"המשרה": true,
"המשרת": true,
"המשתי": true,
"המתאר": true,
"המתבס": true,
"המתוח": true,
"המתוכ": true,
"המתוע": true,
"המתוק": true,
"המתות": true,
"המתחם": true,
"המתים": true,
"המתין": true,
"המתיק": true,
"המתכת": true,
"המתנה": true,
"המתנו": true,
"המתנת": true,
"המתעד": true,
"המתפר": true,
"המתקה": true,
"המתקי": true,
"המתקן": true,
"המתקת": true,
"המתרח": true,
"המתתה": true,
"המתתו": true,
"המתתי": true,
"המתתך": true,
"המתתם": true,
"המתתן": true,
"הנאום": true,
"הנאות": true,
"הנאמן": true,
"הנאמר": true,
"הנאנה": true,
"הנאנו": true,
"הנאצי": true,
"הנאשם": true,
"הנאתה": true,
"הנאתו": true,
"הנאתי": true,
"הנאתך": true,
"הנאתם": true,
"הנאתן": true,
"הנבדק": true,
"הנבות": true,
"הנבחר": true,
"הנבטה": true,
"הנבטת": true,
"הנביא": true,
"הנביט": true,
"הנבנה": true,
"הנבנו": true,
"הנבתה": true,
"הנבתו": true,
"הנבתי": true,
"הנבתך": true,
"הנבתם": true,
"הנבתן": true,
"הנגבי": true,
"הנגדה": true,
"הנגדי": true,
"הנגדת": true,
"הנגיד": true,
"הנגיף": true,
"הנגנה": true,
"הנגרם": true,
"הנגשת": true,
"הנדון": true,
"הנדוס": true,
"הנדות": true,
"הנדיב": true,
"הנדיר": true,
"הנדנה": true,
"הנדנו": true,
"הנדסה": true,
"הנדסו": true,
"הנדסי": true,
"הנדסת": true,
"הנדרש": true,
"הנדתה": true,
"הנדתו": true,
"הנדתי": true,
"הנדתך": true,
"הנדתם": true,
"הנדתן": true,
"הנהגה": true,
"הנהגת": true,
"הנהדר": true,
"הנהוג": true,
"הנהון": true,
"הנהיג": true,
"הנהלה": true,
"הנהלת": true,
"הנהנה": true,
"הנהנו": true,
"הנהני": true,
"הנהנת": true,
"הנובע": true,
"הנוגע": true,
"הנודע": true,
"הנוהג": true,
"הנוהל": true,
"הנוזל": true,
"הנוחה": true,
"הנויר": true,
"הנוכח": true,
"הנולד": true,
"הנוסח": true,
"הנוסע": true,
"הנוסף": true,
"הנוער": true,
"הנופש": true,
"הנוצר": true,
"הנורא": true,
"הנושא": true,
"הנושה": true,
"הנותן": true,
"הנותר": true,
"הנזיר": true,
"הנזכר": true,
"הנחות": true,
"הנחיה": true,
"הנחיל": true,
"הנחית": true,
"הנחלה": true,
"הנחלת": true,
"הנחנה": true,
"הנחנו": true,
"הנחשב": true,
"הנחתה": true,
"הנחתו": true,
"הנחתי": true,
"הנחתך": true,
"הנחתם": true,
"הנחתן": true,
"הנטען": true,
"הניאה": true,
"הניאו": true,
"הניאי": true,
"הניבה": true,
"הניבו": true,
"הניבי": true,
"הנידה": true,
"הנידו": true,
"הנידי": true,
"הניחה": true,
"הניחו": true,
"הניחי": true,
"הנייד": true,
"הנייר": true,
"הניסה": true,
"הניסו": true,
"הניסי": true,
"הניעה": true,
"הניעו": true,
"הניעי": true,
"הניפה": true,
"הניפו": true,
"הניפי": true,
"הניצב": true,
"הניקה": true,
"הניקו": true,
"הניקי": true,
"הניתן": true,
"הנכבד": true,
"הנכבה": true,
"הנכון": true,
"הנכות": true,
"הנכים": true,
"הנכנס": true,
"הנכסף": true,
"הנלמד": true,
"הנמוך": true,
"הנמיך": true,
"הנמכה": true,
"הנמכר": true,
"הנמכת": true,
"הנמנע": true,
"הנמצא": true,
"הנמקה": true,
"הנמשך": true,
"הנסות": true,
"הנסיך": true,
"הנסנה": true,
"הנסנו": true,
"הנסתה": true,
"הנסתו": true,
"הנסתי": true,
"הנסתך": true,
"הנסתם": true,
"הנסתן": true,
"הנסתר": true,
"הנעות": true,
"הנעיל": true,
"הנעים": true,
"הנעלה": true,
"הנעלם": true,
"הנעמה": true,
"הנעמת": true,
"הנענה": true,
"הנענו": true,
"הנערה": true,
"הנעשה": true,
"הנעתה": true,
"הנעתו": true,
"הנעתי": true,
"הנעתך": true,
"הנעתם": true,
"הנעתן": true,
"הנפגע": true,
"הנפוץ": true,
"הנפות": true,
"הנפטר": true,
"הנפיק": true,
"הנפיש": true,
"הנפלא": true,
"הנפנה": true,
"הנפנו": true,
"הנפקה": true,
"הנפקת": true,
"הנפשה": true,
"הנפשי": true,
"הנפשת": true,
"הנפתה": true,
"הנפתו": true,
"הנפתי": true,
"הנפתך": true,
"הנפתם": true,
"הנפתן": true,
"הנצות": true,
"הנצחה": true,
"הנצחי": true,
"הנצחת": true,
"הנציב": true,
"הנציג": true,
"הנציח": true,
"הנציל": true,
"הנצלה": true,
"הנצלת": true,
"הנצנה": true,
"הנצנו": true,
"הנצתה": true,
"הנצתו": true,
"הנצתי": true,
"הנצתך": true,
"הנצתם": true,
"הנצתן": true,
"הנקבה": true,
"הנקוב": true,
"הנקות": true,
"הנקמה": true,
"הנקנה": true,
"הנקנו": true,
"הנקרא": true,
"הנקרה": true,
"הנקתה": true,
"הנקתו": true,
"הנקתי": true,
"הנקתך": true,
"הנקתם": true,
"הנקתן": true,
"הנראה": true,
"הנרות": true,
"הנרחב": true,
"הנשיא": true,
"הנשים": true,
"הנשית": true,
"הנשמה": true,
"הנשמת": true,
"הנשקף": true,
"הנתבע": true,
"הנתון": true,
"הנתיב": true,
"הסבות": true,
"הסביב": true,
"הסביר": true,
"הסבנה": true,
"הסבנו": true,
"הסברה": true,
"הסברו": true,
"הסברי": true,
"הסברך": true,
"הסברם": true,
"הסברן": true,
"הסברת": true,
"הסבתא": true,
"הסבתה": true,
"הסבתו": true,
"הסבתי": true,
"הסבתך": true,
"הסבתם": true,
"הסבתן": true,
"הסגול": true,
"הסגור": true,
"הסגות": true,
"הסגיר": true,
"הסגנה": true,
"הסגנו": true,
"הסגרה": true,
"הסגרו": true,
"הסגרי": true,
"הסגרך": true,
"הסגרם": true,
"הסגרן": true,
"הסגרת": true,
"הסגתה": true,
"הסגתו": true,
"הסגתי": true,
"הסגתך": true,
"הסגתם": true,
"הסגתן": true,
"הסדיר": true,
"הסדנא": true,
"הסדנה": true,
"הסדרה": true,
"הסדרו": true,
"הסדרי": true,
"הסדרך": true,
"הסדרם": true,
"הסדרן": true,
"הסדרת": true,
"הסובב": true,
"הסובי": true,
"הסובל": true,
"הסודי": true,
"הסוהר": true,
"הסווה": true,
"הסווי": true,
"הסוחר": true,
"הסוכה": true,
"הסוכן": true,
"הסוכר": true,
"הסולו": true,
"הסולם": true,
"הסוער": true,
"הסופה": true,
"הסופי": true,
"הסופר": true,
"הסוצי": true,
"הסורי": true,
"הסחות": true,
"הסחנה": true,
"הסחנו": true,
"הסחתה": true,
"הסחתו": true,
"הסחתי": true,
"הסחתך": true,
"הסחתם": true,
"הסחתן": true,
"הסטות": true,
"הסטנד": true,
"הסטנה": true,
"הסטנו": true,
"הסטרי": true,
"הסטתה": true,
"הסטתו": true,
"הסטתי": true,
"הסטתך": true,
"הסטתם": true,
"הסטתן": true,
"הסיבה": true,
"הסיגה": true,
"הסיגו": true,
"הסיגי": true,
"הסידן": true,
"הסיום": true,
"הסיוע": true,
"הסיור": true,
"הסיחה": true,
"הסיחו": true,
"הסיחי": true,
"הסיטה": true,
"הסיטו": true,
"הסיטי": true,
"הסימן": true,
"הסינג": true,
"הסינה": true,
"הסיני": true,
"הסיעה": true,
"הסיעו": true,
"הסיעי": true,
"הסיקה": true,
"הסיקו": true,
"הסיקי": true,
"הסירה": true,
"הסירו": true,
"הסירי": true,
"הסיתה": true,
"הסיתו": true,
"הסיתי": true,
"הסכום": true,
"הסכים": true,
"הסכין": true,
"הסכית": true,
"הסכמה": true,
"הסכמו": true,
"הסכמי": true,
"הסכמך": true,
"הסכמם": true,
"הסכמן": true,
"הסכמת": true,
"הסכנה": true,
"הסכנו": true,
"הסכנת": true,
"הסכתי": true,
"הסכתם": true,
"הסכתן": true,
"הסלול": true,
"הסלון": true,
"הסלים": true,
"הסליק": true,
"הסללה": true,
"הסלמה": true,
"הסלמת": true,
"הסלקה": true,
"הסלקת": true,
"הסמוך": true,
"הסמיך": true,
"הסמים": true,
"הסמינ": true,
"הסמיק": true,
"הסמכה": true,
"הסמכת": true,
"הסמקה": true,
"הסמקת": true,
"הסנאט": true,
"הסנטר": true,
"הסניף": true,
"הסנפה": true,
"הסנפת": true,
"הססנה": true,
"הססני": true,
"הסעדה": true,
"הסעות": true,
"הסעיף": true,
"הסעיר": true,
"הסענה": true,
"הסענו": true,
"הסערה": true,
"הסערת": true,
"הסעתה": true,
"הסעתו": true,
"הסעתי": true,
"הסעתך": true,
"הסעתם": true,
"הסעתן": true,
"הספגה": true,
"הספגת": true,
"הספדה": true,
"הספדו": true,
"הספדי": true,
"הספדך": true,
"הספדם": true,
"הספדן": true,
"הספדת": true,
"הספיג": true,
"הספיד": true,
"הספיק": true,
"הספסל": true,
"הספקה": true,
"הספקו": true,
"הספקי": true,
"הספקך": true,
"הספקם": true,
"הספקן": true,
"הספקת": true,
"הספרי": true,
"הסצנה": true,
"הסקות": true,
"הסקנה": true,
"הסקנו": true,
"הסקתה": true,
"הסקתו": true,
"הסקתי": true,
"הסקתך": true,
"הסקתם": true,
"הסקתן": true,
"הסרות": true,
"הסרחה": true,
"הסרחת": true,
"הסרטה": true,
"הסרטן": true,
"הסרטת": true,
"הסריח": true,
"הסריט": true,
"הסרנה": true,
"הסרנו": true,
"הסרתה": true,
"הסרתו": true,
"הסרתי": true,
"הסרתך": true,
"הסרתם": true,
"הסרתן": true,
"הסתאב": true,
"הסתבך": true,
"הסתבן": true,
"הסתבר": true,
"הסתגל": true,
"הסתגף": true,
"הסתגר": true,
"הסתדר": true,
"הסתוב": true,
"הסתוו": true,
"הסתות": true,
"הסתיו": true,
"הסתיע": true,
"הסתיר": true,
"הסתכל": true,
"הסתכם": true,
"הסתכן": true,
"הסתלק": true,
"הסתמא": true,
"הסתמך": true,
"הסתמם": true,
"הסתמן": true,
"הסתמר": true,
"הסתנה": true,
"הסתנו": true,
"הסתנן": true,
"הסתעף": true,
"הסתער": true,
"הסתפח": true,
"הסתפק": true,
"הסתפר": true,
"הסתרה": true,
"הסתרק": true,
"הסתרת": true,
"הסתתה": true,
"הסתתו": true,
"הסתתי": true,
"הסתתך": true,
"הסתתם": true,
"הסתתן": true,
"הסתתר": true,
"העבדה": true,
"העבדת": true,
"העבות": true,
"העביד": true,
"העביר": true,
"העבנה": true,
"העבנו": true,
"העברה": true,
"העברי": true,
"העברת": true,
"העבתה": true,
"העבתו": true,
"העבתי": true,
"העבתך": true,
"העבתם": true,
"העבתן": true,
"העגול": true,
"העגלה": true,
"העדות": true,
"העדים": true,
"העדין": true,
"העדיף": true,
"העדנה": true,
"העדנו": true,
"העדפה": true,
"העדפת": true,
"העדרה": true,
"העדרו": true,
"העדרי": true,
"העדרך": true,
"העדרם": true,
"העדרן": true,
"העדשה": true,
"העדתה": true,
"העדתו": true,
"העדתי": true,
"העדתך": true,
"העדתם": true,
"העדתן": true,
"העובד": true,
"העובר": true,
"העוגה": true,
"העוגן": true,
"העודף": true,
"העווה": true,
"העווי": true,
"העוול": true,
"העוזר": true,
"העולה": true,
"העולם": true,
"העומד": true,
"העומס": true,
"העומק": true,
"העומר": true,
"העונג": true,
"העונה": true,
"העוני": true,
"העונש": true,
"העוסק": true,
"העורך": true,
"העורף": true,
"העורר": true,
"העושה": true,
"העושר": true,
"העותר": true,
"העזבה": true,
"העזבת": true,
"העזות": true,
"העזיב": true,
"העזים": true,
"העזנה": true,
"העזנו": true,
"העזרה": true,
"העזתה": true,
"העזתו": true,
"העזתי": true,
"העזתך": true,
"העזתם": true,
"העזתן": true,
"העטיר": true,
"העטרה": true,
"העטרת": true,
"העיבה": true,
"העיבו": true,
"העיבי": true,
"העידה": true,
"העידו": true,
"העידי": true,
"העידן": true,
"העיון": true,
"העילה": true,
"העיפה": true,
"העיפו": true,
"העיפי": true,
"העיקה": true,
"העיקו": true,
"העיקי": true,
"העיקר": true,
"העירה": true,
"העירו": true,
"העירי": true,
"העכבר": true,
"העכיר": true,
"העכרה": true,
"העכרת": true,
"העלאה": true,
"העלאת": true,
"העלבה": true,
"העלבת": true,
"העלול": true,
"העלות": true,
"העליב": true,
"העליה": true,
"העליל": true,
"העלים": true,
"העלית": true,
"העללה": true,
"העללת": true,
"העלמה": true,
"העלמת": true,
"העלתה": true,
"העמדה": true,
"העמדת": true,
"העמוד": true,
"העמוק": true,
"העמות": true,
"העמיד": true,
"העמים": true,
"העמיס": true,
"העמיק": true,
"העממי": true,
"העמנה": true,
"העמנו": true,
"העמסה": true,
"העמסת": true,
"העמקה": true,
"העמקת": true,
"העמתה": true,
"העמתו": true,
"העמתי": true,
"העמתך": true,
"העמתם": true,
"העמתן": true,
"הענות": true,
"הענין": true,
"העניק": true,
"העניש": true,
"הענפה": true,
"הענקה": true,
"הענקת": true,
"הענשה": true,
"הענשת": true,
"העסיק": true,
"העסקה": true,
"העסקי": true,
"העסקת": true,
"העפות": true,
"העפיל": true,
"העפלה": true,
"העפלת": true,
"העפנה": true,
"העפנו": true,
"העפתה": true,
"העפתו": true,
"העפתי": true,
"העפתך": true,
"העפתם": true,
"העפתן": true,
"העצבה": true,
"העצבת": true,
"העצום": true,
"העצות": true,
"העציב": true,
"העצים": true,
"העצמה": true,
"העצמי": true,
"העצמת": true,
"העצני": true,
"העצרת": true,
"העקנה": true,
"העקנו": true,
"העקתי": true,
"העקתם": true,
"העקתן": true,
"הערבה": true,
"הערבי": true,
"הערבת": true,
"הערוץ": true,
"הערות": true,
"העריב": true,
"העריך": true,
"הערים": true,
"העריף": true,
"העריץ": true,
"הערכה": true,
"הערכת": true,
"הערמה": true,
"הערמת": true,
"הערנה": true,
"הערנו": true,
"הערפת": true,
"הערצה": true,
"הערצת": true,
"הערתה": true,
"הערתו": true,
"הערתי": true,
"הערתך": true,
"הערתם": true,
"הערתן": true,
"העשוי": true,
"העשור": true,
"העשיר": true,
"העשרה": true,
"העשרת": true,
"העתיד": true,
"העתיק": true,
"העתיר": true,
"העתקה": true,
"העתקו": true,
"העתקי": true,
"העתקך": true,
"העתקם": true,
"העתקן": true,
"העתקת": true,
"העתרה": true,
"העתרת": true,
"הפאנל": true,
"הפארק": true,
"הפגות": true,
"הפגזה": true,
"הפגזת": true,
"הפגיז": true,
"הפגין": true,
"הפגיש": true,
"הפגנה": true,
"הפגנו": true,
"הפגנת": true,
"הפגשה": true,
"הפגשת": true,
"הפגתה": true,
"הפגתו": true,
"הפגתי": true,
"הפגתך": true,
"הפגתם": true,
"הפגתן": true,
"הפוגה": true,
"הפוגע": true,
"הפוכה": true,
"הפונה": true,
"הפונק": true,
"הפוסט": true,
"הפועל": true,
"הפורט": true,
"הפותח": true,
"הפחדה": true,
"הפחדת": true,
"הפחות": true,
"הפחיד": true,
"הפחית": true,
"הפחמן": true,
"הפחנה": true,
"הפחנו": true,
"הפחתה": true,
"הפחתו": true,
"הפחתי": true,
"הפחתך": true,
"הפחתם": true,
"הפחתן": true,
"הפחתת": true,
"הפטור": true,
"הפטיר": true,
"הפטנט": true,
"הפטרה": true,
"הפטרי": true,
"הפטרת": true,
"הפיגה": true,
"הפיגו": true,
"הפיגי": true,
"הפיוט": true,
"הפיוס": true,
"הפיזי": true,
"הפיחה": true,
"הפיחו": true,
"הפיחי": true,
"הפיכה": true,
"הפיכת": true,
"הפילה": true,
"הפילו": true,
"הפילי": true,
"הפינה": true,
"הפיסה": true,
"הפיסו": true,
"הפיסי": true,
"הפיצה": true,
"הפיצו": true,
"הפיצי": true,
"הפיקה": true,
"הפיקו": true,
"הפיקי": true,
"הפירא": true,
"הפכיה": true,
"הפכיו": true,
"הפכיי": true,
"הפכיך": true,
"הפכים": true,
"הפכית": true,
"הפככם": true,
"הפככן": true,
"הפכנו": true,
"הפכפך": true,
"הפכתי": true,
"הפכתם": true,
"הפכתן": true,
"הפלאה": true,
"הפלאת": true,
"הפלגה": true,
"הפלגת": true,
"הפלדה": true,
"הפלות": true,
"הפלטה": true,
"הפלטת": true,
"הפליא": true,
"הפליג": true,
"הפליה": true,
"הפליט": true,
"הפליל": true,
"הפליץ": true,
"הפליק": true,
"הפלית": true,
"הפללה": true,
"הפללת": true,
"הפלנה": true,
"הפלנו": true,
"הפלצה": true,
"הפלצת": true,
"הפלקת": true,
"הפלרה": true,
"הפלתה": true,
"הפלתו": true,
"הפלתי": true,
"הפלתך": true,
"הפלתם": true,
"הפלתן": true,
"הפנאי": true,
"הפנוט": true,
"הפנוי": true,
"הפנטה": true,
"הפנטו": true,
"הפנטי": true,
"הפנטת": true,
"הפניה": true,
"הפנים": true,
"הפנית": true,
"הפנמה": true,
"הפנמת": true,
"הפנתה": true,
"הפסגה": true,
"הפסדה": true,
"הפסדו": true,
"הפסדי": true,
"הפסדך": true,
"הפסדם": true,
"הפסדן": true,
"הפסדת": true,
"הפסות": true,
"הפסטה": true,
"הפסיד": true,
"הפסיכ": true,
"הפסים": true,
"הפסיק": true,
"הפסנה": true,
"הפסנו": true,
"הפסקה": true,
"הפסקת": true,
"הפסתה": true,
"הפסתו": true,
"הפסתי": true,
"הפסתך": true,
"הפסתם": true,
"הפסתן": true,
"הפעוט": true,
"הפעיל": true,
"הפעים": true,
"הפעלה": true,
"הפעלת": true,
"הפעמה": true,
"הפעמת": true,
"הפצוע": true,
"הפצות": true,
"הפציע": true,
"הפציץ": true,
"הפציר": true,
"הפצנה": true,
"הפצנו": true,
"הפצעת": true,
"הפצצה": true,
"הפצצת": true,
"הפצרה": true,
"הפצרת": true,
"הפצתה": true,
"הפצתו": true,
"הפצתי": true,
"הפצתך": true,
"הפצתם": true,
"הפצתן": true,
"הפקדה": true,
"הפקדת": true,
"הפקות": true,
"הפקיד": true,
"הפקיע": true,
"הפקיר": true,
"הפקנה": true,
"הפקנו": true,
"הפקעה": true,
"הפקעת": true,
"הפקרה": true,
"הפקרו": true,
"הפקרי": true,
"הפקרך": true,
"הפקרם": true,
"הפקרן": true,
"הפקרת": true,
"הפקתה": true,
"הפקתו": true,
"הפקתי": true,
"הפקתך": true,
"הפקתם": true,
"הפקתן": true,
"הפראי": true,
"הפרדה": true,
"הפרדס": true,
"הפרדת": true,
"הפרוב": true,
"הפרוט": true,
"הפרוע": true,
"הפרופ": true,
"הפרות": true,
"הפרזה": true,
"הפרזת": true,
"הפרחה": true,
"הפרחת": true,
"הפרטה": true,
"הפרטי": true,
"הפרטת": true,
"הפריד": true,
"הפריה": true,
"הפריז": true,
"הפריח": true,
"הפריט": true,
"הפריך": true,
"הפריס": true,
"הפריע": true,
"הפריש": true,
"הפרית": true,
"הפרכה": true,
"הפרכת": true,
"הפרנה": true,
"הפרנו": true,
"הפרסה": true,
"הפרסי": true,
"הפרסת": true,
"הפרעה": true,
"הפרעת": true,
"הפרקט": true,
"הפרשה": true,
"הפרשו": true,
"הפרשי": true,
"הפרשך": true,
"הפרשם": true,
"הפרשן": true,
"הפרשת": true,
"הפרתה": true,
"הפרתו": true,
"הפרתי": true,
"הפרתך": true,
"הפרתם": true,
"הפרתן": true,
"הפשוט": true,
"הפשטה": true,
"הפשטת": true,
"הפשיט": true,
"הפשיל": true,
"הפשיר": true,
"הפשלה": true,
"הפשלת": true,
"הפשרה": true,
"הפשרת": true,
"הפתוח": true,
"הפתיע": true,
"הפתעה": true,
"הפתעת": true,
"הצבאי": true,
"הצבור": true,
"הצבות": true,
"הצביע": true,
"הצבנה": true,
"הצבנו": true,
"הצבעה": true,
"הצבעת": true,
"הצבתה": true,
"הצבתו": true,
"הצבתי": true,
"הצבתך": true,
"הצבתם": true,
"הצבתן": true,
"הצגות": true,
"הצגנה": true,
"הצגנו": true,
"הצגתה": true,
"הצגתו": true,
"הצגתי": true,
"הצגתך": true,
"הצגתם": true,
"הצגתן": true,
"הצדיע": true,
"הצדיק": true,
"הצדעה": true,
"הצדעת": true,
"הצדקה": true,
"הצדקת": true,
"הצהבה": true,
"הצהבת": true,
"הצהוב": true,
"הצהיב": true,
"הצהיר": true,
"הצהרה": true,
"הצהרת": true,
"הצוות": true,
"הצומח": true,
"הצומת": true,
"הצופה": true,
"הצורה": true,
"הצורך": true,
"הצחוק": true,
"הצחין": true,
"הצחיק": true,
"הצחנה": true,
"הצחנו": true,
"הצחנת": true,
"הצחקת": true,
"הצטבע": true,
"הצטבר": true,
"הצטדק": true,
"הצטוו": true,
"הצטלב": true,
"הצטלל": true,
"הצטלם": true,
"הצטלק": true,
"הצטמג": true,
"הצטמק": true,
"הצטנן": true,
"הצטנע": true,
"הצטנף": true,
"הצטעף": true,
"הצטער": true,
"הצטפד": true,
"הצטרד": true,
"הצטרך": true,
"הצטרף": true,
"הציבה": true,
"הציבו": true,
"הציבי": true,
"הציגה": true,
"הציגו": true,
"הציגי": true,
"הצידה": true,
"הציוד": true,
"הציון": true,
"הציור": true,
"הצייר": true,
"הצילה": true,
"הצילו": true,
"הצילי": true,
"הצימר": true,
"הציעה": true,
"הציעו": true,
"הציעי": true,
"הציפה": true,
"הציפו": true,
"הציפי": true,
"הציצה": true,
"הציצו": true,
"הציצי": true,
"הציקה": true,
"הציקו": true,
"הציקי": true,
"הציתה": true,
"הציתו": true,
"הציתי": true,
"הצלבה": true,
"הצלבת": true,
"הצלות": true,
"הצלחה": true,
"הצלחת": true,
"הצליב": true,
"הצליח": true,
"הצליל": true,
"הצליף": true,
"הצללה": true,
"הצלנה": true,
"הצלנו": true,
"הצלפה": true,
"הצלפת": true,
"הצלתה": true,
"הצלתו": true,
"הצלתי": true,
"הצלתך": true,
"הצלתם": true,
"הצלתן": true,
"הצמאה": true,
"הצמאת": true,
"הצמדה": true,
"הצמדת": true,
"הצמוד": true,
"הצמחה": true,
"הצמחת": true,
"הצמיא": true,
"הצמיד": true,
"הצמיח": true,
"הצמית": true,
"הצמרת": true,
"הצמתה": true,
"הצמתי": true,
"הצמתם": true,
"הצמתן": true,
"הצנחה": true,
"הצנחת": true,
"הצניח": true,
"הצניע": true,
"הצנעה": true,
"הצנעת": true,
"הצנרת": true,
"הצעדה": true,
"הצעדת": true,
"הצעות": true,
"הצעיד": true,
"הצעיר": true,
"הצענה": true,
"הצענו": true,
"הצערה": true,
"הצערת": true,
"הצעתה": true,
"הצעתו": true,
"הצעתי": true,
"הצעתך": true,
"הצעתם": true,
"הצעתן": true,
"הצפוי": true,
"הצפון": true,
"הצפות": true,
"הצפין": true,
"הצפנה": true,
"הצפנו": true,
"הצפנת": true,
"הצפתה": true,
"הצפתו": true,
"הצפתי": true,
"הצפתך": true,
"הצפתם": true,
"הצפתן": true,
"הצצות": true,
"הצצנה": true,
"הצצנו": true,
"הצצתה": true,
"הצצתו": true,
"הצצתי": true,
"הצצתך": true,
"הצצתם": true,
"הצצתן": true,
"הצקות": true,
"הצקנה": true,
"הצקנו": true,
"הצקתה": true,
"הצקתו": true,
"הצקתי": true,
"הצקתך": true,
"הצקתם": true,
"הצקתן": true,
"הצרדה": true,
"הצרדת": true,
"הצרות": true,
"הצרחה": true,
"הצרחת": true,
"הצריד": true,
"הצריח": true,
"הצריך": true,
"הצרכן": true,
"הצרכת": true,
"הצרנה": true,
"הצרנו": true,
"הצרפת": true,
"הצרתה": true,
"הצרתו": true,
"הצרתי": true,
"הצרתך": true,
"הצרתם": true,
"הצרתן": true,
"הצתות": true,
"הצתנה": true,
"הצתנו": true,
"הצתתה": true,
"הצתתו": true,
"הצתתי": true,
"הצתתך": true,
"הצתתם": true,
"הצתתן": true,
"הקאות": true,
"הקאנה": true,
"הקאנו": true,
"הקאתה": true,
"הקאתו": true,
"הקאתי": true,
"הקאתך": true,
"הקאתם": true,
"הקאתן": true,
"הקבוע": true,
"הקבוצ": true,
"הקביל": true,
"הקבלה": true,
"הקבלן": true,
"הקבלת": true,
"הקבצה": true,
"הקדום": true,
"הקדוש": true,
"הקדחה": true,
"הקדחת": true,
"הקדיח": true,
"הקדים": true,
"הקדיש": true,
"הקדמה": true,
"הקדמי": true,
"הקדמת": true,
"הקדשה": true,
"הקדשו": true,
"הקדשי": true,
"הקדשך": true,
"הקדשם": true,
"הקדשן": true,
"הקדשת": true,
"הקהיה": true,
"הקהיל": true,
"הקהית": true,
"הקהלה": true,
"הקהלת": true,
"הקהתה": true,
"הקובע": true,
"הקובץ": true,
"הקודם": true,
"הקודש": true,
"הקוטב": true,
"הקומה": true,
"הקונה": true,
"הקונס": true,
"הקונצ": true,
"הקונק": true,
"הקוסם": true,
"הקופה": true,
"הקורא": true,
"הקורה": true,
"הקורס": true,
"הקושי": true,
"הקזות": true,
"הקזנה": true,
"הקזנו": true,
"הקזתה": true,
"הקזתו": true,
"הקזתי": true,
"הקזתך": true,
"הקזתם": true,
"הקזתן": true,
"הקטין": true,
"הקטיר": true,
"הקטנה": true,
"הקטנו": true,
"הקטנט": true,
"הקטנת": true,
"הקטרת": true,
"הקיאה": true,
"הקיאו": true,
"הקיאי": true,
"הקיבה": true,
"הקיום": true,
"הקיזה": true,
"הקיזו": true,
"הקיזי": true,
"הקייט": true,
"הקיים": true,
"הקימה": true,
"הקימו": true,
"הקימי": true,
"הקיסר": true,
"הקיפה": true,
"הקיפו": true,
"הקיפי": true,
"הקיצה": true,
"הקיצו": true,
"הקיצי": true,
"הקישה": true,
"הקישו": true,
"הקישי": true,
"הקלדה": true,
"הקלדת": true,
"הקלות": true,
"הקלטה": true,
"הקלטת": true,
"הקליד": true,
"הקליט": true,
"הקלים": true,
"הקליפ": true,
"הקליק": true,
"הקליש": true,
"הקלנה": true,
"הקלנו": true,
"הקלקה": true,
"הקלקת": true,
"הקלשה": true,
"הקלשת": true,
"הקלתה": true,
"הקלתו": true,
"הקלתי": true,
"הקלתך": true,
"הקלתם": true,
"הקלתן": true,
"הקמות": true,
"הקמנה": true,
"הקמנו": true,
"הקמתה": true,
"הקמתו": true,
"הקמתי": true,
"הקמתך": true,
"הקמתם": true,
"הקמתן": true,
"הקנאה": true,
"הקנדי": true,
"הקנטה": true,
"הקנטת": true,
"הקניה": true,
"הקניט": true,
"הקנית": true,
"הקנתה": true,
"הקסום": true,
"הקסים": true,
"הקסמה": true,
"הקסמת": true,
"הקערה": true,
"הקפאה": true,
"הקפאת": true,
"הקפדה": true,
"הקפדת": true,
"הקפוא": true,
"הקפות": true,
"הקפטן": true,
"הקפיא": true,
"הקפיד": true,
"הקפיץ": true,
"הקפנה": true,
"הקפנו": true,
"הקפצה": true,
"הקפצת": true,
"הקפתה": true,
"הקפתו": true,
"הקפתי": true,
"הקפתך": true,
"הקפתם": true,
"הקפתן": true,
"הקצאה": true,
"הקצאת": true,
"הקצבה": true,
"הקצבת": true,
"הקצות": true,
"הקציב": true,
"הקצין": true,
"הקציע": true,
"הקציף": true,
"הקצית": true,
"הקצנה": true,
"הקצנו": true,
"הקצנת": true,
"הקצעה": true,
"הקצעת": true,
"הקצפה": true,
"הקצפת": true,
"הקצרה": true,
"הקצתה": true,
"הקצתו": true,
"הקצתי": true,
"הקצתך": true,
"הקצתם": true,
"הקצתן": true,
"הקראה": true,
"הקראת": true,
"הקרבה": true,
"הקרבן": true,
"הקרבת": true,
"הקרדי": true,
"הקרוב": true,
"הקרוי": true,
"הקרחה": true,
"הקרחת": true,
"הקריא": true,
"הקריב": true,
"הקריה": true,
"הקריח": true,
"הקרים": true,
"הקרין": true,
"הקריש": true,
"הקרית": true,
"הקרמה": true,
"הקרמת": true,
"הקרנה": true,
"הקרנו": true,
"הקרנת": true,
"הקרקע": true,
"הקרשה": true,
"הקרשת": true,
"הקשבה": true,
"הקשבת": true,
"הקשור": true,
"הקשות": true,
"הקשחה": true,
"הקשחת": true,
"הקשיב": true,
"הקשיה": true,
"הקשיח": true,
"הקשים": true,
"הקשיש": true,
"הקשית": true,
"הקשנה": true,
"הקשנו": true,
"הקשרה": true,
"הקשרו": true,
"הקשרי": true,
"הקשרך": true,
"הקשרם": true,
"הקשרן": true,
"הקשתה": true,
"הקשתו": true,
"הקשתי": true,
"הקשתך": true,
"הקשתם": true,
"הקשתן": true,
"הראוי": true,
"הראות": true,
"הראיה": true,
"הראית": true,
"הראשי": true,
"הראתה": true,
"הרבות": true,
"הרביה": true,
"הרבים": true,
"הרביע": true,
"הרביץ": true,
"הרבית": true,
"הרבני": true,
"הרבעה": true,
"הרבעת": true,
"הרבצה": true,
"הרבצת": true,
"הרברט": true,
"הרבתה": true,
"הרגזה": true,
"הרגזת": true,
"הרגיז": true,
"הרגיל": true,
"הרגיע": true,
"הרגיש": true,
"הרגלה": true,
"הרגלו": true,
"הרגלי": true,
"הרגלך": true,
"הרגלם": true,
"הרגלן": true,
"הרגלת": true,
"הרגנו": true,
"הרגעה": true,
"הרגעת": true,
"הרגשה": true,
"הרגשי": true,
"הרגשת": true,
"הרגתי": true,
"הרגתם": true,
"הרגתן": true,
"הרדוף": true,
"הרדיו": true,
"הרדים": true,
"הרדיק": true,
"הרדמה": true,
"הרדמת": true,
"הרהבה": true,
"הרהבת": true,
"הרהור": true,
"הרהיב": true,
"הרהרה": true,
"הרהרו": true,
"הרהרי": true,
"הרהרת": true,
"הרואה": true,
"הרואי": true,
"הרובע": true,
"הרוגה": true,
"הרווה": true,
"הרווח": true,
"הרווי": true,
"הרוחב": true,
"הרוטב": true,
"הרוכב": true,
"הרוכש": true,
"הרולד": true,
"הרומי": true,
"הרומן": true,
"הרוסה": true,
"הרוסי": true,
"הרופא": true,
"הרוצה": true,
"הרוצח": true,
"הרושם": true,
"הרזיה": true,
"הרזית": true,
"הרזתה": true,
"הרחבה": true,
"הרחבת": true,
"הרחוב": true,
"הרחוק": true,
"הרחות": true,
"הרחיב": true,
"הרחיק": true,
"הרחנה": true,
"הרחנו": true,
"הרחצה": true,
"הרחקה": true,
"הרחקת": true,
"הרחתה": true,
"הרחתו": true,
"הרחתי": true,
"הרחתך": true,
"הרחתם": true,
"הרחתן": true,
"הרטבה": true,
"הרטבת": true,
"הרטוב": true,
"הרטטה": true,
"הרטטת": true,
"הרטיב": true,
"הרטיט": true,
"הריאה": true,
"הריגה": true,
"הריהו": true,
"הריהי": true,
"הריהם": true,
"הריהן": true,
"הריון": true,
"הריחה": true,
"הריחו": true,
"הריחי": true,
"הרייך": true,
"הריכם": true,
"הריכן": true,
"הרימה": true,
"הרימו": true,
"הרימי": true,
"הרינה": true,
"הרינו": true,
"הריני": true,
"הריסה": true,
"הריסת": true,
"הריעה": true,
"הריעו": true,
"הריעי": true,
"הריצה": true,
"הריצו": true,
"הריצי": true,
"הריקה": true,
"הריקו": true,
"הריקי": true,
"הריתי": true,
"הריתם": true,
"הריתן": true,
"הרכבה": true,
"הרכבו": true,
"הרכבי": true,
"הרכבך": true,
"הרכבם": true,
"הרכבן": true,
"הרכבת": true,
"הרכוש": true,
"הרכות": true,
"הרכיב": true,
"הרכין": true,
"הרכנה": true,
"הרכנו": true,
"הרכנת": true,
"הרלוו": true,
"הרמון": true,
"הרמות": true,
"הרמזה": true,
"הרמזו": true,
"הרמזי": true,
"הרמזך": true,
"הרמזם": true,
"הרמזן": true,
"הרמטי": true,
"הרמטכ": true,
"הרמנה": true,
"הרמנו": true,
"הרמתה": true,
"הרמתו": true,
"הרמתי": true,
"הרמתך": true,
"הרמתם": true,
"הרמתן": true,
"הרנין": true,
"הרננה": true,
"הרננו": true,
"הרננת": true,
"הרסנו": true,
"הרסני": true,
"הרסתי": true,
"הרסתם": true,
"הרסתן": true,
"הרעבה": true,
"הרעבת": true,
"הרעדה": true,
"הרעדת": true,
"הרעות": true,
"הרעיב": true,
"הרעיד": true,
"הרעיל": true,
"הרעים": true,
"הרעיף": true,
"הרעיש": true,
"הרעלה": true,
"הרעלת": true,
"הרעמה": true,
"הרעמת": true,
"הרענה": true,
"הרענו": true,
"הרעפה": true,
"הרעפת": true,
"הרעשה": true,
"הרעשת": true,
"הרעתה": true,
"הרעתו": true,
"הרעתי": true,
"הרעתך": true,
"הרעתם": true,
"הרעתן": true,
"הרפוא": true,
"הרפור": true,
"הרפיה": true,
"הרפית": true,
"הרפתה": true,
"הרצאה": true,
"הרצוג": true,
"הרצוי": true,
"הרצון": true,
"הרצות": true,
"הרצחת": true,
"הרצין": true,
"הרצית": true,
"הרצנה": true,
"הרצנו": true,
"הרצנת": true,
"הרצפה": true,
"הרצתה": true,
"הרצתו": true,
"הרצתי": true,
"הרצתך": true,
"הרצתם": true,
"הרצתן": true,
"הרקבת": true,
"הרקדה": true,
"הרקדת": true,
"הרקות": true,
"הרקיב": true,
"הרקיד": true,
"הרקיע": true,
"הרקמה": true,
"הרקנה": true,
"הרקנו": true,
"הרקעה": true,
"הרקעת": true,
"הרקתה": true,
"הרקתו": true,
"הרקתי": true,
"הרקתך": true,
"הרקתם": true,
"הרקתן": true,
"הרריה": true,
"הרריו": true,
"הרריי": true,
"הרריך": true,
"הררים": true,
"הררית": true,
"הרשאה": true,
"הרשום": true,
"הרשות": true,
"הרשים": true,
"הרשיע": true,
"הרשית": true,
"הרשמה": true,
"הרשמי": true,
"הרשמת": true,
"הרשעה": true,
"הרשעת": true,
"הרשתה": true,
"הרתחה": true,
"הרתחת": true,
"הרתיח": true,
"הרתיע": true,
"הרתעה": true,
"הרתעת": true,
"השאות": true,
"השאיל": true,
"השאיף": true,
"השאיר": true,
"השאלה": true,
"השאלת": true,
"השאנה": true,
"השאנו": true,
"השאפה": true,
"השאפת": true,
"השארה": true,
"השארת": true,
"השאתה": true,
"השאתו": true,
"השאתי": true,
"השאתך": true,
"השאתם": true,
"השאתן": true,
"השבוע": true,
"השבות": true,
"השבחה": true,
"השבחת": true,
"השביח": true,
"השביל": true,
"השבים": true,
"השביע": true,
"השביר": true,
"השבית": true,
"השבנה": true,
"השבנו": true,
"השבעה": true,
"השבעת": true,
"השברה": true,
"השברת": true,
"השבתה": true,
"השבתו": true,
"השבתי": true,
"השבתך": true,
"השבתם": true,
"השבתן": true,
"השבתת": true,
"השגות": true,
"השגחה": true,
"השגחת": true,
"השגיח": true,
"השגנה": true,
"השגנו": true,
"השגרה": true,
"השגתה": true,
"השגתו": true,
"השגתי": true,
"השגתך": true,
"השגתם": true,
"השגתן": true,
"השדות": true,
"השדים": true,
"השדרה": true,
"השהות": true,
"השהיה": true,
"השהית": true,
"השהתה": true,
"השואה": true,
"השואל": true,
"השובר": true,
"השווא": true,
"השווה": true,
"השווי": true,
"השווץ": true,
"השוחד": true,
"השוטף": true,
"השוטר": true,
"השוכן": true,
"השוכר": true,
"השולח": true,
"השולט": true,
"השומה": true,
"השומן": true,
"השומר": true,
"השונה": true,
"השוני": true,
"השוער": true,
"השופט": true,
"השופר": true,
"השורה": true,
"השורש": true,
"השותף": true,
"השחור": true,
"השחות": true,
"השחזה": true,
"השחזת": true,
"השחיז": true,
"השחיל": true,
"השחים": true,
"השחיר": true,
"השחית": true,
"השחלה": true,
"השחלת": true,
"השחמה": true,
"השחמט": true,
"השחמת": true,
"השחנה": true,
"השחנו": true,
"השחקן": true,
"השחרה": true,
"השחרת": true,
"השחתה": true,
"השחתו": true,
"השחתי": true,
"השחתך": true,
"השחתם": true,
"השחתן": true,
"השחתת": true,
"השטות": true,
"השטיח": true,
"השטנה": true,
"השטנו": true,
"השטתה": true,
"השטתו": true,
"השטתי": true,
"השטתך": true,
"השטתם": true,
"השטתן": true,
"השיאה": true,
"השיאו": true,
"השיאי": true,
"השיבה": true,
"השיבו": true,
"השיבי": true,
"השיגה": true,
"השיגו": true,
"השיגי": true,
"השיחה": true,
"השיחו": true,
"השיחי": true,
"השיטה": true,
"השיטו": true,
"השיטי": true,
"השייט": true,
"השייך": true,
"השילה": true,
"השילו": true,
"השילי": true,
"השימה": true,
"השימו": true,
"השימי": true,
"השינה": true,
"השיער": true,
"השיקה": true,
"השיקו": true,
"השיקי": true,
"השירה": true,
"השירו": true,
"השירי": true,
"השישי": true,
"השיתה": true,
"השיתו": true,
"השיתי": true,
"השכבה": true,
"השכבת": true,
"השכול": true,
"השכחה": true,
"השכחת": true,
"השכיב": true,
"השכיח": true,
"השכיל": true,
"השכים": true,
"השכין": true,
"השכיר": true,
"השכלה": true,
"השכלת": true,
"השכמה": true,
"השכמת": true,
"השכנה": true,
"השכנו": true,
"השכנת": true,
"השכרה": true,
"השכרת": true,
"השלום": true,
"השלוש": true,
"השלות": true,
"השלחן": true,
"השלטה": true,
"השלטת": true,
"השליה": true,
"השליח": true,
"השליט": true,
"השליך": true,
"השלים": true,
"השליש": true,
"השלית": true,
"השלכה": true,
"השלכת": true,
"השלמה": true,
"השלמת": true,
"השלנה": true,
"השלנו": true,
"השלתה": true,
"השלתו": true,
"השלתי": true,
"השלתך": true,
"השלתם": true,
"השלתן": true,
"השמאי": true,
"השמאל": true,
"השמדה": true,
"השמדת": true,
"השמות": true,
"השמחה": true,
"השמטה": true,
"השמטת": true,
"השמיד": true,
"השמיט": true,
"השמים": true,
"השמין": true,
"השמיע": true,
"השמיץ": true,
"השמיש": true,
"השמלה": true,
"השמנה": true,
"השמנו": true,
"השמנת": true,
"השמעה": true,
"השמעת": true,
"השמצה": true,
"השמצת": true,
"השמשה": true,
"השמשת": true,
"השמתה": true,
"השמתו": true,
"השמתי": true,
"השמתך": true,
"השמתם": true,
"השמתן": true,
"השנאה": true,
"השנאת": true,
"השנוי": true,
"השניא": true,
"השניה": true,
"השנים": true,
"השנתי": true,
"השעון": true,
"השעות": true,
"השעיה": true,
"השעין": true,
"השעית": true,
"השענה": true,
"השענו": true,
"השענת": true,
"השערה": true,
"השעתה": true,
"השפות": true,
"השפיל": true,
"השפים": true,
"השפיע": true,
"השפלה": true,
"השפלת": true,
"השפעה": true,
"השפעת": true,
"השפרץ": true,
"השקות": true,
"השקטה": true,
"השקטת": true,
"השקיה": true,
"השקיט": true,
"השקיע": true,
"השקיף": true,
"השקית": true,
"השקנה": true,
"השקנו": true,
"השקעה": true,
"השקעת": true,
"השקפה": true,
"השקפת": true,
"השקתה": true,
"השקתו": true,
"השקתי": true,
"השקתך": true,
"השקתם": true,
"השקתן": true,
"השראה": true,
"השרון": true,
"השרות": true,
"השריה": true,
"השרים": true,
"השריץ": true,
"השריר": true,
"השריש": true,
"השרית": true,
"השרנה": true,
"השרנו": true,
"השרצה": true,
"השרצת": true,
"השרשה": true,
"השרשת": true,
"השרתה": true,
"השרתו": true,
"השרתי": true,
"השרתך": true,
"השרתם": true,
"השרתן": true,
"השתאה": true,
"השתאו": true,
"השתאי": true,
"השתבח": true,
"השתבט": true,
"השתבץ": true,
"השתבר": true,
"השתבש": true,
"השתגע": true,
"השתדך": true,
"השתדל": true,
"השתהה": true,
"השתהו": true,
"השתהי": true,
"השתוו": true,
"השתות": true,
"השתזף": true,
"השתחל": true,
"השתחץ": true,
"השתטה": true,
"השתטו": true,
"השתטח": true,
"השתטי": true,
"השתיל": true,
"השתין": true,
"השתיק": true,
"השתית": true,
"השתכח": true,
"השתכן": true,
"השתכר": true,
"השתלב": true,
"השתלה": true,
"השתלח": true,
"השתלט": true,
"השתלם": true,
"השתלת": true,
"השתמד": true,
"השתמט": true,
"השתמע": true,
"השתמר": true,
"השתמש": true,
"השתנה": true,
"השתנו": true,
"השתני": true,
"השתנק": true,
"השתנת": true,
"השתעל": true,
"השתפד": true,
"השתפה": true,
"השתפך": true,
"השתפל": true,
"השתפן": true,
"השתפר": true,
"השתקה": true,
"השתקם": true,
"השתקע": true,
"השתקף": true,
"השתקת": true,
"השתרג": true,
"השתרך": true,
"השתרע": true,
"השתרר": true,
"השתרש": true,
"השתתה": true,
"השתתו": true,
"השתתי": true,
"השתתך": true,
"השתתם": true,
"השתתן": true,
"השתתף": true,
"השתתק": true,
"התאבד": true,
"התאבך": true,
"התאבל": true,
"התאבן": true,
"התאבק": true,
"התאגד": true,
"התאדה": true,
"התאדו": true,
"התאדי": true,
"התאהב": true,
"התאוו": true,
"התאזן": true,
"התאזר": true,
"התאחד": true,
"התאחה": true,
"התאחו": true,
"התאחי": true,
"התאחר": true,
"התאים": true,
"התאכל": true,
"התאמה": true,
"התאמו": true,
"התאמי": true,
"התאמך": true,
"התאמם": true,
"התאמן": true,
"התאמץ": true,
"התאמת": true,
"התאסף": true,
"התאפס": true,
"התאפק": true,
"התאפר": true,
"התארג": true,
"התארח": true,
"התארך": true,
"התארס": true,
"התאשר": true,
"התבאס": true,
"התבאר": true,
"התבגר": true,
"התבדה": true,
"התבדו": true,
"התבדח": true,
"התבדי": true,
"התבדל": true,
"התבדר": true,
"התבהם": true,
"התבהר": true,
"התבזה": true,
"התבזו": true,
"התבזי": true,
"התבטא": true,
"התבטל": true,
"התבלה": true,
"התבלו": true,
"התבלט": true,
"התבלי": true,
"התבסם": true,
"התבסס": true,
"התבצע": true,
"התבצר": true,
"התבקע": true,
"התבקש": true,
"התברג": true,
"התברך": true,
"התברר": true,
"התבשל": true,
"התבשם": true,
"התבשר": true,
"התגאה": true,
"התגאו": true,
"התגאי": true,
"התגבה": true,
"התגבו": true,
"התגבי": true,
"התגבר": true,
"התגבש": true,
"התגדל": true,
"התגדר": true,
"התגור": true,
"התגים": true,
"התגלה": true,
"התגלו": true,
"התגלח": true,
"התגלי": true,
"התגלם": true,
"התגלע": true,
"התגלש": true,
"התגמד": true,
"התגמש": true,
"התגנב": true,
"התגפף": true,
"התגרד": true,
"התגרה": true,
"התגרו": true,
"התגרי": true,
"התגרש": true,
"התגשם": true,
"התדמה": true,
"התדמו": true,
"התדמי": true,
"התדפק": true,
"התהדק": true,
"התהדר": true,
"התהוו": true,
"התהום": true,
"התהלך": true,
"התהלל": true,
"התהפך": true,
"התואם": true,
"התואר": true,
"התובע": true,
"התודה": true,
"התווה": true,
"התווי": true,
"התווך": true,
"התוכל": true,
"התוכן": true,
"התומך": true,
"התוסף": true,
"התוצר": true,
"התוקף": true,
"התורה": true,
"התורם": true,
"התורן": true,
"התותח": true,
"התזות": true,
"התזנה": true,
"התזנו": true,
"התזתה": true,
"התזתו": true,
"התזתי": true,
"התזתך": true,
"התזתם": true,
"התזתן": true,
"התחבא": true,
"התחבב": true,
"התחבט": true,
"התחבק": true,
"התחבר": true,
"התחדד": true,
"התחדש": true,
"התחום": true,
"התחזה": true,
"התחזו": true,
"התחזי": true,
"התחזק": true,
"התחיל": true,
"התחכך": true,
"התחכם": true,
"התחלה": true,
"התחלו": true,
"התחלי": true,
"התחלף": true,
"התחלק": true,
"התחלת": true,
"התחמם": true,
"התחמק": true,
"התחמש": true,
"התחנה": true,
"התחנך": true,
"התחנן": true,
"התחנף": true,
"התחסד": true,
"התחסל": true,
"התחסן": true,
"התחפף": true,
"התחפר": true,
"התחפש": true,
"התחצף": true,
"התחקה": true,
"התחקו": true,
"התחקי": true,
"התחרד": true,
"התחרה": true,
"התחרו": true,
"התחרז": true,
"התחרט": true,
"התחרי": true,
"התחרש": true,
"התחשב": true,
"התחשל": true,
"התחשק": true,
"התחתן": true,
"התחתר": true,
"התיבה": true,
"התיזה": true,
"התיזו": true,
"התיזי": true,
"התייע": true,
"התייש": true,
"התיכה": true,
"התיכו": true,
"התיכי": true,
"התירה": true,
"התירו": true,
"התירי": true,
"התירס": true,
"התישה": true,
"התישו": true,
"התישי": true,
"התכבד": true,
"התכבס": true,
"התכדר": true,
"התכהה": true,
"התכהו": true,
"התכהי": true,
"התכוו": true,
"התכות": true,
"התכחש": true,
"התכלה": true,
"התכלו": true,
"התכלי": true,
"התכלת": true,
"התכנה": true,
"התכנו": true,
"התכני": true,
"התכנס": true,
"התכסה": true,
"התכסו": true,
"התכסח": true,
"התכסי": true,
"התכער": true,
"התכפר": true,
"התכתב": true,
"התכתה": true,
"התכתו": true,
"התכתי": true,
"התכתך": true,
"התכתם": true,
"התכתן": true,
"התכתש": true,
"התלבט": true,
"התלבן": true,
"התלבש": true,
"התלהב": true,
"התלהט": true,
"התלהם": true,
"התלוו": true,
"התלוי": true,
"התלות": true,
"התלחש": true,
"התלטף": true,
"התלטש": true,
"התלכד": true,
"התלמד": true,
"התלנה": true,
"התלפף": true,
"התלקח": true,
"התלקק": true,
"התמגן": true,
"התמדה": true,
"התמדת": true,
"התמהה": true,
"התמהת": true,
"התמוד": true,
"התמזג": true,
"התמזל": true,
"התמחה": true,
"התמחו": true,
"התמחי": true,
"התמיד": true,
"התמיה": true,
"התמים": true,
"התמיר": true,
"התמכן": true,
"התמכר": true,
"התמלא": true,
"התממש": true,
"התמנה": true,
"התמנו": true,
"התמני": true,
"התמנף": true,
"התמסד": true,
"התמסה": true,
"התמסו": true,
"התמסי": true,
"התמסר": true,
"התמעט": true,
"התמצא": true,
"התמצה": true,
"התמצו": true,
"התמצי": true,
"התמצק": true,
"התמקד": true,
"התמקח": true,
"התמקם": true,
"התמרד": true,
"התמרה": true,
"התמרח": true,
"התמרק": true,
"התמרת": true,
"התמשך": true,
"התמתח": true,
"התמתן": true,
"התנאה": true,
"התנאו": true,
"התנאי": true,
"התנבא": true,
"התנגב": true,
"התנגד": true,
"התנגח": true,
"התנגן": true,
"התנגש": true,
"התנדב": true,
"התנדף": true,
"התנהג": true,
"התנהל": true,
"התנור": true,
"התנזל": true,
"התנזר": true,
"התנחל": true,
"התנחם": true,
"התניא": true,
"התניה": true,
"התניע": true,
"התנית": true,
"התנכל": true,
"התנכר": true,
"התנסה": true,
"התנסו": true,
"התנסח": true,
"התנסי": true,
"התנעה": true,
"התנער": true,
"התנעת": true,
"התנפח": true,
"התנפל": true,
"התנפץ": true,
"התנצח": true,
"התנצל": true,
"התנצר": true,
"התנקה": true,
"התנקו": true,
"התנקז": true,
"התנקי": true,
"התנקם": true,
"התנקש": true,
"התנשא": true,
"התנשם": true,
"התנשף": true,
"התנשק": true,
"התנתב": true,
"התנתה": true,
"התנתק": true,
"התסיס": true,
"התססה": true,
"התססת": true,
"התעבה": true,
"התעבו": true,
"התעבי": true,
"התעבר": true,
"התעגל": true,
"התעדן": true,
"התעור": true,
"התעטף": true,
"התעטש": true,
"התעכב": true,
"התעכל": true,
"התעלה": true,
"התעלו": true,
"התעלי": true,
"התעלל": true,
"התעלם": true,
"התעלס": true,
"התעלף": true,
"התעלק": true,
"התעמל": true,
"התעמם": true,
"התעמק": true,
"התעמר": true,
"התעמת": true,
"התענג": true,
"התענה": true,
"התענו": true,
"התעני": true,
"התענן": true,
"התעסק": true,
"התעפש": true,
"התעצב": true,
"התעצל": true,
"התעצם": true,
"התעקל": true,
"התעקם": true,
"התעקר": true,
"התעקש": true,
"התערב": true,
"התערה": true,
"התערו": true,
"התערי": true,
"התעשי": true,
"התעשר": true,
"התעשת": true,
"התעתד": true,
"התפאר": true,
"התפגל": true,
"התפגר": true,
"התפוח": true,
"התפוצ": true,
"התפזם": true,
"התפזר": true,
"התפחה": true,
"התפחם": true,
"התפחס": true,
"התפחת": true,
"התפטם": true,
"התפטר": true,
"התפיח": true,
"התפיל": true,
"התפכח": true,
"התפלא": true,
"התפלג": true,
"התפלה": true,
"התפלח": true,
"התפלל": true,
"התפלץ": true,
"התפלש": true,
"התפלת": true,
"התפנה": true,
"התפנו": true,
"התפני": true,
"התפנק": true,
"התפעל": true,
"התפעם": true,
"התפצח": true,
"התפצל": true,
"התפקד": true,
"התפקח": true,
"התפקע": true,
"התפקר": true,
"התפרס": true,
"התפרע": true,
"התפרץ": true,
"התפרק": true,
"התפרש": true,
"התפשט": true,
"התפשל": true,
"התפשר": true,
"התפתה": true,
"התפתו": true,
"התפתח": true,
"התפתי": true,
"התפתל": true,
"התקבל": true,
"התקבע": true,
"התקבץ": true,
"התקדם": true,
"התקדר": true,
"התקדש": true,
"התקהל": true,
"התקזז": true,
"התקיל": true,
"התקין": true,
"התקיף": true,
"התקלה": true,
"התקלח": true,
"התקלס": true,
"התקלף": true,
"התקלת": true,
"התקמט": true,
"התקמר": true,
"התקנא": true,
"התקנה": true,
"התקנו": true,
"התקני": true,
"התקנך": true,
"התקנם": true,
"התקנן": true,
"התקנת": true,
"התקפד": true,
"התקפה": true,
"התקפו": true,
"התקפי": true,
"התקפך": true,
"התקפל": true,
"התקפם": true,
"התקפן": true,
"התקפת": true,
"התקצץ": true,
"התקצר": true,
"התקרא": true,
"התקרב": true,
"התקרה": true,
"התקרח": true,
"התקרר": true,
"התקרש": true,
"התקשה": true,
"התקשו": true,
"התקשח": true,
"התקשט": true,
"התקשי": true,
"התקשר": true,
"התראה": true,
"התראו": true,
"התראי": true,
"התרבד": true,
"התרבה": true,
"התרבו": true,
"התרבי": true,
"התרגז": true,
"התרגל": true,
"התרגם": true,
"התרגע": true,
"התרגש": true,
"התרדד": true,
"התרהב": true,
"התרות": true,
"התרחב": true,
"התרחץ": true,
"התרחק": true,
"התרחש": true,
"התרטב": true,
"התרים": true,
"התריס": true,
"התריע": true,
"התרית": true,
"התרכב": true,
"התרכז": true,
"התרכך": true,
"התרמה": true,
"התרמת": true,
"התרנג": true,
"התרנה": true,
"התרנו": true,
"התרסה": true,
"התרסן": true,
"התרסק": true,
"התרסת": true,
"התרעה": true,
"התרעם": true,
"התרעת": true,
"התרפא": true,
"התרפה": true,
"התרפו": true,
"התרפט": true,
"התרפי": true,
"התרפס": true,
"התרפק": true,
"התרצה": true,
"התרצו": true,
"התרצי": true,
"התרקם": true,
"התרשל": true,
"התרשם": true,
"התרתה": true,
"התרתו": true,
"התרתח": true,
"התרתי": true,
"התרתך": true,
"התרתם": true,
"התרתן": true,
"התשות": true,
"התשנה": true,
"התשנו": true,
"התשתה": true,
"התשתו": true,
"התשתי": true,
"התשתך": true,
"התשתם": true,
"התשתן": true,
"התתמר": true,
"התתנה": true,
"ואבדן": true,
"ואביה": true,
"ואביו": true,
"ואבים": true,
"ואבני": true,
"ואדיב": true,
"ואדיו": true,
"ואדיי": true,
"ואדיך": true,
"ואהבה": true,
"ואהבת": true,
"ואהרן": true,
"ואודי": true,
"ואוהב": true,
"ואוכל": true,
"ואולי": true,
"ואולם": true,
"ואומר": true,
"ואופט": true,
"ואופי": true,
"ואופן": true,
"ואורח": true,
"ואורי": true,
"ואורך": true,
"ואותה": true,
"ואותו": true,
"ואותם": true,
"ואזור": true,
"ואזרח": true,
"ואחות": true,
"ואחיו": true,
"ואחרי": true,
"ואטיה": true,
"ואטים": true,
"ואידי": true,
"ואיות": true,
"ואיזה": true,
"ואיזו": true,
"ואיזם": true,
"ואיין": true,
"ואילו": true,
"ואילך": true,
"ואינה": true,
"ואינו": true,
"ואינט": true,
"ואיני": true,
"ואינם": true,
"ואיפה": true,
"ואישה": true,
"ואישי": true,
"ואיתו": true,
"ואכבר": true,
"ואליד": true,
"ואליה": true,
"ואלים": true,
"ואלית": true,
"ואלכס": true,
"ואללה": true,
"ואלפי": true,
"ואלקט": true,
"ואמור": true,
"ואמים": true,
"ואמין": true,
"ואמית": true,
"ואמנם": true,
"ואמרה": true,
"ואמרו": true,
"ואמרת": true,
"ואנזה": true,
"ואנטי": true,
"ואנים": true,
"ואנכי": true,
"ואנשי": true,
"ואסור": true,
"ואפקט": true,
"ואפשר": true,
"ואקום": true,
"וארבע": true,
"וארוך": true,
"וארות": true,
"וארים": true,
"וארמה": true,
"ואשמח": true,
"ואשתו": true,
"ואתרי": true,
"ובאים": true,
"ובאמת": true,
"ובארה": true,
"ובארו": true,
"ובארץ": true,
"ובאשר": true,
"ובאתר": true,
"ובבות": true,
"ובבזן": true,
"ובבים": true,
"ובבית": true,
"ובבני": true,
"ובברי": true,
"ובבתי": true,
"ובגיל": true,
"ובגין": true,
"ובגלל": true,
"ובדאר": true,
"ובדבן": true,
"ובדות": true,
"ובדיה": true,
"ובדים": true,
"ובדרך": true,
"ובונה": true,
"ובזמן": true,
"ובחלק": true,
"ובחרו": true,
"ובטוח": true,
"ובטון": true,
"וביוב": true,
"וביום": true,
"וביות": true,
"וביחד": true,
"וביחס": true,
"ובייל": true,
"ובילה": true,
"ובילו": true,
"ובילי": true,
"ובימי": true,
"ובינה": true,
"ובינו": true,
"וביצע": true,
"וביקש": true,
"וביתר": true,
"ובכדי": true,
"ובכלל": true,
"ובכמה": true,
"ובכתב": true,
"ובלבד": true,
"ובלות": true,
"ובלים": true,
"ובליצ": true,
"ובלתי": true,
"ובמבר": true,
"ובמצב": true,
"ובמרן": true,
"ובמשך": true,
"ובנות": true,
"ובניה": true,
"ובניו": true,
"ובנים": true,
"ובסהכ": true,
"ובסוף": true,
"ובסיס": true,
"ובספר": true,
"ובסקי": true,
"ובעבר": true,
"ובעוד": true,
"ובעות": true,
"ובעים": true,
"ובעיר": true,
"ובעלה": true,
"ובעלי": true,
"ובעלת": true,
"ובעצם": true,
"ובערב": true,
"ובפני": true,
"ובפעם": true,
"ובפרט": true,
"ובצדק": true,
"ובקרב": true,
"ובקרה": true,
"ובקשה": true,
"ובראן": true,
"ובראש": true,
"וברגע": true,
"וברוב": true,
"וברוח": true,
"וברוך": true,
"וברור": true,
"וברות": true,
"ובריא": true,
"וברים": true,
"וברכה": true,
"וברמה": true,
"וברמת": true,
"ובשאר": true,
"ובשים": true,
"ובשלב": true,
"ובשנה": true,
"ובשני": true,
"ובשנת": true,
"ובשעה": true,
"ובתוך": true,
"ובתום": true,
"ובתור": true,
"ובתים": true,
"וגגות": true,
"וגגים": true,
"וגדול": true,
"וגדים": true,
"וגדלה": true,
"וגובה": true,
"וגובר": true,
"וגודל": true,
"וגורם": true,
"וגזית": true,
"וגיות": true,
"וגיים": true,
"וגילה": true,
"וגילו": true,
"וגישה": true,
"וגלות": true,
"וגנות": true,
"וגניט": true,
"וגנים": true,
"וגנית": true,
"וגעות": true,
"וגעים": true,
"וגרות": true,
"וגרים": true,
"וגרמה": true,
"וגרמו": true,
"וגרפי": true,
"וגתות": true,
"ודאגה": true,
"ודאות": true,
"ודאים": true,
"ודאית": true,
"ודאנה": true,
"ודברי": true,
"ודגים": true,
"ודדים": true,
"ודומה": true,
"ודוקא": true,
"ודוקס": true,
"ודורש": true,
"ודיוק": true,
"ודיות": true,
"ודיים": true,
"ודיני": true,
"ודעות": true,
"ודעים": true,
"ודעין": true,
"ודעתי": true,
"ודקים": true,
"ודרוך": true,
"ודרום": true,
"ודרות": true,
"ודרים": true,
"ודרין": true,
"ודרכי": true,
"ודרמה": true,
"ודרשו": true,
"ודשים": true,
"והאבי": true,
"והאדם": true,
"והאור": true,
"והאינ": true,
"והאיר": true,
"והאיש": true,
"והאמת": true,
"והארץ": true,
"והאתר": true,
"והביא": true,
"והביט": true,
"והבים": true,
"והבין": true,
"והבית": true,
"והבנה": true,
"והבנת": true,
"והברי": true,
"והגוף": true,
"והגיע": true,
"והגנה": true,
"והגרי": true,
"והדבר": true,
"והדרך": true,
"והודו": true,
"והולך": true,
"והופך": true,
"והורה": true,
"והזמן": true,
"והחבר": true,
"והחוק": true,
"והחלה": true,
"והחלו": true,
"והחלט": true,
"והחלק": true,
"והטבע": true,
"והטוב": true,
"והטעם": true,
"והידע": true,
"והיום": true,
"והיות": true,
"והיחס": true,
"והיית": true,
"והיכן": true,
"והילד": true,
"והינה": true,
"והינו": true,
"והיסט": true,
"והיפה": true,
"והיקף": true,
"והיתה": true,
"והיתר": true,
"והכאב": true,
"והכוח": true,
"והכול": true,
"והכלה": true,
"והכנה": true,
"והכנת": true,
"והכסף": true,
"והכרה": true,
"והלאה": true,
"והלכה": true,
"והלכו": true,
"והמוד": true,
"והמון": true,
"והמוס": true,
"והמוע": true,
"והמור": true,
"והמוש": true,
"והמים": true,
"והמלח": true,
"והמנה": true,
"והמצב": true,
"והמרת": true,
"והמשת": true,
"והנאה": true,
"והנוף": true,
"והנחת": true,
"והניח": true,
"והנים": true,
"והנפש": true,
"והספר": true,
"והסרט": true,
"והעבר": true,
"והעור": true,
"והעיר": true,
"והעלה": true,
"והעסק": true,
"והערב": true,
"והפכה": true,
"והפני": true,
"והפעם": true,
"והפצה": true,
"והפקה": true,
"והפקת": true,
"והפרו": true,
"והפרי": true,
"והפרת": true,
"והצבא": true,
"והצבע": true,
"והצגת": true,
"והציג": true,
"והציע": true,
"והצלה": true,
"והצפי": true,
"והקהל": true,
"והקול": true,
"והקור": true,
"והקים": true,
"והקמת": true,
"והקשר": true,
"והראה": true,
"והראש": true,
"והרבה": true,
"והרגש": true,
"והרוח": true,
"והרים": true,
"והרכב": true,
"והרמב": true,
"והשאר": true,
"והשוק": true,
"והשיב": true,
"והשלי": true,
"והשנה": true,
"והשני": true,
"וואלה": true,
"וואפא": true,
"וודאו": true,
"וודאי": true,
"וודקה": true,
"ווהבי": true,
"ווחים": true,
"וויאס": true,
"וויהם": true,
"וויהן": true,
"וויון": true,
"וויות": true,
"וויטה": true,
"וויטר": true,
"וויטש": true,
"ווייה": true,
"ווייך": true,
"וויים": true,
"וויין": true,
"וויית": true,
"וויכם": true,
"וויכן": true,
"ווילי": true,
"ווימן": true,
"ווינו": true,
"ווינר": true,
"וויסט": true,
"ווירה": true,
"ווירת": true,
"וויתי": true,
"ווכים": true,
"וולבה": true,
"וולבו": true,
"וולגה": true,
"וולוו": true,
"וולטר": true,
"וולפא": true,
"וולקן": true,
"וונדר": true,
"וונות": true,
"וונים": true,
"וונתו": true,
"וונתם": true,
"ווסתה": true,
"ווסתו": true,
"ווסתי": true,
"ווסתם": true,
"ווסתן": true,
"וועדה": true,
"וועדת": true,
"ווקים": true,
"ווקלי": true,
"וורנר": true,
"וותיק": true,
"וותרו": true,
"וזוהי": true,
"וזוהר": true,
"וזוכה": true,
"וזולה": true,
"וזורע": true,
"וזיות": true,
"וזכות": true,
"וזכתה": true,
"וזלטר": true,
"וזלין": true,
"וזמין": true,
"וזרות": true,
"וזרים": true,
"וחארת": true,
"וחברה": true,
"וחברו": true,
"וחברי": true,
"וחברת": true,
"וחגים": true,
"וחדרי": true,
"וחודש": true,
"וחווה": true,
"וחוות": true,
"וחוזר": true,
"וחוטי": true,
"וחומר": true,
"וחוסר": true,
"וחופש": true,
"וחוקר": true,
"וחושב": true,
"וחזקה": true,
"וחזרה": true,
"וחזרו": true,
"וחיות": true,
"וחייב": true,
"וחיים": true,
"וחכמה": true,
"וחלון": true,
"וחלים": true,
"וחלקה": true,
"וחלקו": true,
"וחלקם": true,
"וחלקן": true,
"וחמים": true,
"וחנים": true,
"וחסרי": true,
"וחסרת": true,
"וחרדה": true,
"וחרים": true,
"וחררו": true,
"וחשוב": true,
"וטאלב": true,
"וטבול": true,
"וטבעי": true,
"וטובה": true,
"וטוען": true,
"וטחים": true,
"וטטים": true,
"וטיות": true,
"וטיים": true,
"וטינה": true,
"וטלנד": true,
"וטעים": true,
"וטענה": true,
"וטענו": true,
"וטפים": true,
"וטקסט": true,
"וטרים": true,
"ויאמר": true,
"ויאסר": true,
"ויבאו": true,
"ויבוא": true,
"ויביא": true,
"ויברך": true,
"וידאה": true,
"וידאו": true,
"וידאת": true,
"וידבר": true,
"וידוא": true,
"וידוי": true,
"וידוע": true,
"וידית": true,
"וידנה": true,
"וידעו": true,
"וידתה": true,
"ויהיה": true,
"ויובל": true,
"ויודע": true,
"ויוכל": true,
"ויולה": true,
"ויולן": true,
"ויוסי": true,
"ויוסף": true,
"ויועץ": true,
"ויופי": true,
"ויוצא": true,
"ויוצר": true,
"ויורד": true,
"ויושב": true,
"ויותר": true,
"ויזות": true,
"ויזיה": true,
"ויזנר": true,
"ויזתה": true,
"ויזתו": true,
"ויזתי": true,
"ויזתך": true,
"ויזתם": true,
"ויזתן": true,
"ויחיד": true,
"ויחים": true,
"ויחסי": true,
"ויטלי": true,
"וייקת": true,
"ויישא": true,
"וייתן": true,
"ויכוח": true,
"ויכול": true,
"וילדה": true,
"וילון": true,
"וילות": true,
"וילכו": true,
"וילנה": true,
"וילתה": true,
"וילתו": true,
"וילתי": true,
"וילתך": true,
"וילתם": true,
"וילתן": true,
"וינאי": true,
"וינות": true,
"וינטג": true,
"וינטר": true,
"ויניל": true,
"וינמן": true,
"וינצי": true,
"ויסות": true,
"ויסמן": true,
"ויסעו": true,
"ויסקי": true,
"ויסתה": true,
"ויסתו": true,
"ויסתי": true,
"ויסתם": true,
"ויסתן": true,
"ויעוץ": true,
"ויעיל": true,
"ויעלו": true,
"ויעקב": true,
"ויעשה": true,
"ויעשו": true,
"ויפים": true,
"ויצאה": true,
"ויצאו": true,
"ויצוא": true,
"ויצוב": true,
"ויצחק": true,
"ויציב": true,
"ויצמן": true,
"ויצרו": true,
"ויקחו": true,
"ויקרא": true,
"ויראה": true,
"ויראו": true,
"וירדו": true,
"וירוס": true,
"וירוק": true,
"וירלי": true,
"וישבו": true,
"וישלח": true,
"וישמע": true,
"וישנה": true,
"וישנו": true,
"וישנם": true,
"וישנן": true,
"ויתור": true,
"ויתכן": true,
"ויתנו": true,
"ויתרה": true,
"ויתרו": true,
"ויתרת": true,
"וכאבי": true,
"וכאלה": true,
"וכאשר": true,
"וכבוד": true,
"וכדאי": true,
"וכדור": true,
"וכהנה": true,
"וכואב": true,
"וכולי": true,
"וכולל": true,
"וכולם": true,
"וכולן": true,
"וכושר": true,
"וכותב": true,
"וכחות": true,
"וכחלק": true,
"וכחני": true,
"וכיוב": true,
"וכיום": true,
"וכיון": true,
"וכיוצ": true,
"וכיות": true,
"וכיצד": true,
"וככזה": true,
"וככזו": true,
"וכלים": true,
"וכלפי": true,
"וכמות": true,
"וכמעט": true,
"וכנגד": true,
"וכנות": true,
"וכנים": true,
"וכנית": true,
"וכרגע": true,
"וכרים": true,
"וכתבו": true,
"וכתבי": true,
"וכתוב": true,
"וכתיב": true,
"ולאור": true,
"ולאחר": true,
"ולאתר": true,
"ולבטל": true,
"ולבית": true,
"ולבני": true,
"ולבצע": true,
"ולבקר": true,
"ולבקש": true,
"ולברר": true,
"ולגבי": true,
"ולגשת": true,
"ולדאי": true,
"ולדבר": true,
"ולדון": true,
"ולדות": true,
"ולדים": true,
"ולדכם": true,
"ולדכן": true,
"ולדנו": true,
"ולדעת": true,
"ולדשן": true,
"ולהגן": true,
"ולהחז": true,
"ולהימ": true,
"ולהיפ": true,
"ולהיר": true,
"ולהיש": true,
"ולהסת": true,
"ולהפך": true,
"ולהקל": true,
"ולהשת": true,
"ולהתא": true,
"ולהתב": true,
"ולהתח": true,
"ולהתי": true,
"ולהתע": true,
"ולהתק": true,
"ולהתר": true,
"ולוגי": true,
"ולומד": true,
"ולומר": true,
"ולוקח": true,
"ולחבר": true,
"ולחדש": true,
"ולחוד": true,
"ולחות": true,
"ולחזק": true,
"ולחלק": true,
"ולחפש": true,
"ולטאן": true,
"ולטוב": true,
"ולטור": true,
"ולטות": true,
"ולטים": true,
"ולטפל": true,
"וליאן": true,
"ולידה": true,
"וליום": true,
"וליות": true,
"וליזם": true,
"וליים": true,
"ולילה": true,
"ולינה": true,
"וללות": true,
"וללים": true,
"וללכת": true,
"וללמד": true,
"ולמות": true,
"ולמטה": true,
"ולמים": true,
"ולמלא": true,
"ולממש": true,
"ולמען": true,
"ולנדר": true,
"ולנהל": true,
"ולנצל": true,
"ולנתע": true,
"ולספק": true,
"ולספר": true,
"ולעים": true,
"ולעצב": true,
"ולפיה": true,
"ולפיו": true,
"ולפני": true,
"ולפתח": true,
"ולפתע": true,
"ולצאת": true,
"ולציה": true,
"ולקבל": true,
"ולקדם": true,
"ולקחו": true,
"ולקחת": true,
"ולרוב": true,
"ולרוח": true,
"ולרית": true,
"ולשון": true,
"ולשחק": true,
"ולשים": true,
"ולשית": true,
"ולשלב": true,
"ולשלם": true,
"ולשמר": true,
"ולשפר": true,
"ולשתף": true,
"ולתקן": true,
"ומאוב": true,
"ומאוד": true,
"ומאור": true,
"ומאות": true,
"ומאחר": true,
"ומאים": true,
"ומאיר": true,
"ומאמץ": true,
"ומבוס": true,
"ומבחר": true,
"ומביא": true,
"ומביה": true,
"ומבין": true,
"ומבלי": true,
"ומבנה": true,
"ומבני": true,
"ומבצע": true,
"ומבקש": true,
"ומברי": true,
"ומגדל": true,
"ומגיע": true,
"ומגלה": true,
"ומדבר": true,
"ומדוע": true,
"ומדות": true,
"ומדים": true,
"ומדעי": true,
"ומדרי": true,
"ומהיר": true,
"ומהנה": true,
"ומובל": true,
"ומובן": true,
"ומודה": true,
"ומודר": true,
"ומוות": true,
"ומוכן": true,
"ומוכר": true,
"ומונה": true,
"ומונע": true,
"ומוסר": true,
"ומועד": true,
"ומופת": true,
"ומוצא": true,
"ומורה": true,
"ומושך": true,
"ומושק": true,
"ומותר": true,
"ומזון": true,
"ומזרח": true,
"ומחבר": true,
"ומחול": true,
"ומחוץ": true,
"ומחים": true,
"ומחיר": true,
"ומחכה": true,
"ומחפש": true,
"ומחקר": true,
"ומטיה": true,
"ומטים": true,
"ומטית": true,
"ומטרי": true,
"ומיאל": true,
"ומידע": true,
"ומידת": true,
"ומיהם": true,
"ומיום": true,
"ומיוע": true,
"ומיות": true,
"ומייד": true,
"ומיים": true,
"ומיין": true,
"ומייס": true,
"ומייצ": true,
"ומיכל": true,
"ומיני": true,
"ומכאן": true,
"ומכבי": true,
"ומכות": true,
"ומכיל": true,
"ומכים": true,
"ומכיר": true,
"ומלאה": true,
"ומלאי": true,
"ומלאת": true,
"ומלבד": true,
"ומליה": true,
"ומלין": true,
"ומלמד": true,
"וממוק": true,
"וממוש": true,
"וממות": true,
"וממים": true,
"וממלא": true,
"וממנה": true,
"וממנו": true,
"ומנגד": true,
"ומנהג": true,
"ומנהל": true,
"ומנות": true,
"ומנחה": true,
"ומניה": true,
"ומנים": true,
"ומנית": true,
"ומנסה": true,
"ומסור": true,
"ומסחר": true,
"ומספק": true,
"ומספר": true,
"ומעבר": true,
"ומעוד": true,
"ומעים": true,
"ומעלה": true,
"ומעמד": true,
"ומענה": true,
"ומעצב": true,
"ומעקב": true,
"ומערב": true,
"ומעשה": true,
"ומעשי": true,
"ומעתה": true,
"ומפור": true,
"ומפות": true,
"ומפיק": true,
"ומפני": true,
"ומפנק": true,
"ומפקד": true,
"ומפרי": true,
"ומפתח": true,
"ומפתי": true,
"ומצאו": true,
"ומצבי": true,
"ומצוה": true,
"ומצוי": true,
"ומצות": true,
"ומציג": true,
"ומציה": true,
"ומציע": true,
"ומקבל": true,
"ומקוה": true,
"ומקום": true,
"ומקור": true,
"ומקיף": true,
"ומראה": true,
"ומראש": true,
"ומרגש": true,
"ומרון": true,
"ומרים": true,
"ומרצה": true,
"ומרתק": true,
"ומשהו": true,
"ומשוב": true,
"ומשול": true,
"ומשום": true,
"ומשחק": true,
"ומשכך": true,
"ומשלב": true,
"ומשמש": true,
"ומשנה": true,
"ומשפט": true,
"ומשקל": true,
"ומשרד": true,
"ומתוח": true,
"ומתוך": true,
"ומתחת": true,
"ונאות": true,
"ונאים": true,
"ונאית": true,
"ונאלץ": true,
"ונאמי": true,
"ונאמר": true,
"ונבחר": true,
"ונבנה": true,
"ונגיש": true,
"ונגמר": true,
"ונדון": true,
"ונדים": true,
"ונדמה": true,
"ונדרש": true,
"ונהיה": true,
"ונהנה": true,
"ונהרג": true,
"ונוחה": true,
"ונוכח": true,
"ונוכל": true,
"ונומי": true,
"ונועד": true,
"ונוער": true,
"ונופש": true,
"ונוצר": true,
"ונורא": true,
"ונושא": true,
"ונותן": true,
"ונותר": true,
"ונזיה": true,
"ונחמד": true,
"ונחשב": true,
"ונטול": true,
"ונטיל": true,
"וניהל": true,
"וניור": true,
"וניות": true,
"וניזם": true,
"ונייה": true,
"ונייט": true,
"וניים": true,
"וניסה": true,
"וניקי": true,
"וניתן": true,
"ונכון": true,
"ונכנס": true,
"ונלכה": true,
"ונמצא": true,
"ונמשך": true,
"וננות": true,
"וננים": true,
"וננתי": true,
"ונסון": true,
"ונעים": true,
"ונעלם": true,
"ונעשה": true,
"ונעשו": true,
"ונפטר": true,
"ונציג": true,
"ונציה": true,
"ונקבה": true,
"ונקבע": true,
"ונקרא": true,
"ונראה": true,
"ונרדו": true,
"ונרים": true,
"ונשאר": true,
"ונשיא": true,
"ונשים": true,
"ונשלח": true,
"ונשמח": true,
"ונשמע": true,
"ונתנה": true,
"ונתנו": true,
"ונתתי": true,
"וסביב": true,
"וסביר": true,
"וסבתא": true,
"וסוגי": true,
"וסוכר": true,
"וסופר": true,
"וסטון": true,
"וסטים": true,
"וסטרי": true,
"וסיום": true,
"וסיוע": true,
"וסיות": true,
"וסייה": true,
"וסיים": true,
"וסייע": true,
"וסיית": true,
"וסיפר": true,
"וססים": true,
"וסעיב": true,
"וספות": true,
"וספים": true,
"וספרו": true,
"וספרי": true,
"וסקוס": true,
"וסקופ": true,
"וסקות": true,
"וסקנה": true,
"וסרמן": true,
"וסתות": true,
"וסתיה": true,
"וסתיו": true,
"וסתיי": true,
"וסתיך": true,
"וסתים": true,
"וסתכם": true,
"וסתכן": true,
"וסתנה": true,
"וסתנו": true,
"ועאהד": true,
"ועבוד": true,
"ועבור": true,
"ועברה": true,
"ועברו": true,
"ועדות": true,
"ועדיה": true,
"ועדיו": true,
"ועדיי": true,
"ועדיך": true,
"ועדים": true,
"ועדין": true,
"ועדיף": true,
"ועדכם": true,
"ועדכן": true,
"ועדנו": true,
"ועדתה": true,
"ועדתו": true,
"ועדתי": true,
"ועדתך": true,
"ועדתם": true,
"ועדתן": true,
"ועובד": true,
"ועובר": true,
"ועוזר": true,
"ועולה": true,
"ועולם": true,
"ועומד": true,
"ועונה": true,
"ועוסק": true,
"ועורך": true,
"ועושה": true,
"ועידה": true,
"ועידת": true,
"ועיין": true,
"ועיקר": true,
"ועלול": true,
"ועלות": true,
"ועליה": true,
"ועליו": true,
"ועלים": true,
"ועלתה": true,
"ועמוק": true,
"ועמיד": true,
"ועמית": true,
"וענים": true,
"וערות": true,
"וערים": true,
"ועשוי": true,
"ועשיר": true,
"ועשית": true,
"ועשתה": true,
"ועתיד": true,
"ופאים": true,
"ופאית": true,
"ופארק": true,
"ופדיה": true,
"ופוגע": true,
"ופונה": true,
"ופועל": true,
"ופורט": true,
"ופחות": true,
"ופחים": true,
"ופטים": true,
"ופיגו": true,
"ופיות": true,
"ופיים": true,
"ופילי": true,
"ופינס": true,
"ופינת": true,
"ופירש": true,
"ופלות": true,
"ופלים": true,
"ופלפל": true,
"ופנאי": true,
"ופנים": true,
"ופסור": true,
"ופסיכ": true,
"ופעות": true,
"ופעיל": true,
"ופעים": true,
"ופפות": true,
"ופפים": true,
"ופציה": true,
"ופקים": true,
"ופרופ": true,
"ופרות": true,
"ופרטי": true,
"ופרים": true,
"ופרית": true,
"ופרקט": true,
"ופשוט": true,
"ופשות": true,
"ופשים": true,
"ופתוח": true,
"ופתיה": true,
"ופתית": true,
"וצאות": true,
"וצבות": true,
"וצבים": true,
"וצוות": true,
"וצורת": true,
"וציוד": true,
"וציות": true,
"וציים": true,
"וצניק": true,
"וצפוי": true,
"וצפון": true,
"וצרות": true,
"וצריך": true,
"וצרים": true,
"וצרפת": true,
"וקאין": true,
"וקבלה": true,
"וקבלו": true,
"וקבלת": true,
"וקבעה": true,
"וקבעו": true,
"וקובע": true,
"וקווי": true,
"וקונס": true,
"וקורא": true,
"וקחים": true,
"וקטוב": true,
"וקטור": true,
"וקיבל": true,
"וקיום": true,
"וקיים": true,
"וקלין": true,
"וקמבץ": true,
"וקנין": true,
"וקסטל": true,
"וקסמן": true,
"וקציה": true,
"וקקות": true,
"וקקים": true,
"וקראו": true,
"וקרוב": true,
"וקרטס": true,
"וקרים": true,
"וקרצר": true,
"וקשוב": true,
"וראבד": true,
"וראות": true,
"וראשי": true,
"ורבות": true,
"ורבים": true,
"ורגוע": true,
"ורגיה": true,
"ורגית": true,
"ורגני": true,
"ורדיה": true,
"ורדיו": true,
"ורדיי": true,
"ורדיך": true,
"ורדים": true,
"ורדין": true,
"ורדית": true,
"ורדכם": true,
"ורדכן": true,
"ורדנו": true,
"ורדרד": true,
"ורואה": true,
"ורובם": true,
"ורוגע": true,
"ורודה": true,
"ורווח": true,
"ורוצה": true,
"ורחים": true,
"ורידה": true,
"ורידו": true,
"ורידי": true,
"ורידך": true,
"ורידם": true,
"ורידן": true,
"וריון": true,
"וריות": true,
"ורייה": true,
"וריים": true,
"וריית": true,
"ורלוו": true,
"ורמים": true,
"ורניק": true,
"ורסאי": true,
"ורסות": true,
"ורסמן": true,
"ורענן": true,
"ורצוי": true,
"ורצון": true,
"וררות": true,
"וררים": true,
"ורשות": true,
"ורשים": true,
"ושאלו": true,
"ושאני": true,
"ושבוע": true,
"ושבות": true,
"ושביה": true,
"ושבעה": true,
"ושהוא": true,
"ושהיא": true,
"ושואל": true,
"ושווה": true,
"ושומר": true,
"ושונה": true,
"ושחור": true,
"ושטיה": true,
"ושטיו": true,
"ושטיי": true,
"ושטיך": true,
"ושטים": true,
"ושטכם": true,
"ושטכן": true,
"ושטנו": true,
"ושיות": true,
"ושיטת": true,
"ושימו": true,
"ושימש": true,
"ושינה": true,
"ושיער": true,
"ושירה": true,
"ושירת": true,
"ושישה": true,
"ושלום": true,
"ושלוש": true,
"ושלות": true,
"ושלחו": true,
"ושלים": true,
"ושלמה": true,
"ושמאל": true,
"ושמות": true,
"ושמחה": true,
"ושמחת": true,
"ושמים": true,
"ושמרו": true,
"ושנים": true,
"ושנית": true,
"ושעות": true,
"ושעיה": true,
"ושעים": true,
"ושרות": true,
"ושרים": true,
"וששות": true,
"ושתיה": true,
"ותאמר": true,
"ותהיה": true,
"ותואר": true,
"ותודה": true,
"ותויג": true,
"ותוכל": true,
"ותוכן": true,
"ותומך": true,
"ותורת": true,
"ותזכר": true,
"ותחום": true,
"ותיקה": true,
"ותיקי": true,
"ותיקן": true,
"ותמיד": true,
"ותמשך": true,
"ותנאי": true,
"ותעשה": true,
"ותקכם": true,
"ותקכן": true,
"ותקנו": true,
"ותראה": true,
"ותרות": true,
"ותרים": true,
"ותרמו": true,
"ותרנה": true,
"ותרני": true,
"ותשאר": true,
"ותשעה": true,
"זאבות": true,
"זאביה": true,
"זאביו": true,
"זאביי": true,
"זאביך": true,
"זאבים": true,
"זאבכם": true,
"זאבכן": true,
"זאבנו": true,
"זאבתה": true,
"זאבתו": true,
"זאבתי": true,
"זאבתך": true,
"זאבתם": true,
"זאבתן": true,
"זאטוט": true,
"זאידה": true,
"זאירי": true,
"זאעיף": true,
"זבובה": true,
"זבובו": true,
"זבובי": true,
"זבובך": true,
"זבובם": true,
"זבובן": true,
"זבוחה": true,
"זבחיה": true,
"זבחיו": true,
"זבחיי": true,
"זבחיך": true,
"זבחים": true,
"זבחכם": true,
"זבחכן": true,
"זבחנה": true,
"זבחנו": true,
"זבחתי": true,
"זבחתם": true,
"זבחתן": true,
"זבידי": true,
"זבליה": true,
"זבליו": true,
"זבליי": true,
"זבליך": true,
"זבלים": true,
"זבלכם": true,
"זבלכן": true,
"זבלנה": true,
"זבלנו": true,
"זבנות": true,
"זבניה": true,
"זבניו": true,
"זבניי": true,
"זבניך": true,
"זבנים": true,
"זבנית": true,
"זבנכם": true,
"זבנכן": true,
"זבננו": true,
"זברות": true,
"זברתה": true,
"זברתו": true,
"זברתי": true,
"זברתך": true,
"זברתם": true,
"זברתן": true,
"זגגות": true,
"זגגיה": true,
"זגגיו": true,
"זגגיי": true,
"זגגיך": true,
"זגגים": true,
"זגגית": true,
"זגגכם": true,
"זגגכן": true,
"זגגנה": true,
"זגגנו": true,
"זגוגי": true,
"זגורי": true,
"זגזגה": true,
"זגזגו": true,
"זגזגי": true,
"זגזגת": true,
"זגזוג": true,
"זגיהם": true,
"זגיהן": true,
"זגייך": true,
"זגיכם": true,
"זגיכן": true,
"זגינו": true,
"זדונה": true,
"זדונו": true,
"זדוני": true,
"זדונך": true,
"זדונם": true,
"זדונן": true,
"זדיהם": true,
"זדיהן": true,
"זדייך": true,
"זדיכם": true,
"זדיכן": true,
"זדינו": true,
"זהבהב": true,
"זהביה": true,
"זהביו": true,
"זהביי": true,
"זהביך": true,
"זהבים": true,
"זהבית": true,
"זהבכם": true,
"זהבכן": true,
"זהבנו": true,
"זהובה": true,
"זהובו": true,
"זהובי": true,
"זהובך": true,
"זהובם": true,
"זהובן": true,
"זהותה": true,
"זהותו": true,
"זהותי": true,
"זהותך": true,
"זהותם": true,
"זהותן": true,
"זהינה": true,
"זהירה": true,
"זהמנה": true,
"זהמתה": true,
"זהמתו": true,
"זהמתי": true,
"זהמתך": true,
"זהמתם": true,
"זהמתן": true,
"זהרון": true,
"זהרור": true,
"זהרות": true,
"זהריה": true,
"זהריו": true,
"זהריי": true,
"זהריך": true,
"זהרים": true,
"זהרנה": true,
"זהרנו": true,
"זהרתי": true,
"זהרתם": true,
"זהרתן": true,
"זובור": true,
"זובחת": true,
"זובכם": true,
"זובכן": true,
"זובלה": true,
"זובלו": true,
"זובלת": true,
"זובנה": true,
"זובנו": true,
"זוגגה": true,
"זוגגו": true,
"זוגגת": true,
"זוגות": true,
"זוגית": true,
"זוגכם": true,
"זוגכן": true,
"זוגנו": true,
"זוגתה": true,
"זוגתו": true,
"זוגתי": true,
"זוגתך": true,
"זוגתם": true,
"זוגתן": true,
"זוהית": true,
"זוהמה": true,
"זוהמו": true,
"זוהמת": true,
"זוהרה": true,
"זוהרו": true,
"זוהרי": true,
"זוהרך": true,
"זוהרם": true,
"זוהרן": true,
"זוהרת": true,
"זוהתה": true,
"זווגה": true,
"זווגו": true,
"זווגי": true,
"זווגת": true,
"זוודה": true,
"זוודו": true,
"זוודי": true,
"זוודת": true,
"זוויג": true,
"זווית": true,
"זוועה": true,
"זוזיה": true,
"זוזיו": true,
"זוזיי": true,
"זוזיך": true,
"זוזים": true,
"זוזכם": true,
"זוזכן": true,
"זוזנה": true,
"זוזנו": true,
"זוחלה": true,
"זוחלו": true,
"זוחלי": true,
"זוחלך": true,
"זוחלם": true,
"זוחלן": true,
"זוחלת": true,
"זוטות": true,
"זוטרה": true,
"זוינה": true,
"זוינו": true,
"זוינת": true,
"זויפה": true,
"זויפו": true,
"זויפת": true,
"זוכות": true,
"זוכים": true,
"זוכית": true,
"זוככה": true,
"זוככו": true,
"זוככת": true,
"זוכרת": true,
"זוכתה": true,
"זולגת": true,
"זולדן": true,
"זולות": true,
"זולים": true,
"זוללת": true,
"זולתה": true,
"זולתו": true,
"זולתי": true,
"זולתך": true,
"זולתם": true,
"זולתן": true,
"זומבי": true,
"זומזם": true,
"זוממת": true,
"זומנה": true,
"זומנו": true,
"זומנת": true,
"זומרה": true,
"זומרו": true,
"זומרת": true,
"זונות": true,
"זונחת": true,
"זונים": true,
"זונתה": true,
"זונתו": true,
"זונתי": true,
"זונתך": true,
"זונתם": true,
"זונתן": true,
"זוסמן": true,
"זוספן": true,
"זועבי": true,
"זועזע": true,
"זועמת": true,
"זוענה": true,
"זועפת": true,
"זועקת": true,
"זופיה": true,
"זופתה": true,
"זופתו": true,
"זופתי": true,
"זופתם": true,
"זופתן": true,
"זוקוב": true,
"זוקנה": true,
"זוקנו": true,
"זוקני": true,
"זוקנך": true,
"זוקנם": true,
"זוקנן": true,
"זוקפת": true,
"זוקקה": true,
"זוקקו": true,
"זוקקת": true,
"זוקרת": true,
"זוראב": true,
"זוראם": true,
"זורוף": true,
"זורות": true,
"זורזה": true,
"זורזו": true,
"זורזת": true,
"זורחת": true,
"זורים": true,
"זורמת": true,
"זורעת": true,
"זורקת": true,
"זחוחה": true,
"זחילה": true,
"זחליה": true,
"זחליו": true,
"זחליי": true,
"זחליך": true,
"זחלים": true,
"זחלית": true,
"זחלכם": true,
"זחלכן": true,
"זחלנה": true,
"זחלנו": true,
"זחלני": true,
"זחלתי": true,
"זחלתם": true,
"זחלתן": true,
"זיאנג": true,
"זיבול": true,
"זיבות": true,
"זיבלה": true,
"זיבלו": true,
"זיבלת": true,
"זיבתה": true,
"זיבתו": true,
"זיבתי": true,
"זיבתך": true,
"זיבתם": true,
"זיבתן": true,
"זיגגה": true,
"זיגגו": true,
"זיגגת": true,
"זיגוג": true,
"זיגזג": true,
"זיגמן": true,
"זידאן": true,
"זיהוי": true,
"זיהום": true,
"זיהית": true,
"זיהמה": true,
"זיהמו": true,
"זיהמת": true,
"זיהתה": true,
"זיווג": true,
"זיווד": true,
"זיווה": true,
"זיווי": true,
"זיווך": true,
"זיוום": true,
"זיוון": true,
"זיוית": true,
"זיונה": true,
"זיונו": true,
"זיוני": true,
"זיונך": true,
"זיונם": true,
"זיונן": true,
"זיופה": true,
"זיופו": true,
"זיופי": true,
"זיופך": true,
"זיופם": true,
"זיופן": true,
"זיותי": true,
"זיזיה": true,
"זיזיו": true,
"זיזיי": true,
"זיזיך": true,
"זיזים": true,
"זיזכם": true,
"זיזכן": true,
"זיזנו": true,
"זיידר": true,
"זיילר": true,
"זיינה": true,
"זיינו": true,
"זייני": true,
"זיינת": true,
"זייפה": true,
"זייפו": true,
"זייפי": true,
"זייפן": true,
"זייפת": true,
"זיכוי": true,
"זיכוך": true,
"זיכית": true,
"זיככה": true,
"זיככו": true,
"זיככת": true,
"זיכתה": true,
"זילבר": true,
"זילוף": true,
"זילות": true,
"זיליה": true,
"זילנד": true,
"זימון": true,
"זימיה": true,
"זימיו": true,
"זימיי": true,
"זימיך": true,
"זימים": true,
"זימכם": true,
"זימכן": true,
"זימנה": true,
"זימנו": true,
"זימנת": true,
"זימרה": true,
"זימרו": true,
"זימרת": true,
"זימתה": true,
"זימתו": true,
"זימתי": true,
"זימתך": true,
"זימתם": true,
"זימתן": true,
"זינבה": true,
"זינבו": true,
"זינבת": true,
"זינגר": true,
"זינוב": true,
"זינוק": true,
"זיניה": true,
"זיניו": true,
"זיניי": true,
"זיניך": true,
"זינים": true,
"זינכם": true,
"זינכן": true,
"זיננו": true,
"זינקה": true,
"זינקו": true,
"זינקת": true,
"זיעות": true,
"זיעזע": true,
"זיעתה": true,
"זיעתו": true,
"זיעתי": true,
"זיעתך": true,
"זיעתם": true,
"זיעתן": true,
"זיפות": true,
"זיפיה": true,
"זיפיו": true,
"זיפיי": true,
"זיפיך": true,
"זיפים": true,
"זיפית": true,
"זיפכם": true,
"זיפכן": true,
"זיפנו": true,
"זיפתה": true,
"זיפתו": true,
"זיפתי": true,
"זיפתם": true,
"זיפתן": true,
"זיקוק": true,
"זיקות": true,
"זיקיה": true,
"זיקיו": true,
"זיקיי": true,
"זיקיך": true,
"זיקים": true,
"זיקית": true,
"זיקכם": true,
"זיקכן": true,
"זיקנו": true,
"זיקקה": true,
"זיקקו": true,
"זיקקת": true,
"זיקתה": true,
"זיקתו": true,
"זיקתי": true,
"זיקתך": true,
"זיקתם": true,
"זיקתן": true,
"זירוז": true,
"זירות": true,
"זירזה": true,
"זירזו": true,
"זירזת": true,
"זירית": true,
"זירתה": true,
"זירתו": true,
"זירתי": true,
"זירתך": true,
"זירתם": true,
"זירתן": true,
"זיתון": true,
"זיתיה": true,
"זיתיו": true,
"זיתיי": true,
"זיתיך": true,
"זיתים": true,
"זיתכם": true,
"זיתכן": true,
"זיתנו": true,
"זכאות": true,
"זכאים": true,
"זכאית": true,
"זכורה": true,
"זכותה": true,
"זכותו": true,
"זכותי": true,
"זכותך": true,
"זכותם": true,
"זכותן": true,
"זכיון": true,
"זכיות": true,
"זכייה": true,
"זכיין": true,
"זכינה": true,
"זכינו": true,
"זכירה": true,
"זכיתי": true,
"זכיתם": true,
"זכיתן": true,
"זככנה": true,
"זכרון": true,
"זכרות": true,
"זכריא": true,
"זכריה": true,
"זכריו": true,
"זכריי": true,
"זכריך": true,
"זכרים": true,
"זכרית": true,
"זכרכם": true,
"זכרכן": true,
"זכרנו": true,
"זכרתי": true,
"זכרתם": true,
"זכרתן": true,
"זלגנו": true,
"זלגתי": true,
"זלגתם": true,
"זלגתן": true,
"זלדמן": true,
"זלוטי": true,
"זלזול": true,
"זלזלה": true,
"זלזלו": true,
"זלזלי": true,
"זלזלך": true,
"זלזלם": true,
"זלזלן": true,
"זלזלת": true,
"זליגה": true,
"זליכה": true,
"זלילה": true,
"זללנו": true,
"זללני": true,
"זללתי": true,
"זללתם": true,
"זללתן": true,
"זלעפה": true,
"זלצמן": true,
"זמביה": true,
"זמביש": true,
"זמומה": true,
"זמורה": true,
"זמורת": true,
"זמזום": true,
"זמזמה": true,
"זמזמו": true,
"זמזמי": true,
"זמזמך": true,
"זמזמם": true,
"זמזמן": true,
"זמזמת": true,
"זמינה": true,
"זמירה": true,
"זמירו": true,
"זמירי": true,
"זמירך": true,
"זמירם": true,
"זמירן": true,
"זממיה": true,
"זממיו": true,
"זממיי": true,
"זממיך": true,
"זממים": true,
"זממכם": true,
"זממכן": true,
"זממנו": true,
"זממתי": true,
"זממתם": true,
"זממתן": true,
"זמניה": true,
"זמניו": true,
"זמניי": true,
"זמניך": true,
"זמנים": true,
"זמנית": true,
"זמנכם": true,
"זמנכן": true,
"זמננו": true,
"זמרות": true,
"זמריה": true,
"זמריו": true,
"זמריי": true,
"זמריך": true,
"זמרים": true,
"זמריר": true,
"זמרכם": true,
"זמרכן": true,
"זמרנה": true,
"זמרנו": true,
"זמרתה": true,
"זמרתו": true,
"זמרתי": true,
"זמרתך": true,
"זמרתם": true,
"זמרתן": true,
"זנבות": true,
"זנבכם": true,
"זנבכן": true,
"זנבנב": true,
"זנבנה": true,
"זנבנו": true,
"זנוחה": true,
"זנותה": true,
"זנותו": true,
"זנותי": true,
"זנותך": true,
"זנותם": true,
"זנותן": true,
"זנחנה": true,
"זנחנו": true,
"זנחתי": true,
"זנחתם": true,
"זנחתן": true,
"זניהם": true,
"זניהן": true,
"זניות": true,
"זניחה": true,
"זנייה": true,
"זנייך": true,
"זניים": true,
"זניכם": true,
"זניכן": true,
"זנינה": true,
"זנינו": true,
"זניתי": true,
"זניתם": true,
"זניתן": true,
"זנקנה": true,
"זעומה": true,
"זעופה": true,
"זעזוע": true,
"זעזעה": true,
"זעזעו": true,
"זעזעי": true,
"זעזעת": true,
"זעירא": true,
"זעירה": true,
"זעמיה": true,
"זעמיו": true,
"זעמיי": true,
"זעמיך": true,
"זעמים": true,
"זעמכם": true,
"זעמכן": true,
"זעמנה": true,
"זעמנו": true,
"זעמתי": true,
"זעמתם": true,
"זעמתן": true,
"זענות": true,
"זעפות": true,
"זעפים": true,
"זעפכם": true,
"זעפכן": true,
"זעפנה": true,
"זעפנו": true,
"זעפני": true,
"זעפרן": true,
"זעפתי": true,
"זעפתם": true,
"זעפתן": true,
"זעקות": true,
"זעקנה": true,
"זעקנו": true,
"זעקתה": true,
"זעקתו": true,
"זעקתי": true,
"זעקתך": true,
"זעקתם": true,
"זעקתן": true,
"זערער": true,
"זפקיה": true,
"זפקיו": true,
"זפקיי": true,
"זפקיך": true,
"זפקים": true,
"זפקכם": true,
"זפקכן": true,
"זפקנו": true,
"זפקתה": true,
"זפקתו": true,
"זפקתי": true,
"זפקתך": true,
"זפקתם": true,
"זפקתן": true,
"זפתכם": true,
"זפתכן": true,
"זפתנה": true,
"זפתנו": true,
"זקופה": true,
"זקוקה": true,
"זקורה": true,
"זקיפה": true,
"זקיפו": true,
"זקיפי": true,
"זקיפך": true,
"זקיפם": true,
"זקיפן": true,
"זקיקה": true,
"זקיקו": true,
"זקיקי": true,
"זקיקך": true,
"זקיקם": true,
"זקיקן": true,
"זקירה": true,
"זקנות": true,
"זקניה": true,
"זקניו": true,
"זקניי": true,
"זקניך": true,
"זקנים": true,
"זקנכם": true,
"זקנכן": true,
"זקננו": true,
"זקנקן": true,
"זקנתה": true,
"זקנתו": true,
"זקנתי": true,
"זקנתך": true,
"זקנתם": true,
"זקנתן": true,
"זקפות": true,
"זקפנו": true,
"זקפתה": true,
"זקפתו": true,
"זקפתי": true,
"זקפתך": true,
"זקפתם": true,
"זקפתן": true,
"זקקנה": true,
"זקרנו": true,
"זקרתי": true,
"זקרתם": true,
"זקרתן": true,
"זרביב": true,
"זרגיה": true,
"זרגיו": true,
"זרגיי": true,
"זרגיך": true,
"זרגים": true,
"זרגכם": true,
"זרגכן": true,
"זרגנו": true,
"זרדיה": true,
"זרדיו": true,
"זרדיי": true,
"זרדיך": true,
"זרדים": true,
"זרדכם": true,
"זרדכן": true,
"זרדנו": true,
"זרויה": true,
"זרועה": true,
"זרועו": true,
"זרועי": true,
"זרועך": true,
"זרועם": true,
"זרוען": true,
"זרוקה": true,
"זרותה": true,
"זרותו": true,
"זרותי": true,
"זרותך": true,
"זרותם": true,
"זרותן": true,
"זרזיה": true,
"זרזיו": true,
"זרזיי": true,
"זרזיך": true,
"זרזים": true,
"זרזיף": true,
"זרזיר": true,
"זרזכם": true,
"זרזכן": true,
"זרזנה": true,
"זרזנו": true,
"זרחות": true,
"זרחים": true,
"זרחנה": true,
"זרחנו": true,
"זרחני": true,
"זרחתי": true,
"זרחתם": true,
"זרחתן": true,
"זריהם": true,
"זריהן": true,
"זריות": true,
"זריזה": true,
"זריחה": true,
"זרייה": true,
"זרייך": true,
"זריכם": true,
"זריכן": true,
"זרימה": true,
"זרימת": true,
"זרינה": true,
"זרינו": true,
"זריעה": true,
"זריקה": true,
"זריקת": true,
"זריתי": true,
"זריתם": true,
"זריתן": true,
"זרמות": true,
"זרמיה": true,
"זרמיו": true,
"זרמיי": true,
"זרמיך": true,
"זרמים": true,
"זרמכם": true,
"זרמכן": true,
"זרמנו": true,
"זרמתה": true,
"זרמתו": true,
"זרמתי": true,
"זרמתך": true,
"זרמתם": true,
"זרמתן": true,
"זרנוק": true,
"זרניך": true,
"זרעאל": true,
"זרעון": true,
"זרעיה": true,
"זרעיו": true,
"זרעיי": true,
"זרעיך": true,
"זרעים": true,
"זרעית": true,
"זרעכם": true,
"זרעכן": true,
"זרענה": true,
"זרענו": true,
"זרעתי": true,
"זרעתם": true,
"זרעתן": true,
"זרקור": true,
"זרקנו": true,
"זרקתי": true,
"זרקתם": true,
"זרקתן": true,
"זרתות": true,
"זרתכם": true,
"זרתכן": true,
"זרתנו": true,
"חאזמי": true,
"חאטיב": true,
"חאליד": true,
"חאמזה": true,
"חאנון": true,
"חאתמי": true,
"חבבנה": true,
"חבוטה": true,
"חבויה": true,
"חבולה": true,
"חבוקה": true,
"חבורה": true,
"חבורת": true,
"חבושה": true,
"חבותה": true,
"חבותו": true,
"חבותי": true,
"חבותך": true,
"חבותם": true,
"חבותן": true,
"חבטות": true,
"חבטנו": true,
"חבטתה": true,
"חבטתו": true,
"חבטתי": true,
"חבטתך": true,
"חבטתם": true,
"חבטתן": true,
"חביאו": true,
"חביבה": true,
"חביבו": true,
"חביבי": true,
"חביבך": true,
"חביבם": true,
"חביבן": true,
"חביון": true,
"חביות": true,
"חבילה": true,
"חבילת": true,
"חביצה": true,
"חביקה": true,
"חבירה": true,
"חבירו": true,
"חבישה": true,
"חביתה": true,
"חביתו": true,
"חביתי": true,
"חביתך": true,
"חביתם": true,
"חביתן": true,
"חבלות": true,
"חבליה": true,
"חבליו": true,
"חבליי": true,
"חבליך": true,
"חבלים": true,
"חבלכם": true,
"חבלכן": true,
"חבלנה": true,
"חבלנו": true,
"חבלני": true,
"חבלנך": true,
"חבלנם": true,
"חבלנן": true,
"חבלתה": true,
"חבלתו": true,
"חבלתי": true,
"חבלתך": true,
"חבלתם": true,
"חבלתן": true,
"חבצלת": true,
"חבצנו": true,
"חבצתי": true,
"חבצתם": true,
"חבצתן": true,
"חבקוק": true,
"חבקיה": true,
"חבקיו": true,
"חבקיי": true,
"חבקיך": true,
"חבקים": true,
"חבקכם": true,
"חבקכן": true,
"חבקנה": true,
"חבקנו": true,
"חבקתי": true,
"חבקתם": true,
"חבקתן": true,
"חברון": true,
"חברות": true,
"חבריא": true,
"חבריה": true,
"חבריו": true,
"חבריי": true,
"חבריך": true,
"חברים": true,
"חברית": true,
"חברכם": true,
"חברכן": true,
"חברמן": true,
"חברנה": true,
"חברנו": true,
"חברתה": true,
"חברתו": true,
"חברתי": true,
"חברתך": true,
"חברתם": true,
"חברתן": true,
"חבשים": true,
"חבשית": true,
"חבשנו": true,
"חבשתי": true,
"חבשתם": true,
"חבשתן": true,
"חגביה": true,
"חגביו": true,
"חגביי": true,
"חגביך": true,
"חגבים": true,
"חגבכם": true,
"חגבכן": true,
"חגבנו": true,
"חגגנו": true,
"חגגתי": true,
"חגגתם": true,
"חגגתן": true,
"חגונו": true,
"חגורה": true,
"חגורו": true,
"חגורי": true,
"חגורך": true,
"חגורם": true,
"חגורן": true,
"חגורת": true,
"חגותי": true,
"חגותם": true,
"חגותן": true,
"חגיגה": true,
"חגיגי": true,
"חגיגת": true,
"חגיהם": true,
"חגיהן": true,
"חגייך": true,
"חגיכם": true,
"חגיכן": true,
"חגינו": true,
"חגירה": true,
"חגלות": true,
"חגלתה": true,
"חגלתו": true,
"חגלתי": true,
"חגלתך": true,
"חגלתם": true,
"חגלתן": true,
"חגרנו": true,
"חגרתי": true,
"חגרתם": true,
"חגרתן": true,
"חדדנה": true,
"חדווה": true,
"חדוות": true,
"חדורה": true,
"חדותה": true,
"חדותו": true,
"חדותי": true,
"חדותך": true,
"חדותם": true,
"חדותן": true,
"חדילה": true,
"חדירה": true,
"חדירת": true,
"חדישה": true,
"חדלון": true,
"חדלות": true,
"חדלים": true,
"חדלנו": true,
"חדלתי": true,
"חדלתם": true,
"חדלתן": true,
"חדפיה": true,
"חדפיו": true,
"חדפיי": true,
"חדפיך": true,
"חדפים": true,
"חדפכם": true,
"חדפכן": true,
"חדפנו": true,
"חדקיה": true,
"חדקיו": true,
"חדקיי": true,
"חדקיך": true,
"חדקים": true,
"חדקכם": true,
"חדקכן": true,
"חדקנו": true,
"חדרון": true,
"חדריה": true,
"חדריו": true,
"חדריי": true,
"חדריך": true,
"חדרים": true,
"חדרכם": true,
"חדרכן": true,
"חדרנה": true,
"חדרנו": true,
"חדרני": true,
"חדרנך": true,
"חדרנם": true,
"חדרנן": true,
"חדרתי": true,
"חדרתם": true,
"חדרתן": true,
"חדשות": true,
"חדשים": true,
"חדשנה": true,
"חדשני": true,
"חדשתה": true,
"חדשתו": true,
"חדשתי": true,
"חדשתך": true,
"חדשתם": true,
"חדשתן": true,
"חובבה": true,
"חובבו": true,
"חובבי": true,
"חובבך": true,
"חובבם": true,
"חובבן": true,
"חובבת": true,
"חובות": true,
"חובטת": true,
"חובכם": true,
"חובכן": true,
"חובלה": true,
"חובלו": true,
"חובלת": true,
"חובנה": true,
"חובנו": true,
"חובצת": true,
"חובקה": true,
"חובקו": true,
"חובקת": true,
"חוברה": true,
"חוברו": true,
"חוברת": true,
"חובשת": true,
"חובתה": true,
"חובתו": true,
"חובתי": true,
"חובתך": true,
"חובתם": true,
"חובתן": true,
"חוגגת": true,
"חוגות": true,
"חוגיה": true,
"חוגיו": true,
"חוגיי": true,
"חוגיך": true,
"חוגים": true,
"חוגית": true,
"חוגכם": true,
"חוגכן": true,
"חוגנה": true,
"חוגנו": true,
"חוגרה": true,
"חוגרו": true,
"חוגרי": true,
"חוגרך": true,
"חוגרם": true,
"חוגרן": true,
"חוגרת": true,
"חוגתה": true,
"חוגתו": true,
"חוגתי": true,
"חוגתך": true,
"חוגתם": true,
"חוגתן": true,
"חודדה": true,
"חודדו": true,
"חודדת": true,
"חודיה": true,
"חודיו": true,
"חודיי": true,
"חודיך": true,
"חודים": true,
"חודכם": true,
"חודכן": true,
"חודנה": true,
"חודנו": true,
"חודרת": true,
"חודשה": true,
"חודשו": true,
"חודשי": true,
"חודשך": true,
"חודשם": true,
"חודשן": true,
"חודשת": true,
"חוואה": true,
"חוואו": true,
"חוואי": true,
"חוואך": true,
"חוואם": true,
"חוואן": true,
"חווות": true,
"חווטה": true,
"חווטו": true,
"חווטי": true,
"חווטת": true,
"חוויה": true,
"חווים": true,
"חווית": true,
"חווקה": true,
"חווקו": true,
"חווקי": true,
"חווקך": true,
"חווקם": true,
"חווקן": true,
"חוותה": true,
"חוותו": true,
"חוותי": true,
"חוותך": true,
"חוותם": true,
"חוותן": true,
"חוזהו": true,
"חוזות": true,
"חוזיה": true,
"חוזיו": true,
"חוזיי": true,
"חוזיך": true,
"חוזים": true,
"חוזית": true,
"חוזכם": true,
"חוזכן": true,
"חוזנו": true,
"חוזקה": true,
"חוזקו": true,
"חוזקי": true,
"חוזקך": true,
"חוזקם": true,
"חוזקן": true,
"חוזקת": true,
"חוזרה": true,
"חוזרו": true,
"חוזרת": true,
"חוחיה": true,
"חוחיו": true,
"חוחיי": true,
"חוחיך": true,
"חוחים": true,
"חוחית": true,
"חוחכם": true,
"חוחכן": true,
"חוחנו": true,
"חוטאה": true,
"חוטאו": true,
"חוטאת": true,
"חוטבה": true,
"חוטבו": true,
"חוטבת": true,
"חוטיה": true,
"חוטיו": true,
"חוטיי": true,
"חוטיך": true,
"חוטים": true,
"חוטית": true,
"חוטכם": true,
"חוטכן": true,
"חוטמה": true,
"חוטמו": true,
"חוטמי": true,
"חוטמך": true,
"חוטמם": true,
"חוטמן": true,
"חוטנו": true,
"חוטפה": true,
"חוטפו": true,
"חוטפי": true,
"חוטפך": true,
"חוטפם": true,
"חוטפן": true,
"חוטפת": true,
"חוטרה": true,
"חוטרו": true,
"חוטרי": true,
"חוטרך": true,
"חוטרם": true,
"חוטרן": true,
"חויבה": true,
"חויבו": true,
"חויבת": true,
"חויגה": true,
"חויגו": true,
"חויגת": true,
"חויות": true,
"חויטה": true,
"חויטו": true,
"חויטת": true,
"חוילה": true,
"חוילו": true,
"חוילת": true,
"חוככת": true,
"חוכמה": true,
"חוכמת": true,
"חוכרת": true,
"חולבת": true,
"חולדה": true,
"חולדו": true,
"חולדי": true,
"חולדך": true,
"חולדם": true,
"חולדן": true,
"חולהו": true,
"חולון": true,
"חולות": true,
"חולחל": true,
"חולטה": true,
"חולטו": true,
"חולטת": true,
"חוליה": true,
"חוליו": true,
"חוליי": true,
"חוליך": true,
"חולים": true,
"חולין": true,
"חולית": true,
"חולכם": true,
"חולכן": true,
"חוללה": true,
"חוללו": true,
"חוללי": true,
"חוללת": true,
"חולמת": true,
"חולנה": true,
"חולנו": true,
"חולני": true,
"חולפת": true,
"חולצה": true,
"חולצו": true,
"חולצת": true,
"חולקה": true,
"חולקו": true,
"חולקת": true,
"חולשה": true,
"חולשת": true,
"חולתה": true,
"חולתו": true,
"חולתי": true,
"חולתך": true,
"חולתם": true,
"חולתן": true,
"חומדת": true,
"חומוס": true,
"חומות": true,
"חומטה": true,
"חומטו": true,
"חומטי": true,
"חומטך": true,
"חומטם": true,
"חומטן": true,
"חומים": true,
"חומכם": true,
"חומכן": true,
"חומלת": true,
"חוממה": true,
"חוממו": true,
"חוממת": true,
"חומנו": true,
"חומסת": true,
"חומצה": true,
"חומצו": true,
"חומצי": true,
"חומצך": true,
"חומצם": true,
"חומצן": true,
"חומצת": true,
"חומקת": true,
"חומרה": true,
"חומרו": true,
"חומרי": true,
"חומרך": true,
"חומרם": true,
"חומרן": true,
"חומרת": true,
"חומשה": true,
"חומשו": true,
"חומשי": true,
"חומשך": true,
"חומשם": true,
"חומשן": true,
"חומשת": true,
"חומתה": true,
"חומתו": true,
"חומתי": true,
"חומתך": true,
"חומתם": true,
"חומתן": true,
"חונות": true,
"חונטה": true,
"חונטת": true,
"חונים": true,
"חונית": true,
"חונכה": true,
"חונכו": true,
"חונכת": true,
"חוננה": true,
"חוננו": true,
"חונני": true,
"חוננת": true,
"חונקת": true,
"חוסאי": true,
"חוסאן": true,
"חוסות": true,
"חוסים": true,
"חוסכת": true,
"חוסלה": true,
"חוסלו": true,
"חוסלת": true,
"חוסמה": true,
"חוסמו": true,
"חוסמת": true,
"חוסנה": true,
"חוסנו": true,
"חוסני": true,
"חוסנך": true,
"חוסנם": true,
"חוסנן": true,
"חוסנת": true,
"חוספס": true,
"חוסרה": true,
"חוסרו": true,
"חוסרי": true,
"חוסרך": true,
"חוסרם": true,
"חוסרן": true,
"חוסרת": true,
"חופות": true,
"חופיה": true,
"חופיו": true,
"חופיי": true,
"חופיך": true,
"חופים": true,
"חופית": true,
"חופכם": true,
"חופכן": true,
"חופנה": true,
"חופנו": true,
"חופני": true,
"חופנך": true,
"חופנם": true,
"חופנן": true,
"חופנת": true,
"חופפה": true,
"חופפו": true,
"חופפת": true,
"חופרת": true,
"חופשה": true,
"חופשו": true,
"חופשי": true,
"חופשך": true,
"חופשם": true,
"חופשן": true,
"חופשת": true,
"חופתה": true,
"חופתו": true,
"חופתי": true,
"חופתך": true,
"חופתם": true,
"חופתן": true,
"חוצבת": true,
"חוצות": true,
"חוצים": true,
"חוצכם": true,
"חוצכן": true,
"חוצנה": true,
"חוצנו": true,
"חוצני": true,
"חוצנך": true,
"חוצנם": true,
"חוצנן": true,
"חוצפה": true,
"חוצפן": true,
"חוצצת": true,
"חוקות": true,
"חוקיה": true,
"חוקיו": true,
"חוקיי": true,
"חוקיך": true,
"חוקים": true,
"חוקית": true,
"חוקכם": true,
"חוקכן": true,
"חוקנה": true,
"חוקנו": true,
"חוקני": true,
"חוקנך": true,
"חוקנם": true,
"חוקנן": true,
"חוקקה": true,
"חוקקו": true,
"חוקקי": true,
"חוקקת": true,
"חוקרה": true,
"חוקרו": true,
"חוקרי": true,
"חוקרך": true,
"חוקרם": true,
"חוקרן": true,
"חוקרת": true,
"חוקתה": true,
"חוקתו": true,
"חוקתי": true,
"חוקתך": true,
"חוקתם": true,
"חוקתן": true,
"חורבה": true,
"חורבן": true,
"חורבת": true,
"חורגת": true,
"חורון": true,
"חורות": true,
"חורזת": true,
"חורטת": true,
"חוריה": true,
"חוריו": true,
"חוריי": true,
"חוריך": true,
"חורים": true,
"חורין": true,
"חורכם": true,
"חורכן": true,
"חורכת": true,
"חורמה": true,
"חורנו": true,
"חורפה": true,
"חורפו": true,
"חורפי": true,
"חורפך": true,
"חורפם": true,
"חורפן": true,
"חורפת": true,
"חורצת": true,
"חורקת": true,
"חוררה": true,
"חוררו": true,
"חוררי": true,
"חוררת": true,
"חורשה": true,
"חורשו": true,
"חורשי": true,
"חורשך": true,
"חורשם": true,
"חורשן": true,
"חורשף": true,
"חורשת": true,
"חורתת": true,
"חושבה": true,
"חושבו": true,
"חושבן": true,
"חושבת": true,
"חושדת": true,
"חושות": true,
"חושחש": true,
"חושיה": true,
"חושיו": true,
"חושיי": true,
"חושיך": true,
"חושים": true,
"חושית": true,
"חושכה": true,
"חושכו": true,
"חושכי": true,
"חושכך": true,
"חושכם": true,
"חושכן": true,
"חושלה": true,
"חושלו": true,
"חושלת": true,
"חושמל": true,
"חושנה": true,
"חושנו": true,
"חושני": true,
"חושפת": true,
"חושקה": true,
"חושקו": true,
"חושקת": true,
"חוששת": true,
"חותכת": true,
"חותלה": true,
"חותלו": true,
"חותלת": true,
"חותמה": true,
"חותמו": true,
"חותמי": true,
"חותמך": true,
"חותמם": true,
"חותמן": true,
"חותמת": true,
"חותנה": true,
"חותנו": true,
"חותני": true,
"חותנך": true,
"חותנם": true,
"חותנן": true,
"חותנת": true,
"חותרת": true,
"חזאות": true,
"חזאיה": true,
"חזאיו": true,
"חזאיי": true,
"חזאיך": true,
"חזאים": true,
"חזאית": true,
"חזאכם": true,
"חזאכן": true,
"חזאנו": true,
"חזויה": true,
"חזונה": true,
"חזונו": true,
"חזוני": true,
"חזונך": true,
"חזונם": true,
"חזונן": true,
"חזותה": true,
"חזותו": true,
"חזותי": true,
"חזותך": true,
"חזותם": true,
"חזותן": true,
"חזזית": true,
"חזיון": true,
"חזיות": true,
"חזיזה": true,
"חזיזו": true,
"חזיזי": true,
"חזיזך": true,
"חזיזם": true,
"חזיזן": true,
"חזייה": true,
"חזינה": true,
"חזינו": true,
"חזירה": true,
"חזירו": true,
"חזירי": true,
"חזירך": true,
"חזירם": true,
"חזירן": true,
"חזיתה": true,
"חזיתו": true,
"חזיתי": true,
"חזיתך": true,
"חזיתם": true,
"חזיתן": true,
"חזנות": true,
"חזניה": true,
"חזניו": true,
"חזניי": true,
"חזניך": true,
"חזנים": true,
"חזנית": true,
"חזנכם": true,
"חזנכן": true,
"חזננו": true,
"חזקות": true,
"חזקיה": true,
"חזקים": true,
"חזקנה": true,
"חזקתה": true,
"חזקתו": true,
"חזקתי": true,
"חזקתך": true,
"חזקתם": true,
"חזקתן": true,
"חזרות": true,
"חזרים": true,
"חזרנה": true,
"חזרנו": true,
"חזרני": true,
"חזרנך": true,
"חזרנם": true,
"חזרנן": true,
"חזרתה": true,
"חזרתו": true,
"חזרתי": true,
"חזרתך": true,
"חזרתם": true,
"חזרתן": true,
"חטאות": true,
"חטאיה": true,
"חטאיו": true,
"חטאיי": true,
"חטאיך": true,
"חטאים": true,
"חטאכם": true,
"חטאכן": true,
"חטאנה": true,
"חטאנו": true,
"חטאתה": true,
"חטאתו": true,
"חטאתי": true,
"חטאתך": true,
"חטאתם": true,
"חטאתן": true,
"חטבנה": true,
"חטבנו": true,
"חטבתי": true,
"חטבתם": true,
"חטבתן": true,
"חטואל": true,
"חטובה": true,
"חטופה": true,
"חטטיה": true,
"חטטיו": true,
"חטטיי": true,
"חטטיך": true,
"חטטים": true,
"חטטכם": true,
"חטטכן": true,
"חטטנה": true,
"חטטנו": true,
"חטטני": true,
"חטטתה": true,
"חטטתו": true,
"חטטתי": true,
"חטטתך": true,
"חטטתם": true,
"חטטתן": true,
"חטיבה": true,
"חטיבת": true,
"חטיהם": true,
"חטיהן": true,
"חטייך": true,
"חטיכם": true,
"חטיכן": true,
"חטינו": true,
"חטיפה": true,
"חטיפו": true,
"חטיפי": true,
"חטיפך": true,
"חטיפם": true,
"חטיפן": true,
"חטיפת": true,
"חטפיה": true,
"חטפיו": true,
"חטפיי": true,
"חטפיך": true,
"חטפים": true,
"חטפכם": true,
"חטפכן": true,
"חטפנו": true,
"חטפתי": true,
"חטפתם": true,
"חטפתן": true,
"חיארי": true,
"חיבבה": true,
"חיבבו": true,
"חיבבת": true,
"חיבוב": true,
"חיבוט": true,
"חיבוק": true,
"חיבור": true,
"חיבות": true,
"חיבלה": true,
"חיבלו": true,
"חיבלת": true,
"חיבקה": true,
"חיבקו": true,
"חיבקת": true,
"חיברה": true,
"חיברו": true,
"חיברת": true,
"חיבתה": true,
"חיבתו": true,
"חיבתי": true,
"חיבתך": true,
"חיבתם": true,
"חיבתן": true,
"חיגרת": true,
"חידדה": true,
"חידדו": true,
"חידדת": true,
"חידוד": true,
"חידון": true,
"חידוש": true,
"חידות": true,
"חידקל": true,
"חידשה": true,
"חידשו": true,
"חידשת": true,
"חידתה": true,
"חידתו": true,
"חידתי": true,
"חידתך": true,
"חידתם": true,
"חידתן": true,
"חיובה": true,
"חיובו": true,
"חיובי": true,
"חיובך": true,
"חיובם": true,
"חיובן": true,
"חיוגה": true,
"חיוגו": true,
"חיוגי": true,
"חיוגך": true,
"חיוגם": true,
"חיוגן": true,
"חיווה": true,
"חיווט": true,
"חיווי": true,
"חיוור": true,
"חיוטה": true,
"חיוטו": true,
"חיוטי": true,
"חיוטך": true,
"חיוטם": true,
"חיוטן": true,
"חיוכה": true,
"חיוכו": true,
"חיוכי": true,
"חיוכך": true,
"חיוכם": true,
"חיוכן": true,
"חיולה": true,
"חיולו": true,
"חיולי": true,
"חיולך": true,
"חיולם": true,
"חיולן": true,
"חיוני": true,
"חיותה": true,
"חיותו": true,
"חיותי": true,
"חיותך": true,
"חיותם": true,
"חיותן": true,
"חיזוי": true,
"חיזוק": true,
"חיזור": true,
"חיזמא": true,
"חיזמה": true,
"חיזקה": true,
"חיזקו": true,
"חיזקת": true,
"חיזרה": true,
"חיזרו": true,
"חיזרת": true,
"חיטאה": true,
"חיטאו": true,
"חיטאת": true,
"חיטבה": true,
"חיטבו": true,
"חיטבת": true,
"חיטוב": true,
"חיטוט": true,
"חיטוי": true,
"חיטטה": true,
"חיטטו": true,
"חיטטת": true,
"חיטיה": true,
"חיטיו": true,
"חיטיי": true,
"חיטיך": true,
"חיטים": true,
"חיטתה": true,
"חיטתו": true,
"חיטתי": true,
"חיטתך": true,
"חיטתם": true,
"חיטתן": true,
"חייאט": true,
"חייאת": true,
"חייבה": true,
"חייבו": true,
"חייבי": true,
"חייבך": true,
"חייבם": true,
"חייבן": true,
"חייבר": true,
"חייבת": true,
"חייגה": true,
"חייגו": true,
"חייגי": true,
"חייגן": true,
"חייגת": true,
"חיידק": true,
"חייהם": true,
"חייהן": true,
"חייזר": true,
"חייטה": true,
"חייטו": true,
"חייטי": true,
"חייטך": true,
"חייטם": true,
"חייטן": true,
"חייטת": true,
"חייית": true,
"חייכה": true,
"חייכו": true,
"חייכי": true,
"חייכם": true,
"חייכן": true,
"חייכת": true,
"חיילה": true,
"חיילו": true,
"חיילי": true,
"חיילך": true,
"חיילם": true,
"חיילן": true,
"חיילת": true,
"חיינה": true,
"חיינו": true,
"חיישן": true,
"חייתה": true,
"חייתו": true,
"חייתי": true,
"חייתך": true,
"חייתם": true,
"חייתן": true,
"חיכוך": true,
"חיכית": true,
"חיככה": true,
"חיככו": true,
"חיככת": true,
"חיכתה": true,
"חילוט": true,
"חילול": true,
"חילון": true,
"חילוף": true,
"חילוץ": true,
"חילוק": true,
"חילות": true,
"חילטה": true,
"חילטו": true,
"חילטת": true,
"חילים": true,
"חילית": true,
"חילכם": true,
"חילכן": true,
"חיללה": true,
"חיללו": true,
"חיללת": true,
"חילנו": true,
"חילצה": true,
"חילצו": true,
"חילצת": true,
"חילקה": true,
"חילקו": true,
"חילקת": true,
"חילתה": true,
"חילתו": true,
"חילתי": true,
"חילתך": true,
"חילתם": true,
"חילתן": true,
"חימום": true,
"חימוש": true,
"חיממה": true,
"חיממו": true,
"חיממת": true,
"חימשה": true,
"חימשו": true,
"חימשת": true,
"חינוך": true,
"חינון": true,
"חינות": true,
"חינים": true,
"חינכה": true,
"חינכו": true,
"חינכת": true,
"חינמי": true,
"חינני": true,
"חיסול": true,
"חיסום": true,
"חיסון": true,
"חיסור": true,
"חיסלה": true,
"חיסלו": true,
"חיסלת": true,
"חיסמה": true,
"חיסמו": true,
"חיסמת": true,
"חיסנה": true,
"חיסנו": true,
"חיסנת": true,
"חיסרה": true,
"חיסרו": true,
"חיסרת": true,
"חיפאי": true,
"חיפוי": true,
"חיפוף": true,
"חיפוש": true,
"חיפית": true,
"חיפפה": true,
"חיפפו": true,
"חיפפת": true,
"חיפשה": true,
"חיפשו": true,
"חיפשת": true,
"חיפתה": true,
"חיצונ": true,
"חיציה": true,
"חיציו": true,
"חיציי": true,
"חיציך": true,
"חיצים": true,
"חיצכם": true,
"חיצכן": true,
"חיצנו": true,
"חיקוי": true,
"חיקוק": true,
"חיקור": true,
"חיקיה": true,
"חיקיו": true,
"חיקיי": true,
"חיקיך": true,
"חיקים": true,
"חיקית": true,
"חיקכם": true,
"חיקכן": true,
"חיקנו": true,
"חיקתה": true,
"חירבת": true,
"חירום": true,
"חירוף": true,
"חירוק": true,
"חירור": true,
"חירות": true,
"חיריק": true,
"חירפה": true,
"חירפו": true,
"חירפת": true,
"חירשת": true,
"חישבה": true,
"חישבו": true,
"חישבת": true,
"חישוב": true,
"חישול": true,
"חישוף": true,
"חישוק": true,
"חישור": true,
"חישות": true,
"חישלה": true,
"חישלו": true,
"חישלת": true,
"חישקה": true,
"חישקו": true,
"חישקת": true,
"חישתה": true,
"חישתו": true,
"חישתי": true,
"חישתך": true,
"חישתם": true,
"חישתן": true,
"חיתוך": true,
"חיתול": true,
"חיתום": true,
"חיתון": true,
"חיתות": true,
"חיתלה": true,
"חיתלו": true,
"חיתלת": true,
"חיתנה": true,
"חיתנו": true,
"חיתנת": true,
"חיתתה": true,
"חיתתו": true,
"חיתתי": true,
"חיתתך": true,
"חיתתם": true,
"חיתתן": true,
"חכורה": true,
"חכיהם": true,
"חכיהן": true,
"חכייך": true,
"חכיכה": true,
"חכיכם": true,
"חכיכן": true,
"חכינה": true,
"חכינו": true,
"חכירה": true,
"חככנה": true,
"חככנו": true,
"חככתי": true,
"חככתם": true,
"חככתן": true,
"חכמות": true,
"חכמיה": true,
"חכמיו": true,
"חכמיי": true,
"חכמיך": true,
"חכמים": true,
"חכמכם": true,
"חכמכן": true,
"חכמנו": true,
"חכמתה": true,
"חכמתו": true,
"חכמתי": true,
"חכמתך": true,
"חכמתם": true,
"חכמתן": true,
"חכרנו": true,
"חכרתי": true,
"חכרתם": true,
"חכרתן": true,
"חכתכם": true,
"חכתכן": true,
"חכתנו": true,
"חלאות": true,
"חלאתה": true,
"חלאתו": true,
"חלאתי": true,
"חלאתך": true,
"חלאתם": true,
"חלאתן": true,
"חלבון": true,
"חלבית": true,
"חלבכם": true,
"חלבכן": true,
"חלבנה": true,
"חלבנו": true,
"חלבני": true,
"חלבנך": true,
"חלבנם": true,
"חלבנן": true,
"חלבתי": true,
"חלבתם": true,
"חלבתן": true,
"חלדיה": true,
"חלדיו": true,
"חלדיי": true,
"חלדיך": true,
"חלדים": true,
"חלדכם": true,
"חלדכן": true,
"חלדנו": true,
"חלודה": true,
"חלודו": true,
"חלודי": true,
"חלודך": true,
"חלודם": true,
"חלודן": true,
"חלווה": true,
"חלוטה": true,
"חלולה": true,
"חלומה": true,
"חלומו": true,
"חלומי": true,
"חלומך": true,
"חלומם": true,
"חלומן": true,
"חלונה": true,
"חלונו": true,
"חלוני": true,
"חלונך": true,
"חלונם": true,
"חלונן": true,
"חלופה": true,
"חלופי": true,
"חלוצה": true,
"חלוצו": true,
"חלוצי": true,
"חלוצך": true,
"חלוצם": true,
"חלוצן": true,
"חלוקה": true,
"חלוקו": true,
"חלוקי": true,
"חלוקך": true,
"חלוקם": true,
"חלוקן": true,
"חלוקת": true,
"חלושה": true,
"חלזון": true,
"חלחול": true,
"חלחלה": true,
"חלחלו": true,
"חלחלי": true,
"חלחלת": true,
"חלטות": true,
"חלטנה": true,
"חלטנו": true,
"חלטתי": true,
"חלטתם": true,
"חלטתן": true,
"חליבה": true,
"חליטה": true,
"חלילה": true,
"חלילו": true,
"חלילי": true,
"חלילך": true,
"חלילם": true,
"חלילן": true,
"חלימה": true,
"חלינה": true,
"חלינו": true,
"חליפה": true,
"חליפי": true,
"חליפת": true,
"חליצה": true,
"חלישה": true,
"חליתי": true,
"חליתם": true,
"חליתן": true,
"חלליה": true,
"חלליו": true,
"חלליי": true,
"חלליך": true,
"חללים": true,
"חללית": true,
"חללכם": true,
"חללכן": true,
"חללנה": true,
"חללנו": true,
"חלמאי": true,
"חלמון": true,
"חלמיש": true,
"חלמית": true,
"חלמנו": true,
"חלמתי": true,
"חלמתם": true,
"חלמתן": true,
"חלפון": true,
"חלפיה": true,
"חלפיו": true,
"חלפיי": true,
"חלפיך": true,
"חלפים": true,
"חלפכם": true,
"חלפכן": true,
"חלפנה": true,
"חלפנו": true,
"חלפני": true,
"חלפנך": true,
"חלפנם": true,
"חלפנן": true,
"חלפתי": true,
"חלפתם": true,
"חלפתן": true,
"חלציה": true,
"חלציו": true,
"חלציי": true,
"חלציך": true,
"חלצנה": true,
"חלצנו": true,
"חלצתי": true,
"חלצתם": true,
"חלצתן": true,
"חלקות": true,
"חלקיה": true,
"חלקיו": true,
"חלקיי": true,
"חלקיך": true,
"חלקים": true,
"חלקיק": true,
"חלקית": true,
"חלקכם": true,
"חלקכן": true,
"חלקלק": true,
"חלקנה": true,
"חלקנו": true,
"חלקתה": true,
"חלקתו": true,
"חלקתי": true,
"חלקתך": true,
"חלקתם": true,
"חלקתן": true,
"חלשות": true,
"חלשים": true,
"חלשנו": true,
"חלשתי": true,
"חלשתם": true,
"חלשתן": true,
"חלתכם": true,
"חלתכן": true,
"חלתנו": true,
"חמאות": true,
"חמאתה": true,
"חמאתו": true,
"חמאתי": true,
"חמאתך": true,
"חמאתם": true,
"חמאתן": true,
"חמדאן": true,
"חמדות": true,
"חמדנו": true,
"חמדני": true,
"חמדתה": true,
"חמדתו": true,
"חמדתי": true,
"חמדתך": true,
"חמדתם": true,
"חמדתן": true,
"חמודה": true,
"חמודי": true,
"חמוטל": true,
"חמולה": true,
"חמומה": true,
"חמוסה": true,
"חמוסו": true,
"חמוסי": true,
"חמוסך": true,
"חמוסם": true,
"חמוסן": true,
"חמוצה": true,
"חמורה": true,
"חמורו": true,
"חמורי": true,
"חמורך": true,
"חמורם": true,
"חמורן": true,
"חמושה": true,
"חמותה": true,
"חמותו": true,
"חמותי": true,
"חמותך": true,
"חמותם": true,
"חמותן": true,
"חמידה": true,
"חמיהם": true,
"חמיהן": true,
"חמיות": true,
"חמיטה": true,
"חמייך": true,
"חמיכם": true,
"חמיכן": true,
"חמימה": true,
"חמינה": true,
"חמינו": true,
"חמיני": true,
"חמינך": true,
"חמינם": true,
"חמינן": true,
"חמיסה": true,
"חמיצה": true,
"חמיקה": true,
"חמישה": true,
"חמישי": true,
"חמלנו": true,
"חמלתה": true,
"חמלתו": true,
"חמלתי": true,
"חמלתך": true,
"חמלתם": true,
"חמלתן": true,
"חממות": true,
"חממנה": true,
"חממתה": true,
"חממתו": true,
"חממתי": true,
"חממתך": true,
"חממתם": true,
"חממתן": true,
"חמניה": true,
"חמנית": true,
"חמסין": true,
"חמסנו": true,
"חמסני": true,
"חמסתי": true,
"חמסתם": true,
"חמסתן": true,
"חמצון": true,
"חמציץ": true,
"חמצמץ": true,
"חמצנה": true,
"חמצנו": true,
"חמצני": true,
"חמצנת": true,
"חמקמק": true,
"חמקנו": true,
"חמקני": true,
"חמקתי": true,
"חמקתם": true,
"חמקתן": true,
"חמרות": true,
"חמריה": true,
"חמריו": true,
"חמריי": true,
"חמריך": true,
"חמרים": true,
"חמרכם": true,
"חמרכן": true,
"חמרנו": true,
"חמרתה": true,
"חמרתו": true,
"חמרתי": true,
"חמרתך": true,
"חמרתם": true,
"חמרתן": true,
"חמשות": true,
"חמשים": true,
"חמשיר": true,
"חמשנה": true,
"חמשתם": true,
"חמשתן": true,
"חמתות": true,
"חמתכם": true,
"חמתכן": true,
"חמתנו": true,
"חנוטה": true,
"חנוטו": true,
"חנוטי": true,
"חנוטך": true,
"חנוטם": true,
"חנוטן": true,
"חנוכה": true,
"חנוכת": true,
"חנונה": true,
"חנונו": true,
"חנופה": true,
"חנוקה": true,
"חנותה": true,
"חנותו": true,
"חנותי": true,
"חנותך": true,
"חנותם": true,
"חנותן": true,
"חנטנו": true,
"חנטרש": true,
"חנטתי": true,
"חנטתם": true,
"חנטתן": true,
"חניהם": true,
"חניהן": true,
"חניון": true,
"חניות": true,
"חניטה": true,
"חנייה": true,
"חנייך": true,
"חניכה": true,
"חניכו": true,
"חניכי": true,
"חניכך": true,
"חניכם": true,
"חניכן": true,
"חנינא": true,
"חנינה": true,
"חנינו": true,
"חניקה": true,
"חניתה": true,
"חניתו": true,
"חניתי": true,
"חניתך": true,
"חניתם": true,
"חניתן": true,
"חנכנה": true,
"חנכנו": true,
"חנכתי": true,
"חנכתם": true,
"חנכתן": true,
"חנמון": true,
"חננאל": true,
"חנניה": true,
"חננתי": true,
"חננתם": true,
"חננתן": true,
"חנפני": true,
"חנקות": true,
"חנקין": true,
"חנקנו": true,
"חנקני": true,
"חנקתי": true,
"חנקתם": true,
"חנקתן": true,
"חסדאי": true,
"חסדיה": true,
"חסדיו": true,
"חסדיי": true,
"חסדיך": true,
"חסדים": true,
"חסדכם": true,
"חסדכן": true,
"חסדנו": true,
"חסודה": true,
"חסויה": true,
"חסומה": true,
"חסונה": true,
"חסותה": true,
"חסותו": true,
"חסותי": true,
"חסותך": true,
"חסותם": true,
"חסותן": true,
"חסידה": true,
"חסידו": true,
"חסידי": true,
"חסידך": true,
"חסידם": true,
"חסידן": true,
"חסיון": true,
"חסילה": true,
"חסילו": true,
"חסילי": true,
"חסילך": true,
"חסילם": true,
"חסילן": true,
"חסימה": true,
"חסימת": true,
"חסינה": true,
"חסינו": true,
"חסיני": true,
"חסיתי": true,
"חסיתם": true,
"חסיתן": true,
"חסכון": true,
"חסכיה": true,
"חסכיו": true,
"חסכיי": true,
"חסכיך": true,
"חסכים": true,
"חסככם": true,
"חסככן": true,
"חסכנו": true,
"חסכני": true,
"חסכתי": true,
"חסכתם": true,
"חסכתן": true,
"חסלנה": true,
"חסמיה": true,
"חסמיו": true,
"חסמיי": true,
"חסמיך": true,
"חסמים": true,
"חסמכם": true,
"חסמכן": true,
"חסמנה": true,
"חסמנו": true,
"חסמתי": true,
"חסמתם": true,
"חסמתן": true,
"חספוס": true,
"חספין": true,
"חספסה": true,
"חספסו": true,
"חספסי": true,
"חספסת": true,
"חסקות": true,
"חסקתה": true,
"חסקתו": true,
"חסקתי": true,
"חסקתך": true,
"חסקתם": true,
"חסקתן": true,
"חסרון": true,
"חסרות": true,
"חסרים": true,
"חסרנה": true,
"חסרנו": true,
"חסרתי": true,
"חסרתם": true,
"חסרתן": true,
"חפוזה": true,
"חפויה": true,
"חפונה": true,
"חפופה": true,
"חפורה": true,
"חפותה": true,
"חפותו": true,
"חפותי": true,
"חפותך": true,
"חפותם": true,
"חפותן": true,
"חפזון": true,
"חפינה": true,
"חפיסה": true,
"חפיפה": true,
"חפירה": true,
"חפירו": true,
"חפירי": true,
"חפירך": true,
"חפירם": true,
"חפירן": true,
"חפלות": true,
"חפנתי": true,
"חפנתם": true,
"חפנתן": true,
"חפפנה": true,
"חפפנו": true,
"חפפתי": true,
"חפפתם": true,
"חפפתן": true,
"חפצדי": true,
"חפצות": true,
"חפציה": true,
"חפציו": true,
"חפציי": true,
"חפציך": true,
"חפצים": true,
"חפצכם": true,
"חפצכן": true,
"חפצנו": true,
"חפצתי": true,
"חפצתם": true,
"חפצתן": true,
"חפרנו": true,
"חפרתי": true,
"חפרתם": true,
"חפרתן": true,
"חפשנה": true,
"חפתיה": true,
"חפתיו": true,
"חפתיי": true,
"חפתיך": true,
"חפתים": true,
"חפתכם": true,
"חפתכן": true,
"חפתנו": true,
"חצאיה": true,
"חצאיו": true,
"חצאיי": true,
"חצאיך": true,
"חצאים": true,
"חצאית": true,
"חצביה": true,
"חצביו": true,
"חצביי": true,
"חצביך": true,
"חצבים": true,
"חצבכם": true,
"חצבכן": true,
"חצבנו": true,
"חצבני": true,
"חצבתה": true,
"חצבתו": true,
"חצבתי": true,
"חצבתך": true,
"חצבתם": true,
"חצבתן": true,
"חצובה": true,
"חצויה": true,
"חצופה": true,
"חצותה": true,
"חצותו": true,
"חצותי": true,
"חצותך": true,
"חצותם": true,
"חצותן": true,
"חציבה": true,
"חציבת": true,
"חציהם": true,
"חציהן": true,
"חציון": true,
"חציות": true,
"חצייה": true,
"חצייך": true,
"חציים": true,
"חציין": true,
"חציית": true,
"חציכם": true,
"חציכן": true,
"חצילה": true,
"חצילו": true,
"חצילי": true,
"חצילך": true,
"חצילם": true,
"חצילן": true,
"חצינה": true,
"חצינו": true,
"חציצה": true,
"חצירה": true,
"חצירו": true,
"חצירי": true,
"חצירך": true,
"חצירם": true,
"חצירן": true,
"חציתי": true,
"חציתם": true,
"חציתן": true,
"חצניה": true,
"חצניו": true,
"חצניי": true,
"חצניך": true,
"חצנים": true,
"חצצור": true,
"חצצכם": true,
"חצצכן": true,
"חצצנו": true,
"חצצרה": true,
"חצצרו": true,
"חצצרי": true,
"חצצרת": true,
"חצצתי": true,
"חצצתם": true,
"חצצתן": true,
"חצרות": true,
"חצרים": true,
"חצרכם": true,
"חצרכן": true,
"חצרנה": true,
"חצרנו": true,
"חצרני": true,
"חצרנך": true,
"חצרנם": true,
"חצרנן": true,
"חקוקה": true,
"חקיין": true,
"חקינה": true,
"חקיקה": true,
"חקיקת": true,
"חקירה": true,
"חקירת": true,
"חקלאה": true,
"חקלאו": true,
"חקלאי": true,
"חקלאך": true,
"חקלאם": true,
"חקלאן": true,
"חקנים": true,
"חקנית": true,
"חקקנו": true,
"חקקתי": true,
"חקקתם": true,
"חקקתן": true,
"חקריה": true,
"חקריו": true,
"חקריי": true,
"חקריך": true,
"חקרים": true,
"חקרכם": true,
"חקרכן": true,
"חקרנו": true,
"חקרני": true,
"חקרתי": true,
"חקרתם": true,
"חקרתן": true,
"חראזי": true,
"חרבון": true,
"חרבות": true,
"חרבים": true,
"חרבכם": true,
"חרבכן": true,
"חרבנה": true,
"חרבנו": true,
"חרבני": true,
"חרבנת": true,
"חרבתי": true,
"חרבתם": true,
"חרבתן": true,
"חרגול": true,
"חרגנו": true,
"חרגתי": true,
"חרגתם": true,
"חרגתן": true,
"חרדון": true,
"חרדות": true,
"חרדים": true,
"חרדית": true,
"חרדנו": true,
"חרדתה": true,
"חרדתו": true,
"חרדתי": true,
"חרדתך": true,
"חרדתם": true,
"חרדתן": true,
"חרובה": true,
"חרובו": true,
"חרובי": true,
"חרובך": true,
"חרובם": true,
"חרובן": true,
"חרוזה": true,
"חרוזו": true,
"חרוזי": true,
"חרוזך": true,
"חרוזם": true,
"חרוזן": true,
"חרוטה": true,
"חרוטו": true,
"חרוטי": true,
"חרוטך": true,
"חרוטם": true,
"חרוטן": true,
"חרוכה": true,
"חרונה": true,
"חרונו": true,
"חרוני": true,
"חרונך": true,
"חרונם": true,
"חרונן": true,
"חרוסת": true,
"חרופה": true,
"חרוצה": true,
"חרוקה": true,
"חרושה": true,
"חרושת": true,
"חרותה": true,
"חרזנו": true,
"חרזתי": true,
"חרזתם": true,
"חרזתן": true,
"חרחור": true,
"חרחרה": true,
"חרחרו": true,
"חרחרי": true,
"חרחרן": true,
"חרחרת": true,
"חרטום": true,
"חרטות": true,
"חרטים": true,
"חרטנו": true,
"חרטתה": true,
"חרטתו": true,
"חרטתי": true,
"חרטתך": true,
"חרטתם": true,
"חרטתן": true,
"חריגה": true,
"חריגו": true,
"חריגי": true,
"חריגך": true,
"חריגם": true,
"חריגן": true,
"חריות": true,
"חריזה": true,
"חריזי": true,
"חריטה": true,
"חריים": true,
"חריכה": true,
"חרינה": true,
"חרינו": true,
"חריפה": true,
"חריצה": true,
"חריצו": true,
"חריצי": true,
"חריצך": true,
"חריצם": true,
"חריצן": true,
"חריקה": true,
"חרירה": true,
"חרירו": true,
"חרירי": true,
"חרירך": true,
"חרירם": true,
"חרירן": true,
"חרישה": true,
"חרישו": true,
"חרישי": true,
"חרישך": true,
"חרישם": true,
"חרישן": true,
"חריתה": true,
"חריתי": true,
"חריתם": true,
"חריתן": true,
"חרכיה": true,
"חרכיו": true,
"חרכיי": true,
"חרכיך": true,
"חרכים": true,
"חרככם": true,
"חרככן": true,
"חרכנו": true,
"חרכתי": true,
"חרכתם": true,
"חרכתן": true,
"חרמון": true,
"חרמות": true,
"חרמיה": true,
"חרמיו": true,
"חרמיי": true,
"חרמיך": true,
"חרמים": true,
"חרמכם": true,
"חרמכן": true,
"חרמנה": true,
"חרמנו": true,
"חרמני": true,
"חרמנת": true,
"חרמשה": true,
"חרמשו": true,
"חרמשי": true,
"חרמשך": true,
"חרמשם": true,
"חרמשן": true,
"חרסיה": true,
"חרסיו": true,
"חרסיי": true,
"חרסיך": true,
"חרסים": true,
"חרסית": true,
"חרסכם": true,
"חרסכן": true,
"חרסנו": true,
"חרפות": true,
"חרפנה": true,
"חרפנו": true,
"חרפני": true,
"חרפנך": true,
"חרפנם": true,
"חרפנן": true,
"חרפתה": true,
"חרפתו": true,
"חרפתי": true,
"חרפתך": true,
"חרפתם": true,
"חרפתן": true,
"חרצית": true,
"חרצנה": true,
"חרצנו": true,
"חרצני": true,
"חרצנך": true,
"חרצנם": true,
"חרצנן": true,
"חרצתי": true,
"חרצתם": true,
"חרצתן": true,
"חרקיה": true,
"חרקיו": true,
"חרקיי": true,
"חרקיך": true,
"חרקים": true,
"חרקכם": true,
"חרקכן": true,
"חרקנו": true,
"חרקתי": true,
"חרקתם": true,
"חרקתן": true,
"חררים": true,
"חרשיה": true,
"חרשיו": true,
"חרשיי": true,
"חרשיך": true,
"חרשים": true,
"חרשכם": true,
"חרשכן": true,
"חרשנו": true,
"חרשתי": true,
"חרשתם": true,
"חרשתן": true,
"חרתנו": true,
"חשאית": true,
"חשבון": true,
"חשבות": true,
"חשביה": true,
"חשביו": true,
"חשביי": true,
"חשביך": true,
"חשבים": true,
"חשבכם": true,
"חשבכן": true,
"חשבנה": true,
"חשבנו": true,
"חשבתה": true,
"חשבתו": true,
"חשבתי": true,
"חשבתך": true,
"חשבתם": true,
"חשבתן": true,
"חשדות": true,
"חשדכם": true,
"חשדכן": true,
"חשדנו": true,
"חשדני": true,
"חשדתי": true,
"חשדתם": true,
"חשדתן": true,
"חשובה": true,
"חשודה": true,
"חשוון": true,
"חשוכה": true,
"חשופה": true,
"חשוקה": true,
"חשושה": true,
"חשיבה": true,
"חשיפה": true,
"חשיפת": true,
"חשיקה": true,
"חשכות": true,
"חשכים": true,
"חשכנו": true,
"חשכתה": true,
"חשכתו": true,
"חשכתי": true,
"חשכתך": true,
"חשכתם": true,
"חשכתן": true,
"חשלנה": true,
"חשמול": true,
"חשמלה": true,
"חשמלו": true,
"חשמלי": true,
"חשמלך": true,
"חשמלם": true,
"חשמלן": true,
"חשמלת": true,
"חשמנה": true,
"חשמנו": true,
"חשמני": true,
"חשמנך": true,
"חשמנם": true,
"חשמנן": true,
"חשפנה": true,
"חשפנו": true,
"חשפני": true,
"חשפנך": true,
"חשפנם": true,
"חשפנן": true,
"חשפתי": true,
"חשפתם": true,
"חשפתן": true,
"חשקיה": true,
"חשקיו": true,
"חשקיי": true,
"חשקיך": true,
"חשקים": true,
"חשקכם": true,
"חשקכן": true,
"חשקנה": true,
"חשקנו": true,
"חשקתי": true,
"חשקתם": true,
"חשקתן": true,
"חששות": true,
"חששכם": true,
"חששכן": true,
"חששנו": true,
"חששני": true,
"חששתי": true,
"חששתם": true,
"חששתן": true,
"חתוכה": true,
"חתולה": true,
"חתולו": true,
"חתולי": true,
"חתולך": true,
"חתולם": true,
"חתולן": true,
"חתומה": true,
"חתונה": true,
"חתונת": true,
"חתיכה": true,
"חתיכו": true,
"חתיכי": true,
"חתיכך": true,
"חתיכם": true,
"חתיכן": true,
"חתיכת": true,
"חתימה": true,
"חתימת": true,
"חתירה": true,
"חתכיה": true,
"חתכיו": true,
"חתכיי": true,
"חתכיך": true,
"חתכים": true,
"חתככם": true,
"חתככן": true,
"חתכנו": true,
"חתכתי": true,
"חתכתם": true,
"חתכתן": true,
"חתלנה": true,
"חתמות": true,
"חתמיה": true,
"חתמיו": true,
"חתמיי": true,
"חתמיך": true,
"חתמים": true,
"חתמכם": true,
"חתמכן": true,
"חתמנו": true,
"חתמתה": true,
"חתמתו": true,
"חתמתי": true,
"חתמתך": true,
"חתמתם": true,
"חתמתן": true,
"חתניה": true,
"חתניו": true,
"חתניי": true,
"חתניך": true,
"חתנים": true,
"חתנכם": true,
"חתנכן": true,
"חתננו": true,
"חתרנו": true,
"חתרני": true,
"חתרתי": true,
"חתרתם": true,
"חתרתן": true,
"טאבון": true,
"טאבלט": true,
"טאטאה": true,
"טאטאו": true,
"טאטאי": true,
"טאטאת": true,
"טאטוא": true,
"טאלאט": true,
"טאמון": true,
"טארגט": true,
"טארוט": true,
"טאריף": true,
"טבוחה": true,
"טבולה": true,
"טבועה": true,
"טבורה": true,
"טבורו": true,
"טבורי": true,
"טבורך": true,
"טבורם": true,
"טבורן": true,
"טבחות": true,
"טבחיה": true,
"טבחיו": true,
"טבחיי": true,
"טבחיך": true,
"טבחים": true,
"טבחית": true,
"טבחכם": true,
"טבחכן": true,
"טבחנה": true,
"טבחנו": true,
"טבחתי": true,
"טבחתם": true,
"טבחתן": true,
"טבילה": true,
"טביעה": true,
"טביעת": true,
"טבלאי": true,
"טבלות": true,
"טבלטה": true,
"טבלטו": true,
"טבלטי": true,
"טבלטך": true,
"טבלטם": true,
"טבלטן": true,
"טבלית": true,
"טבלנו": true,
"טבלתה": true,
"טבלתו": true,
"טבלתי": true,
"טבלתך": true,
"טבלתם": true,
"טבלתן": true,
"טבסקו": true,
"טבעון": true,
"טבעות": true,
"טבעיה": true,
"טבעיו": true,
"טבעיי": true,
"טבעיך": true,
"טבעים": true,
"טבעית": true,
"טבעכם": true,
"טבעכן": true,
"טבענה": true,
"טבענו": true,
"טבעתה": true,
"טבעתו": true,
"טבעתי": true,
"טבעתך": true,
"טבעתם": true,
"טבעתן": true,
"טבקכם": true,
"טבקכן": true,
"טבקנו": true,
"טבריה": true,
"טברנה": true,
"טברני": true,
"טגנים": true,
"טדיון": true,
"טהורה": true,
"טהיטי": true,
"טהייד": true,
"טהראן": true,
"טהרות": true,
"טהרנה": true,
"טהרנו": true,
"טהרני": true,
"טהרתה": true,
"טהרתו": true,
"טהרתי": true,
"טהרתך": true,
"טהרתם": true,
"טהרתן": true,
"טואטא": true,
"טואלט": true,
"טובאס": true,
"טובגו": true,
"טובות": true,
"טובחת": true,
"טוביה": true,
"טוביו": true,
"טוביי": true,
"טוביך": true,
"טובים": true,
"טובין": true,
"טובכם": true,
"טובכן": true,
"טובלו": true,
"טובלת": true,
"טובנו": true,
"טובעה": true,
"טובעו": true,
"טובעת": true,
"טובתה": true,
"טובתו": true,
"טובתי": true,
"טובתך": true,
"טובתם": true,
"טובתן": true,
"טוגות": true,
"טוגנה": true,
"טוגנו": true,
"טוגנת": true,
"טוגתה": true,
"טוגתו": true,
"טוגתי": true,
"טוגתך": true,
"טוגתם": true,
"טוגתן": true,
"טודיי": true,
"טוהרה": true,
"טוהרו": true,
"טוהרי": true,
"טוהרך": true,
"טוהרם": true,
"טוהרן": true,
"טוהרת": true,
"טווות": true,
"טווחה": true,
"טווחו": true,
"טווחי": true,
"טווחך": true,
"טווחם": true,
"טווחן": true,
"טווחת": true,
"טוויה": true,
"טוויל": true,
"טווים": true,
"טווית": true,
"טווסה": true,
"טווסו": true,
"טווסי": true,
"טווסך": true,
"טווסם": true,
"טווסן": true,
"טוותה": true,
"טוחנת": true,
"טוטלי": true,
"טוטמה": true,
"טוטמו": true,
"טוטמי": true,
"טוטמך": true,
"טוטמם": true,
"טוטמן": true,
"טוטפת": true,
"טויבה": true,
"טויבו": true,
"טויבת": true,
"טויחה": true,
"טויחו": true,
"טויחת": true,
"טוילה": true,
"טוילו": true,
"טוילת": true,
"טוינה": true,
"טוכלר": true,
"טולדו": true,
"טולטל": true,
"טולנה": true,
"טומאה": true,
"טומאו": true,
"טומאת": true,
"טומטם": true,
"טומנת": true,
"טומעת": true,
"טונגה": true,
"טונוס": true,
"טונות": true,
"טוניה": true,
"טוניו": true,
"טוניי": true,
"טוניך": true,
"טונים": true,
"טוניק": true,
"טונית": true,
"טונכם": true,
"טונכן": true,
"טונלי": true,
"טוננו": true,
"טונפה": true,
"טונפו": true,
"טונפת": true,
"טוסטה": true,
"טוסטו": true,
"טוסטי": true,
"טוסטך": true,
"טוסטם": true,
"טוסטן": true,
"טוסטר": true,
"טוסיה": true,
"טוסיק": true,
"טוסנה": true,
"טועות": true,
"טועים": true,
"טועמת": true,
"טוענת": true,
"טופול": true,
"טופחה": true,
"טופחו": true,
"טופחת": true,
"טופלה": true,
"טופלו": true,
"טופלת": true,
"טופסה": true,
"טופסו": true,
"טופסי": true,
"טופסך": true,
"טופסם": true,
"טופסן": true,
"טופסת": true,
"טופפה": true,
"טופפו": true,
"טופפי": true,
"טופפת": true,
"טופרה": true,
"טופרו": true,
"טופרי": true,
"טופרך": true,
"טופרם": true,
"טופרן": true,
"טוקבק": true,
"טוקול": true,
"טוקטה": true,
"טוקיו": true,
"טוראי": true,
"טוראל": true,
"טורבו": true,
"טורבן": true,
"טורדן": true,
"טורדת": true,
"טורזה": true,
"טורחת": true,
"טורטר": true,
"טוריה": true,
"טוריו": true,
"טוריי": true,
"טוריך": true,
"טורים": true,
"טורית": true,
"טורכם": true,
"טורכן": true,
"טורנו": true,
"טורפד": true,
"טורפת": true,
"טורקי": true,
"טורקת": true,
"טושטש": true,
"טושיה": true,
"טושיו": true,
"טושיי": true,
"טושיך": true,
"טושים": true,
"טושכם": true,
"טושכן": true,
"טושנו": true,
"טזזנה": true,
"טזמני": true,
"טחביה": true,
"טחביו": true,
"טחביי": true,
"טחביך": true,
"טחבים": true,
"טחבכם": true,
"טחבכן": true,
"טחבנו": true,
"טחובה": true,
"טחולה": true,
"טחולו": true,
"טחולי": true,
"טחולך": true,
"טחולם": true,
"טחולן": true,
"טחונה": true,
"טחינה": true,
"טחנות": true,
"טחנתה": true,
"טחנתו": true,
"טחנתי": true,
"טחנתך": true,
"טחנתם": true,
"טחנתן": true,
"טטנוס": true,
"טטרים": true,
"טטרית": true,
"טיאטא": true,
"טיבוע": true,
"טיבטי": true,
"טיביה": true,
"טיביו": true,
"טיביי": true,
"טיביך": true,
"טיבים": true,
"טיבית": true,
"טיבכם": true,
"טיבכן": true,
"טיבנו": true,
"טיבעה": true,
"טיבעו": true,
"טיבעת": true,
"טיגון": true,
"טיגנה": true,
"טיגנו": true,
"טיגנת": true,
"טיהור": true,
"טיהרה": true,
"טיהרו": true,
"טיהרת": true,
"טיובה": true,
"טיובו": true,
"טיובי": true,
"טיובך": true,
"טיובם": true,
"טיובן": true,
"טיווח": true,
"טיוון": true,
"טיוחה": true,
"טיוחו": true,
"טיוחי": true,
"טיוחך": true,
"טיוחם": true,
"טיוחן": true,
"טיוטה": true,
"טיוטת": true,
"טיולה": true,
"טיולו": true,
"טיולי": true,
"טיולך": true,
"טיולם": true,
"טיולן": true,
"טיזוז": true,
"טיזזה": true,
"טיזזו": true,
"טיזזת": true,
"טיטאן": true,
"טיטול": true,
"טיטוס": true,
"טיטכם": true,
"טיטכן": true,
"טיטנו": true,
"טייבה": true,
"טייבו": true,
"טייבי": true,
"טייבת": true,
"טייחה": true,
"טייחו": true,
"טייחי": true,
"טייחך": true,
"טייחם": true,
"טייחן": true,
"טייחת": true,
"טיילה": true,
"טיילו": true,
"טיילי": true,
"טיילך": true,
"טיילם": true,
"טיילן": true,
"טיילת": true,
"טיימס": true,
"טיימר": true,
"טייסה": true,
"טייסו": true,
"טייסי": true,
"טייסך": true,
"טייסם": true,
"טייסן": true,
"טייסת": true,
"טייפה": true,
"טילון": true,
"טיליה": true,
"טיליו": true,
"טיליי": true,
"טיליך": true,
"טילים": true,
"טילכם": true,
"טילכן": true,
"טילנו": true,
"טילפן": true,
"טימאה": true,
"טימאו": true,
"טימאת": true,
"טימוא": true,
"טימור": true,
"טימין": true,
"טינות": true,
"טינים": true,
"טינפה": true,
"טינפו": true,
"טינפת": true,
"טינתה": true,
"טינתו": true,
"טינתי": true,
"טינתך": true,
"טינתם": true,
"טינתן": true,
"טיסות": true,
"טיסנה": true,
"טיסנו": true,
"טיסני": true,
"טיסנך": true,
"טיסנם": true,
"טיסנן": true,
"טיסתה": true,
"טיסתו": true,
"טיסתי": true,
"טיסתך": true,
"טיסתם": true,
"טיסתן": true,
"טיעון": true,
"טיפוח": true,
"טיפול": true,
"טיפון": true,
"טיפוס": true,
"טיפוף": true,
"טיפות": true,
"טיפחה": true,
"טיפחו": true,
"טיפחת": true,
"טיפיה": true,
"טיפיו": true,
"טיפיי": true,
"טיפיך": true,
"טיפים": true,
"טיפין": true,
"טיפלה": true,
"טיפלו": true,
"טיפלת": true,
"טיפסה": true,
"טיפסו": true,
"טיפסת": true,
"טיפקס": true,
"טיפשה": true,
"טיפשי": true,
"טיפתה": true,
"טיפתו": true,
"טיפתי": true,
"טיפתך": true,
"טיפתם": true,
"טיפתן": true,
"טיקון": true,
"טירון": true,
"טירוף": true,
"טירור": true,
"טירות": true,
"טירקל": true,
"טירתה": true,
"טירתו": true,
"טירתי": true,
"טירתך": true,
"טירתם": true,
"טירתן": true,
"טכנאה": true,
"טכנאו": true,
"טכנאי": true,
"טכנאך": true,
"טכנאם": true,
"טכנאן": true,
"טכנית": true,
"טלאיה": true,
"טלאיו": true,
"טלאיי": true,
"טלאיך": true,
"טלאים": true,
"טלאכם": true,
"טלאכן": true,
"טלאנו": true,
"טלביה": true,
"טלבני": true,
"טלגרף": true,
"טלטול": true,
"טלטלה": true,
"טלטלו": true,
"טלטלי": true,
"טלטלת": true,
"טליבן": true,
"טליות": true,
"טלילה": true,
"טליתה": true,
"טליתו": true,
"טליתי": true,
"טליתך": true,
"טליתם": true,
"טליתן": true,
"טלכרט": true,
"טלליה": true,
"טלליו": true,
"טלליי": true,
"טלליך": true,
"טללים": true,
"טלמון": true,
"טלמור": true,
"טלמסר": true,
"טלסקר": true,
"טלפון": true,
"טלפיה": true,
"טלפיו": true,
"טלפיי": true,
"טלפיך": true,
"טלפים": true,
"טלפכם": true,
"טלפכן": true,
"טלפנה": true,
"טלפנו": true,
"טלפני": true,
"טלפנך": true,
"טלפנם": true,
"טלפנן": true,
"טלפנת": true,
"טלפתי": true,
"טלקום": true,
"טמאות": true,
"טמאים": true,
"טמאנה": true,
"טמבור": true,
"טמונה": true,
"טמטום": true,
"טמטמה": true,
"טמטמו": true,
"טמטמי": true,
"טמטמת": true,
"טמיון": true,
"טמינה": true,
"טמיעה": true,
"טמירה": true,
"טמנתי": true,
"טמנתם": true,
"טמנתן": true,
"טמענה": true,
"טמענו": true,
"טמעתי": true,
"טמעתם": true,
"טמעתן": true,
"טמפון": true,
"טמפלר": true,
"טמפרה": true,
"טנאיה": true,
"טנאיו": true,
"טנאיי": true,
"טנאיך": true,
"טנאים": true,
"טנאכם": true,
"טנאכן": true,
"טנאנו": true,
"טנגרל": true,
"טנדלר": true,
"טנדרה": true,
"טנדרו": true,
"טנדרי": true,
"טנדרך": true,
"טנדרם": true,
"טנדרן": true,
"טנזור": true,
"טנזני": true,
"טנטרה": true,
"טנטרי": true,
"טנפנה": true,
"טנקיה": true,
"טנקיו": true,
"טנקיי": true,
"טנקיך": true,
"טנקים": true,
"טנקכם": true,
"טנקכן": true,
"טנקנו": true,
"טנריף": true,
"טסטים": true,
"טסיות": true,
"טסיתה": true,
"טסיתו": true,
"טסיתי": true,
"טסיתך": true,
"טסיתם": true,
"טסיתן": true,
"טעונה": true,
"טעותה": true,
"טעותו": true,
"טעותי": true,
"טעותך": true,
"טעותם": true,
"טעותן": true,
"טעיות": true,
"טעייה": true,
"טעימה": true,
"טעינה": true,
"טעינו": true,
"טעיתי": true,
"טעיתם": true,
"טעיתן": true,
"טעמיה": true,
"טעמיו": true,
"טעמיי": true,
"טעמיך": true,
"טעמים": true,
"טעמכם": true,
"טעמכן": true,
"טעמנה": true,
"טעמנו": true,
"טעמתי": true,
"טעמתם": true,
"טעמתן": true,
"טענות": true,
"טענתה": true,
"טענתו": true,
"טענתי": true,
"טענתך": true,
"טענתם": true,
"טענתן": true,
"טפחות": true,
"טפחיה": true,
"טפחיו": true,
"טפחיי": true,
"טפחיך": true,
"טפחים": true,
"טפחכם": true,
"טפחכן": true,
"טפחנה": true,
"טפחנו": true,
"טפחתה": true,
"טפחתו": true,
"טפחתי": true,
"טפחתך": true,
"טפחתם": true,
"טפחתן": true,
"טפטוף": true,
"טפטיה": true,
"טפטיו": true,
"טפטיי": true,
"טפטיך": true,
"טפטים": true,
"טפטכם": true,
"טפטכן": true,
"טפטנו": true,
"טפטפה": true,
"טפטפו": true,
"טפטפי": true,
"טפטפת": true,
"טפיחה": true,
"טפילה": true,
"טפילו": true,
"טפילי": true,
"טפילך": true,
"טפילם": true,
"טפילן": true,
"טפיפה": true,
"טפירה": true,
"טפירו": true,
"טפירי": true,
"טפירך": true,
"טפירם": true,
"טפירן": true,
"טפלון": true,
"טפלות": true,
"טפלים": true,
"טפלנה": true,
"טפלנו": true,
"טפלתי": true,
"טפלתם": true,
"טפלתן": true,
"טפסיה": true,
"טפסיו": true,
"טפסיי": true,
"טפסיך": true,
"טפסים": true,
"טפסנה": true,
"טפסנו": true,
"טפסני": true,
"טפסנך": true,
"טפסנם": true,
"טפסנן": true,
"טפסרה": true,
"טפסרו": true,
"טפסרי": true,
"טפסרך": true,
"טפסרם": true,
"טפסרן": true,
"טפריה": true,
"טפריו": true,
"טפריי": true,
"טפריך": true,
"טפרים": true,
"טפשון": true,
"טציות": true,
"טקטית": true,
"טקילה": true,
"טקסול": true,
"טקסטה": true,
"טקסטו": true,
"טקסטי": true,
"טקסטך": true,
"טקסטם": true,
"טקסטן": true,
"טקסיה": true,
"טקסיו": true,
"טקסיי": true,
"טקסיך": true,
"טקסים": true,
"טקסית": true,
"טקסכם": true,
"טקסכן": true,
"טקסנו": true,
"טקסני": true,
"טראגי": true,
"טראוס": true,
"טראות": true,
"טראמפ": true,
"טראנס": true,
"טרבאי": true,
"טרגון": true,
"טרגית": true,
"טרדות": true,
"טרדנו": true,
"טרדני": true,
"טרדתה": true,
"טרדתו": true,
"טרדתי": true,
"טרדתך": true,
"טרדתם": true,
"טרדתן": true,
"טרואר": true,
"טרודה": true,
"טרוטה": true,
"טרויה": true,
"טרויט": true,
"טרומי": true,
"טרומן": true,
"טרופה": true,
"טרופי": true,
"טרוקה": true,
"טרזני": true,
"טרחות": true,
"טרחנה": true,
"טרחנו": true,
"טרחני": true,
"טרחתה": true,
"טרחתו": true,
"טרחתי": true,
"טרחתך": true,
"טרחתם": true,
"טרחתן": true,
"טרטור": true,
"טרטנר": true,
"טרטרה": true,
"טרטרו": true,
"טרטרי": true,
"טרטרת": true,
"טריאן": true,
"טריבר": true,
"טריגר": true,
"טריוו": true,
"טריון": true,
"טריות": true,
"טריזה": true,
"טריזו": true,
"טריזי": true,
"טריזך": true,
"טריזם": true,
"טריזן": true,
"טרייד": true,
"טרייה": true,
"טריים": true,
"טרייר": true,
"טרינה": true,
"טריפה": true,
"טריפל": true,
"טריקה": true,
"טריקו": true,
"טרמיט": true,
"טרמית": true,
"טרמפה": true,
"טרמפו": true,
"טרמפי": true,
"טרמפך": true,
"טרמפם": true,
"טרמפן": true,
"טרנדי": true,
"טרנטה": true,
"טרסות": true,
"טרסים": true,
"טרפדה": true,
"טרפדו": true,
"טרפדי": true,
"טרפדת": true,
"טרפוד": true,
"טרפות": true,
"טרפזה": true,
"טרפזו": true,
"טרפזי": true,
"טרפזך": true,
"טרפזם": true,
"טרפזן": true,
"טרפיה": true,
"טרפיו": true,
"טרפיי": true,
"טרפיך": true,
"טרפים": true,
"טרפכם": true,
"טרפכן": true,
"טרפנו": true,
"טרפתה": true,
"טרפתו": true,
"טרפתי": true,
"טרפתך": true,
"טרפתם": true,
"טרפתן": true,
"טרצות": true,
"טרקום": true,
"טרקים": true,
"טרקנו": true,
"טרקתי": true,
"טרקתם": true,
"טרקתן": true,
"טרשות": true,
"טרשיה": true,
"טרשיו": true,
"טרשיי": true,
"טרשיך": true,
"טרשים": true,
"טרשית": true,
"טרשכם": true,
"טרשכן": true,
"טרשנו": true,
"טרשתה": true,
"טרשתו": true,
"טרשתי": true,
"טרשתך": true,
"טרשתם": true,
"טרשתן": true,
"טרתית": true,
"טשטוש": true,
"טשטשה": true,
"טשטשו": true,
"טשטשי": true,
"טשטשת": true,
"טשקנט": true,
"יאבדו": true,
"יאבזר": true,
"יאבחן": true,
"יאבטח": true,
"יאביס": true,
"יאביק": true,
"יאבלו": true,
"יאבנו": true,
"יאבקו": true,
"יאגדו": true,
"יאגור": true,
"יאגמו": true,
"יאגפו": true,
"יאגרו": true,
"יאגרף": true,
"יאדים": true,
"יאדיר": true,
"יאהבו": true,
"יאהדו": true,
"יאהוד": true,
"יאהיב": true,
"יאהיל": true,
"יאובן": true,
"יאובק": true,
"יאוגד": true,
"יאוגף": true,
"יאודה": true,
"יאודו": true,
"יאוזן": true,
"יאוחד": true,
"יאוחה": true,
"יאוחו": true,
"יאוחל": true,
"יאוחר": true,
"יאויד": true,
"יאוים": true,
"יאוין": true,
"יאויר": true,
"יאויש": true,
"יאוית": true,
"יאוכל": true,
"יאוכן": true,
"יאולף": true,
"יאולץ": true,
"יאומן": true,
"יאומץ": true,
"יאומת": true,
"יאונה": true,
"יאונן": true,
"יאופס": true,
"יאופר": true,
"יאוצו": true,
"יאושה": true,
"יאושו": true,
"יאושי": true,
"יאושך": true,
"יאושם": true,
"יאושן": true,
"יאושר": true,
"יאושש": true,
"יאותו": true,
"יאותר": true,
"יאותת": true,
"יאזוק": true,
"יאזור": true,
"יאזין": true,
"יאזכר": true,
"יאזלו": true,
"יאזנו": true,
"יאזקו": true,
"יאזרו": true,
"יאזרח": true,
"יאחבל": true,
"יאחדו": true,
"יאחזו": true,
"יאחזר": true,
"יאחיד": true,
"יאחלו": true,
"יאחסן": true,
"יאחרו": true,
"יאטום": true,
"יאטים": true,
"יאטמו": true,
"יאייד": true,
"יאיים": true,
"יאיין": true,
"יאייר": true,
"יאייש": true,
"יאיית": true,
"יאיצו": true,
"יאירו": true,
"יאכוף": true,
"יאכזב": true,
"יאכטה": true,
"יאכיל": true,
"יאכלו": true,
"יאכלס": true,
"יאכנו": true,
"יאכפו": true,
"יאלחש": true,
"יאליס": true,
"יאלית": true,
"יאללה": true,
"יאלפו": true,
"יאלצו": true,
"יאלתר": true,
"יאמדו": true,
"יאמוד": true,
"יאמון": true,
"יאמין": true,
"יאמיר": true,
"יאמלל": true,
"יאמנו": true,
"יאמצו": true,
"יאמרו": true,
"יאמתו": true,
"יאנוס": true,
"יאנוש": true,
"יאנים": true,
"יאניש": true,
"יאנית": true,
"יאנסו": true,
"יאנפף": true,
"יאסוף": true,
"יאסור": true,
"יאסטר": true,
"יאסין": true,
"יאסלם": true,
"יאספו": true,
"יאסרו": true,
"יאפוף": true,
"יאפיל": true,
"יאפיר": true,
"יאפנן": true,
"יאפסו": true,
"יאפפו": true,
"יאפרו": true,
"יאפשר": true,
"יאצור": true,
"יאציל": true,
"יאצרו": true,
"יאראק": true,
"יארבו": true,
"יארגו": true,
"יארגן": true,
"יארוב": true,
"יארוג": true,
"יארוז": true,
"יארוש": true,
"יארזו": true,
"יארחו": true,
"יאריך": true,
"יאריק": true,
"יארכב": true,
"יארכו": true,
"יארסו": true,
"יאשיה": true,
"יאשים": true,
"יאשמו": true,
"יאשנה": true,
"יאשפז": true,
"יאשרו": true,
"יאשרר": true,
"יאששו": true,
"יאתגר": true,
"יאתחל": true,
"יאתית": true,
"יאתרו": true,
"יבאיש": true,
"יבאנה": true,
"יבאסו": true,
"יבארו": true,
"יבאשו": true,
"יבבות": true,
"יבבנה": true,
"יבבני": true,
"יבבתה": true,
"יבבתו": true,
"יבבתי": true,
"יבבתך": true,
"יבבתם": true,
"יבבתן": true,
"יבגדו": true,
"יבגוד": true,
"יבגני": true,
"יבגרו": true,
"יבדוק": true,
"יבדיל": true,
"יבדלו": true,
"יבדקו": true,
"יבדרו": true,
"יבהיל": true,
"יבהיק": true,
"יבהיר": true,
"יבהקו": true,
"יבואה": true,
"יבואו": true,
"יבואי": true,
"יבואך": true,
"יבואם": true,
"יבואן": true,
"יבואס": true,
"יבואר": true,
"יבודד": true,
"יבודל": true,
"יבודר": true,
"יבוזה": true,
"יבוזו": true,
"יבוזר": true,
"יבוטא": true,
"יבוטח": true,
"יבוטל": true,
"יבויל": true,
"יבוים": true,
"יבויש": true,
"יבוית": true,
"יבולה": true,
"יבולו": true,
"יבולי": true,
"יבולך": true,
"יבולם": true,
"יבולן": true,
"יבולע": true,
"יבולת": true,
"יבוסי": true,
"יבוסם": true,
"יבוסס": true,
"יבוער": true,
"יבוצע": true,
"יבוצר": true,
"יבוקע": true,
"יבוקר": true,
"יבוקש": true,
"יבורא": true,
"יבורו": true,
"יבורי": true,
"יבורך": true,
"יבורר": true,
"יבושו": true,
"יבושל": true,
"יבושם": true,
"יבושר": true,
"יבושש": true,
"יבותק": true,
"יבותר": true,
"יבזבז": true,
"יבזוז": true,
"יבזוק": true,
"יבזזו": true,
"יבזיק": true,
"יבזקו": true,
"יבזרו": true,
"יבחוש": true,
"יבחיל": true,
"יבחין": true,
"יבחלו": true,
"יבחנו": true,
"יבחרו": true,
"יבחשו": true,
"יבטאו": true,
"יבטוש": true,
"יבטחו": true,
"יבטיח": true,
"יבטים": true,
"יבטלו": true,
"יבטשו": true,
"יביאו": true,
"יביות": true,
"יביטו": true,
"יבייל": true,
"יביים": true,
"יבייץ": true,
"יבייש": true,
"יביית": true,
"יביכו": true,
"יבילה": true,
"יבינו": true,
"יביסו": true,
"יביעו": true,
"יבכיר": true,
"יבכרו": true,
"יבלבל": true,
"יבלגן": true,
"יבלוט": true,
"יבלול": true,
"יבלום": true,
"יבלוס": true,
"יבלוש": true,
"יבלות": true,
"יבלטו": true,
"יבליג": true,
"יבליח": true,
"יבליט": true,
"יבלים": true,
"יבליע": true,
"יבללו": true,
"יבלמו": true,
"יבלסו": true,
"יבלעו": true,
"יבלפו": true,
"יבלשו": true,
"יבלתה": true,
"יבלתו": true,
"יבלתי": true,
"יבלתך": true,
"יבלתם": true,
"יבלתן": true,
"יבמות": true,
"יבמנה": true,
"יבמתה": true,
"יבמתו": true,
"יבמתי": true,
"יבמתך": true,
"יבמתם": true,
"יבמתן": true,
"יבנאל": true,
"יבנאם": true,
"יבסמו": true,
"יבססו": true,
"יבסקי": true,
"יבעבע": true,
"יבעטו": true,
"יבעיר": true,
"יבעית": true,
"יבעלו": true,
"יבערו": true,
"יבצבץ": true,
"יבצור": true,
"יבציה": true,
"יבצעו": true,
"יבצרו": true,
"יבקבק": true,
"יבקים": true,
"יבקיע": true,
"יבקעו": true,
"יבקרו": true,
"יבקשו": true,
"יבראו": true,
"יברבר": true,
"יברחו": true,
"יבריא": true,
"יבריג": true,
"יבריז": true,
"יבריח": true,
"יבריק": true,
"יבריש": true,
"יברכו": true,
"יברכך": true,
"יבררו": true,
"יבשות": true,
"יבשיל": true,
"יבשים": true,
"יבשלו": true,
"יבשמו": true,
"יבשנה": true,
"יבשנו": true,
"יבשרו": true,
"יבשתה": true,
"יבשתו": true,
"יבשתי": true,
"יבשתך": true,
"יבשתם": true,
"יבשתן": true,
"יבתית": true,
"יבתקו": true,
"יבתרו": true,
"יגאלו": true,
"יגבבו": true,
"יגבהו": true,
"יגבול": true,
"יגביה": true,
"יגביל": true,
"יגביר": true,
"יגבלו": true,
"יגבנו": true,
"יגבסו": true,
"יגברו": true,
"יגבשו": true,
"יגדום": true,
"יגדיל": true,
"יגדיר": true,
"יגדיש": true,
"יגדלו": true,
"יגדמו": true,
"יגדעו": true,
"יגדפו": true,
"יגדרו": true,
"יגדשו": true,
"יגהצו": true,
"יגהקו": true,
"יגהרו": true,
"יגואר": true,
"יגובב": true,
"יגובה": true,
"יגובו": true,
"יגובן": true,
"יגובס": true,
"יגובש": true,
"יגודל": true,
"יגודף": true,
"יגודר": true,
"יגוהץ": true,
"יגוון": true,
"יגווע": true,
"יגוזו": true,
"יגוחך": true,
"יגויס": true,
"יגויר": true,
"יגולח": true,
"יגולל": true,
"יגולם": true,
"יגולף": true,
"יגומד": true,
"יגומר": true,
"יגונה": true,
"יגונו": true,
"יגוני": true,
"יגונך": true,
"יגונם": true,
"יגונן": true,
"יגופר": true,
"יגורה": true,
"יגורו": true,
"יגורז": true,
"יגורף": true,
"יגורר": true,
"יגורש": true,
"יגורת": true,
"יגושר": true,
"יגזוז": true,
"יגזול": true,
"יגזום": true,
"יגזור": true,
"יגזזו": true,
"יגזים": true,
"יגזלו": true,
"יגזמו": true,
"יגזרו": true,
"יגחיך": true,
"יגחכו": true,
"יגחנו": true,
"יגיבו": true,
"יגידו": true,
"יגיהו": true,
"יגיון": true,
"יגיחו": true,
"יגייס": true,
"יגייר": true,
"יגיעה": true,
"יגיעו": true,
"יגיעי": true,
"יגיעך": true,
"יגיעם": true,
"יגיען": true,
"יגיפו": true,
"יגירו": true,
"יגישו": true,
"יגלגל": true,
"יגלול": true,
"יגלוש": true,
"יגלחו": true,
"יגליד": true,
"יגליש": true,
"יגללו": true,
"יגלמו": true,
"יגלען": true,
"יגלפו": true,
"יגלשו": true,
"יגמגם": true,
"יגמדו": true,
"יגמול": true,
"יגמור": true,
"יגמיש": true,
"יגמלו": true,
"יגמעו": true,
"יגמרו": true,
"יגנבו": true,
"יגנוב": true,
"יגנוז": true,
"יגנזו": true,
"יגנחו": true,
"יגניב": true,
"יגננו": true,
"יגסוס": true,
"יגססו": true,
"יגעגע": true,
"יגעות": true,
"יגעיל": true,
"יגעים": true,
"יגעיש": true,
"יגענה": true,
"יגענו": true,
"יגערו": true,
"יגעשו": true,
"יגעתי": true,
"יגעתם": true,
"יגעתן": true,
"יגפפו": true,
"יגפרו": true,
"יגציה": true,
"יגרבו": true,
"יגרגר": true,
"יגרדו": true,
"יגרוב": true,
"יגרוד": true,
"יגרום": true,
"יגרוס": true,
"יגרוף": true,
"יגרור": true,
"יגרזו": true,
"יגריל": true,
"יגרמו": true,
"יגרסו": true,
"יגרעו": true,
"יגרען": true,
"יגרפו": true,
"יגררו": true,
"יגרשו": true,
"יגרתם": true,
"יגרתן": true,
"יגשים": true,
"יגשרו": true,
"יגששו": true,
"ידאבו": true,
"ידאגו": true,
"ידאיב": true,
"ידאיג": true,
"ידבבו": true,
"ידבגו": true,
"ידביק": true,
"ידביר": true,
"ידבקו": true,
"ידברו": true,
"ידברר": true,
"ידגדג": true,
"ידגול": true,
"ידגום": true,
"ידגור": true,
"ידגים": true,
"ידגיר": true,
"ידגיש": true,
"ידגלו": true,
"ידגמו": true,
"ידגמן": true,
"ידגרו": true,
"ידהים": true,
"ידהיר": true,
"ידהרו": true,
"ידובב": true,
"ידובג": true,
"ידובר": true,
"ידוגו": true,
"ידווח": true,
"ידווש": true,
"ידויי": true,
"ידויק": true,
"ידוכא": true,
"ידולג": true,
"ידולל": true,
"ידומה": true,
"ידומו": true,
"ידומם": true,
"ידונו": true,
"ידועה": true,
"ידוען": true,
"ידופן": true,
"ידורג": true,
"ידורו": true,
"ידורי": true,
"ידושו": true,
"ידושן": true,
"ידחוף": true,
"ידחיק": true,
"ידחסו": true,
"ידחפו": true,
"ידחקו": true,
"ידידה": true,
"ידידו": true,
"ידידי": true,
"ידידך": true,
"ידידם": true,
"ידידן": true,
"ידיהם": true,
"ידיהן": true,
"ידיות": true,
"ידיחו": true,
"ידייך": true,
"ידיים": true,
"ידייק": true,
"ידיכם": true,
"ידיכן": true,
"ידינג": true,
"ידינה": true,
"ידינו": true,
"ידיעה": true,
"ידיעת": true,
"ידיפו": true,
"ידירו": true,
"ידיתה": true,
"ידיתו": true,
"ידיתי": true,
"ידיתך": true,
"ידיתם": true,
"ידיתן": true,
"ידכאו": true,
"ידכדך": true,
"ידלגו": true,
"ידלדל": true,
"ידלוף": true,
"ידלוק": true,
"ידלין": true,
"ידליף": true,
"ידליק": true,
"ידללו": true,
"ידלפו": true,
"ידלקו": true,
"ידמום": true,
"ידמים": true,
"ידממו": true,
"ידמעו": true,
"ידנגו": true,
"ידנית": true,
"ידסקס": true,
"ידעכו": true,
"ידענה": true,
"ידענו": true,
"ידעתי": true,
"ידעתם": true,
"ידעתן": true,
"ידפדף": true,
"ידפוק": true,
"ידפיס": true,
"ידפנו": true,
"ידפקו": true,
"ידקדק": true,
"ידקור": true,
"ידקים": true,
"ידקלם": true,
"ידקרו": true,
"ידרבן": true,
"ידרגו": true,
"ידרדר": true,
"ידרוך": true,
"ידרוס": true,
"ידרוש": true,
"ידריך": true,
"ידרים": true,
"ידרכו": true,
"ידרמן": true,
"ידרסו": true,
"ידרשו": true,
"ידשדש": true,
"ידשנו": true,
"יהבהב": true,
"יהבכם": true,
"יהבכן": true,
"יהבנו": true,
"יהגרו": true,
"יהדהד": true,
"יהדוף": true,
"יהדות": true,
"יהדיר": true,
"יהדנה": true,
"יהדסו": true,
"יהדפו": true,
"יהדקו": true,
"יהדרו": true,
"יהואר": true,
"יהואש": true,
"יהודה": true,
"יהודי": true,
"יהודק": true,
"יהודר": true,
"יהווה": true,
"יהוון": true,
"יהולל": true,
"יהומם": true,
"יהוסה": true,
"יהוסו": true,
"יהורם": true,
"יהושע": true,
"יהינו": true,
"יהירה": true,
"יהלום": true,
"יהלכו": true,
"יהללו": true,
"יהלמו": true,
"יהמגן": true,
"יהמהם": true,
"יהממו": true,
"יהמרו": true,
"יהנדס": true,
"יהנהן": true,
"יהנום": true,
"יהססו": true,
"יהפוך": true,
"יהפכו": true,
"יהפנט": true,
"יהרגו": true,
"יהרהר": true,
"יהרוג": true,
"יהרוס": true,
"יהרסו": true,
"יהרתה": true,
"יהרתו": true,
"יהרתי": true,
"יהרתך": true,
"יהרתם": true,
"יהרתן": true,
"יהתלו": true,
"יואבס": true,
"יואבק": true,
"יואדר": true,
"יואחד": true,
"יואטו": true,
"יואיל": true,
"יואכל": true,
"יואמר": true,
"יואפל": true,
"יואצו": true,
"יואצל": true,
"יוארו": true,
"יוארך": true,
"יוארק": true,
"יואשם": true,
"יובאה": true,
"יובאו": true,
"יובאש": true,
"יובאת": true,
"יובדל": true,
"יובהל": true,
"יובהר": true,
"יובזק": true,
"יובחל": true,
"יובחן": true,
"יובטח": true,
"יוביל": true,
"יובכו": true,
"יובלג": true,
"יובלה": true,
"יובלו": true,
"יובלט": true,
"יובלי": true,
"יובלך": true,
"יובלם": true,
"יובלן": true,
"יובלע": true,
"יובנה": true,
"יובנו": true,
"יובנק": true,
"יובסו": true,
"יובעו": true,
"יובער": true,
"יובקע": true,
"יוברג": true,
"יוברז": true,
"יוברח": true,
"יוברק": true,
"יוברר": true,
"יוברש": true,
"יובשה": true,
"יובשו": true,
"יובשי": true,
"יובשך": true,
"יובשם": true,
"יובשן": true,
"יובשת": true,
"יוגבה": true,
"יוגבו": true,
"יוגבי": true,
"יוגבך": true,
"יוגבל": true,
"יוגבם": true,
"יוגבן": true,
"יוגבר": true,
"יוגדו": true,
"יוגדל": true,
"יוגדר": true,
"יוגדש": true,
"יוגהו": true,
"יוגזם": true,
"יוגין": true,
"יוגיע": true,
"יוגלה": true,
"יוגלו": true,
"יוגלש": true,
"יוגמש": true,
"יוגנב": true,
"יוגנו": true,
"יוגעל": true,
"יוגפו": true,
"יוגרו": true,
"יוגרל": true,
"יוגשו": true,
"יוגשם": true,
"יודאג": true,
"יודבק": true,
"יודבר": true,
"יודגם": true,
"יודגר": true,
"יודגש": true,
"יודהר": true,
"יודחו": true,
"יודחק": true,
"יודיע": true,
"יודלף": true,
"יודלק": true,
"יודמם": true,
"יודעה": true,
"יודעו": true,
"יודעי": true,
"יודעת": true,
"יודפס": true,
"יודפת": true,
"יודרו": true,
"יודרך": true,
"יוהאן": true,
"יוהדה": true,
"יוהדו": true,
"יוהדר": true,
"יוהדת": true,
"יוהרה": true,
"יוואן": true,
"יוודא": true,
"יוודה": true,
"יוודו": true,
"יוונה": true,
"יוונו": true,
"יווני": true,
"יווסת": true,
"יוותר": true,
"יוזהב": true,
"יוזהר": true,
"יוזזו": true,
"יוזחו": true,
"יוזיל": true,
"יוזכר": true,
"יוזלו": true,
"יוזלף": true,
"יוזמה": true,
"יוזמו": true,
"יוזמי": true,
"יוזמך": true,
"יוזמם": true,
"יוזמן": true,
"יוזמת": true,
"יוזנו": true,
"יוזנח": true,
"יוזנק": true,
"יוזעה": true,
"יוזעו": true,
"יוזעק": true,
"יוזעת": true,
"יוזקף": true,
"יוזרם": true,
"יוזרע": true,
"יוזרק": true,
"יוחאי": true,
"יוחבא": true,
"יוחדה": true,
"יוחדו": true,
"יוחדר": true,
"יוחדת": true,
"יוחזק": true,
"יוחזר": true,
"יוחטא": true,
"יוחיה": true,
"יוחיו": true,
"יוחיל": true,
"יוחכר": true,
"יוחלה": true,
"יוחלו": true,
"יוחלט": true,
"יוחלף": true,
"יוחלק": true,
"יוחלש": true,
"יוחלת": true,
"יוחמא": true,
"יוחמה": true,
"יוחמו": true,
"יוחמץ": true,
"יוחמר": true,
"יוחמת": true,
"יוחנה": true,
"יוחנו": true,
"יוחנן": true,
"יוחנק": true,
"יוחסה": true,
"יוחסו": true,
"יוחסר": true,
"יוחסת": true,
"יוחצן": true,
"יוחרב": true,
"יוחרג": true,
"יוחרד": true,
"יוחרם": true,
"יוחרף": true,
"יוחרש": true,
"יוחשד": true,
"יוחשו": true,
"יוחשך": true,
"יוחתם": true,
"יוטבו": true,
"יוטבל": true,
"יוטבע": true,
"יוטחו": true,
"יוטים": true,
"יוטלא": true,
"יוטלו": true,
"יוטמן": true,
"יוטמע": true,
"יוטסו": true,
"יוטעה": true,
"יוטעו": true,
"יוטעם": true,
"יוטען": true,
"יוטפו": true,
"יוטרד": true,
"יוטרח": true,
"יוכבד": true,
"יוכהה": true,
"יוכהו": true,
"יוכחד": true,
"יוכחו": true,
"יוכחש": true,
"יוכיח": true,
"יוכלב": true,
"יוכלו": true,
"יוכלל": true,
"יוכלם": true,
"יוכמן": true,
"יוכנו": true,
"יוכנס": true,
"יוכנע": true,
"יוכסף": true,
"יוכעס": true,
"יוכפז": true,
"יוכפל": true,
"יוכפף": true,
"יוכפש": true,
"יוכרו": true,
"יוכרז": true,
"יוכרח": true,
"יוכרע": true,
"יוכרת": true,
"יוכשו": true,
"יוכשל": true,
"יוכשר": true,
"יוכתב": true,
"יוכתם": true,
"יוכתר": true,
"יולאם": true,
"יולבן": true,
"יולבש": true,
"יולדה": true,
"יולדו": true,
"יולדת": true,
"יולהב": true,
"יולהט": true,
"יולוו": true,
"יולחם": true,
"יולחן": true,
"יולחץ": true,
"יולטו": true,
"יוליד": true,
"יוליה": true,
"יוליך": true,
"יוליס": true,
"יולכו": true,
"יולנו": true,
"יולעט": true,
"יולקה": true,
"יולקו": true,
"יומון": true,
"יומחז": true,
"יומחש": true,
"יומטו": true,
"יומטר": true,
"יומין": true,
"יומית": true,
"יומכם": true,
"יומכן": true,
"יומלח": true,
"יומלט": true,
"יומלך": true,
"יומלץ": true,
"יומנה": true,
"יומנו": true,
"יומני": true,
"יומנך": true,
"יומנם": true,
"יומנן": true,
"יומסו": true,
"יומעד": true,
"יומעט": true,
"יומצא": true,
"יומרד": true,
"יומרה": true,
"יומרו": true,
"יומרץ": true,
"יומשל": true,
"יומתו": true,
"יומתק": true,
"יונאו": true,
"יונבט": true,
"יונהג": true,
"יונות": true,
"יונחה": true,
"יונחו": true,
"יונחל": true,
"יונחת": true,
"יוניה": true,
"יוניו": true,
"יוניי": true,
"יוניך": true,
"יונים": true,
"יונית": true,
"יונמך": true,
"יוננה": true,
"יוננו": true,
"יוננת": true,
"יונסו": true,
"יונעו": true,
"יונעם": true,
"יונפו": true,
"יונפק": true,
"יונפש": true,
"יונצח": true,
"יונקו": true,
"יונקת": true,
"יונשם": true,
"יונתה": true,
"יונתו": true,
"יונתי": true,
"יונתך": true,
"יונתם": true,
"יונתן": true,
"יוסבו": true,
"יוסבר": true,
"יוסגו": true,
"יוסגר": true,
"יוסדה": true,
"יוסדו": true,
"יוסדר": true,
"יוסדת": true,
"יוסוו": true,
"יוסוף": true,
"יוסחו": true,
"יוסטה": true,
"יוסטו": true,
"יוסיף": true,
"יוסכם": true,
"יוסלם": true,
"יוסלק": true,
"יוסמך": true,
"יוסעו": true,
"יוסער": true,
"יוספג": true,
"יוספד": true,
"יוספה": true,
"יוספו": true,
"יוספי": true,
"יוספת": true,
"יוסקה": true,
"יוסקו": true,
"יוסרה": true,
"יוסרו": true,
"יוסרט": true,
"יוסרת": true,
"יוסתו": true,
"יוסתר": true,
"יועבד": true,
"יועבו": true,
"יועבר": true,
"יועדה": true,
"יועדו": true,
"יועדף": true,
"יועדת": true,
"יועזב": true,
"יועיד": true,
"יועיל": true,
"יועלה": true,
"יועלו": true,
"יועלם": true,
"יועלת": true,
"יועמד": true,
"יועמו": true,
"יועמס": true,
"יועמק": true,
"יועמש": true,
"יוענק": true,
"יוענש": true,
"יועסק": true,
"יועפו": true,
"יועצב": true,
"יועצה": true,
"יועצו": true,
"יועצי": true,
"יועצך": true,
"יועצם": true,
"יועצן": true,
"יועצת": true,
"יוערה": true,
"יוערו": true,
"יוערך": true,
"יוערם": true,
"יוערת": true,
"יועשר": true,
"יועתק": true,
"יועתר": true,
"יופגו": true,
"יופגז": true,
"יופגן": true,
"יופגש": true,
"יופחד": true,
"יופחו": true,
"יופחת": true,
"יופטר": true,
"יופיה": true,
"יופיו": true,
"יופיי": true,
"יופיע": true,
"יופית": true,
"יופלג": true,
"יופלה": true,
"יופלו": true,
"יופלט": true,
"יופלל": true,
"יופנה": true,
"יופנו": true,
"יופנם": true,
"יופסו": true,
"יופסק": true,
"יופעל": true,
"יופעם": true,
"יופצו": true,
"יופצץ": true,
"יופקד": true,
"יופקו": true,
"יופקע": true,
"יופקר": true,
"יופרד": true,
"יופרה": true,
"יופרו": true,
"יופרז": true,
"יופרח": true,
"יופרט": true,
"יופרך": true,
"יופרע": true,
"יופרש": true,
"יופשט": true,
"יופשל": true,
"יופשר": true,
"יופתה": true,
"יופתע": true,
"יוצאה": true,
"יוצאו": true,
"יוצאי": true,
"יוצאת": true,
"יוצבה": true,
"יוצבו": true,
"יוצבע": true,
"יוצבת": true,
"יוצגה": true,
"יוצגו": true,
"יוצגת": true,
"יוצדק": true,
"יוצהב": true,
"יוצהר": true,
"יוציא": true,
"יוצלב": true,
"יוצלו": true,
"יוצלח": true,
"יוצמד": true,
"יוצמת": true,
"יוצנח": true,
"יוצנע": true,
"יוצעד": true,
"יוצעו": true,
"יוצפו": true,
"יוצפן": true,
"יוצקת": true,
"יוצרה": true,
"יוצרו": true,
"יוצרי": true,
"יוצרך": true,
"יוצרם": true,
"יוצרן": true,
"יוצרת": true,
"יוצתו": true,
"יוקאו": true,
"יוקדח": true,
"יוקדם": true,
"יוקדש": true,
"יוקדת": true,
"יוקהה": true,
"יוקהו": true,
"יוקהל": true,
"יוקזו": true,
"יוקטן": true,
"יוקטר": true,
"יוקיע": true,
"יוקיר": true,
"יוקלד": true,
"יוקלו": true,
"יוקלט": true,
"יוקמו": true,
"יוקנה": true,
"יוקנו": true,
"יוקנט": true,
"יוקסם": true,
"יוקעו": true,
"יוקפא": true,
"יוקפד": true,
"יוקפו": true,
"יוקפץ": true,
"יוקצב": true,
"יוקצה": true,
"יוקצו": true,
"יוקצן": true,
"יוקצע": true,
"יוקצף": true,
"יוקרא": true,
"יוקרב": true,
"יוקרה": true,
"יוקרו": true,
"יוקרי": true,
"יוקרך": true,
"יוקרם": true,
"יוקרן": true,
"יוקרש": true,
"יוקשה": true,
"יוקשו": true,
"יוקשח": true,
"יוראה": true,
"יוראו": true,
"יורבע": true,
"יורגל": true,
"יורגש": true,
"יורדו": true,
"יורדם": true,
"יורדת": true,
"יורוו": true,
"יורות": true,
"יורחב": true,
"יורחק": true,
"יורטב": true,
"יורטה": true,
"יורטו": true,
"יורטת": true,
"יוריד": true,
"יורים": true,
"יוריק": true,
"יוריש": true,
"יורכב": true,
"יורכן": true,
"יורמו": true,
"יורעב": true,
"יורעד": true,
"יורעו": true,
"יורעל": true,
"יורעף": true,
"יורעש": true,
"יורצו": true,
"יורקד": true,
"יורקו": true,
"יורקי": true,
"יורקר": true,
"יורקת": true,
"יורשה": true,
"יורשו": true,
"יורשי": true,
"יורשך": true,
"יורשם": true,
"יורשן": true,
"יורשע": true,
"יורשת": true,
"יורתח": true,
"יורתע": true,
"יושאו": true,
"יושאל": true,
"יושאף": true,
"יושאר": true,
"יושבה": true,
"יושבו": true,
"יושבח": true,
"יושבי": true,
"יושבע": true,
"יושבת": true,
"יושגו": true,
"יושגח": true,
"יושהה": true,
"יושהו": true,
"יושוו": true,
"יושחז": true,
"יושחל": true,
"יושחם": true,
"יושחר": true,
"יושחת": true,
"יושטו": true,
"יושיב": true,
"יושיט": true,
"יושיע": true,
"יושכב": true,
"יושכח": true,
"יושכם": true,
"יושכן": true,
"יושכר": true,
"יושלה": true,
"יושלו": true,
"יושלט": true,
"יושלך": true,
"יושלם": true,
"יושמד": true,
"יושמה": true,
"יושמו": true,
"יושמט": true,
"יושמע": true,
"יושמץ": true,
"יושמש": true,
"יושמת": true,
"יושנה": true,
"יושנו": true,
"יושני": true,
"יושנך": true,
"יושנם": true,
"יושנן": true,
"יושנת": true,
"יושעה": true,
"יושעו": true,
"יושען": true,
"יושפל": true,
"יושפע": true,
"יושקה": true,
"יושקו": true,
"יושקט": true,
"יושקע": true,
"יושרה": true,
"יושרו": true,
"יושרי": true,
"יושרך": true,
"יושרם": true,
"יושרן": true,
"יושרץ": true,
"יושרש": true,
"יושרת": true,
"יושתו": true,
"יושתל": true,
"יושתן": true,
"יושתק": true,
"יושתת": true,
"יותאם": true,
"יותוו": true,
"יותזו": true,
"יותחל": true,
"יותיר": true,
"יותכו": true,
"יותמר": true,
"יותנה": true,
"יותנו": true,
"יותנע": true,
"יותסס": true,
"יותפח": true,
"יותפל": true,
"יותקל": true,
"יותקן": true,
"יותקף": true,
"יותרה": true,
"יותרו": true,
"יותרת": true,
"יותשו": true,
"יזבון": true,
"יזבחו": true,
"יזבלו": true,
"יזגגו": true,
"יזגזג": true,
"יזדהה": true,
"יזדהו": true,
"יזדהם": true,
"יזדחל": true,
"יזדכה": true,
"יזדכו": true,
"יזדכך": true,
"יזדמן": true,
"יזדמר": true,
"יזדנב": true,
"יזדעף": true,
"יזדעק": true,
"יזדקן": true,
"יזדקף": true,
"יזדקק": true,
"יזדקר": true,
"יזדרז": true,
"יזהיב": true,
"יזהיר": true,
"יזהמו": true,
"יזהרו": true,
"יזובו": true,
"יזובל": true,
"יזוגג": true,
"יזוהה": true,
"יזוהו": true,
"יזוהם": true,
"יזווג": true,
"יזווד": true,
"יזוזו": true,
"יזוין": true,
"יזויף": true,
"יזוכה": true,
"יזוכו": true,
"יזוכך": true,
"יזומה": true,
"יזומן": true,
"יזומר": true,
"יזועו": true,
"יזופר": true,
"יזופת": true,
"יזוקק": true,
"יזורז": true,
"יזחלו": true,
"יזידו": true,
"יזיזו": true,
"יזיחו": true,
"יזיין": true,
"יזייף": true,
"יזילו": true,
"יזינו": true,
"יזיעו": true,
"יזיקה": true,
"יזיקו": true,
"יזכור": true,
"יזכיר": true,
"יזככו": true,
"יזכרו": true,
"יזלגו": true,
"יזלוג": true,
"יזלול": true,
"יזלזל": true,
"יזליף": true,
"יזללו": true,
"יזמום": true,
"יזמור": true,
"יזמות": true,
"יזמזם": true,
"יזמיה": true,
"יזמיו": true,
"יזמיי": true,
"יזמיך": true,
"יזמים": true,
"יזמין": true,
"יזמית": true,
"יזמכם": true,
"יזמכן": true,
"יזממו": true,
"יזמנו": true,
"יזמרו": true,
"יזמתה": true,
"יזמתו": true,
"יזמתי": true,
"יזמתך": true,
"יזמתם": true,
"יזמתן": true,
"יזנבו": true,
"יזנחו": true,
"יזניח": true,
"יזניק": true,
"יזנקו": true,
"יזעזע": true,
"יזעים": true,
"יזעיף": true,
"יזעיק": true,
"יזעכם": true,
"יזעכן": true,
"יזעמו": true,
"יזענה": true,
"יזענו": true,
"יזעפו": true,
"יזעקו": true,
"יזפתו": true,
"יזציה": true,
"יזקוף": true,
"יזקור": true,
"יזקין": true,
"יזקיף": true,
"יזקנו": true,
"יזקפו": true,
"יזקקו": true,
"יזקרו": true,
"יזרום": true,
"יזרוק": true,
"יזרזו": true,
"יזרחו": true,
"יזרים": true,
"יזריע": true,
"יזריק": true,
"יזרמו": true,
"יזרעו": true,
"יזרעל": true,
"יזרעם": true,
"יזרקו": true,
"יחבבו": true,
"יחבוט": true,
"יחבול": true,
"יחבוץ": true,
"יחבוק": true,
"יחבור": true,
"יחבוש": true,
"יחבטו": true,
"יחביא": true,
"יחבלו": true,
"יחבצו": true,
"יחבקו": true,
"יחברו": true,
"יחבשו": true,
"יחגגו": true,
"יחגוג": true,
"יחגור": true,
"יחגרו": true,
"יחדדו": true,
"יחדור": true,
"יחדיו": true,
"יחדיר": true,
"יחדלו": true,
"יחדנה": true,
"יחדרו": true,
"יחדשו": true,
"יחובו": true,
"יחובל": true,
"יחובק": true,
"יחובר": true,
"יחוגו": true,
"יחודד": true,
"יחודו": true,
"יחודי": true,
"יחודש": true,
"יחווה": true,
"יחווט": true,
"יחוזק": true,
"יחוזר": true,
"יחוטא": true,
"יחוטב": true,
"יחויב": true,
"יחויג": true,
"יחויט": true,
"יחויל": true,
"יחולו": true,
"יחולט": true,
"יחולל": true,
"יחולץ": true,
"יחולק": true,
"יחומם": true,
"יחומש": true,
"יחונו": true,
"יחונך": true,
"יחונן": true,
"יחוסו": true,
"יחוסל": true,
"יחוסם": true,
"יחוסן": true,
"יחוסר": true,
"יחופף": true,
"יחופש": true,
"יחוקק": true,
"יחורר": true,
"יחושב": true,
"יחושו": true,
"יחושל": true,
"יחושק": true,
"יחותל": true,
"יחותן": true,
"יחזור": true,
"יחזיק": true,
"יחזיר": true,
"יחזקו": true,
"יחזרו": true,
"יחטאו": true,
"יחטבו": true,
"יחטוב": true,
"יחטוף": true,
"יחטטו": true,
"יחטיא": true,
"יחטיף": true,
"יחטפו": true,
"יחיאל": true,
"יחידה": true,
"יחידי": true,
"יחידת": true,
"יחייב": true,
"יחייג": true,
"יחייט": true,
"יחייך": true,
"יחייל": true,
"יחילו": true,
"יחיעם": true,
"יחישו": true,
"יחכוך": true,
"יחכור": true,
"יחכים": true,
"יחכיר": true,
"יחככו": true,
"יחכרו": true,
"יחלבו": true,
"יחלוב": true,
"יחלוט": true,
"יחלום": true,
"יחלוף": true,
"יחלוץ": true,
"יחלוק": true,
"יחלוש": true,
"יחלחל": true,
"יחלטו": true,
"יחליא": true,
"יחליד": true,
"יחליט": true,
"יחלים": true,
"יחליף": true,
"יחליק": true,
"יחליש": true,
"יחללו": true,
"יחלמו": true,
"יחלנה": true,
"יחלפו": true,
"יחלצו": true,
"יחלקו": true,
"יחלשו": true,
"יחמדו": true,
"יחמוד": true,
"יחמול": true,
"יחמוס": true,
"יחמוק": true,
"יחמור": true,
"יחמיא": true,
"יחמיץ": true,
"יחמיר": true,
"יחמלו": true,
"יחממו": true,
"יחמנה": true,
"יחמסו": true,
"יחמצן": true,
"יחמקו": true,
"יחמשו": true,
"יחנוט": true,
"יחנוך": true,
"יחנון": true,
"יחנוק": true,
"יחנטו": true,
"יחניף": true,
"יחניק": true,
"יחנכו": true,
"יחננו": true,
"יחנקו": true,
"יחסוך": true,
"יחסום": true,
"יחסון": true,
"יחסות": true,
"יחסיה": true,
"יחסיו": true,
"יחסיי": true,
"יחסיך": true,
"יחסים": true,
"יחסיר": true,
"יחסית": true,
"יחסכו": true,
"יחסכם": true,
"יחסכן": true,
"יחסלו": true,
"יחסמו": true,
"יחסנה": true,
"יחסנו": true,
"יחספס": true,
"יחסרו": true,
"יחסתה": true,
"יחסתו": true,
"יחסתי": true,
"יחסתך": true,
"יחסתם": true,
"יחסתן": true,
"יחפון": true,
"יחפוף": true,
"יחפור": true,
"יחפות": true,
"יחפים": true,
"יחפנה": true,
"יחפנו": true,
"יחפני": true,
"יחפנך": true,
"יחפנם": true,
"יחפנן": true,
"יחפפו": true,
"יחפצו": true,
"יחפרו": true,
"יחפשו": true,
"יחצבו": true,
"יחצוב": true,
"יחצון": true,
"יחצוץ": true,
"יחצין": true,
"יחציף": true,
"יחצנה": true,
"יחצנו": true,
"יחצני": true,
"יחצנך": true,
"יחצנם": true,
"יחצנן": true,
"יחצצו": true,
"יחצצר": true,
"יחקוק": true,
"יחקור": true,
"יחקקו": true,
"יחקרו": true,
"יחרבו": true,
"יחרבן": true,
"יחרגו": true,
"יחרדו": true,
"יחרוב": true,
"יחרוג": true,
"יחרוז": true,
"יחרוט": true,
"יחרוך": true,
"יחרוף": true,
"יחרוץ": true,
"יחרוק": true,
"יחרוש": true,
"יחרות": true,
"יחרזו": true,
"יחרחר": true,
"יחרטו": true,
"יחריב": true,
"יחריג": true,
"יחריד": true,
"יחרים": true,
"יחריף": true,
"יחריש": true,
"יחרכו": true,
"יחרמן": true,
"יחרפו": true,
"יחרצו": true,
"יחרקו": true,
"יחרשו": true,
"יחרתו": true,
"יחשבו": true,
"יחשדו": true,
"יחשוב": true,
"יחשוד": true,
"יחשוף": true,
"יחשוק": true,
"יחשוש": true,
"יחשיב": true,
"יחשיד": true,
"יחשיך": true,
"יחשכו": true,
"יחשלו": true,
"יחשמל": true,
"יחשפו": true,
"יחשקו": true,
"יחששו": true,
"יחתוך": true,
"יחתום": true,
"יחתור": true,
"יחתים": true,
"יחתכו": true,
"יחתלו": true,
"יחתמו": true,
"יחתנו": true,
"יחתרו": true,
"יטאות": true,
"יטאטא": true,
"יטבול": true,
"יטבחו": true,
"יטביל": true,
"יטביע": true,
"יטבלו": true,
"יטבעו": true,
"יטבתה": true,
"יטגנו": true,
"יטהרו": true,
"יטובע": true,
"יטוגן": true,
"יטוהר": true,
"יטווה": true,
"יטווח": true,
"יטוחו": true,
"יטויב": true,
"יטויח": true,
"יטויל": true,
"יטומא": true,
"יטונף": true,
"יטוסו": true,
"יטופח": true,
"יטופל": true,
"יטופס": true,
"יטופף": true,
"יטורי": true,
"יטזזו": true,
"יטחנו": true,
"יטיבו": true,
"יטיות": true,
"יטיחו": true,
"יטייב": true,
"יטייח": true,
"יטייל": true,
"יטילו": true,
"יטינג": true,
"יטיסו": true,
"יטיפו": true,
"יטלטל": true,
"יטליא": true,
"יטליה": true,
"יטלפן": true,
"יטמאו": true,
"יטמון": true,
"יטמטם": true,
"יטמין": true,
"יטמיע": true,
"יטמנו": true,
"יטמעו": true,
"יטנאם": true,
"יטנפו": true,
"יטעים": true,
"יטעין": true,
"יטעמו": true,
"יטענו": true,
"יטפול": true,
"יטפחו": true,
"יטפטף": true,
"יטפלו": true,
"יטפסו": true,
"יטרדו": true,
"יטרוד": true,
"יטרוף": true,
"יטרוק": true,
"יטרחו": true,
"יטרטר": true,
"יטריד": true,
"יטריח": true,
"יטריל": true,
"יטריף": true,
"יטרפד": true,
"יטרפו": true,
"יטרקו": true,
"יטשטש": true,
"ייאבק": true,
"ייאגר": true,
"ייאהב": true,
"ייאהד": true,
"ייאור": true,
"ייאוש": true,
"ייאות": true,
"ייאזק": true,
"ייאזר": true,
"ייאחז": true,
"ייאטם": true,
"ייאכל": true,
"ייאכף": true,
"ייאלם": true,
"ייאלץ": true,
"ייאמד": true,
"ייאמן": true,
"ייאמר": true,
"ייאנח": true,
"ייאנס": true,
"ייאנק": true,
"ייאסף": true,
"ייאסר": true,
"ייאפה": true,
"ייאפו": true,
"ייאצר": true,
"ייארג": true,
"ייארז": true,
"ייאשה": true,
"ייאשו": true,
"ייאשם": true,
"ייאשת": true,
"ייבאה": true,
"ייבאו": true,
"ייבאש": true,
"ייבאת": true,
"ייבבה": true,
"ייבבו": true,
"ייבבת": true,
"ייבגד": true,
"ייבדל": true,
"ייבדק": true,
"ייבהל": true,
"ייבוא": true,
"ייבוך": true,
"ייבול": true,
"ייבום": true,
"ייבוש": true,
"ייבזז": true,
"ייבזק": true,
"ייבחן": true,
"ייבחר": true,
"ייבחש": true,
"ייבלו": true,
"ייבלל": true,
"ייבלם": true,
"ייבלע": true,
"ייבמה": true,
"ייבמו": true,
"ייבמת": true,
"ייבנה": true,
"ייבנו": true,
"ייבעה": true,
"ייבעו": true,
"ייבעט": true,
"ייבעל": true,
"ייבעת": true,
"ייבצע": true,
"ייבצר": true,
"ייבקע": true,
"ייברא": true,
"ייבשה": true,
"ייבשו": true,
"ייבשת": true,
"ייגאל": true,
"ייגבה": true,
"ייגבו": true,
"ייגדע": true,
"ייגהו": true,
"ייגוז": true,
"ייגול": true,
"ייגזז": true,
"ייגזל": true,
"ייגזם": true,
"ייגזר": true,
"ייגחו": true,
"ייגלה": true,
"ייגלו": true,
"ייגלל": true,
"ייגמל": true,
"ייגמע": true,
"ייגמר": true,
"ייגנב": true,
"ייגנז": true,
"ייגנר": true,
"ייגעה": true,
"ייגעו": true,
"ייגעי": true,
"ייגעל": true,
"ייגעת": true,
"ייגרב": true,
"ייגרם": true,
"ייגרס": true,
"ייגרע": true,
"ייגרף": true,
"ייגרר": true,
"ייגשו": true,
"יידבק": true,
"יידבר": true,
"יידגם": true,
"יידהם": true,
"יידוי": true,
"יידום": true,
"יידון": true,
"יידוע": true,
"יידוף": true,
"יידור": true,
"יידחה": true,
"יידחו": true,
"יידחס": true,
"יידחף": true,
"יידחק": true,
"יידיש": true,
"יידית": true,
"יידלה": true,
"יידלו": true,
"יידלל": true,
"יידלק": true,
"יידמה": true,
"יידמו": true,
"יידעה": true,
"יידעו": true,
"יידעת": true,
"יידפו": true,
"יידפס": true,
"יידפק": true,
"יידקר": true,
"יידרו": true,
"יידרך": true,
"יידרס": true,
"יידרש": true,
"יידתה": true,
"ייהגה": true,
"ייהגו": true,
"ייהדה": true,
"ייהדו": true,
"ייהדף": true,
"ייהדת": true,
"ייהוד": true,
"ייהנה": true,
"ייהנו": true,
"ייהפך": true,
"ייהרג": true,
"ייהרס": true,
"ייובא": true,
"ייובש": true,
"ייודע": true,
"ייוהד": true,
"ייוון": true,
"ייוזע": true,
"ייוחד": true,
"ייוחל": true,
"ייוחם": true,
"ייוחס": true,
"ייולד": true,
"ייונן": true,
"ייוסד": true,
"ייוסף": true,
"ייוסר": true,
"ייועד": true,
"ייועל": true,
"ייוער": true,
"ייופה": true,
"ייופו": true,
"ייוצא": true,
"ייוצב": true,
"ייוצג": true,
"ייוצר": true,
"ייורט": true,
"ייושב": true,
"ייושם": true,
"ייושן": true,
"ייושר": true,
"ייותר": true,
"ייזבח": true,
"ייזהר": true,
"ייזום": true,
"ייזון": true,
"ייזוע": true,
"ייזכר": true,
"ייזלו": true,
"ייזלל": true,
"ייזמה": true,
"ייזמו": true,
"ייזמר": true,
"ייזנח": true,
"ייזעה": true,
"ייזעו": true,
"ייזעק": true,
"ייזעת": true,
"ייזקף": true,
"ייזקק": true,
"ייזרע": true,
"ייזרק": true,
"ייחבא": true,
"ייחבט": true,
"ייחבל": true,
"ייחבש": true,
"ייחגג": true,
"ייחגר": true,
"ייחדה": true,
"ייחדו": true,
"ייחדת": true,
"ייחוג": true,
"ייחוד": true,
"ייחוו": true,
"ייחול": true,
"ייחום": true,
"ייחוס": true,
"ייחור": true,
"ייחזה": true,
"ייחזו": true,
"ייחטף": true,
"ייחכר": true,
"ייחלב": true,
"ייחלה": true,
"ייחלו": true,
"ייחלם": true,
"ייחלץ": true,
"ייחלק": true,
"ייחלש": true,
"ייחלת": true,
"ייחמד": true,
"ייחמה": true,
"ייחמו": true,
"ייחמץ": true,
"ייחמת": true,
"ייחנט": true,
"ייחנך": true,
"ייחנק": true,
"ייחסה": true,
"ייחסו": true,
"ייחסך": true,
"ייחסם": true,
"ייחסת": true,
"ייחפז": true,
"ייחפף": true,
"ייחפר": true,
"ייחצב": true,
"ייחצה": true,
"ייחצו": true,
"ייחצן": true,
"ייחצץ": true,
"ייחקק": true,
"ייחקר": true,
"ייחרב": true,
"ייחרד": true,
"ייחרט": true,
"ייחרך": true,
"ייחרץ": true,
"ייחרש": true,
"ייחרת": true,
"ייחשב": true,
"ייחשד": true,
"ייחשף": true,
"ייחשק": true,
"ייחתך": true,
"ייחתם": true,
"ייטבו": true,
"ייטבח": true,
"ייטבל": true,
"ייטבע": true,
"ייטהר": true,
"ייטוו": true,
"ייטול": true,
"ייטור": true,
"ייטוש": true,
"ייטחן": true,
"ייטיב": true,
"ייטלו": true,
"ייטמא": true,
"ייטמן": true,
"ייטמע": true,
"ייטעו": true,
"ייטעם": true,
"ייטען": true,
"ייטפל": true,
"ייטרד": true,
"ייטרו": true,
"ייטרף": true,
"ייטרק": true,
"ייטשו": true,
"ייכבה": true,
"ייכבו": true,
"ייכבל": true,
"ייכבש": true,
"ייכוו": true,
"ייכון": true,
"ייכזב": true,
"ייכחד": true,
"ייכחו": true,
"ייכלא": true,
"ייכלל": true,
"ייכלם": true,
"ייכמר": true,
"ייכנס": true,
"ייכנע": true,
"ייכסס": true,
"ייכסף": true,
"ייכפה": true,
"ייכפו": true,
"ייכפל": true,
"ייכפת": true,
"ייכרה": true,
"ייכרו": true,
"ייכרך": true,
"ייכרת": true,
"ייכשל": true,
"ייכתב": true,
"ייכתש": true,
"יילאה": true,
"יילאו": true,
"יילבל": true,
"יילבש": true,
"יילגם": true,
"יילדה": true,
"יילדו": true,
"יילדת": true,
"יילוד": true,
"יילוו": true,
"יילחם": true,
"יילחץ": true,
"יילחש": true,
"יילכד": true,
"ייללה": true,
"ייללו": true,
"ייללת": true,
"יילמד": true,
"יילעס": true,
"יילפת": true,
"יילקח": true,
"יימאס": true,
"יימדד": true,
"יימהל": true,
"יימוג": true,
"יימוח": true,
"יימול": true,
"יימזג": true,
"יימחא": true,
"יימחה": true,
"יימחו": true,
"יימחל": true,
"יימחץ": true,
"יימחק": true,
"יימכר": true,
"יימלא": true,
"יימלט": true,
"יימלך": true,
"יימלק": true,
"יימנה": true,
"יימנו": true,
"יימנע": true,
"יימסו": true,
"יימסך": true,
"יימסק": true,
"יימסר": true,
"יימעך": true,
"יימען": true,
"יימצא": true,
"יימצץ": true,
"יימקו": true,
"יימרח": true,
"יימרט": true,
"יימשה": true,
"יימשו": true,
"יימשח": true,
"יימשך": true,
"יימשל": true,
"יימתח": true,
"יינאם": true,
"יינבט": true,
"יינגח": true,
"יינגס": true,
"יינגע": true,
"יינגף": true,
"יינגר": true,
"יינון": true,
"יינות": true,
"יינזף": true,
"יינזק": true,
"יינזר": true,
"יינחם": true,
"יינחת": true,
"יינטל": true,
"יינטע": true,
"יינטש": true,
"ייניק": true,
"יינכם": true,
"יינכן": true,
"ייננה": true,
"ייננו": true,
"יינני": true,
"ייננך": true,
"ייננם": true,
"ייננן": true,
"ייננת": true,
"יינעל": true,
"יינעץ": true,
"יינצל": true,
"יינצר": true,
"יינקה": true,
"יינקו": true,
"יינקט": true,
"יינקי": true,
"יינקם": true,
"יינקן": true,
"יינקע": true,
"יינשא": true,
"יינשך": true,
"יינשף": true,
"יינתז": true,
"יינתך": true,
"יינתן": true,
"יינתק": true,
"ייסבו": true,
"ייסבל": true,
"ייסגר": true,
"ייסדה": true,
"ייסדו": true,
"ייסדק": true,
"ייסדת": true,
"ייסוג": true,
"ייסוד": true,
"ייסון": true,
"ייסוף": true,
"ייסור": true,
"ייסחב": true,
"ייסחט": true,
"ייסחף": true,
"ייסחר": true,
"ייסכם": true,
"ייסכר": true,
"ייסלח": true,
"ייסלל": true,
"ייסמך": true,
"ייסעו": true,
"ייספג": true,
"ייספה": true,
"ייספו": true,
"ייספח": true,
"ייספר": true,
"ייספת": true,
"ייסקל": true,
"ייסקר": true,
"ייסרג": true,
"ייסרה": true,
"ייסרו": true,
"ייסרק": true,
"ייסרת": true,
"ייסתם": true,
"ייסתר": true,
"ייעבר": true,
"ייעגן": true,
"ייעדה": true,
"ייעדו": true,
"ייעדר": true,
"ייעדת": true,
"ייעוד": true,
"ייעול": true,
"ייעוץ": true,
"ייעור": true,
"ייעזב": true,
"ייעזר": true,
"ייעטף": true,
"ייעכר": true,
"ייעלב": true,
"ייעלה": true,
"ייעלו": true,
"ייעלם": true,
"ייעלת": true,
"ייעמד": true,
"ייענב": true,
"ייענד": true,
"ייענה": true,
"ייענו": true,
"ייענש": true,
"ייעצב": true,
"ייעצה": true,
"ייעצו": true,
"ייעצם": true,
"ייעצר": true,
"ייעצת": true,
"ייעקד": true,
"ייעקף": true,
"ייעקץ": true,
"ייעקר": true,
"ייערה": true,
"ייערו": true,
"ייערך": true,
"ייערם": true,
"ייערף": true,
"ייערץ": true,
"ייערת": true,
"ייעשה": true,
"ייעשו": true,
"ייעשק": true,
"ייעתק": true,
"ייעתר": true,
"ייפגם": true,
"ייפגע": true,
"ייפגש": true,
"ייפדה": true,
"ייפדו": true,
"ייפוי": true,
"ייפול": true,
"ייפוץ": true,
"ייפחו": true,
"ייפטר": true,
"ייפית": true,
"ייפלא": true,
"ייפלו": true,
"ייפלט": true,
"ייפנה": true,
"ייפנו": true,
"ייפסל": true,
"ייפסק": true,
"ייפעם": true,
"ייפער": true,
"ייפצע": true,
"ייפקד": true,
"ייפקח": true,
"ייפרד": true,
"ייפרט": true,
"ייפרם": true,
"ייפרס": true,
"ייפרע": true,
"ייפרץ": true,
"ייפרק": true,
"ייפרש": true,
"ייפתה": true,
"ייפתח": true,
"ייפתר": true,
"ייצאה": true,
"ייצאו": true,
"ייצאת": true,
"ייצבה": true,
"ייצבו": true,
"ייצבט": true,
"ייצבע": true,
"ייצבר": true,
"ייצבת": true,
"ייצגה": true,
"ייצגו": true,
"ייצגת": true,
"ייצוא": true,
"ייצוב": true,
"ייצוג": true,
"ייצוד": true,
"ייצוק": true,
"ייצור": true,
"ייציג": true,
"ייצלב": true,
"ייצלה": true,
"ייצלו": true,
"ייצמד": true,
"ייצמת": true,
"ייצפה": true,
"ייצפו": true,
"ייצפר": true,
"ייצקו": true,
"ייצרב": true,
"ייצרד": true,
"ייצרה": true,
"ייצרו": true,
"ייצרך": true,
"ייצרר": true,
"ייצרת": true,
"ייקבו": true,
"ייקבע": true,
"ייקבץ": true,
"ייקבר": true,
"ייקדו": true,
"ייקדח": true,
"ייקהל": true,
"ייקוב": true,
"ייקוד": true,
"ייקוו": true,
"ייקום": true,
"ייקור": true,
"ייקחו": true,
"ייקטל": true,
"ייקטם": true,
"ייקטע": true,
"ייקטף": true,
"ייקלה": true,
"ייקלו": true,
"ייקלט": true,
"ייקלע": true,
"ייקמו": true,
"ייקנה": true,
"ייקנו": true,
"ייקנס": true,
"ייקצב": true,
"ייקצץ": true,
"ייקצר": true,
"ייקרא": true,
"ייקרה": true,
"ייקרו": true,
"ייקרע": true,
"ייקרש": true,
"ייקרת": true,
"ייקשר": true,
"ייראה": true,
"ייראו": true,
"יירגם": true,
"יירגע": true,
"יירדם": true,
"יירדף": true,
"יירוט": true,
"יירחץ": true,
"יירטב": true,
"יירטה": true,
"יירטו": true,
"יירטט": true,
"יירטת": true,
"יירכס": true,
"יירכש": true,
"יירמז": true,
"יירמס": true,
"יירעש": true,
"יירפא": true,
"יירצה": true,
"יירצו": true,
"יירצח": true,
"יירצץ": true,
"יירקב": true,
"יירקו": true,
"יירקח": true,
"יירקי": true,
"יירקם": true,
"יירשו": true,
"יירשי": true,
"יירשם": true,
"יירתם": true,
"יירתע": true,
"יישאב": true,
"יישאג": true,
"יישאו": true,
"יישאל": true,
"יישאף": true,
"יישאר": true,
"יישבה": true,
"יישבו": true,
"יישבע": true,
"יישבר": true,
"יישבת": true,
"יישגב": true,
"יישדד": true,
"יישוב": true,
"יישוך": true,
"יישום": true,
"יישון": true,
"יישור": true,
"יישזר": true,
"יישחט": true,
"יישחק": true,
"יישטף": true,
"יישיר": true,
"יישכב": true,
"יישכו": true,
"יישכח": true,
"יישכר": true,
"יישלה": true,
"יישלו": true,
"יישלח": true,
"יישלט": true,
"יישלל": true,
"יישלם": true,
"יישלף": true,
"יישלק": true,
"יישמד": true,
"יישמה": true,
"יישמו": true,
"יישמט": true,
"יישמע": true,
"יישמר": true,
"יישמת": true,
"יישנה": true,
"יישנו": true,
"יישנק": true,
"יישנת": true,
"יישען": true,
"יישפט": true,
"יישפך": true,
"יישקו": true,
"יישקל": true,
"יישקף": true,
"יישרה": true,
"יישרו": true,
"יישרט": true,
"יישרך": true,
"יישרף": true,
"יישרק": true,
"יישרת": true,
"יישתל": true,
"ייתבע": true,
"ייתום": true,
"ייתור": true,
"ייתחב": true,
"ייתכן": true,
"ייתלה": true,
"ייתלו": true,
"ייתלש": true,
"ייתמו": true,
"ייתמך": true,
"ייתמם": true,
"ייתמר": true,
"ייתנו": true,
"ייתפס": true,
"ייתפר": true,
"ייתפש": true,
"ייתקל": true,
"ייתקע": true,
"ייתקף": true,
"ייתרה": true,
"ייתרו": true,
"ייתרם": true,
"ייתרת": true,
"ייתשו": true,
"יכאבו": true,
"יכאיב": true,
"יכבדו": true,
"יכבול": true,
"יכבוש": true,
"יכביד": true,
"יכביר": true,
"יכבלו": true,
"יכבסו": true,
"יכבשו": true,
"יכדרר": true,
"יכהנו": true,
"יכובד": true,
"יכובה": true,
"יכובו": true,
"יכובס": true,
"יכוון": true,
"יכווץ": true,
"יכויי": true,
"יכויל": true,
"יכויס": true,
"יכויר": true,
"יכולה": true,
"יכולו": true,
"יכולת": true,
"יכונה": true,
"יכונו": true,
"יכונן": true,
"יכונס": true,
"יכוסה": true,
"יכוסו": true,
"יכוסח": true,
"יכוער": true,
"יכופף": true,
"יכופר": true,
"יכושף": true,
"יכותב": true,
"יכותר": true,
"יכותת": true,
"יכזבו": true,
"יכזיב": true,
"יכחדו": true,
"יכחיד": true,
"יכחיל": true,
"יכחיש": true,
"יכחכח": true,
"יכחשו": true,
"יכטות": true,
"יכטתה": true,
"יכטתו": true,
"יכטתי": true,
"יכטתך": true,
"יכטתם": true,
"יכטתן": true,
"יכיון": true,
"יכייל": true,
"יכייס": true,
"יכייף": true,
"יכייר": true,
"יכילו": true,
"יכינו": true,
"יכירו": true,
"יכישו": true,
"יככבו": true,
"יכלאו": true,
"יכלול": true,
"יכליא": true,
"יכליב": true,
"יכליל": true,
"יכלים": true,
"יכלכל": true,
"יכללו": true,
"יכמהו": true,
"יכמוש": true,
"יכמין": true,
"יכמשו": true,
"יכנוס": true,
"יכניה": true,
"יכניס": true,
"יכניע": true,
"יכנסו": true,
"יכסוס": true,
"יכסחו": true,
"יכסיף": true,
"יכססו": true,
"יכספו": true,
"יכעיס": true,
"יכעסו": true,
"יכערו": true,
"יכפול": true,
"יכפוף": true,
"יכפור": true,
"יכפות": true,
"יכפיל": true,
"יכפיף": true,
"יכפיש": true,
"יכפלו": true,
"יכפפו": true,
"יכפרו": true,
"יכפתו": true,
"יכפתר": true,
"יכרבל": true,
"יכרוך": true,
"יכרון": true,
"יכרות": true,
"יכרטס": true,
"יכריז": true,
"יכריח": true,
"יכריע": true,
"יכרית": true,
"יכרכו": true,
"יכרכר": true,
"יכרסם": true,
"יכרעו": true,
"יכרתו": true,
"יכשהו": true,
"יכשול": true,
"יכשיל": true,
"יכשיר": true,
"יכשכש": true,
"יכשלו": true,
"יכשפו": true,
"יכתבו": true,
"יכתוב": true,
"יכתוש": true,
"יכתיב": true,
"יכתים": true,
"יכתיף": true,
"יכתיר": true,
"יכתרו": true,
"יכתשו": true,
"יכתתו": true,
"ילאטו": true,
"ילאים": true,
"ילאנו": true,
"ילבבו": true,
"ילבון": true,
"ילבין": true,
"ילביש": true,
"ילבלב": true,
"ילבנו": true,
"ילברג": true,
"ילבשו": true,
"ילגום": true,
"ילגלג": true,
"ילגמו": true,
"ילדון": true,
"ילדות": true,
"ילדיה": true,
"ילדיו": true,
"ילדיי": true,
"ילדיך": true,
"ילדים": true,
"ילדכם": true,
"ילדכן": true,
"ילדנה": true,
"ילדנו": true,
"ילדתה": true,
"ילדתו": true,
"ילדתי": true,
"ילדתך": true,
"ילדתם": true,
"ילדתן": true,
"ילהטו": true,
"ילהטט": true,
"ילהיב": true,
"ילהיט": true,
"ילהקו": true,
"ילובה": true,
"ילובו": true,
"ילובן": true,
"ילודה": true,
"ילודו": true,
"ילודי": true,
"ילודך": true,
"ילודם": true,
"ילודן": true,
"ילוהק": true,
"ילווה": true,
"ילווו": true,
"ילוחח": true,
"ילוחך": true,
"ילוטף": true,
"ילוטש": true,
"ילוכד": true,
"ילומה": true,
"ילומי": true,
"ילונו": true,
"ילופף": true,
"ילוקט": true,
"ילוקק": true,
"ילושו": true,
"ילחחו": true,
"ילחים": true,
"ילחין": true,
"ילחיץ": true,
"ילחכו": true,
"ילחלח": true,
"ילחמו": true,
"ילחצו": true,
"ילחשו": true,
"ילחשש": true,
"ילטון": true,
"ילטוש": true,
"ילטפו": true,
"ילטשו": true,
"ילידה": true,
"ילידו": true,
"ילידי": true,
"ילידך": true,
"ילידם": true,
"ילידן": true,
"ילידת": true,
"יליון": true,
"יליטו": true,
"ילינג": true,
"ילינו": true,
"יליסט": true,
"ילכדו": true,
"ילכוד": true,
"ילכלך": true,
"ילכסן": true,
"יללות": true,
"יללנה": true,
"יללני": true,
"יללתה": true,
"יללתו": true,
"יללתי": true,
"יללתך": true,
"יללתם": true,
"יללתן": true,
"ילמדו": true,
"ילנדי": true,
"ילעגו": true,
"ילעיג": true,
"ילעיז": true,
"ילעיט": true,
"ילעסו": true,
"ילפות": true,
"ילפפו": true,
"ילפתו": true,
"ילצין": true,
"ילקוט": true,
"ילקטו": true,
"ילקקו": true,
"ילרים": true,
"ילשין": true,
"ילתית": true,
"ימאות": true,
"ימאיו": true,
"ימאיי": true,
"ימאיך": true,
"ימאים": true,
"ימאיס": true,
"ימאית": true,
"ימאכם": true,
"ימאכן": true,
"ימאנו": true,
"ימאסו": true,
"ימגנו": true,
"ימגנט": true,
"ימגרו": true,
"ימדבר": true,
"ימדדו": true,
"ימדוד": true,
"ימדיה": true,
"ימדרו": true,
"ימהות": true,
"ימהלו": true,
"ימהרו": true,
"ימוגן": true,
"ימוגר": true,
"ימודר": true,
"ימוזג": true,
"ימוטט": true,
"ימוין": true,
"ימוכן": true,
"ימולא": true,
"ימולו": true,
"ימולל": true,
"ימומן": true,
"ימומש": true,
"ימונד": true,
"ימונה": true,
"ימונו": true,
"ימוני": true,
"ימונף": true,
"ימוסד": true,
"ימוסה": true,
"ימוסו": true,
"ימוסך": true,
"ימוסס": true,
"ימוען": true,
"ימופה": true,
"ימופו": true,
"ימוצה": true,
"ימוצו": true,
"ימוצע": true,
"ימוקד": true,
"ימוקם": true,
"ימוקש": true,
"ימורק": true,
"ימושו": true,
"ימושש": true,
"ימותג": true,
"ימותו": true,
"ימותן": true,
"ימזגו": true,
"ימזוג": true,
"ימזמז": true,
"ימזער": true,
"ימחאו": true,
"ימחזר": true,
"ימחיז": true,
"ימחיש": true,
"ימחלו": true,
"ימחצו": true,
"ימחקו": true,
"ימחשב": true,
"ימטיר": true,
"ימיהם": true,
"ימיהן": true,
"ימיות": true,
"ימיטו": true,
"ימייה": true,
"ימייך": true,
"ימייל": true,
"ימיים": true,
"ימיין": true,
"ימיכם": true,
"ימיכן": true,
"ימימה": true,
"ימינג": true,
"ימינה": true,
"ימינו": true,
"ימיני": true,
"ימינך": true,
"ימינם": true,
"ימינן": true,
"ימירו": true,
"ימיתו": true,
"ימכור": true,
"ימכנו": true,
"ימכרו": true,
"ימלאו": true,
"ימלוך": true,
"ימלוק": true,
"ימלטו": true,
"ימליח": true,
"ימליט": true,
"ימליך": true,
"ימליץ": true,
"ימלית": true,
"ימלכד": true,
"ימלכו": true,
"ימלמל": true,
"ימלצר": true,
"ימלקו": true,
"יממות": true,
"יממנו": true,
"יממשו": true,
"יממתה": true,
"יממתו": true,
"יממתי": true,
"יממתך": true,
"יממתם": true,
"יממתן": true,
"ימנות": true,
"ימנים": true,
"ימנית": true,
"ימנעו": true,
"ימנפו": true,
"ימנרט": true,
"ימסגר": true,
"ימסדו": true,
"ימסוך": true,
"ימסוק": true,
"ימסור": true,
"ימסחר": true,
"ימסטל": true,
"ימסכו": true,
"ימסמס": true,
"ימסמר": true,
"ימספר": true,
"ימסקו": true,
"ימסרו": true,
"ימעדו": true,
"ימעטו": true,
"ימעיד": true,
"ימעיט": true,
"ימעכו": true,
"ימעלו": true,
"ימענו": true,
"ימפיא": true,
"ימצאו": true,
"ימצוץ": true,
"ימציא": true,
"ימציה": true,
"ימצמץ": true,
"ימצעו": true,
"ימצצו": true,
"ימצקו": true,
"ימצקי": true,
"ימקדו": true,
"ימקמו": true,
"ימקשו": true,
"ימרדו": true,
"ימרוד": true,
"ימרוט": true,
"ימרחו": true,
"ימרטו": true,
"ימריא": true,
"ימריד": true,
"ימריז": true,
"ימרים": true,
"ימריץ": true,
"ימרכז": true,
"ימרפק": true,
"ימרקו": true,
"ימררו": true,
"ימשוך": true,
"ימשול": true,
"ימשחו": true,
"ימשיך": true,
"ימשיל": true,
"ימשכו": true,
"ימשכן": true,
"ימשלו": true,
"ימשמש": true,
"ימששו": true,
"ימתגו": true,
"ימתחו": true,
"ימתין": true,
"ימתיק": true,
"ימתכם": true,
"ימתכן": true,
"ימתנו": true,
"ינאמו": true,
"ינאפו": true,
"ינאצו": true,
"ינאקו": true,
"ינבאו": true,
"ינבוט": true,
"ינבור": true,
"ינבחו": true,
"ינבטו": true,
"ינביט": true,
"ינבלו": true,
"ינבעו": true,
"ינברג": true,
"ינברו": true,
"ינגבו": true,
"ינגדו": true,
"ינגוס": true,
"ינגחו": true,
"ינגיד": true,
"ינגים": true,
"ינגנו": true,
"ינגסו": true,
"ינגשו": true,
"ינדבו": true,
"ינדדו": true,
"ינדוד": true,
"ינדוס": true,
"ינדור": true,
"ינדנד": true,
"ינדרו": true,
"ינהגו": true,
"ינהיג": true,
"ינהלו": true,
"ינהמו": true,
"ינהרו": true,
"ינואה": true,
"ינואץ": true,
"ינואר": true,
"ינוגב": true,
"ינוגד": true,
"ינוגח": true,
"ינוגן": true,
"ינודב": true,
"ינודה": true,
"ינודו": true,
"ינוהג": true,
"ינוהל": true,
"ינווט": true,
"ינוון": true,
"ינוחו": true,
"ינוחם": true,
"ינוחש": true,
"ינוטר": true,
"ינויו": true,
"ינוכה": true,
"ינוכו": true,
"ינוכי": true,
"ינוכס": true,
"ינוכש": true,
"ינומו": true,
"ינומק": true,
"ינוסה": true,
"ינוסו": true,
"ינוסח": true,
"ינוסר": true,
"ינועו": true,
"ינוער": true,
"ינופה": true,
"ינופו": true,
"ינופח": true,
"ינופף": true,
"ינופץ": true,
"ינופק": true,
"ינוצח": true,
"ינוצל": true,
"ינוקא": true,
"ינוקב": true,
"ינוקד": true,
"ינוקה": true,
"ינוקו": true,
"ינוקז": true,
"ינוקר": true,
"ינושל": true,
"ינושק": true,
"ינותב": true,
"ינותח": true,
"ינותץ": true,
"ינותק": true,
"ינזון": true,
"ינזוף": true,
"ינזפו": true,
"ינחיל": true,
"ינחית": true,
"ינחלו": true,
"ינחמו": true,
"ינחרו": true,
"ינחשו": true,
"ינחתו": true,
"ינטוף": true,
"ינטוש": true,
"ינטים": true,
"ינטפו": true,
"ינטרו": true,
"ינטרל": true,
"ינטשו": true,
"יניאו": true,
"יניבו": true,
"ינידו": true,
"יניהם": true,
"יניון": true,
"יניות": true,
"יניחו": true,
"ינייד": true,
"יניים": true,
"יניכם": true,
"ינינו": true,
"יניסו": true,
"יניעו": true,
"יניפו": true,
"יניקה": true,
"יניקו": true,
"ינכסו": true,
"ינכרו": true,
"ינכשו": true,
"ינמוך": true,
"ינמון": true,
"ינמיך": true,
"ינמכו": true,
"ינמנם": true,
"ינמקו": true,
"יננית": true,
"ינסוך": true,
"ינסון": true,
"ינסוק": true,
"ינסחו": true,
"ינסכו": true,
"ינסקו": true,
"ינסקי": true,
"ינסרו": true,
"ינעים": true,
"ינעלו": true,
"ינעמו": true,
"ינענע": true,
"ינעצו": true,
"ינערו": true,
"ינפוש": true,
"ינפחו": true,
"ינפיק": true,
"ינפיש": true,
"ינפלד": true,
"ינפנף": true,
"ינפצו": true,
"ינפקו": true,
"ינפשו": true,
"ינצוץ": true,
"ינצור": true,
"ינצחו": true,
"ינציה": true,
"ינציח": true,
"ינציל": true,
"ינצלו": true,
"ינצנץ": true,
"ינצצו": true,
"ינצרו": true,
"ינקבו": true,
"ינקדו": true,
"ינקוט": true,
"ינקום": true,
"ינקוף": true,
"ינקור": true,
"ינקוש": true,
"ינקות": true,
"ינקזו": true,
"ינקטו": true,
"ינקנה": true,
"ינקנו": true,
"ינקעו": true,
"ינקפו": true,
"ינקרו": true,
"ינקשו": true,
"ינקתי": true,
"ינקתם": true,
"ינקתן": true,
"ינרים": true,
"ינרמל": true,
"ינשבו": true,
"ינשוך": true,
"ינשום": true,
"ינשוף": true,
"ינשור": true,
"ינשים": true,
"ינשכו": true,
"ינשלו": true,
"ינשמו": true,
"ינשנש": true,
"ינשפו": true,
"ינשקו": true,
"ינשרו": true,
"ינתבו": true,
"ינתון": true,
"ינתחו": true,
"ינתנו": true,
"ינתצו": true,
"ינתקו": true,
"ינתרו": true,
"ינתשו": true,
"יסאבו": true,
"יסאיס": true,
"יסאנו": true,
"יסבאו": true,
"יסבבו": true,
"יסבול": true,
"יסבוק": true,
"יסבור": true,
"יסביר": true,
"יסבכו": true,
"יסבלו": true,
"יסבנו": true,
"יסבסד": true,
"יסברו": true,
"יסגדו": true,
"יסגוד": true,
"יסגור": true,
"יסגיר": true,
"יסגלו": true,
"יסגנן": true,
"יסגרו": true,
"יסדוק": true,
"יסדיר": true,
"יסדנה": true,
"יסדנו": true,
"יסדקו": true,
"יסדרו": true,
"יסדתי": true,
"יסדתם": true,
"יסדתן": true,
"יסואב": true,
"יסובב": true,
"יסובו": true,
"יסובך": true,
"יסובן": true,
"יסוגל": true,
"יסוגר": true,
"יסודה": true,
"יסודו": true,
"יסודי": true,
"יסודך": true,
"יסודם": true,
"יסודן": true,
"יסודר": true,
"יסווג": true,
"יסווה": true,
"יסויג": true,
"יסויד": true,
"יסוים": true,
"יסוכו": true,
"יסוכך": true,
"יסוכל": true,
"יסוכם": true,
"יסולא": true,
"יסולח": true,
"יסולף": true,
"יסולק": true,
"יסומא": true,
"יסומל": true,
"יסומם": true,
"יסומן": true,
"יסומר": true,
"יסונן": true,
"יסונף": true,
"יסופח": true,
"יסופק": true,
"יסופר": true,
"יסוקר": true,
"יסורב": true,
"יסורו": true,
"יסורס": true,
"יסורק": true,
"יסותת": true,
"יסחבו": true,
"יסחטו": true,
"יסחפו": true,
"יסחרו": true,
"יסחרר": true,
"יסטור": true,
"יסטית": true,
"יסטרו": true,
"יסיגו": true,
"יסיחו": true,
"יסיטו": true,
"יסייג": true,
"יסייד": true,
"יסיים": true,
"יסייע": true,
"יסייר": true,
"יסיעו": true,
"יסיקה": true,
"יסיקו": true,
"יסירו": true,
"יסיתו": true,
"יסכוך": true,
"יסכום": true,
"יסכון": true,
"יסכור": true,
"יסכים": true,
"יסכין": true,
"יסכית": true,
"יסככו": true,
"יסכלו": true,
"יסכמו": true,
"יסכנו": true,
"יסכסך": true,
"יסכרו": true,
"יסלדו": true,
"יסלוד": true,
"יסלול": true,
"יסלחו": true,
"יסלים": true,
"יסליק": true,
"יסללו": true,
"יסלסל": true,
"יסלפו": true,
"יסלקו": true,
"יסמאו": true,
"יסמוך": true,
"יסמור": true,
"יסמיך": true,
"יסמין": true,
"יסמיק": true,
"יסמכו": true,
"יסמלו": true,
"יסמלץ": true,
"יסממו": true,
"יסמנו": true,
"יסמרו": true,
"יסנגר": true,
"יסנדל": true,
"יסנוט": true,
"יסנטו": true,
"יסניף": true,
"יסננו": true,
"יסנפו": true,
"יסנתז": true,
"יסעדו": true,
"יסעור": true,
"יסעיר": true,
"יסערו": true,
"יספגו": true,
"יספדו": true,
"יספוג": true,
"יספוד": true,
"יספוק": true,
"יספור": true,
"יספחו": true,
"יספיג": true,
"יספיד": true,
"יספיק": true,
"יספנה": true,
"יספסר": true,
"יספקו": true,
"יספרו": true,
"יספרר": true,
"יסקול": true,
"יסקור": true,
"יסקלו": true,
"יסקרו": true,
"יסקרן": true,
"יסרבו": true,
"יסרבל": true,
"יסרגו": true,
"יסרוג": true,
"יסרוק": true,
"יסרור": true,
"יסרחו": true,
"יסרטן": true,
"יסריה": true,
"יסריח": true,
"יסריט": true,
"יסרנה": true,
"יסרסו": true,
"יסרסר": true,
"יסרקו": true,
"יסררו": true,
"יסתאב": true,
"יסתבך": true,
"יסתבן": true,
"יסתבר": true,
"יסתגל": true,
"יסתגף": true,
"יסתגר": true,
"יסתדר": true,
"יסתוו": true,
"יסתום": true,
"יסתור": true,
"יסתיר": true,
"יסתכל": true,
"יסתכם": true,
"יסתכן": true,
"יסתלק": true,
"יסתמא": true,
"יסתמו": true,
"יסתמך": true,
"יסתמם": true,
"יסתמן": true,
"יסתמר": true,
"יסתנן": true,
"יסתעף": true,
"יסתער": true,
"יסתפח": true,
"יסתפק": true,
"יסתפר": true,
"יסתרו": true,
"יסתרק": true,
"יסתתו": true,
"יסתתם": true,
"יסתתר": true,
"יעבדו": true,
"יעבוד": true,
"יעבוט": true,
"יעבור": true,
"יעבטו": true,
"יעביד": true,
"יעביר": true,
"יעברו": true,
"יעגבו": true,
"יעגוב": true,
"יעגון": true,
"יעגלו": true,
"יעגנו": true,
"יעדור": true,
"יעדיה": true,
"יעדיו": true,
"יעדיי": true,
"יעדיך": true,
"יעדים": true,
"יעדיף": true,
"יעדכם": true,
"יעדכן": true,
"יעדנה": true,
"יעדנו": true,
"יעדרו": true,
"יעובד": true,
"יעובה": true,
"יעובו": true,
"יעובר": true,
"יעוגל": true,
"יעוגן": true,
"יעודד": true,
"יעודה": true,
"יעודי": true,
"יעודן": true,
"יעווה": true,
"יעוור": true,
"יעוות": true,
"יעוטו": true,
"יעוטר": true,
"יעוכב": true,
"יעוכל": true,
"יעולל": true,
"יעומד": true,
"יעומם": true,
"יעומת": true,
"יעונג": true,
"יעונה": true,
"יעונו": true,
"יעוסה": true,
"יעוסו": true,
"יעופו": true,
"יעופף": true,
"יעופש": true,
"יעוצב": true,
"יעוצו": true,
"יעוקל": true,
"יעוקם": true,
"יעוקר": true,
"יעורב": true,
"יעורי": true,
"יעורר": true,
"יעזבו": true,
"יעזוב": true,
"יעזור": true,
"יעזיב": true,
"יעזרו": true,
"יעטוף": true,
"יעטיר": true,
"יעטפו": true,
"יעטרו": true,
"יעיבו": true,
"יעידו": true,
"יעיהם": true,
"יעיהן": true,
"יעייך": true,
"יעיין": true,
"יעייף": true,
"יעיכם": true,
"יעיכן": true,
"יעילה": true,
"יעינו": true,
"יעיפו": true,
"יעיקו": true,
"יעירו": true,
"יעכבו": true,
"יעכור": true,
"יעכיר": true,
"יעכלו": true,
"יעכסו": true,
"יעכרו": true,
"יעלבו": true,
"יעלוב": true,
"יעלוז": true,
"יעלון": true,
"יעלוץ": true,
"יעלזו": true,
"יעליב": true,
"יעליה": true,
"יעליו": true,
"יעליי": true,
"יעליך": true,
"יעליל": true,
"יעלים": true,
"יעלכם": true,
"יעלכן": true,
"יעלנה": true,
"יעלנו": true,
"יעלעל": true,
"יעלצו": true,
"יעמדו": true,
"יעמוד": true,
"יעמול": true,
"יעמיד": true,
"יעמיס": true,
"יעמיק": true,
"יעמלו": true,
"יעמלן": true,
"יעממו": true,
"יעמעם": true,
"יעמתו": true,
"יענבו": true,
"יענגו": true,
"יענדו": true,
"יענוב": true,
"יענוד": true,
"יענטז": true,
"יעניה": true,
"יעניו": true,
"יעניי": true,
"יעניך": true,
"יענים": true,
"יעניק": true,
"יעניש": true,
"יענכם": true,
"יענכן": true,
"יעננו": true,
"יעסוק": true,
"יעסיק": true,
"יעסקו": true,
"יעפיל": true,
"יעפעף": true,
"יעפשו": true,
"יעפתה": true,
"יעפתו": true,
"יעפתי": true,
"יעפתך": true,
"יעפתם": true,
"יעפתן": true,
"יעצבב": true,
"יעצבו": true,
"יעצבן": true,
"יעצום": true,
"יעצור": true,
"יעציב": true,
"יעצים": true,
"יעצמו": true,
"יעצנה": true,
"יעצנו": true,
"יעצרו": true,
"יעצתי": true,
"יעצתם": true,
"יעצתן": true,
"יעקבו": true,
"יעקבי": true,
"יעקבס": true,
"יעקדו": true,
"יעקוב": true,
"יעקוד": true,
"יעקוף": true,
"יעקוץ": true,
"יעקור": true,
"יעקלו": true,
"יעקמו": true,
"יעקפו": true,
"יעקצו": true,
"יעקצץ": true,
"יעקרו": true,
"יערבב": true,
"יערבו": true,
"יערבל": true,
"יערגו": true,
"יערגל": true,
"יערוב": true,
"יערוג": true,
"יערוך": true,
"יערום": true,
"יערוף": true,
"יערוק": true,
"יערות": true,
"יערטל": true,
"יעריב": true,
"יעריך": true,
"יערים": true,
"יעריף": true,
"יעריץ": true,
"יערכו": true,
"יערכם": true,
"יערכן": true,
"יערמו": true,
"יערנה": true,
"יערנו": true,
"יערני": true,
"יערנך": true,
"יערנם": true,
"יערנן": true,
"יערער": true,
"יערפו": true,
"יערפל": true,
"יערקו": true,
"יעשבו": true,
"יעשוק": true,
"יעשיר": true,
"יעשנו": true,
"יעשקו": true,
"יעתור": true,
"יעתיק": true,
"יעתיר": true,
"יעתרו": true,
"יפאון": true,
"יפארו": true,
"יפברק": true,
"יפגום": true,
"יפגוש": true,
"יפגיז": true,
"יפגין": true,
"יפגיש": true,
"יפגלו": true,
"יפגמו": true,
"יפגעו": true,
"יפגרו": true,
"יפגשו": true,
"יפדהו": true,
"יפדיה": true,
"יפדרו": true,
"יפהפה": true,
"יפהקו": true,
"יפואי": true,
"יפואר": true,
"יפוגג": true,
"יפוגו": true,
"יפוגל": true,
"יפוזם": true,
"יפוזר": true,
"יפוחם": true,
"יפוחת": true,
"יפוטם": true,
"יפוטר": true,
"יפויח": true,
"יפויס": true,
"יפולג": true,
"יפולי": true,
"יפולס": true,
"יפונה": true,
"יפונו": true,
"יפונק": true,
"יפוסו": true,
"יפוסל": true,
"יפוסק": true,
"יפוצה": true,
"יפוצו": true,
"יפוצח": true,
"יפוצל": true,
"יפוצץ": true,
"יפוקח": true,
"יפורז": true,
"יפורט": true,
"יפורי": true,
"יפורק": true,
"יפורר": true,
"יפורש": true,
"יפושו": true,
"יפושט": true,
"יפושל": true,
"יפושק": true,
"יפושר": true,
"יפותה": true,
"יפותו": true,
"יפותח": true,
"יפותל": true,
"יפזול": true,
"יפזזו": true,
"יפזלו": true,
"יפזמו": true,
"יפזרו": true,
"יפחדו": true,
"יפחיד": true,
"יפחית": true,
"יפחלץ": true,
"יפחמו": true,
"יפחתו": true,
"יפטור": true,
"יפטיר": true,
"יפטמו": true,
"יפטפט": true,
"יפטרו": true,
"יפטרל": true,
"יפיגו": true,
"יפיור": true,
"יפיות": true,
"יפיחו": true,
"יפייה": true,
"יפייח": true,
"יפייט": true,
"יפיים": true,
"יפייס": true,
"יפילו": true,
"יפינה": true,
"יפינו": true,
"יפיסו": true,
"יפיפה": true,
"יפיצו": true,
"יפיקו": true,
"יפיתי": true,
"יפיתם": true,
"יפיתן": true,
"יפכור": true,
"יפכפך": true,
"יפכרו": true,
"יפלגו": true,
"יפלוט": true,
"יפלוש": true,
"יפלחו": true,
"יפלטו": true,
"יפליא": true,
"יפליג": true,
"יפליט": true,
"יפליל": true,
"יפליץ": true,
"יפליק": true,
"יפללו": true,
"יפלמר": true,
"יפלסו": true,
"יפלפל": true,
"יפלשו": true,
"יפמפם": true,
"יפנטז": true,
"יפנים": true,
"יפנית": true,
"יפנקו": true,
"יפסול": true,
"יפסוק": true,
"יפסחו": true,
"יפסטר": true,
"יפסיד": true,
"יפסיק": true,
"יפסלו": true,
"יפסעו": true,
"יפספס": true,
"יפסקו": true,
"יפעות": true,
"יפעיל": true,
"יפעים": true,
"יפעלו": true,
"יפעמו": true,
"יפענח": true,
"יפעפע": true,
"יפערו": true,
"יפעתה": true,
"יפעתו": true,
"יפעתי": true,
"יפעתך": true,
"יפעתם": true,
"יפעתן": true,
"יפצור": true,
"יפצחו": true,
"יפציע": true,
"יפציץ": true,
"יפציר": true,
"יפצלו": true,
"יפצעו": true,
"יפצפץ": true,
"יפצרו": true,
"יפקדו": true,
"יפקוד": true,
"יפקוק": true,
"יפקחו": true,
"יפקטר": true,
"יפקיד": true,
"יפקיע": true,
"יפקיר": true,
"יפקסס": true,
"יפקעו": true,
"יפקפק": true,
"יפקקו": true,
"יפקשש": true,
"יפרגן": true,
"יפרוט": true,
"יפרום": true,
"יפרוס": true,
"יפרוף": true,
"יפרוץ": true,
"יפרוק": true,
"יפרוש": true,
"יפרזו": true,
"יפרזל": true,
"יפרחו": true,
"יפרטו": true,
"יפריד": true,
"יפריז": true,
"יפריח": true,
"יפריט": true,
"יפריך": true,
"יפריס": true,
"יפריע": true,
"יפריש": true,
"יפרכס": true,
"יפרמו": true,
"יפרמט": true,
"יפרנס": true,
"יפרסו": true,
"יפרסם": true,
"יפרעו": true,
"יפרפו": true,
"יפרפר": true,
"יפרצו": true,
"יפרקו": true,
"יפרשו": true,
"יפרשן": true,
"יפשוט": true,
"יפשטו": true,
"יפשיט": true,
"יפשיל": true,
"יפשיר": true,
"יפשלו": true,
"יפשעו": true,
"יפשפש": true,
"יפשקו": true,
"יפשרו": true,
"יפתור": true,
"יפתחו": true,
"יפתיע": true,
"יפתכם": true,
"יפתכן": true,
"יפתלו": true,
"יפתנו": true,
"יפתרו": true,
"יצאנה": true,
"יצאנו": true,
"יצאתו": true,
"יצאתי": true,
"יצאתם": true,
"יצאתן": true,
"יצבאו": true,
"יצבוט": true,
"יצבור": true,
"יצבות": true,
"יצבטו": true,
"יצביע": true,
"יצבנה": true,
"יצבעו": true,
"יצברו": true,
"יצברח": true,
"יצגנה": true,
"יצדדו": true,
"יצדיע": true,
"יצדיק": true,
"יצדקו": true,
"יצהיב": true,
"יצהיר": true,
"יצהלו": true,
"יצהרה": true,
"יצהרו": true,
"יצהרי": true,
"יצהרך": true,
"יצהרם": true,
"יצהרן": true,
"יצואה": true,
"יצואו": true,
"יצואי": true,
"יצואך": true,
"יצואם": true,
"יצואן": true,
"יצודד": true,
"יצודו": true,
"יצווה": true,
"יצווו": true,
"יצווח": true,
"יצוות": true,
"יצוטט": true,
"יצויד": true,
"יצוין": true,
"יצויר": true,
"יצוית": true,
"יצולה": true,
"יצולו": true,
"יצולי": true,
"יצולך": true,
"יצולם": true,
"יצולן": true,
"יצומד": true,
"יצומו": true,
"יצומק": true,
"יצוני": true,
"יצונן": true,
"יצועה": true,
"יצועו": true,
"יצועי": true,
"יצועך": true,
"יצועם": true,
"יצוען": true,
"יצופה": true,
"יצופו": true,
"יצופף": true,
"יצוצו": true,
"יצוקה": true,
"יצורה": true,
"יצורו": true,
"יצורי": true,
"יצורך": true,
"יצורם": true,
"יצורן": true,
"יצורף": true,
"יצותת": true,
"יצחין": true,
"יצחיק": true,
"יצחצח": true,
"יצחקו": true,
"יצחקי": true,
"יצחקק": true,
"יצטבע": true,
"יצטבר": true,
"יצטדק": true,
"יצטוו": true,
"יצטטו": true,
"יצטלב": true,
"יצטלל": true,
"יצטלם": true,
"יצטלק": true,
"יצטמג": true,
"יצטמק": true,
"יצטנן": true,
"יצטנע": true,
"יצטנף": true,
"יצטעף": true,
"יצטער": true,
"יצטפד": true,
"יצטרד": true,
"יצטרך": true,
"יצטרף": true,
"יציאה": true,
"יציאת": true,
"יציבה": true,
"יציבו": true,
"יציגה": true,
"יציגו": true,
"יציות": true,
"יצייד": true,
"יציין": true,
"יצייץ": true,
"יצייר": true,
"יציית": true,
"יצילו": true,
"יציעה": true,
"יציעו": true,
"יציעי": true,
"יציעך": true,
"יציעם": true,
"יציען": true,
"יציפו": true,
"יציצו": true,
"יציקה": true,
"יציקו": true,
"יציקת": true,
"יצירה": true,
"יצירו": true,
"יצירי": true,
"יצירך": true,
"יצירם": true,
"יצירן": true,
"יציתו": true,
"יצלבו": true,
"יצלוב": true,
"יצלול": true,
"יצלוף": true,
"יצלחו": true,
"יצליב": true,
"יצליח": true,
"יצליף": true,
"יצללו": true,
"יצלמו": true,
"יצלעו": true,
"יצלפו": true,
"יצלצל": true,
"יצלקו": true,
"יצמאו": true,
"יצמדו": true,
"יצמחו": true,
"יצמיא": true,
"יצמיד": true,
"יצמיח": true,
"יצמית": true,
"יצמצם": true,
"יצמקו": true,
"יצמרר": true,
"יצנזר": true,
"יצנחו": true,
"יצניח": true,
"יצניע": true,
"יצננו": true,
"יצנתר": true,
"יצעדו": true,
"יצעיד": true,
"יצעיר": true,
"יצעקו": true,
"יצערו": true,
"יצפון": true,
"יצפור": true,
"יצפין": true,
"יצפנו": true,
"יצפצף": true,
"יצפרו": true,
"יצקנו": true,
"יצקצק": true,
"יצקתי": true,
"יצקתם": true,
"יצקתן": true,
"יצרבו": true,
"יצרוב": true,
"יצרוך": true,
"יצרום": true,
"יצרון": true,
"יצרוף": true,
"יצרור": true,
"יצרחו": true,
"יצריד": true,
"יצריה": true,
"יצריו": true,
"יצריח": true,
"יצריי": true,
"יצריך": true,
"יצרים": true,
"יצרית": true,
"יצרכו": true,
"יצרכם": true,
"יצרכן": true,
"יצרמו": true,
"יצרנה": true,
"יצרנו": true,
"יצרני": true,
"יצרנך": true,
"יצרנם": true,
"יצרנן": true,
"יצרפו": true,
"יצרצר": true,
"יצררו": true,
"יצרתי": true,
"יצרתם": true,
"יצרתן": true,
"יקאים": true,
"יקאית": true,
"יקבול": true,
"יקבור": true,
"יקביה": true,
"יקביו": true,
"יקביי": true,
"יקביך": true,
"יקביל": true,
"יקבים": true,
"יקבכם": true,
"יקבכן": true,
"יקבלו": true,
"יקבנו": true,
"יקבעו": true,
"יקבצו": true,
"יקברו": true,
"יקדחו": true,
"יקדיח": true,
"יקדים": true,
"יקדיש": true,
"יקדמו": true,
"יקדנו": true,
"יקדרו": true,
"יקדשו": true,
"יקדתי": true,
"יקדתם": true,
"יקדתן": true,
"יקהיל": true,
"יקובע": true,
"יקובץ": true,
"יקודד": true,
"יקודו": true,
"יקודם": true,
"יקודש": true,
"יקווה": true,
"יקוזז": true,
"יקוטב": true,
"יקוטע": true,
"יקוים": true,
"יקולח": true,
"יקולל": true,
"יקולף": true,
"יקומה": true,
"יקומו": true,
"יקומח": true,
"יקומט": true,
"יקומי": true,
"יקומך": true,
"יקומם": true,
"יקומן": true,
"יקומר": true,
"יקונח": true,
"יקונן": true,
"יקופד": true,
"יקופח": true,
"יקופל": true,
"יקוצו": true,
"יקוצץ": true,
"יקוצר": true,
"יקורב": true,
"יקורץ": true,
"יקורר": true,
"יקורת": true,
"יקושט": true,
"יקושר": true,
"יקושש": true,
"יקזזו": true,
"יקטבו": true,
"יקטול": true,
"יקטום": true,
"יקטוף": true,
"יקטים": true,
"יקטין": true,
"יקטיר": true,
"יקטלג": true,
"יקטלו": true,
"יקטמו": true,
"יקטנו": true,
"יקטעו": true,
"יקטפו": true,
"יקטרג": true,
"יקטרו": true,
"יקיאו": true,
"יקידה": true,
"יקיזו": true,
"יקיים": true,
"יקייץ": true,
"יקימו": true,
"יקיפו": true,
"יקיצה": true,
"יקיצו": true,
"יקירה": true,
"יקירו": true,
"יקירי": true,
"יקירך": true,
"יקירם": true,
"יקירן": true,
"יקירת": true,
"יקישו": true,
"יקלדו": true,
"יקלוד": true,
"יקלוט": true,
"יקלופ": true,
"יקלחו": true,
"יקלטו": true,
"יקלטר": true,
"יקליד": true,
"יקליט": true,
"יקלים": true,
"יקליק": true,
"יקליש": true,
"יקלית": true,
"יקללו": true,
"יקלסו": true,
"יקלעו": true,
"יקלפו": true,
"יקלקל": true,
"יקמול": true,
"יקמוץ": true,
"יקמחו": true,
"יקמטו": true,
"יקמלו": true,
"יקמצו": true,
"יקמרו": true,
"יקנאו": true,
"יקנוס": true,
"יקנות": true,
"יקנחו": true,
"יקנטר": true,
"יקניט": true,
"יקנים": true,
"יקננו": true,
"יקנסו": true,
"יקנעם": true,
"יקסום": true,
"יקסים": true,
"יקסמו": true,
"יקעקע": true,
"יקפאו": true,
"יקפדו": true,
"יקפוץ": true,
"יקפחו": true,
"יקפיא": true,
"יקפיד": true,
"יקפיץ": true,
"יקפלו": true,
"יקפצו": true,
"יקצבו": true,
"יקצוב": true,
"יקצוף": true,
"יקצוץ": true,
"יקצור": true,
"יקציב": true,
"יקצין": true,
"יקציע": true,
"יקציף": true,
"יקצפו": true,
"יקצצו": true,
"יקצרו": true,
"יקראו": true,
"יקרבו": true,
"יקרום": true,
"יקרון": true,
"יקרוס": true,
"יקרוץ": true,
"יקרות": true,
"יקרטע": true,
"יקריא": true,
"יקריב": true,
"יקריה": true,
"יקריו": true,
"יקריח": true,
"יקריי": true,
"יקריך": true,
"יקרים": true,
"יקרין": true,
"יקריש": true,
"יקרכם": true,
"יקרכן": true,
"יקרמו": true,
"יקרנה": true,
"יקרנו": true,
"יקרסו": true,
"יקרעו": true,
"יקרצו": true,
"יקרצף": true,
"יקרקע": true,
"יקרקף": true,
"יקרקר": true,
"יקרקש": true,
"יקררו": true,
"יקשור": true,
"יקשטו": true,
"יקשיב": true,
"יקשיח": true,
"יקשקש": true,
"יקשרו": true,
"יראון": true,
"יראות": true,
"יראיה": true,
"יראיו": true,
"יראיי": true,
"יראיך": true,
"יראים": true,
"יראכם": true,
"יראכן": true,
"יראנו": true,
"יראתה": true,
"יראתו": true,
"יראתי": true,
"יראתך": true,
"יראתם": true,
"יראתן": true,
"ירבבו": true,
"ירבדו": true,
"ירבוע": true,
"ירבוץ": true,
"ירביע": true,
"ירביץ": true,
"ירבעו": true,
"ירבעם": true,
"ירבצו": true,
"ירגוז": true,
"ירגום": true,
"ירגון": true,
"ירגוש": true,
"ירגזו": true,
"ירגזי": true,
"ירגיז": true,
"ירגיל": true,
"ירגיע": true,
"ירגיש": true,
"ירגלו": true,
"ירגמו": true,
"ירגנו": true,
"ירגעו": true,
"ירגשו": true,
"ירדדו": true,
"ירדוף": true,
"ירדים": true,
"ירדנה": true,
"ירדנו": true,
"ירדני": true,
"ירדפו": true,
"ירדתי": true,
"ירדתם": true,
"ירדתן": true,
"ירהטו": true,
"ירהיב": true,
"ירואי": true,
"ירובב": true,
"ירובד": true,
"ירובע": true,
"ירוגש": true,
"ירודד": true,
"ירודה": true,
"ירוהט": true,
"ירווה": true,
"ירווח": true,
"ירוחם": true,
"ירוטש": true,
"ירויה": true,
"ירוכז": true,
"ירוכך": true,
"ירומה": true,
"ירומו": true,
"ירומם": true,
"ירונו": true,
"ירוסן": true,
"ירוסס": true,
"ירוסק": true,
"ירועי": true,
"ירופא": true,
"ירופד": true,
"ירופה": true,
"ירופף": true,
"ירוצה": true,
"ירוצו": true,
"ירוצף": true,
"ירוצץ": true,
"ירוקה": true,
"ירוקן": true,
"ירוקע": true,
"ירוקת": true,
"ירושה": true,
"ירושו": true,
"ירושל": true,
"ירושש": true,
"ירושת": true,
"ירותו": true,
"ירותי": true,
"ירותך": true,
"ירותק": true,
"ירחון": true,
"ירחיב": true,
"ירחיה": true,
"ירחיו": true,
"ירחיי": true,
"ירחיך": true,
"ירחים": true,
"ירחיק": true,
"ירחית": true,
"ירחכם": true,
"ירחכן": true,
"ירחמו": true,
"ירחנו": true,
"ירחפו": true,
"ירחצו": true,
"ירחקו": true,
"ירחרח": true,
"ירחשו": true,
"ירטוט": true,
"ירטון": true,
"ירטטו": true,
"ירטיב": true,
"ירטיט": true,
"ירטנה": true,
"ירטנו": true,
"ירטשו": true,
"יריבה": true,
"יריבו": true,
"יריבי": true,
"יריבך": true,
"יריבם": true,
"יריבן": true,
"ירידה": true,
"ירידו": true,
"ירידי": true,
"ירידך": true,
"ירידם": true,
"ירידן": true,
"ירידת": true,
"יריות": true,
"יריחו": true,
"ירייה": true,
"יריים": true,
"יריית": true,
"ירימו": true,
"ירינה": true,
"ירינו": true,
"יריעה": true,
"יריעו": true,
"יריצו": true,
"יריקה": true,
"יריקו": true,
"יריתי": true,
"יריתם": true,
"יריתן": true,
"ירכבו": true,
"ירכון": true,
"ירכוס": true,
"ירכוש": true,
"ירכזו": true,
"ירכיב": true,
"ירכיה": true,
"ירכיו": true,
"ירכיי": true,
"ירכיך": true,
"ירכין": true,
"ירכית": true,
"ירככו": true,
"ירככם": true,
"ירככן": true,
"ירכלו": true,
"ירכנו": true,
"ירכסו": true,
"ירכשו": true,
"ירכתה": true,
"ירכתו": true,
"ירכתי": true,
"ירכתך": true,
"ירכתם": true,
"ירכתן": true,
"ירמוז": true,
"ירמוך": true,
"ירמוס": true,
"ירמזו": true,
"ירמזר": true,
"ירמיה": true,
"ירמסו": true,
"ירנין": true,
"ירננו": true,
"ירסנו": true,
"ירססו": true,
"ירסקו": true,
"ירעבו": true,
"ירעדו": true,
"ירעיב": true,
"ירעיד": true,
"ירעיל": true,
"ירעים": true,
"ירעיף": true,
"ירעיש": true,
"ירעמו": true,
"ירענן": true,
"ירעשו": true,
"ירפאו": true,
"ירפדו": true,
"ירפרף": true,
"ירצדו": true,
"ירצוץ": true,
"ירצחו": true,
"ירצין": true,
"ירצעו": true,
"ירצפו": true,
"ירצצו": true,
"ירקבו": true,
"ירקדו": true,
"ירקוד": true,
"ירקום": true,
"ירקון": true,
"ירקוק": true,
"ירקות": true,
"ירקחו": true,
"ירקיב": true,
"ירקיד": true,
"ירקיע": true,
"ירקכם": true,
"ירקכן": true,
"ירקמו": true,
"ירקנה": true,
"ירקנו": true,
"ירקני": true,
"ירקנך": true,
"ירקנם": true,
"ירקנן": true,
"ירקעו": true,
"ירקקו": true,
"ירקרק": true,
"ירקתי": true,
"ירקתם": true,
"ירקתן": true,
"ירשום": true,
"ירשוף": true,
"ירשים": true,
"ירשיע": true,
"ירשמו": true,
"ירשנה": true,
"ירשנו": true,
"ירשפו": true,
"ירשרש": true,
"ירשתו": true,
"ירשתי": true,
"ירשתם": true,
"ירשתן": true,
"ירתום": true,
"ירתחו": true,
"ירתיח": true,
"ירתיע": true,
"ירתית": true,
"ירתכו": true,
"ירתמו": true,
"ירתעו": true,
"ירתקו": true,
"ישאבו": true,
"ישאגו": true,
"ישאיל": true,
"ישאיף": true,
"ישאיר": true,
"ישאלו": true,
"ישאפו": true,
"ישארו": true,
"ישבוק": true,
"ישבור": true,
"ישבות": true,
"ישבחו": true,
"ישבטו": true,
"ישביח": true,
"ישביע": true,
"ישביר": true,
"ישבית": true,
"ישבנה": true,
"ישבנו": true,
"ישבני": true,
"ישבנך": true,
"ישבנם": true,
"ישבנן": true,
"ישבעו": true,
"ישבצו": true,
"ישבקו": true,
"ישברו": true,
"ישבשו": true,
"ישבתו": true,
"ישבתי": true,
"ישבתם": true,
"ישבתן": true,
"ישגגו": true,
"ישגוג": true,
"ישגיח": true,
"ישגים": true,
"ישגעו": true,
"ישגרו": true,
"ישגשג": true,
"ישדדו": true,
"ישדוד": true,
"ישדכו": true,
"ישדלו": true,
"ישדרג": true,
"ישדרו": true,
"ישהקו": true,
"ישובב": true,
"ישובה": true,
"ישובו": true,
"ישובח": true,
"ישובט": true,
"ישובץ": true,
"ישובר": true,
"ישובש": true,
"ישוגע": true,
"ישוגר": true,
"ישודד": true,
"ישודך": true,
"ישודל": true,
"ישודר": true,
"ישווה": true,
"ישווע": true,
"ישווק": true,
"ישוחד": true,
"ישוחו": true,
"ישוחח": true,
"ישוחק": true,
"ישוטו": true,
"ישוטט": true,
"ישויך": true,
"ישויף": true,
"ישוכך": true,
"ישוכל": true,
"ישוכן": true,
"ישולב": true,
"ישולח": true,
"ישולם": true,
"ישולש": true,
"ישומן": true,
"ישומר": true,
"ישונה": true,
"ישונו": true,
"ישונן": true,
"ישונע": true,
"ישוסה": true,
"ישוסו": true,
"ישוסע": true,
"ישוסף": true,
"ישועה": true,
"ישועי": true,
"ישוער": true,
"ישופד": true,
"ישופה": true,
"ישופו": true,
"ישופע": true,
"ישופץ": true,
"ישופר": true,
"ישוקם": true,
"ישוקע": true,
"ישוקף": true,
"ישוקץ": true,
"ישורי": true,
"ישורש": true,
"ישורת": true,
"ישושו": true,
"ישותה": true,
"ישותו": true,
"ישותי": true,
"ישותך": true,
"ישותם": true,
"ישותן": true,
"ישותף": true,
"ישותק": true,
"ישזוף": true,
"ישזור": true,
"ישזפו": true,
"ישזרו": true,
"ישחבר": true,
"ישחדו": true,
"ישחזר": true,
"ישחטו": true,
"ישחיז": true,
"ישחיל": true,
"ישחים": true,
"ישחיר": true,
"ישחית": true,
"ישחלף": true,
"ישחקו": true,
"ישחרו": true,
"ישחרר": true,
"ישחתו": true,
"ישטום": true,
"ישטוף": true,
"ישטחו": true,
"ישטמו": true,
"ישטפו": true,
"ישיאו": true,
"ישיבה": true,
"ישיבו": true,
"ישיבת": true,
"ישיגו": true,
"ישיחו": true,
"ישיטו": true,
"ישייט": true,
"ישייך": true,
"ישייף": true,
"ישילו": true,
"ישימה": true,
"ישימו": true,
"ישיקו": true,
"ישירה": true,
"ישירו": true,
"ישישה": true,
"ישישו": true,
"ישישי": true,
"ישישך": true,
"ישישם": true,
"ישישן": true,
"ישיתו": true,
"ישכבו": true,
"ישכול": true,
"ישכון": true,
"ישכור": true,
"ישכחו": true,
"ישכיב": true,
"ישכיח": true,
"ישכיל": true,
"ישכים": true,
"ישכין": true,
"ישכיר": true,
"ישככו": true,
"ישכלו": true,
"ישכלל": true,
"ישכנו": true,
"ישכנע": true,
"ישכפל": true,
"ישכרו": true,
"ישכשך": true,
"ישכתב": true,
"ישלבו": true,
"ישלהב": true,
"ישלוט": true,
"ישלול": true,
"ישלון": true,
"ישלוף": true,
"ישלוק": true,
"ישלחו": true,
"ישלטו": true,
"ישלטט": true,
"ישליט": true,
"ישליך": true,
"ישלים": true,
"ישללו": true,
"ישלמו": true,
"ישלפו": true,
"ישלקו": true,
"ישלשו": true,
"ישלשל": true,
"ישמוט": true,
"ישמור": true,
"ישמחו": true,
"ישמטו": true,
"ישמיד": true,
"ישמיט": true,
"ישמין": true,
"ישמיע": true,
"ישמיץ": true,
"ישמיש": true,
"ישממו": true,
"ישמנה": true,
"ישמנו": true,
"ישמעו": true,
"ישמרו": true,
"ישמשו": true,
"ישנאו": true,
"ישנות": true,
"ישניא": true,
"ישנים": true,
"ישננו": true,
"ישנסו": true,
"ישנעו": true,
"ישנתי": true,
"ישנתם": true,
"ישנתן": true,
"ישסעו": true,
"ישספו": true,
"ישעבד": true,
"ישעטו": true,
"ישעיה": true,
"ישעין": true,
"ישעכם": true,
"ישעכן": true,
"ישעמם": true,
"ישענו": true,
"ישערו": true,
"ישערך": true,
"ישעשע": true,
"ישעתק": true,
"ישפדו": true,
"ישפוט": true,
"ישפוך": true,
"ישפול": true,
"ישפות": true,
"ישפטו": true,
"ישפיל": true,
"ישפיע": true,
"ישפכו": true,
"ישפלו": true,
"ישפעו": true,
"ישפעל": true,
"ישפצו": true,
"ישפצר": true,
"ישפרו": true,
"ישפשף": true,
"ישפתו": true,
"ישצוף": true,
"ישצפו": true,
"ישקדו": true,
"ישקוד": true,
"ישקוט": true,
"ישקול": true,
"ישקטו": true,
"ישקיט": true,
"ישקין": true,
"ישקיע": true,
"ישקיף": true,
"ישקלו": true,
"ישקלל": true,
"ישקמו": true,
"ישקעו": true,
"ישקפו": true,
"ישקצו": true,
"ישקקו": true,
"ישקרו": true,
"ישקשק": true,
"ישראל": true,
"ישרבב": true,
"ישרבט": true,
"ישרדו": true,
"ישרוד": true,
"ישרוט": true,
"ישרוך": true,
"ישרוף": true,
"ישרוץ": true,
"ישרוק": true,
"ישרור": true,
"ישרות": true,
"ישרטו": true,
"ישרטט": true,
"ישריה": true,
"ישריו": true,
"ישריי": true,
"ישריך": true,
"ישרים": true,
"ישריץ": true,
"ישריש": true,
"ישרכו": true,
"ישרכם": true,
"ישרכן": true,
"ישרנה": true,
"ישרנו": true,
"ישרפו": true,
"ישרצו": true,
"ישרקו": true,
"ישררו": true,
"ישרשו": true,
"ישרשר": true,
"ישרתה": true,
"ישרתו": true,
"ישרתי": true,
"ישרתך": true,
"ישרתם": true,
"ישרתן": true,
"יששכר": true,
"ישתאה": true,
"ישתאו": true,
"ישתבח": true,
"ישתבט": true,
"ישתבץ": true,
"ישתבר": true,
"ישתבש": true,
"ישתגע": true,
"ישתדך": true,
"ישתדל": true,
"ישתהה": true,
"ישתהו": true,
"ישתוו": true,
"ישתול": true,
"ישתוק": true,
"ישתות": true,
"ישתזף": true,
"ישתחל": true,
"ישתחץ": true,
"ישתטה": true,
"ישתטו": true,
"ישתטח": true,
"ישתיל": true,
"ישתין": true,
"ישתיק": true,
"ישתית": true,
"ישתכו": true,
"ישתכח": true,
"ישתכן": true,
"ישתכר": true,
"ישתלב": true,
"ישתלו": true,
"ישתלח": true,
"ישתלט": true,
"ישתלם": true,
"ישתמד": true,
"ישתמט": true,
"ישתמע": true,
"ישתמר": true,
"ישתמש": true,
"ישתנה": true,
"ישתנו": true,
"ישתנק": true,
"ישתעל": true,
"ישתפד": true,
"ישתפו": true,
"ישתפך": true,
"ישתפל": true,
"ישתפן": true,
"ישתפר": true,
"ישתקו": true,
"ישתקם": true,
"ישתקע": true,
"ישתקף": true,
"ישתרג": true,
"ישתרך": true,
"ישתרע": true,
"ישתרר": true,
"ישתרש": true,
"ישתתה": true,
"ישתתו": true,
"ישתתף": true,
"ישתתק": true,
"יתאבד": true,
"יתאבך": true,
"יתאבל": true,
"יתאבן": true,
"יתאבק": true,
"יתאגד": true,
"יתאדה": true,
"יתאדו": true,
"יתאהב": true,
"יתאוו": true,
"יתאזן": true,
"יתאזר": true,
"יתאחד": true,
"יתאחה": true,
"יתאחו": true,
"יתאחר": true,
"יתאים": true,
"יתאכל": true,
"יתאמו": true,
"יתאמן": true,
"יתאמץ": true,
"יתאמת": true,
"יתאסף": true,
"יתאפס": true,
"יתאפק": true,
"יתאפר": true,
"יתארו": true,
"יתארח": true,
"יתארך": true,
"יתארס": true,
"יתאשר": true,
"יתבאס": true,
"יתבאר": true,
"יתבגר": true,
"יתבדה": true,
"יתבדו": true,
"יתבדח": true,
"יתבדל": true,
"יתבדר": true,
"יתבהם": true,
"יתבהר": true,
"יתבזה": true,
"יתבזו": true,
"יתבטא": true,
"יתבטל": true,
"יתבלה": true,
"יתבלו": true,
"יתבלט": true,
"יתבסם": true,
"יתבסס": true,
"יתבעו": true,
"יתבצע": true,
"יתבצר": true,
"יתבקע": true,
"יתבקש": true,
"יתברג": true,
"יתברך": true,
"יתברר": true,
"יתבשל": true,
"יתבשם": true,
"יתבשר": true,
"יתגאה": true,
"יתגאו": true,
"יתגבה": true,
"יתגבו": true,
"יתגבר": true,
"יתגבש": true,
"יתגדר": true,
"יתגלה": true,
"יתגלו": true,
"יתגלח": true,
"יתגלם": true,
"יתגלע": true,
"יתגלש": true,
"יתגמד": true,
"יתגמל": true,
"יתגמש": true,
"יתגנב": true,
"יתגפף": true,
"יתגרד": true,
"יתגרה": true,
"יתגרו": true,
"יתגרש": true,
"יתגשם": true,
"יתדות": true,
"יתדכם": true,
"יתדכן": true,
"יתדלק": true,
"יתדמה": true,
"יתדמו": true,
"יתדנו": true,
"יתדפק": true,
"יתדרך": true,
"יתהדק": true,
"יתהדר": true,
"יתהוו": true,
"יתהלך": true,
"יתהלל": true,
"יתהפך": true,
"יתואם": true,
"יתואר": true,
"יתובל": true,
"יתווה": true,
"יתווך": true,
"יתוזז": true,
"יתוחח": true,
"יתוחם": true,
"יתויג": true,
"יתויק": true,
"יתויר": true,
"יתוכן": true,
"יתומה": true,
"יתומו": true,
"יתומי": true,
"יתומך": true,
"יתומם": true,
"יתומן": true,
"יתוני": true,
"יתועד": true,
"יתועל": true,
"יתועש": true,
"יתופף": true,
"יתוקל": true,
"יתוקן": true,
"יתורו": true,
"יתורץ": true,
"יתושה": true,
"יתושו": true,
"יתושי": true,
"יתושך": true,
"יתושם": true,
"יתושן": true,
"יתזזו": true,
"יתזמן": true,
"יתזמר": true,
"יתחבא": true,
"יתחבב": true,
"יתחבו": true,
"יתחבט": true,
"יתחבל": true,
"יתחבק": true,
"יתחבר": true,
"יתחדד": true,
"יתחדש": true,
"יתחום": true,
"יתחזה": true,
"יתחזו": true,
"יתחזק": true,
"יתחחו": true,
"יתחיל": true,
"יתחכך": true,
"יתחכם": true,
"יתחלה": true,
"יתחלו": true,
"יתחלף": true,
"יתחלק": true,
"יתחמו": true,
"יתחמם": true,
"יתחמן": true,
"יתחמק": true,
"יתחמש": true,
"יתחנך": true,
"יתחנן": true,
"יתחנף": true,
"יתחסד": true,
"יתחסל": true,
"יתחסן": true,
"יתחפף": true,
"יתחפר": true,
"יתחפש": true,
"יתחצף": true,
"יתחקה": true,
"יתחקו": true,
"יתחקר": true,
"יתחרד": true,
"יתחרה": true,
"יתחרו": true,
"יתחרז": true,
"יתחרט": true,
"יתחרש": true,
"יתחשב": true,
"יתחשל": true,
"יתחשק": true,
"יתחתן": true,
"יתחתר": true,
"יתיות": true,
"יתיזו": true,
"יתייג": true,
"יתיים": true,
"יתייק": true,
"יתייר": true,
"יתיכו": true,
"יתירה": true,
"יתירו": true,
"יתישו": true,
"יתכבד": true,
"יתכבס": true,
"יתכדר": true,
"יתכהה": true,
"יתכהו": true,
"יתכחש": true,
"יתכלה": true,
"יתכלו": true,
"יתכנה": true,
"יתכנו": true,
"יתכנן": true,
"יתכנס": true,
"יתכנת": true,
"יתכסה": true,
"יתכסו": true,
"יתכסח": true,
"יתכער": true,
"יתכפר": true,
"יתכתב": true,
"יתכתש": true,
"יתלבט": true,
"יתלבן": true,
"יתלבש": true,
"יתלהב": true,
"יתלהט": true,
"יתלהם": true,
"יתלוו": true,
"יתלוש": true,
"יתלחש": true,
"יתלטף": true,
"יתלטש": true,
"יתלכד": true,
"יתלמד": true,
"יתלפף": true,
"יתלקח": true,
"יתלקק": true,
"יתלשו": true,
"יתלתל": true,
"יתמגן": true,
"יתמהו": true,
"יתמוך": true,
"יתמות": true,
"יתמזג": true,
"יתמזל": true,
"יתמחה": true,
"יתמחו": true,
"יתמחר": true,
"יתמיד": true,
"יתמיה": true,
"יתמיר": true,
"יתמכו": true,
"יתמכן": true,
"יתמכר": true,
"יתמלא": true,
"יתמלל": true,
"יתממש": true,
"יתמנה": true,
"יתמנו": true,
"יתמנף": true,
"יתמסד": true,
"יתמסה": true,
"יתמסו": true,
"יתמסר": true,
"יתמעט": true,
"יתמצא": true,
"יתמצה": true,
"יתמצו": true,
"יתמצק": true,
"יתמצת": true,
"יתמקד": true,
"יתמקח": true,
"יתמקם": true,
"יתמרד": true,
"יתמרח": true,
"יתמרן": true,
"יתמרץ": true,
"יתמרק": true,
"יתמרר": true,
"יתמשך": true,
"יתמתח": true,
"יתמתן": true,
"יתנאה": true,
"יתנאו": true,
"יתנבא": true,
"יתנגב": true,
"יתנגד": true,
"יתנגח": true,
"יתנגן": true,
"יתנגש": true,
"יתנדב": true,
"יתנדף": true,
"יתנהג": true,
"יתנהל": true,
"יתנזל": true,
"יתנזר": true,
"יתנחל": true,
"יתנחם": true,
"יתנים": true,
"יתניע": true,
"יתנכל": true,
"יתנכר": true,
"יתנסה": true,
"יתנסו": true,
"יתנסח": true,
"יתנער": true,
"יתנפח": true,
"יתנפל": true,
"יתנפץ": true,
"יתנצח": true,
"יתנצל": true,
"יתנצר": true,
"יתנקה": true,
"יתנקו": true,
"יתנקז": true,
"יתנקם": true,
"יתנקש": true,
"יתנשא": true,
"יתנשם": true,
"יתנשף": true,
"יתנשק": true,
"יתנתב": true,
"יתנתק": true,
"יתסוס": true,
"יתסיס": true,
"יתסכל": true,
"יתססו": true,
"יתסרט": true,
"יתעבה": true,
"יתעבו": true,
"יתעבר": true,
"יתעגל": true,
"יתעדו": true,
"יתעדן": true,
"יתעדף": true,
"יתעטף": true,
"יתעטש": true,
"יתעכב": true,
"יתעכל": true,
"יתעלה": true,
"יתעלו": true,
"יתעלל": true,
"יתעלם": true,
"יתעלס": true,
"יתעלף": true,
"יתעלק": true,
"יתעמל": true,
"יתעמם": true,
"יתעמק": true,
"יתעמר": true,
"יתעמת": true,
"יתענג": true,
"יתענה": true,
"יתענו": true,
"יתענן": true,
"יתעסק": true,
"יתעפש": true,
"יתעצב": true,
"יתעצל": true,
"יתעצם": true,
"יתעקל": true,
"יתעקם": true,
"יתעקר": true,
"יתעקש": true,
"יתערב": true,
"יתערה": true,
"יתערו": true,
"יתעשו": true,
"יתעשר": true,
"יתעשת": true,
"יתעתד": true,
"יתעתע": true,
"יתעתק": true,
"יתפאר": true,
"יתפגל": true,
"יתפגר": true,
"יתפוס": true,
"יתפור": true,
"יתפוש": true,
"יתפזם": true,
"יתפזר": true,
"יתפחו": true,
"יתפחם": true,
"יתפחס": true,
"יתפטם": true,
"יתפטר": true,
"יתפיח": true,
"יתפיל": true,
"יתפכח": true,
"יתפלא": true,
"יתפלג": true,
"יתפלח": true,
"יתפלל": true,
"יתפלץ": true,
"יתפלש": true,
"יתפנה": true,
"יתפנו": true,
"יתפנק": true,
"יתפסו": true,
"יתפעל": true,
"יתפעם": true,
"יתפצח": true,
"יתפצל": true,
"יתפקד": true,
"יתפקע": true,
"יתפקר": true,
"יתפרו": true,
"יתפרס": true,
"יתפרע": true,
"יתפרץ": true,
"יתפרק": true,
"יתפרש": true,
"יתפשו": true,
"יתפשט": true,
"יתפשל": true,
"יתפשר": true,
"יתפתה": true,
"יתפתו": true,
"יתפתח": true,
"יתפתל": true,
"יתצפת": true,
"יתקבל": true,
"יתקבע": true,
"יתקבץ": true,
"יתקדם": true,
"יתקדר": true,
"יתקדש": true,
"יתקהל": true,
"יתקוף": true,
"יתקזז": true,
"יתקיל": true,
"יתקין": true,
"יתקיף": true,
"יתקלו": true,
"יתקלח": true,
"יתקלט": true,
"יתקלס": true,
"יתקלף": true,
"יתקמט": true,
"יתקמר": true,
"יתקנא": true,
"יתקנו": true,
"יתקעו": true,
"יתקפד": true,
"יתקפו": true,
"יתקפל": true,
"יתקצב": true,
"יתקצץ": true,
"יתקצר": true,
"יתקרא": true,
"יתקרב": true,
"יתקרח": true,
"יתקרר": true,
"יתקרש": true,
"יתקשה": true,
"יתקשו": true,
"יתקשח": true,
"יתקשט": true,
"יתקשר": true,
"יתקתק": true,
"יתראה": true,
"יתראו": true,
"יתרבד": true,
"יתרבה": true,
"יתרבו": true,
"יתרבת": true,
"יתרגז": true,
"יתרגל": true,
"יתרגם": true,
"יתרגע": true,
"יתרגש": true,
"יתרדד": true,
"יתרהב": true,
"יתרום": true,
"יתרון": true,
"יתרות": true,
"יתרחב": true,
"יתרחץ": true,
"יתרחק": true,
"יתרחש": true,
"יתרטב": true,
"יתריה": true,
"יתריו": true,
"יתריי": true,
"יתריך": true,
"יתרים": true,
"יתריס": true,
"יתריע": true,
"יתרכב": true,
"יתרכז": true,
"יתרכך": true,
"יתרכם": true,
"יתרכן": true,
"יתרמו": true,
"יתרנה": true,
"יתרנו": true,
"יתרסן": true,
"יתרסק": true,
"יתרעם": true,
"יתרפא": true,
"יתרפה": true,
"יתרפו": true,
"יתרפט": true,
"יתרפס": true,
"יתרפק": true,
"יתרצה": true,
"יתרצו": true,
"יתרקם": true,
"יתרשל": true,
"יתרשם": true,
"יתרתה": true,
"יתרתו": true,
"יתרתח": true,
"יתרתי": true,
"יתרתך": true,
"יתרתם": true,
"יתרתן": true,
"יתשאל": true,
"כאבול": true,
"כאביה": true,
"כאביו": true,
"כאביי": true,
"כאביך": true,
"כאבים": true,
"כאבכם": true,
"כאבכן": true,
"כאבנה": true,
"כאבנו": true,
"כאבתי": true,
"כאבתם": true,
"כאבתן": true,
"כאובה": true,
"כאוטי": true,
"כאילו": true,
"כאישה": true,
"כאמור": true,
"כארבע": true,
"כבאות": true,
"כבאיה": true,
"כבאיו": true,
"כבאיי": true,
"כבאיך": true,
"כבאים": true,
"כבאית": true,
"כבאכם": true,
"כבאכן": true,
"כבאנו": true,
"כבדות": true,
"כבדיה": true,
"כבדיו": true,
"כבדיי": true,
"כבדיך": true,
"כבדים": true,
"כבדכם": true,
"כבדכן": true,
"כבדנה": true,
"כבדנו": true,
"כבדרך": true,
"כבודה": true,
"כבודו": true,
"כבודי": true,
"כבודך": true,
"כבודם": true,
"כבודן": true,
"כבויה": true,
"כבולה": true,
"כבושה": true,
"כבידה": true,
"כבילה": true,
"כבינה": true,
"כבינו": true,
"כביסה": true,
"כבירה": true,
"כבירת": true,
"כבישה": true,
"כבישו": true,
"כבישי": true,
"כבישך": true,
"כבישם": true,
"כבישן": true,
"כביתי": true,
"כביתם": true,
"כביתן": true,
"כבליה": true,
"כבליו": true,
"כבליי": true,
"כבליך": true,
"כבלים": true,
"כבלכם": true,
"כבלכן": true,
"כבלנו": true,
"כבלתי": true,
"כבלתם": true,
"כבלתן": true,
"כבסיה": true,
"כבסיו": true,
"כבסיי": true,
"כבסיך": true,
"כבסים": true,
"כבסיס": true,
"כבסנה": true,
"כבעבר": true,
"כבעלי": true,
"כבעלת": true,
"כברות": true,
"כברים": true,
"כברתה": true,
"כברתו": true,
"כברתי": true,
"כברתך": true,
"כברתם": true,
"כברתן": true,
"כבשות": true,
"כבשיה": true,
"כבשיו": true,
"כבשיי": true,
"כבשיך": true,
"כבשים": true,
"כבשכם": true,
"כבשכן": true,
"כבשנה": true,
"כבשנו": true,
"כבשני": true,
"כבשנך": true,
"כבשנם": true,
"כבשנן": true,
"כבשתה": true,
"כבשתו": true,
"כבשתי": true,
"כבשתך": true,
"כבשתם": true,
"כבשתן": true,
"כגורם": true,
"כדאים": true,
"כדאית": true,
"כדבעי": true,
"כדברי": true,
"כדורה": true,
"כדורו": true,
"כדורי": true,
"כדורך": true,
"כדורם": true,
"כדורן": true,
"כדורס": true,
"כדורת": true,
"כדיהם": true,
"כדיהן": true,
"כדייך": true,
"כדיכם": true,
"כדיכן": true,
"כדינו": true,
"כדמות": true,
"כדרור": true,
"כדרכו": true,
"כדררה": true,
"כדררו": true,
"כדררי": true,
"כדררת": true,
"כהגדר": true,
"כהונה": true,
"כהונת": true,
"כהותה": true,
"כהותו": true,
"כהותי": true,
"כהותך": true,
"כהותם": true,
"כהותן": true,
"כהינה": true,
"כהינו": true,
"כהיתי": true,
"כהיתם": true,
"כהיתן": true,
"כהכנה": true,
"כהליה": true,
"כהליו": true,
"כהליי": true,
"כהליך": true,
"כהלים": true,
"כהלכה": true,
"כהנים": true,
"כואבת": true,
"כובדה": true,
"כובדו": true,
"כובדי": true,
"כובדך": true,
"כובדם": true,
"כובדן": true,
"כובדת": true,
"כובות": true,
"כובים": true,
"כובית": true,
"כובלת": true,
"כובסה": true,
"כובסו": true,
"כובסי": true,
"כובסך": true,
"כובסם": true,
"כובסן": true,
"כובסת": true,
"כובעה": true,
"כובעו": true,
"כובעי": true,
"כובעך": true,
"כובעם": true,
"כובען": true,
"כובשת": true,
"כובתה": true,
"כוהלה": true,
"כוהלו": true,
"כוהלי": true,
"כוהלך": true,
"כוהלם": true,
"כוהלן": true,
"כוהנה": true,
"כוהנו": true,
"כוהני": true,
"כוהנך": true,
"כוהנם": true,
"כוהנן": true,
"כוהנת": true,
"כוויה": true,
"כווית": true,
"כוונה": true,
"כוונו": true,
"כווני": true,
"כוונן": true,
"כוונת": true,
"כווצה": true,
"כווצו": true,
"כווצי": true,
"כווצת": true,
"כוורן": true,
"כוורת": true,
"כוזבת": true,
"כוזרי": true,
"כוחות": true,
"כוחכם": true,
"כוחכן": true,
"כוחנו": true,
"כוחני": true,
"כויות": true,
"כוילה": true,
"כוילו": true,
"כוילת": true,
"כויסה": true,
"כויסו": true,
"כויסת": true,
"כוירה": true,
"כוירו": true,
"כוירת": true,
"כוכבא": true,
"כוכבה": true,
"כוכבו": true,
"כוכבי": true,
"כוכבך": true,
"כוכבם": true,
"כוכבן": true,
"כוכבת": true,
"כוכיה": true,
"כוכיו": true,
"כוכיי": true,
"כוכיך": true,
"כוכים": true,
"כוככם": true,
"כוככן": true,
"כוכנו": true,
"כולאת": true,
"כולות": true,
"כולים": true,
"כולית": true,
"כולכם": true,
"כולכן": true,
"כוללת": true,
"כולנו": true,
"כולרה": true,
"כולתה": true,
"כומים": true,
"כומרה": true,
"כומרו": true,
"כומרי": true,
"כומרך": true,
"כומרם": true,
"כומרן": true,
"כומשת": true,
"כומתה": true,
"כונות": true,
"כונים": true,
"כונית": true,
"כוננה": true,
"כוננו": true,
"כונני": true,
"כוננך": true,
"כוננם": true,
"כוננן": true,
"כוננת": true,
"כונסה": true,
"כונסו": true,
"כונסת": true,
"כונתה": true,
"כונתו": true,
"כוסות": true,
"כוסחה": true,
"כוסחו": true,
"כוסחת": true,
"כוסית": true,
"כוסכם": true,
"כוסכן": true,
"כוסמת": true,
"כוסנו": true,
"כוססת": true,
"כוסתה": true,
"כועסת": true,
"כוערה": true,
"כוערו": true,
"כוערת": true,
"כופות": true,
"כופים": true,
"כופלת": true,
"כופפה": true,
"כופפו": true,
"כופפי": true,
"כופפת": true,
"כופרה": true,
"כופרו": true,
"כופרת": true,
"כופתה": true,
"כופתר": true,
"כופתת": true,
"כורבל": true,
"כורדי": true,
"כורות": true,
"כורחה": true,
"כורחו": true,
"כורחי": true,
"כורחך": true,
"כורחם": true,
"כורחן": true,
"כוריה": true,
"כוריו": true,
"כוריי": true,
"כוריך": true,
"כורים": true,
"כורכם": true,
"כורכן": true,
"כורכר": true,
"כורכת": true,
"כורמה": true,
"כורמו": true,
"כורמי": true,
"כורמך": true,
"כורמם": true,
"כורמן": true,
"כורמת": true,
"כורנו": true,
"כורסא": true,
"כורסה": true,
"כורסם": true,
"כורעת": true,
"כורתת": true,
"כושים": true,
"כושית": true,
"כושלה": true,
"כושלת": true,
"כושפה": true,
"כושפו": true,
"כושפת": true,
"כושרה": true,
"כושרו": true,
"כושרי": true,
"כושרך": true,
"כושרם": true,
"כושרן": true,
"כותבה": true,
"כותבו": true,
"כותבי": true,
"כותבך": true,
"כותבם": true,
"כותבן": true,
"כותבת": true,
"כותלה": true,
"כותלו": true,
"כותלי": true,
"כותלך": true,
"כותלם": true,
"כותלן": true,
"כותנה": true,
"כותפת": true,
"כותרה": true,
"כותרו": true,
"כותרי": true,
"כותרך": true,
"כותרם": true,
"כותרן": true,
"כותרת": true,
"כותשת": true,
"כותתה": true,
"כותתו": true,
"כותתי": true,
"כותתם": true,
"כותתן": true,
"כזביה": true,
"כזביו": true,
"כזביי": true,
"כזביך": true,
"כזבים": true,
"כזבכם": true,
"כזבכן": true,
"כזבנה": true,
"כזבנו": true,
"כזבני": true,
"כזכור": true,
"כחברה": true,
"כחברת": true,
"כחדנה": true,
"כחודש": true,
"כחולה": true,
"כחומר": true,
"כחושה": true,
"כחכוח": true,
"כחכחה": true,
"כחכחו": true,
"כחכחי": true,
"כחכחת": true,
"כחלון": true,
"כחלחל": true,
"כחשנה": true,
"כיאות": true,
"כיבדה": true,
"כיבדו": true,
"כיבדת": true,
"כיבוד": true,
"כיבוי": true,
"כיבוש": true,
"כיביה": true,
"כיביו": true,
"כיביי": true,
"כיביך": true,
"כיבים": true,
"כיבית": true,
"כיבכם": true,
"כיבכן": true,
"כיבנו": true,
"כיבסה": true,
"כיבסו": true,
"כיבסת": true,
"כיבתה": true,
"כידון": true,
"כידוע": true,
"כיהנה": true,
"כיהנו": true,
"כיהנת": true,
"כיוון": true,
"כיווץ": true,
"כיולה": true,
"כיולו": true,
"כיולי": true,
"כיולך": true,
"כיולם": true,
"כיולן": true,
"כיוסה": true,
"כיוסו": true,
"כיוסי": true,
"כיוסך": true,
"כיוסם": true,
"כיוסן": true,
"כיועץ": true,
"כיופה": true,
"כיופו": true,
"כיופי": true,
"כיופך": true,
"כיופם": true,
"כיופן": true,
"כיוצא": true,
"כיורה": true,
"כיורו": true,
"כיורי": true,
"כיורך": true,
"כיורם": true,
"כיורן": true,
"כיזבה": true,
"כיזבו": true,
"כיזבת": true,
"כיחדה": true,
"כיחדו": true,
"כיחדת": true,
"כיחוד": true,
"כיחוש": true,
"כיחשה": true,
"כיחשו": true,
"כיחשת": true,
"כיילה": true,
"כיילו": true,
"כיילי": true,
"כיילת": true,
"כייסה": true,
"כייסו": true,
"כייסי": true,
"כייסך": true,
"כייסם": true,
"כייסן": true,
"כייסת": true,
"כייפה": true,
"כייפו": true,
"כייפי": true,
"כייפת": true,
"כיירה": true,
"כיירו": true,
"כיירי": true,
"כיירת": true,
"כיכבה": true,
"כיכבו": true,
"כיכבת": true,
"כיכוב": true,
"כיכרה": true,
"כיכרו": true,
"כיכרי": true,
"כיכרך": true,
"כיכרם": true,
"כיכרן": true,
"כילדה": true,
"כילוי": true,
"כילות": true,
"כיליי": true,
"כילים": true,
"כילית": true,
"כילתה": true,
"כילתו": true,
"כילתי": true,
"כילתך": true,
"כילתם": true,
"כילתן": true,
"כימאי": true,
"כימות": true,
"כימיה": true,
"כימית": true,
"כימרה": true,
"כינוי": true,
"כינון": true,
"כינוס": true,
"כינור": true,
"כינות": true,
"כיניה": true,
"כיניו": true,
"כיניי": true,
"כיניך": true,
"כינים": true,
"כינין": true,
"כינית": true,
"כינמת": true,
"כינסה": true,
"כינסו": true,
"כינסת": true,
"כינרת": true,
"כינתה": true,
"כינתו": true,
"כינתי": true,
"כינתך": true,
"כינתם": true,
"כינתן": true,
"כיסאה": true,
"כיסאו": true,
"כיסאי": true,
"כיסאך": true,
"כיסאם": true,
"כיסאן": true,
"כיסוח": true,
"כיסוי": true,
"כיסוף": true,
"כיסחה": true,
"כיסחו": true,
"כיסחת": true,
"כיסיה": true,
"כיסיו": true,
"כיסיי": true,
"כיסיך": true,
"כיסים": true,
"כיסית": true,
"כיסכם": true,
"כיסכן": true,
"כיסנה": true,
"כיסנו": true,
"כיסני": true,
"כיסנך": true,
"כיסנם": true,
"כיסנן": true,
"כיספה": true,
"כיספו": true,
"כיספת": true,
"כיסתה": true,
"כיעור": true,
"כיערה": true,
"כיערו": true,
"כיערת": true,
"כיפוף": true,
"כיפור": true,
"כיפות": true,
"כיפית": true,
"כיפרה": true,
"כיפרו": true,
"כיפרת": true,
"כיפתה": true,
"כיפתו": true,
"כיפתי": true,
"כיפתך": true,
"כיפתם": true,
"כיפתן": true,
"כירות": true,
"כיריי": true,
"כירים": true,
"כירתה": true,
"כירתו": true,
"כירתי": true,
"כירתך": true,
"כירתם": true,
"כירתן": true,
"כישוף": true,
"כישור": true,
"כישפה": true,
"כישפו": true,
"כישפת": true,
"כיתבה": true,
"כיתבו": true,
"כיתבת": true,
"כיתוב": true,
"כיתור": true,
"כיתות": true,
"כיתרה": true,
"כיתרו": true,
"כיתרת": true,
"כיתתה": true,
"כיתתו": true,
"כיתתי": true,
"כיתתך": true,
"כיתתם": true,
"כיתתן": true,
"ככאלו": true,
"ככבנה": true,
"ככולם": true,
"ככתוב": true,
"כלאיה": true,
"כלאיו": true,
"כלאיי": true,
"כלאיך": true,
"כלאים": true,
"כלאית": true,
"כלאכם": true,
"כלאכן": true,
"כלאנה": true,
"כלאנו": true,
"כלאתי": true,
"כלאתם": true,
"כלאתן": true,
"כלבות": true,
"כלביה": true,
"כלביו": true,
"כלביי": true,
"כלביך": true,
"כלבים": true,
"כלבית": true,
"כלבכם": true,
"כלבכן": true,
"כלבלב": true,
"כלבנה": true,
"כלבנו": true,
"כלבני": true,
"כלבנך": true,
"כלבנם": true,
"כלבנן": true,
"כלבתא": true,
"כלבתה": true,
"כלבתו": true,
"כלבתי": true,
"כלבתך": true,
"כלבתם": true,
"כלבתן": true,
"כלואה": true,
"כלובה": true,
"כלובו": true,
"כלובי": true,
"כלובך": true,
"כלובם": true,
"כלובן": true,
"כלולה": true,
"כלומר": true,
"כלונס": true,
"כלורי": true,
"כלותה": true,
"כלותו": true,
"כלותי": true,
"כלותך": true,
"כלותם": true,
"כלותן": true,
"כליאה": true,
"כליהם": true,
"כליהן": true,
"כליון": true,
"כליות": true,
"כלייה": true,
"כלייך": true,
"כליים": true,
"כליין": true,
"כליכם": true,
"כליכן": true,
"כלילה": true,
"כלילי": true,
"כלימה": true,
"כלינה": true,
"כלינו": true,
"כליתי": true,
"כליתם": true,
"כליתן": true,
"כלכלה": true,
"כלכלו": true,
"כלכלי": true,
"כלכלך": true,
"כלכלם": true,
"כלכלן": true,
"כלכלת": true,
"כללות": true,
"כלליה": true,
"כלליו": true,
"כלליי": true,
"כלליך": true,
"כללים": true,
"כללית": true,
"כללכם": true,
"כללכן": true,
"כללנו": true,
"כללתי": true,
"כללתם": true,
"כללתן": true,
"כלנית": true,
"כלפון": true,
"כלפיה": true,
"כלפיו": true,
"כלפיי": true,
"כלפיך": true,
"כלשהו": true,
"כלשהי": true,
"כלשהם": true,
"כלשהן": true,
"כלשון": true,
"כלתכם": true,
"כלתכן": true,
"כלתנו": true,
"כמאמן": true,
"כמאמר": true,
"כמהות": true,
"כמהיה": true,
"כמהיו": true,
"כמהיי": true,
"כמהיך": true,
"כמהים": true,
"כמהין": true,
"כמהנה": true,
"כמהנו": true,
"כמהתה": true,
"כמהתו": true,
"כמהתי": true,
"כמהתך": true,
"כמהתם": true,
"כמהתן": true,
"כמובן": true,
"כמוהו": true,
"כמוהם": true,
"כמוהן": true,
"כמוכם": true,
"כמוכן": true,
"כמונו": true,
"כמוני": true,
"כמוסה": true,
"כמורה": true,
"כמושה": true,
"כמותה": true,
"כמותו": true,
"כמותי": true,
"כמותך": true,
"כמותם": true,
"כמותן": true,
"כמטרה": true,
"כמיהה": true,
"כמיטב": true,
"כמישה": true,
"כמנהג": true,
"כמנהל": true,
"כמספר": true,
"כמעין": true,
"כמעשה": true,
"כמקום": true,
"כמקור": true,
"כמריה": true,
"כמריו": true,
"כמריי": true,
"כמריך": true,
"כמרים": true,
"כמרכז": true,
"כמרצה": true,
"כמשהו": true,
"כמשון": true,
"כמשנו": true,
"כמשתי": true,
"כמשתם": true,
"כמשתן": true,
"כמתנה": true,
"כנגדה": true,
"כנגדו": true,
"כנגדם": true,
"כנדרש": true,
"כנהוג": true,
"כנוסה": true,
"כנועה": true,
"כנותה": true,
"כנותו": true,
"כנותי": true,
"כנותך": true,
"כנותם": true,
"כנותן": true,
"כנזים": true,
"כניהם": true,
"כניהן": true,
"כניון": true,
"כניות": true,
"כנייך": true,
"כניים": true,
"כניכם": true,
"כניכן": true,
"כנימה": true,
"כנינה": true,
"כנינו": true,
"כניסה": true,
"כניסת": true,
"כניעה": true,
"כניקה": true,
"כננות": true,
"כננים": true,
"כננתה": true,
"כננתו": true,
"כננתי": true,
"כננתך": true,
"כננתם": true,
"כננתן": true,
"כנסות": true,
"כנסיה": true,
"כנסיו": true,
"כנסיי": true,
"כנסיך": true,
"כנסים": true,
"כנסכם": true,
"כנסכן": true,
"כנסנה": true,
"כנסנו": true,
"כנספח": true,
"כנסתה": true,
"כנסתו": true,
"כנסתי": true,
"כנסתך": true,
"כנסתם": true,
"כנסתן": true,
"כנעני": true,
"כנפון": true,
"כנפיה": true,
"כנפיו": true,
"כנפיי": true,
"כנפיך": true,
"כנפכם": true,
"כנפכן": true,
"כנפנו": true,
"כנציג": true,
"כנראה": true,
"כנרות": true,
"כנריה": true,
"כנריו": true,
"כנריי": true,
"כנריך": true,
"כנרים": true,
"כנרכם": true,
"כנרכן": true,
"כנרנו": true,
"כנרתה": true,
"כנרתו": true,
"כנרתי": true,
"כנרתך": true,
"כנרתם": true,
"כנרתן": true,
"כנשיא": true,
"כסאות": true,
"כסוכי": true,
"כסוסה": true,
"כסופה": true,
"כסותה": true,
"כסותו": true,
"כסותי": true,
"כסותך": true,
"כסותם": true,
"כסותן": true,
"כסחנה": true,
"כסיות": true,
"כסילה": true,
"כסילו": true,
"כסילי": true,
"כסילך": true,
"כסילם": true,
"כסילן": true,
"כסינה": true,
"כסיסה": true,
"כסלון": true,
"כסניה": true,
"כססנו": true,
"כססתי": true,
"כססתם": true,
"כססתן": true,
"כספות": true,
"כספיה": true,
"כספיו": true,
"כספיי": true,
"כספיך": true,
"כספים": true,
"כספית": true,
"כספכם": true,
"כספכן": true,
"כספנה": true,
"כספנו": true,
"כספרה": true,
"כספרו": true,
"כספרי": true,
"כספרך": true,
"כספרם": true,
"כספרן": true,
"כספתה": true,
"כספתו": true,
"כספתי": true,
"כספתך": true,
"כספתם": true,
"כספתן": true,
"כסתות": true,
"כסתכם": true,
"כסתכן": true,
"כסתנו": true,
"כעבור": true,
"כעובד": true,
"כעולה": true,
"כעוסה": true,
"כעורה": true,
"כעורך": true,
"כעכיה": true,
"כעכיו": true,
"כעכיי": true,
"כעכיך": true,
"כעכים": true,
"כעככם": true,
"כעככן": true,
"כעכנו": true,
"כעסיה": true,
"כעסיו": true,
"כעסיי": true,
"כעסיך": true,
"כעסים": true,
"כעסכם": true,
"כעסכן": true,
"כעסנה": true,
"כעסנו": true,
"כעסני": true,
"כעסתי": true,
"כעסתם": true,
"כעסתן": true,
"כערנה": true,
"כעשור": true,
"כעשרה": true,
"כפאיה": true,
"כפויה": true,
"כפולה": true,
"כפועל": true,
"כפופה": true,
"כפותה": true,
"כפיהם": true,
"כפיהן": true,
"כפיון": true,
"כפיות": true,
"כפייה": true,
"כפייך": true,
"כפיים": true,
"כפיכם": true,
"כפיכן": true,
"כפילה": true,
"כפילו": true,
"כפילי": true,
"כפילך": true,
"כפילם": true,
"כפילן": true,
"כפינה": true,
"כפינו": true,
"כפיסה": true,
"כפיסו": true,
"כפיסי": true,
"כפיסך": true,
"כפיסם": true,
"כפיסן": true,
"כפיפה": true,
"כפירה": true,
"כפירו": true,
"כפירי": true,
"כפירך": true,
"כפירם": true,
"כפירן": true,
"כפיתה": true,
"כפיתו": true,
"כפיתי": true,
"כפיתך": true,
"כפיתם": true,
"כפיתן": true,
"כפכפה": true,
"כפכפו": true,
"כפכפי": true,
"כפכפך": true,
"כפכפם": true,
"כפכפן": true,
"כפליה": true,
"כפליו": true,
"כפליי": true,
"כפליך": true,
"כפלכם": true,
"כפלכן": true,
"כפלנו": true,
"כפלתי": true,
"כפלתם": true,
"כפלתן": true,
"כפפות": true,
"כפפנו": true,
"כפפתה": true,
"כפפתו": true,
"כפפתי": true,
"כפפתך": true,
"כפפתם": true,
"כפפתן": true,
"כפרות": true,
"כפריה": true,
"כפריו": true,
"כפריי": true,
"כפריך": true,
"כפרים": true,
"כפרית": true,
"כפרכם": true,
"כפרכן": true,
"כפרנה": true,
"כפרנו": true,
"כפרתה": true,
"כפרתו": true,
"כפרתי": true,
"כפרתך": true,
"כפרתם": true,
"כפרתן": true,
"כפשוט": true,
"כפתור": true,
"כפתנו": true,
"כפתרה": true,
"כפתרו": true,
"כפתרי": true,
"כפתרת": true,
"כצפוי": true,
"כקצין": true,
"כראוי": true,
"כראזי": true,
"כרבול": true,
"כרבלה": true,
"כרבלו": true,
"כרבלי": true,
"כרבלת": true,
"כרגיל": true,
"כרובה": true,
"כרובו": true,
"כרובי": true,
"כרובך": true,
"כרובם": true,
"כרובן": true,
"כרוזה": true,
"כרוזו": true,
"כרוזי": true,
"כרוזך": true,
"כרוזם": true,
"כרוזן": true,
"כרויה": true,
"כרוכה": true,
"כרוני": true,
"כרועה": true,
"כרופא": true,
"כרותה": true,
"כרותו": true,
"כרזות": true,
"כרזים": true,
"כרזתה": true,
"כרזתו": true,
"כרזתי": true,
"כרזתך": true,
"כרזתם": true,
"כרזתן": true,
"כרטוס": true,
"כרטיס": true,
"כרטסה": true,
"כרטסו": true,
"כרטסי": true,
"כרטסת": true,
"כריהם": true,
"כריהן": true,
"כריות": true,
"כריזה": true,
"כרייה": true,
"כרייך": true,
"כריכה": true,
"כריכו": true,
"כריכי": true,
"כריכך": true,
"כריכם": true,
"כריכן": true,
"כרינה": true,
"כרינו": true,
"כריסט": true,
"כריעה": true,
"כרישה": true,
"כרישו": true,
"כרישי": true,
"כרישך": true,
"כרישם": true,
"כרישן": true,
"כריתה": true,
"כריתו": true,
"כריתי": true,
"כריתך": true,
"כריתם": true,
"כריתן": true,
"כריתת": true,
"כרכוב": true,
"כרכום": true,
"כרכור": true,
"כרכיה": true,
"כרכיו": true,
"כרכיי": true,
"כרכיך": true,
"כרכים": true,
"כרככם": true,
"כרככן": true,
"כרכמש": true,
"כרכנו": true,
"כרכרה": true,
"כרכרו": true,
"כרכרי": true,
"כרכרת": true,
"כרכתי": true,
"כרכתם": true,
"כרכתן": true,
"כרמיה": true,
"כרמיו": true,
"כרמיי": true,
"כרמיך": true,
"כרמים": true,
"כרמית": true,
"כרמכם": true,
"כרמכן": true,
"כרמלה": true,
"כרמלי": true,
"כרמנו": true,
"כרסום": true,
"כרסות": true,
"כרסיה": true,
"כרסיו": true,
"כרסיי": true,
"כרסיך": true,
"כרסים": true,
"כרסכם": true,
"כרסכן": true,
"כרסמה": true,
"כרסמו": true,
"כרסמי": true,
"כרסמת": true,
"כרסנו": true,
"כרסתן": true,
"כרעיה": true,
"כרעיו": true,
"כרעיי": true,
"כרעיך": true,
"כרעכם": true,
"כרעכן": true,
"כרענה": true,
"כרענו": true,
"כרעתי": true,
"כרעתם": true,
"כרעתן": true,
"כרפסה": true,
"כרפסו": true,
"כרפסי": true,
"כרפסך": true,
"כרפסם": true,
"כרפסן": true,
"כרשות": true,
"כרשתה": true,
"כרשתו": true,
"כרשתי": true,
"כרשתך": true,
"כרשתם": true,
"כרשתן": true,
"כרתים": true,
"כרתנו": true,
"כשאדם": true,
"כשאחד": true,
"כשאין": true,
"כשאמר": true,
"כשאנו": true,
"כשאני": true,
"כשאתה": true,
"כשאתם": true,
"כשבוע": true,
"כשדים": true,
"כשדית": true,
"כשהוא": true,
"כשהיא": true,
"כשהיה": true,
"כשהיו": true,
"כשהתח": true,
"כשורה": true,
"כשותה": true,
"כשותו": true,
"כשותי": true,
"כשותך": true,
"כשותם": true,
"כשותן": true,
"כשחזר": true,
"כשחקן": true,
"כשירה": true,
"כשירי": true,
"כשכבר": true,
"כשכוש": true,
"כשכשה": true,
"כשכשו": true,
"כשכשי": true,
"כשכשת": true,
"כשלון": true,
"כשלוש": true,
"כשליה": true,
"כשליו": true,
"כשליי": true,
"כשליך": true,
"כשלים": true,
"כשליש": true,
"כשלכם": true,
"כשלכן": true,
"כשלנו": true,
"כשלתי": true,
"כשלתם": true,
"כשלתן": true,
"כשפיה": true,
"כשפיו": true,
"כשפיי": true,
"כשפיך": true,
"כשפים": true,
"כשפכם": true,
"כשפכן": true,
"כשפנה": true,
"כשפנו": true,
"כשרון": true,
"כשרות": true,
"כשריה": true,
"כשריו": true,
"כשריי": true,
"כשריך": true,
"כשרים": true,
"כתבות": true,
"כתביה": true,
"כתביו": true,
"כתביי": true,
"כתביך": true,
"כתבים": true,
"כתבכם": true,
"כתבכן": true,
"כתבנה": true,
"כתבנו": true,
"כתבני": true,
"כתבנך": true,
"כתבנם": true,
"כתבנן": true,
"כתבתה": true,
"כתבתו": true,
"כתבתי": true,
"כתבתך": true,
"כתבתם": true,
"כתבתן": true,
"כתובה": true,
"כתובו": true,
"כתובי": true,
"כתובך": true,
"כתובם": true,
"כתובן": true,
"כתובת": true,
"כתומה": true,
"כתונת": true,
"כתושה": true,
"כתיבה": true,
"כתיבו": true,
"כתיבי": true,
"כתיבך": true,
"כתיבם": true,
"כתיבן": true,
"כתיבת": true,
"כתיות": true,
"כתיים": true,
"כתישה": true,
"כתיתה": true,
"כתליה": true,
"כתליו": true,
"כתליי": true,
"כתליך": true,
"כתלים": true,
"כתמיה": true,
"כתמיו": true,
"כתמיי": true,
"כתמיך": true,
"כתמים": true,
"כתמכם": true,
"כתמכן": true,
"כתמנו": true,
"כתמתם": true,
"כתנאי": true,
"כתפיה": true,
"כתפיו": true,
"כתפיי": true,
"כתפיך": true,
"כתפכם": true,
"כתפכן": true,
"כתפנו": true,
"כתריה": true,
"כתריו": true,
"כתריי": true,
"כתריך": true,
"כתרים": true,
"כתרכם": true,
"כתרכן": true,
"כתרנה": true,
"כתרנו": true,
"כתשנו": true,
"כתשתי": true,
"כתשתם": true,
"כתשתן": true,
"כתתנה": true,
"לאבוד": true,
"לאבול": true,
"לאבות": true,
"לאבזר": true,
"לאבחן": true,
"לאבטח": true,
"לאביו": true,
"לאבני": true,
"לאגור": true,
"לאגרף": true,
"לאדות": true,
"לאדמה": true,
"לאהבה": true,
"לאהבת": true,
"לאהוב": true,
"לאהוד": true,
"לאוזן": true,
"לאויב": true,
"לאוכל": true,
"לאולם": true,
"לאומה": true,
"לאומו": true,
"לאומי": true,
"לאומך": true,
"לאומם": true,
"לאומן": true,
"לאונן": true,
"לאוסף": true,
"לאופי": true,
"לאופן": true,
"לאופר": true,
"לאוצר": true,
"לאורה": true,
"לאורח": true,
"לאורך": true,
"לאורל": true,
"לאושר": true,
"לאושש": true,
"לאותה": true,
"לאותו": true,
"לאותי": true,
"לאותך": true,
"לאותם": true,
"לאותן": true,
"לאותת": true,
"לאזאר": true,
"לאזול": true,
"לאזוק": true,
"לאזור": true,
"לאזכר": true,
"לאזלת": true,
"לאזרח": true,
"לאחוז": true,
"לאחור": true,
"לאחות": true,
"לאחזר": true,
"לאחיו": true,
"לאחים": true,
"לאחסן": true,
"לאטום": true,
"לאטכם": true,
"לאטכן": true,
"לאטנה": true,
"לאטנו": true,
"לאטתי": true,
"לאטתם": true,
"לאטתן": true,
"לאיזה": true,
"לאיזו": true,
"לאייד": true,
"לאייל": true,
"לאיים": true,
"לאיין": true,
"לאייר": true,
"לאייש": true,
"לאיית": true,
"לאילת": true,
"לאינט": true,
"לאינס": true,
"לאישה": true,
"לאיתן": true,
"לאכול": true,
"לאכוף": true,
"לאכזב": true,
"לאכלס": true,
"לאלון": true,
"לאלחש": true,
"לאליפ": true,
"לאליש": true,
"לאלעד": true,
"לאלפי": true,
"לאלתר": true,
"לאמוד": true,
"לאמוץ": true,
"לאמור": true,
"לאמית": true,
"לאמלל": true,
"לאמצע": true,
"לאנוס": true,
"לאנפף": true,
"לאנשי": true,
"לאסון": true,
"לאסוף": true,
"לאסור": true,
"לאסלם": true,
"לאפוס": true,
"לאפוף": true,
"לאפות": true,
"לאפיק": true,
"לאפנן": true,
"לאפקט": true,
"לאפשר": true,
"לאצור": true,
"לארבע": true,
"לארגן": true,
"לארוב": true,
"לארוג": true,
"לארוז": true,
"לארוך": true,
"לארון": true,
"לארות": true,
"לארכב": true,
"לאשום": true,
"לאשפז": true,
"לאשרר": true,
"לאשתו": true,
"לאתגר": true,
"לאתחל": true,
"לאתרי": true,
"לבאוש": true,
"לבאיה": true,
"לבאיו": true,
"לבאיי": true,
"לבאיך": true,
"לבאים": true,
"לבבות": true,
"לבבית": true,
"לבבכם": true,
"לבבכן": true,
"לבבנה": true,
"לבבנו": true,
"לבגוד": true,
"לבגור": true,
"לבדוק": true,
"לבדות": true,
"לבדיה": true,
"לבדיו": true,
"לבדיי": true,
"לבדיך": true,
"לבדים": true,
"לבדכם": true,
"לבדכן": true,
"לבדנו": true,
"לבהוק": true,
"לבהות": true,
"לבודד": true,
"לבודה": true,
"לבונה": true,
"לבוקר": true,
"לבורא": true,
"לבורג": true,
"לבושה": true,
"לבושו": true,
"לבושי": true,
"לבושך": true,
"לבושם": true,
"לבושן": true,
"לבושש": true,
"לבזבז": true,
"לבזוז": true,
"לבזוק": true,
"לבזות": true,
"לבחול": true,
"לבחון": true,
"לבחור": true,
"לבחוש": true,
"לבטוח": true,
"לבטון": true,
"לבטוש": true,
"לבטות": true,
"לבטיה": true,
"לבטיו": true,
"לבטיי": true,
"לבטיך": true,
"לבטים": true,
"לבטלה": true,
"לביאה": true,
"לביאו": true,
"לביאי": true,
"לביאך": true,
"לביאם": true,
"לביאן": true,
"לביבה": true,
"לבידה": true,
"לבידו": true,
"לבידי": true,
"לבידך": true,
"לבידם": true,
"לבידן": true,
"לביהד": true,
"לביהח": true,
"לביהס": true,
"לביוב": true,
"לבייב": true,
"לבייל": true,
"לביים": true,
"לבייץ": true,
"לבייש": true,
"לביית": true,
"לבינה": true,
"לביצע": true,
"לבירא": true,
"לבירה": true,
"לבישה": true,
"לביתה": true,
"לביתו": true,
"לביתי": true,
"לביתם": true,
"לביתן": true,
"לבכות": true,
"לבלבה": true,
"לבלבו": true,
"לבלבי": true,
"לבלבך": true,
"לבלבל": true,
"לבלבם": true,
"לבלבן": true,
"לבלבת": true,
"לבלגן": true,
"לבלוב": true,
"לבלוג": true,
"לבלוט": true,
"לבלול": true,
"לבלום": true,
"לבלוס": true,
"לבלוע": true,
"לבלוש": true,
"לבלות": true,
"לבלרה": true,
"לבלרו": true,
"לבלרי": true,
"לבלרך": true,
"לבלרם": true,
"לבלרן": true,
"לבלתי": true,
"לבנאם": true,
"לבנבן": true,
"לבנדר": true,
"לבנוב": true,
"לבנון": true,
"לבנות": true,
"לבניה": true,
"לבניו": true,
"לבניי": true,
"לבניך": true,
"לבנים": true,
"לבנין": true,
"לבנית": true,
"לבנתה": true,
"לבנתו": true,
"לבנתי": true,
"לבנתך": true,
"לבנתם": true,
"לבנתן": true,
"לבסוף": true,
"לבסיס": true,
"לבסקי": true,
"לבעבע": true,
"לבעוט": true,
"לבעול": true,
"לבעור": true,
"לבעיה": true,
"לבעלה": true,
"לבעלי": true,
"לבצבץ": true,
"לבצוע": true,
"לבצור": true,
"לבקבק": true,
"לבקוע": true,
"לבקשה": true,
"לבקשת": true,
"לברבר": true,
"לברוא": true,
"לברוח": true,
"לברון": true,
"לברור": true,
"לברית": true,
"לברכה": true,
"לבשיא": true,
"לבשנה": true,
"לבשנו": true,
"לבשתי": true,
"לבשתם": true,
"לבשתן": true,
"לבתים": true,
"לגאול": true,
"לגאות": true,
"לגאלי": true,
"לגבוה": true,
"לגבול": true,
"לגבור": true,
"לגבות": true,
"לגביה": true,
"לגביו": true,
"לגבעה": true,
"לגבעת": true,
"לגגות": true,
"לגדול": true,
"לגדום": true,
"לגדוע": true,
"לגדוש": true,
"לגהור": true,
"לגובה": true,
"לגוגל": true,
"לגודו": true,
"לגודל": true,
"לגוון": true,
"לגווע": true,
"לגולל": true,
"לגולש": true,
"לגונה": true,
"לגונן": true,
"לגופה": true,
"לגופו": true,
"לגורל": true,
"לגורם": true,
"לגזוז": true,
"לגזול": true,
"לגזום": true,
"לגזור": true,
"לגחון": true,
"לגיון": true,
"לגיוס": true,
"לגייס": true,
"לגייר": true,
"לגילה": true,
"לגימה": true,
"לגינה": true,
"לגישה": true,
"לגלגה": true,
"לגלגו": true,
"לגלגי": true,
"לגלגל": true,
"לגלגן": true,
"לגלגת": true,
"לגלוג": true,
"לגלול": true,
"לגלוש": true,
"לגלות": true,
"לגליל": true,
"לגלית": true,
"לגלעד": true,
"לגלען": true,
"לגמגם": true,
"לגמול": true,
"לגמוע": true,
"לגמור": true,
"לגמנו": true,
"לגמרי": true,
"לגמתי": true,
"לגמתם": true,
"לגמתן": true,
"לגנוב": true,
"לגנוז": true,
"לגנוח": true,
"לגנות": true,
"לגסוס": true,
"לגעגע": true,
"לגעור": true,
"לגעוש": true,
"לגעות": true,
"לגרגר": true,
"לגרוב": true,
"לגרוד": true,
"לגרום": true,
"לגרוס": true,
"לגרוע": true,
"לגרוף": true,
"לגרור": true,
"לגרות": true,
"לגרסה": true,
"לגרסת": true,
"לגרען": true,
"לדאגה": true,
"לדאוב": true,
"לדאוג": true,
"לדאות": true,
"לדבוק": true,
"לדברי": true,
"לדברר": true,
"לדגדג": true,
"לדגול": true,
"לדגום": true,
"לדגור": true,
"לדגמן": true,
"לדדות": true,
"לדהור": true,
"לדהות": true,
"לדובב": true,
"לדווח": true,
"לדווש": true,
"לדומם": true,
"לדחוס": true,
"לדחוף": true,
"לדחוק": true,
"לדחות": true,
"לדידו": true,
"לדידם": true,
"לדיון": true,
"לדיור": true,
"לדייק": true,
"לדינו": true,
"לדיני": true,
"לדירה": true,
"לדירת": true,
"לדכדך": true,
"לדלדל": true,
"לדלוף": true,
"לדלוק": true,
"לדלות": true,
"לדלקת": true,
"לדמום": true,
"לדמוע": true,
"לדמות": true,
"לדסקס": true,
"לדעוך": true,
"לדעות": true,
"לדעתה": true,
"לדעתו": true,
"לדעתי": true,
"לדעתך": true,
"לדעתם": true,
"לדפדף": true,
"לדפוס": true,
"לדפוק": true,
"לדקדק": true,
"לדקור": true,
"לדקלם": true,
"לדרבן": true,
"לדרגה": true,
"לדרגת": true,
"לדרדר": true,
"לדרוך": true,
"לדרום": true,
"לדרוס": true,
"לדרוש": true,
"לדרכו": true,
"לדרכי": true,
"לדרמן": true,
"לדשדש": true,
"להאיץ": true,
"להאיר": true,
"להבהב": true,
"להבות": true,
"להביא": true,
"להביה": true,
"להביו": true,
"להביט": true,
"להביי": true,
"להביך": true,
"להבים": true,
"להבין": true,
"להביס": true,
"להביע": true,
"להבכם": true,
"להבכן": true,
"להבנה": true,
"להבנו": true,
"להבנת": true,
"להברי": true,
"להבתה": true,
"להבתו": true,
"להבתי": true,
"להבתך": true,
"להבתם": true,
"להבתן": true,
"להגות": true,
"להגיב": true,
"להגיד": true,
"להגיה": true,
"להגיו": true,
"להגיח": true,
"להגיי": true,
"להגיך": true,
"להגים": true,
"להגיע": true,
"להגיף": true,
"להגיר": true,
"להגיש": true,
"להגכם": true,
"להגכן": true,
"להגנה": true,
"להגנו": true,
"להגנת": true,
"להגעה": true,
"להגעת": true,
"להגשה": true,
"להגשת": true,
"להדהד": true,
"להדוף": true,
"להדיח": true,
"להדיף": true,
"להדיר": true,
"להדסה": true,
"לההין": true,
"להודו": true,
"להוון": true,
"להוות": true,
"להוטה": true,
"להונג": true,
"להורג": true,
"להורה": true,
"להזהר": true,
"להזור": true,
"להזות": true,
"להזיד": true,
"להזיז": true,
"להזיח": true,
"להזיל": true,
"להזין": true,
"להזיע": true,
"להזיק": true,
"להחזר": true,
"להחיל": true,
"להחיש": true,
"להחשף": true,
"להטוט": true,
"להטות": true,
"להטטה": true,
"להטטו": true,
"להטטי": true,
"להטטת": true,
"להטיב": true,
"להטיה": true,
"להטיו": true,
"להטיח": true,
"להטיי": true,
"להטיך": true,
"להטיל": true,
"להטים": true,
"להטיס": true,
"להטיף": true,
"להטכם": true,
"להטכן": true,
"להטלת": true,
"להטנה": true,
"להטנו": true,
"להטתי": true,
"להטתם": true,
"להטתן": true,
"להיגע": true,
"להיום": true,
"להיות": true,
"להיטה": true,
"להיטו": true,
"להיטי": true,
"להיטך": true,
"להיטם": true,
"להיטן": true,
"להיכל": true,
"להיכן": true,
"להימק": true,
"להיסב": true,
"להיפך": true,
"להיקף": true,
"להישג": true,
"להיתר": true,
"להכות": true,
"להכיל": true,
"להכין": true,
"להכיר": true,
"להכיש": true,
"להכנה": true,
"להכנס": true,
"להכנת": true,
"להכרה": true,
"להכרת": true,
"להכשל": true,
"להלום": true,
"להלחם": true,
"להליט": true,
"להליך": true,
"להלין": true,
"להלכה": true,
"להמגן": true,
"להמהם": true,
"להמום": true,
"להמון": true,
"להמות": true,
"להמיט": true,
"להמיס": true,
"להמיר": true,
"להמית": true,
"להמנע": true,
"להמשך": true,
"להנאה": true,
"להנאת": true,
"להנדס": true,
"להנהן": true,
"להנות": true,
"להנחה": true,
"להנחת": true,
"להניא": true,
"להניב": true,
"להניד": true,
"להניח": true,
"להניס": true,
"להניע": true,
"להניף": true,
"להניק": true,
"להנשא": true,
"להנתן": true,
"להסבר": true,
"להסדר": true,
"להסות": true,
"להסיג": true,
"להסיח": true,
"להסיט": true,
"להסיע": true,
"להסיק": true,
"להסיר": true,
"להסית": true,
"להסכם": true,
"להסקה": true,
"להסרת": true,
"להעזר": true,
"להעיב": true,
"להעיד": true,
"להעיף": true,
"להעיק": true,
"להעיר": true,
"להעלם": true,
"להפוך": true,
"להפיג": true,
"להפיח": true,
"להפיל": true,
"להפיס": true,
"להפיץ": true,
"להפיק": true,
"להפנט": true,
"להפסד": true,
"להפצת": true,
"להפקה": true,
"להפקת": true,
"להפרי": true,
"להפרת": true,
"להצגה": true,
"להצגת": true,
"להציב": true,
"להציג": true,
"להציל": true,
"להציע": true,
"להציף": true,
"להציץ": true,
"להציק": true,
"להצית": true,
"להצלת": true,
"להצעה": true,
"להצעת": true,
"להקות": true,
"להקיא": true,
"להקיה": true,
"להקיו": true,
"להקיז": true,
"להקיי": true,
"להקיך": true,
"להקים": true,
"להקיף": true,
"להקיץ": true,
"להקיש": true,
"להקכם": true,
"להקכן": true,
"להקלה": true,
"להקמת": true,
"להקנה": true,
"להקנו": true,
"להקתה": true,
"להקתו": true,
"להקתי": true,
"להקתך": true,
"להקתם": true,
"להקתן": true,
"להרבה": true,
"להרגע": true,
"להרהר": true,
"להרוג": true,
"להרוס": true,
"להרות": true,
"להריח": true,
"להרים": true,
"להריע": true,
"להריץ": true,
"להריק": true,
"להרכב": true,
"להרמת": true,
"להרשם": true,
"להשאר": true,
"להשבת": true,
"להשגה": true,
"להשגת": true,
"להשיא": true,
"להשיב": true,
"להשיג": true,
"להשיח": true,
"להשיט": true,
"להשיל": true,
"להשים": true,
"להשיק": true,
"להשיר": true,
"להשית": true,
"להתיז": true,
"להתיך": true,
"להתיר": true,
"להתיש": true,
"להתמק": true,
"להתרח": true,
"לואטת": true,
"לואיז": true,
"לואיס": true,
"לוביה": true,
"לובים": true,
"לובית": true,
"לובנה": true,
"לובנו": true,
"לובני": true,
"לובנך": true,
"לובנם": true,
"לובנן": true,
"לובנת": true,
"לובשת": true,
"לובתה": true,
"לוגית": true,
"לוגמת": true,
"לודוט": true,
"לודרה": true,
"לודרו": true,
"לודרי": true,
"לודרך": true,
"לודרם": true,
"לודרן": true,
"לוהטת": true,
"לוהקה": true,
"לוהקו": true,
"לוהקת": true,
"לוואה": true,
"לוואו": true,
"לוואי": true,
"לוואך": true,
"לוואם": true,
"לוואן": true,
"לוודא": true,
"לווות": true,
"לוויה": true,
"לוויז": true,
"לווים": true,
"לווין": true,
"לווית": true,
"לוונט": true,
"לווסת": true,
"לוועד": true,
"לוותה": true,
"לוותר": true,
"לוזון": true,
"לוחות": true,
"לוחחה": true,
"לוחחו": true,
"לוחחת": true,
"לוחית": true,
"לוחכה": true,
"לוחכו": true,
"לוחכם": true,
"לוחכן": true,
"לוחכת": true,
"לוחמה": true,
"לוחמו": true,
"לוחמי": true,
"לוחמך": true,
"לוחמם": true,
"לוחמן": true,
"לוחמת": true,
"לוחנו": true,
"לוחצת": true,
"לוחשת": true,
"לוטוס": true,
"לוטות": true,
"לוטטי": true,
"לוטים": true,
"לוטמה": true,
"לוטמו": true,
"לוטמי": true,
"לוטמך": true,
"לוטמם": true,
"לוטמן": true,
"לוטפה": true,
"לוטפו": true,
"לוטפת": true,
"לוטרה": true,
"לוטשה": true,
"לוטשו": true,
"לוטשת": true,
"לויות": true,
"לויים": true,
"לוכדה": true,
"לוכדו": true,
"לוכדת": true,
"לוכלך": true,
"לוכסן": true,
"לולאה": true,
"לולבה": true,
"לולבו": true,
"לולבי": true,
"לולבך": true,
"לולבם": true,
"לולבן": true,
"לולות": true,
"לוליה": true,
"לוליו": true,
"לוליי": true,
"לוליך": true,
"לולים": true,
"לולית": true,
"לולכם": true,
"לולכן": true,
"לולנה": true,
"לולנו": true,
"לולני": true,
"לולנך": true,
"לולנם": true,
"לולנן": true,
"לומדה": true,
"לומדי": true,
"לומדת": true,
"לומים": true,
"לונות": true,
"לונית": true,
"לוננת": true,
"לועגת": true,
"לועות": true,
"לועזי": true,
"לועזת": true,
"לועכם": true,
"לועכן": true,
"לוענו": true,
"לועסת": true,
"לופוס": true,
"לופטי": true,
"לופיה": true,
"לופיו": true,
"לופיי": true,
"לופיך": true,
"לופים": true,
"לופכם": true,
"לופכן": true,
"לופנו": true,
"לופפה": true,
"לופפו": true,
"לופפת": true,
"לופתת": true,
"לוקאס": true,
"לוקוס": true,
"לוקות": true,
"לוקחת": true,
"לוקטה": true,
"לוקטו": true,
"לוקטת": true,
"לוקים": true,
"לוקלי": true,
"לוקקה": true,
"לוקקו": true,
"לוקקת": true,
"לורדה": true,
"לורדו": true,
"לורדי": true,
"לורדך": true,
"לורדם": true,
"לורדן": true,
"לוריא": true,
"לורנס": true,
"לושנה": true,
"לזבוח": true,
"לזגזג": true,
"לזהור": true,
"לזהות": true,
"לזוהר": true,
"לזווג": true,
"לזווד": true,
"לזולת": true,
"לזחול": true,
"לזיין": true,
"לזייף": true,
"לזירה": true,
"לזירת": true,
"לזכור": true,
"לזכות": true,
"לזכרו": true,
"לזלוג": true,
"לזלול": true,
"לזלזל": true,
"לזמום": true,
"לזמור": true,
"לזמזם": true,
"לזמרת": true,
"לזנוח": true,
"לזנות": true,
"לזניה": true,
"לזעום": true,
"לזעוף": true,
"לזעוק": true,
"לזעזע": true,
"לזקון": true,
"לזקוף": true,
"לזקור": true,
"לזרוח": true,
"לזרום": true,
"לזרוע": true,
"לזרוק": true,
"לזרות": true,
"לחבוט": true,
"לחבול": true,
"לחבוץ": true,
"לחבוק": true,
"לחבור": true,
"לחבוש": true,
"לחבית": true,
"לחברה": true,
"לחברו": true,
"לחברי": true,
"לחברת": true,
"לחגוג": true,
"לחגור": true,
"לחגים": true,
"לחדול": true,
"לחדור": true,
"לחדרה": true,
"לחדרי": true,
"לחובת": true,
"לחודש": true,
"לחווט": true,
"לחוות": true,
"לחוזה": true,
"לחולה": true,
"לחולי": true,
"לחולל": true,
"לחומה": true,
"לחומר": true,
"לחונן": true,
"לחוסר": true,
"לחופה": true,
"לחופי": true,
"לחופש": true,
"לחוצה": true,
"לחוקי": true,
"לחוקק": true,
"לחורף": true,
"לחורר": true,
"לחותה": true,
"לחותו": true,
"לחותי": true,
"לחותך": true,
"לחותם": true,
"לחותן": true,
"לחזון": true,
"לחזור": true,
"לחזות": true,
"לחזית": true,
"לחזרה": true,
"לחחנה": true,
"לחטוא": true,
"לחטוב": true,
"לחטוף": true,
"לחיוב": true,
"לחיות": true,
"לחייב": true,
"לחייג": true,
"לחייה": true,
"לחייו": true,
"לחייט": true,
"לחייך": true,
"לחייל": true,
"לחיים": true,
"לחיין": true,
"לחיכה": true,
"לחימה": true,
"לחינם": true,
"לחיפה": true,
"לחיצה": true,
"לחיצו": true,
"לחיצי": true,
"לחיצך": true,
"לחיצם": true,
"לחיצן": true,
"לחישה": true,
"לחכוך": true,
"לחכור": true,
"לחכות": true,
"לחכנה": true,
"לחכנו": true,
"לחכתי": true,
"לחכתם": true,
"לחכתן": true,
"לחלוב": true,
"לחלוח": true,
"לחלוט": true,
"לחלום": true,
"לחלון": true,
"לחלוף": true,
"לחלוץ": true,
"לחלוק": true,
"לחלוש": true,
"לחלות": true,
"לחלחה": true,
"לחלחו": true,
"לחלחי": true,
"לחלחל": true,
"לחלחת": true,
"לחללי": true,
"לחמאס": true,
"לחמוד": true,
"לחמול": true,
"לחמוס": true,
"לחמוק": true,
"לחמות": true,
"לחמיה": true,
"לחמיו": true,
"לחמיי": true,
"לחמיך": true,
"לחמים": true,
"לחמית": true,
"לחמכם": true,
"לחמכן": true,
"לחמנה": true,
"לחמנו": true,
"לחמצן": true,
"לחמתה": true,
"לחמתו": true,
"לחמתי": true,
"לחמתך": true,
"לחמתם": true,
"לחמתן": true,
"לחנוט": true,
"לחנוך": true,
"לחנון": true,
"לחנוק": true,
"לחנות": true,
"לחניה": true,
"לחניו": true,
"לחניי": true,
"לחניך": true,
"לחנים": true,
"לחנכם": true,
"לחנכן": true,
"לחננו": true,
"לחסוך": true,
"לחסום": true,
"לחסור": true,
"לחסות": true,
"לחספס": true,
"לחפון": true,
"לחפוף": true,
"לחפוץ": true,
"לחפור": true,
"לחפות": true,
"לחצוב": true,
"לחצוץ": true,
"לחצור": true,
"לחצות": true,
"לחציה": true,
"לחציו": true,
"לחציי": true,
"לחציך": true,
"לחצים": true,
"לחצכם": true,
"לחצכן": true,
"לחצנה": true,
"לחצנו": true,
"לחצני": true,
"לחצנך": true,
"לחצנם": true,
"לחצנן": true,
"לחצצר": true,
"לחצתי": true,
"לחצתם": true,
"לחצתן": true,
"לחקוק": true,
"לחקור": true,
"לחקות": true,
"לחרבן": true,
"לחרוב": true,
"לחרוג": true,
"לחרוד": true,
"לחרוז": true,
"לחרוט": true,
"לחרוך": true,
"לחרוף": true,
"לחרוץ": true,
"לחרוק": true,
"לחרוש": true,
"לחרות": true,
"לחרחר": true,
"לחרטט": true,
"לחרמן": true,
"לחשוב": true,
"לחשוד": true,
"לחשוך": true,
"לחשוף": true,
"לחשוק": true,
"לחשוש": true,
"לחשיה": true,
"לחשיו": true,
"לחשיי": true,
"לחשיך": true,
"לחשים": true,
"לחשכם": true,
"לחשכן": true,
"לחשמל": true,
"לחשנה": true,
"לחשנו": true,
"לחשני": true,
"לחשנך": true,
"לחשנם": true,
"לחשנן": true,
"לחששה": true,
"לחששו": true,
"לחששי": true,
"לחששת": true,
"לחשתי": true,
"לחשתם": true,
"לחשתן": true,
"לחתוך": true,
"לחתול": true,
"לחתום": true,
"לחתור": true,
"לטאות": true,
"לטאטא": true,
"לטאתה": true,
"לטאתו": true,
"לטאתי": true,
"לטאתך": true,
"לטאתם": true,
"לטאתן": true,
"לטבוח": true,
"לטבול": true,
"לטבוע": true,
"לטביה": true,
"לטבים": true,
"לטבית": true,
"לטהור": true,
"לטובה": true,
"לטובת": true,
"לטווח": true,
"לטוות": true,
"לטופף": true,
"לטושה": true,
"לטחון": true,
"לטיול": true,
"לטייב": true,
"לטייח": true,
"לטייל": true,
"לטיני": true,
"לטיסה": true,
"לטיקה": true,
"לטירת": true,
"לטישה": true,
"לטלטל": true,
"לטלין": true,
"לטלפן": true,
"לטמון": true,
"לטמוע": true,
"לטמטם": true,
"לטמיה": true,
"לטמיו": true,
"לטמיי": true,
"לטמיך": true,
"לטמים": true,
"לטנטי": true,
"לטעום": true,
"לטעון": true,
"לטעות": true,
"לטעמו": true,
"לטעמי": true,
"לטענה": true,
"לטענת": true,
"לטפוח": true,
"לטפול": true,
"לטפטף": true,
"לטפנה": true,
"לטקסט": true,
"לטרוד": true,
"לטרוח": true,
"לטרון": true,
"לטרוף": true,
"לטרוק": true,
"לטרור": true,
"לטרטר": true,
"לטרפד": true,
"לטשטש": true,
"לטשנה": true,
"לטשנו": true,
"לטשתי": true,
"לטשתם": true,
"לטשתן": true,
"ליאון": true,
"ליאור": true,
"ליאוש": true,
"ליאמס": true,
"ליארד": true,
"ליבבה": true,
"ליבבו": true,
"ליבבת": true,
"ליבוב": true,
"ליבוי": true,
"ליבון": true,
"ליבוש": true,
"ליבות": true,
"ליביי": true,
"ליבית": true,
"ליבנה": true,
"ליבנו": true,
"ליבני": true,
"ליבנת": true,
"ליברה": true,
"ליברי": true,
"ליברל": true,
"ליבתה": true,
"ליבתו": true,
"ליבתי": true,
"ליבתך": true,
"ליבתם": true,
"ליבתן": true,
"ליגוח": true,
"ליגוע": true,
"ליגות": true,
"ליגתה": true,
"ליגתו": true,
"ליגתי": true,
"ליגתך": true,
"ליגתם": true,
"ליגתן": true,
"לידות": true,
"לידיה": true,
"לידיו": true,
"לידים": true,
"לידתה": true,
"לידתו": true,
"לידתי": true,
"לידתך": true,
"לידתם": true,
"לידתן": true,
"ליהדף": true,
"ליהוה": true,
"ליהוק": true,
"ליהיא": true,
"ליהפך": true,
"ליהקה": true,
"ליהקו": true,
"ליהקת": true,
"ליהרג": true,
"ליווה": true,
"ליווי": true,
"ליוון": true,
"ליומן": true,
"ליוני": true,
"ליוסף": true,
"ליועץ": true,
"ליופי": true,
"ליותר": true,
"ליזום": true,
"ליחוח": true,
"ליחוך": true,
"ליחות": true,
"ליחחה": true,
"ליחחו": true,
"ליחחת": true,
"ליחיד": true,
"ליחים": true,
"ליחכה": true,
"ליחכו": true,
"ליחכת": true,
"ליחסי": true,
"ליחתה": true,
"ליחתו": true,
"ליחתי": true,
"ליחתך": true,
"ליחתם": true,
"ליחתן": true,
"ליטאי": true,
"ליטבק": true,
"ליטול": true,
"ליטוף": true,
"ליטוש": true,
"ליטות": true,
"ליטים": true,
"ליטפה": true,
"ליטפו": true,
"ליטפת": true,
"ליטרה": true,
"ליטשה": true,
"ליטשו": true,
"ליטשת": true,
"לייאש": true,
"לייבא": true,
"לייבב": true,
"לייבה": true,
"לייבם": true,
"לייבש": true,
"לייגע": true,
"ליידי": true,
"ליידע": true,
"לייהד": true,
"לייזע": true,
"לייזר": true,
"לייחד": true,
"לייחל": true,
"לייחם": true,
"לייחס": true,
"ליילד": true,
"ליילל": true,
"ליינן": true,
"ליינס": true,
"לייסד": true,
"לייסף": true,
"לייסר": true,
"לייעד": true,
"לייעל": true,
"לייעץ": true,
"לייער": true,
"לייפת": true,
"לייצא": true,
"לייצב": true,
"לייצג": true,
"לייצו": true,
"לייצר": true,
"לייקר": true,
"ליירט": true,
"ליישב": true,
"ליישם": true,
"ליישן": true,
"ליישר": true,
"לייתר": true,
"ליכבש": true,
"ליכדה": true,
"ליכדו": true,
"ליכדת": true,
"ליכוד": true,
"ליכים": true,
"לילדה": true,
"לילדי": true,
"לילות": true,
"ליליה": true,
"ליליו": true,
"ליליי": true,
"ליליך": true,
"לילים": true,
"לילית": true,
"לילכה": true,
"לילכו": true,
"לילכי": true,
"לילכך": true,
"לילכם": true,
"לילכן": true,
"לילנו": true,
"לימבו": true,
"לימדה": true,
"לימדו": true,
"לימדת": true,
"לימוד": true,
"לימוז": true,
"לימון": true,
"לימור": true,
"לימות": true,
"לימים": true,
"לימין": true,
"לימפה": true,
"לינגר": true,
"לינוק": true,
"לינור": true,
"לינות": true,
"לינים": true,
"לינתה": true,
"לינתו": true,
"לינתי": true,
"לינתך": true,
"לינתם": true,
"לינתן": true,
"ליסוד": true,
"ליסטי": true,
"ליעדך": true,
"ליעקב": true,
"ליפול": true,
"ליפוף": true,
"ליפות": true,
"ליפים": true,
"ליפמן": true,
"ליפפה": true,
"ליפפו": true,
"ליפפת": true,
"ליפתא": true,
"ליצוק": true,
"ליצור": true,
"ליצחק": true,
"ליצלן": true,
"ליצמן": true,
"ליצנה": true,
"ליצנו": true,
"ליצני": true,
"ליצנך": true,
"ליצנם": true,
"ליצנן": true,
"ליקוד": true,
"ליקוט": true,
"ליקוי": true,
"ליקום": true,
"ליקון": true,
"ליקוק": true,
"ליקטה": true,
"ליקטו": true,
"ליקטת": true,
"ליקיא": true,
"ליקים": true,
"ליקיר": true,
"ליקקה": true,
"ליקקו": true,
"ליקקת": true,
"לירדן": true,
"לירוא": true,
"לירון": true,
"לירוק": true,
"לירות": true,
"לירית": true,
"לישוב": true,
"לישון": true,
"לישות": true,
"לישתה": true,
"לישתו": true,
"לישתי": true,
"לישתך": true,
"לישתם": true,
"לישתן": true,
"לכאבי": true,
"לכאוב": true,
"לכאוס": true,
"לכאלה": true,
"לכבוד": true,
"לכבול": true,
"לכבוש": true,
"לכבות": true,
"לכביש": true,
"לכדור": true,
"לכדנה": true,
"לכדנו": true,
"לכדרר": true,
"לכדתי": true,
"לכדתם": true,
"לכדתן": true,
"לכהות": true,
"לכודה": true,
"לכוון": true,
"לכווץ": true,
"לכוכב": true,
"לכולם": true,
"לכולן": true,
"לכונן": true,
"לכופף": true,
"לכושר": true,
"לכותל": true,
"לכחכח": true,
"לכידה": true,
"לכידת": true,
"לכייל": true,
"לכייס": true,
"לכייף": true,
"לכייר": true,
"לכיכר": true,
"לכיסא": true,
"לכיתה": true,
"לכלוא": true,
"לכלוך": true,
"לכלול": true,
"לכלום": true,
"לכלות": true,
"לכלים": true,
"לכלכה": true,
"לכלכו": true,
"לכלכי": true,
"לכלכל": true,
"לכלכן": true,
"לכלכת": true,
"לכללי": true,
"לכמוה": true,
"לכמוש": true,
"לכמות": true,
"לכנוס": true,
"לכנות": true,
"לכנסת": true,
"לכנרת": true,
"לכסון": true,
"לכסוס": true,
"לכסות": true,
"לכסנה": true,
"לכסנו": true,
"לכסני": true,
"לכסנת": true,
"לכעוס": true,
"לכפול": true,
"לכפוף": true,
"לכפור": true,
"לכפות": true,
"לכפתר": true,
"לכרבל": true,
"לכרוך": true,
"לכרוע": true,
"לכרות": true,
"לכרטס": true,
"לכרכר": true,
"לכרמי": true,
"לכרמל": true,
"לכרסם": true,
"לכשול": true,
"לכשכש": true,
"לכתבה": true,
"לכתוב": true,
"לכתוש": true,
"לכתיה": true,
"לכתיו": true,
"לכתיי": true,
"לכתיך": true,
"לכתים": true,
"לכתית": true,
"לכתכם": true,
"לכתכן": true,
"לכתנו": true,
"ללאוט": true,
"ללבוש": true,
"ללבות": true,
"ללבלב": true,
"ללגום": true,
"ללגלג": true,
"ללהוט": true,
"ללהטט": true,
"ללהיט": true,
"ללוות": true,
"ללחוך": true,
"ללחום": true,
"ללחוץ": true,
"ללחוש": true,
"ללחלח": true,
"ללחשש": true,
"ללטוש": true,
"לליגה": true,
"לליגת": true,
"ללידה": true,
"לליטר": true,
"ללילה": true,
"ללינה": true,
"ללכוד": true,
"ללכלך": true,
"ללכסן": true,
"ללמוד": true,
"ללעוג": true,
"ללעוס": true,
"ללפות": true,
"ללפיד": true,
"ללקוח": true,
"ללקוט": true,
"ללקות": true,
"ללשון": true,
"ללשכת": true,
"למאבק": true,
"למאגר": true,
"למאוד": true,
"למאוס": true,
"למאור": true,
"למאות": true,
"למאיר": true,
"למאכל": true,
"למאמן": true,
"למאמץ": true,
"למאמר": true,
"למאסר": true,
"למבוא": true,
"למבחן": true,
"למבנה": true,
"למבצע": true,
"למבקר": true,
"למבקש": true,
"למגדל": true,
"למגזר": true,
"למגמה": true,
"למגנט": true,
"למגרש": true,
"למדבר": true,
"למדוד": true,
"למדות": true,
"למדים": true,
"למדנה": true,
"למדנו": true,
"למדני": true,
"למדנך": true,
"למדנם": true,
"למדנן": true,
"למדעי": true,
"למדרי": true,
"למדתה": true,
"למדתו": true,
"למדתי": true,
"למדתך": true,
"למדתם": true,
"למדתן": true,
"למהול": true,
"למהות": true,
"למהלך": true,
"למודה": true,
"למודל": true,
"למוות": true,
"למוטט": true,
"למוכר": true,
"למולל": true,
"למונח": true,
"למוסד": true,
"למוסך": true,
"למוסס": true,
"למועד": true,
"למופע": true,
"למופת": true,
"למוצא": true,
"למוצר": true,
"למוקד": true,
"למורה": true,
"למושב": true,
"למושג": true,
"למותג": true,
"למותו": true,
"למותם": true,
"למותר": true,
"למזגן": true,
"למזוג": true,
"למזון": true,
"למזלי": true,
"למזמז": true,
"למזער": true,
"למזרח": true,
"למחאה": true,
"למחוא": true,
"למחוז": true,
"למחול": true,
"למחוץ": true,
"למחוק": true,
"למחות": true,
"למחזר": true,
"למחיר": true,
"למחלה": true,
"למחלף": true,
"למחלת": true,
"למחנה": true,
"למחסן": true,
"למחצה": true,
"למחקר": true,
"למחרת": true,
"למחשב": true,
"למטבח": true,
"למטוס": true,
"למטרה": true,
"למטרת": true,
"למידה": true,
"למידע": true,
"למידת": true,
"למיון": true,
"למיות": true,
"למיזם": true,
"למיטב": true,
"למיטה": true,
"למייל": true,
"למיין": true,
"למיכל": true,
"למילה": true,
"למילי": true,
"למימר": true,
"למינה": true,
"למיני": true,
"למירב": true,
"למכאח": true,
"למכבי": true,
"למכון": true,
"למכור": true,
"למכרז": true,
"למכתב": true,
"למלוך": true,
"למלון": true,
"למלוק": true,
"למלכד": true,
"למלמל": true,
"למלצר": true,
"לממשל": true,
"למנהג": true,
"למנהל": true,
"למנוע": true,
"למנות": true,
"למניה": true,
"למסגר": true,
"למסוך": true,
"למסוק": true,
"למסור": true,
"למסות": true,
"למסחר": true,
"למסטל": true,
"למסמך": true,
"למסמס": true,
"למסמר": true,
"למספר": true,
"למעבר": true,
"למעגל": true,
"למעגן": true,
"למעוד": true,
"למעוז": true,
"למעוך": true,
"למעול": true,
"למעון": true,
"למעין": true,
"למעלה": true,
"למעמד": true,
"למענה": true,
"למענו": true,
"למעני": true,
"למענך": true,
"למענם": true,
"למענן": true,
"למעצר": true,
"למעקב": true,
"למערב": true,
"למערך": true,
"למערת": true,
"למעשה": true,
"למעשי": true,
"למפגש": true,
"למפות": true,
"למפעל": true,
"למפקד": true,
"למפרע": true,
"למצבה": true,
"למצבו": true,
"למצבי": true,
"למצוא": true,
"למצוץ": true,
"למצות": true,
"למצמץ": true,
"למצער": true,
"למצפה": true,
"למקדש": true,
"למקום": true,
"למקור": true,
"למקסם": true,
"למקרא": true,
"למקרה": true,
"למקרר": true,
"למראה": true,
"למרבה": true,
"למרוד": true,
"למרוח": true,
"למרוט": true,
"למרום": true,
"למרות": true,
"למרחב": true,
"למרחק": true,
"למרכז": true,
"למרמס": true,
"למרפק": true,
"למשבר": true,
"למשגב": true,
"למשהו": true,
"למשוח": true,
"למשוך": true,
"למשול": true,
"למשות": true,
"למשחק": true,
"למשטר": true,
"למשיב": true,
"למשכן": true,
"למשמע": true,
"למשמר": true,
"למשמש": true,
"למשעי": true,
"למשפט": true,
"למשקל": true,
"למשרד": true,
"למשרה": true,
"למשרת": true,
"למתוח": true,
"למתזי": true,
"למתחם": true,
"למתקן": true,
"לנאום": true,
"לנאוף": true,
"לנאוק": true,
"לנאות": true,
"לנאשם": true,
"לנבוח": true,
"לנבוט": true,
"לנבול": true,
"לנבוע": true,
"לנבור": true,
"לנגוה": true,
"לנגוח": true,
"לנגוס": true,
"לנגוע": true,
"לנגוש": true,
"לנדאו": true,
"לנדוד": true,
"לנדוי": true,
"לנדום": true,
"לנדוף": true,
"לנדור": true,
"לנדות": true,
"לנדנד": true,
"לנהוג": true,
"לנהום": true,
"לנהור": true,
"לנהות": true,
"לנווה": true,
"לנווט": true,
"לנוון": true,
"לנוכח": true,
"לנוסח": true,
"לנוער": true,
"לנופי": true,
"לנופף": true,
"לנופש": true,
"לנושא": true,
"לנזול": true,
"לנזוף": true,
"לנחול": true,
"לנחור": true,
"לנחות": true,
"לנחלה": true,
"לנטול": true,
"לנטוע": true,
"לנטוף": true,
"לנטור": true,
"לנטוש": true,
"לנטות": true,
"לנטרל": true,
"לנייד": true,
"לנירת": true,
"לנכוח": true,
"לנכון": true,
"לנכות": true,
"לנכים": true,
"לנמוך": true,
"לנמוס": true,
"לנמנם": true,
"לנסוך": true,
"לנסוע": true,
"לנסוק": true,
"לנסות": true,
"לנסקי": true,
"לנעול": true,
"לנעום": true,
"לנעוץ": true,
"לנעור": true,
"לנענע": true,
"לנפגע": true,
"לנפוח": true,
"לנפול": true,
"לנפוש": true,
"לנפות": true,
"לנפנף": true,
"לנצוץ": true,
"לנצור": true,
"לנציג": true,
"לנצנץ": true,
"לנצרת": true,
"לנקוב": true,
"לנקוט": true,
"לנקום": true,
"לנקוע": true,
"לנקוף": true,
"לנקור": true,
"לנקוש": true,
"לנקות": true,
"לנרמל": true,
"לנשוב": true,
"לנשוך": true,
"לנשול": true,
"לנשום": true,
"לנשוף": true,
"לנשוק": true,
"לנשור": true,
"לנשיא": true,
"לנשים": true,
"לנשמה": true,
"לנשנש": true,
"לנתבע": true,
"לנתיב": true,
"לסאון": true,
"לסבוא": true,
"לסבוב": true,
"לסבול": true,
"לסבור": true,
"לסבית": true,
"לסבסד": true,
"לסגוד": true,
"לסגור": true,
"לסגנן": true,
"לסדוק": true,
"לסדרה": true,
"לסדרת": true,
"לסובב": true,
"לסוגי": true,
"לסווג": true,
"לסוטה": true,
"לסוכך": true,
"לסוכן": true,
"לסופר": true,
"לסוצי": true,
"לסורם": true,
"לסותו": true,
"לסחוב": true,
"לסחוט": true,
"לסחוף": true,
"לסחור": true,
"לסחרר": true,
"לסטור": true,
"לסטות": true,
"לסטים": true,
"לסיום": true,
"לסיוע": true,
"לסיור": true,
"לסייג": true,
"לסייד": true,
"לסיים": true,
"לסייע": true,
"לסייר": true,
"לסכוך": true,
"לסכום": true,
"לסכור": true,
"לסכנה": true,
"לסכסך": true,
"לסלאו": true,
"לסלוד": true,
"לסלוח": true,
"לסלול": true,
"לסלון": true,
"לסלסל": true,
"לסמוך": true,
"לסמור": true,
"לסמים": true,
"לסמלץ": true,
"לסנגר": true,
"לסנדל": true,
"לסנוט": true,
"לסניף": true,
"לסנתז": true,
"לסעוד": true,
"לסעור": true,
"לסעיף": true,
"לספוג": true,
"לספוד": true,
"לספוח": true,
"לספוק": true,
"לספור": true,
"לספסר": true,
"לספרד": true,
"לספרו": true,
"לספרי": true,
"לספרר": true,
"לסקוב": true,
"לסקול": true,
"לסקור": true,
"לסקרן": true,
"לסרבל": true,
"לסרוג": true,
"לסרוח": true,
"לסרוק": true,
"לסרור": true,
"לסרטי": true,
"לסרטן": true,
"לסרסר": true,
"לסתום": true,
"לסתור": true,
"לסתות": true,
"לסתכם": true,
"לסתכן": true,
"לסתנו": true,
"לעבוד": true,
"לעבוט": true,
"לעבור": true,
"לעבות": true,
"לעברה": true,
"לעברו": true,
"לעברי": true,
"לעברם": true,
"לעגוב": true,
"לעגון": true,
"לעגיה": true,
"לעגיו": true,
"לעגיי": true,
"לעגיך": true,
"לעגים": true,
"לעגכם": true,
"לעגכן": true,
"לעגנה": true,
"לעגנו": true,
"לעגני": true,
"לעגתי": true,
"לעגתם": true,
"לעגתן": true,
"לעדור": true,
"לעדות": true,
"לעדכן": true,
"לעובד": true,
"לעובר": true,
"לעודד": true,
"לעוור": true,
"לעוות": true,
"לעולל": true,
"לעולם": true,
"לעומק": true,
"לעומת": true,
"לעונה": true,
"לעונש": true,
"לעונת": true,
"לעוסה": true,
"לעופף": true,
"לעופר": true,
"לעורך": true,
"לעורר": true,
"לעזאם": true,
"לעזוב": true,
"לעזור": true,
"לעזיה": true,
"לעזיו": true,
"לעזיי": true,
"לעזיך": true,
"לעזים": true,
"לעזכם": true,
"לעזכן": true,
"לעזנו": true,
"לעזרה": true,
"לעזרי": true,
"לעזרת": true,
"לעטוף": true,
"לעטות": true,
"לעידן": true,
"לעיון": true,
"לעיוף": true,
"לעיין": true,
"לעייף": true,
"לעייש": true,
"לעילא": true,
"לעיני": true,
"לעיסה": true,
"לעכור": true,
"לעלוב": true,
"לעלוז": true,
"לעלוץ": true,
"לעלות": true,
"לעליה": true,
"לעלעל": true,
"לעמדה": true,
"לעמדת": true,
"לעמוד": true,
"לעמול": true,
"לעמית": true,
"לעמלן": true,
"לעמעם": true,
"לענאן": true,
"לענוב": true,
"לענוד": true,
"לענות": true,
"לענטז": true,
"לענין": true,
"לעסוק": true,
"לעסות": true,
"לעסנה": true,
"לעסנו": true,
"לעסקה": true,
"לעסתי": true,
"לעסתם": true,
"לעסתן": true,
"לעפעף": true,
"לעצבב": true,
"לעצבן": true,
"לעצום": true,
"לעצור": true,
"לעצים": true,
"לעצמה": true,
"לעצמו": true,
"לעצמי": true,
"לעצמם": true,
"לעצמן": true,
"לעקוב": true,
"לעקוד": true,
"לעקוף": true,
"לעקוץ": true,
"לעקור": true,
"לעקצץ": true,
"לערבב": true,
"לערבל": true,
"לערגל": true,
"לערוב": true,
"לערוג": true,
"לערוך": true,
"לערום": true,
"לערוף": true,
"לערוץ": true,
"לערוק": true,
"לערטל": true,
"לערים": true,
"לערכי": true,
"לערער": true,
"לערפל": true,
"לעשוק": true,
"לעשור": true,
"לעשות": true,
"לעשרה": true,
"לעתור": true,
"לעתיד": true,
"לעתים": true,
"לפארק": true,
"לפברק": true,
"לפגוט": true,
"לפגום": true,
"לפגוע": true,
"לפגוש": true,
"לפדות": true,
"לפואד": true,
"לפוגג": true,
"לפוסט": true,
"לפועל": true,
"לפוצץ": true,
"לפורט": true,
"לפורר": true,
"לפותה": true,
"לפזול": true,
"לפחוד": true,
"לפחות": true,
"לפחלץ": true,
"לפטופ": true,
"לפטור": true,
"לפטפט": true,
"לפטרל": true,
"לפידה": true,
"לפידו": true,
"לפידי": true,
"לפידך": true,
"לפידם": true,
"לפידן": true,
"לפיהם": true,
"לפיהן": true,
"לפיות": true,
"לפייח": true,
"לפייט": true,
"לפיים": true,
"לפייס": true,
"לפיכך": true,
"לפינה": true,
"לפינת": true,
"לפיתה": true,
"לפכור": true,
"לפכות": true,
"לפכפך": true,
"לפלוט": true,
"לפלוש": true,
"לפלות": true,
"לפלמר": true,
"לפלנד": true,
"לפלפל": true,
"לפמפם": true,
"לפנהס": true,
"לפנות": true,
"לפנטז": true,
"לפניה": true,
"לפניו": true,
"לפניי": true,
"לפניך": true,
"לפנים": true,
"לפסגת": true,
"לפסוח": true,
"לפסול": true,
"לפסוע": true,
"לפסוק": true,
"לפסטר": true,
"לפסיכ": true,
"לפספס": true,
"לפעול": true,
"לפעום": true,
"לפעור": true,
"לפעות": true,
"לפענח": true,
"לפעפע": true,
"לפפנה": true,
"לפצוח": true,
"לפצוע": true,
"לפצור": true,
"לפצות": true,
"לפצפץ": true,
"לפקוד": true,
"לפקוח": true,
"לפקוע": true,
"לפקוק": true,
"לפקטר": true,
"לפקיד": true,
"לפקסס": true,
"לפקפק": true,
"לפקשש": true,
"לפרגן": true,
"לפרדס": true,
"לפרוח": true,
"לפרוט": true,
"לפרום": true,
"לפרוס": true,
"לפרוע": true,
"לפרופ": true,
"לפרוץ": true,
"לפרוק": true,
"לפרוש": true,
"לפרות": true,
"לפרזל": true,
"לפרטי": true,
"לפריז": true,
"לפרין": true,
"לפרכס": true,
"לפרמט": true,
"לפרנס": true,
"לפרסם": true,
"לפרעה": true,
"לפרפר": true,
"לפרשה": true,
"לפרשן": true,
"לפרשת": true,
"לפשוט": true,
"לפשוע": true,
"לפשות": true,
"לפשפש": true,
"לפשרה": true,
"לפתוח": true,
"לפתור": true,
"לפתות": true,
"לפתחו": true,
"לפתית": true,
"לפתכם": true,
"לפתכן": true,
"לפתנה": true,
"לפתני": true,
"לפתנך": true,
"לפתנם": true,
"לפתנן": true,
"לצבוא": true,
"לצבוט": true,
"לצבוע": true,
"לצבור": true,
"לצבות": true,
"לצברח": true,
"לצדוק": true,
"לצדיק": true,
"לצדקה": true,
"לצהול": true,
"לצודד": true,
"לצווח": true,
"לצוות": true,
"לצומת": true,
"לצונה": true,
"לצונו": true,
"לצוני": true,
"לצונך": true,
"לצונם": true,
"לצונן": true,
"לצופה": true,
"לצופף": true,
"לצורה": true,
"לצורך": true,
"לצורת": true,
"לצותת": true,
"לצחוק": true,
"לצחצח": true,
"לצחקק": true,
"לצידה": true,
"לצידו": true,
"לצידם": true,
"לציהם": true,
"לציהן": true,
"לציוד": true,
"לציון": true,
"לציור": true,
"לצייד": true,
"לצייך": true,
"לציין": true,
"לצייץ": true,
"לצייר": true,
"לציית": true,
"לציכם": true,
"לציכן": true,
"לצימר": true,
"לצינו": true,
"לצלוב": true,
"לצלוח": true,
"לצלול": true,
"לצלוע": true,
"לצלוף": true,
"לצלות": true,
"לצלחת": true,
"לצלצל": true,
"לצמוא": true,
"לצמוח": true,
"לצמצם": true,
"לצמרר": true,
"לצנוח": true,
"לצנזר": true,
"לצנרת": true,
"לצנתר": true,
"לצעוד": true,
"לצעוק": true,
"לצערי": true,
"לצפון": true,
"לצפור": true,
"לצפות": true,
"לצפיה": true,
"לצפצף": true,
"לצקצק": true,
"לצרוב": true,
"לצרוח": true,
"לצרוך": true,
"לצרום": true,
"לצרוף": true,
"לצרור": true,
"לצרכי": true,
"לצרכן": true,
"לצרפת": true,
"לצרצר": true,
"לקארל": true,
"לקבול": true,
"לקבוע": true,
"לקבור": true,
"לקבלה": true,
"לקבלו": true,
"לקבלן": true,
"לקבלת": true,
"לקדוח": true,
"לקדום": true,
"לקדור": true,
"לקהות": true,
"לקובץ": true,
"לקודד": true,
"לקווי": true,
"לקוות": true,
"לקוחה": true,
"לקוחו": true,
"לקוחי": true,
"לקוחך": true,
"לקוחם": true,
"לקוחן": true,
"לקויה": true,
"לקויי": true,
"לקומה": true,
"לקומם": true,
"לקונה": true,
"לקונט": true,
"לקוני": true,
"לקונן": true,
"לקונס": true,
"לקונצ": true,
"לקופה": true,
"לקופח": true,
"לקופת": true,
"לקורא": true,
"לקורס": true,
"לקושי": true,
"לקושש": true,
"לקותה": true,
"לקותו": true,
"לקותי": true,
"לקותך": true,
"לקותם": true,
"לקותן": true,
"לקחות": true,
"לקחיה": true,
"לקחיו": true,
"לקחיי": true,
"לקחיך": true,
"לקחים": true,
"לקחכם": true,
"לקחכן": true,
"לקחנו": true,
"לקחתי": true,
"לקחתם": true,
"לקחתן": true,
"לקטוז": true,
"לקטול": true,
"לקטום": true,
"לקטון": true,
"לקטוע": true,
"לקטוף": true,
"לקטיה": true,
"לקטיו": true,
"לקטיי": true,
"לקטיך": true,
"לקטים": true,
"לקטית": true,
"לקטכם": true,
"לקטכן": true,
"לקטלג": true,
"לקטנה": true,
"לקטנו": true,
"לקטרג": true,
"לקיום": true,
"לקיות": true,
"לקיחה": true,
"לקיחת": true,
"לקייה": true,
"לקיים": true,
"לקייץ": true,
"לקינה": true,
"לקינו": true,
"לקיקה": true,
"לקיתי": true,
"לקיתם": true,
"לקיתן": true,
"לקלוד": true,
"לקלוח": true,
"לקלוט": true,
"לקלוע": true,
"לקלוף": true,
"לקלות": true,
"לקלטר": true,
"לקלקל": true,
"לקמול": true,
"לקמוס": true,
"לקמוץ": true,
"לקנוס": true,
"לקנות": true,
"לקנטר": true,
"לקסום": true,
"לקסיה": true,
"לקעקע": true,
"לקערה": true,
"לקפוא": true,
"לקפוח": true,
"לקפוץ": true,
"לקצבה": true,
"לקצוב": true,
"לקצוף": true,
"לקצוץ": true,
"לקצור": true,
"לקציה": true,
"לקצין": true,
"לקקנה": true,
"לקראט": true,
"לקראת": true,
"לקרדה": true,
"לקרוא": true,
"לקרוב": true,
"לקרום": true,
"לקרון": true,
"לקרוס": true,
"לקרוע": true,
"לקרוץ": true,
"לקרות": true,
"לקרטע": true,
"לקרית": true,
"לקרני": true,
"לקרצף": true,
"לקרקע": true,
"לקרקף": true,
"לקרקר": true,
"לקרקש": true,
"לקשור": true,
"לקשות": true,
"לקשקש": true,
"לראות": true,
"לראשו": true,
"לראשי": true,
"לרבוץ": true,
"לרבות": true,
"לרבים": true,
"לרגוז": true,
"לרגום": true,
"לרגון": true,
"לרגוע": true,
"לרגוש": true,
"לרגלי": true,
"לרדוף": true,
"לרדות": true,
"לרדיו": true,
"לרווח": true,
"לרוות": true,
"לרוחב": true,
"לרומא": true,
"לרומם": true,
"לרועי": true,
"לרופא": true,
"לרופף": true,
"לרוצץ": true,
"לרוקן": true,
"לרושש": true,
"לרזות": true,
"לרחבה": true,
"לרחוב": true,
"לרחוץ": true,
"לרחוק": true,
"לרחוש": true,
"לרחרח": true,
"לרטוט": true,
"לרטון": true,
"לריצה": true,
"לרכבת": true,
"לרכוב": true,
"לרכון": true,
"לרכוס": true,
"לרכוש": true,
"לרכזת": true,
"לרמוז": true,
"לרמוס": true,
"לרמות": true,
"לרמזר": true,
"לרנקה": true,
"לרעהו": true,
"לרעוב": true,
"לרעוד": true,
"לרעום": true,
"לרעוש": true,
"לרעות": true,
"לרענן": true,
"לרפות": true,
"לרפרף": true,
"לרצוח": true,
"לרצון": true,
"לרצוע": true,
"לרצוץ": true,
"לרצות": true,
"לרצפה": true,
"לרקוב": true,
"לרקוד": true,
"לרקוח": true,
"לרקום": true,
"לרקוע": true,
"לרקוק": true,
"לרשבא": true,
"לרשום": true,
"לרשוף": true,
"לרשות": true,
"לרשרש": true,
"לרתוח": true,
"לרתום": true,
"לרתוע": true,
"לשאוב": true,
"לשאוג": true,
"לשאול": true,
"לשאוף": true,
"לשאלה": true,
"לשאלת": true,
"לשבוע": true,
"לשבוק": true,
"לשבור": true,
"לשבות": true,
"לשביל": true,
"לשבעה": true,
"לשגוג": true,
"לשגות": true,
"לשגרה": true,
"לשגרת": true,
"לשגשג": true,
"לשדוד": true,
"לשדות": true,
"לשדכם": true,
"לשדכן": true,
"לשדנו": true,
"לשדרג": true,
"לשהות": true,
"לשואה": true,
"לשובב": true,
"לשווא": true,
"לשווי": true,
"לשווע": true,
"לשווק": true,
"לשוות": true,
"לשוחח": true,
"לשוטט": true,
"לשוטר": true,
"לשונה": true,
"לשונו": true,
"לשוני": true,
"לשונך": true,
"לשונם": true,
"לשונן": true,
"לשופט": true,
"לשורש": true,
"לשזוף": true,
"לשזור": true,
"לשחבר": true,
"לשחוט": true,
"לשחוק": true,
"לשחות": true,
"לשחזר": true,
"לשחלף": true,
"לשחקן": true,
"לשחרר": true,
"לשטוח": true,
"לשטום": true,
"לשטוף": true,
"לשטות": true,
"לשטחי": true,
"לשטיח": true,
"לשיאה": true,
"לשיאו": true,
"לשיחה": true,
"לשיחת": true,
"לשיטה": true,
"לשיטת": true,
"לשייט": true,
"לשייך": true,
"לשייף": true,
"לשימו": true,
"לשינה": true,
"לשיער": true,
"לשירה": true,
"לשישה": true,
"לשכוח": true,
"לשכוך": true,
"לשכול": true,
"לשכון": true,
"לשכור": true,
"לשכות": true,
"לשכלל": true,
"לשכנע": true,
"לשכפל": true,
"לשכשך": true,
"לשכתב": true,
"לשכתה": true,
"לשכתו": true,
"לשכתי": true,
"לשכתך": true,
"לשכתם": true,
"לשכתן": true,
"לשלהב": true,
"לשלוח": true,
"לשלוט": true,
"לשלול": true,
"לשלום": true,
"לשלוף": true,
"לשלוק": true,
"לשלוש": true,
"לשלות": true,
"לשלטט": true,
"לשלמה": true,
"לשלשל": true,
"לשלשת": true,
"לשמאל": true,
"לשמוח": true,
"לשמוט": true,
"לשמון": true,
"לשמוע": true,
"לשמור": true,
"לשמות": true,
"לשמחה": true,
"לשמים": true,
"לשמצה": true,
"לשנוא": true,
"לשנות": true,
"לשניה": true,
"לשנים": true,
"לשסות": true,
"לשעבד": true,
"לשעבר": true,
"לשעוט": true,
"לשעון": true,
"לשעור": true,
"לשעות": true,
"לשעמם": true,
"לשערי": true,
"לשערך": true,
"לשעשע": true,
"לשעתק": true,
"לשפוט": true,
"לשפוך": true,
"לשפול": true,
"לשפוע": true,
"לשפות": true,
"לשפעל": true,
"לשפצר": true,
"לשפשף": true,
"לשצוף": true,
"לשקוד": true,
"לשקוט": true,
"לשקול": true,
"לשקוע": true,
"לשקוק": true,
"לשקלל": true,
"לשקשק": true,
"לשרבב": true,
"לשרבט": true,
"לשרוד": true,
"לשרוט": true,
"לשרוך": true,
"לשרוף": true,
"לשרוץ": true,
"לשרוק": true,
"לשרור": true,
"לשרות": true,
"לשרטט": true,
"לשרשר": true,
"לשתול": true,
"לשתוק": true,
"לשתות": true,
"לשתיה": true,
"לתאגד": true,
"לתאום": true,
"לתאים": true,
"לתארך": true,
"לתבוע": true,
"לתגבר": true,
"לתגמל": true,
"לתדלק": true,
"לתדרך": true,
"לתהות": true,
"לתואר": true,
"לתובע": true,
"לתווך": true,
"לתוכה": true,
"לתוכו": true,
"לתוכן": true,
"לתופף": true,
"לתוקף": true,
"לתורה": true,
"לתורת": true,
"לתושב": true,
"לתזמן": true,
"לתזמר": true,
"לתחבל": true,
"לתחוב": true,
"לתחום": true,
"לתחזק": true,
"לתחמן": true,
"לתחנה": true,
"לתחנת": true,
"לתחקר": true,
"לתיבת": true,
"לתייג": true,
"לתייק": true,
"לתייר": true,
"לתכנן": true,
"לתכנת": true,
"לתלוש": true,
"לתלות": true,
"לתלמי": true,
"לתלתל": true,
"לתמוה": true,
"לתמוך": true,
"לתמחר": true,
"לתמיד": true,
"לתמלל": true,
"לתמצת": true,
"לתמרן": true,
"לתמרץ": true,
"לתמרר": true,
"לתנאי": true,
"לתנור": true,
"לתנות": true,
"לתסוס": true,
"לתסכל": true,
"לתסרט": true,
"לתעדף": true,
"לתעות": true,
"לתעתע": true,
"לתעתק": true,
"לתפוח": true,
"לתפוס": true,
"לתפור": true,
"לתפוש": true,
"לתפעל": true,
"לתפקד": true,
"לתצפת": true,
"לתקוע": true,
"לתקוף": true,
"לתקלט": true,
"לתקנה": true,
"לתקצב": true,
"לתקצר": true,
"לתקרה": true,
"לתקשר": true,
"לתקתק": true,
"לתרבת": true,
"לתרגל": true,
"לתרגם": true,
"לתרום": true,
"לתשאל": true,
"לתתכם": true,
"לתתכן": true,
"לתתנו": true,
"מאבדת": true,
"מאבות": true,
"מאבזר": true,
"מאבחן": true,
"מאבטח": true,
"מאביו": true,
"מאביס": true,
"מאביק": true,
"מאבני": true,
"מאבנת": true,
"מאבקה": true,
"מאבקו": true,
"מאבקי": true,
"מאבקך": true,
"מאבקם": true,
"מאבקן": true,
"מאבקת": true,
"מאגדת": true,
"מאגמת": true,
"מאגפת": true,
"מאגרה": true,
"מאגרו": true,
"מאגרי": true,
"מאגרך": true,
"מאגרם": true,
"מאגרן": true,
"מאגרף": true,
"מאדות": true,
"מאדים": true,
"מאדיר": true,
"מאהבה": true,
"מאהבו": true,
"מאהבי": true,
"מאהבך": true,
"מאהבם": true,
"מאהבן": true,
"מאהבת": true,
"מאהדי": true,
"מאהיב": true,
"מאהיל": true,
"מאהלה": true,
"מאהלו": true,
"מאהלי": true,
"מאהלך": true,
"מאהלם": true,
"מאהלן": true,
"מאובן": true,
"מאובק": true,
"מאוגד": true,
"מאוגף": true,
"מאודה": true,
"מאוהב": true,
"מאוזן": true,
"מאוחד": true,
"מאוחה": true,
"מאוחל": true,
"מאוחר": true,
"מאויד": true,
"מאוים": true,
"מאוין": true,
"מאויר": true,
"מאויש": true,
"מאוית": true,
"מאוכל": true,
"מאוכן": true,
"מאולף": true,
"מאולץ": true,
"מאומה": true,
"מאומן": true,
"מאומץ": true,
"מאומת": true,
"מאונך": true,
"מאונן": true,
"מאוסה": true,
"מאופי": true,
"מאופס": true,
"מאופק": true,
"מאופר": true,
"מאורג": true,
"מאורה": true,
"מאורו": true,
"מאורי": true,
"מאורך": true,
"מאורם": true,
"מאורן": true,
"מאורס": true,
"מאורע": true,
"מאושר": true,
"מאושש": true,
"מאותה": true,
"מאותו": true,
"מאותם": true,
"מאותן": true,
"מאותר": true,
"מאותת": true,
"מאזאר": true,
"מאזדה": true,
"מאזוז": true,
"מאזור": true,
"מאזין": true,
"מאזכר": true,
"מאזנה": true,
"מאזנו": true,
"מאזני": true,
"מאזנך": true,
"מאזנם": true,
"מאזנן": true,
"מאזנת": true,
"מאזרח": true,
"מאחדת": true,
"מאחור": true,
"מאחות": true,
"מאחזה": true,
"מאחזו": true,
"מאחזי": true,
"מאחזך": true,
"מאחזם": true,
"מאחזן": true,
"מאחזר": true,
"מאחזת": true,
"מאחיד": true,
"מאחים": true,
"מאחלת": true,
"מאחסן": true,
"מאחרי": true,
"מאחרת": true,
"מאטות": true,
"מאטים": true,
"מאידך": true,
"מאיות": true,
"מאיזה": true,
"מאיזו": true,
"מאייד": true,
"מאייה": true,
"מאיים": true,
"מאיין": true,
"מאייר": true,
"מאייש": true,
"מאיית": true,
"מאילת": true,
"מאיסה": true,
"מאיפה": true,
"מאיצה": true,
"מאיצו": true,
"מאיצי": true,
"מאיצך": true,
"מאיצם": true,
"מאיצן": true,
"מאירה": true,
"מאירי": true,
"מאיתה": true,
"מאיתו": true,
"מאיתי": true,
"מאיתך": true,
"מאיתם": true,
"מאיתן": true,
"מאכזב": true,
"מאכיל": true,
"מאכלה": true,
"מאכלו": true,
"מאכלי": true,
"מאכלך": true,
"מאכלם": true,
"מאכלן": true,
"מאכלס": true,
"מאכלת": true,
"מאכנת": true,
"מאלון": true,
"מאלחש": true,
"מאליה": true,
"מאליו": true,
"מאלית": true,
"מאלפי": true,
"מאלפת": true,
"מאלצת": true,
"מאלתר": true,
"מאמין": true,
"מאמיר": true,
"מאמלל": true,
"מאמנה": true,
"מאמנו": true,
"מאמני": true,
"מאמנך": true,
"מאמנם": true,
"מאמנן": true,
"מאמנת": true,
"מאמצה": true,
"מאמצו": true,
"מאמצי": true,
"מאמצך": true,
"מאמצם": true,
"מאמצן": true,
"מאמצע": true,
"מאמצת": true,
"מאמרה": true,
"מאמרו": true,
"מאמרי": true,
"מאמרך": true,
"מאמרם": true,
"מאמרן": true,
"מאמתת": true,
"מאנאס": true,
"מאניש": true,
"מאנית": true,
"מאנפף": true,
"מאנשי": true,
"מאסטר": true,
"מאסלם": true,
"מאסנה": true,
"מאסנו": true,
"מאספת": true,
"מאסרה": true,
"מאסרו": true,
"מאסרי": true,
"מאסרך": true,
"מאסרם": true,
"מאסרן": true,
"מאסתי": true,
"מאסתם": true,
"מאסתן": true,
"מאפהו": true,
"מאפיה": true,
"מאפיו": true,
"מאפיי": true,
"מאפיך": true,
"מאפיל": true,
"מאפים": true,
"מאפיר": true,
"מאפכם": true,
"מאפכן": true,
"מאפנו": true,
"מאפנן": true,
"מאפסת": true,
"מאפרה": true,
"מאפרת": true,
"מאפשר": true,
"מאציל": true,
"מאקרו": true,
"מארבה": true,
"מארבו": true,
"מארבי": true,
"מארבך": true,
"מארבם": true,
"מארבן": true,
"מארבע": true,
"מארגה": true,
"מארגו": true,
"מארגי": true,
"מארגך": true,
"מארגם": true,
"מארגן": true,
"מארזה": true,
"מארזו": true,
"מארזי": true,
"מארזך": true,
"מארזם": true,
"מארזן": true,
"מארחה": true,
"מארחו": true,
"מארחי": true,
"מארחך": true,
"מארחם": true,
"מארחן": true,
"מארחת": true,
"מאריך": true,
"מאריק": true,
"מארכב": true,
"מארסת": true,
"מאשים": true,
"מאשפז": true,
"מאשרר": true,
"מאשרת": true,
"מאששת": true,
"מאשתו": true,
"מאתגר": true,
"מאתחל": true,
"מאתכם": true,
"מאתכן": true,
"מאתנו": true,
"מאתרי": true,
"מאתרת": true,
"מבאיש": true,
"מבאסת": true,
"מבארת": true,
"מבגרת": true,
"מבדוק": true,
"מבדות": true,
"מבדחת": true,
"מבדיל": true,
"מבדים": true,
"מבדלת": true,
"מבדקה": true,
"מבדקו": true,
"מבדקי": true,
"מבדקך": true,
"מבדקם": true,
"מבדקן": true,
"מבדרת": true,
"מבהיל": true,
"מבהיק": true,
"מבהיר": true,
"מבואה": true,
"מבואו": true,
"מבואי": true,
"מבואך": true,
"מבואם": true,
"מבואן": true,
"מבואס": true,
"מבואר": true,
"מבוגר": true,
"מבודד": true,
"מבודח": true,
"מבודל": true,
"מבודר": true,
"מבוהל": true,
"מבוזה": true,
"מבוזר": true,
"מבוטא": true,
"מבוטח": true,
"מבוטל": true,
"מבויה": true,
"מבויו": true,
"מבויי": true,
"מבויך": true,
"מבויל": true,
"מבוים": true,
"מבוין": true,
"מבויש": true,
"מבוית": true,
"מבוכה": true,
"מבוכו": true,
"מבוכי": true,
"מבוכך": true,
"מבוכם": true,
"מבוכן": true,
"מבולה": true,
"מבולו": true,
"מבולי": true,
"מבולך": true,
"מבולם": true,
"מבולן": true,
"מבונה": true,
"מבוסם": true,
"מבוסס": true,
"מבועה": true,
"מבועו": true,
"מבועי": true,
"מבועך": true,
"מבועם": true,
"מבוען": true,
"מבוער": true,
"מבועת": true,
"מבוצע": true,
"מבוצר": true,
"מבוקע": true,
"מבוקר": true,
"מבוקש": true,
"מבורא": true,
"מבורג": true,
"מבורך": true,
"מבורר": true,
"מבושל": true,
"מבושם": true,
"מבושר": true,
"מבושש": true,
"מבותק": true,
"מבותר": true,
"מבזבז": true,
"מבזות": true,
"מבזים": true,
"מבזיק": true,
"מבזקה": true,
"מבזקו": true,
"מבזקי": true,
"מבזקך": true,
"מבזקם": true,
"מבזקן": true,
"מבזקת": true,
"מבזרת": true,
"מבחוץ": true,
"מבחיל": true,
"מבחין": true,
"מבחנה": true,
"מבחנו": true,
"מבחני": true,
"מבחנך": true,
"מבחנם": true,
"מבחנן": true,
"מבחרה": true,
"מבחרו": true,
"מבחרי": true,
"מבחרך": true,
"מבחרם": true,
"מבחרן": true,
"מבטאה": true,
"מבטאו": true,
"מבטאי": true,
"מבטאך": true,
"מבטאם": true,
"מבטאן": true,
"מבטאת": true,
"מבטון": true,
"מבטחה": true,
"מבטחו": true,
"מבטחי": true,
"מבטחך": true,
"מבטחם": true,
"מבטחן": true,
"מבטחת": true,
"מבטיה": true,
"מבטיו": true,
"מבטיח": true,
"מבטיי": true,
"מבטיך": true,
"מבטים": true,
"מבטכם": true,
"מבטכן": true,
"מבטלת": true,
"מבטנו": true,
"מביאה": true,
"מביטה": true,
"מבייל": true,
"מביים": true,
"מבייץ": true,
"מבייש": true,
"מביית": true,
"מביכה": true,
"מבינה": true,
"מביסה": true,
"מביעה": true,
"מבישה": true,
"מביתו": true,
"מבכות": true,
"מבכים": true,
"מבכיר": true,
"מבכרת": true,
"מבלבל": true,
"מבלגן": true,
"מבלות": true,
"מבלטה": true,
"מבלטו": true,
"מבלטי": true,
"מבלטך": true,
"מבלטם": true,
"מבלטן": true,
"מבליג": true,
"מבליח": true,
"מבליט": true,
"מבלים": true,
"מבליע": true,
"מבלפת": true,
"מבנאם": true,
"מבנהו": true,
"מבנות": true,
"מבניה": true,
"מבניו": true,
"מבניי": true,
"מבניך": true,
"מבנים": true,
"מבנית": true,
"מבנכם": true,
"מבנכן": true,
"מבננו": true,
"מבסוט": true,
"מבסיס": true,
"מבסמת": true,
"מבססת": true,
"מבעבע": true,
"מבעוד": true,
"מבעיה": true,
"מבעיו": true,
"מבעיי": true,
"מבעיך": true,
"מבעים": true,
"מבעיר": true,
"מבעית": true,
"מבעכם": true,
"מבעכן": true,
"מבעלה": true,
"מבעלי": true,
"מבענו": true,
"מבערת": true,
"מבצבץ": true,
"מבצעה": true,
"מבצעו": true,
"מבצעי": true,
"מבצעך": true,
"מבצעם": true,
"מבצען": true,
"מבצעת": true,
"מבצרה": true,
"מבצרו": true,
"מבצרי": true,
"מבצרך": true,
"מבצרם": true,
"מבצרן": true,
"מבצרת": true,
"מבקבק": true,
"מבקיע": true,
"מבקעת": true,
"מבקרה": true,
"מבקרו": true,
"מבקרי": true,
"מבקרך": true,
"מבקרם": true,
"מבקרן": true,
"מבקרת": true,
"מבקשי": true,
"מבקשת": true,
"מבראת": true,
"מברבר": true,
"מברגה": true,
"מברגו": true,
"מברגי": true,
"מברגך": true,
"מברגם": true,
"מברגן": true,
"מברזל": true,
"מבריא": true,
"מבריג": true,
"מבריז": true,
"מבריח": true,
"מבריק": true,
"מבריש": true,
"מברית": true,
"מברכת": true,
"מברקה": true,
"מברקו": true,
"מברקי": true,
"מברקך": true,
"מברקם": true,
"מברקן": true,
"מבררת": true,
"מברשת": true,
"מבשיל": true,
"מבשלה": true,
"מבשלת": true,
"מבשמת": true,
"מבשנה": true,
"מבשרת": true,
"מבתקת": true,
"מבתרת": true,
"מגאטי": true,
"מגבבת": true,
"מגבון": true,
"מגבות": true,
"מגביה": true,
"מגביו": true,
"מגביט": true,
"מגביי": true,
"מגביך": true,
"מגביל": true,
"מגבים": true,
"מגביר": true,
"מגבית": true,
"מגבכם": true,
"מגבכן": true,
"מגבלה": true,
"מגבלת": true,
"מגבנו": true,
"מגבנת": true,
"מגבסת": true,
"מגבעת": true,
"מגברה": true,
"מגברו": true,
"מגברי": true,
"מגברך": true,
"מגברם": true,
"מגברן": true,
"מגבשת": true,
"מגבתה": true,
"מגבתו": true,
"מגבתי": true,
"מגבתך": true,
"מגבתם": true,
"מגבתן": true,
"מגדיה": true,
"מגדיו": true,
"מגדיי": true,
"מגדיך": true,
"מגדיל": true,
"מגדים": true,
"מגדיר": true,
"מגדיש": true,
"מגדכם": true,
"מגדכן": true,
"מגדלה": true,
"מגדלו": true,
"מגדלי": true,
"מגדלך": true,
"מגדלם": true,
"מגדלן": true,
"מגדלת": true,
"מגדנה": true,
"מגדנו": true,
"מגדני": true,
"מגדנך": true,
"מגדנם": true,
"מגדנן": true,
"מגדפת": true,
"מגדרה": true,
"מגדרו": true,
"מגדרי": true,
"מגדרך": true,
"מגדרם": true,
"מגדרן": true,
"מגדרת": true,
"מגהצה": true,
"מגהצו": true,
"מגהצי": true,
"מגהצך": true,
"מגהצם": true,
"מגהצן": true,
"מגהצת": true,
"מגהקת": true,
"מגהרץ": true,
"מגואל": true,
"מגובב": true,
"מגובה": true,
"מגובן": true,
"מגובס": true,
"מגובש": true,
"מגודל": true,
"מגודף": true,
"מגודר": true,
"מגוהץ": true,
"מגוון": true,
"מגוחך": true,
"מגויס": true,
"מגויר": true,
"מגולה": true,
"מגולח": true,
"מגולל": true,
"מגולם": true,
"מגולף": true,
"מגומד": true,
"מגומר": true,
"מגונה": true,
"מגונן": true,
"מגופה": true,
"מגופר": true,
"מגורד": true,
"מגורה": true,
"מגורז": true,
"מגורי": true,
"מגורף": true,
"מגורר": true,
"מגורש": true,
"מגושם": true,
"מגושר": true,
"מגזים": true,
"מגזין": true,
"מגזרה": true,
"מגזרו": true,
"מגזרי": true,
"מגזרך": true,
"מגזרם": true,
"מגזרן": true,
"מגחיך": true,
"מגחכת": true,
"מגטון": true,
"מגיבה": true,
"מגידה": true,
"מגידו": true,
"מגיהה": true,
"מגיות": true,
"מגיחה": true,
"מגיים": true,
"מגייס": true,
"מגייר": true,
"מגילה": true,
"מגילת": true,
"מגינה": true,
"מגיעה": true,
"מגיפה": true,
"מגירה": true,
"מגישה": true,
"מגלגל": true,
"מגלהו": true,
"מגלות": true,
"מגלחת": true,
"מגליד": true,
"מגליה": true,
"מגליו": true,
"מגליי": true,
"מגליך": true,
"מגלים": true,
"מגליש": true,
"מגלכם": true,
"מגלכן": true,
"מגללה": true,
"מגללו": true,
"מגללי": true,
"מגללך": true,
"מגללם": true,
"מגללן": true,
"מגלמת": true,
"מגלנו": true,
"מגלען": true,
"מגלפת": true,
"מגלשה": true,
"מגלשו": true,
"מגלשי": true,
"מגלשך": true,
"מגלשם": true,
"מגלשן": true,
"מגלתה": true,
"מגלתו": true,
"מגלתי": true,
"מגלתך": true,
"מגלתם": true,
"מגלתן": true,
"מגמגם": true,
"מגמדת": true,
"מגמות": true,
"מגמיש": true,
"מגמרת": true,
"מגמתה": true,
"מגמתו": true,
"מגמתי": true,
"מגמתך": true,
"מגמתם": true,
"מגמתן": true,
"מגנוט": true,
"מגנות": true,
"מגנטה": true,
"מגנטו": true,
"מגנטי": true,
"מגנטך": true,
"מגנטם": true,
"מגנטן": true,
"מגנטת": true,
"מגניב": true,
"מגניה": true,
"מגניו": true,
"מגניי": true,
"מגניך": true,
"מגנים": true,
"מגנכם": true,
"מגנכן": true,
"מגננה": true,
"מגננו": true,
"מגננת": true,
"מגעגע": true,
"מגעיה": true,
"מגעיו": true,
"מגעיי": true,
"מגעיך": true,
"מגעיל": true,
"מגעים": true,
"מגעיש": true,
"מגעכם": true,
"מגעכן": true,
"מגענו": true,
"מגפון": true,
"מגפות": true,
"מגפיה": true,
"מגפיו": true,
"מגפיי": true,
"מגפיך": true,
"מגפכם": true,
"מגפכן": true,
"מגפנו": true,
"מגפפת": true,
"מגפרת": true,
"מגפתה": true,
"מגפתו": true,
"מגפתי": true,
"מגפתך": true,
"מגפתם": true,
"מגפתן": true,
"מגרגר": true,
"מגרדת": true,
"מגרות": true,
"מגרזת": true,
"מגריל": true,
"מגרים": true,
"מגרנה": true,
"מגרסה": true,
"מגרען": true,
"מגרעת": true,
"מגרפה": true,
"מגרפת": true,
"מגררת": true,
"מגרשה": true,
"מגרשו": true,
"מגרשי": true,
"מגרשך": true,
"מגרשם": true,
"מגרשן": true,
"מגרשת": true,
"מגשיה": true,
"מגשיו": true,
"מגשיי": true,
"מגשיך": true,
"מגשים": true,
"מגשכם": true,
"מגשכן": true,
"מגשנו": true,
"מגשרת": true,
"מגששת": true,
"מדאיב": true,
"מדאיג": true,
"מדבבת": true,
"מדבגת": true,
"מדבור": true,
"מדביק": true,
"מדביר": true,
"מדבקה": true,
"מדבקת": true,
"מדברה": true,
"מדברו": true,
"מדברי": true,
"מדברך": true,
"מדברם": true,
"מדברן": true,
"מדברר": true,
"מדברת": true,
"מדגדג": true,
"מדגהו": true,
"מדגיה": true,
"מדגיו": true,
"מדגיי": true,
"מדגיך": true,
"מדגים": true,
"מדגיר": true,
"מדגיש": true,
"מדגכם": true,
"מדגכן": true,
"מדגמה": true,
"מדגמו": true,
"מדגמי": true,
"מדגמך": true,
"מדגמם": true,
"מדגמן": true,
"מדגנו": true,
"מדגרה": true,
"מדדות": true,
"מדדיה": true,
"מדדיו": true,
"מדדיי": true,
"מדדיך": true,
"מדדים": true,
"מדדכם": true,
"מדדכן": true,
"מדדנו": true,
"מדדתי": true,
"מדדתם": true,
"מדדתן": true,
"מדהים": true,
"מדהיר": true,
"מדובב": true,
"מדובג": true,
"מדובר": true,
"מדודה": true,
"מדווה": true,
"מדווח": true,
"מדווי": true,
"מדווך": true,
"מדוום": true,
"מדוון": true,
"מדוור": true,
"מדווש": true,
"מדוות": true,
"מדוזה": true,
"מדויק": true,
"מדוכא": true,
"מדוכה": true,
"מדולג": true,
"מדולל": true,
"מדומה": true,
"מדומם": true,
"מדונה": true,
"מדונו": true,
"מדוני": true,
"מדונך": true,
"מדונם": true,
"מדונן": true,
"מדופן": true,
"מדורג": true,
"מדורה": true,
"מדורו": true,
"מדורי": true,
"מדורך": true,
"מדורם": true,
"מדורן": true,
"מדושן": true,
"מדחום": true,
"מדחיק": true,
"מדחנה": true,
"מדחנו": true,
"מדחני": true,
"מדחנך": true,
"מדחנם": true,
"מדחנן": true,
"מדחסה": true,
"מדחסו": true,
"מדחסי": true,
"מדחסך": true,
"מדחסם": true,
"מדחסן": true,
"מדחפה": true,
"מדחפו": true,
"מדחפי": true,
"מדחפך": true,
"מדחפם": true,
"מדחפן": true,
"מדידה": true,
"מדידו": true,
"מדידי": true,
"מדידך": true,
"מדידם": true,
"מדידן": true,
"מדידת": true,
"מדיהם": true,
"מדיהן": true,
"מדיום": true,
"מדיחה": true,
"מדייך": true,
"מדיין": true,
"מדייק": true,
"מדיכם": true,
"מדיכן": true,
"מדינה": true,
"מדינו": true,
"מדיני": true,
"מדינת": true,
"מדיפה": true,
"מדיקל": true,
"מדירה": true,
"מדירס": true,
"מדכאת": true,
"מדכדך": true,
"מדלגת": true,
"מדלדל": true,
"מדליה": true,
"מדליף": true,
"מדליק": true,
"מדללת": true,
"מדמות": true,
"מדמים": true,
"מדמיע": true,
"מדממת": true,
"מדמנה": true,
"מדמעת": true,
"מדנגת": true,
"מדניה": true,
"מדניו": true,
"מדניי": true,
"מדניך": true,
"מדנים": true,
"מדסקס": true,
"מדעיה": true,
"מדעיו": true,
"מדעיי": true,
"מדעיך": true,
"מדעים": true,
"מדעית": true,
"מדעכם": true,
"מדעכן": true,
"מדענה": true,
"מדענו": true,
"מדעני": true,
"מדענך": true,
"מדענם": true,
"מדענן": true,
"מדעתו": true,
"מדפדף": true,
"מדפיה": true,
"מדפיו": true,
"מדפיי": true,
"מדפיך": true,
"מדפים": true,
"מדפיס": true,
"מדפכם": true,
"מדפכן": true,
"מדפנו": true,
"מדפנת": true,
"מדפסת": true,
"מדקדק": true,
"מדקלם": true,
"מדרבן": true,
"מדרגה": true,
"מדרגו": true,
"מדרגי": true,
"מדרגך": true,
"מדרגם": true,
"מדרגן": true,
"מדרגת": true,
"מדרדר": true,
"מדרוג": true,
"מדרום": true,
"מדרון": true,
"מדריד": true,
"מדריך": true,
"מדרים": true,
"מדרכה": true,
"מדרנה": true,
"מדרסה": true,
"מדרסו": true,
"מדרסי": true,
"מדרסך": true,
"מדרסם": true,
"מדרסן": true,
"מדרשה": true,
"מדרשו": true,
"מדרשי": true,
"מדרשך": true,
"מדרשם": true,
"מדרשן": true,
"מדרשת": true,
"מדשאה": true,
"מדשדש": true,
"מדשנת": true,
"מהאדם": true,
"מהארץ": true,
"מהאתר": true,
"מהבהב": true,
"מהביל": true,
"מהבית": true,
"מהבנק": true,
"מהברי": true,
"מהגוף": true,
"מהגרת": true,
"מהדהד": true,
"מהדור": true,
"מהדיר": true,
"מהדסת": true,
"מהדקת": true,
"מהדרה": true,
"מהדרו": true,
"מהדרי": true,
"מהדרך": true,
"מהדרם": true,
"מהדרן": true,
"מהדרת": true,
"מהוגן": true,
"מהודה": true,
"מהודו": true,
"מהודי": true,
"מהודך": true,
"מהודם": true,
"מהודן": true,
"מהודק": true,
"מהודר": true,
"מהוהה": true,
"מהווה": true,
"מהוון": true,
"מהוות": true,
"מהוים": true,
"מהולה": true,
"מהולל": true,
"מהומה": true,
"מהומם": true,
"מהוסה": true,
"מהוסס": true,
"מהופך": true,
"מהותה": true,
"מהותו": true,
"מהותי": true,
"מהותך": true,
"מהותם": true,
"מהותן": true,
"מהזמן": true,
"מהחדר": true,
"מהחוף": true,
"מהטבע": true,
"מהטוב": true,
"מהטעם": true,
"מהיום": true,
"מהיכן": true,
"מהילה": true,
"מהימן": true,
"מהינה": true,
"מהירה": true,
"מהכלא": true,
"מהכלל": true,
"מהכסף": true,
"מהכפר": true,
"מהלכה": true,
"מהלכו": true,
"מהלכי": true,
"מהלכך": true,
"מהלכם": true,
"מהלכן": true,
"מהלכת": true,
"מהללת": true,
"מהלנה": true,
"מהלנו": true,
"מהלתי": true,
"מהלתם": true,
"מהלתן": true,
"מהמאה": true,
"מהמגן": true,
"מהמהם": true,
"מהמוע": true,
"מהמים": true,
"מהממת": true,
"מהמנה": true,
"מהמצב": true,
"מהמרת": true,
"מהנדס": true,
"מהנהן": true,
"מהנוף": true,
"מהנות": true,
"מהנים": true,
"מהסוג": true,
"מהסות": true,
"מהסים": true,
"מהסכם": true,
"מהססת": true,
"מהספר": true,
"מהסרט": true,
"מהעבר": true,
"מהעיר": true,
"מהפכה": true,
"מהפכו": true,
"מהפכי": true,
"מהפכך": true,
"מהפכם": true,
"מהפכן": true,
"מהפכת": true,
"מהפנט": true,
"מהפרו": true,
"מהפרי": true,
"מהפרק": true,
"מהצבא": true,
"מהצרי": true,
"מהקהל": true,
"מהקול": true,
"מהקור": true,
"מהקשר": true,
"מהראש": true,
"מהרבה": true,
"מהרגע": true,
"מהרהר": true,
"מהרנה": true,
"מהרסת": true,
"מהרשק": true,
"מהרשת": true,
"מהשדה": true,
"מהשוק": true,
"מהשטח": true,
"מהשכר": true,
"מהשמש": true,
"מהשנה": true,
"מהשני": true,
"מהשעה": true,
"מהתלה": true,
"מהתלת": true,
"מואבי": true,
"מואבס": true,
"מואבק": true,
"מואדר": true,
"מואחד": true,
"מואטת": true,
"מואיד": true,
"מואיל": true,
"מואכל": true,
"מואמר": true,
"מואסי": true,
"מואסת": true,
"מואפל": true,
"מואצל": true,
"מואצת": true,
"מוארך": true,
"מוארק": true,
"מוארת": true,
"מואשם": true,
"מובאה": true,
"מובאש": true,
"מובאת": true,
"מובדל": true,
"מובהל": true,
"מובהק": true,
"מובהר": true,
"מובזק": true,
"מובחל": true,
"מובחן": true,
"מובחר": true,
"מובטח": true,
"מובטל": true,
"מוביט": true,
"מוביל": true,
"מובכת": true,
"מובלג": true,
"מובלט": true,
"מובלע": true,
"מובלת": true,
"מובנה": true,
"מובנו": true,
"מובני": true,
"מובנך": true,
"מובנם": true,
"מובנן": true,
"מובנת": true,
"מובסת": true,
"מובער": true,
"מובעת": true,
"מובקע": true,
"מוברג": true,
"מוברז": true,
"מוברח": true,
"מוברק": true,
"מוברר": true,
"מוברש": true,
"מוגבה": true,
"מוגבל": true,
"מוגבר": true,
"מוגדל": true,
"מוגדר": true,
"מוגדש": true,
"מוגדת": true,
"מוגהת": true,
"מוגות": true,
"מוגזם": true,
"מוגזת": true,
"מוגים": true,
"מוגיע": true,
"מוגלה": true,
"מוגלש": true,
"מוגמר": true,
"מוגמש": true,
"מוגנב": true,
"מוגנה": true,
"מוגנו": true,
"מוגנט": true,
"מוגנת": true,
"מוגעל": true,
"מוגפת": true,
"מוגרה": true,
"מוגרו": true,
"מוגרל": true,
"מוגרת": true,
"מוגשם": true,
"מוגשת": true,
"מודאג": true,
"מודבק": true,
"מודבר": true,
"מודגם": true,
"מודגר": true,
"מודגש": true,
"מודדה": true,
"מודדו": true,
"מודדי": true,
"מודדך": true,
"מודדם": true,
"מודדן": true,
"מודדת": true,
"מודהר": true,
"מודול": true,
"מודוס": true,
"מודות": true,
"מודחק": true,
"מודחת": true,
"מודטת": true,
"מודים": true,
"מודיע": true,
"מודלה": true,
"מודלו": true,
"מודלי": true,
"מודלך": true,
"מודלם": true,
"מודלן": true,
"מודלף": true,
"מודלק": true,
"מודמה": true,
"מודמו": true,
"מודמי": true,
"מודמך": true,
"מודמם": true,
"מודמן": true,
"מודעה": true,
"מודעת": true,
"מודפס": true,
"מודרה": true,
"מודרו": true,
"מודרך": true,
"מודרת": true,
"מוהדר": true,
"מוהלת": true,
"מוהרה": true,
"מוהרו": true,
"מוהרי": true,
"מוהרך": true,
"מוהרם": true,
"מוהרן": true,
"מוודא": true,
"מוודה": true,
"מווסת": true,
"מוותר": true,
"מוזגה": true,
"מוזגו": true,
"מוזגת": true,
"מוזהב": true,
"מוזהר": true,
"מוזות": true,
"מוזזת": true,
"מוזחת": true,
"מוזיל": true,
"מוזכר": true,
"מוזלף": true,
"מוזלת": true,
"מוזמן": true,
"מוזמת": true,
"מוזנח": true,
"מוזנק": true,
"מוזנת": true,
"מוזעק": true,
"מוזער": true,
"מוזקף": true,
"מוזרה": true,
"מוזרם": true,
"מוזרע": true,
"מוזרק": true,
"מוזתה": true,
"מוזתו": true,
"מוזתי": true,
"מוזתך": true,
"מוזתם": true,
"מוזתן": true,
"מוחאת": true,
"מוחבא": true,
"מוחדר": true,
"מוחדש": true,
"מוחות": true,
"מוחזק": true,
"מוחזר": true,
"מוחטא": true,
"מוחיה": true,
"מוחיל": true,
"מוחים": true,
"מוחין": true,
"מוחית": true,
"מוחכם": true,
"מוחכן": true,
"מוחכר": true,
"מוחלט": true,
"מוחלף": true,
"מוחלק": true,
"מוחלש": true,
"מוחלת": true,
"מוחמא": true,
"מוחמד": true,
"מוחמץ": true,
"מוחמר": true,
"מוחנה": true,
"מוחנו": true,
"מוחנק": true,
"מוחסר": true,
"מוחצן": true,
"מוחצת": true,
"מוחקת": true,
"מוחרב": true,
"מוחרג": true,
"מוחרד": true,
"מוחרם": true,
"מוחרף": true,
"מוחרש": true,
"מוחשב": true,
"מוחשד": true,
"מוחשי": true,
"מוחשך": true,
"מוחשת": true,
"מוחתם": true,
"מוטבל": true,
"מוטבע": true,
"מוטבת": true,
"מוטור": true,
"מוטות": true,
"מוטחת": true,
"מוטטה": true,
"מוטטו": true,
"מוטטי": true,
"מוטטת": true,
"מוטיב": true,
"מוטים": true,
"מוטית": true,
"מוטכם": true,
"מוטכן": true,
"מוטלא": true,
"מוטלת": true,
"מוטמן": true,
"מוטמע": true,
"מוטנו": true,
"מוטנט": true,
"מוטסת": true,
"מוטעה": true,
"מוטעם": true,
"מוטען": true,
"מוטפת": true,
"מוטרד": true,
"מוטרח": true,
"מוטתה": true,
"מוטתו": true,
"מוטתי": true,
"מוטתך": true,
"מוטתם": true,
"מוטתן": true,
"מויאל": true,
"מויות": true,
"מויים": true,
"מוינה": true,
"מוינו": true,
"מוינת": true,
"מוישי": true,
"מוכבד": true,
"מוכבר": true,
"מוכהה": true,
"מוכות": true,
"מוכחד": true,
"מוכחש": true,
"מוכחת": true,
"מוכיח": true,
"מוכים": true,
"מוכית": true,
"מוככם": true,
"מוככן": true,
"מוכלב": true,
"מוכלל": true,
"מוכלם": true,
"מוכלת": true,
"מוכמן": true,
"מוכנה": true,
"מוכנו": true,
"מוכנס": true,
"מוכנע": true,
"מוכנת": true,
"מוכסה": true,
"מוכסו": true,
"מוכסי": true,
"מוכסך": true,
"מוכסם": true,
"מוכסן": true,
"מוכסף": true,
"מוכסת": true,
"מוכעס": true,
"מוכפל": true,
"מוכפף": true,
"מוכפש": true,
"מוכרה": true,
"מוכרו": true,
"מוכרז": true,
"מוכרח": true,
"מוכרי": true,
"מוכרך": true,
"מוכרם": true,
"מוכרן": true,
"מוכרע": true,
"מוכרת": true,
"מוכשל": true,
"מוכשר": true,
"מוכשת": true,
"מוכתב": true,
"מוכתם": true,
"מוכתר": true,
"מולאה": true,
"מולאו": true,
"מולאם": true,
"מולאת": true,
"מולבן": true,
"מולבש": true,
"מולדה": true,
"מולדו": true,
"מולדי": true,
"מולדך": true,
"מולדם": true,
"מולדן": true,
"מולדר": true,
"מולדת": true,
"מולהב": true,
"מולהט": true,
"מולחם": true,
"מולחן": true,
"מולחץ": true,
"מולטי": true,
"מולטת": true,
"מוליד": true,
"מוליך": true,
"מולים": true,
"מולכד": true,
"מולכם": true,
"מולכן": true,
"מולכת": true,
"מוללה": true,
"מוללו": true,
"מוללי": true,
"מוללת": true,
"מולנה": true,
"מולנו": true,
"מולנת": true,
"מולסה": true,
"מולעט": true,
"מולקה": true,
"מולקת": true,
"מומחה": true,
"מומחו": true,
"מומחז": true,
"מומחי": true,
"מומחך": true,
"מומחם": true,
"מומחן": true,
"מומחש": true,
"מומטר": true,
"מומטת": true,
"מומיה": true,
"מומיו": true,
"מומיי": true,
"מומיך": true,
"מומים": true,
"מומכם": true,
"מומכן": true,
"מומלח": true,
"מומלט": true,
"מומלך": true,
"מומלץ": true,
"מומנה": true,
"מומנו": true,
"מומנט": true,
"מומנת": true,
"מומסת": true,
"מומעד": true,
"מומעט": true,
"מומצא": true,
"מומרד": true,
"מומרה": true,
"מומרו": true,
"מומרי": true,
"מומרך": true,
"מומרם": true,
"מומרן": true,
"מומרץ": true,
"מומרת": true,
"מומשה": true,
"מומשו": true,
"מומשל": true,
"מומשת": true,
"מומתק": true,
"מומתת": true,
"מונאי": true,
"מונאת": true,
"מונבט": true,
"מונגש": true,
"מונהג": true,
"מונהו": true,
"מונות": true,
"מונחה": true,
"מונחו": true,
"מונחי": true,
"מונחך": true,
"מונחל": true,
"מונחם": true,
"מונחן": true,
"מונחת": true,
"מוניה": true,
"מוניו": true,
"מוניי": true,
"מוניך": true,
"מונים": true,
"מוניס": true,
"מוניר": true,
"מונית": true,
"מונכם": true,
"מונכן": true,
"מונלי": true,
"מונמך": true,
"מוננו": true,
"מונסה": true,
"מונסת": true,
"מונעם": true,
"מונעת": true,
"מונפה": true,
"מונפו": true,
"מונפק": true,
"מונפש": true,
"מונפת": true,
"מונצח": true,
"מונקו": true,
"מונקת": true,
"מונרו": true,
"מונרך": true,
"מונשם": true,
"מונתה": true,
"מונתו": true,
"מוסבר": true,
"מוסבת": true,
"מוסגר": true,
"מוסגת": true,
"מוסדה": true,
"מוסדו": true,
"מוסדי": true,
"מוסדך": true,
"מוסדם": true,
"מוסדן": true,
"מוסדר": true,
"מוסדת": true,
"מוסול": true,
"מוסחר": true,
"מוסחת": true,
"מוסטל": true,
"מוסטת": true,
"מוסים": true,
"מוסיף": true,
"מוסית": true,
"מוסכה": true,
"מוסכו": true,
"מוסכי": true,
"מוסכך": true,
"מוסכם": true,
"מוסכן": true,
"מוסכת": true,
"מוסלם": true,
"מוסלק": true,
"מוסמך": true,
"מוסמס": true,
"מוסמר": true,
"מוססה": true,
"מוססו": true,
"מוססי": true,
"מוססת": true,
"מוסער": true,
"מוסעת": true,
"מוספג": true,
"מוספד": true,
"מוספה": true,
"מוספו": true,
"מוספי": true,
"מוספך": true,
"מוספם": true,
"מוספן": true,
"מוספר": true,
"מוספת": true,
"מוסקט": true,
"מוסקת": true,
"מוסרה": true,
"מוסרו": true,
"מוסרט": true,
"מוסרי": true,
"מוסרך": true,
"מוסרם": true,
"מוסרן": true,
"מוסרת": true,
"מוסתה": true,
"מוסתר": true,
"מוסתת": true,
"מועבד": true,
"מועבר": true,
"מועבת": true,
"מועדה": true,
"מועדו": true,
"מועדי": true,
"מועדך": true,
"מועדם": true,
"מועדן": true,
"מועדף": true,
"מועדת": true,
"מועזב": true,
"מועטה": true,
"מועטת": true,
"מועיד": true,
"מועיל": true,
"מועכת": true,
"מועלה": true,
"מועלם": true,
"מועלת": true,
"מועמד": true,
"מועמס": true,
"מועמק": true,
"מועמר": true,
"מועמת": true,
"מוענה": true,
"מוענו": true,
"מועני": true,
"מוענך": true,
"מוענם": true,
"מוענן": true,
"מוענק": true,
"מוענש": true,
"מוענת": true,
"מועסק": true,
"מועפת": true,
"מועצב": true,
"מועצה": true,
"מועצם": true,
"מועצת": true,
"מועקה": true,
"מוערך": true,
"מוערם": true,
"מוערת": true,
"מועשר": true,
"מועתק": true,
"מועתר": true,
"מופגז": true,
"מופגן": true,
"מופגש": true,
"מופגת": true,
"מופזה": true,
"מופחד": true,
"מופחת": true,
"מופטר": true,
"מופיע": true,
"מופית": true,
"מופלא": true,
"מופלג": true,
"מופלה": true,
"מופלט": true,
"מופלל": true,
"מופלת": true,
"מופנה": true,
"מופנם": true,
"מופסד": true,
"מופסק": true,
"מופסת": true,
"מופעה": true,
"מופעו": true,
"מופעי": true,
"מופעך": true,
"מופעל": true,
"מופעם": true,
"מופען": true,
"מופצץ": true,
"מופצת": true,
"מופקד": true,
"מופקע": true,
"מופקר": true,
"מופקת": true,
"מופרד": true,
"מופרה": true,
"מופרז": true,
"מופרח": true,
"מופרט": true,
"מופרך": true,
"מופרע": true,
"מופרש": true,
"מופרת": true,
"מופשט": true,
"מופשל": true,
"מופשר": true,
"מופתה": true,
"מופתו": true,
"מופתי": true,
"מופתך": true,
"מופתם": true,
"מופתן": true,
"מופתע": true,
"מוצאה": true,
"מוצאו": true,
"מוצאי": true,
"מוצאך": true,
"מוצאם": true,
"מוצאן": true,
"מוצאת": true,
"מוצבה": true,
"מוצבו": true,
"מוצבי": true,
"מוצבך": true,
"מוצבם": true,
"מוצבן": true,
"מוצבע": true,
"מוצבת": true,
"מוצגה": true,
"מוצגו": true,
"מוצגי": true,
"מוצגך": true,
"מוצגם": true,
"מוצגן": true,
"מוצגת": true,
"מוצדק": true,
"מוצהב": true,
"מוצהר": true,
"מוציא": true,
"מוצית": true,
"מוצכם": true,
"מוצכן": true,
"מוצלב": true,
"מוצלח": true,
"מוצלל": true,
"מוצלת": true,
"מוצמד": true,
"מוצמת": true,
"מוצנו": true,
"מוצנח": true,
"מוצנע": true,
"מוצעב": true,
"מוצעד": true,
"מוצעה": true,
"מוצעו": true,
"מוצעת": true,
"מוצפן": true,
"מוצפת": true,
"מוצצת": true,
"מוצקה": true,
"מוצקו": true,
"מוצקי": true,
"מוצקך": true,
"מוצקם": true,
"מוצקן": true,
"מוצרה": true,
"מוצרו": true,
"מוצרט": true,
"מוצרי": true,
"מוצרך": true,
"מוצרם": true,
"מוצרן": true,
"מוצרת": true,
"מוצתה": true,
"מוצתת": true,
"מוקאת": true,
"מוקדה": true,
"מוקדו": true,
"מוקדח": true,
"מוקדי": true,
"מוקדך": true,
"מוקדם": true,
"מוקדן": true,
"מוקדש": true,
"מוקדת": true,
"מוקהה": true,
"מוקהל": true,
"מוקזת": true,
"מוקטן": true,
"מוקטר": true,
"מוקים": true,
"מוקיע": true,
"מוקיר": true,
"מוקלד": true,
"מוקלט": true,
"מוקלת": true,
"מוקמה": true,
"מוקמו": true,
"מוקמת": true,
"מוקנה": true,
"מוקנט": true,
"מוקסם": true,
"מוקעת": true,
"מוקפא": true,
"מוקפד": true,
"מוקפץ": true,
"מוקפת": true,
"מוקצב": true,
"מוקצה": true,
"מוקצן": true,
"מוקצע": true,
"מוקצף": true,
"מוקצת": true,
"מוקרא": true,
"מוקרב": true,
"מוקרט": true,
"מוקרם": true,
"מוקרן": true,
"מוקרש": true,
"מוקשה": true,
"מוקשו": true,
"מוקשח": true,
"מוקשי": true,
"מוקשך": true,
"מוקשם": true,
"מוקשן": true,
"מוקשת": true,
"מוראד": true,
"מוראה": true,
"מוראו": true,
"מוראי": true,
"מוראך": true,
"מוראם": true,
"מוראן": true,
"מורבע": true,
"מורגל": true,
"מורגן": true,
"מורגש": true,
"מורדה": true,
"מורדו": true,
"מורדי": true,
"מורדך": true,
"מורדם": true,
"מורדן": true,
"מורדת": true,
"מורהו": true,
"מורות": true,
"מורחב": true,
"מורחק": true,
"מורחת": true,
"מורטב": true,
"מורטת": true,
"מוריד": true,
"מוריה": true,
"מוריו": true,
"מוריי": true,
"מוריך": true,
"מורים": true,
"מוריס": true,
"מוריק": true,
"מוריש": true,
"מורכב": true,
"מורכז": true,
"מורכם": true,
"מורכן": true,
"מורלי": true,
"מורמת": true,
"מורנו": true,
"מורסה": true,
"מורסי": true,
"מורעב": true,
"מורעד": true,
"מורעל": true,
"מורעף": true,
"מורעש": true,
"מורעת": true,
"מורצת": true,
"מורקד": true,
"מורקה": true,
"מורקו": true,
"מורקת": true,
"מורשה": true,
"מורשי": true,
"מורשע": true,
"מורשת": true,
"מורתה": true,
"מורתו": true,
"מורתח": true,
"מורתי": true,
"מורתך": true,
"מורתם": true,
"מורתן": true,
"מורתע": true,
"מושאה": true,
"מושאו": true,
"מושאי": true,
"מושאך": true,
"מושאל": true,
"מושאם": true,
"מושאן": true,
"מושאף": true,
"מושאר": true,
"מושאת": true,
"מושבה": true,
"מושבו": true,
"מושבח": true,
"מושבי": true,
"מושבך": true,
"מושבם": true,
"מושבן": true,
"מושבע": true,
"מושבת": true,
"מושגה": true,
"מושגו": true,
"מושגח": true,
"מושגי": true,
"מושגך": true,
"מושגם": true,
"מושגן": true,
"מושגת": true,
"מושהה": true,
"מושות": true,
"מושחז": true,
"מושחל": true,
"מושחם": true,
"מושחר": true,
"מושחת": true,
"מושטת": true,
"מושיב": true,
"מושיט": true,
"מושים": true,
"מושיע": true,
"מושיר": true,
"מושכב": true,
"מושכה": true,
"מושכח": true,
"מושכל": true,
"מושכם": true,
"מושכן": true,
"מושכר": true,
"מושכת": true,
"מושלג": true,
"מושלה": true,
"מושלו": true,
"מושלט": true,
"מושלי": true,
"מושלך": true,
"מושלם": true,
"מושלן": true,
"מושלת": true,
"מושמד": true,
"מושמט": true,
"מושמע": true,
"מושמץ": true,
"מושמש": true,
"מושמת": true,
"מושנה": true,
"מושעה": true,
"מושען": true,
"מושפל": true,
"מושפע": true,
"מושקה": true,
"מושקט": true,
"מושקע": true,
"מושקת": true,
"מושרה": true,
"מושרף": true,
"מושרץ": true,
"מושרש": true,
"מושרת": true,
"מוששה": true,
"מוששו": true,
"מוששת": true,
"מושתל": true,
"מושתן": true,
"מושתק": true,
"מושתת": true,
"מותאם": true,
"מותגה": true,
"מותגו": true,
"מותגי": true,
"מותגך": true,
"מותגם": true,
"מותגן": true,
"מותגת": true,
"מותות": true,
"מותזת": true,
"מותחל": true,
"מותחן": true,
"מותחת": true,
"מותיר": true,
"מותכם": true,
"מותכן": true,
"מותכת": true,
"מותמר": true,
"מותנה": true,
"מותנו": true,
"מותני": true,
"מותנך": true,
"מותנם": true,
"מותנן": true,
"מותנע": true,
"מותנת": true,
"מותסס": true,
"מותפח": true,
"מותפל": true,
"מותקה": true,
"מותקו": true,
"מותקי": true,
"מותקך": true,
"מותקל": true,
"מותקם": true,
"מותקן": true,
"מותקף": true,
"מותרה": true,
"מותרת": true,
"מותשת": true,
"מזבחה": true,
"מזבחו": true,
"מזבחי": true,
"מזבחך": true,
"מזבחם": true,
"מזבחן": true,
"מזבלה": true,
"מזבלת": true,
"מזגגת": true,
"מזגזג": true,
"מזגיה": true,
"מזגיו": true,
"מזגיי": true,
"מזגיך": true,
"מזגים": true,
"מזגכם": true,
"מזגכן": true,
"מזגנה": true,
"מזגנו": true,
"מזגני": true,
"מזגנך": true,
"מזגנם": true,
"מזגנן": true,
"מזגתי": true,
"מזגתם": true,
"מזגתן": true,
"מזדהה": true,
"מזדהם": true,
"מזדחל": true,
"מזדכה": true,
"מזדכך": true,
"מזדמן": true,
"מזדמר": true,
"מזדנב": true,
"מזדעף": true,
"מזדעק": true,
"מזדקן": true,
"מזדקף": true,
"מזדקק": true,
"מזדקר": true,
"מזדרז": true,
"מזההו": true,
"מזהות": true,
"מזהיב": true,
"מזהיה": true,
"מזהיו": true,
"מזהיי": true,
"מזהיך": true,
"מזהים": true,
"מזהיר": true,
"מזהכם": true,
"מזהכן": true,
"מזהמת": true,
"מזהנו": true,
"מזהרת": true,
"מזובל": true,
"מזוגג": true,
"מזוגה": true,
"מזוהה": true,
"מזוהם": true,
"מזווג": true,
"מזווד": true,
"מזווה": true,
"מזווי": true,
"מזווך": true,
"מזוום": true,
"מזוון": true,
"מזוזה": true,
"מזוין": true,
"מזויף": true,
"מזוכה": true,
"מזוכך": true,
"מזומן": true,
"מזומר": true,
"מזונה": true,
"מזונו": true,
"מזוני": true,
"מזונך": true,
"מזונם": true,
"מזונן": true,
"מזופת": true,
"מזוקן": true,
"מזוקק": true,
"מזורה": true,
"מזורו": true,
"מזורז": true,
"מזורי": true,
"מזורך": true,
"מזורם": true,
"מזורן": true,
"מזחיה": true,
"מזחיו": true,
"מזחיי": true,
"מזחיך": true,
"מזחים": true,
"מזחכם": true,
"מזחכן": true,
"מזחלת": true,
"מזחנו": true,
"מזיגה": true,
"מזידה": true,
"מזיזה": true,
"מזיחה": true,
"מזיין": true,
"מזייף": true,
"מזילה": true,
"מזימה": true,
"מזינה": true,
"מזינו": true,
"מזיעה": true,
"מזיקה": true,
"מזכות": true,
"מזכים": true,
"מזכיר": true,
"מזככת": true,
"מזכרה": true,
"מזכרו": true,
"מזכרי": true,
"מזכרך": true,
"מזכרם": true,
"מזכרן": true,
"מזכרת": true,
"מזלגה": true,
"מזלגו": true,
"מזלגי": true,
"מזלגך": true,
"מזלגם": true,
"מזלגן": true,
"מזלות": true,
"מזלזל": true,
"מזליף": true,
"מזלכם": true,
"מזלכן": true,
"מזללה": true,
"מזלנו": true,
"מזלפה": true,
"מזלפו": true,
"מזלפי": true,
"מזלפך": true,
"מזלפם": true,
"מזלפן": true,
"מזמוז": true,
"מזמור": true,
"מזמות": true,
"מזמזה": true,
"מזמזו": true,
"מזמזי": true,
"מזמזם": true,
"מזמזת": true,
"מזמים": true,
"מזמין": true,
"מזמנת": true,
"מזמרה": true,
"מזמרת": true,
"מזנבת": true,
"מזנון": true,
"מזנות": true,
"מזניח": true,
"מזנים": true,
"מזניק": true,
"מזנקת": true,
"מזעור": true,
"מזעזע": true,
"מזעים": true,
"מזעיף": true,
"מזעיק": true,
"מזעקי": true,
"מזערה": true,
"מזערו": true,
"מזערי": true,
"מזערת": true,
"מזפתת": true,
"מזקין": true,
"מזקיף": true,
"מזקקה": true,
"מזקקת": true,
"מזרון": true,
"מזרות": true,
"מזרזת": true,
"מזרחה": true,
"מזרחו": true,
"מזרחי": true,
"מזרחך": true,
"מזרחם": true,
"מזרחן": true,
"מזרים": true,
"מזריע": true,
"מזריק": true,
"מזרנה": true,
"מזרנו": true,
"מזרני": true,
"מזרנך": true,
"מזרנם": true,
"מזרנן": true,
"מזרעה": true,
"מזרקה": true,
"מזרקו": true,
"מזרקי": true,
"מזרקך": true,
"מזרקם": true,
"מזרקן": true,
"מחאות": true,
"מחאנה": true,
"מחאנו": true,
"מחאתה": true,
"מחאתו": true,
"מחאתי": true,
"מחאתך": true,
"מחאתם": true,
"מחאתן": true,
"מחבבת": true,
"מחבוא": true,
"מחבוש": true,
"מחבטה": true,
"מחבטו": true,
"מחבטי": true,
"מחבטך": true,
"מחבטם": true,
"מחבטן": true,
"מחביא": true,
"מחבלה": true,
"מחבלו": true,
"מחבלי": true,
"מחבלך": true,
"מחבלם": true,
"מחבלן": true,
"מחבלת": true,
"מחבקת": true,
"מחברה": true,
"מחברו": true,
"מחברי": true,
"מחברך": true,
"מחברם": true,
"מחברן": true,
"מחברת": true,
"מחבתה": true,
"מחבתו": true,
"מחבתי": true,
"מחבתך": true,
"מחבתם": true,
"מחבתן": true,
"מחדדת": true,
"מחדיר": true,
"מחדלה": true,
"מחדלו": true,
"מחדלי": true,
"מחדלך": true,
"מחדלם": true,
"מחדלן": true,
"מחדשת": true,
"מחובל": true,
"מחובק": true,
"מחובר": true,
"מחובת": true,
"מחוגה": true,
"מחוגו": true,
"מחוגי": true,
"מחוגך": true,
"מחוגם": true,
"מחוגן": true,
"מחודד": true,
"מחודש": true,
"מחווה": true,
"מחווט": true,
"מחוון": true,
"מחוור": true,
"מחוות": true,
"מחוזה": true,
"מחוזו": true,
"מחוזי": true,
"מחוזך": true,
"מחוזם": true,
"מחוזן": true,
"מחוזק": true,
"מחוזר": true,
"מחוטא": true,
"מחוטב": true,
"מחוטט": true,
"מחויב": true,
"מחויג": true,
"מחויט": true,
"מחויך": true,
"מחויל": true,
"מחוכה": true,
"מחוכו": true,
"מחוכי": true,
"מחוכך": true,
"מחוכם": true,
"מחוכן": true,
"מחולה": true,
"מחולו": true,
"מחולט": true,
"מחולי": true,
"מחולך": true,
"מחולל": true,
"מחולם": true,
"מחולן": true,
"מחולץ": true,
"מחולק": true,
"מחומם": true,
"מחומר": true,
"מחומש": true,
"מחונך": true,
"מחונן": true,
"מחוסל": true,
"מחוסם": true,
"מחוסן": true,
"מחוסר": true,
"מחופף": true,
"מחופש": true,
"מחוצה": true,
"מחוצף": true,
"מחוקה": true,
"מחוקק": true,
"מחורץ": true,
"מחורר": true,
"מחושב": true,
"מחושה": true,
"מחושו": true,
"מחושי": true,
"מחושך": true,
"מחושל": true,
"מחושם": true,
"מחושן": true,
"מחושק": true,
"מחותל": true,
"מחותן": true,
"מחזאה": true,
"מחזאו": true,
"מחזאי": true,
"מחזאך": true,
"מחזאם": true,
"מחזאן": true,
"מחזהו": true,
"מחזור": true,
"מחזות": true,
"מחזיק": true,
"מחזיר": true,
"מחזכם": true,
"מחזכן": true,
"מחזמר": true,
"מחזנו": true,
"מחזקת": true,
"מחזרה": true,
"מחזרו": true,
"מחזרי": true,
"מחזרך": true,
"מחזרם": true,
"מחזרן": true,
"מחזרת": true,
"מחטאת": true,
"מחטבת": true,
"מחטטת": true,
"מחטיא": true,
"מחטיה": true,
"מחטיו": true,
"מחטיי": true,
"מחטיך": true,
"מחטים": true,
"מחטיף": true,
"מחטכם": true,
"מחטכן": true,
"מחטנו": true,
"מחטני": true,
"מחטפה": true,
"מחטפו": true,
"מחטפי": true,
"מחטפך": true,
"מחטפם": true,
"מחטפן": true,
"מחיאה": true,
"מחיות": true,
"מחייב": true,
"מחייג": true,
"מחייה": true,
"מחייו": true,
"מחייט": true,
"מחייך": true,
"מחייל": true,
"מחיים": true,
"מחילה": true,
"מחינה": true,
"מחינו": true,
"מחיפה": true,
"מחיצה": true,
"מחיקה": true,
"מחיקת": true,
"מחירה": true,
"מחירו": true,
"מחירי": true,
"מחירך": true,
"מחירם": true,
"מחירן": true,
"מחישה": true,
"מחיתה": true,
"מחיתו": true,
"מחיתי": true,
"מחיתך": true,
"מחיתם": true,
"מחיתן": true,
"מחכות": true,
"מחכים": true,
"מחכיר": true,
"מחככת": true,
"מחלבה": true,
"מחלות": true,
"מחלחל": true,
"מחלטת": true,
"מחליא": true,
"מחליד": true,
"מחליט": true,
"מחלים": true,
"מחליף": true,
"מחליק": true,
"מחליש": true,
"מחללת": true,
"מחלנה": true,
"מחלנו": true,
"מחלפה": true,
"מחלפו": true,
"מחלפי": true,
"מחלפך": true,
"מחלפם": true,
"מחלפן": true,
"מחלצת": true,
"מחלקה": true,
"מחלקת": true,
"מחלתה": true,
"מחלתו": true,
"מחלתי": true,
"מחלתך": true,
"מחלתם": true,
"מחלתן": true,
"מחמאה": true,
"מחמדה": true,
"מחמדו": true,
"מחמדי": true,
"מחמדך": true,
"מחמדם": true,
"מחמדן": true,
"מחמוד": true,
"מחמיא": true,
"מחמיד": true,
"מחמיה": true,
"מחמיו": true,
"מחמיי": true,
"מחמיך": true,
"מחמים": true,
"מחמיץ": true,
"מחמיר": true,
"מחמכם": true,
"מחמכן": true,
"מחמלה": true,
"מחמלו": true,
"מחמלי": true,
"מחמלך": true,
"מחמלם": true,
"מחמלן": true,
"מחממי": true,
"מחממת": true,
"מחמנו": true,
"מחמצן": true,
"מחמשת": true,
"מחנהו": true,
"מחנות": true,
"מחנים": true,
"מחניף": true,
"מחניק": true,
"מחנכה": true,
"מחנכו": true,
"מחנכי": true,
"מחנכך": true,
"מחנכם": true,
"מחנכן": true,
"מחנכת": true,
"מחננו": true,
"מחנקה": true,
"מחנקו": true,
"מחנקי": true,
"מחנקך": true,
"מחנקם": true,
"מחנקן": true,
"מחסהו": true,
"מחסום": true,
"מחסור": true,
"מחסות": true,
"מחסיה": true,
"מחסיו": true,
"מחסיי": true,
"מחסיך": true,
"מחסים": true,
"מחסיר": true,
"מחסכם": true,
"מחסכן": true,
"מחסלת": true,
"מחסמת": true,
"מחסנה": true,
"מחסנו": true,
"מחסני": true,
"מחסנך": true,
"מחסנם": true,
"מחסנן": true,
"מחסנת": true,
"מחספס": true,
"מחסרת": true,
"מחפות": true,
"מחפים": true,
"מחפיר": true,
"מחפפת": true,
"מחפרה": true,
"מחפרו": true,
"מחפרי": true,
"מחפרך": true,
"מחפרם": true,
"מחפרן": true,
"מחפשת": true,
"מחצבה": true,
"מחצבו": true,
"מחצבי": true,
"מחצבך": true,
"מחצבם": true,
"מחצבן": true,
"מחצין": true,
"מחציף": true,
"מחצית": true,
"מחצלת": true,
"מחצנה": true,
"מחצנו": true,
"מחצצר": true,
"מחצתי": true,
"מחצתם": true,
"מחצתן": true,
"מחקות": true,
"מחקיה": true,
"מחקיו": true,
"מחקיי": true,
"מחקיך": true,
"מחקים": true,
"מחקכם": true,
"מחקכן": true,
"מחקנה": true,
"מחקנו": true,
"מחקרה": true,
"מחקרו": true,
"מחקרי": true,
"מחקרך": true,
"מחקרם": true,
"מחקרן": true,
"מחקתי": true,
"מחקתם": true,
"מחקתן": true,
"מחראה": true,
"מחרבן": true,
"מחרחר": true,
"מחרטה": true,
"מחריב": true,
"מחריג": true,
"מחריד": true,
"מחרים": true,
"מחריף": true,
"מחריש": true,
"מחרמן": true,
"מחרפת": true,
"מחרשה": true,
"מחשבה": true,
"מחשבו": true,
"מחשבי": true,
"מחשבך": true,
"מחשבם": true,
"מחשבן": true,
"מחשבת": true,
"מחשוב": true,
"מחשוף": true,
"מחשות": true,
"מחשיב": true,
"מחשיד": true,
"מחשיך": true,
"מחשים": true,
"מחשכה": true,
"מחשכו": true,
"מחשכי": true,
"מחשכך": true,
"מחשכם": true,
"מחשכן": true,
"מחשלת": true,
"מחשמל": true,
"מחשקת": true,
"מחתים": true,
"מחתלת": true,
"מחתנת": true,
"מחתרת": true,
"מטאור": true,
"מטאות": true,
"מטאטא": true,
"מטבחה": true,
"מטבחו": true,
"מטבחי": true,
"מטבחך": true,
"מטבחם": true,
"מטבחן": true,
"מטביל": true,
"מטביע": true,
"מטבלה": true,
"מטבלו": true,
"מטבלי": true,
"מטבלך": true,
"מטבלם": true,
"מטבלן": true,
"מטבעה": true,
"מטבעו": true,
"מטבעי": true,
"מטבעך": true,
"מטבעם": true,
"מטבען": true,
"מטבעת": true,
"מטגנת": true,
"מטדור": true,
"מטהרי": true,
"מטהרת": true,
"מטובע": true,
"מטוגן": true,
"מטוהר": true,
"מטווח": true,
"מטויב": true,
"מטויח": true,
"מטויל": true,
"מטולה": true,
"מטולו": true,
"מטולי": true,
"מטולך": true,
"מטולם": true,
"מטולן": true,
"מטומא": true,
"מטומט": true,
"מטונף": true,
"מטוסה": true,
"מטוסו": true,
"מטוסי": true,
"מטוסך": true,
"מטוסם": true,
"מטוסן": true,
"מטופח": true,
"מטופל": true,
"מטופס": true,
"מטופף": true,
"מטופש": true,
"מטורף": true,
"מטושט": true,
"מטזזת": true,
"מטחיה": true,
"מטחיו": true,
"מטחיי": true,
"מטחיך": true,
"מטחים": true,
"מטחכם": true,
"מטחכן": true,
"מטחנה": true,
"מטחנו": true,
"מטיבה": true,
"מטיול": true,
"מטיות": true,
"מטיחה": true,
"מטייב": true,
"מטייח": true,
"מטייל": true,
"מטיים": true,
"מטילה": true,
"מטיסה": true,
"מטיפה": true,
"מטיקה": true,
"מטירת": true,
"מטכלי": true,
"מטלון": true,
"מטלות": true,
"מטלטל": true,
"מטליא": true,
"מטלית": true,
"מטלפן": true,
"מטלתה": true,
"מטלתו": true,
"מטלתי": true,
"מטלתך": true,
"מטלתם": true,
"מטלתן": true,
"מטמאת": true,
"מטמון": true,
"מטמטם": true,
"מטמין": true,
"מטמיע": true,
"מטנפת": true,
"מטסיה": true,
"מטסיו": true,
"מטסיי": true,
"מטסיך": true,
"מטסים": true,
"מטסכם": true,
"מטסכן": true,
"מטסנו": true,
"מטעות": true,
"מטעיה": true,
"מטעיו": true,
"מטעיי": true,
"מטעיך": true,
"מטעים": true,
"מטעין": true,
"מטעכם": true,
"מטעכן": true,
"מטעמה": true,
"מטעמו": true,
"מטעמי": true,
"מטעמך": true,
"מטעמם": true,
"מטעמן": true,
"מטענה": true,
"מטענו": true,
"מטעני": true,
"מטענך": true,
"מטענם": true,
"מטענן": true,
"מטפהו": true,
"מטפחת": true,
"מטפטף": true,
"מטפיה": true,
"מטפיו": true,
"מטפיי": true,
"מטפיך": true,
"מטפים": true,
"מטפכם": true,
"מטפכן": true,
"מטפלה": true,
"מטפלו": true,
"מטפלי": true,
"מטפלך": true,
"מטפלם": true,
"מטפלן": true,
"מטפלת": true,
"מטפנו": true,
"מטפסת": true,
"מטקות": true,
"מטרדה": true,
"מטרדו": true,
"מטרדי": true,
"מטרדך": true,
"מטרדם": true,
"מטרדן": true,
"מטרות": true,
"מטרטר": true,
"מטריד": true,
"מטריה": true,
"מטריח": true,
"מטריל": true,
"מטרים": true,
"מטריף": true,
"מטרית": true,
"מטרנה": true,
"מטרפד": true,
"מטרתה": true,
"מטרתו": true,
"מטרתי": true,
"מטרתך": true,
"מטרתם": true,
"מטרתן": true,
"מטשטש": true,
"מיאוס": true,
"מיאמי": true,
"מיאנה": true,
"מיאנו": true,
"מיאנת": true,
"מיגון": true,
"מיגור": true,
"מיגים": true,
"מיגנה": true,
"מיגנו": true,
"מיגנת": true,
"מיגרה": true,
"מיגרו": true,
"מיגרת": true,
"מידבק": true,
"מידור": true,
"מידות": true,
"מידיו": true,
"מידים": true,
"מידית": true,
"מידלל": true,
"מידמה": true,
"מידעה": true,
"מידעו": true,
"מידעי": true,
"מידעך": true,
"מידעם": true,
"מידען": true,
"מידפק": true,
"מידרה": true,
"מידרו": true,
"מידרת": true,
"מידתה": true,
"מידתו": true,
"מידתי": true,
"מידתך": true,
"מידתם": true,
"מידתן": true,
"מיהרה": true,
"מיהרו": true,
"מיהרת": true,
"מיואש": true,
"מיובא": true,
"מיובל": true,
"מיובש": true,
"מיוגע": true,
"מיודד": true,
"מיודע": true,
"מיוהד": true,
"מיוזע": true,
"מיוחד": true,
"מיוחל": true,
"מיוחם": true,
"מיוחס": true,
"מיולד": true,
"מיומן": true,
"מיונה": true,
"מיונו": true,
"מיונז": true,
"מיוני": true,
"מיונך": true,
"מיונם": true,
"מיונן": true,
"מיוסד": true,
"מיוסף": true,
"מיוסר": true,
"מיועד": true,
"מיועל": true,
"מיוער": true,
"מיופה": true,
"מיוצא": true,
"מיוצב": true,
"מיוצג": true,
"מיוצר": true,
"מיורט": true,
"מיורם": true,
"מיורן": true,
"מיושב": true,
"מיושם": true,
"מיושן": true,
"מיושר": true,
"מיותם": true,
"מיותר": true,
"מיזגה": true,
"מיזגו": true,
"מיזגת": true,
"מיזוג": true,
"מיזמה": true,
"מיזמו": true,
"מיזמי": true,
"מיזמך": true,
"מיזמם": true,
"מיזמן": true,
"מיזעה": true,
"מיזעו": true,
"מיזעי": true,
"מיזעך": true,
"מיזעם": true,
"מיזען": true,
"מיטבה": true,
"מיטבו": true,
"מיטבי": true,
"מיטבך": true,
"מיטבם": true,
"מיטבן": true,
"מיטהר": true,
"מיטוט": true,
"מיטות": true,
"מיטיב": true,
"מיטען": true,
"מיטשל": true,
"מיטתה": true,
"מיטתו": true,
"מיטתי": true,
"מיטתך": true,
"מיטתם": true,
"מיטתן": true,
"מייאש": true,
"מייבא": true,
"מייבב": true,
"מייבם": true,
"מייבש": true,
"מייגע": true,
"מיידה": true,
"מיידי": true,
"מיידע": true,
"מייהד": true,
"מייזם": true,
"מייזע": true,
"מייחד": true,
"מייחל": true,
"מייחם": true,
"מייחס": true,
"מיילד": true,
"מיילל": true,
"מיינד": true,
"מיינה": true,
"מיינו": true,
"מייני": true,
"מיינן": true,
"מיינת": true,
"מייסד": true,
"מייסף": true,
"מייסר": true,
"מייעד": true,
"מייעל": true,
"מייעץ": true,
"מייער": true,
"מייפה": true,
"מייפל": true,
"מייצא": true,
"מייצב": true,
"מייצג": true,
"מייצר": true,
"מייקל": true,
"מייקר": true,
"מיירט": true,
"מיישב": true,
"מיישם": true,
"מיישן": true,
"מיישר": true,
"מייתר": true,
"מיכאל": true,
"מיכון": true,
"מיכלי": true,
"מיכנה": true,
"מיכנו": true,
"מיכנת": true,
"מילאה": true,
"מילאו": true,
"מילאת": true,
"מילגה": true,
"מילוא": true,
"מילוט": true,
"מילוי": true,
"מילול": true,
"מילון": true,
"מילות": true,
"מילטה": true,
"מילטו": true,
"מילטת": true,
"מילים": true,
"מילית": true,
"מילכה": true,
"מילנו": true,
"מילנר": true,
"מילתא": true,
"מילתה": true,
"מילתו": true,
"מילתי": true,
"מילתך": true,
"מילתם": true,
"מילתן": true,
"מימדי": true,
"מימון": true,
"מימוש": true,
"מימות": true,
"מימיה": true,
"מימיו": true,
"מימיי": true,
"מימיך": true,
"מימים": true,
"מימין": true,
"מימית": true,
"מימנה": true,
"מימנו": true,
"מימני": true,
"מימנת": true,
"מימסד": true,
"מימרה": true,
"מימשה": true,
"מימשו": true,
"מימשת": true,
"מינהל": true,
"מינוח": true,
"מינוי": true,
"מינון": true,
"מינוס": true,
"מינוע": true,
"מינוף": true,
"מינור": true,
"מינות": true,
"מינטה": true,
"מיניה": true,
"מיניו": true,
"מיניי": true,
"מיניך": true,
"מינים": true,
"מיניס": true,
"מיניק": true,
"מינית": true,
"מינכם": true,
"מינכן": true,
"מיננו": true,
"מינסק": true,
"מינפה": true,
"מינפו": true,
"מינפת": true,
"מינצר": true,
"מינקה": true,
"מינקת": true,
"מינרל": true,
"מינתה": true,
"מיסדה": true,
"מיסדו": true,
"מיסדת": true,
"מיסוד": true,
"מיסוי": true,
"מיסוך": true,
"מיסות": true,
"מיסטי": true,
"מיסטר": true,
"מיסים": true,
"מיסית": true,
"מיסכה": true,
"מיסכו": true,
"מיסכת": true,
"מיסתה": true,
"מיסתו": true,
"מיסתי": true,
"מיסתך": true,
"מיסתם": true,
"מיסתן": true,
"מיעוט": true,
"מיעון": true,
"מיעטה": true,
"מיעטו": true,
"מיעטת": true,
"מיענה": true,
"מיענו": true,
"מיענת": true,
"מיפוי": true,
"מיפית": true,
"מיפלג": true,
"מיפלס": true,
"מיפקד": true,
"מיפתה": true,
"מיצבה": true,
"מיצבו": true,
"מיצבי": true,
"מיצבך": true,
"מיצבם": true,
"מיצבן": true,
"מיצגה": true,
"מיצגו": true,
"מיצגי": true,
"מיצגך": true,
"מיצגם": true,
"מיצגן": true,
"מיצוב": true,
"מיצוי": true,
"מיצוע": true,
"מיצוק": true,
"מיציה": true,
"מיציו": true,
"מיציי": true,
"מיציך": true,
"מיצים": true,
"מיצית": true,
"מיצכם": true,
"מיצכן": true,
"מיצנו": true,
"מיצעה": true,
"מיצעו": true,
"מיצעת": true,
"מיצקה": true,
"מיצקו": true,
"מיצקת": true,
"מיצתה": true,
"מיקדה": true,
"מיקדו": true,
"מיקדת": true,
"מיקוד": true,
"מיקוח": true,
"מיקום": true,
"מיקור": true,
"מיקוש": true,
"מיקמה": true,
"מיקמו": true,
"מיקמת": true,
"מיקסר": true,
"מיקרו": true,
"מיקשה": true,
"מיקשו": true,
"מיקשת": true,
"מירבי": true,
"מירדף": true,
"מירון": true,
"מירוץ": true,
"מירוק": true,
"מירור": true,
"מירות": true,
"מירים": true,
"מירית": true,
"מירכא": true,
"מירכה": true,
"מירלה": true,
"מירמה": true,
"מירקה": true,
"מירקו": true,
"מירקת": true,
"מיררה": true,
"מיררו": true,
"מיררת": true,
"מישאל": true,
"מישהו": true,
"מישהי": true,
"מישור": true,
"מישוש": true,
"מישטר": true,
"מישים": true,
"מישיר": true,
"מישלב": true,
"מיששה": true,
"מיששו": true,
"מיששת": true,
"מיתגה": true,
"מיתגו": true,
"מיתגת": true,
"מיתוג": true,
"מיתון": true,
"מיתוס": true,
"מיתות": true,
"מיתחם": true,
"מיתים": true,
"מיתית": true,
"מיתמם": true,
"מיתמר": true,
"מיתנה": true,
"מיתנו": true,
"מיתנת": true,
"מיתקן": true,
"מיתרה": true,
"מיתרו": true,
"מיתרי": true,
"מיתרך": true,
"מיתרם": true,
"מיתרן": true,
"מיתתה": true,
"מיתתו": true,
"מיתתי": true,
"מיתתך": true,
"מיתתם": true,
"מיתתן": true,
"מכאבי": true,
"מכאוב": true,
"מכאיב": true,
"מכאני": true,
"מכבדת": true,
"מכבות": true,
"מכביד": true,
"מכביה": true,
"מכבים": true,
"מכביר": true,
"מכביש": true,
"מכבסה": true,
"מכבסת": true,
"מכבשה": true,
"מכבשו": true,
"מכבשי": true,
"מכבשך": true,
"מכבשם": true,
"מכבשן": true,
"מכדור": true,
"מכדרר": true,
"מכהות": true,
"מכהים": true,
"מכהנת": true,
"מכובד": true,
"מכובה": true,
"מכובס": true,
"מכוון": true,
"מכווץ": true,
"מכוחו": true,
"מכויל": true,
"מכויס": true,
"מכויר": true,
"מכוכב": true,
"מכולה": true,
"מכולם": true,
"מכולן": true,
"מכולת": true,
"מכונה": true,
"מכונו": true,
"מכוני": true,
"מכונך": true,
"מכונם": true,
"מכונן": true,
"מכונס": true,
"מכונף": true,
"מכונת": true,
"מכוסה": true,
"מכוסח": true,
"מכוער": true,
"מכופף": true,
"מכופר": true,
"מכורה": true,
"מכושה": true,
"מכושו": true,
"מכושי": true,
"מכושך": true,
"מכושם": true,
"מכושן": true,
"מכושף": true,
"מכותב": true,
"מכותר": true,
"מכותת": true,
"מכזבת": true,
"מכזיב": true,
"מכחדת": true,
"מכחול": true,
"מכחיד": true,
"מכחיל": true,
"מכחיש": true,
"מכחכח": true,
"מכחשת": true,
"מכיון": true,
"מכייל": true,
"מכייס": true,
"מכייף": true,
"מכייר": true,
"מכילה": true,
"מכינה": true,
"מכירה": true,
"מכירת": true,
"מכישה": true,
"מכיתה": true,
"מככבת": true,
"מכלאה": true,
"מכלול": true,
"מכלוף": true,
"מכלות": true,
"מכליא": true,
"מכליב": true,
"מכליה": true,
"מכליו": true,
"מכליי": true,
"מכליך": true,
"מכליל": true,
"מכלים": true,
"מכליס": true,
"מכלית": true,
"מכלכל": true,
"מכלכם": true,
"מכלכן": true,
"מכללה": true,
"מכללת": true,
"מכלנו": true,
"מכמות": true,
"מכמין": true,
"מכנות": true,
"מכנים": true,
"מכניס": true,
"מכניע": true,
"מכנית": true,
"מכנסה": true,
"מכנסו": true,
"מכנסי": true,
"מכנסך": true,
"מכנסם": true,
"מכנסן": true,
"מכנסת": true,
"מכסהו": true,
"מכסות": true,
"מכסחה": true,
"מכסחת": true,
"מכסיה": true,
"מכסיו": true,
"מכסיי": true,
"מכסיך": true,
"מכסים": true,
"מכסיף": true,
"מכסכם": true,
"מכסכן": true,
"מכסנו": true,
"מכספי": true,
"מכספת": true,
"מכסתה": true,
"מכסתו": true,
"מכסתי": true,
"מכסתך": true,
"מכסתם": true,
"מכסתן": true,
"מכעיס": true,
"מכערת": true,
"מכפיל": true,
"מכפיף": true,
"מכפיש": true,
"מכפלה": true,
"מכפלו": true,
"מכפלי": true,
"מכפלך": true,
"מכפלם": true,
"מכפלן": true,
"מכפלת": true,
"מכפרת": true,
"מכפתר": true,
"מכרבל": true,
"מכרהו": true,
"מכרות": true,
"מכרזה": true,
"מכרזו": true,
"מכרזי": true,
"מכרזך": true,
"מכרזם": true,
"מכרזן": true,
"מכרטס": true,
"מכריה": true,
"מכריו": true,
"מכריז": true,
"מכריח": true,
"מכריי": true,
"מכריך": true,
"מכרים": true,
"מכריע": true,
"מכרית": true,
"מכרכם": true,
"מכרכן": true,
"מכרכר": true,
"מכרנו": true,
"מכרסם": true,
"מכרעת": true,
"מכרתי": true,
"מכרתם": true,
"מכרתן": true,
"מכשול": true,
"מכשור": true,
"מכשיל": true,
"מכשיר": true,
"מכשכש": true,
"מכשלה": true,
"מכשפה": true,
"מכשפו": true,
"מכשפי": true,
"מכשפך": true,
"מכשפם": true,
"מכשפן": true,
"מכשפת": true,
"מכתבה": true,
"מכתבו": true,
"מכתבי": true,
"מכתבך": true,
"מכתבם": true,
"מכתבן": true,
"מכתבת": true,
"מכתיב": true,
"מכתים": true,
"מכתיף": true,
"מכתיר": true,
"מכתמה": true,
"מכתמו": true,
"מכתמי": true,
"מכתמך": true,
"מכתמם": true,
"מכתמן": true,
"מכתרת": true,
"מכתשה": true,
"מכתשו": true,
"מכתשי": true,
"מכתשך": true,
"מכתשם": true,
"מכתשן": true,
"מכתתת": true,
"מלאות": true,
"מלאיה": true,
"מלאיו": true,
"מלאיי": true,
"מלאיך": true,
"מלאים": true,
"מלאית": true,
"מלאכה": true,
"מלאכו": true,
"מלאכי": true,
"מלאכך": true,
"מלאכם": true,
"מלאכן": true,
"מלאכת": true,
"מלאנה": true,
"מלאנו": true,
"מלאתה": true,
"מלאתו": true,
"מלאתי": true,
"מלאתך": true,
"מלאתם": true,
"מלאתן": true,
"מלבבת": true,
"מלבוש": true,
"מלבות": true,
"מלבים": true,
"מלבין": true,
"מלביש": true,
"מלבלב": true,
"מלבנה": true,
"מלבנו": true,
"מלבני": true,
"מלבנך": true,
"מלבנם": true,
"מלבנן": true,
"מלבנת": true,
"מלגות": true,
"מלגזה": true,
"מלגלג": true,
"מלגשי": true,
"מלגתה": true,
"מלגתו": true,
"מלגתי": true,
"מלגתך": true,
"מלגתם": true,
"מלגתן": true,
"מלהטט": true,
"מלהיב": true,
"מלהיט": true,
"מלהקת": true,
"מלואה": true,
"מלואו": true,
"מלואי": true,
"מלואך": true,
"מלואם": true,
"מלואן": true,
"מלובה": true,
"מלובן": true,
"מלובש": true,
"מלודי": true,
"מלוהק": true,
"מלווה": true,
"מלווי": true,
"מלווך": true,
"מלוום": true,
"מלוון": true,
"מלוות": true,
"מלוחה": true,
"מלוחח": true,
"מלוחך": true,
"מלוטף": true,
"מלוטש": true,
"מלוכד": true,
"מלוכה": true,
"מלוכל": true,
"מלוכן": true,
"מלומד": true,
"מלונה": true,
"מלונו": true,
"מלוני": true,
"מלונך": true,
"מלונם": true,
"מלונן": true,
"מלופף": true,
"מלוקה": true,
"מלוקט": true,
"מלוקק": true,
"מלזיה": true,
"מלזים": true,
"מלזית": true,
"מלחחת": true,
"מלחיה": true,
"מלחיו": true,
"מלחיי": true,
"מלחיך": true,
"מלחים": true,
"מלחין": true,
"מלחיץ": true,
"מלחית": true,
"מלחכם": true,
"מלחכן": true,
"מלחכת": true,
"מלחלח": true,
"מלחמה": true,
"מלחמת": true,
"מלחנו": true,
"מלחצת": true,
"מלחשש": true,
"מלטזי": true,
"מלטית": true,
"מלטנה": true,
"מלטפת": true,
"מלטשה": true,
"מלטשת": true,
"מליאה": true,
"מליאת": true,
"מלידה": true,
"מליון": true,
"מליות": true,
"מליחה": true,
"מליחו": true,
"מליחי": true,
"מליחך": true,
"מליחם": true,
"מליחן": true,
"מליטה": true,
"מליים": true,
"מלינה": true,
"מליסה": true,
"מליצה": true,
"מליצו": true,
"מליצי": true,
"מליצך": true,
"מליצם": true,
"מליצן": true,
"מליקה": true,
"מליתה": true,
"מליתו": true,
"מליתי": true,
"מליתך": true,
"מליתם": true,
"מליתן": true,
"מלכדה": true,
"מלכדו": true,
"מלכדי": true,
"מלכדת": true,
"מלכוד": true,
"מלכות": true,
"מלכיה": true,
"מלכיו": true,
"מלכיי": true,
"מלכיך": true,
"מלכים": true,
"מלככם": true,
"מלככן": true,
"מלכלך": true,
"מלכנו": true,
"מלכסן": true,
"מלכתה": true,
"מלכתו": true,
"מלכתי": true,
"מלכתך": true,
"מלכתם": true,
"מלכתן": true,
"מללכם": true,
"מללכן": true,
"מללנו": true,
"מלמדה": true,
"מלמדו": true,
"מלמדי": true,
"מלמדך": true,
"מלמדם": true,
"מלמדן": true,
"מלמדת": true,
"מלמול": true,
"מלמטה": true,
"מלמלה": true,
"מלמלו": true,
"מלמלי": true,
"מלמלת": true,
"מלנין": true,
"מלעיג": true,
"מלעיז": true,
"מלעיט": true,
"מלעיל": true,
"מלפני": true,
"מלפפת": true,
"מלצור": true,
"מלצות": true,
"מלצרה": true,
"מלצרו": true,
"מלצרי": true,
"מלצרך": true,
"מלצרם": true,
"מלצרן": true,
"מלצרת": true,
"מלקוש": true,
"מלקות": true,
"מלקטת": true,
"מלקים": true,
"מלקין": true,
"מלקנו": true,
"מלקקת": true,
"מלקתי": true,
"מלקתם": true,
"מלקתן": true,
"מלריה": true,
"מלרעי": true,
"מלשון": true,
"מלשין": true,
"מלשכת": true,
"מלתחה": true,
"מלתעה": true,
"ממאיס": true,
"ממאיר": true,
"ממאנת": true,
"ממארת": true,
"ממבוא": true,
"ממבחר": true,
"ממגדל": true,
"ממגנט": true,
"ממגנת": true,
"ממגרת": true,
"ממדבר": true,
"ממדיה": true,
"ממדיו": true,
"ממדיי": true,
"ממדיך": true,
"ממדים": true,
"ממדית": true,
"ממדכם": true,
"ממדכן": true,
"ממדנו": true,
"ממדרת": true,
"ממהרת": true,
"ממוגן": true,
"ממוגר": true,
"ממודר": true,
"ממוזג": true,
"ממוטט": true,
"ממוין": true,
"ממוכן": true,
"ממולא": true,
"ממולח": true,
"ממולל": true,
"ממומן": true,
"ממומש": true,
"ממונה": true,
"ממונו": true,
"ממוני": true,
"ממונך": true,
"ממונם": true,
"ממונן": true,
"ממונע": true,
"ממונף": true,
"ממוסד": true,
"ממוסה": true,
"ממוסך": true,
"ממוסס": true,
"ממועד": true,
"ממוען": true,
"ממופה": true,
"ממוצא": true,
"ממוצה": true,
"ממוצע": true,
"ממוצק": true,
"ממוקד": true,
"ממוקם": true,
"ממוקש": true,
"ממורט": true,
"ממורק": true,
"ממושך": true,
"ממושש": true,
"ממותג": true,
"ממותה": true,
"ממותן": true,
"ממותק": true,
"ממזגת": true,
"ממזמז": true,
"ממזער": true,
"ממזרה": true,
"ממזרח": true,
"ממזרי": true,
"ממחהו": true,
"ממחזר": true,
"ממחטה": true,
"ממחיה": true,
"ממחיו": true,
"ממחיז": true,
"ממחיי": true,
"ממחיך": true,
"ממחים": true,
"ממחיר": true,
"ממחיש": true,
"ממחכם": true,
"ממחכן": true,
"ממחלה": true,
"ממחלף": true,
"ממחלת": true,
"ממחנה": true,
"ממחנו": true,
"ממחקר": true,
"ממחשב": true,
"ממטיר": true,
"ממטרה": true,
"ממטרו": true,
"ממטרי": true,
"ממטרך": true,
"ממטרם": true,
"ממטרן": true,
"ממיטב": true,
"ממיטה": true,
"ממיין": true,
"ממילא": true,
"ממירה": true,
"ממיתה": true,
"ממכבי": true,
"ממכון": true,
"ממכנת": true,
"ממכרת": true,
"ממלאת": true,
"ממלחה": true,
"ממלטת": true,
"ממליח": true,
"ממליט": true,
"ממליך": true,
"ממליץ": true,
"ממלכד": true,
"ממלכה": true,
"ממלכת": true,
"ממלמל": true,
"ממלצר": true,
"ממלשה": true,
"מממנה": true,
"מממנו": true,
"מממני": true,
"מממנך": true,
"מממנם": true,
"מממנן": true,
"מממנת": true,
"מממשת": true,
"ממנות": true,
"ממנים": true,
"ממנפת": true,
"ממסגר": true,
"ממסדה": true,
"ממסדו": true,
"ממסדי": true,
"ממסדך": true,
"ממסדם": true,
"ממסדן": true,
"ממסדת": true,
"ממסות": true,
"ממסחר": true,
"ממסטל": true,
"ממסים": true,
"ממסכת": true,
"ממסמס": true,
"ממסמר": true,
"ממספר": true,
"ממסרה": true,
"ממסרו": true,
"ממסרי": true,
"ממסרך": true,
"ממסרם": true,
"ממסרן": true,
"ממעגל": true,
"ממעטת": true,
"ממעיד": true,
"ממעיט": true,
"ממעכה": true,
"ממעכו": true,
"ממעכי": true,
"ממעכך": true,
"ממעכם": true,
"ממעכן": true,
"ממעלה": true,
"ממעמד": true,
"ממענת": true,
"ממעצר": true,
"ממערב": true,
"ממפות": true,
"ממפים": true,
"ממצאה": true,
"ממצאו": true,
"ממצאי": true,
"ממצאך": true,
"ממצאם": true,
"ממצאן": true,
"ממצות": true,
"ממציא": true,
"ממצים": true,
"ממצמץ": true,
"ממצעת": true,
"ממצפה": true,
"ממצקת": true,
"ממקדת": true,
"ממקום": true,
"ממקור": true,
"ממקמת": true,
"ממקשת": true,
"ממראה": true,
"ממרום": true,
"ממרות": true,
"ממרחה": true,
"ממרחו": true,
"ממרחי": true,
"ממרחך": true,
"ממרחם": true,
"ממרחן": true,
"ממרחק": true,
"ממריא": true,
"ממריד": true,
"ממרים": true,
"ממריץ": true,
"ממרכז": true,
"ממרפק": true,
"ממרקת": true,
"ממררת": true,
"ממשהו": true,
"ממשות": true,
"ממשחק": true,
"ממשיך": true,
"ממשיל": true,
"ממשית": true,
"ממשכן": true,
"ממשלה": true,
"ממשלו": true,
"ממשלי": true,
"ממשלך": true,
"ממשלם": true,
"ממשלן": true,
"ממשלת": true,
"ממשמר": true,
"ממשמש": true,
"ממשנה": true,
"ממשקה": true,
"ממשקו": true,
"ממשקי": true,
"ממשקך": true,
"ממשקם": true,
"ממשקן": true,
"ממשרד": true,
"ממששת": true,
"ממתגת": true,
"ממתין": true,
"ממתיק": true,
"ממתכת": true,
"ממתנת": true,
"ממתקה": true,
"ממתקו": true,
"ממתקי": true,
"ממתקך": true,
"ממתקם": true,
"ממתקן": true,
"מנאות": true,
"מנאפת": true,
"מנאצת": true,
"מנבאת": true,
"מנביט": true,
"מנבלת": true,
"מנגבת": true,
"מנגדת": true,
"מנגחת": true,
"מנגיד": true,
"מנגנת": true,
"מנדבת": true,
"מנדות": true,
"מנדטה": true,
"מנדטו": true,
"מנדטי": true,
"מנדטך": true,
"מנדטם": true,
"מנדטן": true,
"מנדים": true,
"מנדלב": true,
"מנדנד": true,
"מנהגה": true,
"מנהגו": true,
"מנהגי": true,
"מנהגך": true,
"מנהגם": true,
"מנהגן": true,
"מנהגת": true,
"מנהטן": true,
"מנהיג": true,
"מנהלה": true,
"מנהלו": true,
"מנהלי": true,
"מנהלך": true,
"מנהלם": true,
"מנהלן": true,
"מנהלת": true,
"מנהרה": true,
"מנואל": true,
"מנואץ": true,
"מנוגב": true,
"מנוגד": true,
"מנוגח": true,
"מנוגן": true,
"מנודב": true,
"מנודה": true,
"מנוהג": true,
"מנוהל": true,
"מנווה": true,
"מנווט": true,
"מנוול": true,
"מנוון": true,
"מנוחה": true,
"מנוחם": true,
"מנוחש": true,
"מנוטר": true,
"מנויה": true,
"מנוכה": true,
"מנוכס": true,
"מנוכר": true,
"מנוכש": true,
"מנומס": true,
"מנומק": true,
"מנומר": true,
"מנומש": true,
"מנוסה": true,
"מנוסח": true,
"מנוסר": true,
"מנועה": true,
"מנועו": true,
"מנועי": true,
"מנועך": true,
"מנועם": true,
"מנוען": true,
"מנוער": true,
"מנופה": true,
"מנופו": true,
"מנופח": true,
"מנופי": true,
"מנופך": true,
"מנופם": true,
"מנופן": true,
"מנופף": true,
"מנופץ": true,
"מנופק": true,
"מנוצה": true,
"מנוצח": true,
"מנוצל": true,
"מנוקב": true,
"מנוקד": true,
"מנוקה": true,
"מנוקז": true,
"מנוקר": true,
"מנורה": true,
"מנורת": true,
"מנושל": true,
"מנושק": true,
"מנותב": true,
"מנותח": true,
"מנותץ": true,
"מנותק": true,
"מנזרה": true,
"מנזרו": true,
"מנזרי": true,
"מנזרך": true,
"מנזרם": true,
"מנזרן": true,
"מנחהו": true,
"מנחות": true,
"מנחיה": true,
"מנחיו": true,
"מנחיי": true,
"מנחיך": true,
"מנחיל": true,
"מנחים": true,
"מנחית": true,
"מנחכם": true,
"מנחכן": true,
"מנחמת": true,
"מנחנו": true,
"מנחשת": true,
"מנחתה": true,
"מנחתו": true,
"מנחתי": true,
"מנחתך": true,
"מנחתם": true,
"מנחתן": true,
"מנטבר": true,
"מנטור": true,
"מנטים": true,
"מנטלי": true,
"מנטרה": true,
"מנטרל": true,
"מנטרת": true,
"מניאה": true,
"מניאק": true,
"מניבה": true,
"מנידה": true,
"מניות": true,
"מניחה": true,
"מנייד": true,
"מנייה": true,
"מניין": true,
"מנייר": true,
"מניית": true,
"מנילה": true,
"מנינה": true,
"מנינו": true,
"מניסה": true,
"מניעה": true,
"מניעו": true,
"מניעי": true,
"מניעך": true,
"מניעם": true,
"מניען": true,
"מניעת": true,
"מניפה": true,
"מניקה": true,
"מניתי": true,
"מניתם": true,
"מניתן": true,
"מנכות": true,
"מנכים": true,
"מנכסת": true,
"מנכרת": true,
"מנכשת": true,
"מנמיך": true,
"מנמנם": true,
"מנמקת": true,
"מנסור": true,
"מנסות": true,
"מנסחת": true,
"מנסים": true,
"מנסרה": true,
"מנסרת": true,
"מנעדה": true,
"מנעדו": true,
"מנעדי": true,
"מנעדך": true,
"מנעדם": true,
"מנעדן": true,
"מנעול": true,
"מנעות": true,
"מנעים": true,
"מנענה": true,
"מנענו": true,
"מנענע": true,
"מנערת": true,
"מנעתי": true,
"מנעתם": true,
"מנעתן": true,
"מנפות": true,
"מנפחת": true,
"מנפים": true,
"מנפיק": true,
"מנפיש": true,
"מנפנה": true,
"מנפנף": true,
"מנפצת": true,
"מנפקת": true,
"מנצות": true,
"מנצחת": true,
"מנציח": true,
"מנציל": true,
"מנצים": true,
"מנצלת": true,
"מנצנץ": true,
"מנצרת": true,
"מנקבת": true,
"מנקדת": true,
"מנקות": true,
"מנקזת": true,
"מנקים": true,
"מנקין": true,
"מנקרת": true,
"מנרמל": true,
"מנשאה": true,
"מנשאו": true,
"מנשאי": true,
"מנשאך": true,
"מנשאם": true,
"מנשאן": true,
"מנשבת": true,
"מנשוא": true,
"מנשים": true,
"מנשכה": true,
"מנשכו": true,
"מנשכי": true,
"מנשכך": true,
"מנשכם": true,
"מנשכן": true,
"מנשלת": true,
"מנשנש": true,
"מנשקה": true,
"מנשקו": true,
"מנשקי": true,
"מנשקך": true,
"מנשקם": true,
"מנשקן": true,
"מנשקת": true,
"מנשרה": true,
"מנשרו": true,
"מנשרי": true,
"מנשרך": true,
"מנשרם": true,
"מנשרן": true,
"מנתבת": true,
"מנתחת": true,
"מנתיב": true,
"מנתכם": true,
"מנתכן": true,
"מנתנו": true,
"מנתצת": true,
"מנתקת": true,
"מנתרת": true,
"מסאבת": true,
"מסאיה": true,
"מסאיו": true,
"מסאיי": true,
"מסאיך": true,
"מסאים": true,
"מסאית": true,
"מסאכם": true,
"מסאכן": true,
"מסאלה": true,
"מסאנו": true,
"מסבאה": true,
"מסבבת": true,
"מסבות": true,
"מסביב": true,
"מסביה": true,
"מסביו": true,
"מסביי": true,
"מסביך": true,
"מסבים": true,
"מסביר": true,
"מסבכם": true,
"מסבכן": true,
"מסבכת": true,
"מסבנו": true,
"מסבנת": true,
"מסבסד": true,
"מסברת": true,
"מסגדה": true,
"מסגדו": true,
"מסגדי": true,
"מסגדך": true,
"מסגדם": true,
"מסגדן": true,
"מסגור": true,
"מסגיה": true,
"מסגיו": true,
"מסגיי": true,
"מסגיך": true,
"מסגים": true,
"מסגיר": true,
"מסגכם": true,
"מסגכן": true,
"מסגלת": true,
"מסגנו": true,
"מסגנן": true,
"מסגרה": true,
"מסגרו": true,
"מסגרי": true,
"מסגרך": true,
"מסגרם": true,
"מסגרן": true,
"מסגרת": true,
"מסדיה": true,
"מסדיו": true,
"מסדיי": true,
"מסדיך": true,
"מסדים": true,
"מסדיר": true,
"מסדכם": true,
"מסדכן": true,
"מסדנה": true,
"מסדנו": true,
"מסדרה": true,
"מסדרו": true,
"מסדרי": true,
"מסדרך": true,
"מסדרם": true,
"מסדרן": true,
"מסדרת": true,
"מסואב": true,
"מסובב": true,
"מסובך": true,
"מסובן": true,
"מסוגה": true,
"מסוגו": true,
"מסוגי": true,
"מסוגל": true,
"מסוגר": true,
"מסודר": true,
"מסווג": true,
"מסווה": true,
"מסוות": true,
"מסויג": true,
"מסויד": true,
"מסוים": true,
"מסוכה": true,
"מסוכך": true,
"מסוכל": true,
"מסוכם": true,
"מסוכן": true,
"מסוכר": true,
"מסולא": true,
"מסולף": true,
"מסולק": true,
"מסומא": true,
"מסומל": true,
"מסומם": true,
"מסומן": true,
"מסומר": true,
"מסונג": true,
"מסונן": true,
"מסונף": true,
"מסועה": true,
"מסועו": true,
"מסועי": true,
"מסועך": true,
"מסועם": true,
"מסוען": true,
"מסועף": true,
"מסופה": true,
"מסופו": true,
"מסופח": true,
"מסופי": true,
"מסופך": true,
"מסופם": true,
"מסופן": true,
"מסופק": true,
"מסופר": true,
"מסוצי": true,
"מסוקה": true,
"מסוקו": true,
"מסוקי": true,
"מסוקך": true,
"מסוקם": true,
"מסוקן": true,
"מסוקס": true,
"מסוקר": true,
"מסורב": true,
"מסורה": true,
"מסורו": true,
"מסורי": true,
"מסורך": true,
"מסורם": true,
"מסורן": true,
"מסורס": true,
"מסורק": true,
"מסורת": true,
"מסותת": true,
"מסחור": true,
"מסחטה": true,
"מסחרה": true,
"מסחרו": true,
"מסחרי": true,
"מסחרך": true,
"מסחרם": true,
"מסחרן": true,
"מסחרר": true,
"מסחרת": true,
"מסטול": true,
"מסטיק": true,
"מסטלה": true,
"מסטלו": true,
"מסטלי": true,
"מסטלת": true,
"מסיבה": true,
"מסיבי": true,
"מסיבת": true,
"מסיגה": true,
"מסיהם": true,
"מסיהן": true,
"מסיון": true,
"מסיחה": true,
"מסיטה": true,
"מסייג": true,
"מסייד": true,
"מסייך": true,
"מסיים": true,
"מסייע": true,
"מסייר": true,
"מסיכה": true,
"מסיכם": true,
"מסיכן": true,
"מסילה": true,
"מסילת": true,
"מסינה": true,
"מסינו": true,
"מסיני": true,
"מסיסה": true,
"מסיעה": true,
"מסיקה": true,
"מסיקו": true,
"מסיקי": true,
"מסיקך": true,
"מסיקם": true,
"מסיקן": true,
"מסירה": true,
"מסירת": true,
"מסיתה": true,
"מסכום": true,
"מסכות": true,
"מסכיה": true,
"מסכיו": true,
"מסכיי": true,
"מסכיך": true,
"מסכים": true,
"מסכין": true,
"מסכית": true,
"מסככם": true,
"מסככן": true,
"מסככת": true,
"מסכלת": true,
"מסכמת": true,
"מסכנה": true,
"מסכנו": true,
"מסכני": true,
"מסכנך": true,
"מסכנם": true,
"מסכנן": true,
"מסכנת": true,
"מסכסך": true,
"מסכתה": true,
"מסכתו": true,
"מסכתי": true,
"מסכתך": true,
"מסכתם": true,
"מסכתן": true,
"מסלול": true,
"מסלים": true,
"מסליק": true,
"מסלסל": true,
"מסלעה": true,
"מסלפת": true,
"מסלקה": true,
"מסלקת": true,
"מסמאת": true,
"מסמוס": true,
"מסמור": true,
"מסמיה": true,
"מסמיך": true,
"מסמיק": true,
"מסמכה": true,
"מסמכו": true,
"מסמכי": true,
"מסמכך": true,
"מסמכם": true,
"מסמכן": true,
"מסמלץ": true,
"מסמלת": true,
"מסממת": true,
"מסמנת": true,
"מסמסה": true,
"מסמסו": true,
"מסמסי": true,
"מסמסת": true,
"מסמרה": true,
"מסמרו": true,
"מסמרי": true,
"מסמרך": true,
"מסמרם": true,
"מסמרן": true,
"מסמרת": true,
"מסנגר": true,
"מסנדל": true,
"מסניף": true,
"מסננה": true,
"מסננו": true,
"מסנני": true,
"מסננך": true,
"מסננם": true,
"מסננן": true,
"מסננת": true,
"מסנפת": true,
"מסנתז": true,
"מסעדה": true,
"מסעדו": true,
"מסעדי": true,
"מסעדך": true,
"מסעדם": true,
"מסעדן": true,
"מסעדת": true,
"מסעוד": true,
"מסעות": true,
"מסעיה": true,
"מסעיו": true,
"מסעיי": true,
"מסעיך": true,
"מסעים": true,
"מסעיר": true,
"מסעכם": true,
"מסעכן": true,
"מסענו": true,
"מספוא": true,
"מספור": true,
"מספחת": true,
"מספיג": true,
"מספיד": true,
"מספיק": true,
"מספנה": true,
"מספסר": true,
"מספקת": true,
"מספרה": true,
"מספרו": true,
"מספרי": true,
"מספרך": true,
"מספרם": true,
"מספרן": true,
"מספרר": true,
"מספרת": true,
"מסקלת": true,
"מסקנה": true,
"מסקנו": true,
"מסקרן": true,
"מסקרת": true,
"מסקתי": true,
"מסקתם": true,
"מסקתן": true,
"מסרבל": true,
"מסרבת": true,
"מסרגה": true,
"מסרון": true,
"מסרות": true,
"מסרטה": true,
"מסרטי": true,
"מסרטן": true,
"מסריה": true,
"מסריו": true,
"מסריח": true,
"מסריט": true,
"מסריי": true,
"מסריך": true,
"מסרים": true,
"מסריק": true,
"מסרכם": true,
"מסרכן": true,
"מסרנו": true,
"מסרסר": true,
"מסרסת": true,
"מסרקה": true,
"מסרקו": true,
"מסרקי": true,
"מסרקך": true,
"מסרקם": true,
"מסרקן": true,
"מסרקת": true,
"מסרתי": true,
"מסרתם": true,
"מסרתן": true,
"מסתאב": true,
"מסתבך": true,
"מסתבן": true,
"מסתבר": true,
"מסתגל": true,
"מסתגף": true,
"מסתגר": true,
"מסתדר": true,
"מסתור": true,
"מסתיר": true,
"מסתכל": true,
"מסתכם": true,
"מסתכן": true,
"מסתלק": true,
"מסתמא": true,
"מסתמה": true,
"מסתמו": true,
"מסתמי": true,
"מסתמך": true,
"מסתמם": true,
"מסתמן": true,
"מסתמר": true,
"מסתנו": true,
"מסתנן": true,
"מסתעף": true,
"מסתער": true,
"מסתפח": true,
"מסתפק": true,
"מסתפר": true,
"מסתרה": true,
"מסתרו": true,
"מסתרי": true,
"מסתרך": true,
"מסתרם": true,
"מסתרן": true,
"מסתרק": true,
"מסתתם": true,
"מסתתר": true,
"מסתתת": true,
"מעבדה": true,
"מעבדו": true,
"מעבדי": true,
"מעבדך": true,
"מעבדם": true,
"מעבדן": true,
"מעבדת": true,
"מעבוד": true,
"מעבות": true,
"מעביד": true,
"מעבים": true,
"מעביר": true,
"מעברה": true,
"מעברו": true,
"מעברי": true,
"מעברך": true,
"מעברם": true,
"מעברן": true,
"מעברת": true,
"מעגלה": true,
"מעגלו": true,
"מעגלי": true,
"מעגלך": true,
"מעגלם": true,
"מעגלן": true,
"מעגלת": true,
"מעגנה": true,
"מעגנו": true,
"מעגני": true,
"מעגנך": true,
"מעגנם": true,
"מעגנן": true,
"מעגנת": true,
"מעדות": true,
"מעדיף": true,
"מעדכן": true,
"מעדנה": true,
"מעדנו": true,
"מעדני": true,
"מעדנך": true,
"מעדנם": true,
"מעדנן": true,
"מעדנת": true,
"מעדרה": true,
"מעדרו": true,
"מעדרי": true,
"מעדרך": true,
"מעדרם": true,
"מעדרן": true,
"מעדתי": true,
"מעדתם": true,
"מעדתן": true,
"מעובד": true,
"מעובה": true,
"מעובר": true,
"מעוגל": true,
"מעוגן": true,
"מעודד": true,
"מעודן": true,
"מעודף": true,
"מעווה": true,
"מעוור": true,
"מעוות": true,
"מעוזה": true,
"מעוזו": true,
"מעוזי": true,
"מעוזך": true,
"מעוזם": true,
"מעוזן": true,
"מעוטה": true,
"מעוטר": true,
"מעוין": true,
"מעוכב": true,
"מעוכה": true,
"מעוכל": true,
"מעולה": true,
"מעולל": true,
"מעולם": true,
"מעולף": true,
"מעומד": true,
"מעומם": true,
"מעומק": true,
"מעומת": true,
"מעונב": true,
"מעונג": true,
"מעונה": true,
"מעונו": true,
"מעוני": true,
"מעונך": true,
"מעונם": true,
"מעונן": true,
"מעוסה": true,
"מעופה": true,
"מעופו": true,
"מעופי": true,
"מעופך": true,
"מעופם": true,
"מעופן": true,
"מעופף": true,
"מעופש": true,
"מעוצב": true,
"מעוצה": true,
"מעוקב": true,
"מעוקל": true,
"מעוקם": true,
"מעוקר": true,
"מעורב": true,
"מעורה": true,
"מעורר": true,
"מעושה": true,
"מעושן": true,
"מעושר": true,
"מעזות": true,
"מעזיב": true,
"מעזים": true,
"מעטהו": true,
"מעטות": true,
"מעטיה": true,
"מעטיו": true,
"מעטיי": true,
"מעטיך": true,
"מעטים": true,
"מעטיר": true,
"מעטכם": true,
"מעטכן": true,
"מעטנה": true,
"מעטנו": true,
"מעטפה": true,
"מעטפת": true,
"מעטרת": true,
"מעיבה": true,
"מעידה": true,
"מעיהם": true,
"מעיהן": true,
"מעיון": true,
"מעייה": true,
"מעייך": true,
"מעיים": true,
"מעיין": true,
"מעייף": true,
"מעיכה": true,
"מעיכם": true,
"מעיכן": true,
"מעילה": true,
"מעילו": true,
"מעילי": true,
"מעילך": true,
"מעילם": true,
"מעילן": true,
"מעינו": true,
"מעיני": true,
"מעיפה": true,
"מעיקה": true,
"מעירה": true,
"מעכבי": true,
"מעכבת": true,
"מעכיר": true,
"מעכלת": true,
"מעכנה": true,
"מעכנו": true,
"מעכסת": true,
"מעכתי": true,
"מעכתם": true,
"מעכתן": true,
"מעלות": true,
"מעליב": true,
"מעליה": true,
"מעליו": true,
"מעליל": true,
"מעלים": true,
"מעלית": true,
"מעללה": true,
"מעללו": true,
"מעללי": true,
"מעללך": true,
"מעללם": true,
"מעללן": true,
"מעלנה": true,
"מעלנו": true,
"מעלעל": true,
"מעלתה": true,
"מעלתו": true,
"מעלתי": true,
"מעלתך": true,
"מעלתם": true,
"מעלתן": true,
"מעמדה": true,
"מעמדו": true,
"מעמדי": true,
"מעמדך": true,
"מעמדם": true,
"מעמדן": true,
"מעמדת": true,
"מעמות": true,
"מעמיד": true,
"מעמים": true,
"מעמיס": true,
"מעמיק": true,
"מעמלן": true,
"מעממת": true,
"מעמסה": true,
"מעמסו": true,
"מעמסי": true,
"מעמסך": true,
"מעמסם": true,
"מעמסן": true,
"מעמעם": true,
"מעמתת": true,
"מענגת": true,
"מענדל": true,
"מענהו": true,
"מענות": true,
"מענטז": true,
"מעניה": true,
"מעניו": true,
"מעניי": true,
"מעניך": true,
"מענים": true,
"מענין": true,
"מעניק": true,
"מעניש": true,
"מענכם": true,
"מענכן": true,
"מעננו": true,
"מענקה": true,
"מענקו": true,
"מענקי": true,
"מענקך": true,
"מענקם": true,
"מענקן": true,
"מעסות": true,
"מעסים": true,
"מעסיק": true,
"מעפיל": true,
"מעפנה": true,
"מעפעף": true,
"מעפשת": true,
"מעצבב": true,
"מעצבי": true,
"מעצבן": true,
"מעצבת": true,
"מעצור": true,
"מעציב": true,
"מעצים": true,
"מעצמה": true,
"מעצמו": true,
"מעצמי": true,
"מעצמם": true,
"מעצרה": true,
"מעצרו": true,
"מעצרי": true,
"מעצרך": true,
"מעצרם": true,
"מעצרן": true,
"מעקבה": true,
"מעקבו": true,
"מעקבי": true,
"מעקבך": true,
"מעקבם": true,
"מעקבן": true,
"מעקהו": true,
"מעקות": true,
"מעקיה": true,
"מעקיו": true,
"מעקיי": true,
"מעקיך": true,
"מעקים": true,
"מעקכם": true,
"מעקכן": true,
"מעקלת": true,
"מעקמת": true,
"מעקנו": true,
"מעקפה": true,
"מעקפו": true,
"מעקפי": true,
"מעקפך": true,
"מעקפם": true,
"מעקפן": true,
"מעקצץ": true,
"מעקרת": true,
"מערבא": true,
"מערבב": true,
"מערבה": true,
"מערבו": true,
"מערבי": true,
"מערבך": true,
"מערבל": true,
"מערבם": true,
"מערבן": true,
"מערבת": true,
"מערגל": true,
"מערוך": true,
"מערום": true,
"מערות": true,
"מערטל": true,
"מעריב": true,
"מעריך": true,
"מערים": true,
"מעריף": true,
"מעריץ": true,
"מערכה": true,
"מערכו": true,
"מערכי": true,
"מערכך": true,
"מערכם": true,
"מערכן": true,
"מערכת": true,
"מערער": true,
"מערפל": true,
"מערתה": true,
"מערתו": true,
"מערתי": true,
"מערתך": true,
"מערתם": true,
"מערתן": true,
"מעשבת": true,
"מעשהו": true,
"מעשור": true,
"מעשיה": true,
"מעשיו": true,
"מעשיי": true,
"מעשיך": true,
"מעשים": true,
"מעשיר": true,
"מעשית": true,
"מעשכם": true,
"מעשכן": true,
"מעשנו": true,
"מעשנת": true,
"מעשרה": true,
"מעשרו": true,
"מעשרי": true,
"מעשרך": true,
"מעשרם": true,
"מעשרן": true,
"מעשרת": true,
"מעתיק": true,
"מעתיר": true,
"מעתכם": true,
"מעתכן": true,
"מעתנו": true,
"מעתקה": true,
"מעתקו": true,
"מעתקי": true,
"מעתקך": true,
"מעתקם": true,
"מעתקן": true,
"מפארק": true,
"מפארת": true,
"מפברק": true,
"מפגיז": true,
"מפגין": true,
"מפגיש": true,
"מפגלת": true,
"מפגנה": true,
"מפגנו": true,
"מפגני": true,
"מפגנך": true,
"מפגנם": true,
"מפגנן": true,
"מפגעה": true,
"מפגעו": true,
"מפגעי": true,
"מפגעך": true,
"מפגעם": true,
"מפגען": true,
"מפגעת": true,
"מפגרת": true,
"מפגשה": true,
"מפגשו": true,
"מפגשי": true,
"מפגשך": true,
"מפגשם": true,
"מפגשן": true,
"מפדרת": true,
"מפהקת": true,
"מפואר": true,
"מפוגג": true,
"מפוגל": true,
"מפוהק": true,
"מפוזם": true,
"מפוזר": true,
"מפוחד": true,
"מפוחה": true,
"מפוחו": true,
"מפוחי": true,
"מפוחך": true,
"מפוחם": true,
"מפוחן": true,
"מפוחת": true,
"מפוטם": true,
"מפוטר": true,
"מפויח": true,
"מפויס": true,
"מפוכח": true,
"מפולג": true,
"מפולח": true,
"מפולי": true,
"מפולס": true,
"מפולש": true,
"מפולת": true,
"מפונה": true,
"מפונק": true,
"מפוסל": true,
"מפוסק": true,
"מפוצה": true,
"מפוצח": true,
"מפוצל": true,
"מפוצץ": true,
"מפוקח": true,
"מפורד": true,
"מפורז": true,
"מפורט": true,
"מפורס": true,
"מפורץ": true,
"מפורק": true,
"מפורר": true,
"מפורש": true,
"מפושט": true,
"מפושל": true,
"מפושק": true,
"מפושר": true,
"מפותה": true,
"מפותח": true,
"מפותל": true,
"מפזזת": true,
"מפזמת": true,
"מפזרת": true,
"מפחדת": true,
"מפחיד": true,
"מפחיה": true,
"מפחיו": true,
"מפחיי": true,
"מפחיך": true,
"מפחים": true,
"מפחית": true,
"מפחכם": true,
"מפחכן": true,
"מפחלץ": true,
"מפחמת": true,
"מפחנו": true,
"מפחתת": true,
"מפטיר": true,
"מפטמת": true,
"מפטפט": true,
"מפטרל": true,
"מפטרת": true,
"מפיגה": true,
"מפיות": true,
"מפיחה": true,
"מפייח": true,
"מפייט": true,
"מפיין": true,
"מפייס": true,
"מפילה": true,
"מפינג": true,
"מפינה": true,
"מפיסה": true,
"מפיצה": true,
"מפיצו": true,
"מפיצי": true,
"מפיצך": true,
"מפיצם": true,
"מפיצן": true,
"מפיקה": true,
"מפיקו": true,
"מפיקי": true,
"מפיקך": true,
"מפיקם": true,
"מפיקן": true,
"מפיתה": true,
"מפיתו": true,
"מפיתי": true,
"מפיתך": true,
"מפיתם": true,
"מפיתן": true,
"מפכות": true,
"מפכים": true,
"מפכפך": true,
"מפלגה": true,
"מפלגו": true,
"מפלגי": true,
"מפלגך": true,
"מפלגם": true,
"מפלגן": true,
"מפלגת": true,
"מפלדה": true,
"מפלות": true,
"מפלחת": true,
"מפלטה": true,
"מפלטו": true,
"מפלטי": true,
"מפלטך": true,
"מפלטם": true,
"מפלטן": true,
"מפליא": true,
"מפליג": true,
"מפליה": true,
"מפליו": true,
"מפליט": true,
"מפליי": true,
"מפליך": true,
"מפליל": true,
"מפלים": true,
"מפליץ": true,
"מפליק": true,
"מפלכם": true,
"מפלכן": true,
"מפללת": true,
"מפלמר": true,
"מפלנו": true,
"מפלסה": true,
"מפלסו": true,
"מפלסי": true,
"מפלסך": true,
"מפלסם": true,
"מפלסן": true,
"מפלסת": true,
"מפלפל": true,
"מפלצת": true,
"מפלתה": true,
"מפלתו": true,
"מפלתי": true,
"מפלתך": true,
"מפלתם": true,
"מפלתן": true,
"מפמפם": true,
"מפנהו": true,
"מפנות": true,
"מפנטז": true,
"מפניה": true,
"מפניו": true,
"מפניי": true,
"מפניך": true,
"מפנים": true,
"מפנכם": true,
"מפנכן": true,
"מפננו": true,
"מפנקת": true,
"מפסטר": true,
"מפסיד": true,
"מפסיק": true,
"מפסלת": true,
"מפספס": true,
"מפסקי": true,
"מפסקת": true,
"מפעיל": true,
"מפעים": true,
"מפעלה": true,
"מפעלו": true,
"מפעלי": true,
"מפעלך": true,
"מפעלם": true,
"מפעלן": true,
"מפעמת": true,
"מפענח": true,
"מפעפע": true,
"מפצות": true,
"מפצחת": true,
"מפציה": true,
"מפציו": true,
"מפציי": true,
"מפציך": true,
"מפצים": true,
"מפציע": true,
"מפציץ": true,
"מפציר": true,
"מפצכם": true,
"מפצכן": true,
"מפצלת": true,
"מפצנו": true,
"מפצפץ": true,
"מפקדה": true,
"מפקדו": true,
"מפקדי": true,
"מפקדך": true,
"מפקדם": true,
"מפקדן": true,
"מפקדת": true,
"מפקחת": true,
"מפקטר": true,
"מפקיד": true,
"מפקיע": true,
"מפקיר": true,
"מפקסס": true,
"מפקפק": true,
"מפקשש": true,
"מפרגן": true,
"מפרות": true,
"מפרזל": true,
"מפרזת": true,
"מפרטת": true,
"מפריד": true,
"מפריה": true,
"מפריו": true,
"מפריז": true,
"מפריח": true,
"מפריט": true,
"מפריי": true,
"מפריך": true,
"מפרים": true,
"מפריס": true,
"מפריע": true,
"מפריש": true,
"מפרכם": true,
"מפרכן": true,
"מפרכס": true,
"מפרכת": true,
"מפרמט": true,
"מפרנו": true,
"מפרנס": true,
"מפרסם": true,
"מפרעה": true,
"מפרפר": true,
"מפרצה": true,
"מפרצו": true,
"מפרצי": true,
"מפרצך": true,
"מפרצם": true,
"מפרצן": true,
"מפרצת": true,
"מפרקה": true,
"מפרקו": true,
"מפרקי": true,
"מפרקך": true,
"מפרקם": true,
"מפרקן": true,
"מפרקת": true,
"מפרשה": true,
"מפרשו": true,
"מפרשי": true,
"מפרשך": true,
"מפרשם": true,
"מפרשן": true,
"מפרשת": true,
"מפשטת": true,
"מפשיט": true,
"מפשיל": true,
"מפשיר": true,
"מפשלת": true,
"מפשעה": true,
"מפשפש": true,
"מפשקת": true,
"מפשרת": true,
"מפתות": true,
"מפתחה": true,
"מפתחו": true,
"מפתחי": true,
"מפתחך": true,
"מפתחם": true,
"מפתחן": true,
"מפתחת": true,
"מפתים": true,
"מפתיע": true,
"מפתכם": true,
"מפתכן": true,
"מפתלת": true,
"מפתנה": true,
"מפתנו": true,
"מפתני": true,
"מפתנך": true,
"מפתנם": true,
"מפתנן": true,
"מצאות": true,
"מצאים": true,
"מצאכם": true,
"מצאכן": true,
"מצאנה": true,
"מצאנו": true,
"מצאנז": true,
"מצאתי": true,
"מצאתם": true,
"מצאתן": true,
"מצבור": true,
"מצבות": true,
"מצביא": true,
"מצביה": true,
"מצביו": true,
"מצביי": true,
"מצביך": true,
"מצבים": true,
"מצביע": true,
"מצבית": true,
"מצבכם": true,
"מצבכן": true,
"מצבנו": true,
"מצברה": true,
"מצברו": true,
"מצברח": true,
"מצברי": true,
"מצברך": true,
"מצברם": true,
"מצברן": true,
"מצבתה": true,
"מצבתו": true,
"מצבתי": true,
"מצבתך": true,
"מצבתם": true,
"מצבתן": true,
"מצגות": true,
"מצגיה": true,
"מצגיו": true,
"מצגיי": true,
"מצגיך": true,
"מצגים": true,
"מצגכם": true,
"מצגכן": true,
"מצגנו": true,
"מצגתה": true,
"מצגתו": true,
"מצגתי": true,
"מצגתך": true,
"מצגתם": true,
"מצגתן": true,
"מצדדת": true,
"מצדות": true,
"מצדיע": true,
"מצדיק": true,
"מצדתה": true,
"מצדתו": true,
"מצדתי": true,
"מצדתך": true,
"מצדתם": true,
"מצדתן": true,
"מצהיב": true,
"מצהיר": true,
"מצהלה": true,
"מצובה": true,
"מצודד": true,
"מצודה": true,
"מצודו": true,
"מצודי": true,
"מצודך": true,
"מצודם": true,
"מצודן": true,
"מצודת": true,
"מצווה": true,
"מצוות": true,
"מצוטט": true,
"מצויד": true,
"מצויה": true,
"מצוין": true,
"מצויץ": true,
"מצויר": true,
"מצוית": true,
"מצולב": true,
"מצולה": true,
"מצולם": true,
"מצולע": true,
"מצולק": true,
"מצומד": true,
"מצומק": true,
"מצומת": true,
"מצונן": true,
"מצונף": true,
"מצועף": true,
"מצופה": true,
"מצופו": true,
"מצופי": true,
"מצופך": true,
"מצופם": true,
"מצופן": true,
"מצופף": true,
"מצוצה": true,
"מצוקה": true,
"מצוקו": true,
"מצוקי": true,
"מצוקך": true,
"מצוקם": true,
"מצוקן": true,
"מצוקת": true,
"מצורה": true,
"מצורו": true,
"מצורי": true,
"מצורך": true,
"מצורם": true,
"מצורן": true,
"מצורע": true,
"מצורף": true,
"מצותת": true,
"מצחוק": true,
"מצחות": true,
"מצחיה": true,
"מצחיו": true,
"מצחיי": true,
"מצחיך": true,
"מצחים": true,
"מצחין": true,
"מצחיק": true,
"מצחכם": true,
"מצחכן": true,
"מצחנו": true,
"מצחצח": true,
"מצחקק": true,
"מצחתה": true,
"מצחתו": true,
"מצחתי": true,
"מצחתך": true,
"מצחתם": true,
"מצחתן": true,
"מצטבע": true,
"מצטבר": true,
"מצטדק": true,
"מצטטת": true,
"מצטלב": true,
"מצטלל": true,
"מצטלם": true,
"מצטלק": true,
"מצטמג": true,
"מצטמק": true,
"מצטנן": true,
"מצטנע": true,
"מצטנף": true,
"מצטעף": true,
"מצטער": true,
"מצטפא": true,
"מצטפד": true,
"מצטרד": true,
"מצטרך": true,
"מצטרף": true,
"מציאה": true,
"מציאת": true,
"מציבה": true,
"מציגה": true,
"מצידה": true,
"מצידו": true,
"מצידי": true,
"מצידם": true,
"מציון": true,
"מציות": true,
"מצייד": true,
"מצייה": true,
"מציין": true,
"מצייץ": true,
"מצייר": true,
"מציית": true,
"מצילה": true,
"מצילו": true,
"מצילי": true,
"מצילך": true,
"מצילם": true,
"מצילן": true,
"מצינה": true,
"מצינו": true,
"מציעה": true,
"מציפה": true,
"מציצה": true,
"מציצן": true,
"מציקה": true,
"מציתה": true,
"מציתו": true,
"מציתי": true,
"מציתך": true,
"מציתם": true,
"מציתן": true,
"מצלבה": true,
"מצלול": true,
"מצלות": true,
"מצליב": true,
"מצליח": true,
"מצלים": true,
"מצליף": true,
"מצלמה": true,
"מצלמת": true,
"מצלצל": true,
"מצלקת": true,
"מצמדה": true,
"מצמדו": true,
"מצמדי": true,
"מצמדך": true,
"מצמדם": true,
"מצמדן": true,
"מצמדת": true,
"מצמוץ": true,
"מצמחת": true,
"מצמיא": true,
"מצמיד": true,
"מצמיח": true,
"מצמים": true,
"מצמית": true,
"מצמצה": true,
"מצמצו": true,
"מצמצי": true,
"מצמצם": true,
"מצמצת": true,
"מצמקת": true,
"מצמרר": true,
"מצנזר": true,
"מצנחה": true,
"מצנחו": true,
"מצנחי": true,
"מצנחך": true,
"מצנחם": true,
"מצנחן": true,
"מצניח": true,
"מצניע": true,
"מצנמה": true,
"מצנמו": true,
"מצנמי": true,
"מצנמך": true,
"מצנמם": true,
"מצנמן": true,
"מצננה": true,
"מצננו": true,
"מצנני": true,
"מצננך": true,
"מצננם": true,
"מצננן": true,
"מצננת": true,
"מצנפת": true,
"מצנתר": true,
"מצעדה": true,
"מצעדו": true,
"מצעדי": true,
"מצעדך": true,
"מצעדם": true,
"מצעדן": true,
"מצעות": true,
"מצעיד": true,
"מצעיה": true,
"מצעיו": true,
"מצעיי": true,
"מצעיך": true,
"מצעים": true,
"מצעיר": true,
"מצעכם": true,
"מצעכן": true,
"מצענה": true,
"מצענו": true,
"מצערת": true,
"מצפהו": true,
"מצפון": true,
"מצפור": true,
"מצפות": true,
"מצפיה": true,
"מצפיו": true,
"מצפיי": true,
"מצפיך": true,
"מצפים": true,
"מצפין": true,
"מצפכם": true,
"מצפכן": true,
"מצפנה": true,
"מצפנו": true,
"מצפני": true,
"מצפנך": true,
"מצפנם": true,
"מצפנן": true,
"מצפצף": true,
"מצצנו": true,
"מצצתי": true,
"מצצתם": true,
"מצצתן": true,
"מצקות": true,
"מצקנה": true,
"מצקצק": true,
"מצקתה": true,
"מצקתו": true,
"מצקתי": true,
"מצקתך": true,
"מצקתם": true,
"מצקתן": true,
"מצרות": true,
"מצריד": true,
"מצריה": true,
"מצריו": true,
"מצריח": true,
"מצריי": true,
"מצריך": true,
"מצרים": true,
"מצרית": true,
"מצרכה": true,
"מצרכו": true,
"מצרכי": true,
"מצרכך": true,
"מצרכם": true,
"מצרכן": true,
"מצרנו": true,
"מצרפה": true,
"מצרפו": true,
"מצרפי": true,
"מצרפך": true,
"מצרפם": true,
"מצרפן": true,
"מצרפת": true,
"מצרצר": true,
"מצררה": true,
"מצררו": true,
"מצררי": true,
"מצררך": true,
"מצררם": true,
"מצררן": true,
"מצתיה": true,
"מצתיו": true,
"מצתיי": true,
"מצתיך": true,
"מצתים": true,
"מצתכם": true,
"מצתכן": true,
"מצתנו": true,
"מקבות": true,
"מקביל": true,
"מקבלי": true,
"מקבלן": true,
"מקבלת": true,
"מקבעת": true,
"מקבצת": true,
"מקברי": true,
"מקבתה": true,
"מקבתו": true,
"מקבתי": true,
"מקבתך": true,
"מקבתם": true,
"מקבתן": true,
"מקדות": true,
"מקדחה": true,
"מקדחו": true,
"מקדחי": true,
"מקדחך": true,
"מקדחם": true,
"מקדחן": true,
"מקדיח": true,
"מקדים": true,
"מקדיש": true,
"מקדמה": true,
"מקדמו": true,
"מקדמי": true,
"מקדמך": true,
"מקדמם": true,
"מקדמן": true,
"מקדמת": true,
"מקדנה": true,
"מקדשה": true,
"מקדשו": true,
"מקדשי": true,
"מקדשך": true,
"מקדשם": true,
"מקדשן": true,
"מקדשת": true,
"מקהות": true,
"מקהיל": true,
"מקהים": true,
"מקהלה": true,
"מקהלת": true,
"מקובל": true,
"מקובע": true,
"מקובץ": true,
"מקודד": true,
"מקודם": true,
"מקודש": true,
"מקווה": true,
"מקווי": true,
"מקווך": true,
"מקוום": true,
"מקוון": true,
"מקוות": true,
"מקוזז": true,
"מקוטב": true,
"מקוטע": true,
"מקוים": true,
"מקולה": true,
"מקולו": true,
"מקולח": true,
"מקולי": true,
"מקולך": true,
"מקולל": true,
"מקולם": true,
"מקולן": true,
"מקולף": true,
"מקולק": true,
"מקומה": true,
"מקומו": true,
"מקומח": true,
"מקומט": true,
"מקומי": true,
"מקומך": true,
"מקומם": true,
"מקומן": true,
"מקומר": true,
"מקונח": true,
"מקונן": true,
"מקופד": true,
"מקופה": true,
"מקופו": true,
"מקופח": true,
"מקופי": true,
"מקופך": true,
"מקופל": true,
"מקופם": true,
"מקופן": true,
"מקופת": true,
"מקוצץ": true,
"מקוצר": true,
"מקורב": true,
"מקורה": true,
"מקורו": true,
"מקורי": true,
"מקורך": true,
"מקורם": true,
"מקורן": true,
"מקורץ": true,
"מקורר": true,
"מקושה": true,
"מקושו": true,
"מקושט": true,
"מקושי": true,
"מקושך": true,
"מקושם": true,
"מקושן": true,
"מקושר": true,
"מקושש": true,
"מקושת": true,
"מקזזת": true,
"מקטבת": true,
"מקטין": true,
"מקטיר": true,
"מקטלג": true,
"מקטעה": true,
"מקטעו": true,
"מקטעי": true,
"מקטעך": true,
"מקטעם": true,
"מקטען": true,
"מקטעת": true,
"מקטרג": true,
"מקטרת": true,
"מקיאה": true,
"מקיזה": true,
"מקיים": true,
"מקייץ": true,
"מקימה": true,
"מקימו": true,
"מקימי": true,
"מקימך": true,
"מקימם": true,
"מקימן": true,
"מקיפה": true,
"מקיצה": true,
"מקישה": true,
"מקלדת": true,
"מקלון": true,
"מקלות": true,
"מקלחת": true,
"מקלטה": true,
"מקלטו": true,
"מקלטי": true,
"מקלטך": true,
"מקלטם": true,
"מקלטן": true,
"מקלטר": true,
"מקליד": true,
"מקליט": true,
"מקלים": true,
"מקליק": true,
"מקליש": true,
"מקלכם": true,
"מקלכן": true,
"מקללן": true,
"מקללת": true,
"מקלנו": true,
"מקלסת": true,
"מקלעה": true,
"מקלעו": true,
"מקלעי": true,
"מקלעך": true,
"מקלעם": true,
"מקלען": true,
"מקלעת": true,
"מקלפת": true,
"מקלקל": true,
"מקמחת": true,
"מקמטת": true,
"מקמנה": true,
"מקמצת": true,
"מקמרת": true,
"מקנאת": true,
"מקנהו": true,
"מקנות": true,
"מקנחת": true,
"מקנטר": true,
"מקניה": true,
"מקניו": true,
"מקניט": true,
"מקניי": true,
"מקניך": true,
"מקנים": true,
"מקנכם": true,
"מקנכן": true,
"מקננו": true,
"מקננת": true,
"מקסים": true,
"מקסיק": true,
"מקסמה": true,
"מקסמו": true,
"מקסמי": true,
"מקסמך": true,
"מקסמם": true,
"מקסמן": true,
"מקעקע": true,
"מקפאה": true,
"מקפאו": true,
"מקפאי": true,
"מקפאך": true,
"מקפאם": true,
"מקפאן": true,
"מקפדת": true,
"מקפחת": true,
"מקפיא": true,
"מקפיד": true,
"מקפיה": true,
"מקפיו": true,
"מקפיי": true,
"מקפיך": true,
"מקפים": true,
"מקפיץ": true,
"מקפכם": true,
"מקפכן": true,
"מקפלת": true,
"מקפנו": true,
"מקפצה": true,
"מקפצת": true,
"מקצבה": true,
"מקצבו": true,
"מקצבי": true,
"מקצבך": true,
"מקצבם": true,
"מקצבן": true,
"מקצבת": true,
"מקצהו": true,
"מקצוע": true,
"מקצות": true,
"מקציב": true,
"מקציה": true,
"מקציו": true,
"מקציי": true,
"מקציך": true,
"מקצים": true,
"מקצין": true,
"מקציע": true,
"מקציף": true,
"מקצכם": true,
"מקצכן": true,
"מקצנו": true,
"מקצפת": true,
"מקצצה": true,
"מקצצת": true,
"מקצרת": true,
"מקצתה": true,
"מקצתו": true,
"מקצתי": true,
"מקצתך": true,
"מקצתם": true,
"מקצתן": true,
"מקקיה": true,
"מקקיו": true,
"מקקיי": true,
"מקקיך": true,
"מקקים": true,
"מקקכם": true,
"מקקכן": true,
"מקקנו": true,
"מקראה": true,
"מקראו": true,
"מקראי": true,
"מקראך": true,
"מקראם": true,
"מקראן": true,
"מקרבת": true,
"מקרהו": true,
"מקרוב": true,
"מקרון": true,
"מקרטע": true,
"מקריא": true,
"מקריב": true,
"מקריה": true,
"מקריו": true,
"מקריח": true,
"מקריי": true,
"מקריך": true,
"מקרים": true,
"מקרין": true,
"מקריש": true,
"מקרית": true,
"מקרכם": true,
"מקרכן": true,
"מקרנה": true,
"מקרנו": true,
"מקרני": true,
"מקרנך": true,
"מקרנם": true,
"מקרנן": true,
"מקרצף": true,
"מקרקע": true,
"מקרקף": true,
"מקרקר": true,
"מקרקש": true,
"מקררה": true,
"מקררו": true,
"מקררי": true,
"מקררך": true,
"מקררם": true,
"מקררן": true,
"מקררת": true,
"מקשוב": true,
"מקשות": true,
"מקשטת": true,
"מקשיב": true,
"מקשיה": true,
"מקשיו": true,
"מקשיח": true,
"מקשיי": true,
"מקשיך": true,
"מקשים": true,
"מקשכם": true,
"מקשכן": true,
"מקשנה": true,
"מקשנו": true,
"מקשקש": true,
"מקשרת": true,
"מקשתה": true,
"מקשתו": true,
"מקשתי": true,
"מקשתך": true,
"מקשתם": true,
"מקשתן": true,
"מראהו": true,
"מראות": true,
"מראים": true,
"מראית": true,
"מראכם": true,
"מראכן": true,
"מראנו": true,
"מראני": true,
"מראשי": true,
"מראתה": true,
"מראתו": true,
"מראתי": true,
"מראתך": true,
"מראתם": true,
"מראתן": true,
"מרבבת": true,
"מרבדה": true,
"מרבדו": true,
"מרבדי": true,
"מרבדך": true,
"מרבדם": true,
"מרבדן": true,
"מרבדת": true,
"מרבות": true,
"מרביה": true,
"מרביו": true,
"מרביי": true,
"מרביך": true,
"מרבים": true,
"מרביע": true,
"מרביץ": true,
"מרבית": true,
"מרבכם": true,
"מרבכן": true,
"מרבנו": true,
"מרבעת": true,
"מרבצה": true,
"מרבצו": true,
"מרבצי": true,
"מרבצך": true,
"מרבצם": true,
"מרבצן": true,
"מרגוע": true,
"מרגיז": true,
"מרגיל": true,
"מרגיע": true,
"מרגיש": true,
"מרגלת": true,
"מרגמה": true,
"מרגרט": true,
"מרגשת": true,
"מרדדת": true,
"מרדיה": true,
"מרדיו": true,
"מרדיי": true,
"מרדיך": true,
"מרדים": true,
"מרדכי": true,
"מרדכם": true,
"מרדכן": true,
"מרדנו": true,
"מרדני": true,
"מרדפה": true,
"מרדפו": true,
"מרדפי": true,
"מרדפך": true,
"מרדפם": true,
"מרדפן": true,
"מרדתי": true,
"מרדתם": true,
"מרדתן": true,
"מרהטת": true,
"מרהיב": true,
"מרובב": true,
"מרובד": true,
"מרובה": true,
"מרובע": true,
"מרוגש": true,
"מרודד": true,
"מרודה": true,
"מרוהט": true,
"מרווה": true,
"מרווח": true,
"מרוות": true,
"מרוחה": true,
"מרוחק": true,
"מרוטה": true,
"מרוטש": true,
"מרוכב": true,
"מרוכז": true,
"מרוכך": true,
"מרומה": true,
"מרומו": true,
"מרומז": true,
"מרומי": true,
"מרומך": true,
"מרומם": true,
"מרומן": true,
"מרוסן": true,
"מרוסס": true,
"מרוסק": true,
"מרופא": true,
"מרופד": true,
"מרופט": true,
"מרופף": true,
"מרופש": true,
"מרוצה": true,
"מרוצו": true,
"מרוצי": true,
"מרוצך": true,
"מרוצם": true,
"מרוצן": true,
"מרוצף": true,
"מרוצץ": true,
"מרוקו": true,
"מרוקן": true,
"מרוקע": true,
"מרורה": true,
"מרורו": true,
"מרורי": true,
"מרורך": true,
"מרורם": true,
"מרורן": true,
"מרושל": true,
"מרושע": true,
"מרושש": true,
"מרושת": true,
"מרותה": true,
"מרותו": true,
"מרותי": true,
"מרותך": true,
"מרותם": true,
"מרותן": true,
"מרותק": true,
"מרזבה": true,
"מרזבו": true,
"מרזבי": true,
"מרזבך": true,
"מרזבם": true,
"מרזבן": true,
"מרזוק": true,
"מרזות": true,
"מרזחה": true,
"מרזחו": true,
"מרזחי": true,
"מרזחך": true,
"מרזחם": true,
"מרזחן": true,
"מרזים": true,
"מרחבה": true,
"מרחבו": true,
"מרחבי": true,
"מרחבך": true,
"מרחבם": true,
"מרחבן": true,
"מרחוב": true,
"מרחוק": true,
"מרחיב": true,
"מרחיק": true,
"מרחמת": true,
"מרחנה": true,
"מרחנו": true,
"מרחפת": true,
"מרחצה": true,
"מרחצו": true,
"מרחצי": true,
"מרחצך": true,
"מרחצם": true,
"מרחצן": true,
"מרחקה": true,
"מרחקו": true,
"מרחקי": true,
"מרחקך": true,
"מרחקם": true,
"מרחקן": true,
"מרחרח": true,
"מרחתי": true,
"מרחתם": true,
"מרחתן": true,
"מרטטת": true,
"מרטיב": true,
"מרטיט": true,
"מרטין": true,
"מרטיר": true,
"מרטנו": true,
"מרטשת": true,
"מרטתי": true,
"מרטתם": true,
"מרטתן": true,
"מריבה": true,
"מרידה": true,
"מריות": true,
"מריחה": true,
"מריטה": true,
"מרייה": true,
"מרייך": true,
"מריים": true,
"מריין": true,
"מרימה": true,
"מרינה": true,
"מרינן": true,
"מריעה": true,
"מריצה": true,
"מריקה": true,
"מרירה": true,
"מריתה": true,
"מריתו": true,
"מריתי": true,
"מריתך": true,
"מריתם": true,
"מריתן": true,
"מרכבה": true,
"מרכבו": true,
"מרכבי": true,
"מרכבך": true,
"מרכבם": true,
"מרכבן": true,
"מרכוז": true,
"מרכול": true,
"מרכוס": true,
"מרכזה": true,
"מרכזו": true,
"מרכזי": true,
"מרכזך": true,
"מרכזם": true,
"מרכזן": true,
"מרכזת": true,
"מרכיב": true,
"מרכין": true,
"מרככת": true,
"מרכלת": true,
"מרמור": true,
"מרמות": true,
"מרמזר": true,
"מרמזת": true,
"מרמים": true,
"מרמסה": true,
"מרמסו": true,
"מרמסי": true,
"מרמסך": true,
"מרמסם": true,
"מרמסן": true,
"מרמרה": true,
"מרמרי": true,
"מרמתה": true,
"מרמתו": true,
"מרמתי": true,
"מרמתך": true,
"מרמתם": true,
"מרמתן": true,
"מרנין": true,
"מרננת": true,
"מרסבא": true,
"מרסיה": true,
"מרסיי": true,
"מרסנת": true,
"מרססת": true,
"מרסקת": true,
"מרעהו": true,
"מרעום": true,
"מרעות": true,
"מרעיב": true,
"מרעיד": true,
"מרעיה": true,
"מרעיו": true,
"מרעיי": true,
"מרעיך": true,
"מרעיל": true,
"מרעים": true,
"מרעיף": true,
"מרעיש": true,
"מרעכם": true,
"מרעכן": true,
"מרענו": true,
"מרענן": true,
"מרפאה": true,
"מרפאת": true,
"מרפדה": true,
"מרפדת": true,
"מרפוק": true,
"מרפות": true,
"מרפים": true,
"מרפסת": true,
"מרפקה": true,
"מרפקו": true,
"מרפקי": true,
"מרפקך": true,
"מרפקם": true,
"מרפקן": true,
"מרפקת": true,
"מרפרף": true,
"מרצבך": true,
"מרצדס": true,
"מרצדת": true,
"מרצהו": true,
"מרצון": true,
"מרצות": true,
"מרצחה": true,
"מרצחו": true,
"מרצחי": true,
"מרצחך": true,
"מרצחם": true,
"מרצחן": true,
"מרצחת": true,
"מרציה": true,
"מרציו": true,
"מרציי": true,
"מרציך": true,
"מרצים": true,
"מרצין": true,
"מרצכם": true,
"מרצכן": true,
"מרצנו": true,
"מרצעה": true,
"מרצעו": true,
"מרצעי": true,
"מרצעך": true,
"מרצעם": true,
"מרצען": true,
"מרצפת": true,
"מרצתה": true,
"מרצתו": true,
"מרצתי": true,
"מרצתך": true,
"מרצתם": true,
"מרצתן": true,
"מרקדת": true,
"מרקוס": true,
"מרקחה": true,
"מרקחת": true,
"מרקיב": true,
"מרקיד": true,
"מרקיה": true,
"מרקיו": true,
"מרקיז": true,
"מרקיי": true,
"מרקיך": true,
"מרקים": true,
"מרקיע": true,
"מרקכם": true,
"מרקכן": true,
"מרקמה": true,
"מרקמו": true,
"מרקמי": true,
"מרקמך": true,
"מרקמם": true,
"מרקמן": true,
"מרקנה": true,
"מרקנו": true,
"מרקעה": true,
"מרקעו": true,
"מרקעי": true,
"מרקעך": true,
"מרקעם": true,
"מרקען": true,
"מרקעת": true,
"מרקקה": true,
"מררות": true,
"מררנה": true,
"מררתה": true,
"מררתו": true,
"מררתי": true,
"מררתך": true,
"מררתם": true,
"מררתן": true,
"מרשהו": true,
"מרשוד": true,
"מרשות": true,
"מרשיה": true,
"מרשיו": true,
"מרשיי": true,
"מרשיך": true,
"מרשים": true,
"מרשיע": true,
"מרשכם": true,
"מרשכן": true,
"מרשמה": true,
"מרשמו": true,
"מרשמי": true,
"מרשמך": true,
"מרשמם": true,
"מרשמן": true,
"מרשנו": true,
"מרשעת": true,
"מרשרש": true,
"מרשתה": true,
"מרשתו": true,
"מרשתי": true,
"מרשתך": true,
"מרשתם": true,
"מרשתן": true,
"מרשתת": true,
"מרתון": true,
"מרתיח": true,
"מרתיע": true,
"מרתכם": true,
"מרתכן": true,
"מרתכת": true,
"מרתנו": true,
"מרתפה": true,
"מרתפו": true,
"מרתפי": true,
"מרתפך": true,
"מרתפם": true,
"מרתפן": true,
"מרתקת": true,
"משאבה": true,
"משאבו": true,
"משאבי": true,
"משאבך": true,
"משאבם": true,
"משאבן": true,
"משאבת": true,
"משאות": true,
"משאיל": true,
"משאיף": true,
"משאיר": true,
"משאית": true,
"משאכם": true,
"משאכן": true,
"משאלה": true,
"משאלו": true,
"משאלי": true,
"משאלך": true,
"משאלם": true,
"משאלן": true,
"משאנו": true,
"משאפה": true,
"משאפו": true,
"משאפי": true,
"משאפך": true,
"משאפם": true,
"משאפן": true,
"משאתה": true,
"משאתו": true,
"משאתי": true,
"משאתך": true,
"משאתם": true,
"משאתן": true,
"משבוע": true,
"משבחת": true,
"משבטת": true,
"משביה": true,
"משביו": true,
"משביח": true,
"משביי": true,
"משביך": true,
"משבים": true,
"משביע": true,
"משביר": true,
"משבית": true,
"משבכם": true,
"משבכן": true,
"משבנו": true,
"משבצת": true,
"משברה": true,
"משברו": true,
"משברי": true,
"משברך": true,
"משברם": true,
"משברן": true,
"משברת": true,
"משבשת": true,
"משגהו": true,
"משגוח": true,
"משגיה": true,
"משגיו": true,
"משגיח": true,
"משגיי": true,
"משגיך": true,
"משגים": true,
"משגכם": true,
"משגכן": true,
"משגלה": true,
"משגלו": true,
"משגלי": true,
"משגלך": true,
"משגלם": true,
"משגלן": true,
"משגנו": true,
"משגעת": true,
"משגרה": true,
"משגרו": true,
"משגרי": true,
"משגרך": true,
"משגרם": true,
"משגרן": true,
"משגרת": true,
"משגשג": true,
"משדדת": true,
"משדות": true,
"משדכת": true,
"משדלת": true,
"משדרג": true,
"משדרה": true,
"משדרו": true,
"משדרי": true,
"משדרך": true,
"משדרם": true,
"משדרן": true,
"משדרת": true,
"משהוא": true,
"משהות": true,
"משהים": true,
"משהקת": true,
"משואה": true,
"משובב": true,
"משובה": true,
"משובו": true,
"משובח": true,
"משובט": true,
"משובי": true,
"משובך": true,
"משובם": true,
"משובן": true,
"משובע": true,
"משובץ": true,
"משובר": true,
"משובש": true,
"משוגה": true,
"משוגע": true,
"משוגר": true,
"משודד": true,
"משודך": true,
"משודל": true,
"משודר": true,
"משווה": true,
"משווי": true,
"משווע": true,
"משווק": true,
"משוות": true,
"משוחד": true,
"משוחה": true,
"משוחח": true,
"משוחק": true,
"משוחר": true,
"משוטה": true,
"משוטו": true,
"משוטט": true,
"משוטי": true,
"משוטך": true,
"משוטם": true,
"משוטן": true,
"משויה": true,
"משויך": true,
"משויף": true,
"משוכה": true,
"משוכך": true,
"משוכל": true,
"משוכן": true,
"משולב": true,
"משולה": true,
"משולח": true,
"משולל": true,
"משולם": true,
"משולש": true,
"משומד": true,
"משומן": true,
"משומר": true,
"משומש": true,
"משונה": true,
"משונן": true,
"משונע": true,
"משוסה": true,
"משוסע": true,
"משוסף": true,
"משוער": true,
"משופד": true,
"משופה": true,
"משופו": true,
"משופי": true,
"משופך": true,
"משופם": true,
"משופן": true,
"משופע": true,
"משופץ": true,
"משופר": true,
"משוקם": true,
"משוקע": true,
"משוקף": true,
"משוקץ": true,
"משורה": true,
"משורי": true,
"משורר": true,
"משורש": true,
"משורת": true,
"משושה": true,
"משושו": true,
"משושי": true,
"משושך": true,
"משושם": true,
"משושן": true,
"משותך": true,
"משותף": true,
"משותק": true,
"משזפת": true,
"משחבר": true,
"משחדת": true,
"משחהו": true,
"משחות": true,
"משחזר": true,
"משחזת": true,
"משחטה": true,
"משחיה": true,
"משחיו": true,
"משחיז": true,
"משחיי": true,
"משחיך": true,
"משחיל": true,
"משחים": true,
"משחיר": true,
"משחית": true,
"משחכם": true,
"משחכן": true,
"משחלף": true,
"משחנה": true,
"משחנו": true,
"משחקה": true,
"משחקו": true,
"משחקי": true,
"משחקך": true,
"משחקם": true,
"משחקן": true,
"משחקת": true,
"משחרר": true,
"משחרת": true,
"משחתה": true,
"משחתו": true,
"משחתי": true,
"משחתך": true,
"משחתם": true,
"משחתן": true,
"משחתת": true,
"משטור": true,
"משטות": true,
"משטחי": true,
"משטחת": true,
"משטיה": true,
"משטיו": true,
"משטיי": true,
"משטיך": true,
"משטים": true,
"משטכם": true,
"משטכן": true,
"משטמה": true,
"משטנו": true,
"משטרה": true,
"משטרו": true,
"משטרי": true,
"משטרך": true,
"משטרם": true,
"משטרן": true,
"משטרת": true,
"משיאה": true,
"משיבה": true,
"משיגה": true,
"משיות": true,
"משיחא": true,
"משיחה": true,
"משיחו": true,
"משיחי": true,
"משיחך": true,
"משיחם": true,
"משיחן": true,
"משיטה": true,
"משייה": true,
"משייו": true,
"משייט": true,
"משייך": true,
"משיים": true,
"משיין": true,
"משייף": true,
"משיכה": true,
"משיכו": true,
"משיכת": true,
"משילה": true,
"משימה": true,
"משימת": true,
"משינה": true,
"משינו": true,
"משיסה": true,
"משיקה": true,
"משירה": true,
"משירי": true,
"משיתה": true,
"משיתי": true,
"משיתם": true,
"משיתן": true,
"משכבה": true,
"משכבו": true,
"משכבי": true,
"משכבך": true,
"משכבם": true,
"משכבן": true,
"משכון": true,
"משכות": true,
"משכיב": true,
"משכיה": true,
"משכיו": true,
"משכיח": true,
"משכיי": true,
"משכיך": true,
"משכיל": true,
"משכים": true,
"משכין": true,
"משכיר": true,
"משכית": true,
"משככם": true,
"משככן": true,
"משככת": true,
"משכלה": true,
"משכלו": true,
"משכלי": true,
"משכלך": true,
"משכלל": true,
"משכלם": true,
"משכלן": true,
"משכלת": true,
"משכנה": true,
"משכנו": true,
"משכני": true,
"משכנך": true,
"משכנם": true,
"משכנן": true,
"משכנע": true,
"משכנת": true,
"משכפל": true,
"משכרת": true,
"משכשך": true,
"משכתב": true,
"משכתי": true,
"משכתם": true,
"משכתן": true,
"משלבה": true,
"משלבו": true,
"משלבי": true,
"משלבך": true,
"משלבם": true,
"משלבן": true,
"משלבת": true,
"משלהב": true,
"משלהם": true,
"משלוח": true,
"משלוש": true,
"משלות": true,
"משלחה": true,
"משלחו": true,
"משלחי": true,
"משלחך": true,
"משלחם": true,
"משלחן": true,
"משלחת": true,
"משלטט": true,
"משלטת": true,
"משליה": true,
"משליו": true,
"משליט": true,
"משליי": true,
"משליך": true,
"משלים": true,
"משלכם": true,
"משלכן": true,
"משלמת": true,
"משלנו": true,
"משלשל": true,
"משלשת": true,
"משלתי": true,
"משלתם": true,
"משלתן": true,
"משמאל": true,
"משמוש": true,
"משמחת": true,
"משמיד": true,
"משמיט": true,
"משמים": true,
"משמין": true,
"משמיע": true,
"משמיץ": true,
"משמיש": true,
"משממת": true,
"משמנה": true,
"משמנו": true,
"משמני": true,
"משמנך": true,
"משמנם": true,
"משמנן": true,
"משמנת": true,
"משמעה": true,
"משמעו": true,
"משמעי": true,
"משמעך": true,
"משמעם": true,
"משמען": true,
"משמעת": true,
"משמרה": true,
"משמרו": true,
"משמרי": true,
"משמרך": true,
"משמרם": true,
"משמרן": true,
"משמרת": true,
"משמשה": true,
"משמשו": true,
"משמשי": true,
"משמשך": true,
"משמשם": true,
"משמשן": true,
"משמשת": true,
"משנאי": true,
"משנהו": true,
"משנות": true,
"משניא": true,
"משניה": true,
"משניו": true,
"משניי": true,
"משניך": true,
"משנים": true,
"משנית": true,
"משנכם": true,
"משנכן": true,
"משננו": true,
"משננת": true,
"משנסת": true,
"משנעת": true,
"משנקה": true,
"משנקו": true,
"משנקי": true,
"משנקך": true,
"משנקם": true,
"משנקן": true,
"משנתה": true,
"משנתו": true,
"משנתי": true,
"משנתך": true,
"משנתם": true,
"משנתן": true,
"משסות": true,
"משסים": true,
"משסעת": true,
"משספת": true,
"משעבד": true,
"משעול": true,
"משעות": true,
"משעים": true,
"משעין": true,
"משעמם": true,
"משענה": true,
"משענו": true,
"משעני": true,
"משענך": true,
"משענם": true,
"משענן": true,
"משענת": true,
"משערי": true,
"משערך": true,
"משערת": true,
"משעשע": true,
"משעתק": true,
"משפדת": true,
"משפות": true,
"משפחה": true,
"משפחת": true,
"משפטה": true,
"משפטו": true,
"משפטי": true,
"משפטך": true,
"משפטם": true,
"משפטן": true,
"משפיל": true,
"משפים": true,
"משפיע": true,
"משפכה": true,
"משפכו": true,
"משפכי": true,
"משפכך": true,
"משפכם": true,
"משפכן": true,
"משפעל": true,
"משפעת": true,
"משפצר": true,
"משפצת": true,
"משפרת": true,
"משפשף": true,
"משקהו": true,
"משקוף": true,
"משקות": true,
"משקיה": true,
"משקיו": true,
"משקיט": true,
"משקיי": true,
"משקיך": true,
"משקים": true,
"משקיע": true,
"משקיף": true,
"משקית": true,
"משקכם": true,
"משקכן": true,
"משקלה": true,
"משקלו": true,
"משקלי": true,
"משקלך": true,
"משקלל": true,
"משקלם": true,
"משקלן": true,
"משקמת": true,
"משקנו": true,
"משקעת": true,
"משקפי": true,
"משקפת": true,
"משקצת": true,
"משקרת": true,
"משקשק": true,
"משרבב": true,
"משרבט": true,
"משרדה": true,
"משרדו": true,
"משרדי": true,
"משרדך": true,
"משרדם": true,
"משרדן": true,
"משרות": true,
"משרטט": true,
"משרים": true,
"משריץ": true,
"משריש": true,
"משרעת": true,
"משרפה": true,
"משרשר": true,
"משרשת": true,
"משרתה": true,
"משרתו": true,
"משרתי": true,
"משרתך": true,
"משרתם": true,
"משרתן": true,
"משרתת": true,
"מששנה": true,
"משתאה": true,
"משתבח": true,
"משתבט": true,
"משתבץ": true,
"משתבר": true,
"משתבש": true,
"משתגע": true,
"משתדך": true,
"משתדל": true,
"משתהה": true,
"משתהו": true,
"משתזף": true,
"משתחל": true,
"משתחץ": true,
"משתטה": true,
"משתטח": true,
"משתיה": true,
"משתיו": true,
"משתיי": true,
"משתיך": true,
"משתיל": true,
"משתים": true,
"משתין": true,
"משתיק": true,
"משתית": true,
"משתכח": true,
"משתכם": true,
"משתכן": true,
"משתכר": true,
"משתכת": true,
"משתלב": true,
"משתלה": true,
"משתלח": true,
"משתלט": true,
"משתלם": true,
"משתמד": true,
"משתמט": true,
"משתמע": true,
"משתמר": true,
"משתמש": true,
"משתנה": true,
"משתנו": true,
"משתני": true,
"משתנך": true,
"משתנם": true,
"משתנן": true,
"משתנק": true,
"משתעל": true,
"משתפד": true,
"משתפך": true,
"משתפל": true,
"משתפן": true,
"משתפר": true,
"משתפת": true,
"משתקם": true,
"משתקע": true,
"משתקף": true,
"משתקת": true,
"משתרג": true,
"משתרך": true,
"משתרע": true,
"משתרר": true,
"משתרש": true,
"משתתה": true,
"משתתף": true,
"משתתק": true,
"מתאבד": true,
"מתאבך": true,
"מתאבל": true,
"מתאבן": true,
"מתאבק": true,
"מתאגד": true,
"מתאדה": true,
"מתאהב": true,
"מתאזן": true,
"מתאזר": true,
"מתאחד": true,
"מתאחה": true,
"מתאחר": true,
"מתאים": true,
"מתאכל": true,
"מתאמה": true,
"מתאמו": true,
"מתאמי": true,
"מתאמך": true,
"מתאמם": true,
"מתאמן": true,
"מתאמץ": true,
"מתאמת": true,
"מתאסף": true,
"מתאפס": true,
"מתאפק": true,
"מתאפר": true,
"מתארה": true,
"מתארו": true,
"מתארח": true,
"מתארי": true,
"מתארך": true,
"מתארם": true,
"מתארן": true,
"מתארס": true,
"מתארת": true,
"מתאשר": true,
"מתבאס": true,
"מתבאר": true,
"מתבגר": true,
"מתבדה": true,
"מתבדח": true,
"מתבדל": true,
"מתבדר": true,
"מתבהם": true,
"מתבהר": true,
"מתבזה": true,
"מתבטא": true,
"מתבטל": true,
"מתבלה": true,
"מתבלט": true,
"מתבלת": true,
"מתבסם": true,
"מתבסס": true,
"מתבצע": true,
"מתבצר": true,
"מתבקע": true,
"מתבקש": true,
"מתברג": true,
"מתברך": true,
"מתברר": true,
"מתבשל": true,
"מתבשם": true,
"מתבשר": true,
"מתגאה": true,
"מתגבה": true,
"מתגבר": true,
"מתגבש": true,
"מתגדי": true,
"מתגדר": true,
"מתגיה": true,
"מתגיו": true,
"מתגיי": true,
"מתגיך": true,
"מתגים": true,
"מתגכם": true,
"מתגכן": true,
"מתגלה": true,
"מתגלח": true,
"מתגלם": true,
"מתגלע": true,
"מתגלש": true,
"מתגמד": true,
"מתגמל": true,
"מתגמש": true,
"מתגנב": true,
"מתגנה": true,
"מתגנו": true,
"מתגפף": true,
"מתגרד": true,
"מתגרה": true,
"מתגרש": true,
"מתגשם": true,
"מתדון": true,
"מתדלק": true,
"מתדמה": true,
"מתדפק": true,
"מתדרך": true,
"מתהדק": true,
"מתהדר": true,
"מתהלך": true,
"מתהלל": true,
"מתהפך": true,
"מתואם": true,
"מתואר": true,
"מתובל": true,
"מתודה": true,
"מתודי": true,
"מתווה": true,
"מתווי": true,
"מתווך": true,
"מתוום": true,
"מתוון": true,
"מתוות": true,
"מתוזז": true,
"מתוחה": true,
"מתוחח": true,
"מתוחם": true,
"מתויג": true,
"מתויק": true,
"מתויר": true,
"מתוכה": true,
"מתוכו": true,
"מתוכם": true,
"מתוכן": true,
"מתומן": true,
"מתונה": true,
"מתועב": true,
"מתועד": true,
"מתועל": true,
"מתועש": true,
"מתופף": true,
"מתוקה": true,
"מתוקו": true,
"מתוקי": true,
"מתוקך": true,
"מתוקל": true,
"מתוקם": true,
"מתוקן": true,
"מתוקף": true,
"מתורג": true,
"מתורץ": true,
"מתושע": true,
"מתזזת": true,
"מתזיה": true,
"מתזיו": true,
"מתזיי": true,
"מתזיך": true,
"מתזים": true,
"מתזכם": true,
"מתזכן": true,
"מתזמן": true,
"מתזמר": true,
"מתזנו": true,
"מתחבא": true,
"מתחבב": true,
"מתחבט": true,
"מתחבל": true,
"מתחבק": true,
"מתחבר": true,
"מתחדד": true,
"מתחדש": true,
"מתחום": true,
"מתחזה": true,
"מתחזק": true,
"מתחחת": true,
"מתחיה": true,
"מתחיו": true,
"מתחיי": true,
"מתחיך": true,
"מתחיל": true,
"מתחים": true,
"מתחכך": true,
"מתחכם": true,
"מתחכן": true,
"מתחלב": true,
"מתחלה": true,
"מתחלף": true,
"מתחלק": true,
"מתחמה": true,
"מתחמו": true,
"מתחמי": true,
"מתחמך": true,
"מתחמם": true,
"מתחמן": true,
"מתחמק": true,
"מתחמש": true,
"מתחמת": true,
"מתחנה": true,
"מתחנו": true,
"מתחנך": true,
"מתחנן": true,
"מתחנף": true,
"מתחנת": true,
"מתחסד": true,
"מתחסל": true,
"מתחסן": true,
"מתחפף": true,
"מתחפר": true,
"מתחפש": true,
"מתחצף": true,
"מתחקה": true,
"מתחקר": true,
"מתחרד": true,
"מתחרה": true,
"מתחרו": true,
"מתחרז": true,
"מתחרט": true,
"מתחרי": true,
"מתחרך": true,
"מתחרם": true,
"מתחרן": true,
"מתחרש": true,
"מתחשב": true,
"מתחשל": true,
"מתחשק": true,
"מתחתי": true,
"מתחתם": true,
"מתחתן": true,
"מתחתר": true,
"מתיהם": true,
"מתיהן": true,
"מתיות": true,
"מתיזה": true,
"מתיחה": true,
"מתיחת": true,
"מתייג": true,
"מתייך": true,
"מתיים": true,
"מתייצ": true,
"מתייק": true,
"מתייר": true,
"מתייש": true,
"מתיכה": true,
"מתיכם": true,
"מתיכן": true,
"מתינו": true,
"מתירה": true,
"מתירן": true,
"מתישה": true,
"מתכבד": true,
"מתכבס": true,
"מתכדר": true,
"מתכהה": true,
"מתכוו": true,
"מתכון": true,
"מתכות": true,
"מתכחש": true,
"מתכלה": true,
"מתכנה": true,
"מתכנן": true,
"מתכנס": true,
"מתכנת": true,
"מתכסה": true,
"מתכסח": true,
"מתכער": true,
"מתכפר": true,
"מתכתב": true,
"מתכתה": true,
"מתכתו": true,
"מתכתי": true,
"מתכתך": true,
"מתכתם": true,
"מתכתן": true,
"מתכתש": true,
"מתלבט": true,
"מתלבן": true,
"מתלבש": true,
"מתלהב": true,
"מתלהו": true,
"מתלהט": true,
"מתלהם": true,
"מתלול": true,
"מתלחש": true,
"מתלטף": true,
"מתלטש": true,
"מתליה": true,
"מתליו": true,
"מתליי": true,
"מתליך": true,
"מתלים": true,
"מתלכד": true,
"מתלכם": true,
"מתלכן": true,
"מתלמד": true,
"מתלמי": true,
"מתלנו": true,
"מתלפף": true,
"מתלקח": true,
"מתלקק": true,
"מתלתל": true,
"מתמגן": true,
"מתמדת": true,
"מתמזג": true,
"מתמזל": true,
"מתמחה": true,
"מתמחו": true,
"מתמחי": true,
"מתמחך": true,
"מתמחם": true,
"מתמחן": true,
"מתמחר": true,
"מתמטי": true,
"מתמיד": true,
"מתמיה": true,
"מתמיר": true,
"מתמכן": true,
"מתמכר": true,
"מתמלא": true,
"מתמלל": true,
"מתממש": true,
"מתמנה": true,
"מתמנף": true,
"מתמסד": true,
"מתמסה": true,
"מתמסר": true,
"מתמעט": true,
"מתמצא": true,
"מתמצה": true,
"מתמצק": true,
"מתמצת": true,
"מתמקד": true,
"מתמקח": true,
"מתמקם": true,
"מתמרד": true,
"מתמרח": true,
"מתמרן": true,
"מתמרץ": true,
"מתמרק": true,
"מתמרר": true,
"מתמשך": true,
"מתמתח": true,
"מתמתן": true,
"מתנאה": true,
"מתנאי": true,
"מתנאל": true,
"מתנבא": true,
"מתנגב": true,
"מתנגד": true,
"מתנגח": true,
"מתנגן": true,
"מתנגש": true,
"מתנדב": true,
"מתנדף": true,
"מתנהג": true,
"מתנהל": true,
"מתנול": true,
"מתנות": true,
"מתנזל": true,
"מתנזר": true,
"מתנחל": true,
"מתנחם": true,
"מתנים": true,
"מתניע": true,
"מתנכל": true,
"מתנכר": true,
"מתנסה": true,
"מתנסח": true,
"מתנעה": true,
"מתנעו": true,
"מתנעי": true,
"מתנעך": true,
"מתנעם": true,
"מתנען": true,
"מתנער": true,
"מתנפח": true,
"מתנפל": true,
"מתנפץ": true,
"מתנצח": true,
"מתנצל": true,
"מתנצר": true,
"מתנקה": true,
"מתנקז": true,
"מתנקם": true,
"מתנקש": true,
"מתנשא": true,
"מתנשם": true,
"מתנשף": true,
"מתנשק": true,
"מתנתב": true,
"מתנתה": true,
"מתנתו": true,
"מתנתי": true,
"מתנתך": true,
"מתנתם": true,
"מתנתן": true,
"מתנתק": true,
"מתסיס": true,
"מתסכל": true,
"מתסרט": true,
"מתעבה": true,
"מתעבר": true,
"מתעבת": true,
"מתעגל": true,
"מתעדן": true,
"מתעדף": true,
"מתעדת": true,
"מתעטף": true,
"מתעטש": true,
"מתעכב": true,
"מתעכל": true,
"מתעלה": true,
"מתעלל": true,
"מתעלם": true,
"מתעלס": true,
"מתעלף": true,
"מתעלק": true,
"מתעלת": true,
"מתעמל": true,
"מתעמם": true,
"מתעמק": true,
"מתעמר": true,
"מתעמת": true,
"מתענג": true,
"מתענה": true,
"מתענן": true,
"מתעסק": true,
"מתעפש": true,
"מתעצב": true,
"מתעצל": true,
"מתעצם": true,
"מתעקל": true,
"מתעקם": true,
"מתעקר": true,
"מתעקש": true,
"מתערב": true,
"מתערה": true,
"מתעשר": true,
"מתעשת": true,
"מתעתד": true,
"מתעתע": true,
"מתעתק": true,
"מתפאר": true,
"מתפגל": true,
"מתפגר": true,
"מתפזם": true,
"מתפזר": true,
"מתפחם": true,
"מתפחס": true,
"מתפטם": true,
"מתפטר": true,
"מתפיח": true,
"מתפיל": true,
"מתפכח": true,
"מתפלא": true,
"מתפלג": true,
"מתפלח": true,
"מתפלל": true,
"מתפלץ": true,
"מתפלש": true,
"מתפנה": true,
"מתפנק": true,
"מתפעל": true,
"מתפעם": true,
"מתפצח": true,
"מתפצל": true,
"מתפקד": true,
"מתפקע": true,
"מתפקר": true,
"מתפרה": true,
"מתפרס": true,
"מתפרע": true,
"מתפרץ": true,
"מתפרק": true,
"מתפרש": true,
"מתפשט": true,
"מתפשל": true,
"מתפשר": true,
"מתפתה": true,
"מתפתח": true,
"מתפתל": true,
"מתצפת": true,
"מתקבל": true,
"מתקבע": true,
"מתקבץ": true,
"מתקדם": true,
"מתקדר": true,
"מתקדש": true,
"מתקהל": true,
"מתקזז": true,
"מתקיל": true,
"מתקים": true,
"מתקין": true,
"מתקיף": true,
"מתקכם": true,
"מתקכן": true,
"מתקלח": true,
"מתקלט": true,
"מתקלס": true,
"מתקלף": true,
"מתקלת": true,
"מתקמט": true,
"מתקמר": true,
"מתקנא": true,
"מתקנה": true,
"מתקנו": true,
"מתקני": true,
"מתקנך": true,
"מתקנם": true,
"מתקנן": true,
"מתקנת": true,
"מתקפד": true,
"מתקפה": true,
"מתקפל": true,
"מתקפת": true,
"מתקצב": true,
"מתקצץ": true,
"מתקצר": true,
"מתקרא": true,
"מתקרב": true,
"מתקרח": true,
"מתקרר": true,
"מתקרש": true,
"מתקשה": true,
"מתקשח": true,
"מתקשט": true,
"מתקשר": true,
"מתקתק": true,
"מתראה": true,
"מתרבד": true,
"מתרבה": true,
"מתרבת": true,
"מתרגז": true,
"מתרגל": true,
"מתרגם": true,
"מתרגע": true,
"מתרגש": true,
"מתרדד": true,
"מתרהב": true,
"מתרות": true,
"מתרחב": true,
"מתרחץ": true,
"מתרחק": true,
"מתרחש": true,
"מתרטב": true,
"מתרים": true,
"מתריס": true,
"מתריע": true,
"מתרכב": true,
"מתרכז": true,
"מתרכך": true,
"מתרסה": true,
"מתרסו": true,
"מתרסי": true,
"מתרסך": true,
"מתרסם": true,
"מתרסן": true,
"מתרסק": true,
"מתרעם": true,
"מתרפא": true,
"מתרפה": true,
"מתרפט": true,
"מתרפס": true,
"מתרפק": true,
"מתרצה": true,
"מתרצת": true,
"מתרקם": true,
"מתרשל": true,
"מתרשם": true,
"מתרתח": true,
"מתשאל": true,
"מתתכם": true,
"מתתכן": true,
"מתתנו": true,
"נאבזר": true,
"נאבחן": true,
"נאבטח": true,
"נאביל": true,
"נאביס": true,
"נאביק": true,
"נאבקה": true,
"נאבקו": true,
"נאבקת": true,
"נאגור": true,
"נאגין": true,
"נאגרה": true,
"נאגרו": true,
"נאגרף": true,
"נאגרת": true,
"נאדות": true,
"נאדים": true,
"נאדיר": true,
"נאדית": true,
"נאדכם": true,
"נאדכן": true,
"נאדנו": true,
"נאהבה": true,
"נאהבו": true,
"נאהבת": true,
"נאהדה": true,
"נאהדו": true,
"נאהדת": true,
"נאהוד": true,
"נאהיב": true,
"נאהיל": true,
"נאובן": true,
"נאובק": true,
"נאוגד": true,
"נאוגף": true,
"נאודה": true,
"נאווה": true,
"נאוות": true,
"נאוזן": true,
"נאוחד": true,
"נאוחה": true,
"נאוחל": true,
"נאוחר": true,
"נאויד": true,
"נאוים": true,
"נאוין": true,
"נאויר": true,
"נאויש": true,
"נאוית": true,
"נאוכל": true,
"נאוכן": true,
"נאולף": true,
"נאולץ": true,
"נאומה": true,
"נאומו": true,
"נאומי": true,
"נאומך": true,
"נאומם": true,
"נאומן": true,
"נאומץ": true,
"נאומת": true,
"נאונן": true,
"נאופס": true,
"נאופר": true,
"נאורה": true,
"נאורו": true,
"נאושר": true,
"נאושש": true,
"נאותה": true,
"נאותו": true,
"נאותר": true,
"נאותת": true,
"נאזוק": true,
"נאזור": true,
"נאזין": true,
"נאזכר": true,
"נאזקה": true,
"נאזקו": true,
"נאזקת": true,
"נאזרה": true,
"נאזרו": true,
"נאזרח": true,
"נאזרת": true,
"נאחזה": true,
"נאחזו": true,
"נאחזר": true,
"נאחזת": true,
"נאחיד": true,
"נאחסן": true,
"נאטום": true,
"נאטמה": true,
"נאטמו": true,
"נאטמת": true,
"נאיבי": true,
"נאייד": true,
"נאיים": true,
"נאיין": true,
"נאייר": true,
"נאייש": true,
"נאיית": true,
"נאכבה": true,
"נאכוף": true,
"נאכזב": true,
"נאכיל": true,
"נאכלה": true,
"נאכלו": true,
"נאכלס": true,
"נאכלת": true,
"נאכפה": true,
"נאכפו": true,
"נאכפת": true,
"נאלחה": true,
"נאלחש": true,
"נאלחת": true,
"נאלמה": true,
"נאלמו": true,
"נאלמת": true,
"נאלצה": true,
"נאלצו": true,
"נאלצת": true,
"נאלתר": true,
"נאמדה": true,
"נאמדו": true,
"נאמדת": true,
"נאמוד": true,
"נאמין": true,
"נאמיר": true,
"נאמלל": true,
"נאמנה": true,
"נאמנו": true,
"נאמני": true,
"נאמנך": true,
"נאמנם": true,
"נאמנן": true,
"נאמנת": true,
"נאמרה": true,
"נאמרו": true,
"נאמרת": true,
"נאמתי": true,
"נאמתם": true,
"נאמתן": true,
"נאנוס": true,
"נאנחה": true,
"נאנחו": true,
"נאנחת": true,
"נאניש": true,
"נאנסה": true,
"נאנסו": true,
"נאנסת": true,
"נאנפף": true,
"נאנקה": true,
"נאנקו": true,
"נאנקת": true,
"נאסדק": true,
"נאסוף": true,
"נאסור": true,
"נאסלם": true,
"נאספה": true,
"נאספו": true,
"נאספת": true,
"נאסרה": true,
"נאסרו": true,
"נאסרת": true,
"נאפוף": true,
"נאפות": true,
"נאפיל": true,
"נאפים": true,
"נאפיר": true,
"נאפית": true,
"נאפנה": true,
"נאפנו": true,
"נאפנן": true,
"נאפשר": true,
"נאפתה": true,
"נאפתי": true,
"נאפתם": true,
"נאפתן": true,
"נאצור": true,
"נאצות": true,
"נאציל": true,
"נאצים": true,
"נאצית": true,
"נאצלה": true,
"נאצלת": true,
"נאצנה": true,
"נאצרה": true,
"נאצרו": true,
"נאצרת": true,
"נאצתה": true,
"נאצתו": true,
"נאצתי": true,
"נאצתך": true,
"נאצתם": true,
"נאצתן": true,
"נאקות": true,
"נאקנה": true,
"נאקנו": true,
"נאקתה": true,
"נאקתו": true,
"נאקתי": true,
"נאקתך": true,
"נאקתם": true,
"נאקתן": true,
"נארגה": true,
"נארגו": true,
"נארגן": true,
"נארגת": true,
"נארדו": true,
"נארוב": true,
"נארוג": true,
"נארוז": true,
"נארזה": true,
"נארזו": true,
"נארזת": true,
"נאריך": true,
"נאריק": true,
"נארכב": true,
"נאשים": true,
"נאשמה": true,
"נאשמו": true,
"נאשמת": true,
"נאשפז": true,
"נאשרר": true,
"נאתגר": true,
"נאתחל": true,
"נבאום": true,
"נבאיש": true,
"נבאנה": true,
"נבארי": true,
"נבאשה": true,
"נבאשו": true,
"נבאשת": true,
"נבגדה": true,
"נבגדו": true,
"נבגדת": true,
"נבגוד": true,
"נבגיה": true,
"נבגיו": true,
"נבגיי": true,
"נבגיך": true,
"נבגים": true,
"נבגכם": true,
"נבגכן": true,
"נבגנו": true,
"נבדוק": true,
"נבדיל": true,
"נבדלה": true,
"נבדלו": true,
"נבדלת": true,
"נבדקה": true,
"נבדקו": true,
"נבדקי": true,
"נבדקך": true,
"נבדקם": true,
"נבדקן": true,
"נבדקת": true,
"נבהיל": true,
"נבהיק": true,
"נבהיר": true,
"נבהלה": true,
"נבהלו": true,
"נבהלת": true,
"נבואה": true,
"נבואי": true,
"נבואס": true,
"נבואר": true,
"נבובה": true,
"נבודד": true,
"נבודל": true,
"נבודר": true,
"נבוזה": true,
"נבוזר": true,
"נבוחה": true,
"נבוטא": true,
"נבוטה": true,
"נבוטו": true,
"נבוטח": true,
"נבוטי": true,
"נבוטך": true,
"נבוטל": true,
"נבוטם": true,
"נבוטן": true,
"נבויל": true,
"נבוים": true,
"נבויש": true,
"נבוית": true,
"נבוכד": true,
"נבוכה": true,
"נבוכו": true,
"נבוכת": true,
"נבולה": true,
"נבונה": true,
"נבוסם": true,
"נבוסס": true,
"נבועה": true,
"נבוער": true,
"נבוצע": true,
"נבוצר": true,
"נבוקע": true,
"נבוקר": true,
"נבוקש": true,
"נבורא": true,
"נבורג": true,
"נבורך": true,
"נבורר": true,
"נבושל": true,
"נבושם": true,
"נבושר": true,
"נבושש": true,
"נבותק": true,
"נבותר": true,
"נבזבז": true,
"נבזוז": true,
"נבזוק": true,
"נבזות": true,
"נבזזה": true,
"נבזזו": true,
"נבזזת": true,
"נבזים": true,
"נבזיק": true,
"נבזית": true,
"נבזקה": true,
"נבזקו": true,
"נבזקת": true,
"נבחיל": true,
"נבחין": true,
"נבחנה": true,
"נבחנו": true,
"נבחני": true,
"נבחנת": true,
"נבחרה": true,
"נבחרו": true,
"נבחרי": true,
"נבחרך": true,
"נבחרם": true,
"נבחרן": true,
"נבחרת": true,
"נבחשה": true,
"נבחשו": true,
"נבחשת": true,
"נבחתי": true,
"נבחתם": true,
"נבחתן": true,
"נבטוש": true,
"נבטיה": true,
"נבטיו": true,
"נבטיח": true,
"נבטיי": true,
"נבטיך": true,
"נבטים": true,
"נבטכם": true,
"נבטנו": true,
"נבטתי": true,
"נבטתם": true,
"נבטתן": true,
"נביאה": true,
"נביאו": true,
"נביאי": true,
"נביאך": true,
"נביאם": true,
"נביאן": true,
"נביחה": true,
"נביטה": true,
"נבייל": true,
"נביים": true,
"נבייץ": true,
"נבייש": true,
"נביית": true,
"נבילה": true,
"נביעה": true,
"נבירה": true,
"נבכיה": true,
"נבכיו": true,
"נבכיי": true,
"נבכיך": true,
"נבכים": true,
"נבכיר": true,
"נבלבל": true,
"נבלגן": true,
"נבלוט": true,
"נבלול": true,
"נבלום": true,
"נבלוס": true,
"נבלוש": true,
"נבלות": true,
"נבליג": true,
"נבליה": true,
"נבליו": true,
"נבליח": true,
"נבליט": true,
"נבליי": true,
"נבליך": true,
"נבלים": true,
"נבליע": true,
"נבלכם": true,
"נבלכן": true,
"נבללה": true,
"נבללו": true,
"נבללת": true,
"נבלמה": true,
"נבלמו": true,
"נבלמת": true,
"נבלנה": true,
"נבלנו": true,
"נבלעה": true,
"נבלעו": true,
"נבלעת": true,
"נבלתה": true,
"נבלתו": true,
"נבלתי": true,
"נבלתך": true,
"נבלתם": true,
"נבלתן": true,
"נבנאם": true,
"נבנות": true,
"נבנים": true,
"נבנית": true,
"נבנצל": true,
"נבנתה": true,
"נבעבע": true,
"נבעות": true,
"נבעטה": true,
"נבעטו": true,
"נבעטת": true,
"נבעים": true,
"נבעיר": true,
"נבעית": true,
"נבעלה": true,
"נבעלו": true,
"נבעלת": true,
"נבענה": true,
"נבענו": true,
"נבערה": true,
"נבערת": true,
"נבעתה": true,
"נבעתו": true,
"נבעתי": true,
"נבעתם": true,
"נבעתן": true,
"נבעתת": true,
"נבצבץ": true,
"נבצור": true,
"נבצעה": true,
"נבצעו": true,
"נבצעת": true,
"נבצרה": true,
"נבצרו": true,
"נבצרת": true,
"נבקבק": true,
"נבקיע": true,
"נבקעה": true,
"נבקעו": true,
"נבקעת": true,
"נבקשך": true,
"נבראה": true,
"נבראו": true,
"נבראת": true,
"נברבר": true,
"נבריא": true,
"נבריג": true,
"נבריז": true,
"נבריח": true,
"נבריק": true,
"נבריש": true,
"נברנה": true,
"נברנו": true,
"נברני": true,
"נברנך": true,
"נברנם": true,
"נברנן": true,
"נברשת": true,
"נברתי": true,
"נברתם": true,
"נברתן": true,
"נבשיל": true,
"נגאלה": true,
"נגאלו": true,
"נגאלת": true,
"נגבול": true,
"נגבות": true,
"נגביה": true,
"נגביל": true,
"נגבים": true,
"נגביר": true,
"נגבית": true,
"נגבנה": true,
"נגבתה": true,
"נגדום": true,
"נגדות": true,
"נגדיה": true,
"נגדיו": true,
"נגדיי": true,
"נגדיך": true,
"נגדיל": true,
"נגדים": true,
"נגדיר": true,
"נגדיש": true,
"נגדית": true,
"נגדכם": true,
"נגדכן": true,
"נגדנה": true,
"נגדנו": true,
"נגדעה": true,
"נגדעו": true,
"נגדעת": true,
"נגדתה": true,
"נגדתו": true,
"נגדתי": true,
"נגדתך": true,
"נגדתם": true,
"נגדתן": true,
"נגהנה": true,
"נגהנו": true,
"נגהתי": true,
"נגהתם": true,
"נגהתן": true,
"נגובב": true,
"נגובה": true,
"נגובן": true,
"נגובס": true,
"נגובש": true,
"נגודל": true,
"נגודף": true,
"נגודר": true,
"נגוהץ": true,
"נגוון": true,
"נגווע": true,
"נגוזה": true,
"נגוזו": true,
"נגוחה": true,
"נגוחך": true,
"נגויס": true,
"נגויר": true,
"נגולה": true,
"נגולו": true,
"נגולח": true,
"נגולל": true,
"נגולם": true,
"נגולף": true,
"נגומד": true,
"נגומר": true,
"נגונה": true,
"נגונן": true,
"נגוסה": true,
"נגועה": true,
"נגופר": true,
"נגורה": true,
"נגורז": true,
"נגורף": true,
"נגורר": true,
"נגורש": true,
"נגושר": true,
"נגזוז": true,
"נגזול": true,
"נגזום": true,
"נגזור": true,
"נגזזה": true,
"נגזזו": true,
"נגזזת": true,
"נגזים": true,
"נגזלה": true,
"נגזלו": true,
"נגזלת": true,
"נגזמה": true,
"נגזמו": true,
"נגזמת": true,
"נגזרה": true,
"נגזרו": true,
"נגזרת": true,
"נגחיך": true,
"נגחנה": true,
"נגחנו": true,
"נגחתי": true,
"נגחתם": true,
"נגחתן": true,
"נגטיב": true,
"נגידה": true,
"נגידו": true,
"נגידי": true,
"נגידך": true,
"נגידם": true,
"נגידן": true,
"נגיהה": true,
"נגיחה": true,
"נגייס": true,
"נגייר": true,
"נגילה": true,
"נגינה": true,
"נגיסה": true,
"נגיעה": true,
"נגיפה": true,
"נגיפו": true,
"נגיפי": true,
"נגיפך": true,
"נגיפם": true,
"נגיפן": true,
"נגישה": true,
"נגלגל": true,
"נגלול": true,
"נגלוש": true,
"נגלות": true,
"נגליד": true,
"נגליה": true,
"נגלים": true,
"נגליש": true,
"נגלית": true,
"נגללה": true,
"נגללו": true,
"נגללת": true,
"נגלען": true,
"נגלתה": true,
"נגמגם": true,
"נגמול": true,
"נגמור": true,
"נגמיש": true,
"נגמלה": true,
"נגמלו": true,
"נגמלת": true,
"נגמעה": true,
"נגמעו": true,
"נגמעת": true,
"נגמרה": true,
"נגמרו": true,
"נגמרת": true,
"נגנבה": true,
"נגנבו": true,
"נגנבת": true,
"נגנוב": true,
"נגנוז": true,
"נגנון": true,
"נגנזה": true,
"נגנזו": true,
"נגנזת": true,
"נגניב": true,
"נגניה": true,
"נגניו": true,
"נגניי": true,
"נגניך": true,
"נגנים": true,
"נגנית": true,
"נגנכם": true,
"נגנכן": true,
"נגננו": true,
"נגסוס": true,
"נגסנו": true,
"נגסקי": true,
"נגסתי": true,
"נגסתם": true,
"נגסתן": true,
"נגעגע": true,
"נגעיה": true,
"נגעיו": true,
"נגעיי": true,
"נגעיך": true,
"נגעיל": true,
"נגעים": true,
"נגעיש": true,
"נגעכם": true,
"נגעכן": true,
"נגעלה": true,
"נגעלו": true,
"נגעלת": true,
"נגענה": true,
"נגענו": true,
"נגעתי": true,
"נגעתם": true,
"נגעתן": true,
"נגפיה": true,
"נגפיו": true,
"נגפיי": true,
"נגפיך": true,
"נגפים": true,
"נגפכם": true,
"נגפכן": true,
"נגפנו": true,
"נגקוק": true,
"נגרבה": true,
"נגרבו": true,
"נגרבת": true,
"נגרגר": true,
"נגרוב": true,
"נגרוד": true,
"נגרום": true,
"נגרוס": true,
"נגרוף": true,
"נגרור": true,
"נגרות": true,
"נגריה": true,
"נגריו": true,
"נגריי": true,
"נגריך": true,
"נגריל": true,
"נגרים": true,
"נגרית": true,
"נגרכם": true,
"נגרכן": true,
"נגרמה": true,
"נגרמו": true,
"נגרמת": true,
"נגרנו": true,
"נגרסה": true,
"נגרסו": true,
"נגרסת": true,
"נגרעה": true,
"נגרעו": true,
"נגרען": true,
"נגרעת": true,
"נגרפה": true,
"נגרפו": true,
"נגרפת": true,
"נגררה": true,
"נגררו": true,
"נגררת": true,
"נגשות": true,
"נגשים": true,
"נגשנו": true,
"נגשתי": true,
"נגשתם": true,
"נגשתן": true,
"נדאיב": true,
"נדאיג": true,
"נדבות": true,
"נדבים": true,
"נדביק": true,
"נדביר": true,
"נדבכה": true,
"נדבכו": true,
"נדבכי": true,
"נדבכך": true,
"נדבכם": true,
"נדבכן": true,
"נדבנה": true,
"נדבנו": true,
"נדבני": true,
"נדבנך": true,
"נדבנם": true,
"נדבנן": true,
"נדבקה": true,
"נדבקו": true,
"נדבקת": true,
"נדברה": true,
"נדברו": true,
"נדברר": true,
"נדברת": true,
"נדבתה": true,
"נדבתו": true,
"נדבתי": true,
"נדבתך": true,
"נדבתם": true,
"נדבתן": true,
"נדגדג": true,
"נדגול": true,
"נדגום": true,
"נדגור": true,
"נדגים": true,
"נדגיר": true,
"נדגיש": true,
"נדגמה": true,
"נדגמו": true,
"נדגמן": true,
"נדגמת": true,
"נדדנו": true,
"נדדתי": true,
"נדדתם": true,
"נדדתן": true,
"נדהים": true,
"נדהיר": true,
"נדהמה": true,
"נדהמו": true,
"נדהמת": true,
"נדובב": true,
"נדובג": true,
"נדובר": true,
"נדודה": true,
"נדווח": true,
"נדווש": true,
"נדויק": true,
"נדוכא": true,
"נדולג": true,
"נדולל": true,
"נדומה": true,
"נדומם": true,
"נדונה": true,
"נדונו": true,
"נדופן": true,
"נדורג": true,
"נדושה": true,
"נדושן": true,
"נדותי": true,
"נדותם": true,
"נדותן": true,
"נדחוף": true,
"נדחות": true,
"נדחים": true,
"נדחיק": true,
"נדחית": true,
"נדחסה": true,
"נדחסו": true,
"נדחסת": true,
"נדחפה": true,
"נדחפו": true,
"נדחפת": true,
"נדחקה": true,
"נדחקו": true,
"נדחקת": true,
"נדחתה": true,
"נדיאל": true,
"נדיבה": true,
"נדידה": true,
"נדיים": true,
"נדייק": true,
"נדימה": true,
"נדינה": true,
"נדיפה": true,
"נדיפר": true,
"נדירה": true,
"נדכאת": true,
"נדכדך": true,
"נדלדל": true,
"נדלוף": true,
"נדלוק": true,
"נדלות": true,
"נדליה": true,
"נדליו": true,
"נדליי": true,
"נדליך": true,
"נדלים": true,
"נדליף": true,
"נדליק": true,
"נדלית": true,
"נדלכם": true,
"נדלכן": true,
"נדלנו": true,
"נדלני": true,
"נדלקה": true,
"נדלקו": true,
"נדלקת": true,
"נדלתה": true,
"נדמום": true,
"נדמות": true,
"נדמים": true,
"נדמית": true,
"נדמנו": true,
"נדמתה": true,
"נדמתי": true,
"נדמתם": true,
"נדמתן": true,
"נדנדה": true,
"נדנדו": true,
"נדנדי": true,
"נדנדת": true,
"נדנוד": true,
"נדניה": true,
"נדניו": true,
"נדניי": true,
"נדניך": true,
"נדנים": true,
"נדנכם": true,
"נדנכן": true,
"נדננו": true,
"נדסים": true,
"נדסקס": true,
"נדפדף": true,
"נדפוק": true,
"נדפיס": true,
"נדפנו": true,
"נדפסה": true,
"נדפסו": true,
"נדפסת": true,
"נדפקה": true,
"נדפקו": true,
"נדפקת": true,
"נדפתי": true,
"נדפתם": true,
"נדפתן": true,
"נדקדק": true,
"נדקור": true,
"נדקלם": true,
"נדקרה": true,
"נדקרו": true,
"נדקרת": true,
"נדרבן": true,
"נדרדר": true,
"נדרוך": true,
"נדרוס": true,
"נדרוש": true,
"נדריה": true,
"נדריו": true,
"נדריי": true,
"נדריך": true,
"נדרים": true,
"נדרכה": true,
"נדרכו": true,
"נדרכם": true,
"נדרכן": true,
"נדרכת": true,
"נדרנו": true,
"נדרסה": true,
"נדרסו": true,
"נדרסת": true,
"נדרשה": true,
"נדרשו": true,
"נדרשת": true,
"נדרתי": true,
"נדרתם": true,
"נדרתן": true,
"נדשדש": true,
"נהבהב": true,
"נהגות": true,
"נהגיה": true,
"נהגיו": true,
"נהגיי": true,
"נהגיך": true,
"נהגים": true,
"נהגית": true,
"נהגכם": true,
"נהגכן": true,
"נהגנה": true,
"נהגנו": true,
"נהגתה": true,
"נהגתו": true,
"נהגתי": true,
"נהגתך": true,
"נהגתם": true,
"נהגתן": true,
"נהדהד": true,
"נהדוף": true,
"נהדיר": true,
"נהדפה": true,
"נהדפו": true,
"נהדפת": true,
"נהדרת": true,
"נהוגה": true,
"נהודק": true,
"נהודר": true,
"נהווה": true,
"נהוון": true,
"נהולל": true,
"נהומם": true,
"נהוסה": true,
"נהורה": true,
"נהיאן": true,
"נהיגה": true,
"נהיות": true,
"נהייה": true,
"נהיים": true,
"נהיית": true,
"נהימה": true,
"נהינה": true,
"נהינו": true,
"נהירה": true,
"נהיתי": true,
"נהיתם": true,
"נהיתן": true,
"נהלום": true,
"נהלות": true,
"נהליה": true,
"נהליו": true,
"נהליי": true,
"נהליך": true,
"נהלים": true,
"נהלנה": true,
"נהמגן": true,
"נהמהם": true,
"נהמות": true,
"נהמנה": true,
"נהמנו": true,
"נהמתה": true,
"נהמתו": true,
"נהמתי": true,
"נהמתך": true,
"נהמתם": true,
"נהמתן": true,
"נהנדס": true,
"נהנהן": true,
"נהנות": true,
"נהנים": true,
"נהנית": true,
"נהננו": true,
"נהנתה": true,
"נהנתי": true,
"נהנתן": true,
"נהפוך": true,
"נהפכה": true,
"נהפכו": true,
"נהפכת": true,
"נהפנט": true,
"נהרגה": true,
"נהרגו": true,
"נהרגת": true,
"נהרהר": true,
"נהרוג": true,
"נהרוס": true,
"נהרות": true,
"נהריה": true,
"נהרין": true,
"נהרכם": true,
"נהרכן": true,
"נהרנה": true,
"נהרנו": true,
"נהרסה": true,
"נהרסו": true,
"נהרסת": true,
"נהרתה": true,
"נהרתו": true,
"נהרתי": true,
"נהרתך": true,
"נהרתם": true,
"נהרתן": true,
"נואבס": true,
"נואבק": true,
"נואדר": true,
"נואחד": true,
"נואיל": true,
"נואכל": true,
"נואלה": true,
"נואלת": true,
"נואמר": true,
"נואמת": true,
"נואפל": true,
"נואפת": true,
"נואצה": true,
"נואצו": true,
"נואצל": true,
"נואצת": true,
"נואקת": true,
"נוארך": true,
"נוארק": true,
"נואשה": true,
"נואשו": true,
"נואשם": true,
"נואשת": true,
"נובאש": true,
"נובדל": true,
"נובהל": true,
"נובהר": true,
"נובות": true,
"נובזק": true,
"נובחל": true,
"נובחן": true,
"נובחת": true,
"נובטח": true,
"נובטת": true,
"נוביל": true,
"נוביץ": true,
"נובלג": true,
"נובלה": true,
"נובלט": true,
"נובלע": true,
"נובלת": true,
"נובנה": true,
"נובער": true,
"נובעת": true,
"נובקע": true,
"נוברג": true,
"נוברז": true,
"נוברח": true,
"נוברק": true,
"נוברר": true,
"נוברש": true,
"נוברת": true,
"נוגבה": true,
"נוגבו": true,
"נוגבל": true,
"נוגבר": true,
"נוגבת": true,
"נוגדה": true,
"נוגדו": true,
"נוגדי": true,
"נוגדל": true,
"נוגדן": true,
"נוגדר": true,
"נוגדש": true,
"נוגדת": true,
"נוגהה": true,
"נוגהו": true,
"נוגהי": true,
"נוגהך": true,
"נוגהם": true,
"נוגהן": true,
"נוגהת": true,
"נוגות": true,
"נוגזם": true,
"נוגחה": true,
"נוגחו": true,
"נוגחת": true,
"נוגים": true,
"נוגיע": true,
"נוגלה": true,
"נוגלש": true,
"נוגמש": true,
"נוגנב": true,
"נוגנה": true,
"נוגנו": true,
"נוגנת": true,
"נוגסת": true,
"נוגעל": true,
"נוגעת": true,
"נוגרל": true,
"נוגשם": true,
"נוגשת": true,
"נודאג": true,
"נודבה": true,
"נודבו": true,
"נודבק": true,
"נודבר": true,
"נודבת": true,
"נודגם": true,
"נודגר": true,
"נודגש": true,
"נודדת": true,
"נודהר": true,
"נודחק": true,
"נודיע": true,
"נודית": true,
"נודלף": true,
"נודלק": true,
"נודמם": true,
"נודמת": true,
"נודנה": true,
"נודעה": true,
"נודעו": true,
"נודעת": true,
"נודפס": true,
"נודפת": true,
"נודרך": true,
"נודרת": true,
"נודתה": true,
"נוהגה": true,
"נוהגו": true,
"נוהגי": true,
"נוהגך": true,
"נוהגם": true,
"נוהגן": true,
"נוהגת": true,
"נוהדר": true,
"נוהות": true,
"נוהים": true,
"נוהלה": true,
"נוהלו": true,
"נוהלי": true,
"נוהלך": true,
"נוהלם": true,
"נוהלן": true,
"נוהלת": true,
"נוהמת": true,
"נוהרת": true,
"נוודא": true,
"נוודה": true,
"נוודו": true,
"נוודי": true,
"נוודך": true,
"נוודם": true,
"נוודן": true,
"נוודת": true,
"נווהו": true,
"נווטה": true,
"נווטו": true,
"נווטי": true,
"נווטך": true,
"נווטם": true,
"נווטן": true,
"נווטת": true,
"נווכם": true,
"נווכן": true,
"נוולס": true,
"נוונה": true,
"נוונו": true,
"נווני": true,
"נוונת": true,
"נווסת": true,
"נוותר": true,
"נוזהב": true,
"נוזהר": true,
"נוזיל": true,
"נוזכר": true,
"נוזלה": true,
"נוזלו": true,
"נוזלי": true,
"נוזלך": true,
"נוזלם": true,
"נוזלן": true,
"נוזלת": true,
"נוזמן": true,
"נוזנח": true,
"נוזנק": true,
"נוזעק": true,
"נוזפת": true,
"נוזקה": true,
"נוזקף": true,
"נוזרם": true,
"נוזרע": true,
"נוזרק": true,
"נוחבא": true,
"נוחדר": true,
"נוחות": true,
"נוחזק": true,
"נוחזר": true,
"נוחטא": true,
"נוחיה": true,
"נוחיל": true,
"נוחים": true,
"נוחכר": true,
"נוחלט": true,
"נוחלף": true,
"נוחלק": true,
"נוחלש": true,
"נוחלת": true,
"נוחמא": true,
"נוחמה": true,
"נוחמו": true,
"נוחמץ": true,
"נוחמר": true,
"נוחמת": true,
"נוחנה": true,
"נוחנק": true,
"נוחסר": true,
"נוחצן": true,
"נוחרב": true,
"נוחרג": true,
"נוחרד": true,
"נוחרם": true,
"נוחרף": true,
"נוחרש": true,
"נוחרת": true,
"נוחשד": true,
"נוחשה": true,
"נוחשו": true,
"נוחשך": true,
"נוחשת": true,
"נוחתם": true,
"נוחתת": true,
"נוטבל": true,
"נוטבע": true,
"נוטות": true,
"נוטים": true,
"נוטלא": true,
"נוטלת": true,
"נוטמן": true,
"נוטמע": true,
"נוטעה": true,
"נוטעם": true,
"נוטען": true,
"נוטעת": true,
"נוטפת": true,
"נוטרד": true,
"נוטרה": true,
"נוטרו": true,
"נוטרח": true,
"נוטרי": true,
"נוטרך": true,
"נוטרל": true,
"נוטרם": true,
"נוטרן": true,
"נוטרת": true,
"נוטשת": true,
"נויות": true,
"נויים": true,
"נויכם": true,
"נויכן": true,
"נוימן": true,
"נוינו": true,
"נוכבד": true,
"נוכהה": true,
"נוכחד": true,
"נוכחה": true,
"נוכחו": true,
"נוכחי": true,
"נוכחך": true,
"נוכחם": true,
"נוכחן": true,
"נוכחש": true,
"נוכחת": true,
"נוכיח": true,
"נוכית": true,
"נוכלב": true,
"נוכלה": true,
"נוכלו": true,
"נוכלי": true,
"נוכלך": true,
"נוכלל": true,
"נוכלם": true,
"נוכלן": true,
"נוכלת": true,
"נוכמן": true,
"נוכנס": true,
"נוכנע": true,
"נוכסה": true,
"נוכסו": true,
"נוכסף": true,
"נוכסת": true,
"נוכעס": true,
"נוכפל": true,
"נוכפף": true,
"נוכפש": true,
"נוכרז": true,
"נוכרח": true,
"נוכרי": true,
"נוכרע": true,
"נוכרת": true,
"נוכשה": true,
"נוכשו": true,
"נוכשל": true,
"נוכשר": true,
"נוכשת": true,
"נוכתב": true,
"נוכתה": true,
"נוכתם": true,
"נוכתר": true,
"נולאם": true,
"נולבן": true,
"נולבש": true,
"נולדה": true,
"נולדו": true,
"נולדת": true,
"נולהב": true,
"נולהט": true,
"נולחם": true,
"נולחן": true,
"נולחץ": true,
"נוליד": true,
"נוליה": true,
"נוליו": true,
"נוליי": true,
"נוליך": true,
"נולים": true,
"נולכם": true,
"נולכן": true,
"נולנו": true,
"נולעט": true,
"נולקה": true,
"נומחז": true,
"נומחש": true,
"נומטר": true,
"נומכה": true,
"נומכו": true,
"נומכי": true,
"נומכך": true,
"נומכם": true,
"נומכן": true,
"נומלח": true,
"נומלט": true,
"נומלך": true,
"נומלץ": true,
"נומנה": true,
"נומעד": true,
"נומעט": true,
"נומצא": true,
"נומקה": true,
"נומקו": true,
"נומקת": true,
"נומרד": true,
"נומרי": true,
"נומרץ": true,
"נומשל": true,
"נומתק": true,
"נונבט": true,
"נונהג": true,
"נונחה": true,
"נונחל": true,
"נונחת": true,
"נונים": true,
"נונית": true,
"נונמך": true,
"נונעם": true,
"נונפק": true,
"נונפש": true,
"נונצח": true,
"נונשם": true,
"נוסבר": true,
"נוסגר": true,
"נוסדה": true,
"נוסדו": true,
"נוסדר": true,
"נוסדת": true,
"נוסחה": true,
"נוסחו": true,
"נוסחי": true,
"נוסחך": true,
"נוסחם": true,
"נוסחן": true,
"נוסחת": true,
"נוסטל": true,
"נוסים": true,
"נוסיף": true,
"נוסית": true,
"נוסכם": true,
"נוסכת": true,
"נוסלם": true,
"נוסלק": true,
"נוסמך": true,
"נוסנה": true,
"נוסעה": true,
"נוסעו": true,
"נוסעי": true,
"נוסעך": true,
"נוסעם": true,
"נוסען": true,
"נוסער": true,
"נוסעת": true,
"נוספג": true,
"נוספד": true,
"נוספה": true,
"נוספו": true,
"נוספת": true,
"נוסקת": true,
"נוסרה": true,
"נוסרו": true,
"נוסרט": true,
"נוסרת": true,
"נוסתה": true,
"נוסתר": true,
"נועבד": true,
"נועבר": true,
"נועדה": true,
"נועדו": true,
"נועדף": true,
"נועדת": true,
"נועות": true,
"נועזב": true,
"נועזת": true,
"נועיד": true,
"נועיל": true,
"נועים": true,
"נועית": true,
"נועלה": true,
"נועלם": true,
"נועלת": true,
"נועמד": true,
"נועמס": true,
"נועמק": true,
"נוענה": true,
"נוענע": true,
"נוענק": true,
"נוענש": true,
"נועסק": true,
"נועצב": true,
"נועצה": true,
"נועצו": true,
"נועצם": true,
"נועצת": true,
"נוערה": true,
"נוערו": true,
"נוערי": true,
"נוערך": true,
"נוערם": true,
"נוערן": true,
"נוערת": true,
"נועשר": true,
"נועתק": true,
"נועתר": true,
"נופגז": true,
"נופגן": true,
"נופגש": true,
"נופחד": true,
"נופחה": true,
"נופחו": true,
"נופחת": true,
"נופטר": true,
"נופיה": true,
"נופיו": true,
"נופיי": true,
"נופיך": true,
"נופים": true,
"נופיע": true,
"נופית": true,
"נופכם": true,
"נופכן": true,
"נופלג": true,
"נופלה": true,
"נופלט": true,
"נופלל": true,
"נופלת": true,
"נופנה": true,
"נופנו": true,
"נופנם": true,
"נופנף": true,
"נופסק": true,
"נופעל": true,
"נופעם": true,
"נופפה": true,
"נופפו": true,
"נופפי": true,
"נופפת": true,
"נופצה": true,
"נופצו": true,
"נופצץ": true,
"נופצת": true,
"נופקד": true,
"נופקה": true,
"נופקו": true,
"נופקע": true,
"נופקר": true,
"נופקת": true,
"נופרד": true,
"נופרה": true,
"נופרו": true,
"נופרז": true,
"נופרח": true,
"נופרט": true,
"נופרי": true,
"נופרך": true,
"נופרם": true,
"נופרן": true,
"נופרע": true,
"נופרש": true,
"נופשה": true,
"נופשו": true,
"נופשט": true,
"נופשי": true,
"נופשך": true,
"נופשל": true,
"נופשם": true,
"נופשן": true,
"נופשר": true,
"נופשת": true,
"נופתה": true,
"נופתע": true,
"נוצבע": true,
"נוצדק": true,
"נוצהב": true,
"נוצהר": true,
"נוצות": true,
"נוצחה": true,
"נוצחו": true,
"נוצחת": true,
"נוציא": true,
"נוצלב": true,
"נוצלה": true,
"נוצלו": true,
"נוצלת": true,
"נוצמד": true,
"נוצמת": true,
"נוצנח": true,
"נוצנע": true,
"נוצנץ": true,
"נוצעד": true,
"נוצפן": true,
"נוצצת": true,
"נוצקה": true,
"נוצקו": true,
"נוצקת": true,
"נוצרה": true,
"נוצרו": true,
"נוצרי": true,
"נוצרך": true,
"נוצרת": true,
"נוצתה": true,
"נוצתו": true,
"נוצתי": true,
"נוצתך": true,
"נוצתם": true,
"נוצתן": true,
"נוקבה": true,
"נוקבו": true,
"נוקבת": true,
"נוקדה": true,
"נוקדו": true,
"נוקדח": true,
"נוקדי": true,
"נוקדך": true,
"נוקדם": true,
"נוקדן": true,
"נוקדש": true,
"נוקדת": true,
"נוקהה": true,
"נוקהל": true,
"נוקות": true,
"נוקזה": true,
"נוקזו": true,
"נוקזת": true,
"נוקטן": true,
"נוקטר": true,
"נוקטת": true,
"נוקיה": true,
"נוקיע": true,
"נוקיר": true,
"נוקית": true,
"נוקלד": true,
"נוקלט": true,
"נוקמת": true,
"נוקנה": true,
"נוקנט": true,
"נוקסם": true,
"נוקעת": true,
"נוקפא": true,
"נוקפד": true,
"נוקפץ": true,
"נוקפת": true,
"נוקצב": true,
"נוקצה": true,
"נוקצן": true,
"נוקצע": true,
"נוקצף": true,
"נוקרא": true,
"נוקרב": true,
"נוקרה": true,
"נוקרו": true,
"נוקרם": true,
"נוקרן": true,
"נוקרש": true,
"נוקרת": true,
"נוקשה": true,
"נוקשח": true,
"נוקשת": true,
"נוקתה": true,
"נוראה": true,
"נוראי": true,
"נורבע": true,
"נורגל": true,
"נורגש": true,
"נורדי": true,
"נורדם": true,
"נורות": true,
"נורחב": true,
"נורחק": true,
"נורטב": true,
"נוריד": true,
"נוריה": true,
"נוריו": true,
"נוריי": true,
"נוריך": true,
"נורים": true,
"נוריק": true,
"נוריש": true,
"נורית": true,
"נורכב": true,
"נורכם": true,
"נורכן": true,
"נורמה": true,
"נורמט": true,
"נורמל": true,
"נורמן": true,
"נורנו": true,
"נורעב": true,
"נורעד": true,
"נורעל": true,
"נורעף": true,
"נורעש": true,
"נורקד": true,
"נורשה": true,
"נורשו": true,
"נורשע": true,
"נורשת": true,
"נורתה": true,
"נורתו": true,
"נורתח": true,
"נורתי": true,
"נורתך": true,
"נורתם": true,
"נורתן": true,
"נורתע": true,
"נושאה": true,
"נושאו": true,
"נושאי": true,
"נושאך": true,
"נושאל": true,
"נושאם": true,
"נושאן": true,
"נושאף": true,
"נושאר": true,
"נושאת": true,
"נושבח": true,
"נושבע": true,
"נושבת": true,
"נושגח": true,
"נושהה": true,
"נושהו": true,
"נושות": true,
"נושחז": true,
"נושחל": true,
"נושחם": true,
"נושחר": true,
"נושחת": true,
"נושיב": true,
"נושיה": true,
"נושיו": true,
"נושיט": true,
"נושיי": true,
"נושיך": true,
"נושים": true,
"נושיע": true,
"נושכב": true,
"נושכח": true,
"נושכם": true,
"נושכן": true,
"נושכר": true,
"נושכת": true,
"נושלה": true,
"נושלו": true,
"נושלט": true,
"נושלך": true,
"נושלם": true,
"נושלת": true,
"נושמד": true,
"נושמט": true,
"נושמע": true,
"נושמץ": true,
"נושמש": true,
"נושמת": true,
"נושנה": true,
"נושנו": true,
"נושנת": true,
"נושעה": true,
"נושעו": true,
"נושען": true,
"נושעת": true,
"נושפל": true,
"נושפע": true,
"נושפת": true,
"נושקה": true,
"נושקו": true,
"נושקט": true,
"נושקע": true,
"נושקת": true,
"נושרה": true,
"נושרץ": true,
"נושרש": true,
"נושרת": true,
"נושתה": true,
"נושתו": true,
"נושתי": true,
"נושתך": true,
"נושתל": true,
"נושתם": true,
"נושתן": true,
"נושתק": true,
"נושתת": true,
"נותאם": true,
"נותבה": true,
"נותבו": true,
"נותבת": true,
"נותחה": true,
"נותחו": true,
"נותחל": true,
"נותחת": true,
"נותיר": true,
"נותמר": true,
"נותנה": true,
"נותני": true,
"נותנע": true,
"נותנת": true,
"נותסס": true,
"נותפח": true,
"נותפל": true,
"נותצה": true,
"נותצו": true,
"נותצת": true,
"נותקה": true,
"נותקו": true,
"נותקל": true,
"נותקן": true,
"נותקף": true,
"נותקת": true,
"נותרה": true,
"נותרו": true,
"נותרת": true,
"נזבחה": true,
"נזבחו": true,
"נזבחת": true,
"נזגזג": true,
"נזדהה": true,
"נזדהם": true,
"נזדחל": true,
"נזדכה": true,
"נזדכך": true,
"נזדמן": true,
"נזדמר": true,
"נזדנב": true,
"נזדעף": true,
"נזדעק": true,
"נזדקן": true,
"נזדקף": true,
"נזדקק": true,
"נזדקר": true,
"נזדרז": true,
"נזהיב": true,
"נזהיר": true,
"נזהרה": true,
"נזהרו": true,
"נזהרת": true,
"נזובל": true,
"נזוגג": true,
"נזוהה": true,
"נזוהם": true,
"נזווג": true,
"נזווד": true,
"נזוין": true,
"נזויף": true,
"נזוכה": true,
"נזוכך": true,
"נזומן": true,
"נזומר": true,
"נזופה": true,
"נזופת": true,
"נזוקק": true,
"נזורה": true,
"נזורז": true,
"נזידה": true,
"נזידו": true,
"נזידי": true,
"נזידך": true,
"נזידם": true,
"נזידן": true,
"נזיום": true,
"נזיין": true,
"נזייף": true,
"נזילה": true,
"נזיפה": true,
"נזיקי": true,
"נזירה": true,
"נזירו": true,
"נזירי": true,
"נזירך": true,
"נזירם": true,
"נזירן": true,
"נזכור": true,
"נזכיר": true,
"נזכרה": true,
"נזכרו": true,
"נזכרת": true,
"נזלוג": true,
"נזלול": true,
"נזלות": true,
"נזלזל": true,
"נזליף": true,
"נזללה": true,
"נזללו": true,
"נזללת": true,
"נזלנו": true,
"נזלתה": true,
"נזלתו": true,
"נזלתי": true,
"נזלתך": true,
"נזלתם": true,
"נזלתן": true,
"נזמום": true,
"נזמור": true,
"נזמזם": true,
"נזמיה": true,
"נזמיו": true,
"נזמיי": true,
"נזמיך": true,
"נזמים": true,
"נזמין": true,
"נזמכם": true,
"נזמכן": true,
"נזמנו": true,
"נזמרה": true,
"נזמרו": true,
"נזמרת": true,
"נזנחה": true,
"נזנחו": true,
"נזנחת": true,
"נזניה": true,
"נזניח": true,
"נזניק": true,
"נזעזע": true,
"נזעים": true,
"נזעיף": true,
"נזעיק": true,
"נזעמת": true,
"נזעפת": true,
"נזעקה": true,
"נזעקו": true,
"נזעקת": true,
"נזפנו": true,
"נזפתי": true,
"נזפתם": true,
"נזפתן": true,
"נזקוף": true,
"נזקור": true,
"נזקיה": true,
"נזקיו": true,
"נזקיי": true,
"נזקיך": true,
"נזקים": true,
"נזקין": true,
"נזקיף": true,
"נזקכם": true,
"נזקכן": true,
"נזקנו": true,
"נזקפה": true,
"נזקפו": true,
"נזקפת": true,
"נזקקה": true,
"נזקקו": true,
"נזקקת": true,
"נזרום": true,
"נזרוק": true,
"נזריה": true,
"נזריו": true,
"נזריי": true,
"נזריך": true,
"נזרים": true,
"נזריע": true,
"נזריק": true,
"נזרכם": true,
"נזרכן": true,
"נזרנו": true,
"נזרעה": true,
"נזרעו": true,
"נזרעת": true,
"נזרקה": true,
"נזרקו": true,
"נזרקת": true,
"נחבאה": true,
"נחבאו": true,
"נחבאת": true,
"נחבוט": true,
"נחבול": true,
"נחבוץ": true,
"נחבוק": true,
"נחבור": true,
"נחבוש": true,
"נחבטה": true,
"נחבטו": true,
"נחבטת": true,
"נחביא": true,
"נחבלה": true,
"נחבלו": true,
"נחבלת": true,
"נחבשה": true,
"נחבשו": true,
"נחבשת": true,
"נחגגה": true,
"נחגגו": true,
"נחגגת": true,
"נחגוג": true,
"נחגור": true,
"נחגרה": true,
"נחגרו": true,
"נחגרת": true,
"נחדור": true,
"נחדיר": true,
"נחובל": true,
"נחובק": true,
"נחובר": true,
"נחוגה": true,
"נחוגו": true,
"נחודד": true,
"נחודש": true,
"נחווה": true,
"נחווט": true,
"נחוות": true,
"נחוזק": true,
"נחוזר": true,
"נחוטא": true,
"נחוטב": true,
"נחויב": true,
"נחויג": true,
"נחויט": true,
"נחויל": true,
"נחולט": true,
"נחולל": true,
"נחולץ": true,
"נחולק": true,
"נחומם": true,
"נחומש": true,
"נחונך": true,
"נחונן": true,
"נחוסל": true,
"נחוסם": true,
"נחוסן": true,
"נחוסר": true,
"נחופף": true,
"נחופש": true,
"נחוצה": true,
"נחוקק": true,
"נחורר": true,
"נחושב": true,
"נחושה": true,
"נחושל": true,
"נחושק": true,
"נחושת": true,
"נחותה": true,
"נחותל": true,
"נחותן": true,
"נחזור": true,
"נחזות": true,
"נחזים": true,
"נחזיק": true,
"נחזיר": true,
"נחזית": true,
"נחזתה": true,
"נחטוב": true,
"נחטוף": true,
"נחטיא": true,
"נחטיף": true,
"נחטפה": true,
"נחטפו": true,
"נחטפת": true,
"נחיות": true,
"נחייב": true,
"נחייג": true,
"נחייה": true,
"נחייט": true,
"נחייך": true,
"נחייל": true,
"נחילה": true,
"נחילו": true,
"נחילי": true,
"נחילך": true,
"נחילם": true,
"נחילן": true,
"נחירה": true,
"נחירו": true,
"נחירי": true,
"נחירך": true,
"נחירם": true,
"נחירן": true,
"נחיתה": true,
"נחכוך": true,
"נחכור": true,
"נחכים": true,
"נחכיר": true,
"נחכרה": true,
"נחכרו": true,
"נחכרת": true,
"נחלבה": true,
"נחלבו": true,
"נחלבת": true,
"נחלוב": true,
"נחלוט": true,
"נחלום": true,
"נחלוף": true,
"נחלוץ": true,
"נחלוק": true,
"נחלוש": true,
"נחלות": true,
"נחלחל": true,
"נחליא": true,
"נחליד": true,
"נחליה": true,
"נחליו": true,
"נחליט": true,
"נחליי": true,
"נחליך": true,
"נחלים": true,
"נחליף": true,
"נחליק": true,
"נחליש": true,
"נחלכם": true,
"נחלכן": true,
"נחלמה": true,
"נחלמו": true,
"נחלמת": true,
"נחלנה": true,
"נחלנו": true,
"נחלצה": true,
"נחלצו": true,
"נחלצת": true,
"נחלקה": true,
"נחלקו": true,
"נחלקת": true,
"נחלשה": true,
"נחלשו": true,
"נחלשת": true,
"נחלתה": true,
"נחלתו": true,
"נחלתי": true,
"נחלתך": true,
"נחלתם": true,
"נחלתן": true,
"נחמדה": true,
"נחמדו": true,
"נחמדת": true,
"נחמוד": true,
"נחמול": true,
"נחמוס": true,
"נחמוק": true,
"נחמות": true,
"נחמיא": true,
"נחמיה": true,
"נחמיץ": true,
"נחמיר": true,
"נחמנה": true,
"נחמני": true,
"נחמצה": true,
"נחמצו": true,
"נחמצן": true,
"נחמצת": true,
"נחמתה": true,
"נחמתו": true,
"נחמתי": true,
"נחמתך": true,
"נחמתם": true,
"נחמתן": true,
"נחנוט": true,
"נחנוך": true,
"נחנון": true,
"נחנוק": true,
"נחנטה": true,
"נחנטו": true,
"נחנטת": true,
"נחנים": true,
"נחניף": true,
"נחניק": true,
"נחנכה": true,
"נחנכו": true,
"נחנכת": true,
"נחנקה": true,
"נחנקו": true,
"נחנקת": true,
"נחסוך": true,
"נחסום": true,
"נחסיר": true,
"נחסכה": true,
"נחסכו": true,
"נחסכת": true,
"נחסמה": true,
"נחסמו": true,
"נחסמת": true,
"נחספס": true,
"נחפון": true,
"נחפוף": true,
"נחפור": true,
"נחפזה": true,
"נחפזו": true,
"נחפזת": true,
"נחפפה": true,
"נחפפו": true,
"נחפפת": true,
"נחפרה": true,
"נחפרו": true,
"נחפרת": true,
"נחצבה": true,
"נחצבו": true,
"נחצבת": true,
"נחצוב": true,
"נחצוץ": true,
"נחצות": true,
"נחצים": true,
"נחצין": true,
"נחציף": true,
"נחצית": true,
"נחצצה": true,
"נחצצו": true,
"נחצצר": true,
"נחצצת": true,
"נחצתה": true,
"נחקוק": true,
"נחקור": true,
"נחקקה": true,
"נחקקו": true,
"נחקקת": true,
"נחקרה": true,
"נחקרו": true,
"נחקרת": true,
"נחרבה": true,
"נחרבו": true,
"נחרבן": true,
"נחרבת": true,
"נחרדה": true,
"נחרדו": true,
"נחרדת": true,
"נחרוב": true,
"נחרוג": true,
"נחרוז": true,
"נחרוט": true,
"נחרוך": true,
"נחרוף": true,
"נחרוץ": true,
"נחרוק": true,
"נחרוש": true,
"נחרות": true,
"נחרחר": true,
"נחרטה": true,
"נחרטו": true,
"נחרטת": true,
"נחריב": true,
"נחריג": true,
"נחריד": true,
"נחרים": true,
"נחריף": true,
"נחריש": true,
"נחרכה": true,
"נחרכו": true,
"נחרכת": true,
"נחרמן": true,
"נחרנה": true,
"נחרנו": true,
"נחרצה": true,
"נחרצו": true,
"נחרצת": true,
"נחרשה": true,
"נחרשו": true,
"נחרשת": true,
"נחרתה": true,
"נחרתו": true,
"נחרתי": true,
"נחרתך": true,
"נחרתם": true,
"נחרתן": true,
"נחרתת": true,
"נחשבה": true,
"נחשבו": true,
"נחשבת": true,
"נחשדה": true,
"נחשדו": true,
"נחשדת": true,
"נחשוב": true,
"נחשוד": true,
"נחשול": true,
"נחשון": true,
"נחשוף": true,
"נחשוק": true,
"נחשוש": true,
"נחשיב": true,
"נחשיד": true,
"נחשיה": true,
"נחשיו": true,
"נחשיי": true,
"נחשיך": true,
"נחשים": true,
"נחשית": true,
"נחשכם": true,
"נחשכן": true,
"נחשלת": true,
"נחשמל": true,
"נחשנה": true,
"נחשנו": true,
"נחשפה": true,
"נחשפו": true,
"נחשפת": true,
"נחשקה": true,
"נחשקו": true,
"נחשקת": true,
"נחתוך": true,
"נחתום": true,
"נחתור": true,
"נחתות": true,
"נחתיה": true,
"נחתיו": true,
"נחתיי": true,
"נחתיך": true,
"נחתים": true,
"נחתכה": true,
"נחתכו": true,
"נחתכם": true,
"נחתכן": true,
"נחתכת": true,
"נחתמה": true,
"נחתמו": true,
"נחתמת": true,
"נחתנה": true,
"נחתנו": true,
"נחתתה": true,
"נחתתו": true,
"נחתתי": true,
"נחתתך": true,
"נחתתם": true,
"נחתתן": true,
"נטאטא": true,
"נטבול": true,
"נטבחה": true,
"נטבחו": true,
"נטבחת": true,
"נטביל": true,
"נטביע": true,
"נטבלה": true,
"נטבלו": true,
"נטבלת": true,
"נטבעה": true,
"נטבעו": true,
"נטבעת": true,
"נטובע": true,
"נטוגן": true,
"נטוהר": true,
"נטווה": true,
"נטווח": true,
"נטוות": true,
"נטויב": true,
"נטויה": true,
"נטויח": true,
"נטויל": true,
"נטולה": true,
"נטולי": true,
"נטולת": true,
"נטומא": true,
"נטונף": true,
"נטועה": true,
"נטופה": true,
"נטופח": true,
"נטופל": true,
"נטופס": true,
"נטופף": true,
"נטורי": true,
"נטושה": true,
"נטזיה": true,
"נטחנה": true,
"נטחנו": true,
"נטחנת": true,
"נטיות": true,
"נטייב": true,
"נטייה": true,
"נטייח": true,
"נטייל": true,
"נטיים": true,
"נטילה": true,
"נטילת": true,
"נטינה": true,
"נטינו": true,
"נטיעה": true,
"נטיפה": true,
"נטיפו": true,
"נטיפי": true,
"נטיפך": true,
"נטיפם": true,
"נטיפן": true,
"נטיקה": true,
"נטישה": true,
"נטיתי": true,
"נטיתם": true,
"נטיתן": true,
"נטלטל": true,
"נטליא": true,
"נטליה": true,
"נטליו": true,
"נטליי": true,
"נטליך": true,
"נטלים": true,
"נטלית": true,
"נטלכם": true,
"נטלכן": true,
"נטלנו": true,
"נטלפן": true,
"נטלתי": true,
"נטלתם": true,
"נטלתן": true,
"נטמאה": true,
"נטמאו": true,
"נטמאת": true,
"נטמון": true,
"נטמטם": true,
"נטמין": true,
"נטמיע": true,
"נטמנה": true,
"נטמנו": true,
"נטמנת": true,
"נטמעה": true,
"נטמעו": true,
"נטמעת": true,
"נטעיה": true,
"נטעיו": true,
"נטעיי": true,
"נטעיך": true,
"נטעים": true,
"נטעין": true,
"נטעכם": true,
"נטעכן": true,
"נטעמה": true,
"נטעמו": true,
"נטעמת": true,
"נטענה": true,
"נטענו": true,
"נטענת": true,
"נטעתי": true,
"נטעתם": true,
"נטעתן": true,
"נטפול": true,
"נטפטף": true,
"נטפיה": true,
"נטפיו": true,
"נטפיי": true,
"נטפיך": true,
"נטפים": true,
"נטפכם": true,
"נטפכן": true,
"נטפלה": true,
"נטפלו": true,
"נטפלת": true,
"נטפנו": true,
"נטפתי": true,
"נטפתם": true,
"נטפתן": true,
"נטקסט": true,
"נטרדה": true,
"נטרדו": true,
"נטרדת": true,
"נטרוד": true,
"נטרול": true,
"נטרון": true,
"נטרוף": true,
"נטרוק": true,
"נטרטר": true,
"נטריד": true,
"נטריח": true,
"נטריף": true,
"נטרית": true,
"נטרלה": true,
"נטרלו": true,
"נטרלי": true,
"נטרלת": true,
"נטרנה": true,
"נטרנו": true,
"נטרפד": true,
"נטרפה": true,
"נטרפו": true,
"נטרפת": true,
"נטרקה": true,
"נטרקו": true,
"נטרקת": true,
"נטרתי": true,
"נטרתם": true,
"נטרתן": true,
"נטשטש": true,
"נטשנו": true,
"נטשתי": true,
"נטשתם": true,
"נטשתן": true,
"ניאבק": true,
"ניאגר": true,
"ניאהב": true,
"ניאהד": true,
"ניאוף": true,
"ניאוץ": true,
"ניאור": true,
"ניאות": true,
"ניאזק": true,
"ניאזר": true,
"ניאחז": true,
"ניאטם": true,
"ניאכל": true,
"ניאכף": true,
"ניאלם": true,
"ניאלץ": true,
"ניאמד": true,
"ניאמן": true,
"ניאמר": true,
"ניאנח": true,
"ניאנס": true,
"ניאנק": true,
"ניאסף": true,
"ניאסר": true,
"ניאפה": true,
"ניאפו": true,
"ניאפת": true,
"ניאצה": true,
"ניאצו": true,
"ניאצר": true,
"ניאצת": true,
"ניארג": true,
"ניארז": true,
"ניאשם": true,
"ניבאה": true,
"ניבאו": true,
"ניבאש": true,
"ניבאת": true,
"ניבגד": true,
"ניבדל": true,
"ניבדק": true,
"ניבהל": true,
"ניבוי": true,
"ניבוך": true,
"ניבול": true,
"ניבזז": true,
"ניבזק": true,
"ניבחן": true,
"ניבחר": true,
"ניבחש": true,
"ניבטה": true,
"ניבטו": true,
"ניבטת": true,
"ניביה": true,
"ניביו": true,
"ניביי": true,
"ניביך": true,
"ניבים": true,
"ניבכם": true,
"ניבכן": true,
"ניבלה": true,
"ניבלו": true,
"ניבלל": true,
"ניבלם": true,
"ניבלע": true,
"ניבלת": true,
"ניבנה": true,
"ניבנו": true,
"ניבעה": true,
"ניבעט": true,
"ניבעל": true,
"ניבעת": true,
"ניבצע": true,
"ניבצר": true,
"ניבקע": true,
"ניברא": true,
"ניגאל": true,
"ניגבה": true,
"ניגבו": true,
"ניגבת": true,
"ניגדה": true,
"ניגדו": true,
"ניגדע": true,
"ניגדת": true,
"ניגוב": true,
"ניגוד": true,
"ניגוז": true,
"ניגוח": true,
"ניגול": true,
"ניגון": true,
"ניגזז": true,
"ניגזל": true,
"ניגזם": true,
"ניגזר": true,
"ניגחה": true,
"ניגחו": true,
"ניגחת": true,
"ניגלה": true,
"ניגלל": true,
"ניגמל": true,
"ניגמע": true,
"ניגמר": true,
"ניגנב": true,
"ניגנה": true,
"ניגנו": true,
"ניגנז": true,
"ניגנת": true,
"ניגעו": true,
"ניגעל": true,
"ניגפה": true,
"ניגפו": true,
"ניגפת": true,
"ניגרב": true,
"ניגרה": true,
"ניגרו": true,
"ניגרי": true,
"ניגרם": true,
"ניגרס": true,
"ניגרע": true,
"ניגרף": true,
"ניגרר": true,
"ניגרת": true,
"ניגשה": true,
"ניגשו": true,
"ניגשת": true,
"נידבה": true,
"נידבו": true,
"נידבק": true,
"נידבר": true,
"נידבת": true,
"נידגם": true,
"נידהם": true,
"נידוב": true,
"נידוי": true,
"נידום": true,
"נידון": true,
"נידוף": true,
"נידור": true,
"נידות": true,
"נידחה": true,
"נידחו": true,
"נידחס": true,
"נידחף": true,
"נידחק": true,
"נידחת": true,
"נידית": true,
"נידלה": true,
"נידלל": true,
"נידלק": true,
"נידמה": true,
"נידפס": true,
"נידפק": true,
"נידפת": true,
"נידקר": true,
"נידרך": true,
"נידרס": true,
"נידרש": true,
"נידתה": true,
"נידתו": true,
"נידתי": true,
"נידתך": true,
"נידתם": true,
"נידתן": true,
"ניהגה": true,
"ניהגו": true,
"ניהגת": true,
"ניהדף": true,
"ניהוג": true,
"ניהול": true,
"ניהלה": true,
"ניהלו": true,
"ניהלת": true,
"ניהנה": true,
"ניהפך": true,
"ניהרג": true,
"ניהרס": true,
"ניובא": true,
"ניובש": true,
"ניודה": true,
"ניודו": true,
"ניודי": true,
"ניודך": true,
"ניודם": true,
"ניודן": true,
"ניודע": true,
"ניוהד": true,
"ניווט": true,
"ניוון": true,
"ניוזע": true,
"ניוחד": true,
"ניוחל": true,
"ניוחם": true,
"ניוחס": true,
"ניולד": true,
"ניונן": true,
"ניוסד": true,
"ניוסף": true,
"ניוסר": true,
"ניועד": true,
"ניועל": true,
"ניוער": true,
"ניופה": true,
"ניוצא": true,
"ניוצב": true,
"ניוצג": true,
"ניוצר": true,
"ניוקי": true,
"ניורט": true,
"ניושב": true,
"ניושם": true,
"ניושן": true,
"ניושר": true,
"ניותר": true,
"ניזאם": true,
"ניזאר": true,
"ניזבח": true,
"ניזהר": true,
"ניזום": true,
"ניזון": true,
"ניזוק": true,
"ניזכר": true,
"ניזלל": true,
"ניזמר": true,
"ניזנח": true,
"ניזעק": true,
"ניזקף": true,
"ניזקק": true,
"ניזרע": true,
"ניזרק": true,
"ניחבא": true,
"ניחבט": true,
"ניחבל": true,
"ניחבש": true,
"ניחגג": true,
"ניחגר": true,
"ניחוג": true,
"ניחוח": true,
"ניחום": true,
"ניחוש": true,
"ניחות": true,
"ניחזה": true,
"ניחטף": true,
"ניחכר": true,
"ניחלב": true,
"ניחלם": true,
"ניחלץ": true,
"ניחלק": true,
"ניחלש": true,
"ניחמד": true,
"ניחמה": true,
"ניחמו": true,
"ניחמץ": true,
"ניחמת": true,
"ניחנה": true,
"ניחנט": true,
"ניחנך": true,
"ניחנק": true,
"ניחסך": true,
"ניחסם": true,
"ניחפז": true,
"ניחפף": true,
"ניחפר": true,
"ניחצב": true,
"ניחצה": true,
"ניחצץ": true,
"ניחקק": true,
"ניחקר": true,
"ניחרב": true,
"ניחרד": true,
"ניחרה": true,
"ניחרט": true,
"ניחרך": true,
"ניחרץ": true,
"ניחרש": true,
"ניחרת": true,
"ניחשב": true,
"ניחשד": true,
"ניחשה": true,
"ניחשו": true,
"ניחשף": true,
"ניחשק": true,
"ניחשת": true,
"ניחתה": true,
"ניחתו": true,
"ניחתי": true,
"ניחתך": true,
"ניחתם": true,
"ניחתן": true,
"ניחתת": true,
"ניטבח": true,
"ניטבל": true,
"ניטבע": true,
"ניטהר": true,
"ניטול": true,
"ניטור": true,
"ניטוש": true,
"ניטחן": true,
"ניטיב": true,
"ניטין": true,
"ניטלה": true,
"ניטלו": true,
"ניטלת": true,
"ניטמא": true,
"ניטמן": true,
"ניטמע": true,
"ניטעה": true,
"ניטעו": true,
"ניטעם": true,
"ניטען": true,
"ניטעת": true,
"ניטפל": true,
"ניטרד": true,
"ניטרה": true,
"ניטרו": true,
"ניטרף": true,
"ניטרק": true,
"ניטרת": true,
"ניטשה": true,
"ניטשו": true,
"ניטשת": true,
"נייאש": true,
"נייבא": true,
"נייבב": true,
"נייבם": true,
"נייבש": true,
"נייגע": true,
"ניידה": true,
"ניידו": true,
"ניידי": true,
"ניידע": true,
"ניידת": true,
"נייהד": true,
"נייזע": true,
"נייחד": true,
"נייחל": true,
"נייחם": true,
"נייחס": true,
"נייחת": true,
"נייטר": true,
"ניילד": true,
"ניילל": true,
"ניינו": true,
"ניינן": true,
"ניינת": true,
"נייסד": true,
"נייסף": true,
"נייסר": true,
"נייעד": true,
"נייעל": true,
"נייעץ": true,
"נייער": true,
"נייפה": true,
"נייצא": true,
"נייצב": true,
"נייצג": true,
"נייצר": true,
"נייקר": true,
"ניירה": true,
"ניירו": true,
"ניירט": true,
"ניירי": true,
"ניירך": true,
"ניירם": true,
"ניירן": true,
"ניירת": true,
"ניישב": true,
"ניישם": true,
"ניישן": true,
"ניישר": true,
"נייתר": true,
"ניכבה": true,
"ניכבל": true,
"ניכבש": true,
"ניכוי": true,
"ניכון": true,
"ניכוס": true,
"ניכור": true,
"ניכוש": true,
"ניכזב": true,
"ניכחד": true,
"ניכית": true,
"ניכלא": true,
"ניכלל": true,
"ניכלם": true,
"ניכמר": true,
"ניכנס": true,
"ניכנע": true,
"ניכסה": true,
"ניכסו": true,
"ניכסס": true,
"ניכסף": true,
"ניכסת": true,
"ניכפה": true,
"ניכפל": true,
"ניכפת": true,
"ניכרה": true,
"ניכרו": true,
"ניכרך": true,
"ניכרת": true,
"ניכשה": true,
"ניכשו": true,
"ניכשל": true,
"ניכשת": true,
"ניכתב": true,
"ניכתה": true,
"ניכתש": true,
"נילאה": true,
"נילבל": true,
"נילבש": true,
"נילגם": true,
"נילוו": true,
"נילון": true,
"נילוס": true,
"נילחם": true,
"נילחץ": true,
"נילחש": true,
"נילכד": true,
"נילמד": true,
"נילעס": true,
"נילפת": true,
"נילקח": true,
"נימאס": true,
"נימבו": true,
"נימדד": true,
"נימהל": true,
"נימוג": true,
"נימוח": true,
"נימול": true,
"נימוס": true,
"נימוק": true,
"נימות": true,
"נימזג": true,
"נימחא": true,
"נימחה": true,
"נימחל": true,
"נימחץ": true,
"נימחק": true,
"נימיה": true,
"נימיו": true,
"נימיי": true,
"נימיך": true,
"נימים": true,
"נימית": true,
"נימכם": true,
"נימכן": true,
"נימכר": true,
"נימלא": true,
"נימלט": true,
"נימלך": true,
"נימלק": true,
"נימנה": true,
"נימנו": true,
"נימנע": true,
"נימסך": true,
"נימסק": true,
"נימסר": true,
"נימעך": true,
"נימען": true,
"נימפה": true,
"נימצא": true,
"נימצץ": true,
"נימקה": true,
"נימקו": true,
"נימקת": true,
"נימרח": true,
"נימרט": true,
"נימשה": true,
"נימשח": true,
"נימשך": true,
"נימשל": true,
"נימתה": true,
"נימתו": true,
"נימתח": true,
"נימתי": true,
"נימתך": true,
"נימתם": true,
"נימתן": true,
"נינאם": true,
"נינבט": true,
"נינגח": true,
"נינגס": true,
"נינגע": true,
"נינגף": true,
"נינגר": true,
"נינוה": true,
"נינוח": true,
"נינות": true,
"נינזף": true,
"נינזק": true,
"נינזר": true,
"נינחם": true,
"נינחת": true,
"נינטל": true,
"נינטע": true,
"נינטש": true,
"ניניה": true,
"ניניו": true,
"ניניי": true,
"ניניך": true,
"נינים": true,
"ניניק": true,
"נינכם": true,
"נינכן": true,
"ניננו": true,
"נינעל": true,
"נינעץ": true,
"נינצל": true,
"נינצר": true,
"נינקט": true,
"נינקם": true,
"נינקע": true,
"נינשא": true,
"נינשך": true,
"נינשף": true,
"נינתה": true,
"נינתו": true,
"נינתז": true,
"נינתי": true,
"נינתך": true,
"נינתם": true,
"נינתן": true,
"נינתק": true,
"ניסבל": true,
"ניסגר": true,
"ניסדק": true,
"ניסוג": true,
"ניסוד": true,
"ניסוח": true,
"ניסוי": true,
"ניסור": true,
"ניסות": true,
"ניסחב": true,
"ניסחה": true,
"ניסחו": true,
"ניסחט": true,
"ניסחף": true,
"ניסחר": true,
"ניסחת": true,
"ניסטי": true,
"ניסים": true,
"ניסית": true,
"ניסכם": true,
"ניסכר": true,
"ניסלח": true,
"ניסלל": true,
"ניסמך": true,
"ניספג": true,
"ניספה": true,
"ניספו": true,
"ניספח": true,
"ניספי": true,
"ניספר": true,
"ניסקל": true,
"ניסקר": true,
"ניסרג": true,
"ניסרה": true,
"ניסרו": true,
"ניסרק": true,
"ניסרת": true,
"ניסתה": true,
"ניסתם": true,
"ניסתר": true,
"ניעבר": true,
"ניעגן": true,
"ניעדר": true,
"ניעור": true,
"ניעות": true,
"ניעזב": true,
"ניעזר": true,
"ניעטף": true,
"ניעכר": true,
"ניעלב": true,
"ניעלם": true,
"ניעמד": true,
"ניענב": true,
"ניענד": true,
"ניענה": true,
"ניענש": true,
"ניעצב": true,
"ניעצם": true,
"ניעצר": true,
"ניעקד": true,
"ניעקף": true,
"ניעקץ": true,
"ניעקר": true,
"ניערה": true,
"ניערו": true,
"ניערך": true,
"ניערם": true,
"ניערף": true,
"ניערץ": true,
"ניערת": true,
"ניעשה": true,
"ניעשק": true,
"ניעתה": true,
"ניעתו": true,
"ניעתי": true,
"ניעתך": true,
"ניעתם": true,
"ניעתן": true,
"ניעתק": true,
"ניעתר": true,
"ניפגם": true,
"ניפגע": true,
"ניפגש": true,
"ניפדה": true,
"ניפוח": true,
"ניפוי": true,
"ניפול": true,
"ניפוף": true,
"ניפוץ": true,
"ניפוק": true,
"ניפחה": true,
"ניפחו": true,
"ניפחת": true,
"ניפטר": true,
"ניפית": true,
"ניפלא": true,
"ניפלט": true,
"ניפנה": true,
"ניפסל": true,
"ניפסק": true,
"ניפעם": true,
"ניפער": true,
"ניפצה": true,
"ניפצו": true,
"ניפצע": true,
"ניפצת": true,
"ניפקד": true,
"ניפקה": true,
"ניפקו": true,
"ניפקח": true,
"ניפקת": true,
"ניפרד": true,
"ניפרט": true,
"ניפרם": true,
"ניפרס": true,
"ניפרע": true,
"ניפרץ": true,
"ניפרק": true,
"ניפרש": true,
"ניפתה": true,
"ניפתח": true,
"ניפתר": true,
"ניצבה": true,
"ניצבו": true,
"ניצבט": true,
"ניצבי": true,
"ניצבך": true,
"ניצבם": true,
"ניצבן": true,
"ניצבע": true,
"ניצבר": true,
"ניצבת": true,
"ניצוד": true,
"ניצוח": true,
"ניצול": true,
"ניצוץ": true,
"ניצוק": true,
"ניצור": true,
"ניצות": true,
"ניצחה": true,
"ניצחו": true,
"ניצחת": true,
"ניציה": true,
"ניציו": true,
"ניציי": true,
"ניציך": true,
"ניצים": true,
"ניצלב": true,
"ניצלה": true,
"ניצלו": true,
"ניצלת": true,
"ניצמד": true,
"ניצמת": true,
"ניצנה": true,
"ניצנו": true,
"ניצני": true,
"ניצנך": true,
"ניצנם": true,
"ניצנן": true,
"ניצפה": true,
"ניצפר": true,
"ניצקי": true,
"ניצרב": true,
"ניצרד": true,
"ניצרה": true,
"ניצרו": true,
"ניצרך": true,
"ניצרר": true,
"ניצרת": true,
"ניצתה": true,
"ניצתו": true,
"ניצתי": true,
"ניצתך": true,
"ניצתם": true,
"ניצתן": true,
"ניקבה": true,
"ניקבו": true,
"ניקבע": true,
"ניקבץ": true,
"ניקבר": true,
"ניקבת": true,
"ניקדה": true,
"ניקדו": true,
"ניקדח": true,
"ניקדת": true,
"ניקהל": true,
"ניקוב": true,
"ניקוד": true,
"ניקוז": true,
"ניקוי": true,
"ניקול": true,
"ניקום": true,
"ניקור": true,
"ניקות": true,
"ניקזה": true,
"ניקזו": true,
"ניקזת": true,
"ניקטל": true,
"ניקטם": true,
"ניקטע": true,
"ניקטף": true,
"ניקים": true,
"ניקית": true,
"ניקלה": true,
"ניקלט": true,
"ניקלע": true,
"ניקמה": true,
"ניקמו": true,
"ניקמת": true,
"ניקנה": true,
"ניקנס": true,
"ניקצב": true,
"ניקצץ": true,
"ניקצר": true,
"ניקרא": true,
"ניקרה": true,
"ניקרו": true,
"ניקרע": true,
"ניקרש": true,
"ניקרת": true,
"ניקשר": true,
"ניקתה": true,
"ניראה": true,
"נירגם": true,
"נירגע": true,
"נירדם": true,
"נירדף": true,
"נירון": true,
"נירחץ": true,
"נירטב": true,
"נירטט": true,
"ניריה": true,
"ניריו": true,
"ניריי": true,
"ניריך": true,
"נירים": true,
"נירית": true,
"נירכם": true,
"נירכן": true,
"נירכס": true,
"נירכש": true,
"נירלט": true,
"נירמז": true,
"נירמס": true,
"נירנו": true,
"נירעש": true,
"נירפא": true,
"נירצה": true,
"נירצח": true,
"נירצץ": true,
"נירקב": true,
"נירקח": true,
"נירקם": true,
"נירשם": true,
"נירתם": true,
"נירתע": true,
"נישאב": true,
"נישאג": true,
"נישאה": true,
"נישאו": true,
"נישאל": true,
"נישאף": true,
"נישאר": true,
"נישאת": true,
"נישבה": true,
"נישבו": true,
"נישבע": true,
"נישבר": true,
"נישבת": true,
"נישגב": true,
"נישדד": true,
"נישוא": true,
"נישוב": true,
"נישוך": true,
"נישול": true,
"נישום": true,
"נישוק": true,
"נישות": true,
"נישזר": true,
"נישחט": true,
"נישחק": true,
"נישטף": true,
"נישיר": true,
"נישכב": true,
"נישכה": true,
"נישכו": true,
"נישכח": true,
"נישכר": true,
"נישכת": true,
"נישלה": true,
"נישלו": true,
"נישלח": true,
"נישלט": true,
"נישלל": true,
"נישלם": true,
"נישלף": true,
"נישלק": true,
"נישלת": true,
"נישמד": true,
"נישמט": true,
"נישמע": true,
"נישמר": true,
"נישנה": true,
"נישנק": true,
"נישען": true,
"נישפט": true,
"נישפך": true,
"נישקה": true,
"נישקו": true,
"נישקל": true,
"נישקף": true,
"נישקת": true,
"נישרט": true,
"נישרך": true,
"נישרף": true,
"נישרק": true,
"נישתה": true,
"נישתו": true,
"נישתי": true,
"נישתך": true,
"נישתל": true,
"נישתם": true,
"נישתן": true,
"ניתבה": true,
"ניתבו": true,
"ניתבע": true,
"ניתבת": true,
"ניתוב": true,
"ניתוח": true,
"ניתום": true,
"ניתוץ": true,
"ניתוק": true,
"ניתור": true,
"ניתזה": true,
"ניתזו": true,
"ניתזת": true,
"ניתחב": true,
"ניתחה": true,
"ניתחו": true,
"ניתחת": true,
"ניתכה": true,
"ניתכו": true,
"ניתכן": true,
"ניתכת": true,
"ניתלה": true,
"ניתלש": true,
"ניתמך": true,
"ניתמם": true,
"ניתמר": true,
"ניתנה": true,
"ניתנו": true,
"ניתנת": true,
"ניתפס": true,
"ניתפר": true,
"ניתפש": true,
"ניתצה": true,
"ניתצו": true,
"ניתצת": true,
"ניתקה": true,
"ניתקו": true,
"ניתקל": true,
"ניתקע": true,
"ניתקף": true,
"ניתקת": true,
"ניתרה": true,
"ניתרו": true,
"ניתרם": true,
"ניתרת": true,
"ניתתי": true,
"ניתתם": true,
"ניתתן": true,
"נכאיב": true,
"נכאיה": true,
"נכאיו": true,
"נכאיי": true,
"נכאיך": true,
"נכאים": true,
"נכבדה": true,
"נכבדת": true,
"נכבול": true,
"נכבוש": true,
"נכבות": true,
"נכביד": true,
"נכבים": true,
"נכביר": true,
"נכבית": true,
"נכבלה": true,
"נכבלו": true,
"נכבלת": true,
"נכבשה": true,
"נכבשו": true,
"נכבשת": true,
"נכבתה": true,
"נכדות": true,
"נכדיה": true,
"נכדיו": true,
"נכדיי": true,
"נכדיך": true,
"נכדים": true,
"נכדכם": true,
"נכדכן": true,
"נכדנו": true,
"נכדרר": true,
"נכדתה": true,
"נכדתו": true,
"נכדתי": true,
"נכדתך": true,
"נכדתם": true,
"נכדתן": true,
"נכובד": true,
"נכובה": true,
"נכובס": true,
"נכווה": true,
"נכוון": true,
"נכווץ": true,
"נכוות": true,
"נכוחה": true,
"נכויל": true,
"נכויס": true,
"נכויר": true,
"נכולה": true,
"נכונה": true,
"נכונו": true,
"נכונן": true,
"נכונס": true,
"נכוסה": true,
"נכוסח": true,
"נכוער": true,
"נכופף": true,
"נכופר": true,
"נכושף": true,
"נכותב": true,
"נכותה": true,
"נכותו": true,
"נכותי": true,
"נכותך": true,
"נכותם": true,
"נכותן": true,
"נכותר": true,
"נכותת": true,
"נכזבה": true,
"נכזבו": true,
"נכזבת": true,
"נכזיב": true,
"נכחדה": true,
"נכחדו": true,
"נכחדת": true,
"נכחיד": true,
"נכחיה": true,
"נכחיו": true,
"נכחיי": true,
"נכחיך": true,
"נכחיל": true,
"נכחים": true,
"נכחיש": true,
"נכחכח": true,
"נכחנה": true,
"נכחנו": true,
"נכחתי": true,
"נכחתם": true,
"נכחתן": true,
"נכיהם": true,
"נכיהן": true,
"נכיון": true,
"נכייך": true,
"נכייל": true,
"נכייס": true,
"נכייף": true,
"נכייר": true,
"נכיכם": true,
"נכיכן": true,
"נכינה": true,
"נכינו": true,
"נכלאה": true,
"נכלאו": true,
"נכלאת": true,
"נכלול": true,
"נכליא": true,
"נכליב": true,
"נכליל": true,
"נכלים": true,
"נכלכל": true,
"נכללה": true,
"נכללו": true,
"נכללת": true,
"נכלמה": true,
"נכלמו": true,
"נכלמת": true,
"נכמוש": true,
"נכמין": true,
"נכמרה": true,
"נכמרו": true,
"נכמרת": true,
"נכנוס": true,
"נכניס": true,
"נכניע": true,
"נכנסה": true,
"נכנסו": true,
"נכנסת": true,
"נכנעה": true,
"נכנעו": true,
"נכנעת": true,
"נכסוס": true,
"נכסיה": true,
"נכסיו": true,
"נכסיי": true,
"נכסיך": true,
"נכסים": true,
"נכסיף": true,
"נכסכם": true,
"נכסכן": true,
"נכסנה": true,
"נכסנו": true,
"נכססה": true,
"נכססו": true,
"נכססת": true,
"נכספה": true,
"נכספו": true,
"נכספת": true,
"נכעיס": true,
"נכפול": true,
"נכפוף": true,
"נכפור": true,
"נכפות": true,
"נכפיל": true,
"נכפים": true,
"נכפיף": true,
"נכפיש": true,
"נכפית": true,
"נכפלה": true,
"נכפלו": true,
"נכפלת": true,
"נכפתה": true,
"נכפתו": true,
"נכפתי": true,
"נכפתם": true,
"נכפתן": true,
"נכפתר": true,
"נכפתת": true,
"נכרבל": true,
"נכרוך": true,
"נכרות": true,
"נכרטס": true,
"נכריה": true,
"נכריז": true,
"נכריח": true,
"נכרים": true,
"נכריע": true,
"נכרית": true,
"נכרכה": true,
"נכרכו": true,
"נכרכר": true,
"נכרכת": true,
"נכרנה": true,
"נכרסם": true,
"נכרתה": true,
"נכרתו": true,
"נכרתי": true,
"נכרתם": true,
"נכרתן": true,
"נכרתת": true,
"נכשול": true,
"נכשיל": true,
"נכשיר": true,
"נכשכש": true,
"נכשלה": true,
"נכשלו": true,
"נכשלת": true,
"נכשנה": true,
"נכתבה": true,
"נכתבו": true,
"נכתבת": true,
"נכתוב": true,
"נכתוש": true,
"נכתיב": true,
"נכתים": true,
"נכתיף": true,
"נכתיר": true,
"נכתכם": true,
"נכתכן": true,
"נכתנו": true,
"נכתשה": true,
"נכתשו": true,
"נכתשת": true,
"נלאות": true,
"נלאים": true,
"נלאית": true,
"נלאתה": true,
"נלבבת": true,
"נלבין": true,
"נלביש": true,
"נלבלב": true,
"נלבלה": true,
"נלבלו": true,
"נלבלת": true,
"נלבשה": true,
"נלבשו": true,
"נלבשת": true,
"נלגום": true,
"נלגלג": true,
"נלגמה": true,
"נלגמו": true,
"נלגמת": true,
"נלהבה": true,
"נלהבו": true,
"נלהבת": true,
"נלהטט": true,
"נלהיב": true,
"נלהיט": true,
"נלובה": true,
"נלובן": true,
"נלוהק": true,
"נלווה": true,
"נלוות": true,
"נלוזה": true,
"נלוחח": true,
"נלוחך": true,
"נלוטף": true,
"נלוטש": true,
"נלוכד": true,
"נלופף": true,
"נלוקט": true,
"נלוקק": true,
"נלחים": true,
"נלחין": true,
"נלחיץ": true,
"נלחלח": true,
"נלחמה": true,
"נלחמו": true,
"נלחמת": true,
"נלחצה": true,
"נלחצו": true,
"נלחצת": true,
"נלחשה": true,
"נלחשו": true,
"נלחשש": true,
"נלחשת": true,
"נלטוש": true,
"נליזה": true,
"נלכדה": true,
"נלכדו": true,
"נלכדת": true,
"נלכוד": true,
"נלכלך": true,
"נלכסן": true,
"נלמדה": true,
"נלמדו": true,
"נלמדת": true,
"נלסון": true,
"נלעגת": true,
"נלעיג": true,
"נלעיז": true,
"נלעיט": true,
"נלעסה": true,
"נלעסו": true,
"נלעסת": true,
"נלפות": true,
"נלפתה": true,
"נלפתו": true,
"נלפתי": true,
"נלפתם": true,
"נלפתן": true,
"נלפתת": true,
"נלקחה": true,
"נלקחו": true,
"נלקחת": true,
"נלשין": true,
"נמאיס": true,
"נמאסה": true,
"נמאסו": true,
"נמאסת": true,
"נמגנט": true,
"נמדבר": true,
"נמדדה": true,
"נמדדו": true,
"נמדדת": true,
"נמדוד": true,
"נמהלה": true,
"נמהלו": true,
"נמהלת": true,
"נמהרת": true,
"נמוגה": true,
"נמוגו": true,
"נמוגן": true,
"נמוגר": true,
"נמוגת": true,
"נמודר": true,
"נמוזג": true,
"נמוטט": true,
"נמוין": true,
"נמוכה": true,
"נמוכן": true,
"נמולא": true,
"נמולל": true,
"נמומן": true,
"נמומש": true,
"נמונה": true,
"נמונף": true,
"נמוסד": true,
"נמוסה": true,
"נמוסך": true,
"נמוסס": true,
"נמוען": true,
"נמופה": true,
"נמוצה": true,
"נמוצע": true,
"נמוקד": true,
"נמוקם": true,
"נמוקש": true,
"נמורק": true,
"נמושה": true,
"נמושו": true,
"נמושי": true,
"נמושך": true,
"נמושם": true,
"נמושן": true,
"נמושש": true,
"נמותג": true,
"נמותן": true,
"נמזגה": true,
"נמזגו": true,
"נמזגת": true,
"נמזוג": true,
"נמזמז": true,
"נמזער": true,
"נמחאה": true,
"נמחאו": true,
"נמחאת": true,
"נמחות": true,
"נמחזר": true,
"נמחיז": true,
"נמחים": true,
"נמחיש": true,
"נמחית": true,
"נמחלה": true,
"נמחלו": true,
"נמחלת": true,
"נמחצה": true,
"נמחצו": true,
"נמחצת": true,
"נמחקה": true,
"נמחקו": true,
"נמחקת": true,
"נמחשב": true,
"נמחתה": true,
"נמטיר": true,
"נמיבי": true,
"נמיות": true,
"נמייה": true,
"נמיין": true,
"נמכור": true,
"נמכות": true,
"נמכים": true,
"נמכנו": true,
"נמכרה": true,
"נמכרו": true,
"נמכרת": true,
"נמכתי": true,
"נמכתם": true,
"נמכתן": true,
"נמלאה": true,
"נמלאו": true,
"נמלאת": true,
"נמלוך": true,
"נמלוק": true,
"נמלטה": true,
"נמלטו": true,
"נמלטת": true,
"נמליה": true,
"נמליו": true,
"נמליח": true,
"נמליט": true,
"נמליי": true,
"נמליך": true,
"נמלים": true,
"נמליץ": true,
"נמלכד": true,
"נמלכה": true,
"נמלכו": true,
"נמלכם": true,
"נמלכן": true,
"נמלכת": true,
"נמלמל": true,
"נמלנו": true,
"נמלצר": true,
"נמלצת": true,
"נמלקה": true,
"נמלקו": true,
"נמלקת": true,
"נמלתה": true,
"נמלתו": true,
"נמלתי": true,
"נמלתך": true,
"נמלתם": true,
"נמלתן": true,
"נמנום": true,
"נמנות": true,
"נמנים": true,
"נמנית": true,
"נמנמה": true,
"נמנמו": true,
"נמנמי": true,
"נמנמת": true,
"נמנעה": true,
"נמנעו": true,
"נמנעת": true,
"נמנתה": true,
"נמסגר": true,
"נמסוך": true,
"נמסוק": true,
"נמסור": true,
"נמסות": true,
"נמסחר": true,
"נמסטל": true,
"נמסים": true,
"נמסכה": true,
"נמסכו": true,
"נמסכת": true,
"נמסמס": true,
"נמסמר": true,
"נמסנו": true,
"נמספר": true,
"נמסקה": true,
"נמסקו": true,
"נמסקת": true,
"נמסרה": true,
"נמסרו": true,
"נמסרת": true,
"נמסתי": true,
"נמסתם": true,
"נמסתן": true,
"נמעיד": true,
"נמעיט": true,
"נמעכה": true,
"נמעכו": true,
"נמעכת": true,
"נמענה": true,
"נמענו": true,
"נמעני": true,
"נמענך": true,
"נמענם": true,
"נמענן": true,
"נמענת": true,
"נמצאה": true,
"נמצאו": true,
"נמצאת": true,
"נמצוץ": true,
"נמציא": true,
"נמצמץ": true,
"נמצצה": true,
"נמצצו": true,
"נמצצת": true,
"נמקות": true,
"נמקיה": true,
"נמקיו": true,
"נמקיי": true,
"נמקיך": true,
"נמקים": true,
"נמקכם": true,
"נמקכן": true,
"נמקנה": true,
"נמקנו": true,
"נמרוד": true,
"נמרוט": true,
"נמרות": true,
"נמרחה": true,
"נמרחו": true,
"נמרחת": true,
"נמרטה": true,
"נמרטו": true,
"נמרטת": true,
"נמריא": true,
"נמריד": true,
"נמריה": true,
"נמריו": true,
"נמריי": true,
"נמריך": true,
"נמרים": true,
"נמריץ": true,
"נמרית": true,
"נמרכז": true,
"נמרכם": true,
"נמרכן": true,
"נמרנו": true,
"נמרפק": true,
"נמרצת": true,
"נמרתה": true,
"נמרתו": true,
"נמרתי": true,
"נמרתך": true,
"נמרתם": true,
"נמרתן": true,
"נמשוך": true,
"נמשול": true,
"נמשות": true,
"נמשחה": true,
"נמשחו": true,
"נמשחת": true,
"נמשיה": true,
"נמשיו": true,
"נמשיי": true,
"נמשיך": true,
"נמשיל": true,
"נמשים": true,
"נמשית": true,
"נמשכה": true,
"נמשכו": true,
"נמשכם": true,
"נמשכן": true,
"נמשכת": true,
"נמשלה": true,
"נמשלו": true,
"נמשלי": true,
"נמשלך": true,
"נמשלם": true,
"נמשלן": true,
"נמשלת": true,
"נמשמש": true,
"נמשנו": true,
"נמשתה": true,
"נמתחה": true,
"נמתחו": true,
"נמתחת": true,
"נמתין": true,
"נמתיק": true,
"ננאמה": true,
"ננאמו": true,
"ננאמת": true,
"ננבוט": true,
"ננבור": true,
"ננביט": true,
"ננגוס": true,
"ננגחה": true,
"ננגחו": true,
"ננגחת": true,
"ננגיד": true,
"ננגסה": true,
"ננגסו": true,
"ננגסת": true,
"ננגעה": true,
"ננגעו": true,
"ננגעת": true,
"ננדוד": true,
"ננדור": true,
"ננדנד": true,
"ננהיג": true,
"ננואץ": true,
"ננוגב": true,
"ננוגד": true,
"ננוגח": true,
"ננוגן": true,
"ננודב": true,
"ננודה": true,
"ננוהג": true,
"ננוהל": true,
"ננווט": true,
"ננוון": true,
"ננוחם": true,
"ננוחש": true,
"ננוטר": true,
"ננוכה": true,
"ננוכס": true,
"ננוכש": true,
"ננומק": true,
"ננוסה": true,
"ננוסח": true,
"ננוסר": true,
"ננוער": true,
"ננופה": true,
"ננופח": true,
"ננופף": true,
"ננופץ": true,
"ננופק": true,
"ננוצח": true,
"ננוצל": true,
"ננוקב": true,
"ננוקד": true,
"ננוקה": true,
"ננוקז": true,
"ננוקר": true,
"ננושל": true,
"ננושק": true,
"ננותב": true,
"ננותח": true,
"ננותץ": true,
"ננותק": true,
"ננזוף": true,
"ננזפה": true,
"ננזפו": true,
"ננזפת": true,
"ננחיל": true,
"ננחית": true,
"ננטוף": true,
"ננטוש": true,
"ננטעה": true,
"ננטעו": true,
"ננטעת": true,
"ננטרל": true,
"ננטשה": true,
"ננטשו": true,
"ננטשת": true,
"ננייד": true,
"ננמוך": true,
"ננמיך": true,
"ננמנם": true,
"ננסוך": true,
"ננסוק": true,
"ננסות": true,
"ננסיה": true,
"ננסיו": true,
"ננסיי": true,
"ננסיך": true,
"ננסים": true,
"ננסית": true,
"ננסכם": true,
"ננסכן": true,
"ננסנו": true,
"ננסתה": true,
"ננסתו": true,
"ננסתי": true,
"ננסתך": true,
"ננסתם": true,
"ננסתן": true,
"ננעים": true,
"ננעלה": true,
"ננעלו": true,
"ננעלת": true,
"ננענע": true,
"ננעצה": true,
"ננעצו": true,
"ננעצת": true,
"ננפוש": true,
"ננפיק": true,
"ננפיש": true,
"ננפנף": true,
"ננצוץ": true,
"ננצור": true,
"ננציח": true,
"ננציל": true,
"ננצנץ": true,
"ננצרה": true,
"ננצרו": true,
"ננצרת": true,
"ננקוט": true,
"ננקום": true,
"ננקוף": true,
"ננקור": true,
"ננקוש": true,
"ננקטה": true,
"ננקטו": true,
"ננקטת": true,
"ננקעה": true,
"ננקעו": true,
"ננקעת": true,
"ננרמל": true,
"ננשוך": true,
"ננשום": true,
"ננשוף": true,
"ננשור": true,
"ננשים": true,
"ננשכה": true,
"ננשכו": true,
"ננשכת": true,
"ננשנש": true,
"ננשפה": true,
"ננשפו": true,
"ננשפת": true,
"נסבול": true,
"נסבור": true,
"נסבות": true,
"נסבים": true,
"נסביר": true,
"נסבלה": true,
"נסבלו": true,
"נסבלת": true,
"נסבסד": true,
"נסגוד": true,
"נסגור": true,
"נסגיר": true,
"נסגנן": true,
"נסגרה": true,
"נסגרו": true,
"נסגרת": true,
"נסדוק": true,
"נסדיר": true,
"נסדקה": true,
"נסדקו": true,
"נסדקת": true,
"נסואב": true,
"נסובב": true,
"נסובה": true,
"נסובך": true,
"נסובן": true,
"נסוגה": true,
"נסוגו": true,
"נסוגל": true,
"נסוגר": true,
"נסוגת": true,
"נסודר": true,
"נסווג": true,
"נסווה": true,
"נסויג": true,
"נסויד": true,
"נסוים": true,
"נסוכה": true,
"נסוכך": true,
"נסוכל": true,
"נסוכם": true,
"נסולא": true,
"נסולף": true,
"נסולק": true,
"נסומא": true,
"נסומל": true,
"נסומם": true,
"נסומן": true,
"נסומר": true,
"נסונן": true,
"נסונף": true,
"נסועה": true,
"נסופח": true,
"נסופק": true,
"נסופר": true,
"נסוקר": true,
"נסורב": true,
"נסורס": true,
"נסורק": true,
"נסורת": true,
"נסותת": true,
"נסחבה": true,
"נסחבו": true,
"נסחבת": true,
"נסחטה": true,
"נסחטו": true,
"נסחטת": true,
"נסחיה": true,
"נסחיו": true,
"נסחיי": true,
"נסחיך": true,
"נסחים": true,
"נסחכם": true,
"נסחכן": true,
"נסחנה": true,
"נסחנו": true,
"נסחפה": true,
"נסחפו": true,
"נסחפת": true,
"נסחרה": true,
"נסחרו": true,
"נסחרר": true,
"נסחרת": true,
"נסטור": true,
"נסיבה": true,
"נסיגה": true,
"נסיהם": true,
"נסיהן": true,
"נסיוב": true,
"נסיון": true,
"נסיות": true,
"נסייג": true,
"נסייד": true,
"נסייך": true,
"נסיים": true,
"נסיין": true,
"נסייע": true,
"נסייר": true,
"נסיכה": true,
"נסיכו": true,
"נסיכי": true,
"נסיכך": true,
"נסיכם": true,
"נסיכן": true,
"נסיכת": true,
"נסימי": true,
"נסינה": true,
"נסינו": true,
"נסיעה": true,
"נסיעת": true,
"נסיקה": true,
"נסכוך": true,
"נסכום": true,
"נסכור": true,
"נסכיה": true,
"נסכיו": true,
"נסכיי": true,
"נסכיך": true,
"נסכים": true,
"נסכין": true,
"נסכית": true,
"נסככם": true,
"נסככן": true,
"נסכמה": true,
"נסכמו": true,
"נסכמת": true,
"נסכנו": true,
"נסכסך": true,
"נסכרה": true,
"נסכרו": true,
"נסכרת": true,
"נסכתי": true,
"נסכתם": true,
"נסכתן": true,
"נסלוד": true,
"נסלול": true,
"נסלחה": true,
"נסלחו": true,
"נסלחת": true,
"נסלים": true,
"נסליק": true,
"נסללה": true,
"נסללו": true,
"נסללת": true,
"נסלסל": true,
"נסמוך": true,
"נסמור": true,
"נסמיך": true,
"נסמיק": true,
"נסמכה": true,
"נסמכו": true,
"נסמכת": true,
"נסמלץ": true,
"נסנגר": true,
"נסנדל": true,
"נסנוט": true,
"נסניף": true,
"נסנתז": true,
"נסעיר": true,
"נסענו": true,
"נסערת": true,
"נסעתי": true,
"נסעתם": true,
"נסעתן": true,
"נספגה": true,
"נספגו": true,
"נספגת": true,
"נספוג": true,
"נספוד": true,
"נספוק": true,
"נספור": true,
"נספות": true,
"נספחה": true,
"נספחו": true,
"נספחי": true,
"נספחך": true,
"נספחם": true,
"נספחן": true,
"נספחת": true,
"נספיג": true,
"נספיד": true,
"נספים": true,
"נספיק": true,
"נספית": true,
"נספסר": true,
"נספרה": true,
"נספרו": true,
"נספרר": true,
"נספרת": true,
"נספתה": true,
"נסקול": true,
"נסקור": true,
"נסקלה": true,
"נסקלו": true,
"נסקלת": true,
"נסקנו": true,
"נסקרה": true,
"נסקרו": true,
"נסקרן": true,
"נסקרת": true,
"נסקתי": true,
"נסקתם": true,
"נסקתן": true,
"נסרבל": true,
"נסרגה": true,
"נסרגו": true,
"נסרגת": true,
"נסרוג": true,
"נסרוק": true,
"נסרור": true,
"נסרטן": true,
"נסריח": true,
"נסריט": true,
"נסרנה": true,
"נסרסר": true,
"נסרקה": true,
"נסרקו": true,
"נסרקת": true,
"נסתאב": true,
"נסתבך": true,
"נסתבן": true,
"נסתבר": true,
"נסתגל": true,
"נסתגף": true,
"נסתגר": true,
"נסתדר": true,
"נסתום": true,
"נסתור": true,
"נסתיר": true,
"נסתכל": true,
"נסתכם": true,
"נסתכן": true,
"נסתלק": true,
"נסתמא": true,
"נסתמה": true,
"נסתמו": true,
"נסתמך": true,
"נסתמם": true,
"נסתמן": true,
"נסתמר": true,
"נסתמת": true,
"נסתנן": true,
"נסתעף": true,
"נסתער": true,
"נסתפח": true,
"נסתפק": true,
"נסתפר": true,
"נסתרה": true,
"נסתרו": true,
"נסתרק": true,
"נסתרת": true,
"נסתתם": true,
"נסתתר": true,
"נעבוד": true,
"נעבוט": true,
"נעבור": true,
"נעביד": true,
"נעביר": true,
"נעברה": true,
"נעברו": true,
"נעברת": true,
"נעגוב": true,
"נעגון": true,
"נעגנה": true,
"נעגנו": true,
"נעגנת": true,
"נעדור": true,
"נעדיף": true,
"נעדכן": true,
"נעדרה": true,
"נעדרו": true,
"נעדרת": true,
"נעובד": true,
"נעובה": true,
"נעובר": true,
"נעוגל": true,
"נעוגן": true,
"נעודד": true,
"נעודן": true,
"נעווה": true,
"נעוור": true,
"נעוות": true,
"נעוטר": true,
"נעוכב": true,
"נעוכל": true,
"נעולה": true,
"נעולל": true,
"נעומד": true,
"נעומם": true,
"נעומת": true,
"נעונג": true,
"נעונה": true,
"נעוסה": true,
"נעופף": true,
"נעופש": true,
"נעוצב": true,
"נעוצה": true,
"נעוקל": true,
"נעוקם": true,
"נעוקר": true,
"נעורב": true,
"נעורו": true,
"נעורר": true,
"נעורת": true,
"נעזבה": true,
"נעזבו": true,
"נעזבת": true,
"נעזוב": true,
"נעזור": true,
"נעזיב": true,
"נעזרה": true,
"נעזרו": true,
"נעזרת": true,
"נעטוף": true,
"נעטיר": true,
"נעטפה": true,
"נעטפו": true,
"נעטפת": true,
"נעיין": true,
"נעייף": true,
"נעילה": true,
"נעילת": true,
"נעימה": true,
"נעיצה": true,
"נעירה": true,
"נעכור": true,
"נעכיר": true,
"נעכרה": true,
"נעכרו": true,
"נעכרת": true,
"נעלבה": true,
"נעלבו": true,
"נעלבת": true,
"נעלוב": true,
"נעלוז": true,
"נעלוץ": true,
"נעלות": true,
"נעליב": true,
"נעליה": true,
"נעליו": true,
"נעליי": true,
"נעליך": true,
"נעליל": true,
"נעלים": true,
"נעלית": true,
"נעלכם": true,
"נעלכן": true,
"נעלמה": true,
"נעלמו": true,
"נעלמת": true,
"נעלנה": true,
"נעלנו": true,
"נעלעל": true,
"נעלתי": true,
"נעלתם": true,
"נעלתן": true,
"נעמאן": true,
"נעמדה": true,
"נעמדו": true,
"נעמדת": true,
"נעמוד": true,
"נעמול": true,
"נעמיד": true,
"נעמיס": true,
"נעמיק": true,
"נעמלן": true,
"נעמנה": true,
"נעמנו": true,
"נעמעם": true,
"נעמתי": true,
"נעמתם": true,
"נעמתן": true,
"נענבה": true,
"נענבו": true,
"נענבת": true,
"נענדה": true,
"נענדו": true,
"נענדת": true,
"נענוב": true,
"נענוד": true,
"נענוע": true,
"נענות": true,
"נענטז": true,
"נענים": true,
"נעניק": true,
"נעניש": true,
"נענית": true,
"נענעה": true,
"נענעו": true,
"נענעי": true,
"נענעת": true,
"נענשה": true,
"נענשו": true,
"נענשת": true,
"נענתה": true,
"נעסוק": true,
"נעסיק": true,
"נעפיל": true,
"נעפעף": true,
"נעצבב": true,
"נעצבה": true,
"נעצבו": true,
"נעצבן": true,
"נעצבת": true,
"נעצום": true,
"נעצור": true,
"נעציב": true,
"נעציה": true,
"נעציו": true,
"נעציי": true,
"נעציך": true,
"נעצים": true,
"נעצכם": true,
"נעצכן": true,
"נעצמה": true,
"נעצמו": true,
"נעצמת": true,
"נעצנה": true,
"נעצנו": true,
"נעצרה": true,
"נעצרו": true,
"נעצרת": true,
"נעצתי": true,
"נעצתם": true,
"נעצתן": true,
"נעקדה": true,
"נעקדו": true,
"נעקדת": true,
"נעקוב": true,
"נעקוד": true,
"נעקוף": true,
"נעקוץ": true,
"נעקור": true,
"נעקפה": true,
"נעקפו": true,
"נעקפת": true,
"נעקצה": true,
"נעקצו": true,
"נעקצץ": true,
"נעקצת": true,
"נעקרה": true,
"נעקרו": true,
"נעקרת": true,
"נערבב": true,
"נערבל": true,
"נערגל": true,
"נערוב": true,
"נערוג": true,
"נערוך": true,
"נערום": true,
"נערוף": true,
"נערוק": true,
"נערות": true,
"נערטל": true,
"נעריב": true,
"נעריה": true,
"נעריו": true,
"נעריי": true,
"נעריך": true,
"נערים": true,
"נעריף": true,
"נעריץ": true,
"נערית": true,
"נערכה": true,
"נערכו": true,
"נערכם": true,
"נערכן": true,
"נערכת": true,
"נערמה": true,
"נערמו": true,
"נערמת": true,
"נערנה": true,
"נערנו": true,
"נערער": true,
"נערפה": true,
"נערפו": true,
"נערפל": true,
"נערפת": true,
"נערצה": true,
"נערצו": true,
"נערצת": true,
"נערתה": true,
"נערתו": true,
"נערתי": true,
"נערתך": true,
"נערתם": true,
"נערתן": true,
"נעשוק": true,
"נעשות": true,
"נעשים": true,
"נעשיר": true,
"נעשית": true,
"נעשקה": true,
"נעשקו": true,
"נעשקת": true,
"נעשתה": true,
"נעתור": true,
"נעתיק": true,
"נעתיר": true,
"נעתקה": true,
"נעתקו": true,
"נעתקת": true,
"נעתרה": true,
"נעתרו": true,
"נעתרת": true,
"נפאלי": true,
"נפברק": true,
"נפגום": true,
"נפגוש": true,
"נפגיז": true,
"נפגין": true,
"נפגיש": true,
"נפגמה": true,
"נפגמו": true,
"נפגמת": true,
"נפגעה": true,
"נפגעו": true,
"נפגעי": true,
"נפגעת": true,
"נפגשה": true,
"נפגשו": true,
"נפגשת": true,
"נפדות": true,
"נפדים": true,
"נפדית": true,
"נפדתה": true,
"נפואר": true,
"נפוגג": true,
"נפוגל": true,
"נפוזם": true,
"נפוזר": true,
"נפוחה": true,
"נפוחם": true,
"נפוחת": true,
"נפוטם": true,
"נפוטר": true,
"נפויח": true,
"נפויס": true,
"נפולג": true,
"נפולה": true,
"נפולי": true,
"נפולס": true,
"נפולת": true,
"נפונה": true,
"נפונק": true,
"נפוסל": true,
"נפוסק": true,
"נפוצה": true,
"נפוצו": true,
"נפוצח": true,
"נפוצל": true,
"נפוצץ": true,
"נפוצת": true,
"נפוקח": true,
"נפורד": true,
"נפורז": true,
"נפורט": true,
"נפורק": true,
"נפורר": true,
"נפורש": true,
"נפושט": true,
"נפושל": true,
"נפושק": true,
"נפושר": true,
"נפותה": true,
"נפותח": true,
"נפותל": true,
"נפזול": true,
"נפחדת": true,
"נפחות": true,
"נפחיד": true,
"נפחיה": true,
"נפחיו": true,
"נפחיי": true,
"נפחיך": true,
"נפחים": true,
"נפחית": true,
"נפחכם": true,
"נפחכן": true,
"נפחלץ": true,
"נפחנה": true,
"נפחנו": true,
"נפחתי": true,
"נפחתם": true,
"נפחתן": true,
"נפטון": true,
"נפטור": true,
"נפטיר": true,
"נפטפט": true,
"נפטרה": true,
"נפטרו": true,
"נפטרל": true,
"נפטרת": true,
"נפיחה": true,
"נפייח": true,
"נפייט": true,
"נפייס": true,
"נפילה": true,
"נפילו": true,
"נפילי": true,
"נפילך": true,
"נפילם": true,
"נפילן": true,
"נפילת": true,
"נפינה": true,
"נפיצה": true,
"נפכור": true,
"נפכפך": true,
"נפלאה": true,
"נפלאו": true,
"נפלאת": true,
"נפלוט": true,
"נפלור": true,
"נפלוש": true,
"נפלטה": true,
"נפלטו": true,
"נפלטת": true,
"נפליא": true,
"נפליג": true,
"נפליה": true,
"נפליו": true,
"נפליט": true,
"נפליי": true,
"נפליך": true,
"נפליל": true,
"נפלים": true,
"נפליץ": true,
"נפליק": true,
"נפלית": true,
"נפלכם": true,
"נפלכן": true,
"נפלמר": true,
"נפלנו": true,
"נפלפל": true,
"נפלתי": true,
"נפלתם": true,
"נפלתן": true,
"נפמפם": true,
"נפנוף": true,
"נפנות": true,
"נפנטז": true,
"נפנים": true,
"נפנית": true,
"נפנפה": true,
"נפנפו": true,
"נפנפי": true,
"נפנפת": true,
"נפנתה": true,
"נפסדת": true,
"נפסול": true,
"נפסוק": true,
"נפסטר": true,
"נפסיד": true,
"נפסיק": true,
"נפסלה": true,
"נפסלו": true,
"נפסלת": true,
"נפספס": true,
"נפסקה": true,
"נפסקו": true,
"נפסקת": true,
"נפעיל": true,
"נפעים": true,
"נפעמה": true,
"נפעמו": true,
"נפעמת": true,
"נפענח": true,
"נפעפע": true,
"נפערה": true,
"נפערו": true,
"נפערת": true,
"נפצור": true,
"נפציה": true,
"נפציו": true,
"נפציי": true,
"נפציך": true,
"נפצים": true,
"נפציע": true,
"נפציץ": true,
"נפציר": true,
"נפצכם": true,
"נפצכן": true,
"נפצנה": true,
"נפצנו": true,
"נפצעה": true,
"נפצעו": true,
"נפצעת": true,
"נפצפץ": true,
"נפקדה": true,
"נפקדו": true,
"נפקדת": true,
"נפקוד": true,
"נפקוק": true,
"נפקחה": true,
"נפקחו": true,
"נפקחת": true,
"נפקטר": true,
"נפקיד": true,
"נפקיע": true,
"נפקיר": true,
"נפקנה": true,
"נפקסס": true,
"נפקפק": true,
"נפקשש": true,
"נפרגן": true,
"נפרדה": true,
"נפרדו": true,
"נפרדת": true,
"נפרוט": true,
"נפרום": true,
"נפרוס": true,
"נפרוף": true,
"נפרוץ": true,
"נפרוק": true,
"נפרוש": true,
"נפרזל": true,
"נפרטה": true,
"נפרטו": true,
"נפרטת": true,
"נפריד": true,
"נפריז": true,
"נפריח": true,
"נפריט": true,
"נפריך": true,
"נפריס": true,
"נפריע": true,
"נפריש": true,
"נפרכס": true,
"נפרמה": true,
"נפרמו": true,
"נפרמט": true,
"נפרמת": true,
"נפרנס": true,
"נפרסה": true,
"נפרסו": true,
"נפרסם": true,
"נפרסת": true,
"נפרעה": true,
"נפרעו": true,
"נפרעת": true,
"נפרפר": true,
"נפרצה": true,
"נפרצו": true,
"נפרצת": true,
"נפרקה": true,
"נפרקו": true,
"נפרקת": true,
"נפרשה": true,
"נפרשו": true,
"נפרשן": true,
"נפרשת": true,
"נפשוט": true,
"נפשות": true,
"נפשיה": true,
"נפשיו": true,
"נפשיט": true,
"נפשיי": true,
"נפשיך": true,
"נפשיל": true,
"נפשים": true,
"נפשיר": true,
"נפשית": true,
"נפשכם": true,
"נפשכן": true,
"נפשנו": true,
"נפשעת": true,
"נפשפש": true,
"נפשתי": true,
"נפשתם": true,
"נפשתן": true,
"נפתוח": true,
"נפתור": true,
"נפתחה": true,
"נפתחו": true,
"נפתחת": true,
"נפתיע": true,
"נפתכם": true,
"נפתכן": true,
"נפתלי": true,
"נפתלת": true,
"נפתנו": true,
"נפתרה": true,
"נפתרו": true,
"נפתרת": true,
"נצבוט": true,
"נצבור": true,
"נצבטה": true,
"נצבטו": true,
"נצבטת": true,
"נצביע": true,
"נצבעה": true,
"נצבעו": true,
"נצבעת": true,
"נצברה": true,
"נצברו": true,
"נצברח": true,
"נצברת": true,
"נצדיע": true,
"נצדיק": true,
"נצהיב": true,
"נצהיר": true,
"נצודד": true,
"נצווה": true,
"נצווח": true,
"נצוות": true,
"נצוטט": true,
"נצויד": true,
"נצוין": true,
"נצויר": true,
"נצוית": true,
"נצולם": true,
"נצולת": true,
"נצומד": true,
"נצומק": true,
"נצונן": true,
"נצופה": true,
"נצופף": true,
"נצורה": true,
"נצורף": true,
"נצותת": true,
"נצחון": true,
"נצחיה": true,
"נצחיו": true,
"נצחיי": true,
"נצחיך": true,
"נצחים": true,
"נצחין": true,
"נצחיק": true,
"נצחית": true,
"נצחכם": true,
"נצחכן": true,
"נצחנה": true,
"נצחנו": true,
"נצחצח": true,
"נצחקק": true,
"נצטבע": true,
"נצטבר": true,
"נצטדק": true,
"נצטוו": true,
"נצטלב": true,
"נצטלל": true,
"נצטלם": true,
"נצטלק": true,
"נצטמג": true,
"נצטמק": true,
"נצטנן": true,
"נצטנע": true,
"נצטנף": true,
"נצטעף": true,
"נצטער": true,
"נצטפד": true,
"נצטרד": true,
"נצטרך": true,
"נצטרף": true,
"נציבה": true,
"נציבו": true,
"נציבי": true,
"נציבך": true,
"נציבם": true,
"נציבן": true,
"נציגה": true,
"נציגו": true,
"נציגי": true,
"נציגך": true,
"נציגם": true,
"נציגן": true,
"נציגת": true,
"נציהם": true,
"נציהן": true,
"נציות": true,
"נצייד": true,
"נצייך": true,
"נציים": true,
"נציין": true,
"נצייץ": true,
"נצייר": true,
"נציית": true,
"נציכם": true,
"נציכן": true,
"נצינו": true,
"נציצה": true,
"נצירה": true,
"נצלבה": true,
"נצלבו": true,
"נצלבת": true,
"נצלוב": true,
"נצלול": true,
"נצלוף": true,
"נצלות": true,
"נצליב": true,
"נצליח": true,
"נצלים": true,
"נצליף": true,
"נצלית": true,
"נצלנה": true,
"נצלני": true,
"נצלצל": true,
"נצלתה": true,
"נצמדה": true,
"נצמדו": true,
"נצמדת": true,
"נצמיא": true,
"נצמיד": true,
"נצמיח": true,
"נצמית": true,
"נצמצם": true,
"נצמרר": true,
"נצמתה": true,
"נצמתו": true,
"נצמתי": true,
"נצמתם": true,
"נצמתן": true,
"נצמתת": true,
"נצנוץ": true,
"נצנות": true,
"נצנזר": true,
"נצניח": true,
"נצניע": true,
"נצנצה": true,
"נצנצו": true,
"נצנצי": true,
"נצנצך": true,
"נצנצם": true,
"נצנצן": true,
"נצנצת": true,
"נצנתר": true,
"נצעיד": true,
"נצעיר": true,
"נצפון": true,
"נצפור": true,
"נצפות": true,
"נצפים": true,
"נצפין": true,
"נצפית": true,
"נצפצף": true,
"נצפרה": true,
"נצפרו": true,
"נצפרת": true,
"נצפתה": true,
"נצצנו": true,
"נצצתי": true,
"נצצתם": true,
"נצצתן": true,
"נצקצק": true,
"נצרבה": true,
"נצרבו": true,
"נצרבת": true,
"נצרדה": true,
"נצרדו": true,
"נצרדת": true,
"נצרוב": true,
"נצרוך": true,
"נצרום": true,
"נצרוף": true,
"נצרור": true,
"נצרות": true,
"נצריד": true,
"נצריה": true,
"נצריו": true,
"נצריח": true,
"נצריי": true,
"נצריך": true,
"נצרים": true,
"נצרכה": true,
"נצרכו": true,
"נצרכם": true,
"נצרכן": true,
"נצרכת": true,
"נצרנה": true,
"נצרנו": true,
"נצרצר": true,
"נצררה": true,
"נצררו": true,
"נצררת": true,
"נצרתה": true,
"נצרתו": true,
"נצרתי": true,
"נצרתך": true,
"נצרתם": true,
"נצרתן": true,
"נקבול": true,
"נקבור": true,
"נקבות": true,
"נקביה": true,
"נקביו": true,
"נקביי": true,
"נקביך": true,
"נקביל": true,
"נקבים": true,
"נקבית": true,
"נקבכם": true,
"נקבכן": true,
"נקבנה": true,
"נקבנו": true,
"נקבעה": true,
"נקבעו": true,
"נקבעת": true,
"נקבצה": true,
"נקבצו": true,
"נקבצת": true,
"נקברה": true,
"נקברו": true,
"נקברת": true,
"נקבתה": true,
"נקבתו": true,
"נקבתי": true,
"נקבתך": true,
"נקבתם": true,
"נקבתן": true,
"נקדחה": true,
"נקדחו": true,
"נקדחת": true,
"נקדיח": true,
"נקדים": true,
"נקדיש": true,
"נקדנה": true,
"נקהיל": true,
"נקהלה": true,
"נקהלו": true,
"נקהלת": true,
"נקובה": true,
"נקובע": true,
"נקובץ": true,
"נקודד": true,
"נקודה": true,
"נקודם": true,
"נקודש": true,
"נקודת": true,
"נקווה": true,
"נקוות": true,
"נקוזז": true,
"נקוטב": true,
"נקוטה": true,
"נקוטע": true,
"נקוים": true,
"נקולח": true,
"נקולל": true,
"נקולף": true,
"נקומח": true,
"נקומט": true,
"נקומם": true,
"נקומר": true,
"נקונח": true,
"נקונן": true,
"נקועה": true,
"נקופד": true,
"נקופה": true,
"נקופח": true,
"נקופל": true,
"נקוצץ": true,
"נקוצר": true,
"נקורב": true,
"נקורה": true,
"נקורץ": true,
"נקורר": true,
"נקושה": true,
"נקושט": true,
"נקושר": true,
"נקושש": true,
"נקזנה": true,
"נקטול": true,
"נקטום": true,
"נקטוף": true,
"נקטין": true,
"נקטיר": true,
"נקטלג": true,
"נקטלה": true,
"נקטלו": true,
"נקטלת": true,
"נקטמה": true,
"נקטמו": true,
"נקטמת": true,
"נקטנו": true,
"נקטעה": true,
"נקטעו": true,
"נקטעת": true,
"נקטפה": true,
"נקטפו": true,
"נקטפת": true,
"נקטרג": true,
"נקטתי": true,
"נקטתם": true,
"נקטתן": true,
"נקיון": true,
"נקיות": true,
"נקיטה": true,
"נקיטת": true,
"נקייה": true,
"נקיים": true,
"נקייץ": true,
"נקינה": true,
"נקיעה": true,
"נקיפה": true,
"נקיקה": true,
"נקיקו": true,
"נקיקי": true,
"נקיקך": true,
"נקיקם": true,
"נקיקן": true,
"נקירה": true,
"נקישה": true,
"נקלוד": true,
"נקלוט": true,
"נקלוף": true,
"נקלות": true,
"נקלטה": true,
"נקלטו": true,
"נקלטר": true,
"נקלטת": true,
"נקליד": true,
"נקליט": true,
"נקלים": true,
"נקליק": true,
"נקליש": true,
"נקלית": true,
"נקלעה": true,
"נקלעו": true,
"נקלעת": true,
"נקלקל": true,
"נקלתה": true,
"נקמול": true,
"נקמוץ": true,
"נקמות": true,
"נקמנו": true,
"נקמני": true,
"נקמתה": true,
"נקמתו": true,
"נקמתי": true,
"נקמתך": true,
"נקמתם": true,
"נקמתן": true,
"נקנוס": true,
"נקנות": true,
"נקנטר": true,
"נקניט": true,
"נקנים": true,
"נקניק": true,
"נקנית": true,
"נקנסה": true,
"נקנסו": true,
"נקנסת": true,
"נקנתה": true,
"נקסום": true,
"נקסים": true,
"נקעיה": true,
"נקעיו": true,
"נקעיי": true,
"נקעיך": true,
"נקעים": true,
"נקעכם": true,
"נקעכן": true,
"נקענה": true,
"נקענו": true,
"נקעקע": true,
"נקעתי": true,
"נקעתם": true,
"נקעתן": true,
"נקפוץ": true,
"נקפיא": true,
"נקפיד": true,
"נקפיץ": true,
"נקפנו": true,
"נקפתי": true,
"נקפתם": true,
"נקפתן": true,
"נקצבה": true,
"נקצבו": true,
"נקצבת": true,
"נקצוב": true,
"נקצוף": true,
"נקצוץ": true,
"נקצור": true,
"נקציב": true,
"נקציה": true,
"נקצין": true,
"נקציע": true,
"נקציף": true,
"נקצצה": true,
"נקצצו": true,
"נקצצת": true,
"נקצרה": true,
"נקצרו": true,
"נקצרת": true,
"נקראה": true,
"נקראו": true,
"נקראת": true,
"נקרום": true,
"נקרון": true,
"נקרוס": true,
"נקרוץ": true,
"נקרות": true,
"נקרטע": true,
"נקריא": true,
"נקריב": true,
"נקריה": true,
"נקריו": true,
"נקריח": true,
"נקריי": true,
"נקריך": true,
"נקרים": true,
"נקרין": true,
"נקריש": true,
"נקרית": true,
"נקרכם": true,
"נקרכן": true,
"נקרנה": true,
"נקרנו": true,
"נקרעה": true,
"נקרעו": true,
"נקרעת": true,
"נקרצף": true,
"נקרקע": true,
"נקרקף": true,
"נקרקר": true,
"נקרקש": true,
"נקרשה": true,
"נקרשו": true,
"נקרשת": true,
"נקרתה": true,
"נקרתי": true,
"נקרתם": true,
"נקרתן": true,
"נקשור": true,
"נקשות": true,
"נקשיב": true,
"נקשיח": true,
"נקשנו": true,
"נקשקש": true,
"נקשרה": true,
"נקשרו": true,
"נקשרת": true,
"נקשתי": true,
"נקשתם": true,
"נקשתן": true,
"נראות": true,
"נראים": true,
"נראית": true,
"נראתה": true,
"נרבוץ": true,
"נרביע": true,
"נרביץ": true,
"נרגוז": true,
"נרגום": true,
"נרגון": true,
"נרגוש": true,
"נרגזת": true,
"נרגיה": true,
"נרגיז": true,
"נרגיל": true,
"נרגיע": true,
"נרגיש": true,
"נרגמה": true,
"נרגמו": true,
"נרגמת": true,
"נרגנת": true,
"נרגעה": true,
"נרגעו": true,
"נרגעת": true,
"נרגשת": true,
"נרדוף": true,
"נרדים": true,
"נרדמה": true,
"נרדמו": true,
"נרדמת": true,
"נרדפה": true,
"נרדפו": true,
"נרדפת": true,
"נרהיב": true,
"נרובב": true,
"נרובד": true,
"נרובע": true,
"נרוגש": true,
"נרודד": true,
"נרוהט": true,
"נרווה": true,
"נרווח": true,
"נרוטש": true,
"נרוכז": true,
"נרוכך": true,
"נרומה": true,
"נרומם": true,
"נרוסן": true,
"נרוסס": true,
"נרוסק": true,
"נרופא": true,
"נרופד": true,
"נרופף": true,
"נרוצה": true,
"נרוצף": true,
"נרוצץ": true,
"נרוקן": true,
"נרוקע": true,
"נרושש": true,
"נרושת": true,
"נרותך": true,
"נרותק": true,
"נרחבת": true,
"נרחיב": true,
"נרחיק": true,
"נרחצה": true,
"נרחצו": true,
"נרחצת": true,
"נרחרח": true,
"נרטבה": true,
"נרטבו": true,
"נרטבת": true,
"נרטוט": true,
"נרטון": true,
"נרטטה": true,
"נרטטו": true,
"נרטטת": true,
"נרטיב": true,
"נרטיט": true,
"נריים": true,
"נרכון": true,
"נרכוס": true,
"נרכוש": true,
"נרכיב": true,
"נרכין": true,
"נרכסה": true,
"נרכסו": true,
"נרכסת": true,
"נרכשה": true,
"נרכשו": true,
"נרכשת": true,
"נרלים": true,
"נרמוז": true,
"נרמול": true,
"נרמוס": true,
"נרמזה": true,
"נרמזו": true,
"נרמזר": true,
"נרמזת": true,
"נרמלה": true,
"נרמלו": true,
"נרמלי": true,
"נרמלת": true,
"נרמסה": true,
"נרמסו": true,
"נרמסת": true,
"נרנין": true,
"נרעדת": true,
"נרעיב": true,
"נרעיד": true,
"נרעיל": true,
"נרעים": true,
"נרעיף": true,
"נרעיש": true,
"נרענן": true,
"נרעשה": true,
"נרעשו": true,
"נרעשת": true,
"נרפאה": true,
"נרפאו": true,
"נרפאת": true,
"נרפות": true,
"נרפים": true,
"נרפית": true,
"נרפרף": true,
"נרצוץ": true,
"נרצות": true,
"נרצחה": true,
"נרצחו": true,
"נרצחת": true,
"נרצים": true,
"נרצין": true,
"נרצית": true,
"נרצעת": true,
"נרצצה": true,
"נרצצו": true,
"נרצצת": true,
"נרצתה": true,
"נרקבה": true,
"נרקבו": true,
"נרקבת": true,
"נרקוד": true,
"נרקום": true,
"נרקוק": true,
"נרקחה": true,
"נרקחו": true,
"נרקחת": true,
"נרקיב": true,
"נרקיד": true,
"נרקיס": true,
"נרקיע": true,
"נרקמה": true,
"נרקמו": true,
"נרקמת": true,
"נרשום": true,
"נרשוף": true,
"נרשים": true,
"נרשיע": true,
"נרשמה": true,
"נרשמו": true,
"נרשמת": true,
"נרשרש": true,
"נרתום": true,
"נרתיח": true,
"נרתיע": true,
"נרתיק": true,
"נרתמה": true,
"נרתמו": true,
"נרתמת": true,
"נרתעה": true,
"נרתעו": true,
"נרתעת": true,
"נשאבה": true,
"נשאבו": true,
"נשאבת": true,
"נשאגה": true,
"נשאגו": true,
"נשאגת": true,
"נשאות": true,
"נשאיה": true,
"נשאיו": true,
"נשאיי": true,
"נשאיך": true,
"נשאיל": true,
"נשאים": true,
"נשאיף": true,
"נשאיר": true,
"נשאית": true,
"נשאכם": true,
"נשאכן": true,
"נשאלה": true,
"נשאלו": true,
"נשאלת": true,
"נשאנו": true,
"נשאפה": true,
"נשאפו": true,
"נשאפת": true,
"נשארה": true,
"נשארו": true,
"נשארת": true,
"נשאתי": true,
"נשאתם": true,
"נשאתן": true,
"נשבוק": true,
"נשבור": true,
"נשבות": true,
"נשביח": true,
"נשבים": true,
"נשביע": true,
"נשביר": true,
"נשבית": true,
"נשבנה": true,
"נשבנו": true,
"נשבעה": true,
"נשבעו": true,
"נשבעת": true,
"נשברה": true,
"נשברו": true,
"נשברת": true,
"נשבתה": true,
"נשבתי": true,
"נשבתם": true,
"נשבתן": true,
"נשגבה": true,
"נשגבו": true,
"נשגבת": true,
"נשגוג": true,
"נשגיח": true,
"נשגשג": true,
"נשדדה": true,
"נשדדו": true,
"נשדדת": true,
"נשדוד": true,
"נשדרג": true,
"נשואה": true,
"נשובב": true,
"נשובח": true,
"נשובט": true,
"נשובץ": true,
"נשובר": true,
"נשובש": true,
"נשוגע": true,
"נשוגר": true,
"נשודד": true,
"נשודך": true,
"נשודל": true,
"נשודר": true,
"נשווה": true,
"נשווע": true,
"נשווק": true,
"נשוחד": true,
"נשוחח": true,
"נשוחק": true,
"נשוטט": true,
"נשויך": true,
"נשויף": true,
"נשוכה": true,
"נשוכך": true,
"נשוכל": true,
"נשוכן": true,
"נשולב": true,
"נשולח": true,
"נשולם": true,
"נשולש": true,
"נשומן": true,
"נשומר": true,
"נשונה": true,
"נשונן": true,
"נשונע": true,
"נשוסה": true,
"נשוסע": true,
"נשוסף": true,
"נשוער": true,
"נשופד": true,
"נשופה": true,
"נשופע": true,
"נשופץ": true,
"נשופר": true,
"נשוקם": true,
"נשוקע": true,
"נשוקף": true,
"נשוקץ": true,
"נשורש": true,
"נשורת": true,
"נשותך": true,
"נשותף": true,
"נשותק": true,
"נשזוף": true,
"נשזור": true,
"נשזרה": true,
"נשזרו": true,
"נשזרת": true,
"נשחבר": true,
"נשחזר": true,
"נשחטה": true,
"נשחטו": true,
"נשחטת": true,
"נשחיז": true,
"נשחיל": true,
"נשחים": true,
"נשחיר": true,
"נשחית": true,
"נשחלף": true,
"נשחקה": true,
"נשחקו": true,
"נשחקת": true,
"נשחרר": true,
"נשטום": true,
"נשטוף": true,
"נשטפה": true,
"נשטפו": true,
"נשטפת": true,
"נשיאה": true,
"נשיאו": true,
"נשיאי": true,
"נשיאך": true,
"נשיאם": true,
"נשיאן": true,
"נשיאת": true,
"נשיהם": true,
"נשיהן": true,
"נשיות": true,
"נשייה": true,
"נשייט": true,
"נשייך": true,
"נשיים": true,
"נשייף": true,
"נשיכה": true,
"נשיכם": true,
"נשיכן": true,
"נשילה": true,
"נשימה": true,
"נשינו": true,
"נשיפה": true,
"נשיקה": true,
"נשירה": true,
"נשירת": true,
"נשכבה": true,
"נשכבו": true,
"נשכבת": true,
"נשכול": true,
"נשכון": true,
"נשכור": true,
"נשכחה": true,
"נשכחו": true,
"נשכחת": true,
"נשכיב": true,
"נשכיח": true,
"נשכיל": true,
"נשכים": true,
"נשכין": true,
"נשכיר": true,
"נשכלל": true,
"נשכנה": true,
"נשכנו": true,
"נשכני": true,
"נשכנך": true,
"נשכנם": true,
"נשכנן": true,
"נשכנע": true,
"נשכפל": true,
"נשכרה": true,
"נשכרו": true,
"נשכרת": true,
"נשכשך": true,
"נשכתב": true,
"נשכתי": true,
"נשכתם": true,
"נשכתן": true,
"נשלהב": true,
"נשלוט": true,
"נשלול": true,
"נשלוף": true,
"נשלוק": true,
"נשלות": true,
"נשלחה": true,
"נשלחו": true,
"נשלחת": true,
"נשלטה": true,
"נשלטו": true,
"נשלטט": true,
"נשלטת": true,
"נשליה": true,
"נשליו": true,
"נשליט": true,
"נשליי": true,
"נשליך": true,
"נשלים": true,
"נשלית": true,
"נשלכם": true,
"נשלכן": true,
"נשללה": true,
"נשללו": true,
"נשללת": true,
"נשלמה": true,
"נשלמו": true,
"נשלמת": true,
"נשלנה": true,
"נשלנו": true,
"נשלפה": true,
"נשלפו": true,
"נשלפת": true,
"נשלקה": true,
"נשלקו": true,
"נשלקת": true,
"נשלשל": true,
"נשלתה": true,
"נשלתי": true,
"נשלתם": true,
"נשלתן": true,
"נשמדה": true,
"נשמדו": true,
"נשמדת": true,
"נשמוט": true,
"נשמור": true,
"נשמות": true,
"נשמטה": true,
"נשמטו": true,
"נשמטת": true,
"נשמיד": true,
"נשמיט": true,
"נשמין": true,
"נשמיע": true,
"נשמיץ": true,
"נשמיש": true,
"נשמנו": true,
"נשמעה": true,
"נשמעו": true,
"נשמעת": true,
"נשמרה": true,
"נשמרו": true,
"נשמרת": true,
"נשמתה": true,
"נשמתו": true,
"נשמתי": true,
"נשמתך": true,
"נשמתם": true,
"נשמתן": true,
"נשנוש": true,
"נשנות": true,
"נשניא": true,
"נשנים": true,
"נשנית": true,
"נשנקה": true,
"נשנקו": true,
"נשנקת": true,
"נשנשה": true,
"נשנשו": true,
"נשנשי": true,
"נשנשת": true,
"נשנתה": true,
"נשעבד": true,
"נשעין": true,
"נשעמם": true,
"נשענה": true,
"נשענו": true,
"נשענת": true,
"נשערך": true,
"נשעשע": true,
"נשעתק": true,
"נשפוט": true,
"נשפוך": true,
"נשפול": true,
"נשפות": true,
"נשפטה": true,
"נשפטו": true,
"נשפטת": true,
"נשפיה": true,
"נשפיו": true,
"נשפיי": true,
"נשפיך": true,
"נשפיל": true,
"נשפים": true,
"נשפיע": true,
"נשפכה": true,
"נשפכו": true,
"נשפכם": true,
"נשפכן": true,
"נשפכת": true,
"נשפנו": true,
"נשפעל": true,
"נשפצר": true,
"נשפשף": true,
"נשפתי": true,
"נשפתם": true,
"נשפתן": true,
"נשצוף": true,
"נשקוד": true,
"נשקוט": true,
"נשקול": true,
"נשקיה": true,
"נשקיו": true,
"נשקיט": true,
"נשקיי": true,
"נשקיך": true,
"נשקים": true,
"נשקיע": true,
"נשקיף": true,
"נשקכם": true,
"נשקכן": true,
"נשקלה": true,
"נשקלו": true,
"נשקלל": true,
"נשקלת": true,
"נשקנה": true,
"נשקנו": true,
"נשקפה": true,
"נשקפו": true,
"נשקפת": true,
"נשקשק": true,
"נשקתי": true,
"נשקתם": true,
"נשקתן": true,
"נשרבב": true,
"נשרבט": true,
"נשרוד": true,
"נשרוט": true,
"נשרוך": true,
"נשרוף": true,
"נשרוץ": true,
"נשרוק": true,
"נשרור": true,
"נשרטה": true,
"נשרטו": true,
"נשרטט": true,
"נשרטת": true,
"נשריה": true,
"נשריו": true,
"נשריי": true,
"נשריך": true,
"נשרים": true,
"נשריץ": true,
"נשריש": true,
"נשרכה": true,
"נשרכו": true,
"נשרכם": true,
"נשרכן": true,
"נשרכת": true,
"נשרנו": true,
"נשרפה": true,
"נשרפו": true,
"נשרפת": true,
"נשרקה": true,
"נשרקו": true,
"נשרקת": true,
"נשרשר": true,
"נשרתי": true,
"נשרתם": true,
"נשרתן": true,
"נשתאה": true,
"נשתבח": true,
"נשתבט": true,
"נשתבץ": true,
"נשתבר": true,
"נשתבש": true,
"נשתגע": true,
"נשתדך": true,
"נשתדל": true,
"נשתהה": true,
"נשתהו": true,
"נשתול": true,
"נשתוק": true,
"נשתות": true,
"נשתזף": true,
"נשתחל": true,
"נשתחץ": true,
"נשתטה": true,
"נשתטח": true,
"נשתיל": true,
"נשתין": true,
"נשתיק": true,
"נשתית": true,
"נשתכח": true,
"נשתכן": true,
"נשתכר": true,
"נשתלב": true,
"נשתלה": true,
"נשתלו": true,
"נשתלח": true,
"נשתלט": true,
"נשתלם": true,
"נשתלת": true,
"נשתמד": true,
"נשתמט": true,
"נשתמע": true,
"נשתמר": true,
"נשתמש": true,
"נשתנה": true,
"נשתנו": true,
"נשתנק": true,
"נשתעל": true,
"נשתפד": true,
"נשתפך": true,
"נשתפל": true,
"נשתפן": true,
"נשתפר": true,
"נשתקם": true,
"נשתקע": true,
"נשתקף": true,
"נשתרג": true,
"נשתרך": true,
"נשתרע": true,
"נשתרר": true,
"נשתרש": true,
"נשתתה": true,
"נשתתף": true,
"נשתתק": true,
"נתאבד": true,
"נתאבך": true,
"נתאבל": true,
"נתאבן": true,
"נתאבק": true,
"נתאגד": true,
"נתאדה": true,
"נתאהב": true,
"נתאזן": true,
"נתאזר": true,
"נתאחד": true,
"נתאחה": true,
"נתאחר": true,
"נתאים": true,
"נתאכל": true,
"נתאמן": true,
"נתאמץ": true,
"נתאמת": true,
"נתאסף": true,
"נתאפס": true,
"נתאפק": true,
"נתאפר": true,
"נתארח": true,
"נתארך": true,
"נתארס": true,
"נתאשר": true,
"נתבאס": true,
"נתבאר": true,
"נתבגר": true,
"נתבדה": true,
"נתבדח": true,
"נתבדל": true,
"נתבדר": true,
"נתבהם": true,
"נתבהר": true,
"נתבות": true,
"נתבזה": true,
"נתבטא": true,
"נתבטל": true,
"נתביה": true,
"נתביו": true,
"נתביי": true,
"נתביך": true,
"נתבים": true,
"נתבכם": true,
"נתבכן": true,
"נתבלה": true,
"נתבלט": true,
"נתבנה": true,
"נתבנו": true,
"נתבסם": true,
"נתבסס": true,
"נתבעה": true,
"נתבעו": true,
"נתבעת": true,
"נתבצע": true,
"נתבצר": true,
"נתבקע": true,
"נתבקש": true,
"נתברג": true,
"נתברך": true,
"נתברר": true,
"נתבשל": true,
"נתבשם": true,
"נתבשר": true,
"נתבתה": true,
"נתבתו": true,
"נתבתי": true,
"נתבתך": true,
"נתבתם": true,
"נתבתן": true,
"נתגאה": true,
"נתגבה": true,
"נתגבר": true,
"נתגבש": true,
"נתגדר": true,
"נתגלה": true,
"נתגלו": true,
"נתגלח": true,
"נתגלם": true,
"נתגלע": true,
"נתגלש": true,
"נתגמד": true,
"נתגמל": true,
"נתגמש": true,
"נתגנב": true,
"נתגפף": true,
"נתגרד": true,
"נתגרה": true,
"נתגרש": true,
"נתגשם": true,
"נתדלק": true,
"נתדמה": true,
"נתדפק": true,
"נתדרך": true,
"נתהדק": true,
"נתהדר": true,
"נתהוו": true,
"נתהלך": true,
"נתהלל": true,
"נתהפך": true,
"נתואם": true,
"נתואר": true,
"נתובל": true,
"נתווה": true,
"נתווך": true,
"נתוזז": true,
"נתוחח": true,
"נתוחם": true,
"נתויג": true,
"נתויק": true,
"נתויר": true,
"נתוכן": true,
"נתונה": true,
"נתונו": true,
"נתוני": true,
"נתונך": true,
"נתונם": true,
"נתונן": true,
"נתועד": true,
"נתועל": true,
"נתועש": true,
"נתופף": true,
"נתוקל": true,
"נתוקן": true,
"נתורץ": true,
"נתזיה": true,
"נתזיו": true,
"נתזיי": true,
"נתזיך": true,
"נתזים": true,
"נתזכם": true,
"נתזכן": true,
"נתזמן": true,
"נתזמר": true,
"נתזנו": true,
"נתחבא": true,
"נתחבב": true,
"נתחבה": true,
"נתחבו": true,
"נתחבט": true,
"נתחבל": true,
"נתחבק": true,
"נתחבר": true,
"נתחבת": true,
"נתחדד": true,
"נתחדש": true,
"נתחום": true,
"נתחזה": true,
"נתחזק": true,
"נתחיה": true,
"נתחיו": true,
"נתחיי": true,
"נתחיך": true,
"נתחיל": true,
"נתחים": true,
"נתחכך": true,
"נתחכם": true,
"נתחכן": true,
"נתחלה": true,
"נתחלף": true,
"נתחלק": true,
"נתחמם": true,
"נתחמן": true,
"נתחמק": true,
"נתחמש": true,
"נתחנה": true,
"נתחנו": true,
"נתחנך": true,
"נתחנן": true,
"נתחנף": true,
"נתחסד": true,
"נתחסל": true,
"נתחסן": true,
"נתחפף": true,
"נתחפר": true,
"נתחפש": true,
"נתחצף": true,
"נתחקה": true,
"נתחקר": true,
"נתחרד": true,
"נתחרה": true,
"נתחרז": true,
"נתחרט": true,
"נתחרש": true,
"נתחשב": true,
"נתחשל": true,
"נתחשק": true,
"נתחתן": true,
"נתחתר": true,
"נתיבה": true,
"נתיבו": true,
"נתיבי": true,
"נתיבך": true,
"נתיבם": true,
"נתיבן": true,
"נתיות": true,
"נתיחה": true,
"נתייג": true,
"נתיים": true,
"נתייק": true,
"נתייר": true,
"נתיכה": true,
"נתיכו": true,
"נתיכי": true,
"נתיכך": true,
"נתיכם": true,
"נתיכן": true,
"נתינה": true,
"נתינו": true,
"נתיני": true,
"נתינך": true,
"נתינם": true,
"נתינן": true,
"נתינת": true,
"נתיקה": true,
"נתכבד": true,
"נתכבס": true,
"נתכדר": true,
"נתכהה": true,
"נתכחש": true,
"נתכיה": true,
"נתכיו": true,
"נתכיי": true,
"נתכיך": true,
"נתכים": true,
"נתככם": true,
"נתככן": true,
"נתכלה": true,
"נתכנה": true,
"נתכנו": true,
"נתכנן": true,
"נתכנס": true,
"נתכנת": true,
"נתכסה": true,
"נתכסח": true,
"נתכער": true,
"נתכפר": true,
"נתכתב": true,
"נתכתש": true,
"נתלבט": true,
"נתלבן": true,
"נתלבש": true,
"נתלהב": true,
"נתלהט": true,
"נתלהם": true,
"נתלוש": true,
"נתלות": true,
"נתלחש": true,
"נתלטף": true,
"נתלטש": true,
"נתלים": true,
"נתלית": true,
"נתלכד": true,
"נתלמד": true,
"נתלפף": true,
"נתלקח": true,
"נתלקק": true,
"נתלשה": true,
"נתלשו": true,
"נתלשת": true,
"נתלתה": true,
"נתלתל": true,
"נתמגן": true,
"נתמוך": true,
"נתמזג": true,
"נתמזל": true,
"נתמחה": true,
"נתמחר": true,
"נתמיד": true,
"נתמיה": true,
"נתמיר": true,
"נתמכה": true,
"נתמכו": true,
"נתמכן": true,
"נתמכר": true,
"נתמכת": true,
"נתמלא": true,
"נתמלל": true,
"נתממש": true,
"נתמנה": true,
"נתמנו": true,
"נתמנף": true,
"נתמסד": true,
"נתמסה": true,
"נתמסר": true,
"נתמעט": true,
"נתמצא": true,
"נתמצה": true,
"נתמצק": true,
"נתמצת": true,
"נתמקד": true,
"נתמקח": true,
"נתמקם": true,
"נתמרד": true,
"נתמרח": true,
"נתמרן": true,
"נתמרץ": true,
"נתמרק": true,
"נתמרר": true,
"נתמשך": true,
"נתמתח": true,
"נתמתן": true,
"נתנאה": true,
"נתנאל": true,
"נתנבא": true,
"נתנגב": true,
"נתנגד": true,
"נתנגח": true,
"נתנגן": true,
"נתנגש": true,
"נתנדב": true,
"נתנדף": true,
"נתנהג": true,
"נתנהל": true,
"נתנזל": true,
"נתנזר": true,
"נתנחל": true,
"נתנחם": true,
"נתניה": true,
"נתניע": true,
"נתנכל": true,
"נתנכר": true,
"נתנסה": true,
"נתנסח": true,
"נתנער": true,
"נתנפח": true,
"נתנפל": true,
"נתנפץ": true,
"נתנצח": true,
"נתנצל": true,
"נתנצר": true,
"נתנקה": true,
"נתנקז": true,
"נתנקם": true,
"נתנקש": true,
"נתנשא": true,
"נתנשם": true,
"נתנשף": true,
"נתנשק": true,
"נתנתב": true,
"נתנתק": true,
"נתסוס": true,
"נתסיס": true,
"נתסכל": true,
"נתסרט": true,
"נתעבה": true,
"נתעבר": true,
"נתעבת": true,
"נתעגל": true,
"נתעדן": true,
"נתעדף": true,
"נתעטף": true,
"נתעטש": true,
"נתעכב": true,
"נתעכל": true,
"נתעלה": true,
"נתעלל": true,
"נתעלם": true,
"נתעלס": true,
"נתעלף": true,
"נתעלק": true,
"נתעמל": true,
"נתעמם": true,
"נתעמק": true,
"נתעמר": true,
"נתעמת": true,
"נתענג": true,
"נתענה": true,
"נתענן": true,
"נתעסק": true,
"נתעפש": true,
"נתעצב": true,
"נתעצל": true,
"נתעצם": true,
"נתעקל": true,
"נתעקם": true,
"נתעקר": true,
"נתעקש": true,
"נתערב": true,
"נתערה": true,
"נתעשר": true,
"נתעשת": true,
"נתעתד": true,
"נתעתע": true,
"נתעתק": true,
"נתפאר": true,
"נתפגל": true,
"נתפגר": true,
"נתפוס": true,
"נתפור": true,
"נתפוש": true,
"נתפזם": true,
"נתפזר": true,
"נתפחם": true,
"נתפחס": true,
"נתפטם": true,
"נתפטר": true,
"נתפיח": true,
"נתפיל": true,
"נתפכח": true,
"נתפלא": true,
"נתפלג": true,
"נתפלח": true,
"נתפלל": true,
"נתפלץ": true,
"נתפלש": true,
"נתפנה": true,
"נתפנק": true,
"נתפסה": true,
"נתפסו": true,
"נתפסת": true,
"נתפעל": true,
"נתפעם": true,
"נתפצח": true,
"נתפצל": true,
"נתפקד": true,
"נתפקע": true,
"נתפקר": true,
"נתפרה": true,
"נתפרו": true,
"נתפרס": true,
"נתפרע": true,
"נתפרץ": true,
"נתפרק": true,
"נתפרש": true,
"נתפרת": true,
"נתפשה": true,
"נתפשו": true,
"נתפשט": true,
"נתפשל": true,
"נתפשר": true,
"נתפשת": true,
"נתפתה": true,
"נתפתח": true,
"נתפתל": true,
"נתצנה": true,
"נתצפת": true,
"נתקבל": true,
"נתקבע": true,
"נתקבץ": true,
"נתקדם": true,
"נתקדר": true,
"נתקדש": true,
"נתקהל": true,
"נתקוף": true,
"נתקות": true,
"נתקזז": true,
"נתקיה": true,
"נתקיו": true,
"נתקיי": true,
"נתקיך": true,
"נתקיל": true,
"נתקים": true,
"נתקין": true,
"נתקיף": true,
"נתקכם": true,
"נתקכן": true,
"נתקלה": true,
"נתקלו": true,
"נתקלח": true,
"נתקלט": true,
"נתקלס": true,
"נתקלף": true,
"נתקלת": true,
"נתקמט": true,
"נתקמר": true,
"נתקנא": true,
"נתקנה": true,
"נתקנו": true,
"נתקעה": true,
"נתקעו": true,
"נתקעת": true,
"נתקפד": true,
"נתקפה": true,
"נתקפו": true,
"נתקפל": true,
"נתקפת": true,
"נתקצב": true,
"נתקצץ": true,
"נתקצר": true,
"נתקרא": true,
"נתקרב": true,
"נתקרח": true,
"נתקרר": true,
"נתקרש": true,
"נתקשה": true,
"נתקשח": true,
"נתקשט": true,
"נתקשר": true,
"נתקתק": true,
"נתראה": true,
"נתרבד": true,
"נתרבה": true,
"נתרבת": true,
"נתרגז": true,
"נתרגל": true,
"נתרגם": true,
"נתרגע": true,
"נתרגש": true,
"נתרדד": true,
"נתרהב": true,
"נתרום": true,
"נתרופ": true,
"נתרחב": true,
"נתרחץ": true,
"נתרחק": true,
"נתרחש": true,
"נתרטב": true,
"נתרים": true,
"נתריס": true,
"נתריע": true,
"נתרכב": true,
"נתרכז": true,
"נתרכך": true,
"נתרמה": true,
"נתרמו": true,
"נתרמת": true,
"נתרנה": true,
"נתרסן": true,
"נתרסק": true,
"נתרעם": true,
"נתרפא": true,
"נתרפה": true,
"נתרפט": true,
"נתרפס": true,
"נתרפק": true,
"נתרצה": true,
"נתרקם": true,
"נתרשל": true,
"נתרשם": true,
"נתרתח": true,
"נתשאל": true,
"סאבנה": true,
"סאברי": true,
"סאדאם": true,
"סאדאת": true,
"סאוטה": true,
"סאונד": true,
"סאונה": true,
"סאטמר": true,
"סאיהם": true,
"סאיהן": true,
"סאייך": true,
"סאיכם": true,
"סאיכן": true,
"סאינו": true,
"סאלאה": true,
"סאלאם": true,
"סאלים": true,
"סאנתי": true,
"סאנתם": true,
"סאנתן": true,
"סאפיר": true,
"סאקטא": true,
"סאתכם": true,
"סאתכן": true,
"סאתנו": true,
"סבאנה": true,
"סבאנו": true,
"סבארו": true,
"סבאתם": true,
"סבאתן": true,
"סבביה": true,
"סבביו": true,
"סבביי": true,
"סבביך": true,
"סבבים": true,
"סבבכם": true,
"סבבכן": true,
"סבבנה": true,
"סבבנו": true,
"סבבתי": true,
"סבבתם": true,
"סבבתן": true,
"סבוכה": true,
"סבולת": true,
"סבונה": true,
"סבונו": true,
"סבוני": true,
"סבונך": true,
"סבונם": true,
"סבונן": true,
"סבורה": true,
"סבותי": true,
"סבותם": true,
"סבותן": true,
"סביאה": true,
"סביבה": true,
"סביבו": true,
"סביבי": true,
"סביבך": true,
"סביבם": true,
"סביבן": true,
"סביבת": true,
"סביהם": true,
"סביהן": true,
"סביון": true,
"סבייך": true,
"סביכם": true,
"סביכן": true,
"סבילה": true,
"סבינו": true,
"סבירה": true,
"סבכות": true,
"סבכיה": true,
"סבכיו": true,
"סבכיי": true,
"סבכיך": true,
"סבכים": true,
"סבככם": true,
"סבככן": true,
"סבכנה": true,
"סבכנו": true,
"סבכתה": true,
"סבכתו": true,
"סבכתי": true,
"סבכתך": true,
"סבכתם": true,
"סבכתן": true,
"סבלות": true,
"סבליה": true,
"סבליו": true,
"סבליי": true,
"סבליך": true,
"סבלים": true,
"סבלית": true,
"סבלכם": true,
"סבלכן": true,
"סבלנו": true,
"סבלני": true,
"סבלתי": true,
"סבלתם": true,
"סבלתן": true,
"סבנגי": true,
"סבסדה": true,
"סבסדו": true,
"סבסדי": true,
"סבסדת": true,
"סבסוד": true,
"סברות": true,
"סברכם": true,
"סברכן": true,
"סברנה": true,
"סברנו": true,
"סברתה": true,
"סברתו": true,
"סברתי": true,
"סברתך": true,
"סברתם": true,
"סברתן": true,
"סבתות": true,
"סבתכם": true,
"סבתכן": true,
"סבתנו": true,
"סגדנו": true,
"סגדתי": true,
"סגדתם": true,
"סגדתן": true,
"סגולה": true,
"סגולי": true,
"סגורה": true,
"סגידה": true,
"סגירה": true,
"סגירת": true,
"סגלגל": true,
"סגליה": true,
"סגליו": true,
"סגליי": true,
"סגליך": true,
"סגלים": true,
"סגלכם": true,
"סגלכן": true,
"סגלנה": true,
"סגלנו": true,
"סגנון": true,
"סגנות": true,
"סגניה": true,
"סגניו": true,
"סגניי": true,
"סגניך": true,
"סגנים": true,
"סגנית": true,
"סגנכם": true,
"סגנכן": true,
"סגננה": true,
"סגננו": true,
"סגנני": true,
"סגננת": true,
"סגעיה": true,
"סגפני": true,
"סגריה": true,
"סגריו": true,
"סגריי": true,
"סגריך": true,
"סגרים": true,
"סגריר": true,
"סגרכם": true,
"סגרכן": true,
"סגרנו": true,
"סגרתי": true,
"סגרתם": true,
"סגרתן": true,
"סדבון": true,
"סדוקה": true,
"סדורה": true,
"סדיהם": true,
"סדיהן": true,
"סדיזם": true,
"סדייך": true,
"סדיכם": true,
"סדיכן": true,
"סדינה": true,
"סדינו": true,
"סדיני": true,
"סדינך": true,
"סדינם": true,
"סדינן": true,
"סדיסט": true,
"סדיקה": true,
"סדירה": true,
"סדניה": true,
"סדניו": true,
"סדניי": true,
"סדניך": true,
"סדנים": true,
"סדנכם": true,
"סדנכן": true,
"סדננו": true,
"סדנתה": true,
"סדנתו": true,
"סדנתי": true,
"סדנתך": true,
"סדנתם": true,
"סדנתן": true,
"סדקיה": true,
"סדקיו": true,
"סדקיי": true,
"סדקיך": true,
"סדקים": true,
"סדקית": true,
"סדקכם": true,
"סדקכן": true,
"סדקנו": true,
"סדקתי": true,
"סדקתם": true,
"סדקתן": true,
"סדרון": true,
"סדרות": true,
"סדריה": true,
"סדריו": true,
"סדריי": true,
"סדריך": true,
"סדרים": true,
"סדרכם": true,
"סדרכן": true,
"סדרנה": true,
"סדרנו": true,
"סדרני": true,
"סדרנך": true,
"סדרנם": true,
"סדרנן": true,
"סדרתה": true,
"סדרתו": true,
"סדרתי": true,
"סדרתך": true,
"סדרתם": true,
"סדרתן": true,
"סהדכם": true,
"סהדכן": true,
"סהדנו": true,
"סהרון": true,
"סהריה": true,
"סהריו": true,
"סהריי": true,
"סהריך": true,
"סהרים": true,
"סהרכם": true,
"סהרכן": true,
"סהרנו": true,
"סואבה": true,
"סואבו": true,
"סואבת": true,
"סואנת": true,
"סובאת": true,
"סובבה": true,
"סובבו": true,
"סובבי": true,
"סובבת": true,
"סובול": true,
"סוביה": true,
"סוביו": true,
"סוביי": true,
"סוביך": true,
"סובים": true,
"סובין": true,
"סובכה": true,
"סובכו": true,
"סובכת": true,
"סובלת": true,
"סובנה": true,
"סובנו": true,
"סובנת": true,
"סובסד": true,
"סוברת": true,
"סוגדת": true,
"סוגות": true,
"סוגיה": true,
"סוגיו": true,
"סוגיי": true,
"סוגיך": true,
"סוגים": true,
"סוגכם": true,
"סוגכן": true,
"סוגלה": true,
"סוגלו": true,
"סוגלת": true,
"סוגנו": true,
"סוגנן": true,
"סוגרה": true,
"סוגרו": true,
"סוגרי": true,
"סוגרך": true,
"סוגרם": true,
"סוגרן": true,
"סוגרת": true,
"סוגתה": true,
"סוגתו": true,
"סוגתי": true,
"סוגתך": true,
"סוגתם": true,
"סוגתן": true,
"סודאן": true,
"סודות": true,
"סודית": true,
"סודכם": true,
"סודכן": true,
"סודנה": true,
"סודנו": true,
"סודקת": true,
"סודרה": true,
"סודרו": true,
"סודרי": true,
"סודרך": true,
"סודרם": true,
"סודרן": true,
"סודרת": true,
"סוהרה": true,
"סוהרו": true,
"סוהרי": true,
"סוהרך": true,
"סוהרם": true,
"סוהרן": true,
"סוהרת": true,
"סווגה": true,
"סווגו": true,
"סווגי": true,
"סווגת": true,
"סוודר": true,
"סוונה": true,
"סוורה": true,
"סוורו": true,
"סוורי": true,
"סוורך": true,
"סוורם": true,
"סוורן": true,
"סוזנה": true,
"סוחבת": true,
"סוחטת": true,
"סוחפת": true,
"סוחרי": true,
"סוחרר": true,
"סוחרת": true,
"סוטול": true,
"סוטות": true,
"סוטים": true,
"סוטרת": true,
"סויגה": true,
"סויגו": true,
"סויגת": true,
"סוידה": true,
"סוידו": true,
"סוידת": true,
"סויוז": true,
"סויטה": true,
"סוימה": true,
"סוימו": true,
"סוימת": true,
"סויסה": true,
"סוכות": true,
"סוככה": true,
"סוככו": true,
"סוככי": true,
"סוככת": true,
"סוכלה": true,
"סוכלו": true,
"סוכלת": true,
"סוכמה": true,
"סוכמו": true,
"סוכמת": true,
"סוכנה": true,
"סוכנו": true,
"סוכני": true,
"סוכנך": true,
"סוכנם": true,
"סוכנן": true,
"סוכנת": true,
"סוכסך": true,
"סוכרה": true,
"סוכרו": true,
"סוכרי": true,
"סוכרך": true,
"סוכרם": true,
"סוכרן": true,
"סוכרת": true,
"סוכתה": true,
"סוכתו": true,
"סוכתי": true,
"סוכתך": true,
"סוכתם": true,
"סוכתן": true,
"סולאה": true,
"סולאו": true,
"סולאת": true,
"סולדת": true,
"סולחה": true,
"סולחת": true,
"סולטן": true,
"סוליה": true,
"סולימ": true,
"סוללה": true,
"סוללת": true,
"סולמה": true,
"סולמו": true,
"סולמי": true,
"סולמך": true,
"סולמם": true,
"סולמן": true,
"סולנה": true,
"סולנו": true,
"סולני": true,
"סולנך": true,
"סולנם": true,
"סולנן": true,
"סולסל": true,
"סולפה": true,
"סולפו": true,
"סולפת": true,
"סולקה": true,
"סולקו": true,
"סולקת": true,
"סולרי": true,
"סולתה": true,
"סולתו": true,
"סולתי": true,
"סולתך": true,
"סולתם": true,
"סולתן": true,
"סומאה": true,
"סומאו": true,
"סומאת": true,
"סומות": true,
"סומטי": true,
"סומים": true,
"סומכת": true,
"סומלה": true,
"סומלו": true,
"סומלי": true,
"סומלץ": true,
"סומלת": true,
"סוממה": true,
"סוממו": true,
"סוממת": true,
"סומנה": true,
"סומנו": true,
"סומנת": true,
"סומקה": true,
"סומקו": true,
"סומקי": true,
"סומקך": true,
"סומקם": true,
"סומקן": true,
"סומרה": true,
"סומרו": true,
"סומרת": true,
"סונדל": true,
"סונול": true,
"סונטה": true,
"סונטת": true,
"סוניה": true,
"סונים": true,
"סונית": true,
"סוננה": true,
"סוננו": true,
"סוננת": true,
"סונפה": true,
"סונפו": true,
"סונפת": true,
"סונתז": true,
"סוסון": true,
"סוסות": true,
"סוסיה": true,
"סוסיו": true,
"סוסיי": true,
"סוסיך": true,
"סוסים": true,
"סוסית": true,
"סוסכם": true,
"סוסכן": true,
"סוסנו": true,
"סוסתה": true,
"סוסתו": true,
"סוסתי": true,
"סוסתך": true,
"סוסתם": true,
"סוסתן": true,
"סועאד": true,
"סועדת": true,
"סוערת": true,
"סופגת": true,
"סופדת": true,
"סופות": true,
"סופחה": true,
"סופחו": true,
"סופחת": true,
"סופיה": true,
"סופיו": true,
"סופיי": true,
"סופיך": true,
"סופים": true,
"סופית": true,
"סופכם": true,
"סופכן": true,
"סופלה": true,
"סופנו": true,
"סופני": true,
"סופקה": true,
"סופקו": true,
"סופקת": true,
"סופרה": true,
"סופרו": true,
"סופרי": true,
"סופרן": true,
"סופרר": true,
"סופרת": true,
"סופתה": true,
"סופתו": true,
"סופתי": true,
"סופתך": true,
"סופתם": true,
"סופתן": true,
"סוציא": true,
"סוציו": true,
"סוקול": true,
"סוקלת": true,
"סוקרה": true,
"סוקרו": true,
"סוקרן": true,
"סוקרת": true,
"סוקתי": true,
"סורבה": true,
"סורבו": true,
"סורבל": true,
"סורבת": true,
"סורגה": true,
"סורגו": true,
"סורגי": true,
"סורגך": true,
"סורגם": true,
"סורגן": true,
"סורגת": true,
"סורדה": true,
"סורות": true,
"סורחת": true,
"סוריה": true,
"סוריז": true,
"סורים": true,
"סורית": true,
"סורכם": true,
"סורכן": true,
"סורנה": true,
"סורנו": true,
"סורני": true,
"סורסה": true,
"סורסו": true,
"סורסת": true,
"סורקה": true,
"סורקו": true,
"סורקת": true,
"סוררת": true,
"סותמת": true,
"סותרת": true,
"סותתה": true,
"סותתו": true,
"סותתי": true,
"סותתם": true,
"סותתן": true,
"סחבות": true,
"סחבנה": true,
"סחבנו": true,
"סחבקי": true,
"סחבתה": true,
"סחבתו": true,
"סחבתי": true,
"סחבתך": true,
"סחבתם": true,
"סחבתן": true,
"סחוטה": true,
"סחוסה": true,
"סחוסו": true,
"סחוסי": true,
"סחוסך": true,
"סחוסם": true,
"סחוסן": true,
"סחופה": true,
"סחורה": true,
"סחטנה": true,
"סחטנו": true,
"סחטני": true,
"סחטתי": true,
"סחטתם": true,
"סחטתן": true,
"סחיבה": true,
"סחיטה": true,
"סחיפה": true,
"סחירה": true,
"סחלבה": true,
"סחלבו": true,
"סחלבי": true,
"סחלבך": true,
"סחלבם": true,
"סחלבן": true,
"סחפכם": true,
"סחפכן": true,
"סחפנה": true,
"סחפנו": true,
"סחפתי": true,
"סחפתם": true,
"סחפתן": true,
"סחרוב": true,
"סחרור": true,
"סחרחר": true,
"סחרנה": true,
"סחרנו": true,
"סחררה": true,
"סחררו": true,
"סחררי": true,
"סחררת": true,
"סחרתי": true,
"סחרתם": true,
"סחרתן": true,
"סטארט": true,
"סטגרם": true,
"סטואה": true,
"סטואי": true,
"סטוצה": true,
"סטוצו": true,
"סטוצי": true,
"סטוצך": true,
"סטוצם": true,
"סטוצן": true,
"סטורה": true,
"סטטוס": true,
"סטטית": true,
"סטיאן": true,
"סטיבל": true,
"סטיבן": true,
"סטיות": true,
"סטייג": true,
"סטייה": true,
"סטייט": true,
"סטייל": true,
"סטיים": true,
"סטייק": true,
"סטילס": true,
"סטינה": true,
"סטינו": true,
"סטיקה": true,
"סטיקר": true,
"סטירה": true,
"סטירי": true,
"סטיתי": true,
"סטיתם": true,
"סטיתן": true,
"סטלין": true,
"סטנדר": true,
"סטנלי": true,
"סטפנו": true,
"סטקטו": true,
"סטראו": true,
"סטרוי": true,
"סטרול": true,
"סטרון": true,
"סטרופ": true,
"סטרוק": true,
"סטרטג": true,
"סטריט": true,
"סטרים": true,
"סטריק": true,
"סטרית": true,
"סטרלה": true,
"סטרנו": true,
"סטרתי": true,
"סטרתם": true,
"סטרתן": true,
"סיאבה": true,
"סיאבו": true,
"סיאבת": true,
"סיאוב": true,
"סיאול": true,
"סיאטל": true,
"סיאמי": true,
"סיאסה": true,
"סיבבה": true,
"סיבבו": true,
"סיבבת": true,
"סיבוב": true,
"סיבוך": true,
"סיבון": true,
"סיבות": true,
"סיביה": true,
"סיביו": true,
"סיביי": true,
"סיביך": true,
"סיבים": true,
"סיביר": true,
"סיבית": true,
"סיבכה": true,
"סיבכו": true,
"סיבכם": true,
"סיבכן": true,
"סיבכת": true,
"סיבנה": true,
"סיבנו": true,
"סיבנת": true,
"סיברה": true,
"סיברו": true,
"סיברת": true,
"סיבתה": true,
"סיבתו": true,
"סיבתי": true,
"סיבתך": true,
"סיבתם": true,
"סיבתן": true,
"סיגול": true,
"סיגוף": true,
"סיגיה": true,
"סיגיו": true,
"סיגיי": true,
"סיגיך": true,
"סיגים": true,
"סיגכם": true,
"סיגכן": true,
"סיגלה": true,
"סיגלו": true,
"סיגלי": true,
"סיגלך": true,
"סיגלם": true,
"סיגלן": true,
"סיגלת": true,
"סיגמא": true,
"סיגמה": true,
"סיגנו": true,
"סיגנל": true,
"סיגרה": true,
"סיגרו": true,
"סיגרי": true,
"סיגרך": true,
"סיגרם": true,
"סיגרן": true,
"סידור": true,
"סידכם": true,
"סידכן": true,
"סידנו": true,
"סידני": true,
"סידרה": true,
"סידרו": true,
"סידרת": true,
"סיוגה": true,
"סיוגו": true,
"סיוגי": true,
"סיוגך": true,
"סיוגם": true,
"סיוגן": true,
"סיודה": true,
"סיודו": true,
"סיודי": true,
"סיודך": true,
"סיודם": true,
"סיודן": true,
"סיווג": true,
"סיוון": true,
"סיוטה": true,
"סיוטו": true,
"סיוטי": true,
"סיוטך": true,
"סיוטם": true,
"סיוטן": true,
"סיומה": true,
"סיומו": true,
"סיומי": true,
"סיומך": true,
"סיומם": true,
"סיומן": true,
"סיומת": true,
"סיועה": true,
"סיועו": true,
"סיועי": true,
"סיועך": true,
"סיועם": true,
"סיוען": true,
"סיופה": true,
"סיופו": true,
"סיופי": true,
"סיופך": true,
"סיופם": true,
"סיופן": true,
"סיורה": true,
"סיורו": true,
"סיורי": true,
"סיורך": true,
"סיורם": true,
"סיורן": true,
"סיטון": true,
"סייבר": true,
"סייגה": true,
"סייגו": true,
"סייגי": true,
"סייגך": true,
"סייגם": true,
"סייגן": true,
"סייגת": true,
"סיידא": true,
"סיידה": true,
"סיידו": true,
"סיידי": true,
"סיידך": true,
"סיידם": true,
"סיידן": true,
"סיידר": true,
"סיידת": true,
"סייחה": true,
"סייחו": true,
"סייחי": true,
"סייחך": true,
"סייחם": true,
"סייחן": true,
"סיימה": true,
"סיימו": true,
"סיימי": true,
"סיימת": true,
"סיינט": true,
"סייסה": true,
"סייסו": true,
"סייסי": true,
"סייסך": true,
"סייסם": true,
"סייסן": true,
"סייסת": true,
"סייעה": true,
"סייעו": true,
"סייעי": true,
"סייעך": true,
"סייעם": true,
"סייען": true,
"סייעת": true,
"סייפה": true,
"סייפו": true,
"סייפי": true,
"סייפך": true,
"סייפם": true,
"סייפן": true,
"סייפת": true,
"סיירה": true,
"סיירו": true,
"סיירי": true,
"סיירך": true,
"סיירם": true,
"סיירן": true,
"סיירת": true,
"סיישל": true,
"סיכוי": true,
"סיכוך": true,
"סיכול": true,
"סיכום": true,
"סיכון": true,
"סיכות": true,
"סיככה": true,
"סיככו": true,
"סיככת": true,
"סיכלה": true,
"סיכלו": true,
"סיכלת": true,
"סיכמה": true,
"סיכמו": true,
"סיכמת": true,
"סיכנה": true,
"סיכנו": true,
"סיכנת": true,
"סיכתה": true,
"סיכתו": true,
"סיכתי": true,
"סיכתך": true,
"סיכתם": true,
"סיכתן": true,
"סילאן": true,
"סילבן": true,
"סילבר": true,
"סילון": true,
"סילוף": true,
"סילוק": true,
"סילפה": true,
"סילפו": true,
"סילפת": true,
"סילקה": true,
"סילקו": true,
"סילקת": true,
"סימאה": true,
"סימאו": true,
"סימאת": true,
"סימבה": true,
"סימוי": true,
"סימול": true,
"סימום": true,
"סימון": true,
"סימור": true,
"סימטת": true,
"סימלה": true,
"סימלו": true,
"סימלי": true,
"סימלת": true,
"סיממה": true,
"סיממו": true,
"סיממת": true,
"סימנה": true,
"סימנו": true,
"סימני": true,
"סימנך": true,
"סימנם": true,
"סימנן": true,
"סימנת": true,
"סימפט": true,
"סימרה": true,
"סימרו": true,
"סימרת": true,
"סינאן": true,
"סינגל": true,
"סינדי": true,
"סינוד": true,
"סינון": true,
"סינוס": true,
"סינוף": true,
"סינות": true,
"סינטה": true,
"סיניה": true,
"סינים": true,
"סינית": true,
"סינמה": true,
"סיננה": true,
"סיננו": true,
"סיננת": true,
"סינפה": true,
"סינפו": true,
"סינפת": true,
"סינרה": true,
"סינרו": true,
"סינרי": true,
"סינרך": true,
"סינרם": true,
"סינרן": true,
"סיסים": true,
"סיסמא": true,
"סיסמה": true,
"סיסקו": true,
"סיעוד": true,
"סיעור": true,
"סיעות": true,
"סיעתה": true,
"סיעתו": true,
"סיעתי": true,
"סיעתך": true,
"סיעתם": true,
"סיעתן": true,
"סיפוח": true,
"סיפון": true,
"סיפוק": true,
"סיפור": true,
"סיפות": true,
"סיפחה": true,
"סיפחו": true,
"סיפחת": true,
"סיפכם": true,
"סיפכן": true,
"סיפנו": true,
"סיפקה": true,
"סיפקו": true,
"סיפקת": true,
"סיפרה": true,
"סיפרו": true,
"סיפרת": true,
"סיפתה": true,
"סיפתו": true,
"סיפתי": true,
"סיפתך": true,
"סיפתם": true,
"סיפתן": true,
"סיקול": true,
"סיקון": true,
"סיקור": true,
"סיקות": true,
"סיקים": true,
"סיקית": true,
"סיקלה": true,
"סיקלו": true,
"סיקלת": true,
"סיקסק": true,
"סיקרה": true,
"סיקרו": true,
"סיקרי": true,
"סיקרת": true,
"סירבה": true,
"סירבו": true,
"סירבת": true,
"סירוב": true,
"סירוג": true,
"סירוס": true,
"סירופ": true,
"סירוק": true,
"סירות": true,
"סיריה": true,
"סיריו": true,
"סיריי": true,
"סיריך": true,
"סיריל": true,
"סירים": true,
"סירכם": true,
"סירכן": true,
"סירנה": true,
"סירנו": true,
"סירני": true,
"סירסה": true,
"סירסו": true,
"סירסת": true,
"סירקה": true,
"סירקו": true,
"סירקת": true,
"סירתה": true,
"סירתו": true,
"סירתי": true,
"סירתך": true,
"סירתם": true,
"סירתן": true,
"סיתות": true,
"סיתתה": true,
"סיתתו": true,
"סיתתי": true,
"סיתתם": true,
"סיתתן": true,
"סכומה": true,
"סכומו": true,
"סכומי": true,
"סכומך": true,
"סכומם": true,
"סכומן": true,
"סכורה": true,
"סכימה": true,
"סכינה": true,
"סכינו": true,
"סכיני": true,
"סכינך": true,
"סכינם": true,
"סכינן": true,
"סכירה": true,
"סככות": true,
"סככנה": true,
"סככנו": true,
"סככתה": true,
"סככתו": true,
"סככתי": true,
"סככתך": true,
"סככתם": true,
"סככתן": true,
"סכלות": true,
"סכלים": true,
"סכלנה": true,
"סכמות": true,
"סכמטי": true,
"סכמית": true,
"סכמנה": true,
"סכמנו": true,
"סכמתה": true,
"סכמתו": true,
"סכמתי": true,
"סכמתך": true,
"סכמתם": true,
"סכמתן": true,
"סכנות": true,
"סכנין": true,
"סכנתה": true,
"סכנתו": true,
"סכנתי": true,
"סכנתך": true,
"סכנתם": true,
"סכנתן": true,
"סכסוך": true,
"סכסכה": true,
"סכסכו": true,
"סכסכי": true,
"סכסכן": true,
"סכסכת": true,
"סכריה": true,
"סכריו": true,
"סכריי": true,
"סכריך": true,
"סכרים": true,
"סכרין": true,
"סכרכם": true,
"סכרכן": true,
"סכרנו": true,
"סכרתי": true,
"סכרתם": true,
"סכרתן": true,
"סלאמה": true,
"סלאנט": true,
"סלארי": true,
"סלבים": true,
"סלבית": true,
"סלדנו": true,
"סלדתי": true,
"סלדתם": true,
"סלדתן": true,
"סלהוב": true,
"סלוגן": true,
"סלולה": true,
"סלולר": true,
"סלונה": true,
"סלונו": true,
"סלוני": true,
"סלונך": true,
"סלונם": true,
"סלונן": true,
"סלחנה": true,
"סלחנו": true,
"סלחני": true,
"סלחתי": true,
"סלחתם": true,
"סלחתן": true,
"סלטות": true,
"סלטיה": true,
"סלטיו": true,
"סלטיי": true,
"סלטיך": true,
"סלטים": true,
"סלטכם": true,
"סלטכן": true,
"סלטנו": true,
"סלטקס": true,
"סלידה": true,
"סליהם": true,
"סליהן": true,
"סליחה": true,
"סלייך": true,
"סליכם": true,
"סליכן": true,
"סלילה": true,
"סלילו": true,
"סלילי": true,
"סלילך": true,
"סלילם": true,
"סלילן": true,
"סלילת": true,
"סלינו": true,
"סליקה": true,
"סללנו": true,
"סללתי": true,
"סללתם": true,
"סללתן": true,
"סלמאן": true,
"סלמון": true,
"סלמים": true,
"סלנגי": true,
"סלסול": true,
"סלסלה": true,
"סלסלו": true,
"סלסלי": true,
"סלסלת": true,
"סלעיה": true,
"סלעיו": true,
"סלעיי": true,
"סלעיך": true,
"סלעים": true,
"סלעית": true,
"סלעכם": true,
"סלעכן": true,
"סלענו": true,
"סלפטר": true,
"סלפיה": true,
"סלפים": true,
"סלפית": true,
"סלפנה": true,
"סלקום": true,
"סלקיה": true,
"סלקיו": true,
"סלקיי": true,
"סלקיך": true,
"סלקים": true,
"סלקכם": true,
"סלקכן": true,
"סלקנה": true,
"סלקנו": true,
"סלתות": true,
"סמאנה": true,
"סמארט": true,
"סמואה": true,
"סמואל": true,
"סמובר": true,
"סמוחה": true,
"סמויה": true,
"סמוכה": true,
"סמוקה": true,
"סמורה": true,
"סמורו": true,
"סמורי": true,
"סמורך": true,
"סמורם": true,
"סמורן": true,
"סמטתה": true,
"סמטתו": true,
"סמטתי": true,
"סמטתך": true,
"סמטתם": true,
"סמטתן": true,
"סמיהם": true,
"סמיהן": true,
"סמייה": true,
"סמייך": true,
"סמיכה": true,
"סמיכם": true,
"סמיכן": true,
"סמינו": true,
"סמינר": true,
"סמירה": true,
"סמכות": true,
"סמכנו": true,
"סמכתי": true,
"סמכתם": true,
"סמכתן": true,
"סמלוץ": true,
"סמלות": true,
"סמליה": true,
"סמליו": true,
"סמליי": true,
"סמליך": true,
"סמליל": true,
"סמלים": true,
"סמלית": true,
"סמלכם": true,
"סמלכן": true,
"סמלנה": true,
"סמלנו": true,
"סמלצה": true,
"סמלצו": true,
"סמלצי": true,
"סמלצת": true,
"סמלתה": true,
"סמלתו": true,
"סמלתי": true,
"סמלתך": true,
"סמלתם": true,
"סמלתן": true,
"סממנה": true,
"סממנו": true,
"סממני": true,
"סממנך": true,
"סממנם": true,
"סממנן": true,
"סמנטי": true,
"סמניה": true,
"סמניו": true,
"סמניי": true,
"סמניך": true,
"סמנים": true,
"סמנכל": true,
"סמנכם": true,
"סמנכן": true,
"סמננו": true,
"סמסטר": true,
"סמפון": true,
"סמרנה": true,
"סמרנו": true,
"סמרתי": true,
"סמרתם": true,
"סמרתן": true,
"סנאיה": true,
"סנאיו": true,
"סנאיי": true,
"סנאיך": true,
"סנאים": true,
"סנאפל": true,
"סנגור": true,
"סנגלי": true,
"סנגרה": true,
"סנגרו": true,
"סנגרי": true,
"סנגרת": true,
"סנדול": true,
"סנדיי": true,
"סנדלה": true,
"סנדלו": true,
"סנדלי": true,
"סנדלך": true,
"סנדלם": true,
"סנדלן": true,
"סנדלר": true,
"סנדלת": true,
"סנדקה": true,
"סנדקו": true,
"סנדקי": true,
"סנדקך": true,
"סנדקם": true,
"סנדקן": true,
"סנדרו": true,
"סנואר": true,
"סנובי": true,
"סנוור": true,
"סנוקר": true,
"סנטוס": true,
"סנטור": true,
"סנטימ": true,
"סנטנו": true,
"סנטרה": true,
"סנטרו": true,
"סנטרי": true,
"סנטרך": true,
"סנטרם": true,
"סנטרן": true,
"סנטתי": true,
"סנטתם": true,
"סנטתן": true,
"סניור": true,
"סניטה": true,
"סניטר": true,
"סנייה": true,
"סנייך": true,
"סניים": true,
"סניין": true,
"סנילי": true,
"סנינא": true,
"סנינה": true,
"סניפה": true,
"סניפו": true,
"סניפי": true,
"סניפך": true,
"סניפם": true,
"סניפן": true,
"סנכרן": true,
"סנסור": true,
"סנפיר": true,
"סנפנה": true,
"סנתוז": true,
"סנתזה": true,
"סנתזו": true,
"סנתזי": true,
"סנתזת": true,
"ססלוב": true,
"ססמות": true,
"ססמתה": true,
"ססמתו": true,
"ססמתי": true,
"ססמתך": true,
"ססמתם": true,
"ססמתן": true,
"סעדאת": true,
"סעדון": true,
"סעדיה": true,
"סעדכם": true,
"סעדכן": true,
"סעדנה": true,
"סעדנו": true,
"סעדתי": true,
"סעדתם": true,
"סעדתן": true,
"סעודה": true,
"סעודי": true,
"סעודת": true,
"סעיפה": true,
"סעיפו": true,
"סעיפי": true,
"סעיפך": true,
"סעיפם": true,
"סעיפן": true,
"סערות": true,
"סערנה": true,
"סערנו": true,
"סערתה": true,
"סערתו": true,
"סערתי": true,
"סערתך": true,
"סערתם": true,
"סערתן": true,
"ספארי": true,
"ספגטי": true,
"ספגנו": true,
"ספגתי": true,
"ספגתם": true,
"ספגתן": true,
"ספדיה": true,
"ספדנו": true,
"ספדתי": true,
"ספדתם": true,
"ספדתן": true,
"ספוגה": true,
"ספוגו": true,
"ספוגי": true,
"ספוגך": true,
"ספוגם": true,
"ספוגן": true,
"ספוטה": true,
"ספונה": true,
"ספונט": true,
"ספוקה": true,
"ספורה": true,
"ספורט": true,
"ספחות": true,
"ספחיה": true,
"ספחיו": true,
"ספחיי": true,
"ספחיך": true,
"ספחים": true,
"ספחכם": true,
"ספחכן": true,
"ספחנה": true,
"ספחנו": true,
"ספחתה": true,
"ספחתו": true,
"ספחתי": true,
"ספחתך": true,
"ספחתם": true,
"ספחתן": true,
"ספיבק": true,
"ספיגה": true,
"ספיגת": true,
"ספיהם": true,
"ספיהן": true,
"ספיחה": true,
"ספיחו": true,
"ספיחי": true,
"ספיחך": true,
"ספיחם": true,
"ספיחן": true,
"ספייך": true,
"ספייס": true,
"ספיכם": true,
"ספיכן": true,
"ספינה": true,
"ספינו": true,
"ספינת": true,
"ספיקה": true,
"ספיקת": true,
"ספירה": true,
"ספירו": true,
"ספירי": true,
"ספירך": true,
"ספירם": true,
"ספירן": true,
"ספירת": true,
"ספלון": true,
"ספליה": true,
"ספליו": true,
"ספליי": true,
"ספליך": true,
"ספלים": true,
"ספלכם": true,
"ספלכן": true,
"ספלנו": true,
"ספנות": true,
"ספניה": true,
"ספניו": true,
"ספניי": true,
"ספניך": true,
"ספנים": true,
"ספנית": true,
"ספנכם": true,
"ספנכן": true,
"ספננו": true,
"ספסור": true,
"ספסלה": true,
"ספסלו": true,
"ספסלי": true,
"ספסלך": true,
"ספסלם": true,
"ספסלן": true,
"ספסרה": true,
"ספסרו": true,
"ספסרי": true,
"ספסרך": true,
"ספסרם": true,
"ספסרן": true,
"ספסרת": true,
"ספציפ": true,
"ספקות": true,
"ספקיה": true,
"ספקיו": true,
"ספקיי": true,
"ספקיך": true,
"ספקים": true,
"ספקית": true,
"ספקכם": true,
"ספקכן": true,
"ספקנה": true,
"ספקנו": true,
"ספקני": true,
"ספקתי": true,
"ספקתם": true,
"ספקתן": true,
"ספרדי": true,
"ספרון": true,
"ספרור": true,
"ספרות": true,
"ספרטה": true,
"ספריה": true,
"ספריו": true,
"ספריי": true,
"ספריך": true,
"ספרים": true,
"ספרין": true,
"ספרית": true,
"ספרכם": true,
"ספרכן": true,
"ספרנה": true,
"ספרנו": true,
"ספרני": true,
"ספרנך": true,
"ספרנם": true,
"ספרנן": true,
"ספררה": true,
"ספררו": true,
"ספררי": true,
"ספררת": true,
"ספרתה": true,
"ספרתו": true,
"ספרתי": true,
"ספרתך": true,
"ספרתם": true,
"ספרתן": true,
"ספתכם": true,
"ספתכן": true,
"ספתנו": true,
"סצואן": true,
"סצינה": true,
"סצנות": true,
"סצנתה": true,
"סצנתו": true,
"סצנתי": true,
"סצנתך": true,
"סצנתם": true,
"סצנתן": true,
"סקוור": true,
"סקוטי": true,
"סקולה": true,
"סקופה": true,
"סקופו": true,
"סקופי": true,
"סקופך": true,
"סקופם": true,
"סקופן": true,
"סקורה": true,
"סקטור": true,
"סקייט": true,
"סקילה": true,
"סקיצה": true,
"סקירה": true,
"סקירת": true,
"סקלוס": true,
"סקלות": true,
"סקלנה": true,
"סקלנו": true,
"סקלרי": true,
"סקלתי": true,
"סקלתם": true,
"סקלתן": true,
"סקנדל": true,
"סקנות": true,
"סקסטה": true,
"סקסית": true,
"סקפטי": true,
"סקציה": true,
"סקרון": true,
"סקריה": true,
"סקריו": true,
"סקריי": true,
"סקריך": true,
"סקרים": true,
"סקרכם": true,
"סקרכן": true,
"סקרנה": true,
"סקרנו": true,
"סקרני": true,
"סקרנת": true,
"סקרתי": true,
"סקרתם": true,
"סקרתן": true,
"סרבול": true,
"סרביה": true,
"סרבים": true,
"סרבית": true,
"סרבלה": true,
"סרבלו": true,
"סרבלי": true,
"סרבלך": true,
"סרבלם": true,
"סרבלן": true,
"סרבלת": true,
"סרבנה": true,
"סרבני": true,
"סרגיו": true,
"סרגיי": true,
"סרגלה": true,
"סרגלו": true,
"סרגלי": true,
"סרגלך": true,
"סרגלם": true,
"סרגלן": true,
"סרגנו": true,
"סרגתי": true,
"סרגתם": true,
"סרגתן": true,
"סרדין": true,
"סרוגה": true,
"סרוחה": true,
"סרוקה": true,
"סרחאן": true,
"סרחון": true,
"סרחיה": true,
"סרחיו": true,
"סרחיי": true,
"סרחיך": true,
"סרחים": true,
"סרחכם": true,
"סרחכן": true,
"סרחנה": true,
"סרחנו": true,
"סרחתי": true,
"סרחתם": true,
"סרחתן": true,
"סרטבה": true,
"סרטון": true,
"סרטיה": true,
"סרטיו": true,
"סרטיי": true,
"סרטיך": true,
"סרטים": true,
"סרטכם": true,
"סרטכן": true,
"סרטנה": true,
"סרטנו": true,
"סרטני": true,
"סרטנך": true,
"סרטנם": true,
"סרטנן": true,
"סרטנת": true,
"סריגה": true,
"סריגו": true,
"סריגי": true,
"סריגך": true,
"סריגם": true,
"סריגן": true,
"סריחה": true,
"סריסה": true,
"סריסו": true,
"סריסי": true,
"סריסך": true,
"סריסם": true,
"סריסן": true,
"סריקה": true,
"סריקת": true,
"סרכות": true,
"סרכזת": true,
"סרכתה": true,
"סרכתו": true,
"סרכתי": true,
"סרכתך": true,
"סרכתם": true,
"סרכתן": true,
"סרנדה": true,
"סרניה": true,
"סרניו": true,
"סרניי": true,
"סרניך": true,
"סרנים": true,
"סרנכם": true,
"סרנכן": true,
"סרננו": true,
"סרסור": true,
"סרסנה": true,
"סרסרה": true,
"סרסרו": true,
"סרסרי": true,
"סרסרת": true,
"סרעפת": true,
"סרפדה": true,
"סרפדו": true,
"סרפדי": true,
"סרפדך": true,
"סרפדם": true,
"סרפדן": true,
"סרקזם": true,
"סרקנה": true,
"סרקנו": true,
"סרקתי": true,
"סרקתם": true,
"סרקתן": true,
"סררנו": true,
"סררתי": true,
"סררתם": true,
"סררתן": true,
"סשימי": true,
"סתגלן": true,
"סתודד": true,
"סתווה": true,
"סתווי": true,
"סתווך": true,
"סתוום": true,
"סתוון": true,
"סתומה": true,
"סתורה": true,
"סתימה": true,
"סתירה": true,
"סתלבט": true,
"סתמית": true,
"סתמנו": true,
"סתמתי": true,
"סתמתם": true,
"סתמתן": true,
"סתריה": true,
"סתריו": true,
"סתריי": true,
"סתריך": true,
"סתרים": true,
"סתרכם": true,
"סתרכן": true,
"סתרנו": true,
"סתרתי": true,
"סתרתם": true,
"סתרתן": true,
"סתתות": true,
"סתתיה": true,
"סתתיו": true,
"סתתיי": true,
"סתתיך": true,
"סתתים": true,
"סתתכם": true,
"סתתכן": true,
"סתתנה": true,
"סתתנו": true,
"סתתתה": true,
"סתתתו": true,
"סתתתי": true,
"סתתתך": true,
"סתתתם": true,
"סתתתן": true,
"עאדרי": true,
"עאסלה": true,
"עארדה": true,
"עבאדי": true,
"עבאסי": true,
"עבדול": true,
"עבדות": true,
"עבדיה": true,
"עבדיו": true,
"עבדיי": true,
"עבדיך": true,
"עבדים": true,
"עבדכם": true,
"עבדכן": true,
"עבדנה": true,
"עבדנו": true,
"עבדקן": true,
"עבדתי": true,
"עבדתם": true,
"עבדתן": true,
"עבודה": true,
"עבודת": true,
"עבוטה": true,
"עבוטו": true,
"עבוטי": true,
"עבוטך": true,
"עבוטם": true,
"עבוטן": true,
"עבורה": true,
"עבורו": true,
"עבורי": true,
"עבורך": true,
"עבורם": true,
"עבורן": true,
"עבותה": true,
"עבותו": true,
"עבותי": true,
"עבותך": true,
"עבותם": true,
"עבותן": true,
"עבטנו": true,
"עבטתי": true,
"עבטתם": true,
"עבטתן": true,
"עביאת": true,
"עביהם": true,
"עביהן": true,
"עבייך": true,
"עביכם": true,
"עביכן": true,
"עבינה": true,
"עבינו": true,
"עבירה": true,
"עבירת": true,
"עברון": true,
"עברות": true,
"עבריה": true,
"עבריו": true,
"עבריי": true,
"עבריך": true,
"עברים": true,
"עברית": true,
"עברכם": true,
"עברכן": true,
"עברנה": true,
"עברנו": true,
"עברתה": true,
"עברתו": true,
"עברתי": true,
"עברתך": true,
"עברתם": true,
"עברתן": true,
"עבשות": true,
"עבשים": true,
"עגביה": true,
"עגביו": true,
"עגביי": true,
"עגביך": true,
"עגבים": true,
"עגבנו": true,
"עגבני": true,
"עגבתה": true,
"עגבתו": true,
"עגבתי": true,
"עגבתך": true,
"עגבתם": true,
"עגבתן": true,
"עגולה": true,
"עגומה": true,
"עגונה": true,
"עגורה": true,
"עגורו": true,
"עגורי": true,
"עגורך": true,
"עגורם": true,
"עגורן": true,
"עגילה": true,
"עגילו": true,
"עגילי": true,
"עגילך": true,
"עגילם": true,
"עגילן": true,
"עגינה": true,
"עגלגל": true,
"עגלון": true,
"עגלות": true,
"עגליה": true,
"עגליו": true,
"עגליי": true,
"עגליך": true,
"עגלים": true,
"עגלכם": true,
"עגלכן": true,
"עגלנה": true,
"עגלנו": true,
"עגלתה": true,
"עגלתו": true,
"עגלתי": true,
"עגלתך": true,
"עגלתם": true,
"עגלתן": true,
"עגמות": true,
"עגמתה": true,
"עגמתו": true,
"עגמתי": true,
"עגמתך": true,
"עגמתם": true,
"עגמתן": true,
"עגנון": true,
"עגנתי": true,
"עגנתם": true,
"עגנתן": true,
"עגתכם": true,
"עגתכן": true,
"עגתנו": true,
"עדאלה": true,
"עדואן": true,
"עדולם": true,
"עדורה": true,
"עדותה": true,
"עדותו": true,
"עדותי": true,
"עדותך": true,
"עדותם": true,
"עדותן": true,
"עדיאל": true,
"עדיהם": true,
"עדיהן": true,
"עדיות": true,
"עדייה": true,
"עדייו": true,
"עדייך": true,
"עדיים": true,
"עדיין": true,
"עדיכם": true,
"עדיכן": true,
"עדינה": true,
"עדינו": true,
"עדיפה": true,
"עדירה": true,
"עדכון": true,
"עדכנה": true,
"עדכנו": true,
"עדכני": true,
"עדכנת": true,
"עדנות": true,
"עדנתה": true,
"עדנתו": true,
"עדנתי": true,
"עדנתך": true,
"עדנתם": true,
"עדנתן": true,
"עדפות": true,
"עדריה": true,
"עדריו": true,
"עדריי": true,
"עדריך": true,
"עדרים": true,
"עדרית": true,
"עדרכם": true,
"עדרכן": true,
"עדרנו": true,
"עדרתי": true,
"עדרתם": true,
"עדרתן": true,
"עדשות": true,
"עדשיה": true,
"עדשיו": true,
"עדשיי": true,
"עדשיך": true,
"עדשים": true,
"עדשתה": true,
"עדשתו": true,
"עדשתי": true,
"עדשתך": true,
"עדשתם": true,
"עדשתן": true,
"עדתית": true,
"עדתכם": true,
"עדתכן": true,
"עדתנו": true,
"עובדה": true,
"עובדו": true,
"עובדי": true,
"עובדך": true,
"עובדם": true,
"עובדן": true,
"עובדת": true,
"עובטת": true,
"עוביו": true,
"עוביי": true,
"עובית": true,
"עוברה": true,
"עוברו": true,
"עוברי": true,
"עוברך": true,
"עוברם": true,
"עוברן": true,
"עוברת": true,
"עובשה": true,
"עובשו": true,
"עובשי": true,
"עובשך": true,
"עובשם": true,
"עובשן": true,
"עובתה": true,
"עוגבה": true,
"עוגבו": true,
"עוגבי": true,
"עוגבך": true,
"עוגבם": true,
"עוגבן": true,
"עוגבת": true,
"עוגות": true,
"עוגיה": true,
"עוגלה": true,
"עוגלו": true,
"עוגלת": true,
"עוגמה": true,
"עוגמת": true,
"עוגנה": true,
"עוגנו": true,
"עוגני": true,
"עוגנת": true,
"עוגתה": true,
"עוגתו": true,
"עוגתי": true,
"עוגתך": true,
"עוגתם": true,
"עוגתן": true,
"עודדה": true,
"עודדו": true,
"עודדי": true,
"עודדת": true,
"עודכם": true,
"עודכן": true,
"עודנה": true,
"עודנו": true,
"עודני": true,
"עודנת": true,
"עודפה": true,
"עודפו": true,
"עודפי": true,
"עודפך": true,
"עודפם": true,
"עודפן": true,
"עודפת": true,
"עודרת": true,
"עוואן": true,
"עווית": true,
"עוולה": true,
"עוולו": true,
"עוולי": true,
"עוולך": true,
"עוולם": true,
"עוולן": true,
"עוונה": true,
"עוונו": true,
"עווני": true,
"עוונך": true,
"עוונם": true,
"עוונן": true,
"עוורה": true,
"עוורו": true,
"עוורי": true,
"עוורת": true,
"עוותה": true,
"עוותו": true,
"עוותי": true,
"עוותם": true,
"עוותן": true,
"עוזאל": true,
"עוזבת": true,
"עוזיה": true,
"עוזיו": true,
"עוזיי": true,
"עוזיך": true,
"עוזים": true,
"עוזכם": true,
"עוזכן": true,
"עוזנו": true,
"עוזני": true,
"עוזרה": true,
"עוזרו": true,
"עוזרי": true,
"עוזרך": true,
"עוזרם": true,
"עוזרן": true,
"עוזרר": true,
"עוזרת": true,
"עוטות": true,
"עוטים": true,
"עוטנה": true,
"עוטפת": true,
"עוטרה": true,
"עוטרו": true,
"עוטרת": true,
"עוינת": true,
"עוכבה": true,
"עוכבו": true,
"עוכבת": true,
"עוכלה": true,
"עוכלו": true,
"עוכלת": true,
"עוכרת": true,
"עולבת": true,
"עולות": true,
"עולזת": true,
"עוליה": true,
"עוליו": true,
"עוליי": true,
"עוליך": true,
"עולים": true,
"עולכם": true,
"עולכן": true,
"עוללה": true,
"עוללו": true,
"עוללי": true,
"עוללך": true,
"עוללם": true,
"עוללן": true,
"עוללת": true,
"עולמה": true,
"עולמו": true,
"עולמי": true,
"עולמך": true,
"עולמם": true,
"עולמן": true,
"עולנו": true,
"עולעל": true,
"עולצת": true,
"עולשה": true,
"עולשו": true,
"עולשי": true,
"עולשך": true,
"עולשם": true,
"עולשן": true,
"עולתה": true,
"עולתו": true,
"עולתי": true,
"עולתך": true,
"עולתם": true,
"עולתן": true,
"עומאן": true,
"עומאר": true,
"עומדה": true,
"עומדו": true,
"עומדת": true,
"עומלן": true,
"עוממה": true,
"עוממו": true,
"עוממת": true,
"עומסה": true,
"עומסו": true,
"עומסי": true,
"עומסך": true,
"עומסם": true,
"עומסן": true,
"עומעם": true,
"עומקה": true,
"עומקו": true,
"עומקי": true,
"עומקך": true,
"עומקם": true,
"עומקן": true,
"עומרה": true,
"עומרו": true,
"עומרי": true,
"עומרך": true,
"עומרם": true,
"עומרן": true,
"עומתה": true,
"עומתו": true,
"עומתי": true,
"עומתם": true,
"עומתן": true,
"עונבת": true,
"עונגה": true,
"עונגו": true,
"עונגי": true,
"עונגך": true,
"עונגם": true,
"עונגן": true,
"עונגת": true,
"עונדת": true,
"עונות": true,
"עוניו": true,
"עוניי": true,
"עונים": true,
"עונין": true,
"עונית": true,
"עונשה": true,
"עונשו": true,
"עונשי": true,
"עונשך": true,
"עונשם": true,
"עונשן": true,
"עונתה": true,
"עונתו": true,
"עונתי": true,
"עונתך": true,
"עונתם": true,
"עונתן": true,
"עוסית": true,
"עוסמה": true,
"עוסקת": true,
"עוסתה": true,
"עופות": true,
"עופיו": true,
"עופיי": true,
"עופכם": true,
"עופכן": true,
"עופנה": true,
"עופנו": true,
"עופפה": true,
"עופפו": true,
"עופפי": true,
"עופפת": true,
"עופרה": true,
"עופרו": true,
"עופרי": true,
"עופרך": true,
"עופרם": true,
"עופרן": true,
"עופרת": true,
"עופשה": true,
"עופשו": true,
"עופשת": true,
"עוצבב": true,
"עוצבה": true,
"עוצבו": true,
"עוצבן": true,
"עוצבת": true,
"עוצמה": true,
"עוצמת": true,
"עוצנה": true,
"עוצרי": true,
"עוצרת": true,
"עוקבת": true,
"עוקדן": true,
"עוקדת": true,
"עוקלה": true,
"עוקלו": true,
"עוקלת": true,
"עוקמה": true,
"עוקמו": true,
"עוקמי": true,
"עוקמך": true,
"עוקמם": true,
"עוקמן": true,
"עוקמת": true,
"עוקפת": true,
"עוקצה": true,
"עוקצו": true,
"עוקצי": true,
"עוקצך": true,
"עוקצם": true,
"עוקצן": true,
"עוקצת": true,
"עוקרה": true,
"עוקרו": true,
"עוקרת": true,
"עורבא": true,
"עורבב": true,
"עורבה": true,
"עורבו": true,
"עורבי": true,
"עורבך": true,
"עורבל": true,
"עורבם": true,
"עורבן": true,
"עורבת": true,
"עורגל": true,
"עורגת": true,
"עורות": true,
"עורטל": true,
"עורית": true,
"עורכה": true,
"עורכו": true,
"עורכי": true,
"עורכך": true,
"עורכם": true,
"עורכן": true,
"עורכת": true,
"עורלה": true,
"עורמה": true,
"עורמת": true,
"עורנו": true,
"עורער": true,
"עורפה": true,
"עורפו": true,
"עורפי": true,
"עורפך": true,
"עורפל": true,
"עורפם": true,
"עורפן": true,
"עורפת": true,
"עורקה": true,
"עורקו": true,
"עורקי": true,
"עורקך": true,
"עורקם": true,
"עורקן": true,
"עורקת": true,
"עוררה": true,
"עוררו": true,
"עוררי": true,
"עוררך": true,
"עוררם": true,
"עוררן": true,
"עוררת": true,
"עושות": true,
"עושים": true,
"עושין": true,
"עושקת": true,
"עושרה": true,
"עושרו": true,
"עושרי": true,
"עושרך": true,
"עושרם": true,
"עושרן": true,
"עותיו": true,
"עותקה": true,
"עותקו": true,
"עותקי": true,
"עותקך": true,
"עותקם": true,
"עותקן": true,
"עותרת": true,
"עזאזל": true,
"עזבון": true,
"עזבנו": true,
"עזבתי": true,
"עזבתם": true,
"עזבתן": true,
"עזובה": true,
"עזיבה": true,
"עזיבת": true,
"עזיהם": true,
"עזיהן": true,
"עזיזה": true,
"עזייך": true,
"עזיכם": true,
"עזיכן": true,
"עזינו": true,
"עזניה": true,
"עזרות": true,
"עזריה": true,
"עזריו": true,
"עזריי": true,
"עזריך": true,
"עזרים": true,
"עזרכם": true,
"עזרכן": true,
"עזרנו": true,
"עזרתה": true,
"עזרתו": true,
"עזרתי": true,
"עזרתך": true,
"עזרתם": true,
"עזרתן": true,
"עזתים": true,
"עזתית": true,
"עטויה": true,
"עטופה": true,
"עטורה": true,
"עטיהם": true,
"עטיהן": true,
"עטיות": true,
"עטייה": true,
"עטייך": true,
"עטיים": true,
"עטיין": true,
"עטיכם": true,
"עטיכן": true,
"עטינה": true,
"עטינו": true,
"עטיני": true,
"עטינך": true,
"עטינם": true,
"עטינן": true,
"עטיפה": true,
"עטיפת": true,
"עטיתי": true,
"עטיתם": true,
"עטיתן": true,
"עטלפה": true,
"עטלפו": true,
"עטלפי": true,
"עטלפך": true,
"עטלפם": true,
"עטלפן": true,
"עטפנו": true,
"עטפתי": true,
"עטפתם": true,
"עטפתן": true,
"עטרות": true,
"עטרנה": true,
"עטרתה": true,
"עטרתו": true,
"עטרתי": true,
"עטרתך": true,
"עטרתם": true,
"עטרתן": true,
"עיבדה": true,
"עיבדו": true,
"עיבדת": true,
"עיבוד": true,
"עיבוי": true,
"עיבור": true,
"עיבית": true,
"עיברה": true,
"עיברו": true,
"עיברת": true,
"עיבתה": true,
"עיגול": true,
"עיגון": true,
"עיגלה": true,
"עיגלו": true,
"עיגלת": true,
"עיגנה": true,
"עיגנו": true,
"עיגנת": true,
"עידוד": true,
"עידון": true,
"עידור": true,
"עידית": true,
"עידנה": true,
"עידנו": true,
"עידני": true,
"עידנך": true,
"עידנם": true,
"עידנן": true,
"עידנת": true,
"עיווה": true,
"עיוור": true,
"עיוות": true,
"עיונה": true,
"עיונו": true,
"עיוני": true,
"עיונך": true,
"עיונם": true,
"עיונן": true,
"עיופה": true,
"עיופו": true,
"עיופי": true,
"עיופך": true,
"עיופם": true,
"עיופן": true,
"עיורה": true,
"עיורו": true,
"עיורי": true,
"עיורך": true,
"עיורם": true,
"עיורן": true,
"עיזים": true,
"עיטוף": true,
"עיטור": true,
"עיטוש": true,
"עיטיה": true,
"עיטיו": true,
"עיטיי": true,
"עיטיך": true,
"עיטים": true,
"עיטכם": true,
"עיטכן": true,
"עיטנו": true,
"עיטרה": true,
"עיטרו": true,
"עיטרת": true,
"עיידה": true,
"עיינה": true,
"עיינו": true,
"עייני": true,
"עיינת": true,
"עייפה": true,
"עייפו": true,
"עייפי": true,
"עייפת": true,
"עיירה": true,
"עיירת": true,
"עייתו": true,
"עיכבה": true,
"עיכבו": true,
"עיכבת": true,
"עיכוב": true,
"עיכול": true,
"עיכוס": true,
"עיכלה": true,
"עיכלו": true,
"עיכלת": true,
"עיכסה": true,
"עיכסו": true,
"עיכסת": true,
"עילאי": true,
"עילאר": true,
"עילגת": true,
"עילוט": true,
"עילוי": true,
"עילום": true,
"עילות": true,
"עילית": true,
"עילתה": true,
"עילתו": true,
"עילתי": true,
"עילתך": true,
"עילתם": true,
"עילתן": true,
"עימאד": true,
"עימדה": true,
"עימדו": true,
"עימדת": true,
"עימוד": true,
"עימום": true,
"עימות": true,
"עיממה": true,
"עיממו": true,
"עיממת": true,
"עימנו": true,
"עימתה": true,
"עימתו": true,
"עימתי": true,
"עימתם": true,
"עימתן": true,
"עינבר": true,
"עינגה": true,
"עינגו": true,
"עינגת": true,
"עינוג": true,
"עינוי": true,
"עיניה": true,
"עיניו": true,
"עיניי": true,
"עיניך": true,
"עינים": true,
"עינית": true,
"עינכם": true,
"עינכן": true,
"עיננו": true,
"עינתה": true,
"עיסאם": true,
"עיסוי": true,
"עיסוק": true,
"עיסות": true,
"עיסית": true,
"עיסתה": true,
"עיסתו": true,
"עיסתי": true,
"עיסתך": true,
"עיסתם": true,
"עיסתן": true,
"עיפוש": true,
"עיפשה": true,
"עיפשו": true,
"עיפשת": true,
"עיצבה": true,
"עיצבו": true,
"עיצבן": true,
"עיצבת": true,
"עיצוב": true,
"עיצום": true,
"עיצור": true,
"עיקוב": true,
"עיקול": true,
"עיקום": true,
"עיקוף": true,
"עיקור": true,
"עיקלה": true,
"עיקלו": true,
"עיקלת": true,
"עיקמה": true,
"עיקמו": true,
"עיקמת": true,
"עיקרה": true,
"עיקרו": true,
"עיקרי": true,
"עיקרך": true,
"עיקרם": true,
"עיקרן": true,
"עיקרת": true,
"עיקשת": true,
"עיראק": true,
"עירבה": true,
"עירבו": true,
"עירבל": true,
"עירבת": true,
"עירוב": true,
"עירוי": true,
"עירום": true,
"עירון": true,
"עירור": true,
"עירות": true,
"עיריה": true,
"עירית": true,
"עירכם": true,
"עירכן": true,
"עירנו": true,
"עירני": true,
"עירפל": true,
"עירתה": true,
"עירתו": true,
"עירתי": true,
"עירתך": true,
"עירתם": true,
"עירתן": true,
"עישבה": true,
"עישבו": true,
"עישבת": true,
"עישוב": true,
"עישון": true,
"עישנה": true,
"עישנו": true,
"עישנת": true,
"עיתוי": true,
"עיתון": true,
"עיתים": true,
"עכבות": true,
"עכביש": true,
"עכבנה": true,
"עכברה": true,
"עכברו": true,
"עכברי": true,
"עכברך": true,
"עכברם": true,
"עכברן": true,
"עכבתה": true,
"עכבתו": true,
"עכבתי": true,
"עכבתך": true,
"עכבתם": true,
"עכבתן": true,
"עכואי": true,
"עכוזה": true,
"עכוזו": true,
"עכוזי": true,
"עכוזך": true,
"עכוזם": true,
"עכוזן": true,
"עכורה": true,
"עכירה": true,
"עכלנה": true,
"עכסנה": true,
"עכסנו": true,
"עכסני": true,
"עכסנך": true,
"עכסנם": true,
"עכסנן": true,
"עכרמה": true,
"עכרנו": true,
"עכרתי": true,
"עכרתם": true,
"עכרתן": true,
"עכשוי": true,
"עכשיו": true,
"עלבון": true,
"עלבים": true,
"עלבנו": true,
"עלבתי": true,
"עלבתם": true,
"עלבתן": true,
"עלובה": true,
"עלווה": true,
"עלווי": true,
"עלולה": true,
"עלומה": true,
"עלונה": true,
"עלונו": true,
"עלוני": true,
"עלונך": true,
"עלונם": true,
"עלונן": true,
"עלוקה": true,
"עלותה": true,
"עלותו": true,
"עלותי": true,
"עלותך": true,
"עלותם": true,
"עלותן": true,
"עלזנו": true,
"עלזתי": true,
"עלזתם": true,
"עלזתן": true,
"עלטות": true,
"עלטתה": true,
"עלטתו": true,
"עלטתי": true,
"עלטתך": true,
"עלטתם": true,
"עלטתן": true,
"עליאן": true,
"עלידי": true,
"עליהם": true,
"עליהן": true,
"עליון": true,
"עליות": true,
"עליזה": true,
"עלייה": true,
"עלייך": true,
"עליית": true,
"עליכם": true,
"עליכן": true,
"עלילה": true,
"עלילת": true,
"עלינה": true,
"עלינו": true,
"עליתי": true,
"עליתם": true,
"עליתן": true,
"עללות": true,
"עלמות": true,
"עלמיה": true,
"עלמיו": true,
"עלמיי": true,
"עלמיך": true,
"עלמים": true,
"עלמין": true,
"עלמכם": true,
"עלמכן": true,
"עלמנו": true,
"עלמתה": true,
"עלמתו": true,
"עלמתי": true,
"עלמתך": true,
"עלמתם": true,
"עלמתן": true,
"עלעול": true,
"עלעלה": true,
"עלעלו": true,
"עלעלי": true,
"עלעלך": true,
"עלעלם": true,
"עלעלן": true,
"עלעלת": true,
"עלפון": true,
"עלצנו": true,
"עלצתי": true,
"עלצתם": true,
"עלצתן": true,
"עלרוד": true,
"עמדות": true,
"עמדנה": true,
"עמדנו": true,
"עמדתה": true,
"עמדתו": true,
"עמדתי": true,
"עמדתך": true,
"עמדתם": true,
"עמדתן": true,
"עמודה": true,
"עמודו": true,
"עמודי": true,
"עמודך": true,
"עמודם": true,
"עמודן": true,
"עמומה": true,
"עמונה": true,
"עמוני": true,
"עמוסה": true,
"עמוקה": true,
"עמורה": true,
"עמותה": true,
"עמותת": true,
"עמיאל": true,
"עמידה": true,
"עמידר": true,
"עמיהם": true,
"עמיהן": true,
"עמיחי": true,
"עמיטל": true,
"עמייך": true,
"עמיכם": true,
"עמיכן": true,
"עמילה": true,
"עמילו": true,
"עמילי": true,
"עמילך": true,
"עמילם": true,
"עמילן": true,
"עמינו": true,
"עמינח": true,
"עמיעד": true,
"עמיקם": true,
"עמירה": true,
"עמירו": true,
"עמירי": true,
"עמירך": true,
"עמירם": true,
"עמירן": true,
"עמיתה": true,
"עמיתו": true,
"עמיתי": true,
"עמיתך": true,
"עמיתם": true,
"עמיתן": true,
"עמלון": true,
"עמלות": true,
"עמליה": true,
"עמלים": true,
"עמלכם": true,
"עמלכן": true,
"עמלנה": true,
"עמלנו": true,
"עמלני": true,
"עמלנת": true,
"עמלקי": true,
"עמלתה": true,
"עמלתו": true,
"עמלתי": true,
"עמלתך": true,
"עמלתם": true,
"עמלתן": true,
"עממיה": true,
"עממיו": true,
"עממיי": true,
"עממיך": true,
"עממים": true,
"עממית": true,
"עממנה": true,
"עמעום": true,
"עמעמה": true,
"עמעמו": true,
"עמעמי": true,
"עמעמת": true,
"עמקות": true,
"עמקיה": true,
"עמקיו": true,
"עמקיי": true,
"עמקיך": true,
"עמקים": true,
"עמקכם": true,
"עמקכן": true,
"עמקנו": true,
"עמרני": true,
"עמתנה": true,
"ענביה": true,
"ענביו": true,
"ענביי": true,
"ענביך": true,
"ענבים": true,
"ענבכם": true,
"ענבכן": true,
"ענבלה": true,
"ענבלו": true,
"ענבלי": true,
"ענבלך": true,
"ענבלם": true,
"ענבלן": true,
"ענבנו": true,
"ענברה": true,
"ענברו": true,
"ענברי": true,
"ענברך": true,
"ענברם": true,
"ענברן": true,
"ענבתא": true,
"ענבתי": true,
"ענבתם": true,
"ענבתן": true,
"ענגנה": true,
"ענדנו": true,
"ענדתי": true,
"ענדתם": true,
"ענדתן": true,
"ענובה": true,
"ענוגה": true,
"ענודה": true,
"ענווה": true,
"ענוות": true,
"ענויה": true,
"ענטוז": true,
"ענטזה": true,
"ענטזו": true,
"ענטזי": true,
"ענטזת": true,
"עניבה": true,
"עניבה": true,
"ענידה": true,
"עניות": true,
"ענייה": true,
"ענייו": true,
"ענייך": true,
"עניים": true,
"עניין": true,
"עניכם": true,
"עניכן": true,
"ענינה": true,
"ענינו": true,
"עניני": true,
"עניפה": true,
"ענישה": true,
"עניתי": true,
"עניתם": true,
"עניתן": true,
"עננות": true,
"ענניה": true,
"ענניו": true,
"ענניי": true,
"ענניך": true,
"עננים": true,
"עננכם": true,
"עננכן": true,
"ענננו": true,
"עננתה": true,
"עננתו": true,
"עננתי": true,
"עננתך": true,
"עננתם": true,
"עננתן": true,
"ענפות": true,
"ענפיה": true,
"ענפיו": true,
"ענפיי": true,
"ענפיך": true,
"ענפים": true,
"ענפית": true,
"ענפכם": true,
"ענפכן": true,
"ענפנו": true,
"ענקות": true,
"ענקיה": true,
"ענקיו": true,
"ענקיי": true,
"ענקיך": true,
"ענקים": true,
"ענקית": true,
"ענקכם": true,
"ענקכן": true,
"ענקנו": true,
"ענתות": true,
"עסוקה": true,
"עסינה": true,
"עסיסה": true,
"עסיסו": true,
"עסיסי": true,
"עסיסך": true,
"עסיסם": true,
"עסיסן": true,
"עסקות": true,
"עסקיה": true,
"עסקיו": true,
"עסקיי": true,
"עסקיך": true,
"עסקים": true,
"עסקית": true,
"עסקכם": true,
"עסקכן": true,
"עסקנה": true,
"עסקנו": true,
"עסקני": true,
"עסקנך": true,
"עסקנם": true,
"עסקנן": true,
"עסקתה": true,
"עסקתו": true,
"עסקתי": true,
"עסקתך": true,
"עסקתם": true,
"עסקתן": true,
"עפולה": true,
"עפיצה": true,
"עפנאן": true,
"עפעוף": true,
"עפעפה": true,
"עפעפו": true,
"עפעפי": true,
"עפעפך": true,
"עפעפם": true,
"עפעפן": true,
"עפעפת": true,
"עפציה": true,
"עפציו": true,
"עפציי": true,
"עפציך": true,
"עפצים": true,
"עפצכם": true,
"עפצכן": true,
"עפצנו": true,
"עפרון": true,
"עפרות": true,
"עפרכם": true,
"עפרכן": true,
"עפרנו": true,
"עפרתה": true,
"עפרתו": true,
"עפרתי": true,
"עפרתך": true,
"עפרתם": true,
"עפרתן": true,
"עפשנה": true,
"עצבבה": true,
"עצבבו": true,
"עצבבי": true,
"עצבבת": true,
"עצבוב": true,
"עצבון": true,
"עצבות": true,
"עצביה": true,
"עצביו": true,
"עצביי": true,
"עצביך": true,
"עצבים": true,
"עצבית": true,
"עצבכם": true,
"עצבכן": true,
"עצבנה": true,
"עצבנו": true,
"עצבני": true,
"עצבנת": true,
"עצובה": true,
"עצומה": true,
"עצורה": true,
"עציהם": true,
"עציהן": true,
"עציון": true,
"עציות": true,
"עצייך": true,
"עציים": true,
"עציכם": true,
"עציכן": true,
"עצימה": true,
"עצינו": true,
"עציצה": true,
"עציצו": true,
"עציצי": true,
"עציצך": true,
"עציצם": true,
"עציצן": true,
"עצירה": true,
"עצירו": true,
"עצירי": true,
"עצירך": true,
"עצירם": true,
"עצירן": true,
"עצירת": true,
"עצלות": true,
"עצלים": true,
"עצמאי": true,
"עצמון": true,
"עצמות": true,
"עצמיה": true,
"עצמיו": true,
"עצמיי": true,
"עצמיך": true,
"עצמים": true,
"עצמית": true,
"עצמכם": true,
"עצמכן": true,
"עצמנו": true,
"עצמתה": true,
"עצמתו": true,
"עצמתי": true,
"עצמתך": true,
"עצמתם": true,
"עצמתן": true,
"עצרות": true,
"עצרנו": true,
"עצרתה": true,
"עצרתו": true,
"עצרתי": true,
"עצרתך": true,
"עצרתם": true,
"עצרתן": true,
"עצתכם": true,
"עצתכן": true,
"עצתנו": true,
"עקבות": true,
"עקביה": true,
"עקביו": true,
"עקביי": true,
"עקביך": true,
"עקבים": true,
"עקבית": true,
"עקבכם": true,
"עקבכן": true,
"עקבנו": true,
"עקבתי": true,
"עקבתם": true,
"עקבתן": true,
"עקדות": true,
"עקדנו": true,
"עקדתה": true,
"עקדתו": true,
"עקדתי": true,
"עקדתך": true,
"עקדתם": true,
"עקדתן": true,
"עקובה": true,
"עקודה": true,
"עקומה": true,
"עקוצה": true,
"עקורה": true,
"עקיבא": true,
"עקיבה": true,
"עקידה": true,
"עקיפה": true,
"עקיצה": true,
"עקירה": true,
"עקירת": true,
"עקלנה": true,
"עקלקל": true,
"עקמות": true,
"עקמנה": true,
"עקמתה": true,
"עקמתו": true,
"עקמתי": true,
"עקמתך": true,
"עקמתם": true,
"עקמתן": true,
"עקפנו": true,
"עקפתי": true,
"עקפתם": true,
"עקפתן": true,
"עקצוץ": true,
"עקצנו": true,
"עקצצה": true,
"עקצצו": true,
"עקצצי": true,
"עקצצת": true,
"עקצתי": true,
"עקצתם": true,
"עקצתן": true,
"עקרבה": true,
"עקרבו": true,
"עקרבי": true,
"עקרבך": true,
"עקרבם": true,
"עקרבן": true,
"עקרון": true,
"עקרות": true,
"עקרים": true,
"עקרמה": true,
"עקרנה": true,
"עקרנו": true,
"עקרתי": true,
"עקרתם": true,
"עקרתן": true,
"עקשני": true,
"עקתכם": true,
"עקתכן": true,
"עקתנו": true,
"עראבה": true,
"ערבבה": true,
"ערבבו": true,
"ערבבי": true,
"ערבבת": true,
"ערבוב": true,
"ערבול": true,
"ערבון": true,
"ערבות": true,
"ערביה": true,
"ערביו": true,
"ערביי": true,
"ערביך": true,
"ערבים": true,
"ערביק": true,
"ערבית": true,
"ערבכם": true,
"ערבכן": true,
"ערבלה": true,
"ערבלו": true,
"ערבלי": true,
"ערבלת": true,
"ערבנה": true,
"ערבנו": true,
"ערבתה": true,
"ערבתו": true,
"ערבתי": true,
"ערבתך": true,
"ערבתם": true,
"ערבתן": true,
"ערגול": true,
"ערגות": true,
"ערגלה": true,
"ערגלו": true,
"ערגלי": true,
"ערגלת": true,
"ערגנו": true,
"ערגתה": true,
"ערגתו": true,
"ערגתי": true,
"ערגתך": true,
"ערגתם": true,
"ערגתן": true,
"ערדלה": true,
"ערדלו": true,
"ערדלי": true,
"ערדלך": true,
"ערדלם": true,
"ערדלן": true,
"ערובה": true,
"ערובת": true,
"ערוגה": true,
"ערודה": true,
"ערודו": true,
"ערודי": true,
"ערודך": true,
"ערודם": true,
"ערודן": true,
"ערווה": true,
"ערוכה": true,
"ערומה": true,
"ערוסי": true,
"ערופה": true,
"ערוצה": true,
"ערוצו": true,
"ערוצי": true,
"ערוצך": true,
"ערוצם": true,
"ערוצן": true,
"ערותה": true,
"ערותו": true,
"ערותי": true,
"ערותך": true,
"ערותם": true,
"ערותן": true,
"ערטול": true,
"ערטלה": true,
"ערטלו": true,
"ערטלי": true,
"ערטלת": true,
"עריגה": true,
"עריהם": true,
"עריהן": true,
"עריות": true,
"ערייך": true,
"עריכה": true,
"עריכם": true,
"עריכן": true,
"עריכת": true,
"ערימה": true,
"ערימת": true,
"ערינו": true,
"עריסה": true,
"עריפה": true,
"עריצה": true,
"עריצו": true,
"עריצי": true,
"עריצך": true,
"עריצם": true,
"עריצן": true,
"עריקה": true,
"עריקו": true,
"עריקי": true,
"עריקך": true,
"עריקם": true,
"עריקן": true,
"ערירי": true,
"ערכאה": true,
"ערכות": true,
"ערכיה": true,
"ערכיו": true,
"ערכיי": true,
"ערכיך": true,
"ערכים": true,
"ערכית": true,
"ערככם": true,
"ערככן": true,
"ערכנו": true,
"ערכתה": true,
"ערכתו": true,
"ערכתי": true,
"ערכתך": true,
"ערכתם": true,
"ערכתן": true,
"ערלוי": true,
"ערלות": true,
"ערלים": true,
"ערלתה": true,
"ערלתו": true,
"ערלתי": true,
"ערלתך": true,
"ערלתם": true,
"ערלתן": true,
"ערמון": true,
"ערמות": true,
"ערמנו": true,
"ערמתה": true,
"ערמתו": true,
"ערמתי": true,
"ערמתך": true,
"ערמתם": true,
"ערמתן": true,
"ערנות": true,
"ערנית": true,
"ערסלה": true,
"ערסלו": true,
"ערסלי": true,
"ערסלך": true,
"ערסלם": true,
"ערסלן": true,
"ערעור": true,
"ערערה": true,
"ערערו": true,
"ערערי": true,
"ערערת": true,
"ערפאת": true,
"ערפדה": true,
"ערפדו": true,
"ערפדי": true,
"ערפדך": true,
"ערפדם": true,
"ערפדן": true,
"ערפול": true,
"ערפיח": true,
"ערפלה": true,
"ערפלו": true,
"ערפלי": true,
"ערפלך": true,
"ערפלם": true,
"ערפלן": true,
"ערפלת": true,
"ערפנו": true,
"ערפתי": true,
"ערפתם": true,
"ערפתן": true,
"ערקנו": true,
"ערקתי": true,
"ערקתם": true,
"ערקתן": true,
"ערריה": true,
"ערריו": true,
"ערריי": true,
"ערריך": true,
"עררים": true,
"עררכם": true,
"עררכן": true,
"עררנו": true,
"ערשות": true,
"ערשכם": true,
"ערשכן": true,
"ערשנו": true,
"עשביה": true,
"עשביו": true,
"עשביי": true,
"עשביך": true,
"עשבים": true,
"עשבית": true,
"עשבכם": true,
"עשבכן": true,
"עשבנה": true,
"עשבנו": true,
"עשויה": true,
"עשוקה": true,
"עשורה": true,
"עשורו": true,
"עשורי": true,
"עשורך": true,
"עשורם": true,
"עשורן": true,
"עשיהם": true,
"עשיהן": true,
"עשיות": true,
"עשייה": true,
"עשייך": true,
"עשיית": true,
"עשיכם": true,
"עשיכן": true,
"עשינה": true,
"עשינו": true,
"עשירה": true,
"עשירי": true,
"עשיתי": true,
"עשיתם": true,
"עשיתן": true,
"עשנות": true,
"עשנים": true,
"עשנכם": true,
"עשנכן": true,
"עשננו": true,
"עשקנו": true,
"עשקתי": true,
"עשקתם": true,
"עשקתן": true,
"עשרות": true,
"עשרים": true,
"עשרתה": true,
"עשרתו": true,
"עשרתי": true,
"עשרתך": true,
"עשרתם": true,
"עשרתן": true,
"עששית": true,
"עששתה": true,
"עששתו": true,
"עששתי": true,
"עששתך": true,
"עששתם": true,
"עששתן": true,
"עתודה": true,
"עתידה": true,
"עתידו": true,
"עתידי": true,
"עתידך": true,
"עתידם": true,
"עתידן": true,
"עתיהם": true,
"עתיהן": true,
"עתייך": true,
"עתיכם": true,
"עתיכן": true,
"עתינו": true,
"עתיקה": true,
"עתירה": true,
"עתירי": true,
"עתירת": true,
"עתליה": true,
"עתלית": true,
"עתקות": true,
"עתקים": true,
"עתרנו": true,
"עתרתי": true,
"עתרתם": true,
"עתרתן": true,
"פאבים": true,
"פאבלו": true,
"פאואל": true,
"פאואר": true,
"פאודה": true,
"פאודל": true,
"פאוול": true,
"פאוור": true,
"פאוזה": true,
"פאולו": true,
"פאונד": true,
"פאונה": true,
"פאונו": true,
"פאוני": true,
"פאונך": true,
"פאונם": true,
"פאונן": true,
"פאורה": true,
"פארוק": true,
"פארות": true,
"פאריס": true,
"פארכם": true,
"פארכן": true,
"פארמה": true,
"פארנה": true,
"פארנו": true,
"פארסה": true,
"פארתה": true,
"פארתו": true,
"פארתי": true,
"פארתך": true,
"פארתם": true,
"פארתן": true,
"פאתכם": true,
"פאתכן": true,
"פאתנו": true,
"פברוק": true,
"פברקה": true,
"פברקו": true,
"פברקי": true,
"פברקת": true,
"פגודה": true,
"פגומה": true,
"פגועה": true,
"פגושה": true,
"פגושו": true,
"פגושי": true,
"פגושך": true,
"פגושם": true,
"פגושן": true,
"פגזיה": true,
"פגזיו": true,
"פגזיי": true,
"פגזיך": true,
"פגזים": true,
"פגזכם": true,
"פגזכן": true,
"פגזנו": true,
"פגיהם": true,
"פגיהן": true,
"פגיון": true,
"פגיות": true,
"פגייה": true,
"פגייך": true,
"פגיכם": true,
"פגיכן": true,
"פגימה": true,
"פגינו": true,
"פגיעה": true,
"פגיעת": true,
"פגישה": true,
"פגישת": true,
"פגיתה": true,
"פגיתו": true,
"פגיתי": true,
"פגיתך": true,
"פגיתם": true,
"פגיתן": true,
"פגלנה": true,
"פגמיה": true,
"פגמיו": true,
"פגמיי": true,
"פגמיך": true,
"פגמים": true,
"פגמכם": true,
"פגמכן": true,
"פגמנו": true,
"פגמתי": true,
"פגמתם": true,
"פגמתן": true,
"פגנים": true,
"פגנית": true,
"פגעות": true,
"פגעיה": true,
"פגעיו": true,
"פגעיי": true,
"פגעיך": true,
"פגעים": true,
"פגעכם": true,
"פגעכן": true,
"פגענה": true,
"פגענו": true,
"פגעתי": true,
"פגעתם": true,
"פגעתן": true,
"פגרות": true,
"פגריה": true,
"פגריו": true,
"פגריי": true,
"פגריך": true,
"פגרים": true,
"פגרכם": true,
"פגרכן": true,
"פגרנה": true,
"פגרנו": true,
"פגרתה": true,
"פגרתו": true,
"פגרתי": true,
"פגרתך": true,
"פגרתם": true,
"פגרתן": true,
"פגשים": true,
"פגשנו": true,
"פגשתי": true,
"פגשתם": true,
"פגשתן": true,
"פגתכם": true,
"פגתכן": true,
"פגתנו": true,
"פדגוג": true,
"פדואל": true,
"פדויה": true,
"פדחות": true,
"פדחתה": true,
"פדחתו": true,
"פדחתי": true,
"פדחתך": true,
"פדחתם": true,
"פדחתן": true,
"פדיון": true,
"פדיות": true,
"פדיחה": true,
"פדייה": true,
"פדינה": true,
"פדינו": true,
"פדיתי": true,
"פדיתם": true,
"פדיתן": true,
"פדלאה": true,
"פדלון": true,
"פדלים": true,
"פדנות": true,
"פדנטי": true,
"פדרלי": true,
"פדרמן": true,
"פדרנה": true,
"פהקנה": true,
"פואטי": true,
"פואמה": true,
"פוארה": true,
"פוארו": true,
"פוארת": true,
"פוביה": true,
"פוברק": true,
"פוגגה": true,
"פוגגו": true,
"פוגגי": true,
"פוגגת": true,
"פוגות": true,
"פוגלה": true,
"פוגלו": true,
"פוגלת": true,
"פוגמת": true,
"פוגנה": true,
"פוגעת": true,
"פוגשת": true,
"פודות": true,
"פודים": true,
"פודלה": true,
"פודלו": true,
"פודלי": true,
"פודלך": true,
"פודלם": true,
"פודלן": true,
"פודרה": true,
"פוואר": true,
"פוזות": true,
"פוזלת": true,
"פוזמה": true,
"פוזמו": true,
"פוזמק": true,
"פוזמת": true,
"פוזרה": true,
"פוזרו": true,
"פוזרת": true,
"פוחדת": true,
"פוחזת": true,
"פוחחת": true,
"פוחית": true,
"פוחלץ": true,
"פוחמה": true,
"פוחמו": true,
"פוחמת": true,
"פוחתה": true,
"פוחתו": true,
"פוחתי": true,
"פוחתם": true,
"פוחתן": true,
"פוחתת": true,
"פוטון": true,
"פוטין": true,
"פוטמה": true,
"פוטמו": true,
"פוטמת": true,
"פוטרה": true,
"פוטרו": true,
"פוטרת": true,
"פויות": true,
"פויחה": true,
"פויחו": true,
"פויחת": true,
"פויים": true,
"פוינט": true,
"פויסה": true,
"פויסו": true,
"פויסת": true,
"פוכות": true,
"פוככם": true,
"פוככן": true,
"פוכנו": true,
"פוכרת": true,
"פולגה": true,
"פולגו": true,
"פולגת": true,
"פולות": true,
"פולחן": true,
"פולטת": true,
"פוליא": true,
"פוליה": true,
"פוליו": true,
"פוליט": true,
"פוליי": true,
"פוליך": true,
"פולים": true,
"פולין": true,
"פוליס": true,
"פוליפ": true,
"פוליש": true,
"פולית": true,
"פולכם": true,
"פולכן": true,
"פולמר": true,
"פולנה": true,
"פולנו": true,
"פולני": true,
"פולנס": true,
"פולסא": true,
"פולסה": true,
"פולסו": true,
"פולסר": true,
"פולסת": true,
"פולפל": true,
"פולשה": true,
"פולשו": true,
"פולשי": true,
"פולשך": true,
"פולשם": true,
"פולשן": true,
"פולשת": true,
"פומבי": true,
"פומות": true,
"פומיס": true,
"פומית": true,
"פומלה": true,
"פומלו": true,
"פומפה": true,
"פומתה": true,
"פומתו": true,
"פומתי": true,
"פומתך": true,
"פומתם": true,
"פומתן": true,
"פונאר": true,
"פונדה": true,
"פונדו": true,
"פונדק": true,
"פונות": true,
"פונטו": true,
"פונטי": true,
"פוניה": true,
"פונים": true,
"פונית": true,
"פונמה": true,
"פונמי": true,
"פונקה": true,
"פונקו": true,
"פונקת": true,
"פונתה": true,
"פוסחת": true,
"פוסטר": true,
"פוסים": true,
"פוסלה": true,
"פוסלו": true,
"פוסלת": true,
"פוסנה": true,
"פוסעת": true,
"פוספט": true,
"פוספס": true,
"פוסקה": true,
"פוסקו": true,
"פוסקת": true,
"פועות": true,
"פועים": true,
"פועלה": true,
"פועלו": true,
"פועלי": true,
"פועלך": true,
"פועלם": true,
"פועלן": true,
"פועלת": true,
"פועמת": true,
"פוענח": true,
"פוערת": true,
"פופול": true,
"פופים": true,
"פופיק": true,
"פוצות": true,
"פוצחה": true,
"פוצחו": true,
"פוצחת": true,
"פוצים": true,
"פוצית": true,
"פוצלה": true,
"פוצלו": true,
"פוצלת": true,
"פוצעת": true,
"פוצצה": true,
"פוצצו": true,
"פוצצי": true,
"פוצצת": true,
"פוצרת": true,
"פוצתה": true,
"פוקדת": true,
"פוקוס": true,
"פוקחה": true,
"פוקחו": true,
"פוקחת": true,
"פוקטר": true,
"פוקעת": true,
"פוקקת": true,
"פורבס": true,
"פורגן": true,
"פורום": true,
"פורוש": true,
"פורות": true,
"פורזה": true,
"פורזו": true,
"פורזת": true,
"פורחת": true,
"פורטה": true,
"פורטו": true,
"פורטל": true,
"פורטר": true,
"פורטת": true,
"פוריה": true,
"פוריו": true,
"פוריי": true,
"פוריך": true,
"פורים": true,
"פוריק": true,
"פורכם": true,
"פורכן": true,
"פורמה": true,
"פורמט": true,
"פורמת": true,
"פורנו": true,
"פורסם": true,
"פורסת": true,
"פורעת": true,
"פורפת": true,
"פורצי": true,
"פורצן": true,
"פורצת": true,
"פורקה": true,
"פורקו": true,
"פורקן": true,
"פורקת": true,
"פוררה": true,
"פוררו": true,
"פוררי": true,
"פוררת": true,
"פורשה": true,
"פורשו": true,
"פורשת": true,
"פורתא": true,
"פושות": true,
"פושטה": true,
"פושטו": true,
"פושטי": true,
"פושטק": true,
"פושטת": true,
"פושים": true,
"פושלה": true,
"פושלו": true,
"פושלת": true,
"פושעת": true,
"פושקה": true,
"פושקו": true,
"פושקת": true,
"פושרה": true,
"פושרו": true,
"פושרת": true,
"פותות": true,
"פותחה": true,
"פותחו": true,
"פותחן": true,
"פותחת": true,
"פותים": true,
"פותית": true,
"פותכם": true,
"פותכן": true,
"פותלה": true,
"פותלו": true,
"פותלת": true,
"פותנו": true,
"פותרת": true,
"פותתה": true,
"פזורה": true,
"פזזנה": true,
"פזיהם": true,
"פזיהן": true,
"פזיות": true,
"פזיזה": true,
"פזייך": true,
"פזיים": true,
"פזיכם": true,
"פזיכן": true,
"פזילה": true,
"פזינו": true,
"פזלים": true,
"פזלנו": true,
"פזלתי": true,
"פזלתם": true,
"פזלתן": true,
"פזמון": true,
"פזמנה": true,
"פזרנה": true,
"פזרני": true,
"פחדיה": true,
"פחדיו": true,
"פחדיי": true,
"פחדיך": true,
"פחדים": true,
"פחדכם": true,
"פחדכן": true,
"פחדנה": true,
"פחדנו": true,
"פחדני": true,
"פחדתי": true,
"פחדתם": true,
"פחדתן": true,
"פחונה": true,
"פחונו": true,
"פחוני": true,
"פחונך": true,
"פחונם": true,
"פחונן": true,
"פחוסה": true,
"פחותה": true,
"פחזני": true,
"פחחות": true,
"פחחיה": true,
"פחחיו": true,
"פחחיי": true,
"פחחיך": true,
"פחחים": true,
"פחחית": true,
"פחחכם": true,
"פחחכן": true,
"פחחנו": true,
"פחיהם": true,
"פחיהן": true,
"פחיות": true,
"פחייך": true,
"פחיכם": true,
"פחיכן": true,
"פחימה": true,
"פחינו": true,
"פחיתה": true,
"פחיתו": true,
"פחיתי": true,
"פחיתך": true,
"פחיתם": true,
"פחיתן": true,
"פחלוץ": true,
"פחלצה": true,
"פחלצו": true,
"פחלצי": true,
"פחלצת": true,
"פחמות": true,
"פחמיה": true,
"פחמיו": true,
"פחמיי": true,
"פחמיך": true,
"פחמים": true,
"פחמכם": true,
"פחמכן": true,
"פחמנה": true,
"פחמנו": true,
"פחמני": true,
"פחמתי": true,
"פחתנה": true,
"פחתנו": true,
"פטאקי": true,
"פטורה": true,
"פטינג": true,
"פטירה": true,
"פטירת": true,
"פטישה": true,
"פטישו": true,
"פטישי": true,
"פטישך": true,
"פטישם": true,
"פטישן": true,
"פטליה": true,
"פטליו": true,
"פטליי": true,
"פטליך": true,
"פטלים": true,
"פטלית": true,
"פטלכם": true,
"פטלכן": true,
"פטלנו": true,
"פטמבר": true,
"פטמות": true,
"פטמנה": true,
"פטמתה": true,
"פטמתו": true,
"פטמתי": true,
"פטמתך": true,
"פטמתם": true,
"פטמתן": true,
"פטנטה": true,
"פטנטו": true,
"פטנטי": true,
"פטנטך": true,
"פטנטם": true,
"פטנטן": true,
"פטפוט": true,
"פטפון": true,
"פטפטה": true,
"פטפטו": true,
"פטפטי": true,
"פטפטן": true,
"פטפטת": true,
"פטרול": true,
"פטרון": true,
"פטרות": true,
"פטריה": true,
"פטריק": true,
"פטרלה": true,
"פטרלו": true,
"פטרלי": true,
"פטרלת": true,
"פטרנה": true,
"פטרנו": true,
"פטרתה": true,
"פטרתו": true,
"פטרתי": true,
"פטרתך": true,
"פטרתם": true,
"פטרתן": true,
"פיאור": true,
"פיאנו": true,
"פיאצה": true,
"פיארה": true,
"פיארו": true,
"פיארת": true,
"פיגוג": true,
"פיגול": true,
"פיגום": true,
"פיגוע": true,
"פיגור": true,
"פיגלה": true,
"פיגלו": true,
"פיגלת": true,
"פיגעה": true,
"פיגעו": true,
"פיגעת": true,
"פיגרה": true,
"פיגרו": true,
"פיגרת": true,
"פידבק": true,
"פידור": true,
"פידות": true,
"פידים": true,
"פידרה": true,
"פידרו": true,
"פידרת": true,
"פיהוק": true,
"פיהקה": true,
"פיהקו": true,
"פיהקת": true,
"פיוגע": true,
"פיוחה": true,
"פיוחו": true,
"פיוחי": true,
"פיוחך": true,
"פיוחם": true,
"פיוחן": true,
"פיוטה": true,
"פיוטו": true,
"פיוטי": true,
"פיוטך": true,
"פיוטם": true,
"פיוטן": true,
"פיונג": true,
"פיוסה": true,
"פיוסו": true,
"פיוסי": true,
"פיוסך": true,
"פיוסם": true,
"פיוסן": true,
"פיורד": true,
"פיזוז": true,
"פיזום": true,
"פיזור": true,
"פיזזה": true,
"פיזזו": true,
"פיזזת": true,
"פיזית": true,
"פיזמה": true,
"פיזמו": true,
"פיזמת": true,
"פיזרה": true,
"פיזרו": true,
"פיזרת": true,
"פיחום": true,
"פיחות": true,
"פיחמה": true,
"פיחמו": true,
"פיחמת": true,
"פיחתה": true,
"פיחתו": true,
"פיחתי": true,
"פיחתם": true,
"פיחתן": true,
"פיטום": true,
"פיטור": true,
"פיטמה": true,
"פיטמו": true,
"פיטמן": true,
"פיטמת": true,
"פיטרה": true,
"פיטרו": true,
"פיטרת": true,
"פייחה": true,
"פייחו": true,
"פייחי": true,
"פייחת": true,
"פייטה": true,
"פייטו": true,
"פייטי": true,
"פייטן": true,
"פייטר": true,
"פייטת": true,
"פיילה": true,
"פיילר": true,
"פייני": true,
"פיינת": true,
"פייסה": true,
"פייסו": true,
"פייסי": true,
"פייסל": true,
"פייסן": true,
"פייסת": true,
"פייתה": true,
"פייתו": true,
"פייתי": true,
"פייתך": true,
"פייתם": true,
"פייתן": true,
"פיכוי": true,
"פיכחת": true,
"פיכית": true,
"פיכתה": true,
"פילגה": true,
"פילגו": true,
"פילגש": true,
"פילגת": true,
"פילוג": true,
"פילוח": true,
"פילול": true,
"פילון": true,
"פילוס": true,
"פילות": true,
"פילחה": true,
"פילחו": true,
"פילחת": true,
"פילטר": true,
"פיליה": true,
"פיליו": true,
"פיליי": true,
"פיליך": true,
"פילים": true,
"פילין": true,
"פילכם": true,
"פילכן": true,
"פיללה": true,
"פיללו": true,
"פיללת": true,
"פילנו": true,
"פילנט": true,
"פילסה": true,
"פילסו": true,
"פילסת": true,
"פילתה": true,
"פילתו": true,
"פילתי": true,
"פילתך": true,
"פילתם": true,
"פילתן": true,
"פימות": true,
"פימתה": true,
"פימתו": true,
"פימתי": true,
"פימתך": true,
"פימתם": true,
"פימתן": true,
"פינוי": true,
"פינוק": true,
"פינות": true,
"פינחס": true,
"פינטו": true,
"פיניה": true,
"פיניו": true,
"פיניי": true,
"פיניך": true,
"פינים": true,
"פינית": true,
"פינכם": true,
"פינכן": true,
"פינלה": true,
"פיננו": true,
"פינקה": true,
"פינקו": true,
"פינקל": true,
"פינקת": true,
"פינתה": true,
"פינתו": true,
"פינתי": true,
"פינתך": true,
"פינתם": true,
"פינתן": true,
"פיסול": true,
"פיסוק": true,
"פיסות": true,
"פיסחת": true,
"פיסים": true,
"פיסית": true,
"פיסלה": true,
"פיסלו": true,
"פיסלת": true,
"פיספס": true,
"פיסקה": true,
"פיסקו": true,
"פיסקת": true,
"פיסתה": true,
"פיסתו": true,
"פיסתי": true,
"פיסתך": true,
"פיסתם": true,
"פיסתן": true,
"פיעום": true,
"פיעמה": true,
"פיעמו": true,
"פיעמת": true,
"פיפות": true,
"פיפטה": true,
"פיצוח": true,
"פיצוי": true,
"פיצול": true,
"פיצוץ": true,
"פיצות": true,
"פיצחה": true,
"פיצחו": true,
"פיצחת": true,
"פיצית": true,
"פיצלה": true,
"פיצלו": true,
"פיצלת": true,
"פיצתה": true,
"פיצתו": true,
"פיצתי": true,
"פיצתך": true,
"פיצתם": true,
"פיצתן": true,
"פיקאר": true,
"פיקדה": true,
"פיקדו": true,
"פיקדת": true,
"פיקוד": true,
"פיקוח": true,
"פיקוס": true,
"פיקות": true,
"פיקחה": true,
"פיקחו": true,
"פיקחי": true,
"פיקחת": true,
"פיקים": true,
"פיקסל": true,
"פיקתה": true,
"פיקתו": true,
"פיקתי": true,
"פיקתך": true,
"פיקתם": true,
"פיקתן": true,
"פירוד": true,
"פירוז": true,
"פירוט": true,
"פירון": true,
"פירוק": true,
"פירור": true,
"פירוש": true,
"פירות": true,
"פירזה": true,
"פירזו": true,
"פירזת": true,
"פירטה": true,
"פירטו": true,
"פירטי": true,
"פירטך": true,
"פירטם": true,
"פירטן": true,
"פירטת": true,
"פיריה": true,
"פיריו": true,
"פיריי": true,
"פיריך": true,
"פירים": true,
"פירכם": true,
"פירכן": true,
"פירמה": true,
"פירנו": true,
"פירקה": true,
"פירקו": true,
"פירקת": true,
"פירשה": true,
"פירשו": true,
"פירשת": true,
"פישוט": true,
"פישול": true,
"פישוק": true,
"פישור": true,
"פישטה": true,
"פישטו": true,
"פישטת": true,
"פישלה": true,
"פישלו": true,
"פישלת": true,
"פישמן": true,
"פישקה": true,
"פישקו": true,
"פישקת": true,
"פישרה": true,
"פישרו": true,
"פישרת": true,
"פיתוח": true,
"פיתוי": true,
"פיתול": true,
"פיתום": true,
"פיתון": true,
"פיתות": true,
"פיתחה": true,
"פיתחו": true,
"פיתחת": true,
"פיתית": true,
"פיתלה": true,
"פיתלו": true,
"פיתלת": true,
"פיתתה": true,
"פיתתו": true,
"פיתתי": true,
"פיתתך": true,
"פיתתם": true,
"פיתתן": true,
"פכחון": true,
"פכינה": true,
"פכירה": true,
"פכפוך": true,
"פכפכה": true,
"פכפכו": true,
"פכפכי": true,
"פכפכת": true,
"פכרנו": true,
"פכרתי": true,
"פכרתם": true,
"פכרתן": true,
"פלאגה": true,
"פלאות": true,
"פלאזה": true,
"פלאיה": true,
"פלאיו": true,
"פלאיי": true,
"פלאיך": true,
"פלאים": true,
"פלאית": true,
"פלאכם": true,
"פלאכן": true,
"פלאנו": true,
"פלאפל": true,
"פלאצי": true,
"פלגות": true,
"פלגיה": true,
"פלגיו": true,
"פלגיי": true,
"פלגיך": true,
"פלגים": true,
"פלגכם": true,
"פלגכן": true,
"פלגנה": true,
"פלגנו": true,
"פלדור": true,
"פלדות": true,
"פלדלת": true,
"פלדמן": true,
"פלדתה": true,
"פלדתו": true,
"פלדתי": true,
"פלדתך": true,
"פלדתם": true,
"פלדתן": true,
"פלוגה": true,
"פלוגת": true,
"פלוטו": true,
"פלומה": true,
"פלוני": true,
"פלורס": true,
"פלזמה": true,
"פלחיה": true,
"פלחיו": true,
"פלחיי": true,
"פלחיך": true,
"פלחים": true,
"פלחכם": true,
"פלחכן": true,
"פלחנה": true,
"פלחנו": true,
"פלטות": true,
"פלטיה": true,
"פלטיו": true,
"פלטיי": true,
"פלטיך": true,
"פלטים": true,
"פלטכם": true,
"פלטכן": true,
"פלטנו": true,
"פלטתה": true,
"פלטתו": true,
"פלטתי": true,
"פלטתך": true,
"פלטתם": true,
"פלטתן": true,
"פליאה": true,
"פליות": true,
"פליטה": true,
"פליטו": true,
"פליטי": true,
"פליטך": true,
"פליטם": true,
"פליטן": true,
"פליטת": true,
"פלייה": true,
"פליים": true,
"פלייס": true,
"פלייר": true,
"פלילי": true,
"פלינה": true,
"פלינו": true,
"פליסר": true,
"פליקט": true,
"פליקס": true,
"פלירט": true,
"פלישה": true,
"פליתי": true,
"פליתם": true,
"פליתן": true,
"פלכיה": true,
"פלכיו": true,
"פלכיי": true,
"פלכיך": true,
"פלכים": true,
"פלככם": true,
"פלככן": true,
"פלכנו": true,
"פללים": true,
"פללנה": true,
"פלמור": true,
"פלמים": true,
"פלמית": true,
"פלמרה": true,
"פלמרו": true,
"פלמרי": true,
"פלמרת": true,
"פלנטה": true,
"פלסבו": true,
"פלסטי": true,
"פלסטר": true,
"פלסיה": true,
"פלסיו": true,
"פלסיי": true,
"פלסיך": true,
"פלסים": true,
"פלסכם": true,
"פלסכן": true,
"פלסנה": true,
"פלסנו": true,
"פלסתר": true,
"פלפול": true,
"פלפון": true,
"פלפלה": true,
"פלפלו": true,
"פלפלי": true,
"פלפלך": true,
"פלפלם": true,
"פלפלן": true,
"פלפלת": true,
"פלצור": true,
"פלצות": true,
"פלציה": true,
"פלצני": true,
"פלקטה": true,
"פלקטו": true,
"פלקטי": true,
"פלקטך": true,
"פלקטם": true,
"פלקטן": true,
"פלקסר": true,
"פלרטט": true,
"פלשבק": true,
"פלשנו": true,
"פלשתי": true,
"פלשתם": true,
"פלשתן": true,
"פמוטה": true,
"פמוטו": true,
"פמוטי": true,
"פמוטך": true,
"פמוטם": true,
"פמוטן": true,
"פמיני": true,
"פמליה": true,
"פמפום": true,
"פמפלט": true,
"פמפמה": true,
"פמפמו": true,
"פמפמי": true,
"פמפמת": true,
"פנאיה": true,
"פנאיו": true,
"פנאיי": true,
"פנאיך": true,
"פנאים": true,
"פנאכם": true,
"פנאכן": true,
"פנאנו": true,
"פנדות": true,
"פנדמי": true,
"פנדתה": true,
"פנדתו": true,
"פנדתי": true,
"פנדתך": true,
"פנדתם": true,
"פנדתן": true,
"פנויה": true,
"פנומן": true,
"פנטוז": true,
"פנטון": true,
"פנטזה": true,
"פנטזו": true,
"פנטזי": true,
"פנטזת": true,
"פנטים": true,
"פנטית": true,
"פניהם": true,
"פניהן": true,
"פניות": true,
"פנייה": true,
"פנייך": true,
"פניית": true,
"פניכם": true,
"פניכן": true,
"פנימה": true,
"פנימו": true,
"פנימי": true,
"פנימך": true,
"פנימם": true,
"פנימן": true,
"פנינה": true,
"פנינו": true,
"פניני": true,
"פניקה": true,
"פניקי": true,
"פניקס": true,
"פניתי": true,
"פניתם": true,
"פניתן": true,
"פנכות": true,
"פנכתה": true,
"פנכתו": true,
"פנכתי": true,
"פנכתך": true,
"פנכתם": true,
"פנכתן": true,
"פנלים": true,
"פנמים": true,
"פנמית": true,
"פנסיה": true,
"פנסיו": true,
"פנסיי": true,
"פנסיך": true,
"פנסים": true,
"פנסכם": true,
"פנסכן": true,
"פנסנו": true,
"פנקים": true,
"פנקנה": true,
"פנקסה": true,
"פנקסו": true,
"פנקסי": true,
"פנקסך": true,
"פנקסם": true,
"פנקסן": true,
"פנתרה": true,
"פנתרו": true,
"פנתרי": true,
"פנתרך": true,
"פנתרם": true,
"פנתרן": true,
"פסגות": true,
"פסגתה": true,
"פסגתו": true,
"פסגתי": true,
"פסגתך": true,
"פסגתם": true,
"פסגתן": true,
"פסדים": true,
"פסולה": true,
"פסולו": true,
"פסולי": true,
"פסולך": true,
"פסולם": true,
"פסולן": true,
"פסולת": true,
"פסונו": true,
"פסופה": true,
"פסוקה": true,
"פסוקו": true,
"פסוקי": true,
"פסוקך": true,
"פסוקם": true,
"פסוקן": true,
"פסותי": true,
"פסותם": true,
"פסותן": true,
"פסחיה": true,
"פסחיו": true,
"פסחיי": true,
"פסחיך": true,
"פסחים": true,
"פסחכם": true,
"פסחכן": true,
"פסחנה": true,
"פסחנו": true,
"פסחתי": true,
"פסחתם": true,
"פסחתן": true,
"פסטור": true,
"פסטות": true,
"פסטרה": true,
"פסטרו": true,
"פסטרי": true,
"פסטרת": true,
"פסיבי": true,
"פסיגה": true,
"פסיגו": true,
"פסיגי": true,
"פסיגך": true,
"פסיגם": true,
"פסיגן": true,
"פסידו": true,
"פסיהם": true,
"פסיהן": true,
"פסיון": true,
"פסיחה": true,
"פסייך": true,
"פסיכו": true,
"פסיכי": true,
"פסיכם": true,
"פסיכן": true,
"פסילה": true,
"פסילו": true,
"פסילי": true,
"פסילך": true,
"פסילם": true,
"פסילן": true,
"פסילת": true,
"פסימי": true,
"פסינו": true,
"פסיעה": true,
"פסיפס": true,
"פסיקה": true,
"פסיקו": true,
"פסיקי": true,
"פסיקך": true,
"פסיקם": true,
"פסיקן": true,
"פסיקת": true,
"פסלון": true,
"פסלות": true,
"פסליה": true,
"פסליו": true,
"פסליי": true,
"פסליך": true,
"פסלים": true,
"פסלכם": true,
"פסלכן": true,
"פסלנה": true,
"פסלנו": true,
"פסלתה": true,
"פסלתו": true,
"פסלתי": true,
"פסלתך": true,
"פסלתם": true,
"פסלתן": true,
"פסנתר": true,
"פסעיה": true,
"פסעיו": true,
"פסעיי": true,
"פסעיך": true,
"פסעים": true,
"פסעכם": true,
"פסעכן": true,
"פסענה": true,
"פסענו": true,
"פסעתי": true,
"פסעתם": true,
"פסעתן": true,
"פספוס": true,
"פספסה": true,
"פספסו": true,
"פספסי": true,
"פספסן": true,
"פספסת": true,
"פסקול": true,
"פסקות": true,
"פסקיה": true,
"פסקיו": true,
"פסקיי": true,
"פסקיך": true,
"פסקים": true,
"פסקכם": true,
"פסקכן": true,
"פסקנה": true,
"פסקנו": true,
"פסקני": true,
"פסקתה": true,
"פסקתו": true,
"פסקתי": true,
"פסקתך": true,
"פסקתם": true,
"פסקתן": true,
"פעוטה": true,
"פעוטו": true,
"פעוטי": true,
"פעוטך": true,
"פעוטם": true,
"פעוטן": true,
"פעולה": true,
"פעולי": true,
"פעולת": true,
"פעורה": true,
"פעיות": true,
"פעייה": true,
"פעילה": true,
"פעילו": true,
"פעילי": true,
"פעילך": true,
"פעילם": true,
"פעילן": true,
"פעימה": true,
"פעינה": true,
"פעינו": true,
"פעירה": true,
"פעיתי": true,
"פעיתם": true,
"פעיתן": true,
"פעלול": true,
"פעלות": true,
"פעליה": true,
"פעליו": true,
"פעליי": true,
"פעליך": true,
"פעלים": true,
"פעלנה": true,
"פעלנו": true,
"פעלתי": true,
"פעלתם": true,
"פעלתן": true,
"פעמון": true,
"פעמיה": true,
"פעמיו": true,
"פעמיי": true,
"פעמיך": true,
"פעמים": true,
"פעמית": true,
"פעמכם": true,
"פעמכן": true,
"פעמנה": true,
"פעמנו": true,
"פעמתי": true,
"פעמתם": true,
"פעמתן": true,
"פענוח": true,
"פענחה": true,
"פענחו": true,
"פענחי": true,
"פענחת": true,
"פעפוע": true,
"פעפעה": true,
"פעפעו": true,
"פעפעי": true,
"פעפעת": true,
"פעריה": true,
"פעריו": true,
"פעריי": true,
"פעריך": true,
"פערים": true,
"פערכם": true,
"פערכן": true,
"פערנה": true,
"פערנו": true,
"פערתי": true,
"פערתם": true,
"פערתן": true,
"פפואה": true,
"פפטיד": true,
"פפיות": true,
"פפקין": true,
"פפרצי": true,
"פצועה": true,
"פצועו": true,
"פצועי": true,
"פצועך": true,
"פצועם": true,
"פצוען": true,
"פצורה": true,
"פצחנה": true,
"פצחנו": true,
"פצחתי": true,
"פצחתם": true,
"פצחתן": true,
"פציבה": true,
"פציות": true,
"פציחה": true,
"פצייה": true,
"פצינה": true,
"פצינו": true,
"פציעה": true,
"פצירה": true,
"פציתי": true,
"פציתם": true,
"פציתן": true,
"פצלנה": true,
"פצעון": true,
"פצעיה": true,
"פצעיו": true,
"פצעיי": true,
"פצעיך": true,
"פצעים": true,
"פצעכם": true,
"פצעכן": true,
"פצענה": true,
"פצענו": true,
"פצעתי": true,
"פצעתם": true,
"פצעתן": true,
"פצפון": true,
"פצפוץ": true,
"פצפצה": true,
"פצפצו": true,
"פצפצי": true,
"פצפצת": true,
"פצצות": true,
"פצצתה": true,
"פצצתו": true,
"פצצתי": true,
"פצצתך": true,
"פצצתם": true,
"פצצתן": true,
"פצרנו": true,
"פצרתי": true,
"פצרתם": true,
"פצרתן": true,
"פקדון": true,
"פקדים": true,
"פקדית": true,
"פקדנה": true,
"פקדנו": true,
"פקדתי": true,
"פקדתם": true,
"פקדתן": true,
"פקודה": true,
"פקודו": true,
"פקודי": true,
"פקודך": true,
"פקודם": true,
"פקודן": true,
"פקודת": true,
"פקוחה": true,
"פקועה": true,
"פקוקה": true,
"פקחון": true,
"פקחיה": true,
"פקחיו": true,
"פקחיי": true,
"פקחיך": true,
"פקחים": true,
"פקחית": true,
"פקחכם": true,
"פקחכן": true,
"פקחנה": true,
"פקחנו": true,
"פקחתי": true,
"פקחתם": true,
"פקחתן": true,
"פקטור": true,
"פקטים": true,
"פקטין": true,
"פקטרה": true,
"פקטרו": true,
"פקטרי": true,
"פקטרת": true,
"פקידה": true,
"פקידו": true,
"פקידי": true,
"פקידך": true,
"פקידם": true,
"פקידן": true,
"פקידת": true,
"פקיחה": true,
"פקינז": true,
"פקיעה": true,
"פקנים": true,
"פקסוס": true,
"פקסים": true,
"פקססה": true,
"פקססו": true,
"פקססי": true,
"פקססת": true,
"פקעות": true,
"פקענה": true,
"פקענו": true,
"פקעתה": true,
"פקעתו": true,
"פקעתי": true,
"פקעתך": true,
"פקעתם": true,
"פקעתן": true,
"פקפוק": true,
"פקפקה": true,
"פקפקו": true,
"פקפקי": true,
"פקפקן": true,
"פקפקת": true,
"פקקיה": true,
"פקקיו": true,
"פקקיי": true,
"פקקיך": true,
"פקקים": true,
"פקקכם": true,
"פקקכן": true,
"פקקנו": true,
"פקקתי": true,
"פקקתם": true,
"פקקתן": true,
"פקשוש": true,
"פקששה": true,
"פקששו": true,
"פקששי": true,
"פקששת": true,
"פראות": true,
"פראטו": true,
"פראיה": true,
"פראיו": true,
"פראיי": true,
"פראיך": true,
"פראים": true,
"פראית": true,
"פראכם": true,
"פראכן": true,
"פראנו": true,
"פראנס": true,
"פראנק": true,
"פרברי": true,
"פרגוד": true,
"פרגול": true,
"פרגון": true,
"פרגיה": true,
"פרגיו": true,
"פרגיי": true,
"פרגיך": true,
"פרגים": true,
"פרגית": true,
"פרגכם": true,
"פרגכן": true,
"פרגנה": true,
"פרגנו": true,
"פרגני": true,
"פרגנת": true,
"פרדות": true,
"פרדיה": true,
"פרדיו": true,
"פרדיי": true,
"פרדיך": true,
"פרדים": true,
"פרדכם": true,
"פרדכן": true,
"פרדנו": true,
"פרדני": true,
"פרדסה": true,
"פרדסו": true,
"פרדסי": true,
"פרדסך": true,
"פרדסם": true,
"פרדסן": true,
"פרדתה": true,
"פרדתו": true,
"פרדתי": true,
"פרדתך": true,
"פרדתם": true,
"פרדתן": true,
"פרודה": true,
"פרודי": true,
"פרווה": true,
"פרווז": true,
"פרוון": true,
"פרוור": true,
"פרוות": true,
"פרוזה": true,
"פרוזק": true,
"פרוטה": true,
"פרויד": true,
"פרוכת": true,
"פרומה": true,
"פרומו": true,
"פרומן": true,
"פרומר": true,
"פרונט": true,
"פרוסה": true,
"פרוסט": true,
"פרוסי": true,
"פרועה": true,
"פרופה": true,
"פרופס": true,
"פרוצה": true,
"פרוקה": true,
"פרוקצ": true,
"פרושה": true,
"פרושי": true,
"פרזול": true,
"פרזות": true,
"פרזיט": true,
"פרזלה": true,
"פרזלו": true,
"פרזלי": true,
"פרזלת": true,
"פרזנה": true,
"פרחחה": true,
"פרחחו": true,
"פרחחי": true,
"פרחחך": true,
"פרחחם": true,
"פרחחן": true,
"פרחיה": true,
"פרחיו": true,
"פרחיי": true,
"פרחיך": true,
"פרחים": true,
"פרחכם": true,
"פרחכן": true,
"פרחנה": true,
"פרחנו": true,
"פרחתי": true,
"פרחתם": true,
"פרחתן": true,
"פרטיה": true,
"פרטיו": true,
"פרטיי": true,
"פרטיך": true,
"פרטים": true,
"פרטית": true,
"פרטכם": true,
"פרטכן": true,
"פרטנה": true,
"פרטנו": true,
"פרטני": true,
"פרטנר": true,
"פרטתי": true,
"פרטתם": true,
"פרטתן": true,
"פריאל": true,
"פרידה": true,
"פרידל": true,
"פריהם": true,
"פריהן": true,
"פריון": true,
"פריות": true,
"פריזר": true,
"פריחה": true,
"פריחת": true,
"פריטה": true,
"פריטו": true,
"פריטי": true,
"פריטך": true,
"פריטל": true,
"פריטם": true,
"פריטן": true,
"פרייה": true,
"פרייך": true,
"פריים": true,
"פריין": true,
"פרייס": true,
"פרייר": true,
"פריכה": true,
"פריכם": true,
"פריכן": true,
"פרילי": true,
"פרימה": true,
"פרימט": true,
"פרינה": true,
"פרינו": true,
"פרינס": true,
"פריסה": true,
"פריסת": true,
"פריעה": true,
"פריפה": true,
"פריצה": true,
"פריצו": true,
"פריצי": true,
"פריצך": true,
"פריצם": true,
"פריצן": true,
"פריצת": true,
"פריקה": true,
"פריקי": true,
"פרישה": true,
"פריתי": true,
"פריתם": true,
"פריתן": true,
"פרכוס": true,
"פרכות": true,
"פרכסה": true,
"פרכסו": true,
"פרכסי": true,
"פרכסת": true,
"פרכתה": true,
"פרכתו": true,
"פרכתי": true,
"פרכתך": true,
"פרכתם": true,
"פרכתן": true,
"פרלוד": true,
"פרלין": true,
"פרמוט": true,
"פרמזן": true,
"פרמטה": true,
"פרמטו": true,
"פרמטי": true,
"פרמטר": true,
"פרמטת": true,
"פרמיה": true,
"פרמנו": true,
"פרמתי": true,
"פרמתם": true,
"פרמתן": true,
"פרנוס": true,
"פרנסה": true,
"פרנסו": true,
"פרנסי": true,
"פרנסך": true,
"פרנסם": true,
"פרנסן": true,
"פרנסת": true,
"פרנקו": true,
"פרנקל": true,
"פרסום": true,
"פרסון": true,
"פרסות": true,
"פרסטר": true,
"פרסיה": true,
"פרסיו": true,
"פרסיי": true,
"פרסיך": true,
"פרסים": true,
"פרסית": true,
"פרסכם": true,
"פרסכן": true,
"פרסמה": true,
"פרסמו": true,
"פרסמי": true,
"פרסמת": true,
"פרסנו": true,
"פרסקו": true,
"פרסקי": true,
"פרסתה": true,
"פרסתו": true,
"פרסתי": true,
"פרסתך": true,
"פרסתם": true,
"פרסתן": true,
"פרעון": true,
"פרעוש": true,
"פרעות": true,
"פרענה": true,
"פרענו": true,
"פרעתי": true,
"פרעתם": true,
"פרעתן": true,
"פרפור": true,
"פרפנו": true,
"פרפרה": true,
"פרפרו": true,
"פרפרי": true,
"פרפרך": true,
"פרפרם": true,
"פרפרן": true,
"פרפרת": true,
"פרפתי": true,
"פרפתם": true,
"פרפתן": true,
"פרצוף": true,
"פרצות": true,
"פרציה": true,
"פרציו": true,
"פרציי": true,
"פרציך": true,
"פרצים": true,
"פרציק": true,
"פרצכם": true,
"פרצכן": true,
"פרצנו": true,
"פרצתה": true,
"פרצתו": true,
"פרצתי": true,
"פרצתך": true,
"פרצתם": true,
"פרצתן": true,
"פרקדן": true,
"פרקטי": true,
"פרקטל": true,
"פרקיה": true,
"פרקיו": true,
"פרקיי": true,
"פרקיך": true,
"פרקים": true,
"פרקכם": true,
"פרקכן": true,
"פרקנה": true,
"פרקנו": true,
"פרקתי": true,
"פרקתם": true,
"פרקתן": true,
"פרשון": true,
"פרשות": true,
"פרשיה": true,
"פרשיו": true,
"פרשיי": true,
"פרשיך": true,
"פרשים": true,
"פרשית": true,
"פרשכם": true,
"פרשכן": true,
"פרשנה": true,
"פרשנו": true,
"פרשני": true,
"פרשנך": true,
"פרשנם": true,
"פרשנן": true,
"פרשנת": true,
"פרשתה": true,
"פרשתו": true,
"פרשתי": true,
"פרשתך": true,
"פרשתם": true,
"פרשתן": true,
"פרתכם": true,
"פרתכן": true,
"פרתנו": true,
"פשוטה": true,
"פשוטו": true,
"פשוטי": true,
"פשוטך": true,
"פשוטם": true,
"פשוטן": true,
"פשושה": true,
"פשושו": true,
"פשושי": true,
"פשושך": true,
"פשושם": true,
"פשושן": true,
"פשטון": true,
"פשטות": true,
"פשטנה": true,
"פשטנו": true,
"פשטני": true,
"פשטתי": true,
"פשטתם": true,
"פשטתן": true,
"פשיות": true,
"פשיזם": true,
"פשיטה": true,
"פשיטת": true,
"פשייה": true,
"פשינה": true,
"פשינו": true,
"פשיסט": true,
"פשיעה": true,
"פשיתי": true,
"פשיתם": true,
"פשיתן": true,
"פשלות": true,
"פשלנה": true,
"פשלתה": true,
"פשלתו": true,
"פשלתי": true,
"פשלתך": true,
"פשלתם": true,
"פשלתן": true,
"פשעיה": true,
"פשעיו": true,
"פשעיי": true,
"פשעיך": true,
"פשעים": true,
"פשעכם": true,
"פשעכן": true,
"פשענה": true,
"פשענו": true,
"פשעתי": true,
"פשעתם": true,
"פשעתן": true,
"פשפוש": true,
"פשפשה": true,
"פשפשו": true,
"פשפשי": true,
"פשפשך": true,
"פשפשם": true,
"פשפשן": true,
"פשפשת": true,
"פשקנה": true,
"פשרות": true,
"פשריה": true,
"פשריו": true,
"פשריי": true,
"פשריך": true,
"פשרים": true,
"פשרכם": true,
"פשרכן": true,
"פשרנה": true,
"פשרנו": true,
"פשרני": true,
"פשרתה": true,
"פשרתו": true,
"פשרתי": true,
"פשרתך": true,
"פשרתם": true,
"פשרתן": true,
"פשתיה": true,
"פשתיו": true,
"פשתיי": true,
"פשתיך": true,
"פשתים": true,
"פשתנה": true,
"פשתנו": true,
"פשתני": true,
"פשתנך": true,
"פשתנם": true,
"פשתנן": true,
"פשתתה": true,
"פשתתו": true,
"פשתתי": true,
"פשתתך": true,
"פשתתם": true,
"פשתתן": true,
"פתאום": true,
"פתאטי": true,
"פתאיה": true,
"פתאיו": true,
"פתאיי": true,
"פתאיך": true,
"פתאים": true,
"פתגמה": true,
"פתגמו": true,
"פתגמי": true,
"פתגמך": true,
"פתגמם": true,
"פתגמן": true,
"פתוגן": true,
"פתוות": true,
"פתוחה": true,
"פתורה": true,
"פתחון": true,
"פתחות": true,
"פתחיה": true,
"פתחיו": true,
"פתחיי": true,
"פתחיך": true,
"פתחים": true,
"פתחכם": true,
"פתחכן": true,
"פתחנה": true,
"פתחנו": true,
"פתחתי": true,
"פתחתם": true,
"פתחתן": true,
"פתטית": true,
"פתיהם": true,
"פתיהן": true,
"פתיון": true,
"פתיות": true,
"פתיחה": true,
"פתיחו": true,
"פתיחי": true,
"פתיחך": true,
"פתיחם": true,
"פתיחן": true,
"פתיחת": true,
"פתייה": true,
"פתייו": true,
"פתייך": true,
"פתיים": true,
"פתיין": true,
"פתיכם": true,
"פתיכן": true,
"פתילה": true,
"פתילו": true,
"פתילי": true,
"פתילך": true,
"פתילם": true,
"פתילן": true,
"פתינה": true,
"פתינו": true,
"פתירה": true,
"פתיתה": true,
"פתיתו": true,
"פתיתי": true,
"פתיתך": true,
"פתיתם": true,
"פתיתן": true,
"פתלנה": true,
"פתלתל": true,
"פתניה": true,
"פתניו": true,
"פתניי": true,
"פתניך": true,
"פתנים": true,
"פתנכם": true,
"פתנכן": true,
"פתננו": true,
"פתעות": true,
"פתקיה": true,
"פתקיו": true,
"פתקיי": true,
"פתקיך": true,
"פתקים": true,
"פתקית": true,
"פתקכם": true,
"פתקכן": true,
"פתקנו": true,
"פתקתה": true,
"פתקתו": true,
"פתקתי": true,
"פתקתך": true,
"פתקתם": true,
"פתקתן": true,
"פתרון": true,
"פתרנו": true,
"פתרתי": true,
"פתרתם": true,
"פתרתן": true,
"צאברי": true,
"צאווז": true,
"צאיות": true,
"צאלים": true,
"צאנכם": true,
"צאנכן": true,
"צאננו": true,
"צאצאה": true,
"צאצאו": true,
"צאצאי": true,
"צאצאך": true,
"צאצאם": true,
"צאצאן": true,
"צארים": true,
"צארית": true,
"צארלס": true,
"צאתכם": true,
"צאתכן": true,
"צאתנו": true,
"צבאות": true,
"צבאיה": true,
"צבאיו": true,
"צבאיי": true,
"צבאיך": true,
"צבאים": true,
"צבאית": true,
"צבאכם": true,
"צבאכן": true,
"צבאנה": true,
"צבאנו": true,
"צבאתי": true,
"צבאתם": true,
"צבאתן": true,
"צבוטה": true,
"צבועה": true,
"צבועו": true,
"צבועי": true,
"צבועך": true,
"צבועם": true,
"צבוען": true,
"צבורג": true,
"צבורה": true,
"צבטנו": true,
"צבטתי": true,
"צבטתם": true,
"צבטתן": true,
"צביאל": true,
"צביהם": true,
"צביהן": true,
"צביון": true,
"צביות": true,
"צביטה": true,
"צבייה": true,
"צבייו": true,
"צבייך": true,
"צביים": true,
"צביין": true,
"צביכם": true,
"צביכן": true,
"צבינה": true,
"צבינו": true,
"צביעה": true,
"צביעת": true,
"צביקה": true,
"צביקי": true,
"צבירה": true,
"צבירו": true,
"צבירי": true,
"צבירך": true,
"צבירם": true,
"צבירן": true,
"צבירת": true,
"צביתי": true,
"צביתם": true,
"צביתן": true,
"צבעות": true,
"צבעיה": true,
"צבעיו": true,
"צבעיי": true,
"צבעיך": true,
"צבעים": true,
"צבעית": true,
"צבעכם": true,
"צבעכן": true,
"צבענה": true,
"צבענו": true,
"צבעני": true,
"צבענך": true,
"צבענם": true,
"צבענן": true,
"צבעתי": true,
"צבעתם": true,
"צבעתן": true,
"צברוח": true,
"צברחה": true,
"צברחו": true,
"צברחי": true,
"צברחת": true,
"צבריה": true,
"צבריו": true,
"צבריי": true,
"צבריך": true,
"צברים": true,
"צברית": true,
"צברכם": true,
"צברכן": true,
"צברנו": true,
"צברתי": true,
"צברתם": true,
"צברתן": true,
"צבתות": true,
"צבתכם": true,
"צבתכן": true,
"צבתנו": true,
"צגיהם": true,
"צגיהן": true,
"צגייך": true,
"צגיכם": true,
"צגיכן": true,
"צגינו": true,
"צדדיה": true,
"צדדיו": true,
"צדדיי": true,
"צדדיך": true,
"צדדים": true,
"צדדית": true,
"צדדנה": true,
"צדוקי": true,
"צדורה": true,
"צדיהם": true,
"צדיהן": true,
"צדיות": true,
"צדייך": true,
"צדיים": true,
"צדיכם": true,
"צדיכן": true,
"צדינו": true,
"צדיקה": true,
"צדיקו": true,
"צדיקי": true,
"צדיקך": true,
"צדיקם": true,
"צדיקן": true,
"צדעיה": true,
"צדעיו": true,
"צדעיי": true,
"צדעיך": true,
"צדעכם": true,
"צדעכן": true,
"צדענו": true,
"צדפות": true,
"צדפיה": true,
"צדפיו": true,
"צדפיי": true,
"צדפיך": true,
"צדפים": true,
"צדפכם": true,
"צדפכן": true,
"צדפנו": true,
"צדפתה": true,
"צדפתו": true,
"צדפתי": true,
"צדפתך": true,
"צדפתם": true,
"צדפתן": true,
"צדקות": true,
"צדקיה": true,
"צדקכם": true,
"צדקכן": true,
"צדקנו": true,
"צדקני": true,
"צדקתה": true,
"צדקתו": true,
"צדקתי": true,
"צדקתך": true,
"צדקתם": true,
"צדקתן": true,
"צהבהב": true,
"צהבתה": true,
"צהבתו": true,
"צהבתי": true,
"צהבתך": true,
"צהבתם": true,
"צהבתן": true,
"צהובה": true,
"צהירה": true,
"צהירו": true,
"צהלות": true,
"צהלנה": true,
"צהלנו": true,
"צהלתה": true,
"צהלתו": true,
"צהלתי": true,
"צהלתך": true,
"צהלתם": true,
"צהלתן": true,
"צהרון": true,
"צהרות": true,
"צהריה": true,
"צהריו": true,
"צהריי": true,
"צהריך": true,
"צהרים": true,
"צואות": true,
"צואתה": true,
"צואתו": true,
"צואתי": true,
"צואתך": true,
"צואתם": true,
"צואתן": true,
"צובאת": true,
"צובות": true,
"צובטת": true,
"צובים": true,
"צוביץ": true,
"צובעת": true,
"צוברח": true,
"צוברת": true,
"צודדה": true,
"צודדו": true,
"צודדי": true,
"צודדת": true,
"צודנה": true,
"צודקת": true,
"צוהלת": true,
"צוהרה": true,
"צוהרו": true,
"צוהרי": true,
"צוהרך": true,
"צוהרם": true,
"צוהרן": true,
"צוואה": true,
"צוואר": true,
"צווחה": true,
"צווחו": true,
"צווחי": true,
"צווחן": true,
"צווחת": true,
"צוויה": true,
"צוויו": true,
"צוויי": true,
"צוויך": true,
"צווים": true,
"צווית": true,
"צווכם": true,
"צווכן": true,
"צוונו": true,
"צוותה": true,
"צוותו": true,
"צוותי": true,
"צוותך": true,
"צוותם": true,
"צוותן": true,
"צוחצח": true,
"צוחקת": true,
"צוטטה": true,
"צוטטו": true,
"צוטטת": true,
"צוידה": true,
"צוידו": true,
"צוידת": true,
"צויות": true,
"צוייג": true,
"צוייד": true,
"צויין": true,
"צוינה": true,
"צוינו": true,
"צוינת": true,
"צוירה": true,
"צוירו": true,
"צוירת": true,
"צויתה": true,
"צויתו": true,
"צויתי": true,
"צויתם": true,
"צויתן": true,
"צולבת": true,
"צולות": true,
"צולחת": true,
"צולים": true,
"צוללן": true,
"צוללת": true,
"צולמה": true,
"צולמו": true,
"צולמת": true,
"צולעת": true,
"צולפת": true,
"צומדה": true,
"צומדו": true,
"צומדת": true,
"צומות": true,
"צומחת": true,
"צומכם": true,
"צומכן": true,
"צומנה": true,
"צומנו": true,
"צומצם": true,
"צומקה": true,
"צומקו": true,
"צומקת": true,
"צומרר": true,
"צומתה": true,
"צומתו": true,
"צומתי": true,
"צומתך": true,
"צומתם": true,
"צומתן": true,
"צונזר": true,
"צונחת": true,
"צונמי": true,
"צוננה": true,
"צוננו": true,
"צוננת": true,
"צועדת": true,
"צועות": true,
"צועים": true,
"צועני": true,
"צועקת": true,
"צוערה": true,
"צוערו": true,
"צוערי": true,
"צוערך": true,
"צוערם": true,
"צוערן": true,
"צוערת": true,
"צופהו": true,
"צופות": true,
"צופיה": true,
"צופיו": true,
"צופיי": true,
"צופיך": true,
"צופים": true,
"צופית": true,
"צופכם": true,
"צופכן": true,
"צופנה": true,
"צופנו": true,
"צופני": true,
"צופנך": true,
"צופנם": true,
"צופנן": true,
"צופנת": true,
"צופפה": true,
"צופפו": true,
"צופפי": true,
"צופפת": true,
"צופצה": true,
"צופרה": true,
"צופרו": true,
"צופרי": true,
"צופרך": true,
"צופרם": true,
"צופרן": true,
"צופרת": true,
"צופתה": true,
"צופתו": true,
"צופתי": true,
"צופתך": true,
"צופתם": true,
"צופתן": true,
"צוציק": true,
"צוצנה": true,
"צוקיה": true,
"צוקיו": true,
"צוקיי": true,
"צוקיך": true,
"צוקים": true,
"צוקכם": true,
"צוקכן": true,
"צוקנה": true,
"צוקנו": true,
"צורבת": true,
"צורות": true,
"צורחת": true,
"צוריה": true,
"צוריו": true,
"צוריי": true,
"צוריך": true,
"צורים": true,
"צוריף": true,
"צורכה": true,
"צורכו": true,
"צורכי": true,
"צורכך": true,
"צורכם": true,
"צורכן": true,
"צורכת": true,
"צורמת": true,
"צורנה": true,
"צורנו": true,
"צורני": true,
"צורפה": true,
"צורפו": true,
"צורפת": true,
"צוררת": true,
"צורתה": true,
"צורתו": true,
"צורתי": true,
"צורתך": true,
"צורתם": true,
"צורתן": true,
"צותתה": true,
"צותתו": true,
"צותתי": true,
"צותתם": true,
"צותתן": true,
"צזיום": true,
"צחוקה": true,
"צחוקו": true,
"צחוקי": true,
"צחוקך": true,
"צחוקם": true,
"צחוקן": true,
"צחורה": true,
"צחותה": true,
"צחותו": true,
"צחותי": true,
"צחותך": true,
"צחותם": true,
"צחותן": true,
"צחיחה": true,
"צחנות": true,
"צחנתה": true,
"צחנתו": true,
"צחנתי": true,
"צחנתך": true,
"צחנתם": true,
"צחנתן": true,
"צחצוח": true,
"צחצחה": true,
"צחצחו": true,
"צחצחי": true,
"צחצחת": true,
"צחקוק": true,
"צחקנה": true,
"צחקנו": true,
"צחקקה": true,
"צחקקו": true,
"צחקקי": true,
"צחקקת": true,
"צחקתי": true,
"צחקתם": true,
"צחקתן": true,
"צטטנה": true,
"ציאנה": true,
"ציבור": true,
"ציבים": true,
"ציבין": true,
"צידדה": true,
"צידדו": true,
"צידדת": true,
"צידוד": true,
"צידון": true,
"צידוק": true,
"צידות": true,
"צידיה": true,
"צידיו": true,
"צידיי": true,
"צידיך": true,
"צידים": true,
"צידכם": true,
"צידכן": true,
"צידנו": true,
"צידתה": true,
"צידתו": true,
"צידתי": true,
"צידתך": true,
"צידתם": true,
"צידתן": true,
"ציודה": true,
"ציודו": true,
"ציודי": true,
"ציודך": true,
"ציודם": true,
"ציודן": true,
"ציווה": true,
"ציווי": true,
"ציוות": true,
"ציונה": true,
"ציונו": true,
"ציוני": true,
"ציונך": true,
"ציונם": true,
"ציונן": true,
"ציוצה": true,
"ציוצו": true,
"ציוצי": true,
"ציוצך": true,
"ציוצם": true,
"ציוצן": true,
"ציורה": true,
"ציורו": true,
"ציורי": true,
"ציורך": true,
"ציורם": true,
"ציורן": true,
"ציותה": true,
"ציותו": true,
"ציותי": true,
"ציותך": true,
"ציותם": true,
"ציותן": true,
"ציזיק": true,
"ציטוט": true,
"ציטטה": true,
"ציטטו": true,
"ציטטת": true,
"ציידה": true,
"ציידו": true,
"ציידי": true,
"ציידך": true,
"ציידם": true,
"ציידן": true,
"ציידת": true,
"צייהם": true,
"צייהן": true,
"צייכם": true,
"צייכן": true,
"ציינה": true,
"ציינו": true,
"צייני": true,
"ציינת": true,
"צייצה": true,
"צייצו": true,
"צייצי": true,
"צייצת": true,
"צייקן": true,
"ציירה": true,
"ציירו": true,
"ציירי": true,
"ציירך": true,
"ציירם": true,
"ציירן": true,
"ציירת": true,
"צייתה": true,
"צייתו": true,
"צייתי": true,
"צייתם": true,
"צייתן": true,
"צילום": true,
"צילון": true,
"צילוק": true,
"צילמה": true,
"צילמו": true,
"צילמת": true,
"צילקה": true,
"צילקו": true,
"צילקת": true,
"צימדה": true,
"צימדו": true,
"צימדת": true,
"צימוד": true,
"צימוח": true,
"צימוק": true,
"צימחה": true,
"צימחו": true,
"צימחת": true,
"צימלי": true,
"צימקה": true,
"צימקו": true,
"צימקת": true,
"צימרה": true,
"צימרו": true,
"צימרי": true,
"צימרך": true,
"צימרם": true,
"צימרן": true,
"צינון": true,
"צינוק": true,
"צינור": true,
"צינות": true,
"צינים": true,
"צינית": true,
"ציננה": true,
"ציננו": true,
"ציננת": true,
"צינתה": true,
"צינתו": true,
"צינתי": true,
"צינתך": true,
"צינתם": true,
"צינתן": true,
"ציסטה": true,
"ציעור": true,
"ציערה": true,
"ציערו": true,
"ציערת": true,
"ציפוי": true,
"ציפוף": true,
"ציפור": true,
"ציפות": true,
"ציפיה": true,
"ציפית": true,
"ציפלי": true,
"ציפתה": true,
"ציפתו": true,
"ציפתי": true,
"ציפתך": true,
"ציפתם": true,
"ציפתן": true,
"ציצות": true,
"ציציה": true,
"ציציו": true,
"ציציי": true,
"ציציך": true,
"ציצים": true,
"ציצית": true,
"ציצכם": true,
"ציצכן": true,
"ציצנו": true,
"ציצתה": true,
"ציצתו": true,
"ציצתי": true,
"ציצתך": true,
"ציצתם": true,
"ציצתן": true,
"ציקדה": true,
"ציקלי": true,
"צירוף": true,
"צירות": true,
"ציריה": true,
"ציריו": true,
"ציריי": true,
"ציריך": true,
"צירים": true,
"צירית": true,
"צירכם": true,
"צירכן": true,
"צירנו": true,
"צירפה": true,
"צירפו": true,
"צירפת": true,
"צירתה": true,
"צירתו": true,
"צירתי": true,
"צירתך": true,
"צירתם": true,
"צירתן": true,
"ציתות": true,
"צלביה": true,
"צלביו": true,
"צלביי": true,
"צלביך": true,
"צלבים": true,
"צלבכם": true,
"צלבכן": true,
"צלבנה": true,
"צלבנו": true,
"צלבני": true,
"צלבנך": true,
"צלבנם": true,
"צלבנן": true,
"צלבתי": true,
"צלבתם": true,
"צלבתן": true,
"צלובה": true,
"צלויה": true,
"צלולה": true,
"צלונה": true,
"צלופח": true,
"צלופן": true,
"צלחות": true,
"צלחנה": true,
"צלחנו": true,
"צלחתה": true,
"צלחתו": true,
"צלחתי": true,
"צלחתך": true,
"צלחתם": true,
"צלחתן": true,
"צליאק": true,
"צליבה": true,
"צליות": true,
"צליחה": true,
"צלייה": true,
"צלייו": true,
"צלייך": true,
"צליים": true,
"צליין": true,
"צליכם": true,
"צליכן": true,
"צלילה": true,
"צלילו": true,
"צלילי": true,
"צלילך": true,
"צלילם": true,
"צלילן": true,
"צלינה": true,
"צלינו": true,
"צליעה": true,
"צליפה": true,
"צליתי": true,
"צליתם": true,
"צליתן": true,
"צלליה": true,
"צלליו": true,
"צלליי": true,
"צלליך": true,
"צללים": true,
"צללית": true,
"צללנו": true,
"צללתי": true,
"צללתם": true,
"צללתן": true,
"צלמון": true,
"צלמות": true,
"צלמיה": true,
"צלמיו": true,
"צלמיי": true,
"צלמיך": true,
"צלמים": true,
"צלמית": true,
"צלמכם": true,
"צלמכן": true,
"צלמנה": true,
"צלמנו": true,
"צלמתה": true,
"צלמתו": true,
"צלמתי": true,
"צלמתך": true,
"צלמתם": true,
"צלמתן": true,
"צלעות": true,
"צלעכם": true,
"צלעכן": true,
"צלענה": true,
"צלענו": true,
"צלעתי": true,
"צלעתם": true,
"צלעתן": true,
"צלפיה": true,
"צלפיו": true,
"צלפיי": true,
"צלפיך": true,
"צלפים": true,
"צלפית": true,
"צלפכם": true,
"צלפכן": true,
"צלפנו": true,
"צלפתי": true,
"צלפתם": true,
"צלפתן": true,
"צלצול": true,
"צלצלה": true,
"צלצלו": true,
"צלצלי": true,
"צלצלך": true,
"צלצלם": true,
"צלצלן": true,
"צלצלת": true,
"צלקות": true,
"צלקנה": true,
"צלקתה": true,
"צלקתו": true,
"צלקתי": true,
"צלקתך": true,
"צלקתם": true,
"צלקתן": true,
"צמאון": true,
"צמאות": true,
"צמאים": true,
"צמאכם": true,
"צמאכן": true,
"צמאנה": true,
"צמאנו": true,
"צמאתי": true,
"צמאתם": true,
"צמאתן": true,
"צמדיה": true,
"צמדיו": true,
"צמדיי": true,
"צמדיך": true,
"צמדים": true,
"צמדכם": true,
"צמדכן": true,
"צמדנה": true,
"צמדנו": true,
"צמודה": true,
"צמודי": true,
"צמחיה": true,
"צמחיו": true,
"צמחיי": true,
"צמחיך": true,
"צמחים": true,
"צמחית": true,
"צמחכם": true,
"צמחכן": true,
"צמחנה": true,
"צמחנו": true,
"צמחתי": true,
"צמחתם": true,
"צמחתן": true,
"צמיגה": true,
"צמיגו": true,
"צמיגי": true,
"צמיגך": true,
"צמיגם": true,
"צמיגן": true,
"צמידה": true,
"צמידו": true,
"צמידי": true,
"צמידך": true,
"צמידם": true,
"צמידן": true,
"צמיחה": true,
"צמיחת": true,
"צמיתה": true,
"צמיתו": true,
"צמיתי": true,
"צמיתך": true,
"צמיתם": true,
"צמיתן": true,
"צמנטי": true,
"צמצום": true,
"צמצמה": true,
"צמצמו": true,
"צמצמי": true,
"צמצמך": true,
"צמצמם": true,
"צמצמן": true,
"צמצמת": true,
"צמקנה": true,
"צמרור": true,
"צמרות": true,
"צמריה": true,
"צמריו": true,
"צמריי": true,
"צמריך": true,
"צמרים": true,
"צמרית": true,
"צמרכם": true,
"צמרכן": true,
"צמרנו": true,
"צמררה": true,
"צמררו": true,
"צמררי": true,
"צמררת": true,
"צמרתה": true,
"צמרתו": true,
"צמרתי": true,
"צמרתך": true,
"צמרתם": true,
"צמרתן": true,
"צמתיה": true,
"צמתיו": true,
"צמתיי": true,
"צמתיך": true,
"צמתים": true,
"צמתכם": true,
"צמתכן": true,
"צמתנו": true,
"צנובר": true,
"צנומה": true,
"צנונה": true,
"צנונו": true,
"צנוני": true,
"צנונך": true,
"צנונם": true,
"צנונן": true,
"צנועה": true,
"צנופה": true,
"צנזור": true,
"צנזרה": true,
"צנזרו": true,
"צנזרי": true,
"צנזרת": true,
"צנחנה": true,
"צנחנו": true,
"צנחני": true,
"צנחנך": true,
"צנחנם": true,
"צנחנן": true,
"צנחתי": true,
"צנחתם": true,
"צנחתן": true,
"צנטרי": true,
"צניחה": true,
"צנימה": true,
"צנימו": true,
"צנימי": true,
"צנימך": true,
"צנימם": true,
"צנימן": true,
"צנינה": true,
"צנינו": true,
"צניני": true,
"צנינך": true,
"צנינם": true,
"צנינן": true,
"צניפה": true,
"צניפו": true,
"צניפי": true,
"צניפך": true,
"צניפם": true,
"צניפן": true,
"צנעתה": true,
"צנעתו": true,
"צנעתי": true,
"צנעתך": true,
"צנעתם": true,
"צנעתן": true,
"צנפות": true,
"צנפתה": true,
"צנפתו": true,
"צנפתי": true,
"צנפתך": true,
"צנפתם": true,
"צנפתן": true,
"צנצנת": true,
"צנרות": true,
"צנרתה": true,
"צנרתו": true,
"צנרתי": true,
"צנרתך": true,
"צנרתם": true,
"צנרתן": true,
"צנתור": true,
"צנתרה": true,
"צנתרו": true,
"צנתרי": true,
"צנתרך": true,
"צנתרם": true,
"צנתרן": true,
"צנתרת": true,
"צעדות": true,
"צעדיה": true,
"צעדיו": true,
"צעדיי": true,
"צעדיך": true,
"צעדים": true,
"צעדכם": true,
"צעדכן": true,
"צעדנה": true,
"צעדנו": true,
"צעדתה": true,
"צעדתו": true,
"צעדתי": true,
"צעדתך": true,
"צעדתם": true,
"צעדתן": true,
"צעידה": true,
"צעיפה": true,
"צעיפו": true,
"צעיפי": true,
"צעיפך": true,
"צעיפם": true,
"צעיפן": true,
"צעירה": true,
"צעירו": true,
"צעירי": true,
"צעירך": true,
"צעירם": true,
"צעירן": true,
"צעצוע": true,
"צעקות": true,
"צעקנה": true,
"צעקנו": true,
"צעקני": true,
"צעקתה": true,
"צעקתו": true,
"צעקתי": true,
"צעקתך": true,
"צעקתם": true,
"צעקתן": true,
"צערכם": true,
"צערכן": true,
"צערנה": true,
"צערנו": true,
"צפדות": true,
"צפדתה": true,
"צפדתו": true,
"צפדתי": true,
"צפדתך": true,
"צפדתם": true,
"צפדתן": true,
"צפודה": true,
"צפויה": true,
"צפונה": true,
"צפונו": true,
"צפוני": true,
"צפונך": true,
"צפונם": true,
"צפונן": true,
"צפופה": true,
"צפורה": true,
"צפחות": true,
"צפחתה": true,
"צפחתו": true,
"צפחתי": true,
"צפחתך": true,
"צפחתם": true,
"צפחתן": true,
"צפידה": true,
"צפיות": true,
"צפייה": true,
"צפינה": true,
"צפינו": true,
"צפירה": true,
"צפיתי": true,
"צפיתם": true,
"צפיתן": true,
"צפלון": true,
"צפלין": true,
"צפניה": true,
"צפניו": true,
"צפניי": true,
"צפניך": true,
"צפנים": true,
"צפנתי": true,
"צפנתם": true,
"צפנתן": true,
"צפעיה": true,
"צפעיו": true,
"צפעיי": true,
"צפעיך": true,
"צפעים": true,
"צפעכם": true,
"צפעכן": true,
"צפענו": true,
"צפצוף": true,
"צפצפה": true,
"צפצפו": true,
"צפצפי": true,
"צפצפת": true,
"צפרדע": true,
"צפרות": true,
"צפריה": true,
"צפריו": true,
"צפריי": true,
"צפריך": true,
"צפרים": true,
"צפריר": true,
"צפרית": true,
"צפרכם": true,
"צפרכן": true,
"צפרנו": true,
"צפרתי": true,
"צפרתם": true,
"צפרתן": true,
"צפתים": true,
"צפתית": true,
"צצניה": true,
"צצנים": true,
"צקלון": true,
"צקצוק": true,
"צקצקה": true,
"צקצקו": true,
"צקצקי": true,
"צקצקת": true,
"צרבות": true,
"צרבנו": true,
"צרבתה": true,
"צרבתו": true,
"צרבתי": true,
"צרבתך": true,
"צרבתם": true,
"צרבתן": true,
"צרובה": true,
"צרודה": true,
"צרויה": true,
"צרופה": true,
"צרורה": true,
"צרורו": true,
"צרורי": true,
"צרורך": true,
"צרורם": true,
"צרורן": true,
"צרחות": true,
"צרחנה": true,
"צרחנו": true,
"צרחני": true,
"צרחתה": true,
"צרחתו": true,
"צרחתי": true,
"צרחתך": true,
"צרחתם": true,
"צרחתן": true,
"צריבה": true,
"צריהם": true,
"צריהן": true,
"צריום": true,
"צריחה": true,
"צריחו": true,
"צריחי": true,
"צריחך": true,
"צריחם": true,
"צריחן": true,
"צרייך": true,
"צריכה": true,
"צריכם": true,
"צריכן": true,
"צריכת": true,
"צרימה": true,
"צרינו": true,
"צריפה": true,
"צריפו": true,
"צריפי": true,
"צריפך": true,
"צריפם": true,
"צריפן": true,
"צרירה": true,
"צרכיה": true,
"צרכיו": true,
"צרכיי": true,
"צרכיך": true,
"צרכים": true,
"צרכנה": true,
"צרכנו": true,
"צרכני": true,
"צרכנך": true,
"צרכנם": true,
"צרכנן": true,
"צרכתי": true,
"צרכתם": true,
"צרכתן": true,
"צרמנו": true,
"צרמתי": true,
"צרמתם": true,
"צרמתן": true,
"צרעות": true,
"צרעתה": true,
"צרעתו": true,
"צרעתי": true,
"צרעתך": true,
"צרעתם": true,
"צרעתן": true,
"צרפנה": true,
"צרפנו": true,
"צרפתה": true,
"צרפתי": true,
"צרפתם": true,
"צרפתן": true,
"צרצור": true,
"צרציל": true,
"צרצרה": true,
"צרצרו": true,
"צרצרי": true,
"צרצרך": true,
"צרצרם": true,
"צרצרן": true,
"צרצרת": true,
"צררנו": true,
"צררתי": true,
"צררתם": true,
"צררתן": true,
"צרתכם": true,
"צרתכן": true,
"צרתנו": true,
"קאבול": true,
"קאדים": true,
"קאידה": true,
"קאמפף": true,
"קאמרי": true,
"קאנאל": true,
"קאעדה": true,
"קארין": true,
"קארלו": true,
"קארשי": true,
"קבאבה": true,
"קבאבו": true,
"קבאבי": true,
"קבאבך": true,
"קבאבם": true,
"קבאבן": true,
"קבונו": true,
"קבועה": true,
"קבוצה": true,
"קבוצת": true,
"קבוקי": true,
"קבורה": true,
"קבותי": true,
"קבותם": true,
"קבותן": true,
"קבטיה": true,
"קבטים": true,
"קביהם": true,
"קביהן": true,
"קביות": true,
"קבייך": true,
"קביים": true,
"קביכם": true,
"קביכן": true,
"קבילה": true,
"קבינה": true,
"קבינו": true,
"קבינט": true,
"קביעה": true,
"קביעת": true,
"קבירה": true,
"קבלות": true,
"קבליה": true,
"קבליו": true,
"קבליי": true,
"קבליך": true,
"קבלים": true,
"קבלית": true,
"קבלכם": true,
"קבלכן": true,
"קבלנה": true,
"קבלנו": true,
"קבלני": true,
"קבלנך": true,
"קבלנם": true,
"קבלנן": true,
"קבלתה": true,
"קבלתו": true,
"קבלתי": true,
"קבלתך": true,
"קבלתם": true,
"קבלתן": true,
"קבנוס": true,
"קבעון": true,
"קבענה": true,
"קבענו": true,
"קבעתי": true,
"קבעתם": true,
"קבעתן": true,
"קבציה": true,
"קבציו": true,
"קבציי": true,
"קבציך": true,
"קבצים": true,
"קבצנה": true,
"קבצנו": true,
"קבצני": true,
"קבצנך": true,
"קבצנם": true,
"קבצנן": true,
"קבקבה": true,
"קבקבו": true,
"קבקבי": true,
"קבקבך": true,
"קבקבם": true,
"קבקבן": true,
"קברות": true,
"קברטי": true,
"קבריה": true,
"קבריו": true,
"קבריי": true,
"קבריך": true,
"קברים": true,
"קברכם": true,
"קברכן": true,
"קברנה": true,
"קברנו": true,
"קברני": true,
"קברנך": true,
"קברנם": true,
"קברנן": true,
"קברתי": true,
"קברתם": true,
"קברתן": true,
"קדאפי": true,
"קדוחה": true,
"קדומה": true,
"קדונו": true,
"קדוסי": true,
"קדושה": true,
"קדושו": true,
"קדושי": true,
"קדושך": true,
"קדושם": true,
"קדושן": true,
"קדושת": true,
"קדותי": true,
"קדותם": true,
"קדותן": true,
"קדחות": true,
"קדחיה": true,
"קדחיו": true,
"קדחיי": true,
"קדחיך": true,
"קדחים": true,
"קדחכם": true,
"קדחכן": true,
"קדחנה": true,
"קדחנו": true,
"קדחתה": true,
"קדחתו": true,
"קדחתי": true,
"קדחתך": true,
"קדחתם": true,
"קדחתן": true,
"קדיחה": true,
"קדימה": true,
"קדישא": true,
"קדיתא": true,
"קדלים": true,
"קדמון": true,
"קדמות": true,
"קדמיה": true,
"קדמים": true,
"קדמית": true,
"קדמנה": true,
"קדמנו": true,
"קדמתה": true,
"קדמתו": true,
"קדמתי": true,
"קדמתך": true,
"קדמתם": true,
"קדמתן": true,
"קדקוד": true,
"קדרון": true,
"קדרות": true,
"קדריה": true,
"קדריו": true,
"קדריי": true,
"קדריך": true,
"קדרים": true,
"קדרית": true,
"קדרכן": true,
"קדרנו": true,
"קדרתה": true,
"קדרתו": true,
"קדרתי": true,
"קדרתך": true,
"קדרתם": true,
"קדרתן": true,
"קדשות": true,
"קדשים": true,
"קדשנה": true,
"קדשתה": true,
"קדשתו": true,
"קדשתי": true,
"קדשתך": true,
"קדשתם": true,
"קדשתן": true,
"קהויה": true,
"קהותה": true,
"קהותו": true,
"קהותי": true,
"קהותך": true,
"קהותם": true,
"קהותן": true,
"קהיות": true,
"קהייה": true,
"קהילה": true,
"קהילת": true,
"קהינה": true,
"קהינו": true,
"קהיתי": true,
"קהיתם": true,
"קהיתן": true,
"קהליה": true,
"קהליו": true,
"קהליי": true,
"קהליך": true,
"קהלים": true,
"קהלכם": true,
"קהלכן": true,
"קהלנו": true,
"קהלני": true,
"קואלה": true,
"קואלי": true,
"קובוס": true,
"קוביה": true,
"קוביץ": true,
"קובלט": true,
"קובלת": true,
"קובנה": true,
"קובני": true,
"קובעה": true,
"קובעו": true,
"קובעת": true,
"קובצה": true,
"קובצו": true,
"קובצי": true,
"קובצך": true,
"קובצם": true,
"קובצן": true,
"קובצת": true,
"קוברה": true,
"קוברת": true,
"קוגלר": true,
"קודדה": true,
"קודדו": true,
"קודדי": true,
"קודדת": true,
"קודחת": true,
"קודיה": true,
"קודיו": true,
"קודיי": true,
"קודיך": true,
"קודים": true,
"קודכם": true,
"קודכן": true,
"קודמה": true,
"קודמו": true,
"קודמי": true,
"קודמך": true,
"קודמם": true,
"קודמן": true,
"קודמת": true,
"קודנה": true,
"קודנו": true,
"קודקס": true,
"קודרת": true,
"קודשה": true,
"קודשו": true,
"קודשי": true,
"קודשך": true,
"קודשם": true,
"קודשן": true,
"קודשת": true,
"קוהות": true,
"קוהים": true,
"קוהלר": true,
"קוהלת": true,
"קוואן": true,
"קווטה": true,
"קוויה": true,
"קוויו": true,
"קוויי": true,
"קוויך": true,
"קווים": true,
"קווית": true,
"קווכם": true,
"קווכן": true,
"קוונה": true,
"קוונו": true,
"קוונט": true,
"קווני": true,
"קוונך": true,
"קוונם": true,
"קוונן": true,
"קווצה": true,
"קווקז": true,
"קוורץ": true,
"קוורק": true,
"קוזאק": true,
"קוזזה": true,
"קוזזו": true,
"קוזזת": true,
"קוזין": true,
"קוטבה": true,
"קוטבו": true,
"קוטבי": true,
"קוטבך": true,
"קוטבם": true,
"קוטבן": true,
"קוטבת": true,
"קוטלג": true,
"קוטלר": true,
"קוטלת": true,
"קוטמת": true,
"קוטנה": true,
"קוטנו": true,
"קוטני": true,
"קוטנך": true,
"קוטנם": true,
"קוטנן": true,
"קוטעה": true,
"קוטעו": true,
"קוטעת": true,
"קוטפת": true,
"קוטרה": true,
"קוטרו": true,
"קוטרי": true,
"קוטרך": true,
"קוטרם": true,
"קוטרן": true,
"קויוט": true,
"קויים": true,
"קוימה": true,
"קוימו": true,
"קוימת": true,
"קוכמן": true,
"קולבה": true,
"קולבו": true,
"קולבי": true,
"קולבך": true,
"קולבם": true,
"קולבן": true,
"קולגה": true,
"קולגן": true,
"קולדת": true,
"קולות": true,
"קולחה": true,
"קולחו": true,
"קולחת": true,
"קולטה": true,
"קולטן": true,
"קולטת": true,
"קולים": true,
"קולית": true,
"קולכם": true,
"קולכן": true,
"קוללה": true,
"קוללו": true,
"קוללת": true,
"קולמן": true,
"קולנה": true,
"קולנו": true,
"קולני": true,
"קולנך": true,
"קולנם": true,
"קולנן": true,
"קולעת": true,
"קולפה": true,
"קולפו": true,
"קולפת": true,
"קולקל": true,
"קולרה": true,
"קולרו": true,
"קולרי": true,
"קולרך": true,
"קולרם": true,
"קולרן": true,
"קולתה": true,
"קולתו": true,
"קולתי": true,
"קולתך": true,
"קולתם": true,
"קולתן": true,
"קומות": true,
"קומחה": true,
"קומחו": true,
"קומחת": true,
"קומטה": true,
"קומטו": true,
"קומטת": true,
"קומית": true,
"קוממה": true,
"קוממו": true,
"קוממי": true,
"קוממת": true,
"קומנה": true,
"קומפק": true,
"קומצה": true,
"קומצו": true,
"קומצי": true,
"קומצך": true,
"קומצם": true,
"קומצן": true,
"קומצת": true,
"קומרה": true,
"קומרו": true,
"קומרן": true,
"קומרת": true,
"קומתה": true,
"קומתו": true,
"קומתי": true,
"קומתך": true,
"קומתם": true,
"קומתן": true,
"קונגו": true,
"קונדס": true,
"קונוס": true,
"קונות": true,
"קונחה": true,
"קונחו": true,
"קונחת": true,
"קונטר": true,
"קונים": true,
"קוננה": true,
"קוננו": true,
"קונני": true,
"קוננת": true,
"קונסט": true,
"קונסת": true,
"קונצה": true,
"קונצו": true,
"קונצי": true,
"קונצך": true,
"קונצם": true,
"קונצן": true,
"קוסטה": true,
"קוסמה": true,
"קוסמו": true,
"קוסמט": true,
"קוסמי": true,
"קוסמך": true,
"קוסמם": true,
"קוסמן": true,
"קוסמת": true,
"קועקע": true,
"קופאה": true,
"קופאו": true,
"קופאי": true,
"קופאך": true,
"קופאם": true,
"קופאן": true,
"קופאת": true,
"קופדה": true,
"קופדו": true,
"קופדת": true,
"קופון": true,
"קופות": true,
"קופחה": true,
"קופחו": true,
"קופחת": true,
"קופטי": true,
"קופיה": true,
"קופיו": true,
"קופיי": true,
"קופיך": true,
"קופים": true,
"קופיף": true,
"קופיץ": true,
"קופית": true,
"קופכם": true,
"קופכן": true,
"קופלה": true,
"קופלו": true,
"קופלת": true,
"קופנו": true,
"קופסא": true,
"קופסה": true,
"קופסת": true,
"קופצת": true,
"קופתה": true,
"קופתו": true,
"קופתי": true,
"קופתך": true,
"קופתם": true,
"קופתן": true,
"קוצבת": true,
"קוציה": true,
"קוציו": true,
"קוציי": true,
"קוציך": true,
"קוצים": true,
"קוצין": true,
"קוצכם": true,
"קוצכן": true,
"קוצמה": true,
"קוצנה": true,
"קוצנו": true,
"קוצני": true,
"קוצפת": true,
"קוצצה": true,
"קוצצו": true,
"קוצצת": true,
"קוצרה": true,
"קוצרו": true,
"קוצרת": true,
"קוקוס": true,
"קוקטי": true,
"קוקיה": true,
"קוראה": true,
"קוראו": true,
"קוראי": true,
"קוראך": true,
"קוראם": true,
"קוראן": true,
"קוראת": true,
"קורבה": true,
"קורבו": true,
"קורבן": true,
"קורבת": true,
"קורול": true,
"קורות": true,
"קורים": true,
"קורין": true,
"קוריס": true,
"קורכם": true,
"קורכן": true,
"קורמת": true,
"קורנו": true,
"קורנס": true,
"קורנת": true,
"קורסי": true,
"קורסת": true,
"קורעת": true,
"קורפו": true,
"קורצה": true,
"קורצו": true,
"קורצף": true,
"קורצק": true,
"קורצת": true,
"קורקע": true,
"קורקף": true,
"קוררה": true,
"קוררו": true,
"קוררת": true,
"קורתה": true,
"קורתו": true,
"קורתי": true,
"קורתך": true,
"קורתם": true,
"קורתן": true,
"קושטה": true,
"קושטו": true,
"קושטת": true,
"קושיה": true,
"קושיו": true,
"קושיי": true,
"קושנר": true,
"קושקש": true,
"קושרה": true,
"קושרו": true,
"קושרי": true,
"קושרך": true,
"קושרם": true,
"קושרן": true,
"קושרת": true,
"קוששה": true,
"קוששו": true,
"קוששי": true,
"קוששת": true,
"קותלה": true,
"קותלו": true,
"קותלי": true,
"קותלך": true,
"קותלם": true,
"קותלן": true,
"קזזנה": true,
"קזחים": true,
"קזחית": true,
"קזינו": true,
"קטביה": true,
"קטביו": true,
"קטביי": true,
"קטביך": true,
"קטבים": true,
"קטבנה": true,
"קטגור": true,
"קטולה": true,
"קטומה": true,
"קטונה": true,
"קטונו": true,
"קטונת": true,
"קטועה": true,
"קטופה": true,
"קטורה": true,
"קטורת": true,
"קטטות": true,
"קטטתה": true,
"קטטתו": true,
"קטטתי": true,
"קטטתך": true,
"קטטתם": true,
"קטטתן": true,
"קטיבי": true,
"קטיון": true,
"קטיות": true,
"קטילה": true,
"קטימה": true,
"קטינה": true,
"קטיעה": true,
"קטיפא": true,
"קטיפה": true,
"קטיפו": true,
"קטיפי": true,
"קטיפך": true,
"קטיפם": true,
"קטיפן": true,
"קטיקה": true,
"קטלבה": true,
"קטלבו": true,
"קטלבי": true,
"קטלבך": true,
"קטלבם": true,
"קטלבן": true,
"קטלגה": true,
"קטלגו": true,
"קטלגי": true,
"קטלגת": true,
"קטלוג": true,
"קטלנו": true,
"קטלני": true,
"קטלתי": true,
"קטלתם": true,
"קטלתן": true,
"קטמון": true,
"קטמנו": true,
"קטמתי": true,
"קטמתם": true,
"קטמתן": true,
"קטנוע": true,
"קטנות": true,
"קטנטן": true,
"קטנים": true,
"קטנית": true,
"קטנתי": true,
"קטנתם": true,
"קטנתן": true,
"קטעיה": true,
"קטעיו": true,
"קטעיי": true,
"קטעיך": true,
"קטעים": true,
"קטעכם": true,
"קטעכן": true,
"קטענה": true,
"קטענו": true,
"קטעתי": true,
"קטעתם": true,
"קטעתן": true,
"קטפנו": true,
"קטפתי": true,
"קטפתם": true,
"קטפתן": true,
"קטרגה": true,
"קטרגו": true,
"קטרגי": true,
"קטרגת": true,
"קטרוג": true,
"קטריה": true,
"קטריו": true,
"קטריי": true,
"קטריך": true,
"קטרים": true,
"קטרית": true,
"קטרכם": true,
"קטרכן": true,
"קטרנה": true,
"קטרנו": true,
"קטרקט": true,
"קטשופ": true,
"קיאכם": true,
"קיאכן": true,
"קיאנו": true,
"קיאקה": true,
"קיאקו": true,
"קיאקי": true,
"קיאקך": true,
"קיאקם": true,
"קיאקן": true,
"קיבול": true,
"קיבוע": true,
"קיבוץ": true,
"קיבות": true,
"קיבלה": true,
"קיבלו": true,
"קיבלת": true,
"קיבעה": true,
"קיבעו": true,
"קיבעת": true,
"קיבצה": true,
"קיבצו": true,
"קיבצת": true,
"קיבתה": true,
"קיבתו": true,
"קיבתי": true,
"קיבתך": true,
"קיבתם": true,
"קיבתן": true,
"קידוד": true,
"קידוח": true,
"קידום": true,
"קידוש": true,
"קידות": true,
"קידמה": true,
"קידמו": true,
"קידמת": true,
"קידשה": true,
"קידשו": true,
"קידשת": true,
"קידתה": true,
"קידתו": true,
"קידתי": true,
"קידתך": true,
"קידתם": true,
"קידתן": true,
"קיווה": true,
"קיווי": true,
"קיוטו": true,
"קיומה": true,
"קיומו": true,
"קיומי": true,
"קיומך": true,
"קיומם": true,
"קיומן": true,
"קיוסק": true,
"קיוצה": true,
"קיוצו": true,
"קיוצי": true,
"קיוצך": true,
"קיוצם": true,
"קיוצן": true,
"קיזוז": true,
"קיזזה": true,
"קיזזו": true,
"קיזזת": true,
"קיטבג": true,
"קיטבה": true,
"קיטבו": true,
"קיטבת": true,
"קיטוב": true,
"קיטום": true,
"קיטון": true,
"קיטוע": true,
"קיטור": true,
"קיטים": true,
"קיטעה": true,
"קיטעו": true,
"קיטעת": true,
"קיטרה": true,
"קיטרו": true,
"קיטרת": true,
"קיטשי": true,
"קייגן": true,
"קייטן": true,
"קיימא": true,
"קיימה": true,
"קיימו": true,
"קיימי": true,
"קיימת": true,
"קייסי": true,
"קייצה": true,
"קייצו": true,
"קייצי": true,
"קייצת": true,
"קיכלי": true,
"קילוח": true,
"קילוס": true,
"קילוף": true,
"קילחה": true,
"קילחו": true,
"קילחת": true,
"קיללה": true,
"קיללו": true,
"קיללת": true,
"קילסה": true,
"קילסו": true,
"קילסת": true,
"קילפה": true,
"קילפו": true,
"קילפת": true,
"קימוח": true,
"קימוט": true,
"קימום": true,
"קימוץ": true,
"קימור": true,
"קימות": true,
"קימחה": true,
"קימחו": true,
"קימחת": true,
"קימטה": true,
"קימטו": true,
"קימטת": true,
"קימצה": true,
"קימצו": true,
"קימצת": true,
"קימרה": true,
"קימרו": true,
"קימרת": true,
"קימתה": true,
"קימתו": true,
"קימתי": true,
"קימתך": true,
"קימתם": true,
"קימתן": true,
"קינאה": true,
"קינאו": true,
"קינאת": true,
"קינוח": true,
"קינון": true,
"קינות": true,
"קינחה": true,
"קינחו": true,
"קינחת": true,
"קינטי": true,
"קיננה": true,
"קיננו": true,
"קיננת": true,
"קינקי": true,
"קינתה": true,
"קינתו": true,
"קינתי": true,
"קינתך": true,
"קינתם": true,
"קינתן": true,
"קיסוס": true,
"קיסמה": true,
"קיסמו": true,
"קיסמי": true,
"קיסמך": true,
"קיסמם": true,
"קיסמן": true,
"קיסרה": true,
"קיסרו": true,
"קיסרי": true,
"קיסרך": true,
"קיסרם": true,
"קיסרן": true,
"קיעים": true,
"קיעקע": true,
"קיפדה": true,
"קיפדו": true,
"קיפדת": true,
"קיפוד": true,
"קיפוח": true,
"קיפול": true,
"קיפוף": true,
"קיפוץ": true,
"קיפחה": true,
"קיפחו": true,
"קיפחת": true,
"קיפלה": true,
"קיפלו": true,
"קיפלת": true,
"קיפצה": true,
"קיפצו": true,
"קיפצת": true,
"קיצבה": true,
"קיצבו": true,
"קיצבת": true,
"קיצוב": true,
"קיצון": true,
"קיצוץ": true,
"קיצור": true,
"קיצות": true,
"קיציה": true,
"קיציו": true,
"קיציי": true,
"קיציך": true,
"קיצים": true,
"קיצית": true,
"קיצכם": true,
"קיצכן": true,
"קיצנו": true,
"קיצצה": true,
"קיצצו": true,
"קיצצת": true,
"קיצרה": true,
"קיצרו": true,
"קיצרת": true,
"קיצתה": true,
"קיצתו": true,
"קיצתי": true,
"קיצתך": true,
"קיצתם": true,
"קיצתן": true,
"קירבה": true,
"קירבו": true,
"קירבת": true,
"קירוב": true,
"קירוי": true,
"קירור": true,
"קירות": true,
"קירחת": true,
"קירטע": true,
"קירכם": true,
"קירכן": true,
"קירנו": true,
"קיררה": true,
"קיררו": true,
"קיררת": true,
"קישוא": true,
"קישוט": true,
"קישון": true,
"קישור": true,
"קישוש": true,
"קישחת": true,
"קישטה": true,
"קישטו": true,
"קישטת": true,
"קישים": true,
"קישלה": true,
"קישרה": true,
"קישרו": true,
"קישרת": true,
"קיתון": true,
"קלאוס": true,
"קלאסי": true,
"קלארק": true,
"קלגסה": true,
"קלגסו": true,
"קלגסי": true,
"קלגסך": true,
"קלגסם": true,
"קלגסן": true,
"קלגרי": true,
"קלדות": true,
"קלדנה": true,
"קלדנו": true,
"קלדני": true,
"קלדנך": true,
"קלדנם": true,
"קלדנן": true,
"קלדתי": true,
"קלדתם": true,
"קלדתן": true,
"קלוגר": true,
"קלוטה": true,
"קלויה": true,
"קלונה": true,
"קלונו": true,
"קלוני": true,
"קלונך": true,
"קלונם": true,
"קלונן": true,
"קלועה": true,
"קלופה": true,
"קלוקל": true,
"קלורי": true,
"קלושה": true,
"קלותה": true,
"קלותו": true,
"קלותי": true,
"קלותך": true,
"קלותם": true,
"קלותן": true,
"קלחות": true,
"קלחיה": true,
"קלחיו": true,
"קלחיי": true,
"קלחיך": true,
"קלחים": true,
"קלחכם": true,
"קלחכן": true,
"קלחנה": true,
"קלחנו": true,
"קלחתה": true,
"קלחתו": true,
"קלחתי": true,
"קלחתך": true,
"קלחתם": true,
"קלחתן": true,
"קלטור": true,
"קלטות": true,
"קלטיה": true,
"קלטיו": true,
"קלטיי": true,
"קלטיך": true,
"קלטים": true,
"קלטית": true,
"קלטכם": true,
"קלטכן": true,
"קלטנו": true,
"קלטרה": true,
"קלטרו": true,
"קלטרי": true,
"קלטרת": true,
"קלטתה": true,
"קלטתו": true,
"קלטתי": true,
"קלטתך": true,
"קלטתם": true,
"קלטתן": true,
"קליבר": true,
"קלידה": true,
"קלידו": true,
"קלידי": true,
"קלידך": true,
"קלידם": true,
"קלידן": true,
"קליות": true,
"קליטה": true,
"קליטת": true,
"קלייה": true,
"קליין": true,
"קלייר": true,
"קלילה": true,
"קלימר": true,
"קלינה": true,
"קלינו": true,
"קליני": true,
"קליעה": true,
"קליעו": true,
"קליעי": true,
"קליעך": true,
"קליעם": true,
"קליען": true,
"קליפה": true,
"קליפת": true,
"קליקה": true,
"קליקת": true,
"קלישה": true,
"קלישו": true,
"קלישי": true,
"קלישך": true,
"קלישם": true,
"קלישן": true,
"קליתי": true,
"קליתם": true,
"קליתן": true,
"קללות": true,
"קללנה": true,
"קללתה": true,
"קללתו": true,
"קללתי": true,
"קללתך": true,
"קללתם": true,
"קללתן": true,
"קלמרה": true,
"קלמרו": true,
"קלמרי": true,
"קלמרך": true,
"קלמרם": true,
"קלמרן": true,
"קלנוע": true,
"קלסית": true,
"קלסנה": true,
"קלסרה": true,
"קלסרו": true,
"קלסרי": true,
"קלסרך": true,
"קלסרם": true,
"קלסרן": true,
"קלסתר": true,
"קלעות": true,
"קלעיה": true,
"קלעיו": true,
"קלעיי": true,
"קלעיך": true,
"קלעים": true,
"קלעית": true,
"קלעכם": true,
"קלעכן": true,
"קלענה": true,
"קלענו": true,
"קלעתי": true,
"קלעתם": true,
"קלעתן": true,
"קלפיה": true,
"קלפיו": true,
"קלפיי": true,
"קלפיך": true,
"קלפים": true,
"קלפכם": true,
"קלפכן": true,
"קלפנה": true,
"קלפנו": true,
"קלפני": true,
"קלפנך": true,
"קלפנם": true,
"קלפנן": true,
"קלפתי": true,
"קלפתם": true,
"קלפתן": true,
"קלקול": true,
"קלקלה": true,
"קלקלו": true,
"קלקלי": true,
"קלקלת": true,
"קלשון": true,
"קמאית": true,
"קמוצה": true,
"קמורה": true,
"קמחון": true,
"קמחיה": true,
"קמחיו": true,
"קמחיי": true,
"קמחיך": true,
"קמחים": true,
"קמחית": true,
"קמחכם": true,
"קמחכן": true,
"קמחנה": true,
"קמחנו": true,
"קמטוט": true,
"קמטיה": true,
"קמטיו": true,
"קמטיי": true,
"קמטיך": true,
"קמטים": true,
"קמטכם": true,
"קמטכן": true,
"קמטנה": true,
"קמטנו": true,
"קמילה": true,
"קמינה": true,
"קמינו": true,
"קמיני": true,
"קמינך": true,
"קמינם": true,
"קמינן": true,
"קמיצה": true,
"קמלות": true,
"קמליה": true,
"קמלים": true,
"קמלנו": true,
"קמלתי": true,
"קמלתם": true,
"קמלתן": true,
"קממבר": true,
"קמעות": true,
"קמעיה": true,
"קמעיו": true,
"קמעיי": true,
"קמעיך": true,
"קמעים": true,
"קמעכם": true,
"קמעכן": true,
"קמענו": true,
"קמפוס": true,
"קמפור": true,
"קמפפר": true,
"קמצוץ": true,
"קמציה": true,
"קמציו": true,
"קמציי": true,
"קמציך": true,
"קמצים": true,
"קמצנה": true,
"קמצנו": true,
"קמצני": true,
"קמצתי": true,
"קמצתם": true,
"קמצתן": true,
"קמרון": true,
"קמרית": true,
"קמרנה": true,
"קנאות": true,
"קנאים": true,
"קנאית": true,
"קנאנה": true,
"קנאתה": true,
"קנאתו": true,
"קנאתי": true,
"קנאתך": true,
"קנאתם": true,
"קנאתן": true,
"קנבוס": true,
"קנביס": true,
"קנברה": true,
"קנדיל": true,
"קנדים": true,
"קנדית": true,
"קנויה": true,
"קנולה": true,
"קנולר": true,
"קנוני": true,
"קנחנה": true,
"קנטון": true,
"קנטור": true,
"קנטטה": true,
"קנטקי": true,
"קנטרה": true,
"קנטרו": true,
"קנטרי": true,
"קנטרן": true,
"קנטרת": true,
"קניבל": true,
"קניהם": true,
"קניהן": true,
"קניון": true,
"קניות": true,
"קנייא": true,
"קנייה": true,
"קנייך": true,
"קניין": true,
"קניית": true,
"קניכם": true,
"קניכן": true,
"קנינה": true,
"קנינו": true,
"קניסה": true,
"קניתי": true,
"קניתם": true,
"קניתן": true,
"קנמון": true,
"קנסות": true,
"קנסכם": true,
"קנסכן": true,
"קנסנו": true,
"קנסתי": true,
"קנסתם": true,
"קנסתן": true,
"קנצלר": true,
"קנקנה": true,
"קנקנו": true,
"קנקני": true,
"קנקנך": true,
"קנקנם": true,
"קנקנן": true,
"קנרים": true,
"קנרית": true,
"קנרסה": true,
"קנרסו": true,
"קנרסי": true,
"קנרסך": true,
"קנרסם": true,
"קנרסן": true,
"קסבות": true,
"קסדות": true,
"קסדתה": true,
"קסדתו": true,
"קסדתי": true,
"קסדתך": true,
"קסדתם": true,
"קסדתן": true,
"קסוטו": true,
"קסומה": true,
"קסטות": true,
"קסטים": true,
"קסטנר": true,
"קסטרה": true,
"קסטרו": true,
"קסלמן": true,
"קסמיה": true,
"קסמיו": true,
"קסמיי": true,
"קסמיך": true,
"קסמים": true,
"קסמכם": true,
"קסמכן": true,
"קסמנו": true,
"קסמתי": true,
"קסמתם": true,
"קסמתן": true,
"קסנון": true,
"קסריה": true,
"קסתות": true,
"קסתכם": true,
"קסתכן": true,
"קסתנו": true,
"קעאדה": true,
"קעורה": true,
"קעידה": true,
"קעקוע": true,
"קעקור": true,
"קעקעה": true,
"קעקעו": true,
"קעקעי": true,
"קעקעך": true,
"קעקעם": true,
"קעקען": true,
"קעקעת": true,
"קערות": true,
"קערית": true,
"קערתה": true,
"קערתו": true,
"קערתי": true,
"קערתך": true,
"קערתם": true,
"קערתן": true,
"קפאון": true,
"קפאין": true,
"קפאנה": true,
"קפאנו": true,
"קפאתי": true,
"קפאתם": true,
"קפאתן": true,
"קפדנה": true,
"קפדני": true,
"קפואה": true,
"קפוטה": true,
"קפוצה": true,
"קפחנה": true,
"קפחנו": true,
"קפחתי": true,
"קפחתם": true,
"קפחתן": true,
"קפטנה": true,
"קפטנו": true,
"קפטני": true,
"קפטנך": true,
"קפטנם": true,
"קפטנן": true,
"קפיאה": true,
"קפידה": true,
"קפיטל": true,
"קפיטן": true,
"קפיצה": true,
"קפיצו": true,
"קפיצי": true,
"קפיצך": true,
"קפיצם": true,
"קפיצן": true,
"קפיצת": true,
"קפלות": true,
"קפליה": true,
"קפליו": true,
"קפליי": true,
"קפליך": true,
"קפלים": true,
"קפלכם": true,
"קפלכן": true,
"קפלנה": true,
"קפלנו": true,
"קפצון": true,
"קפצנה": true,
"קפצנו": true,
"קפצתי": true,
"קפצתם": true,
"קפצתן": true,
"קפקאי": true,
"קצבות": true,
"קצביה": true,
"קצביו": true,
"קצביי": true,
"קצביך": true,
"קצבים": true,
"קצבית": true,
"קצבכם": true,
"קצבכן": true,
"קצבנה": true,
"קצבנו": true,
"קצבתה": true,
"קצבתו": true,
"קצבתי": true,
"קצבתך": true,
"קצבתם": true,
"קצבתן": true,
"קצואה": true,
"קצובה": true,
"קצובר": true,
"קצווי": true,
"קצוות": true,
"קצונה": true,
"קצוצה": true,
"קצורה": true,
"קציבה": true,
"קציהם": true,
"קציהן": true,
"קציות": true,
"קצייך": true,
"קציכם": true,
"קציכן": true,
"קצינה": true,
"קצינו": true,
"קציני": true,
"קצינך": true,
"קצינם": true,
"קצינן": true,
"קציפה": true,
"קציצה": true,
"קציצו": true,
"קציצי": true,
"קציצך": true,
"קציצם": true,
"קציצן": true,
"קצירה": true,
"קצירו": true,
"קצירי": true,
"קצירך": true,
"קצירם": true,
"קצירן": true,
"קצפות": true,
"קצפיה": true,
"קצפיו": true,
"קצפיי": true,
"קצפיך": true,
"קצפים": true,
"קצפכם": true,
"קצפכן": true,
"קצפנו": true,
"קצפתה": true,
"קצפתו": true,
"קצפתי": true,
"קצפתך": true,
"קצפתם": true,
"קצפתן": true,
"קצצנה": true,
"קצצנו": true,
"קצצתי": true,
"קצצתם": true,
"קצצתן": true,
"קצרות": true,
"קצריה": true,
"קצריו": true,
"קצריי": true,
"קצריך": true,
"קצרים": true,
"קצרין": true,
"קצרכם": true,
"קצרכן": true,
"קצרנה": true,
"קצרנו": true,
"קצרני": true,
"קצרנך": true,
"קצרנם": true,
"קצרנן": true,
"קצרצר": true,
"קצרתה": true,
"קצרתו": true,
"קצרתי": true,
"קצרתך": true,
"קצרתם": true,
"קצרתן": true,
"קצתכם": true,
"קצתכן": true,
"קצתנו": true,
"קקטוס": true,
"קקיון": true,
"קראדי": true,
"קראון": true,
"קראוס": true,
"קראות": true,
"קראטה": true,
"קראים": true,
"קראית": true,
"קראנה": true,
"קראנו": true,
"קראתי": true,
"קראתם": true,
"קראתן": true,
"קרבות": true,
"קרביה": true,
"קרביו": true,
"קרביי": true,
"קרביך": true,
"קרבים": true,
"קרבין": true,
"קרבית": true,
"קרבכם": true,
"קרבכן": true,
"קרבנה": true,
"קרבנו": true,
"קרבני": true,
"קרבנך": true,
"קרבנם": true,
"קרבנן": true,
"קרבתה": true,
"קרבתו": true,
"קרבתי": true,
"קרבתך": true,
"קרבתם": true,
"קרבתן": true,
"קרדום": true,
"קרדיט": true,
"קרדית": true,
"קרואה": true,
"קרובה": true,
"קרובו": true,
"קרובי": true,
"קרובך": true,
"קרובם": true,
"קרובן": true,
"קרוון": true,
"קרויה": true,
"קרולי": true,
"קרומה": true,
"קרומו": true,
"קרומי": true,
"קרומך": true,
"קרומם": true,
"קרומן": true,
"קרונה": true,
"קרונו": true,
"קרוני": true,
"קרונך": true,
"קרונם": true,
"קרונן": true,
"קרוסה": true,
"קרועה": true,
"קרוצה": true,
"קרושה": true,
"קרחון": true,
"קרחות": true,
"קרחיה": true,
"קרחיו": true,
"קרחיי": true,
"קרחיך": true,
"קרחים": true,
"קרחכם": true,
"קרחכן": true,
"קרחנו": true,
"קרחתה": true,
"קרחתו": true,
"קרחתי": true,
"קרחתך": true,
"קרחתם": true,
"קרחתן": true,
"קרטוב": true,
"קרטון": true,
"קרטוע": true,
"קרטזי": true,
"קרטיב": true,
"קרטים": true,
"קרטלי": true,
"קרטעה": true,
"קרטעו": true,
"קרטעי": true,
"קרטעת": true,
"קריאה": true,
"קריאת": true,
"קריבי": true,
"קריות": true,
"קריזה": true,
"קריטי": true,
"קרייה": true,
"קרייך": true,
"קריים": true,
"קריין": true,
"קריית": true,
"קרינה": true,
"קרינו": true,
"קרינת": true,
"קריסה": true,
"קריסט": true,
"קריסת": true,
"קריעה": true,
"קריצה": true,
"קריקט": true,
"קרירה": true,
"קרישה": true,
"קרישו": true,
"קרישי": true,
"קרישך": true,
"קרישם": true,
"קרישן": true,
"קריתי": true,
"קריתם": true,
"קריתן": true,
"קרלוס": true,
"קרמבו": true,
"קרמים": true,
"קרמית": true,
"קרמנו": true,
"קרמתה": true,
"קרמתו": true,
"קרמתי": true,
"קרמתך": true,
"קרמתם": true,
"קרמתן": true,
"קרנבל": true,
"קרנות": true,
"קרניה": true,
"קרניו": true,
"קרניז": true,
"קרניי": true,
"קרניך": true,
"קרנים": true,
"קרנית": true,
"קרנכם": true,
"קרנכן": true,
"קרננו": true,
"קרנפה": true,
"קרנפו": true,
"קרנפי": true,
"קרנפך": true,
"קרנפם": true,
"קרנפן": true,
"קרנתי": true,
"קרנתם": true,
"קרנתן": true,
"קרסול": true,
"קרסיה": true,
"קרסיו": true,
"קרסיי": true,
"קרסיך": true,
"קרסים": true,
"קרסיק": true,
"קרסכם": true,
"קרסכן": true,
"קרסנו": true,
"קרסתי": true,
"קרסתם": true,
"קרסתן": true,
"קרעיה": true,
"קרעיו": true,
"קרעיי": true,
"קרעיך": true,
"קרעים": true,
"קרעכם": true,
"קרעכן": true,
"קרענה": true,
"קרענו": true,
"קרעתי": true,
"קרעתם": true,
"קרעתן": true,
"קרפדה": true,
"קרפדו": true,
"קרפדי": true,
"קרפדך": true,
"קרפדם": true,
"קרפדן": true,
"קרפטי": true,
"קרפים": true,
"קרפלה": true,
"קרפלך": true,
"קרצוף": true,
"קרציה": true,
"קרצית": true,
"קרצנו": true,
"קרצפה": true,
"קרצפו": true,
"קרצפי": true,
"קרצפת": true,
"קרצתי": true,
"קרצתם": true,
"קרצתן": true,
"קרקוע": true,
"קרקוף": true,
"קרקור": true,
"קרקוש": true,
"קרקסה": true,
"קרקסו": true,
"קרקסי": true,
"קרקסך": true,
"קרקסם": true,
"קרקסן": true,
"קרקעה": true,
"קרקעו": true,
"קרקעי": true,
"קרקעך": true,
"קרקעם": true,
"קרקען": true,
"קרקעת": true,
"קרקפה": true,
"קרקפו": true,
"קרקפי": true,
"קרקפת": true,
"קרקרה": true,
"קרקרו": true,
"קרקרי": true,
"קרקרת": true,
"קרקשה": true,
"קרקשו": true,
"קרקשי": true,
"קרקשת": true,
"קררנה": true,
"קרשיה": true,
"קרשיו": true,
"קרשיי": true,
"קרשיך": true,
"קרשים": true,
"קרשכם": true,
"קרשכן": true,
"קרשנו": true,
"קרתגו": true,
"קרתגי": true,
"קרתות": true,
"קרתכם": true,
"קרתכן": true,
"קרתנו": true,
"קרתני": true,
"קשבות": true,
"קשביה": true,
"קשביו": true,
"קשביי": true,
"קשביך": true,
"קשבים": true,
"קשבכם": true,
"קשבכן": true,
"קשבנו": true,
"קשבתה": true,
"קשבתו": true,
"קשבתי": true,
"קשבתך": true,
"קשבתם": true,
"קשבתן": true,
"קשובה": true,
"קשווה": true,
"קשוות": true,
"קשוחה": true,
"קשורה": true,
"קשורת": true,
"קשטנה": true,
"קשיון": true,
"קשיות": true,
"קשיחה": true,
"קשייה": true,
"קשייו": true,
"קשייך": true,
"קשיים": true,
"קשינה": true,
"קשינו": true,
"קשירה": true,
"קשירת": true,
"קשישה": true,
"קשישו": true,
"קשישי": true,
"קשישך": true,
"קשישם": true,
"קשישן": true,
"קשיתה": true,
"קשיתו": true,
"קשיתי": true,
"קשיתך": true,
"קשיתם": true,
"קשיתן": true,
"קשמיר": true,
"קשנות": true,
"קשקבל": true,
"קשקוש": true,
"קשקשה": true,
"קשקשו": true,
"קשקשי": true,
"קשקשך": true,
"קשקשם": true,
"קשקשן": true,
"קשקשת": true,
"קשריה": true,
"קשריו": true,
"קשריי": true,
"קשריך": true,
"קשרים": true,
"קשרית": true,
"קשרכם": true,
"קשרכן": true,
"קשרנה": true,
"קשרנו": true,
"קשרתי": true,
"קשרתם": true,
"קשרתן": true,
"קשתות": true,
"קשתיה": true,
"קשתיו": true,
"קשתיי": true,
"קשתיך": true,
"קשתים": true,
"קשתית": true,
"קשתכם": true,
"קשתכן": true,
"קשתנו": true,
"קתדרה": true,
"קתודה": true,
"קתודי": true,
"קתולי": true,
"קתליה": true,
"קתליו": true,
"קתליי": true,
"קתליך": true,
"קתלים": true,
"קתרין": true,
"ראגוב": true,
"ראגיב": true,
"ראדאר": true,
"ראדון": true,
"ראובן": true,
"ראווה": true,
"ראוות": true,
"ראויה": true,
"ראוים": true,
"ראומה": true,
"ראותה": true,
"ראותו": true,
"ראותי": true,
"ראותך": true,
"ראותם": true,
"ראותן": true,
"ראטון": true,
"ראיון": true,
"ראיות": true,
"ראייה": true,
"ראייו": true,
"ראייך": true,
"ראיים": true,
"ראיין": true,
"ראיית": true,
"ראינה": true,
"ראינו": true,
"ראיסי": true,
"ראיתי": true,
"ראיתם": true,
"ראיתן": true,
"ראלית": true,
"ראמיה": true,
"ראמיו": true,
"ראמיי": true,
"ראמיך": true,
"ראמים": true,
"ראמין": true,
"ראמכם": true,
"ראמכן": true,
"ראמנו": true,
"ראשון": true,
"ראשות": true,
"ראשיד": true,
"ראשיה": true,
"ראשיו": true,
"ראשיי": true,
"ראשיך": true,
"ראשים": true,
"ראשית": true,
"ראשכם": true,
"ראשכן": true,
"ראשלצ": true,
"ראשנה": true,
"ראשנו": true,
"ראשני": true,
"ראשנך": true,
"ראשנם": true,
"ראשנן": true,
"ראשתן": true,
"רבבות": true,
"רבביה": true,
"רבביו": true,
"רבביי": true,
"רבביך": true,
"רבבים": true,
"רבבכם": true,
"רבבכן": true,
"רבבנה": true,
"רבבנו": true,
"רבבתה": true,
"רבבתו": true,
"רבבתי": true,
"רבבתך": true,
"רבבתם": true,
"רבבתן": true,
"רבדיה": true,
"רבדיו": true,
"רבדיי": true,
"רבדיך": true,
"רבדים": true,
"רבדנה": true,
"רבהון": true,
"רבונו": true,
"רבונט": true,
"רבועה": true,
"רבותי": true,
"רביבו": true,
"רביבי": true,
"רבידה": true,
"רבידו": true,
"רבידי": true,
"רבידך": true,
"רבידם": true,
"רבידן": true,
"רביות": true,
"רבייג": true,
"רבייה": true,
"רביכה": true,
"רבינה": true,
"רבינו": true,
"רביעה": true,
"רביעו": true,
"רביעי": true,
"רביעך": true,
"רביעם": true,
"רביען": true,
"רביצה": true,
"רביתי": true,
"רביתם": true,
"רביתן": true,
"רבלין": true,
"רבנות": true,
"רבניה": true,
"רבניו": true,
"רבניי": true,
"רבניך": true,
"רבנים": true,
"רבנית": true,
"רבעון": true,
"רבעיה": true,
"רבעיו": true,
"רבעיי": true,
"רבעיך": true,
"רבעים": true,
"רבעכם": true,
"רבעכן": true,
"רבענה": true,
"רבענו": true,
"רבצנו": true,
"רבצתי": true,
"רבצתם": true,
"רבצתן": true,
"רברבן": true,
"רבשקה": true,
"רגביה": true,
"רגביו": true,
"רגביי": true,
"רגביך": true,
"רגבים": true,
"רגבכם": true,
"רגבכן": true,
"רגבנו": true,
"רגוזה": true,
"רגומה": true,
"רגונה": true,
"רגועה": true,
"רגזנו": true,
"רגזני": true,
"רגזתה": true,
"רגזתו": true,
"רגזתי": true,
"רגזתך": true,
"רגזתם": true,
"רגזתן": true,
"רגיות": true,
"רגילה": true,
"רגימה": true,
"רגיעה": true,
"רגישה": true,
"רגישו": true,
"רגליה": true,
"רגליו": true,
"רגליי": true,
"רגליך": true,
"רגלים": true,
"רגלית": true,
"רגלכם": true,
"רגלכן": true,
"רגלנה": true,
"רגלנו": true,
"רגמים": true,
"רגמנו": true,
"רגמתי": true,
"רגמתם": true,
"רגמתן": true,
"רגנות": true,
"רגנים": true,
"רגנסי": true,
"רגנתי": true,
"רגנתם": true,
"רגנתן": true,
"רגעיה": true,
"רגעיו": true,
"רגעיי": true,
"רגעיך": true,
"רגעים": true,
"רגעית": true,
"רגעכם": true,
"רגעכן": true,
"רגענה": true,
"רגענו": true,
"רגעתי": true,
"רגעתם": true,
"רגעתן": true,
"רגשות": true,
"רגשים": true,
"רגשית": true,
"רגשכם": true,
"רגשכן": true,
"רגשנה": true,
"רגשנו": true,
"רגשני": true,
"רגשתי": true,
"רגשתם": true,
"רגשתן": true,
"רדדנה": true,
"רדואן": true,
"רדודה": true,
"רדויה": true,
"רדומה": true,
"רדופה": true,
"רדיאן": true,
"רדידה": true,
"רדידו": true,
"רדידי": true,
"רדידך": true,
"רדידם": true,
"רדידן": true,
"רדיוא": true,
"רדיום": true,
"רדיוס": true,
"רדיות": true,
"רדייה": true,
"רדינג": true,
"רדינה": true,
"רדינו": true,
"רדיפה": true,
"רדיקל": true,
"רדיתי": true,
"רדיתם": true,
"רדיתן": true,
"רדפנו": true,
"רדפתי": true,
"רדפתם": true,
"רדפתן": true,
"רדתכם": true,
"רדתכן": true,
"רדתנו": true,
"רהבכם": true,
"רהבכן": true,
"רהבנו": true,
"רהבתן": true,
"רהוטה": true,
"רהטנה": true,
"רהיטה": true,
"רהיטו": true,
"רהיטי": true,
"רהיטך": true,
"רהיטם": true,
"רהיטן": true,
"רואות": true,
"רואיל": true,
"רואים": true,
"רובאה": true,
"רובאו": true,
"רובאי": true,
"רובאך": true,
"רובאם": true,
"רובאן": true,
"רובבה": true,
"רובבו": true,
"רובבת": true,
"רובדה": true,
"רובדו": true,
"רובדי": true,
"רובדך": true,
"רובדם": true,
"רובדן": true,
"רובדת": true,
"רובהו": true,
"רובוט": true,
"רובות": true,
"רוביה": true,
"רוביו": true,
"רוביי": true,
"רוביך": true,
"רובים": true,
"רובין": true,
"רובית": true,
"רובכם": true,
"רובכן": true,
"רובנו": true,
"רובנר": true,
"רובעה": true,
"רובעו": true,
"רובעי": true,
"רובעך": true,
"רובעם": true,
"רובען": true,
"רובעת": true,
"רובצת": true,
"רוברט": true,
"רוגבי": true,
"רוגות": true,
"רוגזה": true,
"רוגזו": true,
"רוגזי": true,
"רוגזך": true,
"רוגזם": true,
"רוגזן": true,
"רוגזת": true,
"רוגים": true,
"רוגלה": true,
"רוגלך": true,
"רוגמת": true,
"רוגנת": true,
"רוגעה": true,
"רוגעו": true,
"רוגעי": true,
"רוגעך": true,
"רוגעם": true,
"רוגען": true,
"רוגעת": true,
"רוגשה": true,
"רוגשו": true,
"רוגשת": true,
"רודדה": true,
"רודדו": true,
"רודדת": true,
"רודוס": true,
"רודות": true,
"רודים": true,
"רודנה": true,
"רודנו": true,
"רודני": true,
"רודנך": true,
"רודנם": true,
"רודנן": true,
"רודפת": true,
"רוהטה": true,
"רוהטו": true,
"רוהטת": true,
"רווחה": true,
"רווחו": true,
"רווחי": true,
"רווחך": true,
"רווחם": true,
"רווחן": true,
"רווחת": true,
"רוויה": true,
"רוויח": true,
"רווים": true,
"רווית": true,
"רווקה": true,
"רווקו": true,
"רווקי": true,
"רווקך": true,
"רווקם": true,
"רווקן": true,
"רוורס": true,
"רוותה": true,
"רוזים": true,
"רוזין": true,
"רוזמן": true,
"רוזנה": true,
"רוזנו": true,
"רוזני": true,
"רוזנך": true,
"רוזנם": true,
"רוזנן": true,
"רוזנק": true,
"רוזנר": true,
"רוזנת": true,
"רוחבה": true,
"רוחבו": true,
"רוחבי": true,
"רוחבך": true,
"רוחבם": true,
"רוחבן": true,
"רוחות": true,
"רוחכם": true,
"רוחכן": true,
"רוחלה": true,
"רוחמה": true,
"רוחנו": true,
"רוחני": true,
"רוחצת": true,
"רוחקת": true,
"רוחשת": true,
"רוטבה": true,
"רוטבו": true,
"רוטבי": true,
"רוטבך": true,
"רוטבם": true,
"רוטבן": true,
"רוטור": true,
"רוטטת": true,
"רוטמן": true,
"רוטנת": true,
"רוטשה": true,
"רוטשו": true,
"רוטשת": true,
"רויאל": true,
"רויות": true,
"רויטל": true,
"רוכבה": true,
"רוכבו": true,
"רוכבי": true,
"רוכבך": true,
"רוכבם": true,
"רוכבן": true,
"רוכבת": true,
"רוכות": true,
"רוכזה": true,
"רוכזו": true,
"רוכזת": true,
"רוככה": true,
"רוככו": true,
"רוככם": true,
"רוככן": true,
"רוככת": true,
"רוכלה": true,
"רוכלו": true,
"רוכלי": true,
"רוכלך": true,
"רוכלם": true,
"רוכלן": true,
"רוכלת": true,
"רוכנו": true,
"רוכנת": true,
"רוכסן": true,
"רוכסת": true,
"רוכשה": true,
"רוכשו": true,
"רוכשי": true,
"רוכשך": true,
"רוכשם": true,
"רוכשן": true,
"רוכשת": true,
"רולדה": true,
"רולטה": true,
"רולמה": true,
"רומאו": true,
"רומאי": true,
"רומבה": true,
"רומזר": true,
"רומזת": true,
"רומחה": true,
"רומחו": true,
"רומחי": true,
"רומחך": true,
"רומחם": true,
"רומחן": true,
"רומים": true,
"רומית": true,
"רומכם": true,
"רומכן": true,
"רוממה": true,
"רוממו": true,
"רוממי": true,
"רוממת": true,
"רומנה": true,
"רומנו": true,
"רומני": true,
"רומנך": true,
"רומנם": true,
"רומנן": true,
"רומסת": true,
"רומתה": true,
"רונאל": true,
"רונדל": true,
"רונות": true,
"רוניה": true,
"רוניו": true,
"רוניי": true,
"רוניך": true,
"רונים": true,
"רונית": true,
"רונכם": true,
"רונכן": true,
"רונלד": true,
"רוננו": true,
"רוננת": true,
"רוסטה": true,
"רוסיה": true,
"רוסים": true,
"רוסית": true,
"רוסנה": true,
"רוסנו": true,
"רוסנת": true,
"רוססה": true,
"רוססו": true,
"רוססת": true,
"רוסקה": true,
"רוסקו": true,
"רוסקת": true,
"רועדת": true,
"רועהו": true,
"רועות": true,
"רועיה": true,
"רועיו": true,
"רועיי": true,
"רועיך": true,
"רועים": true,
"רועכם": true,
"רועכן": true,
"רועמת": true,
"רוענו": true,
"רועשת": true,
"רועתה": true,
"רועתו": true,
"רועתי": true,
"רועתך": true,
"רועתם": true,
"רועתן": true,
"רופאה": true,
"רופאו": true,
"רופאי": true,
"רופאך": true,
"רופאם": true,
"רופאן": true,
"רופאת": true,
"רופדה": true,
"רופדו": true,
"רופדת": true,
"רופות": true,
"רופיה": true,
"רופים": true,
"רופין": true,
"רופסת": true,
"רופפה": true,
"רופפו": true,
"רופפי": true,
"רופפת": true,
"רוצות": true,
"רוצחה": true,
"רוצחו": true,
"רוצחי": true,
"רוצחך": true,
"רוצחם": true,
"רוצחן": true,
"רוצחת": true,
"רוצים": true,
"רוצית": true,
"רוצנה": true,
"רוצעת": true,
"רוצפה": true,
"רוצפו": true,
"רוצפת": true,
"רוצצה": true,
"רוצצו": true,
"רוצצי": true,
"רוצצת": true,
"רוצתה": true,
"רוקדת": true,
"רוקות": true,
"רוקחה": true,
"רוקחו": true,
"רוקחי": true,
"רוקחך": true,
"רוקחם": true,
"רוקחן": true,
"רוקחת": true,
"רוקכם": true,
"רוקכן": true,
"רוקמת": true,
"רוקנה": true,
"רוקנו": true,
"רוקני": true,
"רוקנת": true,
"רוקעה": true,
"רוקעו": true,
"רוקעת": true,
"רוקקת": true,
"רושמה": true,
"רושמו": true,
"רושמי": true,
"רושמך": true,
"רושמם": true,
"רושמן": true,
"רושמת": true,
"רושפת": true,
"רוששה": true,
"רוששו": true,
"רוששי": true,
"רוששת": true,
"רושתה": true,
"רושתו": true,
"רושתת": true,
"רותחת": true,
"רותכה": true,
"רותכו": true,
"רותכת": true,
"רותמה": true,
"רותמו": true,
"רותמי": true,
"רותמך": true,
"רותמם": true,
"רותמן": true,
"רותמת": true,
"רותעת": true,
"רותקה": true,
"רותקו": true,
"רותקת": true,
"רזומה": true,
"רזונה": true,
"רזונו": true,
"רזוני": true,
"רזונך": true,
"רזונם": true,
"רזונן": true,
"רזונת": true,
"רזיאל": true,
"רזיהם": true,
"רזיהן": true,
"רזייה": true,
"רזייך": true,
"רזיכם": true,
"רזיכן": true,
"רזינה": true,
"רזינו": true,
"רזיתי": true,
"רזיתם": true,
"רזיתן": true,
"רזניק": true,
"רזרבה": true,
"רזרבי": true,
"רחבות": true,
"רחביה": true,
"רחביו": true,
"רחביי": true,
"רחביך": true,
"רחבים": true,
"רחבעם": true,
"רחבתה": true,
"רחבתו": true,
"רחבתי": true,
"רחבתך": true,
"רחבתם": true,
"רחבתן": true,
"רחובה": true,
"רחובו": true,
"רחובי": true,
"רחובך": true,
"רחובם": true,
"רחובן": true,
"רחומה": true,
"רחוצה": true,
"רחוקה": true,
"רחורת": true,
"רחיהם": true,
"רחיהן": true,
"רחייך": true,
"רחיים": true,
"רחיכם": true,
"רחיכן": true,
"רחינו": true,
"רחיפה": true,
"רחיצה": true,
"רחיקה": true,
"רחלות": true,
"רחליה": true,
"רחליו": true,
"רחליי": true,
"רחליך": true,
"רחלים": true,
"רחלכם": true,
"רחלכן": true,
"רחלנו": true,
"רחמאן": true,
"רחמיה": true,
"רחמיו": true,
"רחמיי": true,
"רחמיך": true,
"רחמים": true,
"רחמית": true,
"רחמכם": true,
"רחמכן": true,
"רחמנא": true,
"רחמנה": true,
"רחמנו": true,
"רחמני": true,
"רחפות": true,
"רחפנה": true,
"רחפתה": true,
"רחפתו": true,
"רחפתי": true,
"רחפתך": true,
"רחפתם": true,
"רחפתן": true,
"רחצות": true,
"רחצנה": true,
"רחצנו": true,
"רחצתה": true,
"רחצתו": true,
"רחצתי": true,
"רחצתך": true,
"רחצתם": true,
"רחצתן": true,
"רחקנה": true,
"רחקנו": true,
"רחקתי": true,
"רחקתם": true,
"רחקתן": true,
"רחרוח": true,
"רחרחה": true,
"רחרחו": true,
"רחרחי": true,
"רחרחן": true,
"רחרחת": true,
"רחשוש": true,
"רחשיה": true,
"רחשיו": true,
"רחשיי": true,
"רחשיך": true,
"רחשים": true,
"רחשכם": true,
"רחשכן": true,
"רחשנה": true,
"רחשנו": true,
"רחשתי": true,
"רחשתם": true,
"רחשתן": true,
"רטביה": true,
"רטביו": true,
"רטביי": true,
"רטביך": true,
"רטבים": true,
"רטובה": true,
"רטונה": true,
"רטורי": true,
"רטטיה": true,
"רטטיו": true,
"רטטיי": true,
"רטטיך": true,
"רטטים": true,
"רטטכם": true,
"רטטכן": true,
"רטטנה": true,
"רטטנו": true,
"רטטתי": true,
"רטטתם": true,
"רטטתן": true,
"רטיבי": true,
"רטיות": true,
"רטיטה": true,
"רטייה": true,
"רטיים": true,
"רטינה": true,
"רטנתי": true,
"רטנתם": true,
"רטנתן": true,
"רטרוא": true,
"רטשנה": true,
"ריאוט": true,
"ריאול": true,
"ריאות": true,
"ריאלי": true,
"ריאשי": true,
"ריאתה": true,
"ריאתו": true,
"ריאתי": true,
"ריאתך": true,
"ריאתם": true,
"ריאתן": true,
"ריבבה": true,
"ריבבו": true,
"ריבבת": true,
"ריבדה": true,
"ריבדו": true,
"ריבדת": true,
"ריבוא": true,
"ריבוב": true,
"ריבוד": true,
"ריבוי": true,
"ריבון": true,
"ריבוע": true,
"ריבות": true,
"ריביה": true,
"ריביו": true,
"ריביי": true,
"ריביך": true,
"ריבים": true,
"ריבית": true,
"ריבכם": true,
"ריבכן": true,
"ריבנה": true,
"ריבנו": true,
"ריבעה": true,
"ריבעו": true,
"ריבעי": true,
"ריבעך": true,
"ריבעם": true,
"ריבען": true,
"ריבעת": true,
"ריבתה": true,
"ריבתו": true,
"ריבתי": true,
"ריבתך": true,
"ריבתם": true,
"ריבתן": true,
"ריגול": true,
"ריגוש": true,
"ריגלה": true,
"ריגלו": true,
"ריגלת": true,
"ריגשה": true,
"ריגשו": true,
"ריגשת": true,
"רידדה": true,
"רידדו": true,
"רידדת": true,
"רידוד": true,
"רידלי": true,
"ריהוט": true,
"ריהטה": true,
"ריהטו": true,
"ריהטת": true,
"ריווח": true,
"ריזות": true,
"ריחוף": true,
"ריחוק": true,
"ריחות": true,
"ריחכם": true,
"ריחכן": true,
"ריחמה": true,
"ריחמו": true,
"ריחמת": true,
"ריחנו": true,
"ריחני": true,
"ריחפה": true,
"ריחפו": true,
"ריחפת": true,
"ריטוב": true,
"ריטוט": true,
"ריטוש": true,
"ריטטה": true,
"ריטטו": true,
"ריטטת": true,
"ריטים": true,
"ריטשה": true,
"ריטשו": true,
"ריטשת": true,
"רייגן": true,
"ריידר": true,
"ריינה": true,
"ריינס": true,
"ריירה": true,
"ריכבו": true,
"ריכוז": true,
"ריכוך": true,
"ריכול": true,
"ריכות": true,
"ריכזה": true,
"ריכזו": true,
"ריכזת": true,
"ריכטר": true,
"ריכים": true,
"ריככה": true,
"ריככו": true,
"ריככת": true,
"ריכלה": true,
"ריכלו": true,
"ריכלת": true,
"רימון": true,
"רימות": true,
"רימזה": true,
"רימזו": true,
"רימזת": true,
"רימית": true,
"רימתה": true,
"רימתו": true,
"רימתי": true,
"רימתך": true,
"רימתם": true,
"רימתן": true,
"רינון": true,
"רינות": true,
"ריננה": true,
"ריננו": true,
"ריננת": true,
"רינתה": true,
"רינתו": true,
"רינתי": true,
"רינתך": true,
"רינתם": true,
"רינתן": true,
"ריסון": true,
"ריסוס": true,
"ריסוק": true,
"ריסיה": true,
"ריסיו": true,
"ריסיי": true,
"ריסיך": true,
"ריסים": true,
"ריסכם": true,
"ריסכן": true,
"ריסנה": true,
"ריסנו": true,
"ריסנת": true,
"ריססה": true,
"ריססו": true,
"ריססת": true,
"ריסקה": true,
"ריסקו": true,
"ריסקת": true,
"ריעים": true,
"ריפאה": true,
"ריפאו": true,
"ריפאת": true,
"ריפדה": true,
"ריפדו": true,
"ריפדת": true,
"ריפוד": true,
"ריפוי": true,
"ריפוף": true,
"ריפים": true,
"ריפין": true,
"ריפית": true,
"ריפמן": true,
"ריפתה": true,
"ריצדה": true,
"ריצדו": true,
"ריצדת": true,
"ריצוד": true,
"ריצוי": true,
"ריצוף": true,
"ריצוץ": true,
"ריצות": true,
"ריצים": true,
"ריצית": true,
"ריצפה": true,
"ריצפו": true,
"ריצפת": true,
"ריצרד": true,
"ריצתה": true,
"ריצתו": true,
"ריצתי": true,
"ריצתך": true,
"ריצתם": true,
"ריצתן": true,
"ריקדה": true,
"ריקדו": true,
"ריקדת": true,
"ריקוד": true,
"ריקון": true,
"ריקוע": true,
"ריקות": true,
"ריקים": true,
"ריקמת": true,
"ריקני": true,
"ריקעה": true,
"ריקעו": true,
"ריקעת": true,
"ריקשה": true,
"ריריה": true,
"ריריו": true,
"ריריי": true,
"ריריך": true,
"רירים": true,
"רירית": true,
"רירכם": true,
"רירכן": true,
"רירנו": true,
"רישוי": true,
"רישול": true,
"רישום": true,
"רישוש": true,
"רישות": true,
"רישמי": true,
"רישתה": true,
"רישתו": true,
"רישתי": true,
"רישתך": true,
"רישתם": true,
"רישתן": true,
"רישתת": true,
"ריתוך": true,
"ריתוק": true,
"ריתכה": true,
"ריתכו": true,
"ריתכת": true,
"ריתמי": true,
"ריתקה": true,
"ריתקו": true,
"ריתקת": true,
"רכאות": true,
"רכבות": true,
"רכביה": true,
"רכביו": true,
"רכביי": true,
"רכביך": true,
"רכבים": true,
"רכבכם": true,
"רכבכן": true,
"רכבלה": true,
"רכבלו": true,
"רכבלי": true,
"רכבלך": true,
"רכבלם": true,
"רכבלן": true,
"רכבנה": true,
"רכבנו": true,
"רכבתה": true,
"רכבתו": true,
"רכבתי": true,
"רכבתך": true,
"רכבתם": true,
"רכבתן": true,
"רכובה": true,
"רכונה": true,
"רכוסה": true,
"רכושה": true,
"רכושו": true,
"רכושי": true,
"רכושך": true,
"רכושם": true,
"רכושן": true,
"רכותה": true,
"רכותו": true,
"רכותי": true,
"רכותך": true,
"רכותם": true,
"רכותן": true,
"רכזות": true,
"רכזיה": true,
"רכזיו": true,
"רכזיי": true,
"רכזיך": true,
"רכזים": true,
"רכזית": true,
"רכזכם": true,
"רכזכן": true,
"רכזנה": true,
"רכזנו": true,
"רכזתה": true,
"רכזתו": true,
"רכזתי": true,
"רכזתך": true,
"רכזתם": true,
"רכזתן": true,
"רכיבה": true,
"רכיבו": true,
"רכיבי": true,
"רכיבך": true,
"רכיבם": true,
"רכיבן": true,
"רכיבת": true,
"רכיון": true,
"רכיכה": true,
"רכינה": true,
"רכיסה": true,
"רכישה": true,
"רכישת": true,
"רככנה": true,
"רככתה": true,
"רככתו": true,
"רככתי": true,
"רככתך": true,
"רככתם": true,
"רככתן": true,
"רכלנה": true,
"רכנים": true,
"רכניץ": true,
"רכנתי": true,
"רכנתם": true,
"רכנתן": true,
"רכסיה": true,
"רכסיו": true,
"רכסיי": true,
"רכסיך": true,
"רכסים": true,
"רכסכם": true,
"רכסכן": true,
"רכסנו": true,
"רכסתי": true,
"רכסתם": true,
"רכסתן": true,
"רכשנו": true,
"רכשתי": true,
"רכשתם": true,
"רכשתן": true,
"רמאדן": true,
"רמאות": true,
"רמאיה": true,
"רמאיו": true,
"רמאיי": true,
"רמאיך": true,
"רמאים": true,
"רמאית": true,
"רמאכם": true,
"רמאכן": true,
"רמאנו": true,
"רמדאן": true,
"רמוזה": true,
"רמוסה": true,
"רמזור": true,
"רמזיה": true,
"רמזיו": true,
"רמזיי": true,
"רמזיך": true,
"רמזים": true,
"רמזכם": true,
"רמזכן": true,
"רמזנה": true,
"רמזנו": true,
"רמזרה": true,
"רמזרו": true,
"רמזרי": true,
"רמזרת": true,
"רמזתי": true,
"רמזתם": true,
"רמזתן": true,
"רמחיה": true,
"רמחיו": true,
"רמחיי": true,
"רמחיך": true,
"רמחים": true,
"רמטכל": true,
"רמיאל": true,
"רמיות": true,
"רמיזה": true,
"רמייה": true,
"רמינה": true,
"רמיסה": true,
"רמלין": true,
"רמניה": true,
"רמנים": true,
"רמנית": true,
"רמסנו": true,
"רמסתי": true,
"רמסתם": true,
"רמסתן": true,
"רמפות": true,
"רמציה": true,
"רמציו": true,
"רמציי": true,
"רמציך": true,
"רמצים": true,
"רמצכם": true,
"רמצכן": true,
"רמצנו": true,
"רמקול": true,
"רמרקט": true,
"רמשיה": true,
"רמשיו": true,
"רמשיי": true,
"רמשיך": true,
"רמשים": true,
"רמשכם": true,
"רמשכן": true,
"רמשנו": true,
"רמתכם": true,
"רמתכן": true,
"רמתנו": true,
"רנאטו": true,
"רנגון": true,
"רנונו": true,
"רנותי": true,
"רנותם": true,
"רנותן": true,
"רנטגן": true,
"רניום": true,
"רננות": true,
"רננתה": true,
"רננתו": true,
"רננתי": true,
"רננתך": true,
"רננתם": true,
"רננתן": true,
"רנסנס": true,
"רנתיס": true,
"רסאות": true,
"רסיבר": true,
"רסיטל": true,
"רסיסה": true,
"רסיסו": true,
"רסיסי": true,
"רסיסך": true,
"רסיסם": true,
"רסיסן": true,
"רסניה": true,
"רסניו": true,
"רסניי": true,
"רסניך": true,
"רסנים": true,
"רסנכם": true,
"רסנכן": true,
"רסננו": true,
"רססנה": true,
"רסקיה": true,
"רסקיו": true,
"רסקיי": true,
"רסקיך": true,
"רסקים": true,
"רסקין": true,
"רסקכם": true,
"רסקכן": true,
"רסקנה": true,
"רסקנו": true,
"רעבון": true,
"רעבות": true,
"רעבים": true,
"רעבכם": true,
"רעבכן": true,
"רעבנה": true,
"רעבנו": true,
"רעבתי": true,
"רעבתם": true,
"רעבתן": true,
"רעדות": true,
"רעדיה": true,
"רעדיו": true,
"רעדיי": true,
"רעדיך": true,
"רעדים": true,
"רעדכם": true,
"רעדכן": true,
"רעדנה": true,
"רעדנו": true,
"רעדתה": true,
"רעדתו": true,
"רעדתי": true,
"רעדתך": true,
"רעדתם": true,
"רעדתן": true,
"רעואל": true,
"רעויה": true,
"רעולה": true,
"רעועה": true,
"רעותה": true,
"רעותו": true,
"רעותי": true,
"רעותך": true,
"רעותם": true,
"רעותן": true,
"רעידה": true,
"רעידת": true,
"רעיהם": true,
"רעיהן": true,
"רעיון": true,
"רעיות": true,
"רעייה": true,
"רעייך": true,
"רעיכם": true,
"רעיכן": true,
"רעילה": true,
"רעינה": true,
"רעינו": true,
"רעיתי": true,
"רעיתם": true,
"רעיתן": true,
"רעלות": true,
"רעליה": true,
"רעליו": true,
"רעליי": true,
"רעליך": true,
"רעלים": true,
"רעלכם": true,
"רעלכן": true,
"רעלנה": true,
"רעלנו": true,
"רעלני": true,
"רעלנך": true,
"רעלנם": true,
"רעלנן": true,
"רעלתה": true,
"רעלתו": true,
"רעלתי": true,
"רעלתך": true,
"רעלתם": true,
"רעלתן": true,
"רעמות": true,
"רעמיה": true,
"רעמיו": true,
"רעמיי": true,
"רעמיך": true,
"רעמים": true,
"רעמכם": true,
"רעמכן": true,
"רעמנה": true,
"רעמנו": true,
"רעמסס": true,
"רעמתה": true,
"רעמתו": true,
"רעמתי": true,
"רעמתך": true,
"רעמתם": true,
"רעמתן": true,
"רענון": true,
"רעננה": true,
"רעננו": true,
"רענני": true,
"רעננת": true,
"רעפיה": true,
"רעפיו": true,
"רעפיי": true,
"רעפיך": true,
"רעפים": true,
"רעפכם": true,
"רעפכן": true,
"רעפנו": true,
"רעשיה": true,
"רעשיו": true,
"רעשיי": true,
"רעשיך": true,
"רעשים": true,
"רעשכם": true,
"רעשכן": true,
"רעשנה": true,
"רעשנו": true,
"רעשני": true,
"רעשנך": true,
"רעשנם": true,
"רעשנן": true,
"רעשתי": true,
"רעשתם": true,
"רעשתן": true,
"רעתכם": true,
"רעתכן": true,
"רעתנו": true,
"רפאות": true,
"רפאיה": true,
"רפאיו": true,
"רפאיי": true,
"רפאיך": true,
"רפאים": true,
"רפאית": true,
"רפאכם": true,
"רפאכן": true,
"רפאלי": true,
"רפאנה": true,
"רפאנו": true,
"רפדות": true,
"רפדיה": true,
"רפדיו": true,
"רפדיי": true,
"רפדיך": true,
"רפדים": true,
"רפדכם": true,
"רפדכן": true,
"רפדנה": true,
"רפדנו": true,
"רפדתה": true,
"רפדתו": true,
"רפדתי": true,
"רפדתך": true,
"רפדתם": true,
"רפדתן": true,
"רפואה": true,
"רפואי": true,
"רפואת": true,
"רפויה": true,
"רפורט": true,
"רפידה": true,
"רפיהם": true,
"רפיהן": true,
"רפיון": true,
"רפייך": true,
"רפיכם": true,
"רפיכן": true,
"רפינה": true,
"רפינו": true,
"רפיעה": true,
"רפיקה": true,
"רפיתי": true,
"רפיתם": true,
"רפיתן": true,
"רפלקט": true,
"רפלקס": true,
"רפפות": true,
"רפרוף": true,
"רפרפה": true,
"רפרפו": true,
"רפרפי": true,
"רפרפת": true,
"רפשכם": true,
"רפשכן": true,
"רפשנו": true,
"רפתות": true,
"רפתכם": true,
"רפתכן": true,
"רפתנה": true,
"רפתנו": true,
"רפתני": true,
"רפתנך": true,
"רפתנם": true,
"רפתנן": true,
"רפתקה": true,
"רצאבי": true,
"רצדנה": true,
"רצויה": true,
"רצונה": true,
"רצונו": true,
"רצוני": true,
"רצונך": true,
"רצונם": true,
"רצונן": true,
"רצועה": true,
"רצועת": true,
"רצופה": true,
"רצוצה": true,
"רצחנה": true,
"רצחנו": true,
"רצחני": true,
"רצחתי": true,
"רצחתם": true,
"רצחתן": true,
"רציהם": true,
"רציהן": true,
"רציונ": true,
"רציות": true,
"רציחה": true,
"רצייה": true,
"רצייך": true,
"רציכם": true,
"רציכן": true,
"רצינה": true,
"רצינו": true,
"רציני": true,
"רציעה": true,
"רציפה": true,
"רציפו": true,
"רציפי": true,
"רציפך": true,
"רציפם": true,
"רציפן": true,
"רציצה": true,
"רציתי": true,
"רציתם": true,
"רציתן": true,
"רצענה": true,
"רצענו": true,
"רצעני": true,
"רצענך": true,
"רצענם": true,
"רצענן": true,
"רצעתי": true,
"רצעתם": true,
"רצעתן": true,
"רצפות": true,
"רצפיה": true,
"רצפיו": true,
"רצפיי": true,
"רצפיך": true,
"רצפים": true,
"רצפית": true,
"רצפכם": true,
"רצפכן": true,
"רצפלד": true,
"רצפנה": true,
"רצפנו": true,
"רצפתה": true,
"רצפתו": true,
"רצפתי": true,
"רצפתך": true,
"רצפתם": true,
"רצפתן": true,
"רצצנו": true,
"רצצתי": true,
"רצצתם": true,
"רצצתן": true,
"רצתכם": true,
"רצתכן": true,
"רצתנו": true,
"רקבון": true,
"רקבות": true,
"רקבים": true,
"רקבנו": true,
"רקבתי": true,
"רקבתם": true,
"רקבתן": true,
"רקדנה": true,
"רקדנו": true,
"רקדני": true,
"רקדנך": true,
"רקדנם": true,
"רקדנן": true,
"רקדתי": true,
"רקדתם": true,
"רקדתן": true,
"רקובה": true,
"רקובר": true,
"רקוחה": true,
"רקומה": true,
"רקועה": true,
"רקורד": true,
"רקחנה": true,
"רקחנו": true,
"רקחתי": true,
"רקחתם": true,
"רקחתן": true,
"רקטום": true,
"רקטור": true,
"רקטות": true,
"רקטית": true,
"רקטלי": true,
"רקטתה": true,
"רקטתו": true,
"רקטתי": true,
"רקטתך": true,
"רקטתם": true,
"רקטתן": true,
"רקיחה": true,
"רקימה": true,
"רקיעה": true,
"רקיעו": true,
"רקיעי": true,
"רקיעך": true,
"רקיעם": true,
"רקיען": true,
"רקיקה": true,
"רקיקו": true,
"רקיקי": true,
"רקיקך": true,
"רקיקם": true,
"רקיקן": true,
"רקמות": true,
"רקמנו": true,
"רקמתה": true,
"רקמתו": true,
"רקמתי": true,
"רקמתך": true,
"רקמתם": true,
"רקמתן": true,
"רקעיה": true,
"רקעיו": true,
"רקעיי": true,
"רקעיך": true,
"רקעים": true,
"רקעין": true,
"רקעכם": true,
"רקעכן": true,
"רקענה": true,
"רקענו": true,
"רקעתי": true,
"רקעתם": true,
"רקעתן": true,
"רקפות": true,
"רקפתה": true,
"רקפתו": true,
"רקפתי": true,
"רקפתך": true,
"רקפתם": true,
"רקפתן": true,
"רקקנו": true,
"רקקתי": true,
"רקקתם": true,
"רקקתן": true,
"רקתכם": true,
"רקתכן": true,
"רקתנו": true,
"רשאים": true,
"רשאית": true,
"רשברג": true,
"רשומה": true,
"רשופה": true,
"רשותה": true,
"רשותו": true,
"רשותי": true,
"רשותך": true,
"רשותם": true,
"רשותן": true,
"רשיון": true,
"רשימה": true,
"רשימת": true,
"רשלני": true,
"רשמות": true,
"רשמיה": true,
"רשמיו": true,
"רשמיי": true,
"רשמיך": true,
"רשמים": true,
"רשמית": true,
"רשמכם": true,
"רשמכן": true,
"רשמנו": true,
"רשמתה": true,
"רשמתו": true,
"רשמתי": true,
"רשמתך": true,
"רשמתם": true,
"רשמתן": true,
"רשעות": true,
"רשעים": true,
"רשעכם": true,
"רשעכן": true,
"רשענו": true,
"רשעתה": true,
"רשעתו": true,
"רשעתי": true,
"רשעתך": true,
"רשעתם": true,
"רשעתן": true,
"רשפיה": true,
"רשפיו": true,
"רשפיי": true,
"רשפיך": true,
"רשפים": true,
"רשפכם": true,
"רשפכן": true,
"רשפנו": true,
"רשפתי": true,
"רשפתם": true,
"רשפתן": true,
"רשרוש": true,
"רשרשה": true,
"רשרשו": true,
"רשרשי": true,
"רשרשת": true,
"רשתות": true,
"רשתית": true,
"רשתכם": true,
"רשתכן": true,
"רשתנה": true,
"רשתנו": true,
"רתוחה": true,
"רתומה": true,
"רתוקה": true,
"רתחנה": true,
"רתחנו": true,
"רתחני": true,
"רתחתי": true,
"רתחתם": true,
"רתחתן": true,
"רתיחה": true,
"רתימה": true,
"רתיעה": true,
"רתכות": true,
"רתכיה": true,
"רתכיו": true,
"רתכיי": true,
"רתכיך": true,
"רתכים": true,
"רתככם": true,
"רתככן": true,
"רתכנה": true,
"רתכנו": true,
"רתכתה": true,
"רתכתו": true,
"רתכתי": true,
"רתכתך": true,
"רתכתם": true,
"רתכתן": true,
"רתמות": true,
"רתמיה": true,
"רתמיו": true,
"רתמיי": true,
"רתמיך": true,
"רתמים": true,
"רתמנו": true,
"רתמתה": true,
"רתמתו": true,
"רתמתי": true,
"רתמתך": true,
"רתמתם": true,
"רתמתן": true,
"רתענה": true,
"רתענו": true,
"רתעתי": true,
"רתעתם": true,
"רתעתן": true,
"רתקנה": true,
"שאביו": true,
"שאבנה": true,
"שאבנו": true,
"שאבתי": true,
"שאבתם": true,
"שאבתן": true,
"שאגות": true,
"שאגנה": true,
"שאגנו": true,
"שאגתה": true,
"שאגתו": true,
"שאגתי": true,
"שאגתך": true,
"שאגתם": true,
"שאגתן": true,
"שאהיד": true,
"שאהיה": true,
"שאובה": true,
"שאוהב": true,
"שאוכל": true,
"שאולה": true,
"שאולי": true,
"שאומר": true,
"שאונה": true,
"שאונו": true,
"שאוני": true,
"שאונך": true,
"שאונם": true,
"שאונן": true,
"שאופה": true,
"שאורה": true,
"שאורו": true,
"שאורי": true,
"שאורך": true,
"שאורם": true,
"שאורן": true,
"שאושר": true,
"שאותה": true,
"שאותו": true,
"שאותם": true,
"שאותן": true,
"שאחרי": true,
"שאיבד": true,
"שאיבה": true,
"שאיבת": true,
"שאיזו": true,
"שאילה": true,
"שאילו": true,
"שאילת": true,
"שאינה": true,
"שאינו": true,
"שאיני": true,
"שאינך": true,
"שאינם": true,
"שאינן": true,
"שאיפה": true,
"שאירה": true,
"שאירו": true,
"שאירי": true,
"שאירך": true,
"שאירם": true,
"שאירן": true,
"שאירע": true,
"שאישה": true,
"שאיתה": true,
"שאיתו": true,
"שאיתם": true,
"שאלון": true,
"שאלות": true,
"שאליה": true,
"שאליו": true,
"שאלנה": true,
"שאלנו": true,
"שאלתה": true,
"שאלתו": true,
"שאלתי": true,
"שאלתך": true,
"שאלתם": true,
"שאלתן": true,
"שאמור": true,
"שאמנם": true,
"שאמרה": true,
"שאמרו": true,
"שאמרת": true,
"שאנור": true,
"שאננה": true,
"שאנשי": true,
"שאסור": true,
"שאפנה": true,
"שאפנו": true,
"שאפשר": true,
"שאפתי": true,
"שאפתם": true,
"שאפתן": true,
"שאראס": true,
"שארות": true,
"שאריה": true,
"שאריו": true,
"שאריי": true,
"שאריך": true,
"שארים": true,
"שארית": true,
"שארכם": true,
"שארכן": true,
"שארנו": true,
"שארעו": true,
"שארתה": true,
"שארתו": true,
"שארתי": true,
"שארתך": true,
"שארתם": true,
"שארתן": true,
"שאשתו": true,
"שאתות": true,
"שאתכם": true,
"שאתכן": true,
"שאתנו": true,
"שבאים": true,
"שבאמת": true,
"שבארץ": true,
"שבאתר": true,
"שבביה": true,
"שבביו": true,
"שבביי": true,
"שבביך": true,
"שבבים": true,
"שבבית": true,
"שבבכם": true,
"שבבכן": true,
"שבבנו": true,
"שבגיל": true,
"שבגלל": true,
"שבדיה": true,
"שבדרך": true,
"שבוחר": true,
"שבויה": true,
"שבויו": true,
"שבויי": true,
"שבויך": true,
"שבוים": true,
"שבוין": true,
"שבועה": true,
"שבועו": true,
"שבועי": true,
"שבועך": true,
"שבועם": true,
"שבוען": true,
"שבועת": true,
"שבוצע": true,
"שבורה": true,
"שבזמן": true,
"שבחוץ": true,
"שבחיה": true,
"שבחיו": true,
"שבחיי": true,
"שבחיך": true,
"שבחים": true,
"שבחכם": true,
"שבחכן": true,
"שבחלק": true,
"שבחנה": true,
"שבחנו": true,
"שבחרה": true,
"שבחרו": true,
"שבחרת": true,
"שבטוח": true,
"שבטיה": true,
"שבטיו": true,
"שבטיי": true,
"שבטיך": true,
"שבטים": true,
"שבטית": true,
"שבטכם": true,
"שבטכן": true,
"שבטנה": true,
"שבטנו": true,
"שביבה": true,
"שביבו": true,
"שביבי": true,
"שביבך": true,
"שביבם": true,
"שביבן": true,
"שבידי": true,
"שביום": true,
"שביות": true,
"שביטה": true,
"שביטו": true,
"שביטי": true,
"שביטך": true,
"שביטם": true,
"שביטן": true,
"שבייה": true,
"שבייך": true,
"שביים": true,
"שביין": true,
"שבילה": true,
"שבילו": true,
"שבילי": true,
"שבילך": true,
"שבילם": true,
"שבילן": true,
"שבינה": true,
"שבינו": true,
"שביסה": true,
"שביסו": true,
"שביסי": true,
"שביסך": true,
"שביסם": true,
"שביסן": true,
"שביעי": true,
"שביצע": true,
"שביקר": true,
"שביקש": true,
"שבירה": true,
"שבירו": true,
"שבירת": true,
"שביתה": true,
"שביתי": true,
"שביתם": true,
"שביתן": true,
"שביתת": true,
"שבכות": true,
"שבכלל": true,
"שבכתב": true,
"שבכתה": true,
"שבכתו": true,
"שבכתי": true,
"שבכתך": true,
"שבכתם": true,
"שבכתן": true,
"שבלאס": true,
"שבלול": true,
"שבליה": true,
"שבליו": true,
"שבליי": true,
"שבליך": true,
"שבלים": true,
"שבלעד": true,
"שבמשך": true,
"שבסוף": true,
"שבסקי": true,
"שבעבר": true,
"שבעוד": true,
"שבעות": true,
"שבעים": true,
"שבעיר": true,
"שבעלה": true,
"שבעלי": true,
"שבענה": true,
"שבענו": true,
"שבעצם": true,
"שבעתי": true,
"שבעתם": true,
"שבעתן": true,
"שבפני": true,
"שבציה": true,
"שבציו": true,
"שבציי": true,
"שבציך": true,
"שבצים": true,
"שבצכם": true,
"שבצכן": true,
"שבצנה": true,
"שבצנו": true,
"שבקנו": true,
"שבקתי": true,
"שבקתם": true,
"שבקתן": true,
"שבראש": true,
"שברגע": true,
"שברוב": true,
"שברון": true,
"שברור": true,
"שבריה": true,
"שבריו": true,
"שבריי": true,
"שבריך": true,
"שברים": true,
"שבריר": true,
"שברכם": true,
"שברכן": true,
"שברנה": true,
"שברנו": true,
"שברתי": true,
"שברתם": true,
"שברתן": true,
"שבשבת": true,
"שבשלב": true,
"שבשנה": true,
"שבתאי": true,
"שבתוך": true,
"שבתון": true,
"שבתות": true,
"שבתכם": true,
"שבתכן": true,
"שבתנו": true,
"שבתתי": true,
"שבתתם": true,
"שבתתן": true,
"שגגות": true,
"שגגנו": true,
"שגגתה": true,
"שגגתו": true,
"שגגתי": true,
"שגגתך": true,
"שגגתם": true,
"שגגתן": true,
"שגדלה": true,
"שגדלו": true,
"שגובה": true,
"שגוגה": true,
"שגוגל": true,
"שגויה": true,
"שגורה": true,
"שגורם": true,
"שגיאה": true,
"שגיבה": true,
"שגיגה": true,
"שגיון": true,
"שגיות": true,
"שגייה": true,
"שגילה": true,
"שגילו": true,
"שגינה": true,
"שגינו": true,
"שגיתי": true,
"שגיתם": true,
"שגיתן": true,
"שגעון": true,
"שגענה": true,
"שגרון": true,
"שגרות": true,
"שגריה": true,
"שגריו": true,
"שגריי": true,
"שגריך": true,
"שגרים": true,
"שגריר": true,
"שגרכם": true,
"שגרכן": true,
"שגרמה": true,
"שגרמו": true,
"שגרנה": true,
"שגרנו": true,
"שגרתה": true,
"שגרתו": true,
"שגרתי": true,
"שגרתך": true,
"שגרתם": true,
"שגרתן": true,
"שגשגה": true,
"שגשגו": true,
"שגשגי": true,
"שגשגת": true,
"שגשוג": true,
"שדאות": true,
"שדברי": true,
"שדדנה": true,
"שדדנו": true,
"שדדתי": true,
"שדדתם": true,
"שדדתן": true,
"שדודה": true,
"שדולה": true,
"שדולן": true,
"שדומה": true,
"שדונה": true,
"שדונו": true,
"שדוני": true,
"שדונך": true,
"שדונם": true,
"שדונן": true,
"שדונת": true,
"שדופה": true,
"שדורש": true,
"שדיבר": true,
"שדיהם": true,
"שדיהן": true,
"שדייך": true,
"שדיים": true,
"שדיכם": true,
"שדיכן": true,
"שדינו": true,
"שדכנה": true,
"שדכנו": true,
"שדכני": true,
"שדכנך": true,
"שדכנם": true,
"שדכנן": true,
"שדלנה": true,
"שדמות": true,
"שדפון": true,
"שדרגה": true,
"שדרגו": true,
"שדרגי": true,
"שדרגת": true,
"שדרוג": true,
"שדרוש": true,
"שדרות": true,
"שדריה": true,
"שדריו": true,
"שדריי": true,
"שדריך": true,
"שדרים": true,
"שדרית": true,
"שדרכו": true,
"שדרכם": true,
"שדרכן": true,
"שדרנה": true,
"שדרנו": true,
"שדרני": true,
"שדרנך": true,
"שדרנם": true,
"שדרנן": true,
"שדרשו": true,
"שדרתה": true,
"שדרתו": true,
"שדרתי": true,
"שדרתך": true,
"שדרתם": true,
"שדרתן": true,
"שהאדא": true,
"שהאדם": true,
"שהאור": true,
"שהאיש": true,
"שהאתר": true,
"שהביא": true,
"שהבין": true,
"שהבית": true,
"שהבנק": true,
"שהבעל": true,
"שהברי": true,
"שהגיע": true,
"שהגיש": true,
"שהדבר": true,
"שהדכם": true,
"שהדכן": true,
"שהדנו": true,
"שהדרך": true,
"שהובא": true,
"שהוגש": true,
"שהוכח": true,
"שהוכר": true,
"שהולך": true,
"שהופך": true,
"שהופק": true,
"שהוצא": true,
"שהוצג": true,
"שהוצע": true,
"שהוקם": true,
"שהורה": true,
"שהושג": true,
"שהושק": true,
"שהותה": true,
"שהותו": true,
"שהותי": true,
"שהותך": true,
"שהותם": true,
"שהותן": true,
"שהזמן": true,
"שהחוק": true,
"שהחלה": true,
"שהחלו": true,
"שהחלט": true,
"שהטיל": true,
"שהידה": true,
"שהיוו": true,
"שהיום": true,
"שהיות": true,
"שהייה": true,
"שהיית": true,
"שהילד": true,
"שהינה": true,
"שהינו": true,
"שהינם": true,
"שהיתה": true,
"שהיתי": true,
"שהיתם": true,
"שהיתן": true,
"שהכול": true,
"שהכין": true,
"שהכיר": true,
"שהכנס": true,
"שהכסף": true,
"שהלכה": true,
"שהלכו": true,
"שהמים": true,
"שהמנה": true,
"שהמצב": true,
"שהספר": true,
"שהסרט": true,
"שהעיר": true,
"שהעלה": true,
"שהעלו": true,
"שהעסק": true,
"שהפכה": true,
"שהפכו": true,
"שהפעם": true,
"שהפרו": true,
"שהפרי": true,
"שהצבא": true,
"שהציב": true,
"שהציג": true,
"שהציע": true,
"שהצפי": true,
"שהקהל": true,
"שהקים": true,
"שהקנה": true,
"שהקשר": true,
"שהרבה": true,
"שהרבי": true,
"שהרכב": true,
"שהרמב": true,
"שהשוק": true,
"שהשיר": true,
"שהשנה": true,
"שהתרח": true,
"שואבה": true,
"שואבת": true,
"שואגת": true,
"שואות": true,
"שואים": true,
"שואין": true,
"שואלת": true,
"שואעי": true,
"שואפת": true,
"שואתה": true,
"שואתו": true,
"שואתי": true,
"שואתך": true,
"שואתם": true,
"שואתן": true,
"שובבה": true,
"שובבו": true,
"שובבי": true,
"שובבת": true,
"שובהו": true,
"שובות": true,
"שובחה": true,
"שובחו": true,
"שובחת": true,
"שובטה": true,
"שובטו": true,
"שובטת": true,
"שוביה": true,
"שוביו": true,
"שוביי": true,
"שוביך": true,
"שובים": true,
"שובכה": true,
"שובכו": true,
"שובכי": true,
"שובכך": true,
"שובכם": true,
"שובכן": true,
"שובלה": true,
"שובלו": true,
"שובלי": true,
"שובלך": true,
"שובלם": true,
"שובלן": true,
"שובנה": true,
"שובנו": true,
"שובעה": true,
"שובעו": true,
"שובעי": true,
"שובעך": true,
"שובעם": true,
"שובען": true,
"שובצה": true,
"שובצו": true,
"שובצת": true,
"שובקת": true,
"שוברה": true,
"שוברו": true,
"שוברט": true,
"שוברי": true,
"שוברת": true,
"שובשה": true,
"שובשו": true,
"שובשת": true,
"שובתה": true,
"שובתו": true,
"שובתי": true,
"שובתך": true,
"שובתם": true,
"שובתן": true,
"שובתת": true,
"שוגגת": true,
"שוגות": true,
"שוגים": true,
"שוגעה": true,
"שוגעו": true,
"שוגעת": true,
"שוגרה": true,
"שוגרו": true,
"שוגרת": true,
"שודדה": true,
"שודדו": true,
"שודדי": true,
"שודדך": true,
"שודדם": true,
"שודדן": true,
"שודדת": true,
"שודיה": true,
"שודיו": true,
"שודיי": true,
"שודיך": true,
"שודים": true,
"שודכה": true,
"שודכו": true,
"שודכם": true,
"שודכן": true,
"שודכת": true,
"שודלה": true,
"שודלו": true,
"שודלת": true,
"שודנו": true,
"שודרג": true,
"שודרה": true,
"שודרו": true,
"שודרת": true,
"שוהות": true,
"שוהים": true,
"שוהמה": true,
"שוהמו": true,
"שוהמי": true,
"שוהמך": true,
"שוהמם": true,
"שוהמן": true,
"שוואה": true,
"שוואי": true,
"שוואת": true,
"שוודי": true,
"שוויו": true,
"שוויי": true,
"שווים": true,
"שוויץ": true,
"שווית": true,
"שוועה": true,
"שוועו": true,
"שוועי": true,
"שווקה": true,
"שווקו": true,
"שווקי": true,
"שווקת": true,
"שוורץ": true,
"שוותה": true,
"שוזפת": true,
"שוזרת": true,
"שוחבר": true,
"שוחדה": true,
"שוחדו": true,
"שוחדי": true,
"שוחדך": true,
"שוחדם": true,
"שוחדן": true,
"שוחדת": true,
"שוחות": true,
"שוחזר": true,
"שוחחה": true,
"שוחחו": true,
"שוחחי": true,
"שוחחת": true,
"שוחטת": true,
"שוחים": true,
"שוחלף": true,
"שוחקה": true,
"שוחקו": true,
"שוחקת": true,
"שוחרי": true,
"שוחרר": true,
"שוחרת": true,
"שוחתה": true,
"שוחתו": true,
"שוחתי": true,
"שוחתך": true,
"שוחתם": true,
"שוחתן": true,
"שוטון": true,
"שוטות": true,
"שוטחת": true,
"שוטטה": true,
"שוטטו": true,
"שוטטי": true,
"שוטטת": true,
"שוטיה": true,
"שוטיו": true,
"שוטיי": true,
"שוטיך": true,
"שוטים": true,
"שוטכם": true,
"שוטכן": true,
"שוטמת": true,
"שוטנה": true,
"שוטנו": true,
"שוטפת": true,
"שוטרה": true,
"שוטרו": true,
"שוטרי": true,
"שוטרך": true,
"שוטרם": true,
"שוטרן": true,
"שוטרת": true,
"שויון": true,
"שויות": true,
"שוייך": true,
"שויכה": true,
"שויכו": true,
"שויכת": true,
"שוילי": true,
"שויפה": true,
"שויפו": true,
"שויפת": true,
"שוכבת": true,
"שוכחת": true,
"שוככה": true,
"שוככו": true,
"שוככם": true,
"שוככן": true,
"שוככת": true,
"שוכלה": true,
"שוכלו": true,
"שוכלל": true,
"שוכלת": true,
"שוכנה": true,
"שוכנו": true,
"שוכנע": true,
"שוכנת": true,
"שוכפל": true,
"שוכרת": true,
"שוכתב": true,
"שולבה": true,
"שולבו": true,
"שולבת": true,
"שולהב": true,
"שולות": true,
"שולחה": true,
"שולחו": true,
"שולחי": true,
"שולחך": true,
"שולחם": true,
"שולחן": true,
"שולחת": true,
"שולטת": true,
"שוליה": true,
"שוליו": true,
"שוליי": true,
"שוליך": true,
"שולים": true,
"שולית": true,
"שולכם": true,
"שולכן": true,
"שוללת": true,
"שולמה": true,
"שולמו": true,
"שולמת": true,
"שולנו": true,
"שולפת": true,
"שולקת": true,
"שולשה": true,
"שולשו": true,
"שולשל": true,
"שולשת": true,
"שומות": true,
"שומטת": true,
"שומיה": true,
"שומיו": true,
"שומיי": true,
"שומיך": true,
"שומים": true,
"שומכם": true,
"שומכן": true,
"שוממה": true,
"שוממת": true,
"שומנה": true,
"שומנו": true,
"שומני": true,
"שומנך": true,
"שומנם": true,
"שומנן": true,
"שומנת": true,
"שומעה": true,
"שומעו": true,
"שומעי": true,
"שומעך": true,
"שומעם": true,
"שומען": true,
"שומעת": true,
"שומרה": true,
"שומרו": true,
"שומרי": true,
"שומרך": true,
"שומרם": true,
"שומרן": true,
"שומרת": true,
"שומתה": true,
"שומתו": true,
"שומתי": true,
"שומתך": true,
"שומתם": true,
"שומתן": true,
"שונאה": true,
"שונאו": true,
"שונאי": true,
"שונאך": true,
"שונאם": true,
"שונאן": true,
"שונאת": true,
"שונות": true,
"שוניו": true,
"שוניי": true,
"שונים": true,
"שונית": true,
"שוננה": true,
"שוננו": true,
"שוננת": true,
"שונעה": true,
"שונעו": true,
"שונעת": true,
"שונרה": true,
"שונרו": true,
"שונרי": true,
"שונרך": true,
"שונרם": true,
"שונרן": true,
"שונתה": true,
"שוסטר": true,
"שוסית": true,
"שוסעה": true,
"שוסעו": true,
"שוסעת": true,
"שוספה": true,
"שוספו": true,
"שוספת": true,
"שוסתה": true,
"שועאי": true,
"שועבד": true,
"שועות": true,
"שועטת": true,
"שועיה": true,
"שועיו": true,
"שועיי": true,
"שועיך": true,
"שועים": true,
"שועכם": true,
"שועכן": true,
"שועלה": true,
"שועלו": true,
"שועלי": true,
"שועלך": true,
"שועלם": true,
"שועלן": true,
"שועמם": true,
"שוענו": true,
"שועפט": true,
"שוערה": true,
"שוערו": true,
"שוערי": true,
"שוערך": true,
"שוערם": true,
"שוערן": true,
"שוערת": true,
"שועשע": true,
"שועתק": true,
"שופדה": true,
"שופדו": true,
"שופדת": true,
"שופטה": true,
"שופטו": true,
"שופטי": true,
"שופטך": true,
"שופטם": true,
"שופטן": true,
"שופטת": true,
"שופין": true,
"שופית": true,
"שופכן": true,
"שופכת": true,
"שופמן": true,
"שופעה": true,
"שופעו": true,
"שופעל": true,
"שופעת": true,
"שופצה": true,
"שופצו": true,
"שופצר": true,
"שופצת": true,
"שופרה": true,
"שופרו": true,
"שופרי": true,
"שופרך": true,
"שופרם": true,
"שופרן": true,
"שופרת": true,
"שופשף": true,
"שופתה": true,
"שופתת": true,
"שוצפת": true,
"שוקדת": true,
"שוקחה": true,
"שוקטת": true,
"שוקיה": true,
"שוקיו": true,
"שוקיי": true,
"שוקיך": true,
"שוקים": true,
"שוקין": true,
"שוקכם": true,
"שוקכן": true,
"שוקלל": true,
"שוקלת": true,
"שוקמה": true,
"שוקמו": true,
"שוקמת": true,
"שוקנו": true,
"שוקעה": true,
"שוקעו": true,
"שוקעת": true,
"שוקפה": true,
"שוקפו": true,
"שוקפת": true,
"שוקצה": true,
"שוקצו": true,
"שוקצת": true,
"שוקקת": true,
"שוקתה": true,
"שוקתו": true,
"שוקתי": true,
"שוקתך": true,
"שוקתם": true,
"שוקתן": true,
"שורבב": true,
"שורבט": true,
"שורגי": true,
"שורדת": true,
"שורוק": true,
"שורות": true,
"שורטט": true,
"שורטת": true,
"שורים": true,
"שורכם": true,
"שורכן": true,
"שורכת": true,
"שורנו": true,
"שורפת": true,
"שורצת": true,
"שורקת": true,
"שוררת": true,
"שורשה": true,
"שורשו": true,
"שורשי": true,
"שורשך": true,
"שורשם": true,
"שורשן": true,
"שורשר": true,
"שורשת": true,
"שורתה": true,
"שורתו": true,
"שורתי": true,
"שורתך": true,
"שורתם": true,
"שורתן": true,
"שושלת": true,
"שושנה": true,
"שושנו": true,
"שושני": true,
"שושנך": true,
"שושנם": true,
"שושנן": true,
"שושנת": true,
"שותות": true,
"שותיו": true,
"שותים": true,
"שותכה": true,
"שותכו": true,
"שותכת": true,
"שותלת": true,
"שותפה": true,
"שותפו": true,
"שותפי": true,
"שותפך": true,
"שותפם": true,
"שותפן": true,
"שותפת": true,
"שותקה": true,
"שותקו": true,
"שותקת": true,
"שותתת": true,
"שזוהי": true,
"שזוכה": true,
"שזופה": true,
"שזורה": true,
"שזיפה": true,
"שזיפו": true,
"שזיפי": true,
"שזיפך": true,
"שזיפם": true,
"שזיפן": true,
"שזירה": true,
"שזכתה": true,
"שזפון": true,
"שזפנה": true,
"שזפנו": true,
"שזפתי": true,
"שזפתם": true,
"שזפתן": true,
"שזקוק": true,
"שזרנו": true,
"שזרתי": true,
"שזרתם": true,
"שזרתן": true,
"שחאדה": true,
"שחבור": true,
"שחברה": true,
"שחברו": true,
"שחברי": true,
"שחברת": true,
"שחדיה": true,
"שחדיו": true,
"שחדיי": true,
"שחדיך": true,
"שחדים": true,
"שחדנה": true,
"שחובה": true,
"שחווה": true,
"שחוזר": true,
"שחוחה": true,
"שחוטה": true,
"שחומה": true,
"שחונה": true,
"שחוקה": true,
"שחורה": true,
"שחושב": true,
"שחזור": true,
"שחזרה": true,
"שחזרו": true,
"שחזרי": true,
"שחזרת": true,
"שחטות": true,
"שחטנה": true,
"שחטנו": true,
"שחטתי": true,
"שחטתם": true,
"שחטתן": true,
"שחיבר": true,
"שחיות": true,
"שחיטה": true,
"שחייב": true,
"שחייה": true,
"שחייך": true,
"שחיים": true,
"שחיין": true,
"שחינה": true,
"שחינו": true,
"שחיקה": true,
"שחיתי": true,
"שחיתם": true,
"שחיתן": true,
"שחלוף": true,
"שחלות": true,
"שחליה": true,
"שחליו": true,
"שחליי": true,
"שחליך": true,
"שחלים": true,
"שחלכם": true,
"שחלכן": true,
"שחלנו": true,
"שחלפה": true,
"שחלפו": true,
"שחלפי": true,
"שחלפת": true,
"שחלקו": true,
"שחלקם": true,
"שחלקן": true,
"שחלתה": true,
"שחלתו": true,
"שחלתי": true,
"שחלתך": true,
"שחלתם": true,
"שחלתן": true,
"שחפות": true,
"שחפיה": true,
"שחפיו": true,
"שחפיי": true,
"שחפיך": true,
"שחפים": true,
"שחפכם": true,
"שחפכן": true,
"שחפנו": true,
"שחפתה": true,
"שחפתו": true,
"שחפתי": true,
"שחפתך": true,
"שחפתם": true,
"שחפתן": true,
"שחצני": true,
"שחקיה": true,
"שחקיו": true,
"שחקיי": true,
"שחקיך": true,
"שחקים": true,
"שחקכם": true,
"שחקכן": true,
"שחקנה": true,
"שחקנו": true,
"שחקני": true,
"שחקנך": true,
"שחקנם": true,
"שחקנן": true,
"שחקתי": true,
"שחקתם": true,
"שחקתן": true,
"שחרור": true,
"שחרות": true,
"שחרחר": true,
"שחריה": true,
"שחריו": true,
"שחריי": true,
"שחריך": true,
"שחרים": true,
"שחרית": true,
"שחרכם": true,
"שחרכן": true,
"שחרנה": true,
"שחרנו": true,
"שחררה": true,
"שחררו": true,
"שחררי": true,
"שחררת": true,
"שחשבו": true,
"שחשוב": true,
"שחתכם": true,
"שחתכן": true,
"שחתנה": true,
"שחתנו": true,
"שטאדה": true,
"שטואר": true,
"שטוחה": true,
"שטוען": true,
"שטופה": true,
"שטותה": true,
"שטותו": true,
"שטותי": true,
"שטותך": true,
"שטותם": true,
"שטותן": true,
"שטחיה": true,
"שטחיו": true,
"שטחיי": true,
"שטחיך": true,
"שטחים": true,
"שטחית": true,
"שטחכם": true,
"שטחכן": true,
"שטחנה": true,
"שטחנו": true,
"שטחתי": true,
"שטחתם": true,
"שטחתן": true,
"שטיון": true,
"שטיחה": true,
"שטיחו": true,
"שטיחי": true,
"שטיחך": true,
"שטיחם": true,
"שטיחן": true,
"שטיין": true,
"שטינה": true,
"שטיפה": true,
"שטיפת": true,
"שטמלר": true,
"שטמנו": true,
"שטמתי": true,
"שטמתם": true,
"שטמתן": true,
"שטנגר": true,
"שטניה": true,
"שטניו": true,
"שטניי": true,
"שטניך": true,
"שטנים": true,
"שטנית": true,
"שטנכם": true,
"שטנכן": true,
"שטננו": true,
"שטנתה": true,
"שטנתו": true,
"שטנתי": true,
"שטנתך": true,
"שטנתם": true,
"שטנתן": true,
"שטענה": true,
"שטענו": true,
"שטפון": true,
"שטפיה": true,
"שטפיו": true,
"שטפיי": true,
"שטפיך": true,
"שטפים": true,
"שטפכם": true,
"שטפכן": true,
"שטפנו": true,
"שטפתי": true,
"שטפתם": true,
"שטפתן": true,
"שטרות": true,
"שטריה": true,
"שטריו": true,
"שטריי": true,
"שטריך": true,
"שטרים": true,
"שטרית": true,
"שטרכם": true,
"שטרכן": true,
"שטרנו": true,
"שיאיה": true,
"שיאיו": true,
"שיאיי": true,
"שיאיך": true,
"שיאים": true,
"שיאכם": true,
"שיאכן": true,
"שיאמר": true,
"שיאנו": true,
"שיאצו": true,
"שיבוא": true,
"שיבוב": true,
"שיבוח": true,
"שיבוט": true,
"שיבוץ": true,
"שיבוש": true,
"שיבות": true,
"שיבחה": true,
"שיבחו": true,
"שיבחר": true,
"שיבחת": true,
"שיבטה": true,
"שיבטו": true,
"שיבטת": true,
"שיביא": true,
"שיביה": true,
"שיבצה": true,
"שיבצו": true,
"שיבצע": true,
"שיבצת": true,
"שיברה": true,
"שיברו": true,
"שיברת": true,
"שיבשה": true,
"שיבשו": true,
"שיבשת": true,
"שיבתה": true,
"שיבתו": true,
"שיבתי": true,
"שיבתך": true,
"שיבתם": true,
"שיבתן": true,
"שיגור": true,
"שיגיע": true,
"שיגעה": true,
"שיגעו": true,
"שיגעת": true,
"שיגרה": true,
"שיגרו": true,
"שיגרת": true,
"שידדה": true,
"שידדו": true,
"שידדת": true,
"שידוד": true,
"שידוך": true,
"שידול": true,
"שידוע": true,
"שידור": true,
"שידות": true,
"שידכה": true,
"שידכו": true,
"שידכת": true,
"שידלה": true,
"שידלו": true,
"שידלת": true,
"שידעה": true,
"שידעו": true,
"שידרה": true,
"שידרו": true,
"שידרת": true,
"שידתה": true,
"שידתו": true,
"שידתי": true,
"שידתך": true,
"שידתם": true,
"שידתן": true,
"שיהאב": true,
"שיהוי": true,
"שיהוק": true,
"שיהיה": true,
"שיהיו": true,
"שיהקה": true,
"שיהקו": true,
"שיהקת": true,
"שיודע": true,
"שיווה": true,
"שיווי": true,
"שיווע": true,
"שיווק": true,
"שיוטה": true,
"שיוטו": true,
"שיוטי": true,
"שיוטך": true,
"שיוטם": true,
"שיוטן": true,
"שיוכה": true,
"שיוכו": true,
"שיוכי": true,
"שיוכך": true,
"שיוכל": true,
"שיוכם": true,
"שיוכן": true,
"שיופה": true,
"שיופו": true,
"שיופי": true,
"שיופך": true,
"שיופם": true,
"שיופן": true,
"שיוצא": true,
"שיוצר": true,
"שיורה": true,
"שיורו": true,
"שיורי": true,
"שיורך": true,
"שיורם": true,
"שיורן": true,
"שיושב": true,
"שיותר": true,
"שיזוף": true,
"שיזכה": true,
"שיזפה": true,
"שיזפו": true,
"שיזפת": true,
"שיחדה": true,
"שיחדו": true,
"שיחדת": true,
"שיחוד": true,
"שיחול": true,
"שיחון": true,
"שיחור": true,
"שיחות": true,
"שיחיה": true,
"שיחיו": true,
"שיחיי": true,
"שיחיך": true,
"שיחים": true,
"שיחכם": true,
"שיחכן": true,
"שיחנה": true,
"שיחנו": true,
"שיחקה": true,
"שיחקו": true,
"שיחקת": true,
"שיחרה": true,
"שיחרו": true,
"שיחרת": true,
"שיחתה": true,
"שיחתו": true,
"שיחתי": true,
"שיחתך": true,
"שיחתם": true,
"שיחתן": true,
"שיטוח": true,
"שיטוט": true,
"שיטוי": true,
"שיטור": true,
"שיטות": true,
"שיטחה": true,
"שיטחו": true,
"שיטחת": true,
"שיטיה": true,
"שיטיו": true,
"שיטיי": true,
"שיטיך": true,
"שיטים": true,
"שיטין": true,
"שיטית": true,
"שיטכם": true,
"שיטכן": true,
"שיטלו": true,
"שיטנו": true,
"שיטתה": true,
"שיטתו": true,
"שיטתי": true,
"שיטתך": true,
"שיטתם": true,
"שיטתן": true,
"שיידע": true,
"שייהו": true,
"שייהם": true,
"שייהן": true,
"שייטה": true,
"שייטו": true,
"שייטי": true,
"שייטך": true,
"שייטם": true,
"שייטן": true,
"שייטת": true,
"שייכה": true,
"שייכו": true,
"שייכי": true,
"שייכם": true,
"שייכן": true,
"שייכת": true,
"שיינו": true,
"שייפה": true,
"שייפו": true,
"שייפי": true,
"שייפת": true,
"שייצא": true,
"שייצג": true,
"שייקה": true,
"שייקח": true,
"שיירה": true,
"שיירו": true,
"שיירי": true,
"שיירך": true,
"שיירם": true,
"שיירן": true,
"שייתה": true,
"שייתו": true,
"שייתי": true,
"שייתך": true,
"שייתם": true,
"שייתן": true,
"שיכוך": true,
"שיכול": true,
"שיכון": true,
"שיכור": true,
"שיככה": true,
"שיככו": true,
"שיככת": true,
"שיכלה": true,
"שיכלו": true,
"שיכלת": true,
"שיכנה": true,
"שיכנו": true,
"שיכנת": true,
"שילבה": true,
"שילבו": true,
"שילבת": true,
"שילדה": true,
"שילוב": true,
"שילוח": true,
"שילוט": true,
"שילום": true,
"שילון": true,
"שילוש": true,
"שילחה": true,
"שילחו": true,
"שילחת": true,
"שילטה": true,
"שילטו": true,
"שילטת": true,
"שילמד": true,
"שילמה": true,
"שילמו": true,
"שילמת": true,
"שילשה": true,
"שילשו": true,
"שילשי": true,
"שילשך": true,
"שילשם": true,
"שילשן": true,
"שילשת": true,
"שימום": true,
"שימון": true,
"שימוע": true,
"שימור": true,
"שימוש": true,
"שימות": true,
"שימחה": true,
"שימחו": true,
"שימחת": true,
"שיממה": true,
"שיממו": true,
"שיממת": true,
"שימנה": true,
"שימנו": true,
"שימנע": true,
"שימנת": true,
"שימרה": true,
"שימרו": true,
"שימרת": true,
"שימשה": true,
"שימשו": true,
"שימשת": true,
"שימתה": true,
"שימתו": true,
"שימתי": true,
"שימתך": true,
"שימתם": true,
"שימתן": true,
"שינוי": true,
"שינון": true,
"שינוס": true,
"שינוע": true,
"שינטו": true,
"שיניה": true,
"שיניו": true,
"שיניי": true,
"שיניך": true,
"שינית": true,
"שינכם": true,
"שינכן": true,
"שיננה": true,
"שיננו": true,
"שינני": true,
"שיננך": true,
"שיננם": true,
"שיננן": true,
"שיננת": true,
"שינסה": true,
"שינסו": true,
"שינסת": true,
"שינעה": true,
"שינעו": true,
"שינעת": true,
"שינתה": true,
"שיסוי": true,
"שיסוע": true,
"שיסוף": true,
"שיסית": true,
"שיסעה": true,
"שיסעו": true,
"שיסעת": true,
"שיספה": true,
"שיספו": true,
"שיספק": true,
"שיספת": true,
"שיסתה": true,
"שיעול": true,
"שיעור": true,
"שיעים": true,
"שיעית": true,
"שיעלה": true,
"שיעלו": true,
"שיערה": true,
"שיערו": true,
"שיערת": true,
"שיעשה": true,
"שיעשו": true,
"שיעשע": true,
"שיפדה": true,
"שיפדו": true,
"שיפדת": true,
"שיפוד": true,
"שיפוט": true,
"שיפוי": true,
"שיפון": true,
"שיפוע": true,
"שיפוץ": true,
"שיפור": true,
"שיפית": true,
"שיפעה": true,
"שיפעו": true,
"שיפעת": true,
"שיפצה": true,
"שיפצו": true,
"שיפצת": true,
"שיפרה": true,
"שיפרו": true,
"שיפרת": true,
"שיפתה": true,
"שיפתח": true,
"שיצאה": true,
"שיצאו": true,
"שיצרה": true,
"שיצרו": true,
"שיקבל": true,
"שיקבע": true,
"שיקגו": true,
"שיקוי": true,
"שיקול": true,
"שיקום": true,
"שיקוע": true,
"שיקוף": true,
"שיקוץ": true,
"שיקים": true,
"שיקמה": true,
"שיקמו": true,
"שיקמת": true,
"שיקעה": true,
"שיקעו": true,
"שיקעת": true,
"שיקפה": true,
"שיקפו": true,
"שיקפת": true,
"שיקצה": true,
"שיקצו": true,
"שיקצת": true,
"שיקרא": true,
"שיקרה": true,
"שיקרו": true,
"שיקרת": true,
"שיראה": true,
"שיראו": true,
"שיראז": true,
"שיראק": true,
"שירדו": true,
"שירון": true,
"שירוש": true,
"שירות": true,
"שיריה": true,
"שיריו": true,
"שיריי": true,
"שיריך": true,
"שירים": true,
"שירית": true,
"שירכם": true,
"שירכן": true,
"שירלי": true,
"שירנה": true,
"שירנו": true,
"שירצה": true,
"שירצו": true,
"שירשה": true,
"שירשו": true,
"שירשת": true,
"שירתה": true,
"שירתו": true,
"שירתי": true,
"שירתך": true,
"שירתם": true,
"שירתן": true,
"שישבו": true,
"שישות": true,
"שישיה": true,
"שישים": true,
"שישית": true,
"שישכם": true,
"שישכן": true,
"שישמש": true,
"שישנה": true,
"שישנו": true,
"שישנם": true,
"שישנן": true,
"שישתה": true,
"שישתו": true,
"שישתי": true,
"שישתך": true,
"שישתם": true,
"שישתן": true,
"שיתוך": true,
"שיתוף": true,
"שיתוק": true,
"שיתכה": true,
"שיתכו": true,
"שיתכן": true,
"שיתכת": true,
"שיתנו": true,
"שיתפה": true,
"שיתפו": true,
"שיתפת": true,
"שיתקה": true,
"שיתקו": true,
"שיתקת": true,
"שכאלה": true,
"שכאלו": true,
"שכאשר": true,
"שכבות": true,
"שכבנה": true,
"שכבנו": true,
"שכבתה": true,
"שכבתו": true,
"שכבתי": true,
"שכבתך": true,
"שכבתם": true,
"שכבתן": true,
"שכדאי": true,
"שכובה": true,
"שכווי": true,
"שכוחה": true,
"שכולה": true,
"שכולו": true,
"שכולל": true,
"שכולם": true,
"שכולן": true,
"שכונה": true,
"שכונת": true,
"שכורה": true,
"שכותב": true,
"שכזאת": true,
"שכחות": true,
"שכחנה": true,
"שכחנו": true,
"שכחתה": true,
"שכחתו": true,
"שכחתי": true,
"שכחתך": true,
"שכחתם": true,
"שכחתן": true,
"שכיבה": true,
"שכיהן": true,
"שכיום": true,
"שכיות": true,
"שכיחה": true,
"שכייה": true,
"שכיכה": true,
"שכינה": true,
"שכירה": true,
"שכירו": true,
"שכירי": true,
"שכירך": true,
"שכירם": true,
"שכירן": true,
"שככנה": true,
"שככנו": true,
"שככתי": true,
"שככתם": true,
"שככתן": true,
"שכלול": true,
"שכלית": true,
"שכלכם": true,
"שכלכן": true,
"שכללה": true,
"שכללו": true,
"שכללי": true,
"שכללת": true,
"שכלנה": true,
"שכלנו": true,
"שכלתי": true,
"שכלתם": true,
"שכלתן": true,
"שכמות": true,
"שכמיה": true,
"שכמכם": true,
"שכמכן": true,
"שכמנו": true,
"שכמעט": true,
"שכנגד": true,
"שכנוע": true,
"שכנות": true,
"שכניה": true,
"שכניו": true,
"שכניי": true,
"שכניך": true,
"שכנים": true,
"שכנכם": true,
"שכנכן": true,
"שכננו": true,
"שכנעה": true,
"שכנעו": true,
"שכנעי": true,
"שכנעת": true,
"שכנתה": true,
"שכנתו": true,
"שכנתי": true,
"שכנתך": true,
"שכנתם": true,
"שכנתן": true,
"שכפאם": true,
"שכפול": true,
"שכפלה": true,
"שכפלו": true,
"שכפלי": true,
"שכפלת": true,
"שכרגע": true,
"שכרון": true,
"שכרות": true,
"שכרכם": true,
"שכרכן": true,
"שכרנו": true,
"שכרתי": true,
"שכרתם": true,
"שכרתן": true,
"שכשוך": true,
"שכשכה": true,
"שכשכו": true,
"שכשכי": true,
"שכשכת": true,
"שכתבה": true,
"שכתבו": true,
"שכתבי": true,
"שכתבת": true,
"שכתוב": true,
"שלאור": true,
"שלאחר": true,
"שלביה": true,
"שלביו": true,
"שלביי": true,
"שלביך": true,
"שלבים": true,
"שלבכם": true,
"שלבכן": true,
"שלבנה": true,
"שלבנו": true,
"שלבקת": true,
"שלגון": true,
"שלגיה": true,
"שלגיו": true,
"שלגיי": true,
"שלגיך": true,
"שלגים": true,
"שלגכם": true,
"שלגכן": true,
"שלגנו": true,
"שלדגה": true,
"שלדגו": true,
"שלדגי": true,
"שלדגך": true,
"שלדגם": true,
"שלדגן": true,
"שלדות": true,
"שלדיה": true,
"שלדיו": true,
"שלדיי": true,
"שלדיך": true,
"שלדים": true,
"שלדית": true,
"שלדכם": true,
"שלדכן": true,
"שלדנו": true,
"שלדעת": true,
"שלדתה": true,
"שלדתו": true,
"שלדתי": true,
"שלדתך": true,
"שלדתם": true,
"שלדתן": true,
"שלהבה": true,
"שלהבו": true,
"שלהבי": true,
"שלהבת": true,
"שלהוב": true,
"שלהלן": true,
"שלובה": true,
"שלווה": true,
"שלווי": true,
"שלווך": true,
"שלוום": true,
"שלוון": true,
"שלוות": true,
"שלוחה": true,
"שלוחו": true,
"שלוחי": true,
"שלוחך": true,
"שלוחם": true,
"שלוחן": true,
"שלוחת": true,
"שלומד": true,
"שלומה": true,
"שלומו": true,
"שלומי": true,
"שלומך": true,
"שלומם": true,
"שלומן": true,
"שלופה": true,
"שלוקה": true,
"שלוקח": true,
"שלושה": true,
"שלושת": true,
"שלחין": true,
"שלחנה": true,
"שלחנו": true,
"שלחתי": true,
"שלחתם": true,
"שלחתן": true,
"שלטוט": true,
"שלטון": true,
"שלטטה": true,
"שלטטו": true,
"שלטטי": true,
"שלטטת": true,
"שלטיה": true,
"שלטיו": true,
"שלטיי": true,
"שלטיך": true,
"שלטים": true,
"שלטכם": true,
"שלטכן": true,
"שלטנה": true,
"שלטנו": true,
"שלטתי": true,
"שלטתם": true,
"שלטתן": true,
"שליוו": true,
"שליות": true,
"שליחה": true,
"שליחו": true,
"שליחי": true,
"שליחך": true,
"שליחם": true,
"שליחן": true,
"שליחת": true,
"שליטא": true,
"שליטה": true,
"שליטו": true,
"שליטי": true,
"שליטך": true,
"שליטם": true,
"שליטן": true,
"שליטת": true,
"שלייה": true,
"שלילה": true,
"שלילי": true,
"שלילת": true,
"שלימד": true,
"שלימה": true,
"שלינה": true,
"שלינו": true,
"שלינץ": true,
"שליסל": true,
"שליפה": true,
"שליקה": true,
"שלישה": true,
"שלישו": true,
"שלישי": true,
"שלישך": true,
"שלישם": true,
"שלישן": true,
"שליתי": true,
"שליתם": true,
"שליתן": true,
"שלכות": true,
"שלכתה": true,
"שלכתו": true,
"שלכתי": true,
"שלכתך": true,
"שלכתם": true,
"שלכתן": true,
"שללכם": true,
"שללכן": true,
"שללנו": true,
"שללתי": true,
"שללתם": true,
"שללתן": true,
"שלמדו": true,
"שלמון": true,
"שלמות": true,
"שלמיה": true,
"שלמיו": true,
"שלמיי": true,
"שלמיך": true,
"שלמים": true,
"שלמנה": true,
"שלנות": true,
"שלעיל": true,
"שלפיה": true,
"שלפיו": true,
"שלפיי": true,
"שלפיך": true,
"שלפים": true,
"שלפכם": true,
"שלפכן": true,
"שלפנו": true,
"שלפני": true,
"שלפתי": true,
"שלפתם": true,
"שלפתן": true,
"שלקחו": true,
"שלקנו": true,
"שלקתי": true,
"שלקתם": true,
"שלקתן": true,
"שלרוב": true,
"שלשול": true,
"שלשום": true,
"שלשות": true,
"שלשים": true,
"שלשלה": true,
"שלשלו": true,
"שלשלי": true,
"שלשלת": true,
"שלשמה": true,
"שלשנה": true,
"שלשתה": true,
"שלשתו": true,
"שלשתי": true,
"שלשתך": true,
"שלשתם": true,
"שלשתן": true,
"שמאוד": true,
"שמאות": true,
"שמאיה": true,
"שמאיו": true,
"שמאיי": true,
"שמאיך": true,
"שמאים": true,
"שמאית": true,
"שמאכם": true,
"שמאכן": true,
"שמאלה": true,
"שמאלו": true,
"שמאלי": true,
"שמאלך": true,
"שמאלם": true,
"שמאלן": true,
"שמאנו": true,
"שמביא": true,
"שמבין": true,
"שמבצע": true,
"שמבקש": true,
"שמגיע": true,
"שמדבר": true,
"שמדחת": true,
"שמואל": true,
"שמוטה": true,
"שמוכן": true,
"שמוכר": true,
"שמונה": true,
"שמונע": true,
"שמונת": true,
"שמועה": true,
"שמוצא": true,
"שמוצג": true,
"שמורה": true,
"שמורת": true,
"שמושך": true,
"שמותר": true,
"שמחבר": true,
"שמחון": true,
"שמחוץ": true,
"שמחות": true,
"שמחים": true,
"שמחיר": true,
"שמחכה": true,
"שמחנה": true,
"שמחנו": true,
"שמחתה": true,
"שמחתו": true,
"שמחתי": true,
"שמחתך": true,
"שמחתם": true,
"שמחתן": true,
"שמטנו": true,
"שמטתי": true,
"שמטתם": true,
"שמטתן": true,
"שמיהם": true,
"שמיהן": true,
"שמיוע": true,
"שמיות": true,
"שמיטה": true,
"שמייך": true,
"שמיים": true,
"שמייצ": true,
"שמיכה": true,
"שמיכם": true,
"שמיכן": true,
"שמילא": true,
"שמימי": true,
"שמינו": true,
"שמיני": true,
"שמיעה": true,
"שמיעת": true,
"שמירה": true,
"שמירו": true,
"שמירי": true,
"שמירך": true,
"שמירם": true,
"שמירן": true,
"שמירת": true,
"שמישה": true,
"שמכיל": true,
"שמכיר": true,
"שמלות": true,
"שמלצי": true,
"שמלתה": true,
"שמלתו": true,
"שמלתי": true,
"שמלתך": true,
"שמלתם": true,
"שמלתן": true,
"שממון": true,
"שממות": true,
"שממית": true,
"שמממה": true,
"שממנה": true,
"שממנו": true,
"שממתה": true,
"שממתו": true,
"שממתי": true,
"שממתך": true,
"שממתם": true,
"שממתן": true,
"שמנהל": true,
"שמנות": true,
"שמניה": true,
"שמניו": true,
"שמניי": true,
"שמניך": true,
"שמנים": true,
"שמניע": true,
"שמנית": true,
"שמנכם": true,
"שמנכן": true,
"שמנמן": true,
"שמננו": true,
"שמנסה": true,
"שמנתי": true,
"שמנתם": true,
"שמנתן": true,
"שמספק": true,
"שמספר": true,
"שמעבר": true,
"שמעון": true,
"שמעיד": true,
"שמעכם": true,
"שמעכן": true,
"שמעלה": true,
"שמענה": true,
"שמענו": true,
"שמעתי": true,
"שמעתם": true,
"שמעתן": true,
"שמפרי": true,
"שמצאה": true,
"שמצאו": true,
"שמצוי": true,
"שמציג": true,
"שמציה": true,
"שמציו": true,
"שמציי": true,
"שמציך": true,
"שמצים": true,
"שמציע": true,
"שמצכם": true,
"שמצכן": true,
"שמצנו": true,
"שמקבל": true,
"שמקום": true,
"שמקור": true,
"שמקנה": true,
"שמראה": true,
"שמרון": true,
"שמרטף": true,
"שמריה": true,
"שמריו": true,
"שמריי": true,
"שמריך": true,
"שמרים": true,
"שמרכז": true,
"שמרנה": true,
"שמרנו": true,
"שמרני": true,
"שמרתי": true,
"שמרתם": true,
"שמרתן": true,
"שמשהו": true,
"שמשון": true,
"שמשות": true,
"שמשחק": true,
"שמשיה": true,
"שמשיו": true,
"שמשיי": true,
"שמשיך": true,
"שמשים": true,
"שמשית": true,
"שמשכם": true,
"שמשכן": true,
"שמשלב": true,
"שמשמש": true,
"שמשנה": true,
"שמשנו": true,
"שמשרד": true,
"שמשרת": true,
"שמשתה": true,
"שמשתו": true,
"שמשתי": true,
"שמשתך": true,
"שמשתם": true,
"שמשתן": true,
"שמתאר": true,
"שמתוך": true,
"שמתחת": true,
"שמתקי": true,
"שמתרח": true,
"שנאות": true,
"שנאיה": true,
"שנאיו": true,
"שנאיי": true,
"שנאיך": true,
"שנאים": true,
"שנאכם": true,
"שנאכן": true,
"שנאלץ": true,
"שנאמר": true,
"שנאנה": true,
"שנאנו": true,
"שנאסף": true,
"שנאתה": true,
"שנאתו": true,
"שנאתי": true,
"שנאתך": true,
"שנאתם": true,
"שנאתן": true,
"שנבחר": true,
"שנבנה": true,
"שנבנו": true,
"שנגזר": true,
"שנגיע": true,
"שנגמר": true,
"שנגרם": true,
"שנדמה": true,
"שנדרש": true,
"שנהבה": true,
"שנהבו": true,
"שנהבי": true,
"שנהבך": true,
"שנהבם": true,
"שנהבן": true,
"שנהגו": true,
"שנהוג": true,
"שנהיה": true,
"שנהנה": true,
"שנהרג": true,
"שנואה": true,
"שנובע": true,
"שנוגע": true,
"שנודע": true,
"שנוהג": true,
"שנויה": true,
"שנוכל": true,
"שנולד": true,
"שנונה": true,
"שנוסד": true,
"שנועד": true,
"שנוצר": true,
"שנורר": true,
"שנושא": true,
"שנותן": true,
"שנותר": true,
"שנזכה": true,
"שנחשב": true,
"שנחשף": true,
"שנחתם": true,
"שנטען": true,
"שניהל": true,
"שניהם": true,
"שניות": true,
"שנייה": true,
"שניים": true,
"שניכם": true,
"שנינה": true,
"שנינו": true,
"שניסה": true,
"שניסו": true,
"שניצל": true,
"שניתי": true,
"שניתם": true,
"שניתן": true,
"שנכון": true,
"שנכנס": true,
"שנכתב": true,
"שנלמד": true,
"שנמכר": true,
"שנמסר": true,
"שנמצא": true,
"שנמשך": true,
"שנסנה": true,
"שנעים": true,
"שנעלם": true,
"שנענה": true,
"שנעצר": true,
"שנערך": true,
"שנעשה": true,
"שנעשו": true,
"שנפגע": true,
"שנפטר": true,
"שנפלה": true,
"שנפלו": true,
"שנפסק": true,
"שנפצע": true,
"שנפתח": true,
"שנצבר": true,
"שנקבל": true,
"שנקבע": true,
"שנקרא": true,
"שנראה": true,
"שנראו": true,
"שנרכש": true,
"שנרצה": true,
"שנרצח": true,
"שנרשם": true,
"שנשאר": true,
"שנשים": true,
"שנשלח": true,
"שנשמע": true,
"שנתון": true,
"שנתות": true,
"שנתים": true,
"שנתית": true,
"שנתכם": true,
"שנתכן": true,
"שנתנה": true,
"שנתנו": true,
"שנתפס": true,
"שנתתי": true,
"שסביב": true,
"שסביר": true,
"שסבלו": true,
"שסובל": true,
"שסועה": true,
"שסיים": true,
"שסייע": true,
"שסינה": true,
"שסיפק": true,
"שסיפר": true,
"שסעיה": true,
"שסעיו": true,
"שסעיי": true,
"שסעיך": true,
"שסעים": true,
"שסעכם": true,
"שסעכן": true,
"שסענה": true,
"שסענו": true,
"שספנה": true,
"שסקיה": true,
"שסקיו": true,
"שסקיי": true,
"שסקיך": true,
"שסקים": true,
"שסקכם": true,
"שסקכן": true,
"שסקנו": true,
"שסתום": true,
"שעבדה": true,
"שעבדו": true,
"שעבדי": true,
"שעבדת": true,
"שעבוד": true,
"שעבור": true,
"שעברה": true,
"שעברו": true,
"שעדיף": true,
"שעובד": true,
"שעובר": true,
"שעווה": true,
"שעוזר": true,
"שעולה": true,
"שעומד": true,
"שעונה": true,
"שעונו": true,
"שעוני": true,
"שעונך": true,
"שעונם": true,
"שעונן": true,
"שעוסק": true,
"שעורה": true,
"שעורך": true,
"שעורר": true,
"שעושה": true,
"שעזאם": true,
"שעזבו": true,
"שעטות": true,
"שעטנה": true,
"שעטנו": true,
"שעטנז": true,
"שעטתה": true,
"שעטתו": true,
"שעטתי": true,
"שעטתך": true,
"שעטתם": true,
"שעטתן": true,
"שעינה": true,
"שעינו": true,
"שעיצב": true,
"שעיקר": true,
"שעירה": true,
"שעירו": true,
"שעירי": true,
"שעירך": true,
"שעירם": true,
"שעירן": true,
"שעיתי": true,
"שעיתם": true,
"שעיתן": true,
"שעלול": true,
"שעלות": true,
"שעליה": true,
"שעליו": true,
"שעליי": true,
"שעליך": true,
"שעלים": true,
"שעלכם": true,
"שעלכן": true,
"שעלנו": true,
"שעלתה": true,
"שעלתו": true,
"שעלתי": true,
"שעלתך": true,
"שעלתם": true,
"שעלתן": true,
"שעמדה": true,
"שעמדו": true,
"שעמום": true,
"שעמיה": true,
"שעמיו": true,
"שעמיי": true,
"שעמיך": true,
"שעמים": true,
"שעמכם": true,
"שעמכן": true,
"שעממה": true,
"שעממו": true,
"שעממי": true,
"שעממת": true,
"שעמנו": true,
"שענות": true,
"שעניה": true,
"שעניו": true,
"שעניי": true,
"שעניך": true,
"שענים": true,
"שענית": true,
"שענכם": true,
"שענכן": true,
"שעננו": true,
"שעסקה": true,
"שעסקו": true,
"שערוך": true,
"שערות": true,
"שעריה": true,
"שעריו": true,
"שעריי": true,
"שעריך": true,
"שערים": true,
"שערכה": true,
"שערכו": true,
"שערכי": true,
"שערכם": true,
"שערכן": true,
"שערכת": true,
"שערנה": true,
"שערנו": true,
"שערתה": true,
"שערתו": true,
"שערתי": true,
"שערתך": true,
"שערתם": true,
"שערתן": true,
"שעשגז": true,
"שעשוי": true,
"שעשוע": true,
"שעשית": true,
"שעשעה": true,
"שעשעו": true,
"שעשעי": true,
"שעשעת": true,
"שעשתה": true,
"שעתוק": true,
"שעתיד": true,
"שעתכם": true,
"שעתכן": true,
"שעתנו": true,
"שעתקה": true,
"שעתקו": true,
"שעתקי": true,
"שעתקת": true,
"שפדנה": true,
"שפוגע": true,
"שפוטה": true,
"שפויה": true,
"שפוכה": true,
"שפונה": true,
"שפועה": true,
"שפועל": true,
"שפופה": true,
"שפותה": true,
"שפותח": true,
"שפחות": true,
"שפחתה": true,
"שפחתו": true,
"שפחתי": true,
"שפחתך": true,
"שפחתם": true,
"שפחתן": true,
"שפטיה": true,
"שפטיו": true,
"שפטיי": true,
"שפטיך": true,
"שפטים": true,
"שפטנו": true,
"שפטתי": true,
"שפטתם": true,
"שפטתן": true,
"שפיגל": true,
"שפיות": true,
"שפיטה": true,
"שפיים": true,
"שפיכה": true,
"שפינה": true,
"שפינו": true,
"שפיעה": true,
"שפיצה": true,
"שפיצו": true,
"שפיצי": true,
"שפיצך": true,
"שפיצם": true,
"שפיצן": true,
"שפירא": true,
"שפירה": true,
"שפיתה": true,
"שפיתח": true,
"שפכות": true,
"שפכטל": true,
"שפכיה": true,
"שפכיו": true,
"שפכיי": true,
"שפכיך": true,
"שפכים": true,
"שפכין": true,
"שפככם": true,
"שפככן": true,
"שפכנו": true,
"שפכתה": true,
"שפכתו": true,
"שפכתי": true,
"שפכתך": true,
"שפכתם": true,
"שפכתן": true,
"שפלות": true,
"שפלים": true,
"שפלנו": true,
"שפלתה": true,
"שפלתו": true,
"שפלתי": true,
"שפלתך": true,
"שפלתם": true,
"שפלתן": true,
"שפמיה": true,
"שפמיו": true,
"שפמיי": true,
"שפמיך": true,
"שפמים": true,
"שפמכם": true,
"שפמכן": true,
"שפמנו": true,
"שפמפם": true,
"שפניה": true,
"שפניו": true,
"שפניי": true,
"שפניך": true,
"שפנים": true,
"שפנכם": true,
"שפנכן": true,
"שפננו": true,
"שפנפן": true,
"שפעול": true,
"שפעות": true,
"שפעיה": true,
"שפעיו": true,
"שפעיי": true,
"שפעיך": true,
"שפעים": true,
"שפעכם": true,
"שפעכן": true,
"שפעלה": true,
"שפעלו": true,
"שפעלי": true,
"שפעלת": true,
"שפענה": true,
"שפענו": true,
"שפעתה": true,
"שפעתו": true,
"שפעתי": true,
"שפעתך": true,
"שפעתם": true,
"שפעתן": true,
"שפצור": true,
"שפצנה": true,
"שפצרה": true,
"שפצרו": true,
"שפצרי": true,
"שפצרת": true,
"שפרנה": true,
"שפרסם": true,
"שפרעם": true,
"שפרצו": true,
"שפשוט": true,
"שפשוף": true,
"שפשפה": true,
"שפשפו": true,
"שפשפי": true,
"שפשפת": true,
"שפתון": true,
"שפתות": true,
"שפתחה": true,
"שפתחו": true,
"שפתיה": true,
"שפתיו": true,
"שפתיי": true,
"שפתיך": true,
"שפתכם": true,
"שפתכן": true,
"שפתנו": true,
"שצבעם": true,
"שצוין": true,
"שצולם": true,
"שצורף": true,
"שציין": true,
"שצילם": true,
"שצפוי": true,
"שצפיה": true,
"שצפיו": true,
"שצפיי": true,
"שצפיך": true,
"שצפים": true,
"שצפכם": true,
"שצפכן": true,
"שצפנו": true,
"שצפתי": true,
"שצפתם": true,
"שצפתן": true,
"שצריך": true,
"שקבעה": true,
"שקבעו": true,
"שקדיה": true,
"שקדיו": true,
"שקדיי": true,
"שקדיך": true,
"שקדים": true,
"שקדכם": true,
"שקדכן": true,
"שקדמה": true,
"שקדמו": true,
"שקדנו": true,
"שקדני": true,
"שקדתי": true,
"שקדתם": true,
"שקדתן": true,
"שקובע": true,
"שקודם": true,
"שקולה": true,
"שקועה": true,
"שקופה": true,
"שקורא": true,
"שקורה": true,
"שקטות": true,
"שקטים": true,
"שקטנו": true,
"שקטתי": true,
"שקטתם": true,
"שקטתן": true,
"שקיבל": true,
"שקידה": true,
"שקיהם": true,
"שקיהן": true,
"שקיות": true,
"שקייך": true,
"שקיים": true,
"שקיכם": true,
"שקיכן": true,
"שקילה": true,
"שקינו": true,
"שקיני": true,
"שקיעה": true,
"שקיעת": true,
"שקיקה": true,
"שקיקו": true,
"שקיקי": true,
"שקיקך": true,
"שקיקם": true,
"שקיקן": true,
"שקיתה": true,
"שקיתו": true,
"שקיתי": true,
"שקיתך": true,
"שקיתם": true,
"שקיתן": true,
"שקלאר": true,
"שקלול": true,
"שקלון": true,
"שקליה": true,
"שקליו": true,
"שקליי": true,
"שקליך": true,
"שקלים": true,
"שקלית": true,
"שקלכם": true,
"שקלכן": true,
"שקללה": true,
"שקללו": true,
"שקללי": true,
"שקללת": true,
"שקלנו": true,
"שקלתי": true,
"שקלתם": true,
"שקלתן": true,
"שקמיה": true,
"שקמיו": true,
"שקמיי": true,
"שקמיך": true,
"שקמים": true,
"שקמנה": true,
"שקמתה": true,
"שקמתו": true,
"שקמתי": true,
"שקמתך": true,
"שקמתם": true,
"שקמתן": true,
"שקנאה": true,
"שקנאו": true,
"שקנאי": true,
"שקנאך": true,
"שקנאם": true,
"שקנאן": true,
"שקעיה": true,
"שקעיו": true,
"שקעיי": true,
"שקעיך": true,
"שקעים": true,
"שקעכם": true,
"שקעכן": true,
"שקענה": true,
"שקענו": true,
"שקעתי": true,
"שקעתם": true,
"שקעתן": true,
"שקפיה": true,
"שקפיו": true,
"שקפיי": true,
"שקפיך": true,
"שקפים": true,
"שקפכם": true,
"שקפכן": true,
"שקפנה": true,
"שקפנו": true,
"שקצנה": true,
"שקקנו": true,
"שקקתי": true,
"שקקתם": true,
"שקקתן": true,
"שקראו": true,
"שקרוב": true,
"שקריה": true,
"שקריו": true,
"שקריי": true,
"שקריך": true,
"שקרים": true,
"שקרית": true,
"שקרכם": true,
"שקרכן": true,
"שקרנה": true,
"שקרנו": true,
"שקשוק": true,
"שקשור": true,
"שקשקה": true,
"שקשקו": true,
"שקשקי": true,
"שקשקת": true,
"שקתות": true,
"שראוי": true,
"שראות": true,
"שראית": true,
"שראלי": true,
"שראתה": true,
"שרבבה": true,
"שרבבו": true,
"שרבבי": true,
"שרבבת": true,
"שרבוב": true,
"שרבוט": true,
"שרבטה": true,
"שרבטו": true,
"שרבטי": true,
"שרבטת": true,
"שרביה": true,
"שרביו": true,
"שרביט": true,
"שרביי": true,
"שרביך": true,
"שרביל": true,
"שרבים": true,
"שרבית": true,
"שרבכם": true,
"שרבכן": true,
"שרבנו": true,
"שרברב": true,
"שרגאי": true,
"שרדות": true,
"שרדים": true,
"שרדנו": true,
"שרדתי": true,
"שרדתם": true,
"שרדתן": true,
"שרואה": true,
"שרובם": true,
"שרוול": true,
"שרוטה": true,
"שרויה": true,
"שרוכה": true,
"שרוכו": true,
"שרוכי": true,
"שרוכך": true,
"שרוכם": true,
"שרוכן": true,
"שרונה": true,
"שרועה": true,
"שרופה": true,
"שרוצה": true,
"שרותי": true,
"שרטוט": true,
"שרטון": true,
"שרטטה": true,
"שרטטו": true,
"שרטטי": true,
"שרטטת": true,
"שרטנו": true,
"שרטתי": true,
"שרטתם": true,
"שרטתן": true,
"שריאל": true,
"שריגה": true,
"שריגו": true,
"שריגי": true,
"שריגך": true,
"שריגם": true,
"שריגן": true,
"שרידה": true,
"שרידו": true,
"שרידי": true,
"שרידך": true,
"שרידם": true,
"שרידן": true,
"שריהם": true,
"שריהן": true,
"שריון": true,
"שריות": true,
"שריטה": true,
"שרייה": true,
"שרייך": true,
"שריין": true,
"שריכה": true,
"שריכם": true,
"שריכן": true,
"שרימפ": true,
"שרינה": true,
"שרינו": true,
"שריעה": true,
"שריפה": true,
"שריפת": true,
"שריצה": true,
"שריקה": true,
"שרירה": true,
"שרירו": true,
"שרירי": true,
"שרירך": true,
"שרירם": true,
"שרירן": true,
"שריתי": true,
"שריתם": true,
"שריתן": true,
"שרכיה": true,
"שרכיו": true,
"שרכיי": true,
"שרכיך": true,
"שרכים": true,
"שרככם": true,
"שרככן": true,
"שרכנו": true,
"שרכשה": true,
"שרכשו": true,
"שרכתי": true,
"שרכתם": true,
"שרכתן": true,
"שרלוק": true,
"שרלטן": true,
"שרעפה": true,
"שרעפו": true,
"שרעפי": true,
"שרעפך": true,
"שרעפם": true,
"שרעפן": true,
"שרפות": true,
"שרפיה": true,
"שרפיו": true,
"שרפיי": true,
"שרפיך": true,
"שרפים": true,
"שרפכם": true,
"שרפכן": true,
"שרפנו": true,
"שרפרף": true,
"שרפתה": true,
"שרפתו": true,
"שרפתי": true,
"שרפתך": true,
"שרפתם": true,
"שרפתן": true,
"שרציה": true,
"שרציו": true,
"שרציי": true,
"שרציך": true,
"שרצים": true,
"שרצכם": true,
"שרצכן": true,
"שרצנו": true,
"שרצתי": true,
"שרצתם": true,
"שרצתן": true,
"שרקיה": true,
"שרקנה": true,
"שרקנו": true,
"שרקני": true,
"שרקנך": true,
"שרקנם": true,
"שרקנן": true,
"שרקתי": true,
"שרקתם": true,
"שרקתן": true,
"שררות": true,
"שררנו": true,
"שררתה": true,
"שררתו": true,
"שררתי": true,
"שררתך": true,
"שררתם": true,
"שררתן": true,
"שרשור": true,
"שרשנה": true,
"שרשרה": true,
"שרשרו": true,
"שרשרי": true,
"שרשרת": true,
"שרתיה": true,
"שרתיו": true,
"שרתיי": true,
"שרתיך": true,
"שרתים": true,
"שרתכם": true,
"שרתכן": true,
"שרתנה": true,
"שרתנו": true,
"ששווה": true,
"ששולם": true,
"ששומר": true,
"ששונה": true,
"ששונו": true,
"ששוני": true,
"ששונך": true,
"ששונם": true,
"ששונן": true,
"ששיחק": true,
"ששייך": true,
"ששילם": true,
"ששימש": true,
"ששינה": true,
"ששירת": true,
"ששלחה": true,
"ששלחו": true,
"ששמעו": true,
"ששרדו": true,
"ששתכם": true,
"ששתכן": true,
"ששתנו": true,
"שתבוא": true,
"שתביא": true,
"שתגיע": true,
"שתדלן": true,
"שתדעו": true,
"שתהיה": true,
"שתהיו": true,
"שתואר": true,
"שתויה": true,
"שתוכל": true,
"שתולה": true,
"שתומה": true,
"שתומך": true,
"שתוקה": true,
"שתוקי": true,
"שתיאר": true,
"שתיהן": true,
"שתיות": true,
"שתייה": true,
"שתיים": true,
"שתיין": true,
"שתיית": true,
"שתיכן": true,
"שתילה": true,
"שתילו": true,
"שתילי": true,
"שתילך": true,
"שתילם": true,
"שתילן": true,
"שתילת": true,
"שתינה": true,
"שתינו": true,
"שתיקה": true,
"שתישא": true,
"שתיתה": true,
"שתיתי": true,
"שתיתם": true,
"שתיתן": true,
"שתכנה": true,
"שתכנן": true,
"שתלטן": true,
"שתליה": true,
"שתליו": true,
"שתליי": true,
"שתליך": true,
"שתלים": true,
"שתלכם": true,
"שתלכן": true,
"שתלנו": true,
"שתלתי": true,
"שתלתם": true,
"שתלתן": true,
"שתמיד": true,
"שתמשו": true,
"שתמשך": true,
"שתנכם": true,
"שתנכן": true,
"שתננו": true,
"שתעלה": true,
"שתעשה": true,
"שתעשו": true,
"שתפנה": true,
"שתקבל": true,
"שתקנה": true,
"שתקנו": true,
"שתקני": true,
"שתקתי": true,
"שתקתם": true,
"שתקתן": true,
"שתראו": true,
"שתרמו": true,
"שתרצה": true,
"שתרצו": true,
"שתתנו": true,
"שתתפו": true,
"תאבדו": true,
"תאבדי": true,
"תאבון": true,
"תאבות": true,
"תאבזר": true,
"תאבחן": true,
"תאבטח": true,
"תאבים": true,
"תאביס": true,
"תאביק": true,
"תאבלו": true,
"תאבלי": true,
"תאבנה": true,
"תאבנו": true,
"תאבני": true,
"תאבקו": true,
"תאבקי": true,
"תאגדה": true,
"תאגדו": true,
"תאגדי": true,
"תאגדת": true,
"תאגוד": true,
"תאגור": true,
"תאגיד": true,
"תאגמו": true,
"תאגמי": true,
"תאגפו": true,
"תאגפי": true,
"תאגרו": true,
"תאגרי": true,
"תאגרף": true,
"תאדים": true,
"תאדיר": true,
"תאהבו": true,
"תאהבי": true,
"תאהדו": true,
"תאהדי": true,
"תאהוד": true,
"תאהיב": true,
"תאהיל": true,
"תאואה": true,
"תאואו": true,
"תאואי": true,
"תאואך": true,
"תאואם": true,
"תאואן": true,
"תאובן": true,
"תאובק": true,
"תאוגד": true,
"תאוגף": true,
"תאודה": true,
"תאודו": true,
"תאודי": true,
"תאווה": true,
"תאוות": true,
"תאוזן": true,
"תאוחד": true,
"תאוחה": true,
"תאוחו": true,
"תאוחי": true,
"תאוחל": true,
"תאוחר": true,
"תאוטה": true,
"תאויד": true,
"תאוים": true,
"תאוין": true,
"תאויר": true,
"תאויש": true,
"תאוית": true,
"תאוכל": true,
"תאוכן": true,
"תאולף": true,
"תאולץ": true,
"תאומה": true,
"תאומו": true,
"תאומי": true,
"תאומך": true,
"תאומם": true,
"תאומן": true,
"תאומץ": true,
"תאומת": true,
"תאונה": true,
"תאונן": true,
"תאונת": true,
"תאופס": true,
"תאופר": true,
"תאוצה": true,
"תאוצו": true,
"תאוצי": true,
"תאורה": true,
"תאורן": true,
"תאורת": true,
"תאושר": true,
"תאושש": true,
"תאותר": true,
"תאותת": true,
"תאזוק": true,
"תאזור": true,
"תאזין": true,
"תאזכר": true,
"תאזלו": true,
"תאזלי": true,
"תאזנה": true,
"תאזנו": true,
"תאזני": true,
"תאזקו": true,
"תאזקי": true,
"תאזרו": true,
"תאזרח": true,
"תאזרי": true,
"תאחדו": true,
"תאחדי": true,
"תאחזו": true,
"תאחזי": true,
"תאחזר": true,
"תאחיד": true,
"תאחלו": true,
"תאחלי": true,
"תאחסן": true,
"תאחרו": true,
"תאחרי": true,
"תאטום": true,
"תאטמו": true,
"תאטמי": true,
"תאטנה": true,
"תאיהם": true,
"תאיהן": true,
"תאיות": true,
"תאיזם": true,
"תאייד": true,
"תאייך": true,
"תאיים": true,
"תאיין": true,
"תאייר": true,
"תאייש": true,
"תאיית": true,
"תאיכם": true,
"תאיכן": true,
"תאימה": true,
"תאינו": true,
"תאיסט": true,
"תאיצו": true,
"תאיצי": true,
"תאירו": true,
"תאירי": true,
"תאכוף": true,
"תאכזב": true,
"תאכיל": true,
"תאכלו": true,
"תאכלי": true,
"תאכלס": true,
"תאכנה": true,
"תאכנו": true,
"תאכני": true,
"תאכפו": true,
"תאכפי": true,
"תאלחש": true,
"תאלפו": true,
"תאלפי": true,
"תאלצו": true,
"תאלצי": true,
"תאלתר": true,
"תאמדו": true,
"תאמדי": true,
"תאמוד": true,
"תאמין": true,
"תאמיר": true,
"תאמלל": true,
"תאמנה": true,
"תאמנו": true,
"תאמני": true,
"תאמצו": true,
"תאמצי": true,
"תאמרו": true,
"תאמרי": true,
"תאמתו": true,
"תאמתי": true,
"תאמתם": true,
"תאמתן": true,
"תאנוס": true,
"תאניה": true,
"תאניו": true,
"תאניי": true,
"תאניך": true,
"תאנים": true,
"תאניש": true,
"תאנסו": true,
"תאנסי": true,
"תאנפף": true,
"תאנתה": true,
"תאנתו": true,
"תאנתי": true,
"תאנתך": true,
"תאנתם": true,
"תאנתן": true,
"תאסוף": true,
"תאסור": true,
"תאסלם": true,
"תאספו": true,
"תאספי": true,
"תאסרו": true,
"תאסרי": true,
"תאפוף": true,
"תאפיל": true,
"תאפיר": true,
"תאפנן": true,
"תאפסו": true,
"תאפסי": true,
"תאפפו": true,
"תאפפי": true,
"תאפרו": true,
"תאפרי": true,
"תאפשר": true,
"תאצור": true,
"תאציל": true,
"תאצנה": true,
"תאצרו": true,
"תאצרי": true,
"תארבו": true,
"תארבי": true,
"תארגו": true,
"תארגי": true,
"תארגן": true,
"תארוב": true,
"תארוג": true,
"תארוז": true,
"תארוך": true,
"תארזו": true,
"תארזי": true,
"תארחו": true,
"תארחי": true,
"תאריה": true,
"תאריו": true,
"תאריי": true,
"תאריך": true,
"תארים": true,
"תאריק": true,
"תארכב": true,
"תארכה": true,
"תארכו": true,
"תארכי": true,
"תארכת": true,
"תארנה": true,
"תארסו": true,
"תארסי": true,
"תאשים": true,
"תאשמו": true,
"תאשמי": true,
"תאשפז": true,
"תאשרו": true,
"תאשרי": true,
"תאשרר": true,
"תאששו": true,
"תאששי": true,
"תאתגר": true,
"תאתחל": true,
"תאתרו": true,
"תאתרי": true,
"תבאיש": true,
"תבאנה": true,
"תבאסו": true,
"תבאסי": true,
"תבארו": true,
"תבארי": true,
"תבאשו": true,
"תבאשי": true,
"תבגדו": true,
"תבגדי": true,
"תבגוד": true,
"תבגרו": true,
"תבגרי": true,
"תבדוק": true,
"תבדיל": true,
"תבדלו": true,
"תבדלי": true,
"תבדקו": true,
"תבדקי": true,
"תבדרו": true,
"תבדרי": true,
"תבהיל": true,
"תבהיק": true,
"תבהיר": true,
"תבהקו": true,
"תבהקי": true,
"תבואה": true,
"תבואו": true,
"תבואי": true,
"תבואס": true,
"תבואר": true,
"תבודד": true,
"תבודל": true,
"תבודר": true,
"תבוזה": true,
"תבוזו": true,
"תבוזי": true,
"תבוזר": true,
"תבוטא": true,
"תבוטח": true,
"תבוטל": true,
"תבויל": true,
"תבוים": true,
"תבויש": true,
"תבוית": true,
"תבונה": true,
"תבוני": true,
"תבוסה": true,
"תבוסם": true,
"תבוסס": true,
"תבועה": true,
"תבוער": true,
"תבוצע": true,
"תבוצר": true,
"תבוקע": true,
"תבוקר": true,
"תבוקש": true,
"תבורא": true,
"תבורו": true,
"תבורי": true,
"תבורך": true,
"תבורר": true,
"תבושו": true,
"תבושי": true,
"תבושל": true,
"תבושם": true,
"תבושר": true,
"תבושש": true,
"תבותק": true,
"תבותר": true,
"תבזבז": true,
"תבזוז": true,
"תבזוק": true,
"תבזזו": true,
"תבזזי": true,
"תבזיק": true,
"תבזקו": true,
"תבזקי": true,
"תבזרו": true,
"תבזרי": true,
"תבחיל": true,
"תבחין": true,
"תבחלו": true,
"תבחלי": true,
"תבחנה": true,
"תבחנו": true,
"תבחני": true,
"תבחרו": true,
"תבחרי": true,
"תבחשו": true,
"תבחשי": true,
"תבטאו": true,
"תבטאי": true,
"תבטוש": true,
"תבטחו": true,
"תבטחי": true,
"תבטיח": true,
"תבטלו": true,
"תבטלי": true,
"תבטנה": true,
"תבטשו": true,
"תבטשי": true,
"תביאו": true,
"תביאי": true,
"תביטו": true,
"תביטי": true,
"תבייל": true,
"תביים": true,
"תבייץ": true,
"תבייש": true,
"תביית": true,
"תביכו": true,
"תביכי": true,
"תבינו": true,
"תביני": true,
"תביסו": true,
"תביסי": true,
"תביעה": true,
"תביעו": true,
"תביעי": true,
"תביעת": true,
"תבכיר": true,
"תבכנה": true,
"תבכרו": true,
"תבכרי": true,
"תבלבל": true,
"תבלגן": true,
"תבלוט": true,
"תבלול": true,
"תבלום": true,
"תבלוס": true,
"תבלוש": true,
"תבלטו": true,
"תבלטי": true,
"תבליג": true,
"תבליח": true,
"תבליט": true,
"תבלין": true,
"תבליע": true,
"תבללו": true,
"תבללי": true,
"תבלמו": true,
"תבלמי": true,
"תבלנה": true,
"תבלסו": true,
"תבלסי": true,
"תבלעו": true,
"תבלעי": true,
"תבלפו": true,
"תבלפי": true,
"תבלשו": true,
"תבלשי": true,
"תבנאם": true,
"תבנית": true,
"תבנכם": true,
"תבנכן": true,
"תבננו": true,
"תבסמו": true,
"תבסמי": true,
"תבסנה": true,
"תבססו": true,
"תבססי": true,
"תבעבע": true,
"תבעטו": true,
"תבעטי": true,
"תבעיר": true,
"תבעית": true,
"תבעלו": true,
"תבעלי": true,
"תבענה": true,
"תבענו": true,
"תבערה": true,
"תבערו": true,
"תבערי": true,
"תבעתי": true,
"תבעתם": true,
"תבעתן": true,
"תבצבץ": true,
"תבצור": true,
"תבצעו": true,
"תבצעי": true,
"תבצרו": true,
"תבצרי": true,
"תבקבק": true,
"תבקיע": true,
"תבקעו": true,
"תבקעי": true,
"תבקרו": true,
"תבקרי": true,
"תבקשו": true,
"תבקשי": true,
"תבראו": true,
"תבראי": true,
"תברבר": true,
"תברחו": true,
"תברחי": true,
"תבריא": true,
"תבריג": true,
"תבריז": true,
"תבריח": true,
"תבריק": true,
"תבריש": true,
"תברכו": true,
"תברכי": true,
"תבררו": true,
"תבררי": true,
"תבשיל": true,
"תבשלו": true,
"תבשלי": true,
"תבשמו": true,
"תבשמי": true,
"תבשרו": true,
"תבשרי": true,
"תבתקו": true,
"תבתקי": true,
"תבתרו": true,
"תבתרי": true,
"תגאלו": true,
"תגאלי": true,
"תגאתי": true,
"תגבבו": true,
"תגבבי": true,
"תגבהו": true,
"תגבהי": true,
"תגבול": true,
"תגבור": true,
"תגביה": true,
"תגביל": true,
"תגביר": true,
"תגבלו": true,
"תגבלי": true,
"תגבנה": true,
"תגבנו": true,
"תגבני": true,
"תגבסו": true,
"תגבסי": true,
"תגברה": true,
"תגברו": true,
"תגברי": true,
"תגברת": true,
"תגבשו": true,
"תגבשי": true,
"תגדום": true,
"תגדיל": true,
"תגדיר": true,
"תגדיש": true,
"תגדלו": true,
"תגדלי": true,
"תגדמו": true,
"תגדמי": true,
"תגדנה": true,
"תגדעו": true,
"תגדעי": true,
"תגדפו": true,
"תגדפי": true,
"תגדרו": true,
"תגדרי": true,
"תגדשו": true,
"תגדשי": true,
"תגהנה": true,
"תגהצו": true,
"תגהצי": true,
"תגהקו": true,
"תגהקי": true,
"תגהרו": true,
"תגהרי": true,
"תגובב": true,
"תגובה": true,
"תגובו": true,
"תגובי": true,
"תגובן": true,
"תגובס": true,
"תגובש": true,
"תגובת": true,
"תגודל": true,
"תגודף": true,
"תגודר": true,
"תגוהץ": true,
"תגוון": true,
"תגווע": true,
"תגוזו": true,
"תגוזי": true,
"תגוחך": true,
"תגויס": true,
"תגויר": true,
"תגולח": true,
"תגולל": true,
"תגולם": true,
"תגולף": true,
"תגומד": true,
"תגומר": true,
"תגונה": true,
"תגונו": true,
"תגוני": true,
"תגונן": true,
"תגופר": true,
"תגורה": true,
"תגורו": true,
"תגורז": true,
"תגורי": true,
"תגורף": true,
"תגורר": true,
"תגורש": true,
"תגושר": true,
"תגזוז": true,
"תגזול": true,
"תגזום": true,
"תגזור": true,
"תגזזו": true,
"תגזזי": true,
"תגזים": true,
"תגזלו": true,
"תגזלי": true,
"תגזמו": true,
"תגזמי": true,
"תגזרו": true,
"תגזרי": true,
"תגחיך": true,
"תגחכו": true,
"תגחכי": true,
"תגחנה": true,
"תגחנו": true,
"תגחני": true,
"תגיבו": true,
"תגיבי": true,
"תגידו": true,
"תגידי": true,
"תגיהו": true,
"תגיהי": true,
"תגיהם": true,
"תגיהן": true,
"תגיות": true,
"תגיחו": true,
"תגיחי": true,
"תגייך": true,
"תגייס": true,
"תגייר": true,
"תגיכם": true,
"תגיכן": true,
"תגילו": true,
"תגינו": true,
"תגיעו": true,
"תגיעי": true,
"תגיפו": true,
"תגיפי": true,
"תגירו": true,
"תגירי": true,
"תגישו": true,
"תגישי": true,
"תגיתה": true,
"תגיתו": true,
"תגיתי": true,
"תגיתך": true,
"תגיתם": true,
"תגיתן": true,
"תגלגל": true,
"תגלול": true,
"תגלוש": true,
"תגלחו": true,
"תגלחי": true,
"תגלחת": true,
"תגליד": true,
"תגליף": true,
"תגליש": true,
"תגלית": true,
"תגללו": true,
"תגללי": true,
"תגלמו": true,
"תגלמי": true,
"תגלען": true,
"תגלפו": true,
"תגלפי": true,
"תגלשו": true,
"תגלשי": true,
"תגמגם": true,
"תגמדו": true,
"תגמדי": true,
"תגמול": true,
"תגמור": true,
"תגמיש": true,
"תגמלה": true,
"תגמלו": true,
"תגמלי": true,
"תגמלת": true,
"תגמעו": true,
"תגמעי": true,
"תגמרו": true,
"תגמרי": true,
"תגנבו": true,
"תגנבי": true,
"תגנוב": true,
"תגנוז": true,
"תגנזו": true,
"תגנזי": true,
"תגנחו": true,
"תגנחי": true,
"תגניב": true,
"תגננה": true,
"תגננו": true,
"תגנני": true,
"תגסוס": true,
"תגססו": true,
"תגססי": true,
"תגעגע": true,
"תגעיל": true,
"תגעיש": true,
"תגענה": true,
"תגערו": true,
"תגערי": true,
"תגעשו": true,
"תגעשי": true,
"תגפנה": true,
"תגפפו": true,
"תגפפי": true,
"תגפרו": true,
"תגפרי": true,
"תגרבו": true,
"תגרבי": true,
"תגרגר": true,
"תגרדו": true,
"תגרדי": true,
"תגרוב": true,
"תגרוד": true,
"תגרום": true,
"תגרוס": true,
"תגרוף": true,
"תגרור": true,
"תגרות": true,
"תגרזו": true,
"תגרזי": true,
"תגריה": true,
"תגריו": true,
"תגריי": true,
"תגריך": true,
"תגריל": true,
"תגרים": true,
"תגרית": true,
"תגרכם": true,
"תגרכן": true,
"תגרמו": true,
"תגרמי": true,
"תגרנה": true,
"תגרנו": true,
"תגרני": true,
"תגרנך": true,
"תגרנם": true,
"תגרנן": true,
"תגרסו": true,
"תגרסי": true,
"תגרעו": true,
"תגרעי": true,
"תגרען": true,
"תגרפו": true,
"תגרפי": true,
"תגררו": true,
"תגררי": true,
"תגרשו": true,
"תגרשי": true,
"תגרתה": true,
"תגרתו": true,
"תגרתי": true,
"תגרתך": true,
"תגרתם": true,
"תגרתן": true,
"תגשים": true,
"תגשנה": true,
"תגשרו": true,
"תגשרי": true,
"תגששו": true,
"תגששי": true,
"תדאבו": true,
"תדאבי": true,
"תדאגו": true,
"תדאגי": true,
"תדאיב": true,
"תדאיג": true,
"תדבבו": true,
"תדבבי": true,
"תדבגו": true,
"תדבגי": true,
"תדביק": true,
"תדביר": true,
"תדבקו": true,
"תדבקי": true,
"תדברו": true,
"תדברי": true,
"תדברר": true,
"תדגדג": true,
"תדגול": true,
"תדגום": true,
"תדגור": true,
"תדגים": true,
"תדגיר": true,
"תדגיש": true,
"תדגלו": true,
"תדגלי": true,
"תדגמו": true,
"תדגמי": true,
"תדגמן": true,
"תדגרו": true,
"תדגרי": true,
"תדהים": true,
"תדהיר": true,
"תדהמה": true,
"תדהרה": true,
"תדהרו": true,
"תדהרי": true,
"תדהרך": true,
"תדהרם": true,
"תדהרן": true,
"תדובב": true,
"תדובג": true,
"תדובר": true,
"תדוגו": true,
"תדוגי": true,
"תדווח": true,
"תדווש": true,
"תדויק": true,
"תדוכא": true,
"תדולג": true,
"תדולל": true,
"תדומה": true,
"תדומו": true,
"תדומי": true,
"תדומם": true,
"תדונה": true,
"תדונו": true,
"תדוני": true,
"תדופן": true,
"תדורג": true,
"תדורו": true,
"תדורי": true,
"תדושו": true,
"תדושי": true,
"תדושן": true,
"תדחוף": true,
"תדחיק": true,
"תדחנה": true,
"תדחסו": true,
"תדחסי": true,
"תדחפו": true,
"תדחפי": true,
"תדחקו": true,
"תדחקי": true,
"תדיחו": true,
"תדיחי": true,
"תדייק": true,
"תדיפו": true,
"תדיפי": true,
"תדירה": true,
"תדירו": true,
"תדירי": true,
"תדכאו": true,
"תדכאי": true,
"תדכדך": true,
"תדלגו": true,
"תדלגי": true,
"תדלדל": true,
"תדלוף": true,
"תדלוק": true,
"תדליף": true,
"תדליק": true,
"תדללו": true,
"תדללי": true,
"תדלפו": true,
"תדלפי": true,
"תדלקה": true,
"תדלקו": true,
"תדלקי": true,
"תדלקת": true,
"תדמום": true,
"תדמור": true,
"תדמים": true,
"תדמית": true,
"תדממו": true,
"תדממי": true,
"תדמעו": true,
"תדמעי": true,
"תדנגו": true,
"תדנגי": true,
"תדסקס": true,
"תדעכו": true,
"תדעכי": true,
"תדענה": true,
"תדפדף": true,
"תדפוק": true,
"תדפיס": true,
"תדפנה": true,
"תדפנו": true,
"תדפני": true,
"תדפקו": true,
"תדפקי": true,
"תדקדק": true,
"תדקור": true,
"תדקלם": true,
"תדקרו": true,
"תדקרי": true,
"תדרבן": true,
"תדרגו": true,
"תדרגי": true,
"תדרדר": true,
"תדרוך": true,
"תדרוס": true,
"תדרוש": true,
"תדריה": true,
"תדריו": true,
"תדריי": true,
"תדריך": true,
"תדרים": true,
"תדרכה": true,
"תדרכו": true,
"תדרכי": true,
"תדרכם": true,
"תדרכן": true,
"תדרכת": true,
"תדרנה": true,
"תדרנו": true,
"תדרסו": true,
"תדרסי": true,
"תדרשו": true,
"תדרשי": true,
"תדשדש": true,
"תדשנה": true,
"תדשנו": true,
"תדשני": true,
"תהבהב": true,
"תהגרו": true,
"תהגרי": true,
"תהדהד": true,
"תהדוף": true,
"תהדיה": true,
"תהדיר": true,
"תהדסו": true,
"תהדסי": true,
"תהדפו": true,
"תהדפי": true,
"תהדקו": true,
"תהדקי": true,
"תהדרו": true,
"תהדרי": true,
"תהודה": true,
"תהודק": true,
"תהודר": true,
"תהווה": true,
"תהווי": true,
"תהוון": true,
"תהולל": true,
"תהומה": true,
"תהומו": true,
"תהומי": true,
"תהומך": true,
"תהומם": true,
"תהומן": true,
"תהוסה": true,
"תהוסו": true,
"תהוסי": true,
"תהיות": true,
"תהייה": true,
"תהילה": true,
"תהינה": true,
"תהינו": true,
"תהיני": true,
"תהיתי": true,
"תהיתם": true,
"תהיתן": true,
"תהלום": true,
"תהליך": true,
"תהלים": true,
"תהלכו": true,
"תהלכי": true,
"תהללו": true,
"תהללי": true,
"תהלמו": true,
"תהלמי": true,
"תהלנה": true,
"תהמגן": true,
"תהמהם": true,
"תהממו": true,
"תהממי": true,
"תהמרו": true,
"תהמרי": true,
"תהנדס": true,
"תהנהן": true,
"תהססו": true,
"תהססי": true,
"תהפוך": true,
"תהפכו": true,
"תהפכי": true,
"תהפנט": true,
"תהרגו": true,
"תהרגי": true,
"תהרהר": true,
"תהרוג": true,
"תהרוס": true,
"תהרסו": true,
"תהרסי": true,
"תהתלו": true,
"תהתלי": true,
"תואבס": true,
"תואבק": true,
"תואגד": true,
"תואדר": true,
"תואחד": true,
"תואטו": true,
"תואטי": true,
"תואיל": true,
"תואכל": true,
"תואמה": true,
"תואמו": true,
"תואמר": true,
"תואמת": true,
"תואנה": true,
"תואפל": true,
"תואצו": true,
"תואצי": true,
"תואצל": true,
"תוארה": true,
"תוארו": true,
"תוארי": true,
"תוארך": true,
"תוארם": true,
"תוארן": true,
"תוארק": true,
"תוארת": true,
"תואשם": true,
"תובאו": true,
"תובאי": true,
"תובאש": true,
"תובדל": true,
"תובהל": true,
"תובהר": true,
"תובזק": true,
"תובחל": true,
"תובחן": true,
"תובטח": true,
"תוביל": true,
"תובכו": true,
"תובכי": true,
"תובלג": true,
"תובלה": true,
"תובלו": true,
"תובלט": true,
"תובלי": true,
"תובלע": true,
"תובלת": true,
"תובנה": true,
"תובנו": true,
"תובני": true,
"תובסו": true,
"תובסי": true,
"תובעו": true,
"תובעי": true,
"תובער": true,
"תובעת": true,
"תובקע": true,
"תוברג": true,
"תוברז": true,
"תוברח": true,
"תוברק": true,
"תוברר": true,
"תוברש": true,
"תוגבה": true,
"תוגבל": true,
"תוגבר": true,
"תוגדו": true,
"תוגדי": true,
"תוגדל": true,
"תוגדר": true,
"תוגדש": true,
"תוגהו": true,
"תוגהי": true,
"תוגות": true,
"תוגזם": true,
"תוגיע": true,
"תוגלה": true,
"תוגלו": true,
"תוגלי": true,
"תוגלש": true,
"תוגמל": true,
"תוגמש": true,
"תוגנב": true,
"תוגנה": true,
"תוגנו": true,
"תוגני": true,
"תוגעל": true,
"תוגפו": true,
"תוגפי": true,
"תוגרו": true,
"תוגרי": true,
"תוגרל": true,
"תוגשו": true,
"תוגשי": true,
"תוגשם": true,
"תוגתה": true,
"תוגתו": true,
"תוגתי": true,
"תוגתך": true,
"תוגתם": true,
"תוגתן": true,
"תודאג": true,
"תודבק": true,
"תודבר": true,
"תודגם": true,
"תודגר": true,
"תודגש": true,
"תודהר": true,
"תודות": true,
"תודחו": true,
"תודחי": true,
"תודחק": true,
"תודיע": true,
"תודלף": true,
"תודלק": true,
"תודמם": true,
"תודעה": true,
"תודעת": true,
"תודפס": true,
"תודרו": true,
"תודרי": true,
"תודרך": true,
"תודתה": true,
"תודתו": true,
"תודתי": true,
"תודתך": true,
"תודתם": true,
"תודתן": true,
"תוהדר": true,
"תוהות": true,
"תוהים": true,
"תוהמי": true,
"תוואה": true,
"תוואו": true,
"תוואי": true,
"תוואך": true,
"תוואם": true,
"תוואן": true,
"תוודא": true,
"תוודה": true,
"תוודו": true,
"תוודי": true,
"תוויה": true,
"תוויו": true,
"תוויי": true,
"תוויך": true,
"תווים": true,
"תווית": true,
"תווכה": true,
"תווכו": true,
"תווכי": true,
"תווכם": true,
"תווכן": true,
"תווכת": true,
"תוונו": true,
"תווסת": true,
"תוותר": true,
"תוזהב": true,
"תוזהר": true,
"תוזזה": true,
"תוזזו": true,
"תוזזי": true,
"תוזזת": true,
"תוזחו": true,
"תוזחי": true,
"תוזיל": true,
"תוזכר": true,
"תוזלו": true,
"תוזלי": true,
"תוזלף": true,
"תוזמו": true,
"תוזמי": true,
"תוזמן": true,
"תוזמר": true,
"תוזנה": true,
"תוזנו": true,
"תוזנח": true,
"תוזני": true,
"תוזנק": true,
"תוזעק": true,
"תוזקף": true,
"תוזרם": true,
"תוזרע": true,
"תוזרק": true,
"תוחבא": true,
"תוחבת": true,
"תוחדר": true,
"תוחזק": true,
"תוחזר": true,
"תוחחה": true,
"תוחחו": true,
"תוחחת": true,
"תוחטא": true,
"תוחיה": true,
"תוחיו": true,
"תוחיי": true,
"תוחיל": true,
"תוחכר": true,
"תוחלו": true,
"תוחלט": true,
"תוחלי": true,
"תוחלף": true,
"תוחלק": true,
"תוחלש": true,
"תוחלת": true,
"תוחמא": true,
"תוחמה": true,
"תוחמו": true,
"תוחמץ": true,
"תוחמר": true,
"תוחמת": true,
"תוחנה": true,
"תוחנו": true,
"תוחני": true,
"תוחנק": true,
"תוחסר": true,
"תוחצן": true,
"תוחקר": true,
"תוחרב": true,
"תוחרג": true,
"תוחרד": true,
"תוחרם": true,
"תוחרף": true,
"תוחרש": true,
"תוחשד": true,
"תוחשו": true,
"תוחשי": true,
"תוחשך": true,
"תוחתם": true,
"תוטבו": true,
"תוטבי": true,
"תוטבל": true,
"תוטבע": true,
"תוטחו": true,
"תוטחי": true,
"תוטלא": true,
"תוטלו": true,
"תוטלי": true,
"תוטמן": true,
"תוטמע": true,
"תוטסו": true,
"תוטסי": true,
"תוטעה": true,
"תוטעו": true,
"תוטעי": true,
"תוטעם": true,
"תוטען": true,
"תוטפו": true,
"תוטפי": true,
"תוטרד": true,
"תוטרח": true,
"תויגה": true,
"תויגו": true,
"תויגת": true,
"תויקה": true,
"תויקו": true,
"תויקת": true,
"תוירה": true,
"תוירו": true,
"תוירת": true,
"תוכבד": true,
"תוכהה": true,
"תוכהו": true,
"תוכהי": true,
"תוכון": true,
"תוכות": true,
"תוכחד": true,
"תוכחה": true,
"תוכחו": true,
"תוכחי": true,
"תוכחש": true,
"תוכחת": true,
"תוכיה": true,
"תוכיו": true,
"תוכיח": true,
"תוכיי": true,
"תוכיך": true,
"תוכים": true,
"תוכית": true,
"תוככי": true,
"תוככם": true,
"תוככן": true,
"תוכלב": true,
"תוכלו": true,
"תוכלי": true,
"תוכלל": true,
"תוכלם": true,
"תוכמן": true,
"תוכנה": true,
"תוכנו": true,
"תוכני": true,
"תוכנך": true,
"תוכנם": true,
"תוכנן": true,
"תוכנס": true,
"תוכנע": true,
"תוכנת": true,
"תוכסף": true,
"תוכעס": true,
"תוכפל": true,
"תוכפף": true,
"תוכפש": true,
"תוכרו": true,
"תוכרז": true,
"תוכרח": true,
"תוכרי": true,
"תוכרע": true,
"תוכרת": true,
"תוכשו": true,
"תוכשי": true,
"תוכשל": true,
"תוכשר": true,
"תוכתב": true,
"תוכתם": true,
"תוכתר": true,
"תולאם": true,
"תולבן": true,
"תולבש": true,
"תולדה": true,
"תולהב": true,
"תולהט": true,
"תולוו": true,
"תולות": true,
"תולחם": true,
"תולחן": true,
"תולחץ": true,
"תולטו": true,
"תולטי": true,
"תוליד": true,
"תוליך": true,
"תולים": true,
"תולכו": true,
"תולכי": true,
"תולנה": true,
"תולנו": true,
"תולני": true,
"תולעה": true,
"תולעו": true,
"תולעט": true,
"תולעי": true,
"תולעך": true,
"תולעם": true,
"תולען": true,
"תולעת": true,
"תולקה": true,
"תולקו": true,
"תולקי": true,
"תולשת": true,
"תולתל": true,
"תומאס": true,
"תומחז": true,
"תומחר": true,
"תומחש": true,
"תומטו": true,
"תומטי": true,
"תומטר": true,
"תומיה": true,
"תומיו": true,
"תומיי": true,
"תומיך": true,
"תומים": true,
"תומכה": true,
"תומכו": true,
"תומכי": true,
"תומכך": true,
"תומכם": true,
"תומכן": true,
"תומכת": true,
"תומלח": true,
"תומלט": true,
"תומלך": true,
"תומלל": true,
"תומלץ": true,
"תומנה": true,
"תומנו": true,
"תומסו": true,
"תומסי": true,
"תומעד": true,
"תומעט": true,
"תומצא": true,
"תומצת": true,
"תומרד": true,
"תומרו": true,
"תומרי": true,
"תומרן": true,
"תומרץ": true,
"תומרר": true,
"תומשל": true,
"תומתה": true,
"תומתו": true,
"תומתי": true,
"תומתך": true,
"תומתם": true,
"תומתן": true,
"תומתק": true,
"תונאו": true,
"תונאי": true,
"תונבט": true,
"תונהג": true,
"תונחה": true,
"תונחו": true,
"תונחי": true,
"תונחל": true,
"תונחת": true,
"תוניס": true,
"תונמך": true,
"תונסו": true,
"תונסי": true,
"תונעו": true,
"תונעי": true,
"תונעם": true,
"תונפו": true,
"תונפי": true,
"תונפק": true,
"תונפש": true,
"תונצח": true,
"תונקו": true,
"תונקי": true,
"תונשם": true,
"תוסבו": true,
"תוסבי": true,
"תוסבר": true,
"תוסגו": true,
"תוסגי": true,
"תוסגר": true,
"תוסדר": true,
"תוסוו": true,
"תוסחו": true,
"תוסחי": true,
"תוסטו": true,
"תוסטי": true,
"תוסיף": true,
"תוסכל": true,
"תוסכם": true,
"תוסלם": true,
"תוסלק": true,
"תוסמך": true,
"תוססת": true,
"תוסעו": true,
"תוסעי": true,
"תוסער": true,
"תוספג": true,
"תוספד": true,
"תוספה": true,
"תוספו": true,
"תוספי": true,
"תוספך": true,
"תוספם": true,
"תוספן": true,
"תוספת": true,
"תוסקו": true,
"תוסקי": true,
"תוסרו": true,
"תוסרט": true,
"תוסרי": true,
"תוסתו": true,
"תוסתי": true,
"תוסתר": true,
"תועבד": true,
"תועבה": true,
"תועבו": true,
"תועבי": true,
"תועבר": true,
"תועדה": true,
"תועדו": true,
"תועדי": true,
"תועדף": true,
"תועדת": true,
"תועות": true,
"תועזב": true,
"תועיד": true,
"תועיל": true,
"תועים": true,
"תועלה": true,
"תועלו": true,
"תועלי": true,
"תועלם": true,
"תועלת": true,
"תועמד": true,
"תועמו": true,
"תועמי": true,
"תועמס": true,
"תועמק": true,
"תוענק": true,
"תוענש": true,
"תועסק": true,
"תועפו": true,
"תועפי": true,
"תועצב": true,
"תועצם": true,
"תוערו": true,
"תוערי": true,
"תוערך": true,
"תוערם": true,
"תועשה": true,
"תועשו": true,
"תועשר": true,
"תועשת": true,
"תועתע": true,
"תועתק": true,
"תועתר": true,
"תופגו": true,
"תופגז": true,
"תופגי": true,
"תופגן": true,
"תופגש": true,
"תופחד": true,
"תופחו": true,
"תופחי": true,
"תופחת": true,
"תופטר": true,
"תופיה": true,
"תופיו": true,
"תופיי": true,
"תופיך": true,
"תופים": true,
"תופין": true,
"תופיע": true,
"תופיק": true,
"תופית": true,
"תופכם": true,
"תופכן": true,
"תופלג": true,
"תופלה": true,
"תופלו": true,
"תופלט": true,
"תופלי": true,
"תופלל": true,
"תופנה": true,
"תופנו": true,
"תופני": true,
"תופנם": true,
"תופסו": true,
"תופסי": true,
"תופסק": true,
"תופסת": true,
"תופעה": true,
"תופעל": true,
"תופעם": true,
"תופעת": true,
"תופפה": true,
"תופפו": true,
"תופפי": true,
"תופפת": true,
"תופצו": true,
"תופצי": true,
"תופצץ": true,
"תופקד": true,
"תופקו": true,
"תופקי": true,
"תופקע": true,
"תופקר": true,
"תופרד": true,
"תופרה": true,
"תופרו": true,
"תופרז": true,
"תופרח": true,
"תופרט": true,
"תופרי": true,
"תופרך": true,
"תופרע": true,
"תופרש": true,
"תופרת": true,
"תופשט": true,
"תופשל": true,
"תופשר": true,
"תופשת": true,
"תופתה": true,
"תופתו": true,
"תופתי": true,
"תופתך": true,
"תופתם": true,
"תופתן": true,
"תופתע": true,
"תוצאה": true,
"תוצאו": true,
"תוצאי": true,
"תוצאך": true,
"תוצאם": true,
"תוצאן": true,
"תוצאת": true,
"תוצבו": true,
"תוצבי": true,
"תוצבע": true,
"תוצגו": true,
"תוצגי": true,
"תוצדק": true,
"תוצהב": true,
"תוצהר": true,
"תוציא": true,
"תוצלב": true,
"תוצלו": true,
"תוצלי": true,
"תוצמד": true,
"תוצמת": true,
"תוצנח": true,
"תוצנע": true,
"תוצעד": true,
"תוצעו": true,
"תוצעי": true,
"תוצפו": true,
"תוצפי": true,
"תוצפן": true,
"תוצרה": true,
"תוצרו": true,
"תוצרי": true,
"תוצרך": true,
"תוצרם": true,
"תוצרן": true,
"תוצרת": true,
"תוצתו": true,
"תוצתי": true,
"תוקאו": true,
"תוקאי": true,
"תוקדח": true,
"תוקדם": true,
"תוקדש": true,
"תוקהה": true,
"תוקהו": true,
"תוקהי": true,
"תוקהל": true,
"תוקזו": true,
"תוקזי": true,
"תוקטן": true,
"תוקטר": true,
"תוקיע": true,
"תוקיר": true,
"תוקלד": true,
"תוקלה": true,
"תוקלו": true,
"תוקלט": true,
"תוקלי": true,
"תוקלת": true,
"תוקמו": true,
"תוקמי": true,
"תוקנה": true,
"תוקנו": true,
"תוקנט": true,
"תוקני": true,
"תוקנת": true,
"תוקסם": true,
"תוקעו": true,
"תוקעי": true,
"תוקעת": true,
"תוקפא": true,
"תוקפד": true,
"תוקפה": true,
"תוקפו": true,
"תוקפי": true,
"תוקפך": true,
"תוקפם": true,
"תוקפן": true,
"תוקפץ": true,
"תוקפת": true,
"תוקצב": true,
"תוקצה": true,
"תוקצו": true,
"תוקצי": true,
"תוקצן": true,
"תוקצע": true,
"תוקצף": true,
"תוקצר": true,
"תוקרא": true,
"תוקרב": true,
"תוקרם": true,
"תוקרן": true,
"תוקרש": true,
"תוקשה": true,
"תוקשו": true,
"תוקשח": true,
"תוקשי": true,
"תוקשר": true,
"תוראה": true,
"תוראו": true,
"תוראי": true,
"תורבע": true,
"תורבת": true,
"תורגל": true,
"תורגם": true,
"תורגש": true,
"תורדו": true,
"תורדי": true,
"תורדם": true,
"תורוו": true,
"תורות": true,
"תורחב": true,
"תורחק": true,
"תורטב": true,
"תוריד": true,
"תוריה": true,
"תוריו": true,
"תוריי": true,
"תוריך": true,
"תורים": true,
"תוריק": true,
"תוריש": true,
"תורכב": true,
"תורכי": true,
"תורכם": true,
"תורכן": true,
"תורמו": true,
"תורמי": true,
"תורמת": true,
"תורנה": true,
"תורנו": true,
"תורני": true,
"תורנך": true,
"תורנם": true,
"תורנן": true,
"תורעב": true,
"תורעד": true,
"תורעו": true,
"תורעי": true,
"תורעל": true,
"תורעף": true,
"תורעש": true,
"תורפה": true,
"תורצה": true,
"תורצו": true,
"תורצי": true,
"תורצת": true,
"תורקד": true,
"תורקו": true,
"תורקי": true,
"תורשה": true,
"תורשו": true,
"תורשי": true,
"תורשע": true,
"תורתה": true,
"תורתו": true,
"תורתח": true,
"תורתי": true,
"תורתך": true,
"תורתם": true,
"תורתן": true,
"תורתע": true,
"תושאו": true,
"תושאי": true,
"תושאל": true,
"תושאף": true,
"תושאר": true,
"תושבה": true,
"תושבו": true,
"תושבח": true,
"תושבי": true,
"תושבך": true,
"תושבם": true,
"תושבן": true,
"תושבע": true,
"תושבת": true,
"תושגו": true,
"תושגח": true,
"תושגי": true,
"תושהה": true,
"תושהו": true,
"תושהי": true,
"תושוו": true,
"תושחז": true,
"תושחל": true,
"תושחם": true,
"תושחר": true,
"תושחת": true,
"תושטו": true,
"תושטי": true,
"תושיב": true,
"תושיה": true,
"תושיט": true,
"תושיע": true,
"תושכב": true,
"תושכח": true,
"תושכם": true,
"תושכן": true,
"תושכר": true,
"תושלה": true,
"תושלו": true,
"תושלט": true,
"תושלי": true,
"תושלך": true,
"תושלם": true,
"תושמד": true,
"תושמו": true,
"תושמט": true,
"תושמי": true,
"תושמע": true,
"תושמץ": true,
"תושמש": true,
"תושעה": true,
"תושעו": true,
"תושעי": true,
"תושען": true,
"תושפל": true,
"תושפע": true,
"תושקה": true,
"תושקו": true,
"תושקט": true,
"תושקי": true,
"תושקע": true,
"תושרה": true,
"תושרו": true,
"תושרי": true,
"תושרץ": true,
"תושרש": true,
"תושתו": true,
"תושתי": true,
"תושתל": true,
"תושתן": true,
"תושתק": true,
"תושתת": true,
"תותאם": true,
"תותבה": true,
"תותבו": true,
"תותבי": true,
"תותבך": true,
"תותבם": true,
"תותבן": true,
"תותבת": true,
"תותוו": true,
"תותזו": true,
"תותזי": true,
"תותחה": true,
"תותחו": true,
"תותחי": true,
"תותחך": true,
"תותחל": true,
"תותחם": true,
"תותחן": true,
"תותיה": true,
"תותיו": true,
"תותיי": true,
"תותיך": true,
"תותים": true,
"תותיר": true,
"תותכו": true,
"תותכי": true,
"תותכם": true,
"תותכן": true,
"תותמר": true,
"תותנה": true,
"תותנו": true,
"תותני": true,
"תותנע": true,
"תותסס": true,
"תותפח": true,
"תותפל": true,
"תותקל": true,
"תותקן": true,
"תותקף": true,
"תותרה": true,
"תותרו": true,
"תותרי": true,
"תותשו": true,
"תותשי": true,
"תזבחו": true,
"תזבחי": true,
"תזבלו": true,
"תזבלי": true,
"תזגגו": true,
"תזגגי": true,
"תזגזג": true,
"תזדהה": true,
"תזדהו": true,
"תזדהי": true,
"תזדהם": true,
"תזדחל": true,
"תזדכה": true,
"תזדכו": true,
"תזדכי": true,
"תזדכך": true,
"תזדמן": true,
"תזדמר": true,
"תזדנב": true,
"תזדנה": true,
"תזדעף": true,
"תזדעק": true,
"תזדקן": true,
"תזדקף": true,
"תזדקק": true,
"תזדקר": true,
"תזדרז": true,
"תזהיב": true,
"תזהיר": true,
"תזהמו": true,
"תזהמי": true,
"תזהרו": true,
"תזהרי": true,
"תזובו": true,
"תזובי": true,
"תזובל": true,
"תזוגג": true,
"תזוהה": true,
"תזוהו": true,
"תזוהי": true,
"תזוהם": true,
"תזווג": true,
"תזווד": true,
"תזוזה": true,
"תזוזו": true,
"תזוזי": true,
"תזוין": true,
"תזויף": true,
"תזוכה": true,
"תזוכו": true,
"תזוכי": true,
"תזוכך": true,
"תזומן": true,
"תזומר": true,
"תזונה": true,
"תזועו": true,
"תזועי": true,
"תזופת": true,
"תזוקק": true,
"תזורז": true,
"תזזית": true,
"תזזנה": true,
"תזחלו": true,
"תזחלי": true,
"תזחנה": true,
"תזידו": true,
"תזידי": true,
"תזיזו": true,
"תזיזי": true,
"תזיחו": true,
"תזיחי": true,
"תזיין": true,
"תזייף": true,
"תזילו": true,
"תזילי": true,
"תזינו": true,
"תזיני": true,
"תזיעו": true,
"תזיעי": true,
"תזיקו": true,
"תזיקי": true,
"תזכור": true,
"תזכיר": true,
"תזככו": true,
"תזככי": true,
"תזכרו": true,
"תזכרי": true,
"תזלגו": true,
"תזלגי": true,
"תזלוג": true,
"תזלול": true,
"תזלזל": true,
"תזליף": true,
"תזללו": true,
"תזללי": true,
"תזלנה": true,
"תזמום": true,
"תזמון": true,
"תזמור": true,
"תזמזם": true,
"תזמין": true,
"תזממו": true,
"תזממי": true,
"תזמנה": true,
"תזמנו": true,
"תזמני": true,
"תזמנת": true,
"תזמרה": true,
"תזמרו": true,
"תזמרי": true,
"תזמרת": true,
"תזנבו": true,
"תזנבי": true,
"תזנחו": true,
"תזנחי": true,
"תזניח": true,
"תזניק": true,
"תזנקו": true,
"תזנקי": true,
"תזעזע": true,
"תזעים": true,
"תזעיף": true,
"תזעיק": true,
"תזעמו": true,
"תזעמי": true,
"תזענה": true,
"תזעפו": true,
"תזעפי": true,
"תזעקו": true,
"תזעקי": true,
"תזפתו": true,
"תזפתי": true,
"תזקוף": true,
"תזקור": true,
"תזקין": true,
"תזקיף": true,
"תזקיק": true,
"תזקנה": true,
"תזקנו": true,
"תזקני": true,
"תזקפו": true,
"תזקפי": true,
"תזקקו": true,
"תזקקי": true,
"תזקרו": true,
"תזקרי": true,
"תזרום": true,
"תזרוק": true,
"תזרזו": true,
"תזרזי": true,
"תזרחו": true,
"תזרחי": true,
"תזרים": true,
"תזריע": true,
"תזריק": true,
"תזרמו": true,
"תזרמי": true,
"תזרעו": true,
"תזרעי": true,
"תזרקו": true,
"תזרקי": true,
"תזתכם": true,
"תזתכן": true,
"תזתנו": true,
"תחבבו": true,
"תחבבי": true,
"תחבוט": true,
"תחבול": true,
"תחבוץ": true,
"תחבוק": true,
"תחבור": true,
"תחבוש": true,
"תחבטו": true,
"תחבטי": true,
"תחביא": true,
"תחביב": true,
"תחביר": true,
"תחבלה": true,
"תחבלו": true,
"תחבלי": true,
"תחבלן": true,
"תחבלת": true,
"תחבנה": true,
"תחבנו": true,
"תחבצו": true,
"תחבצי": true,
"תחבקו": true,
"תחבקי": true,
"תחברו": true,
"תחברי": true,
"תחבשו": true,
"תחבשי": true,
"תחבתי": true,
"תחבתם": true,
"תחבתן": true,
"תחגגו": true,
"תחגגי": true,
"תחגוג": true,
"תחגור": true,
"תחגרו": true,
"תחגרי": true,
"תחדדו": true,
"תחדדי": true,
"תחדור": true,
"תחדיר": true,
"תחדיש": true,
"תחדלו": true,
"תחדלי": true,
"תחדרו": true,
"תחדרי": true,
"תחדשו": true,
"תחדשי": true,
"תחובה": true,
"תחובו": true,
"תחובי": true,
"תחובל": true,
"תחובק": true,
"תחובר": true,
"תחוגו": true,
"תחוגי": true,
"תחודד": true,
"תחודו": true,
"תחודי": true,
"תחודש": true,
"תחווה": true,
"תחווט": true,
"תחווי": true,
"תחוזק": true,
"תחוזר": true,
"תחוחה": true,
"תחוטא": true,
"תחוטב": true,
"תחויב": true,
"תחויג": true,
"תחויט": true,
"תחויל": true,
"תחולה": true,
"תחולו": true,
"תחולט": true,
"תחולי": true,
"תחולל": true,
"תחולץ": true,
"תחולק": true,
"תחומה": true,
"תחומו": true,
"תחומי": true,
"תחומך": true,
"תחומם": true,
"תחומן": true,
"תחומש": true,
"תחונה": true,
"תחונו": true,
"תחוני": true,
"תחונך": true,
"תחונן": true,
"תחוסו": true,
"תחוסי": true,
"תחוסל": true,
"תחוסם": true,
"תחוסן": true,
"תחוסר": true,
"תחופף": true,
"תחופש": true,
"תחוקק": true,
"תחורר": true,
"תחושב": true,
"תחושה": true,
"תחושו": true,
"תחושי": true,
"תחושל": true,
"תחושק": true,
"תחושת": true,
"תחותל": true,
"תחותן": true,
"תחזוק": true,
"תחזור": true,
"תחזיק": true,
"תחזיר": true,
"תחזית": true,
"תחזקה": true,
"תחזקו": true,
"תחזקי": true,
"תחזקת": true,
"תחזרו": true,
"תחזרי": true,
"תחחנה": true,
"תחטאו": true,
"תחטאי": true,
"תחטבו": true,
"תחטבי": true,
"תחטוב": true,
"תחטוף": true,
"תחטטו": true,
"תחטטי": true,
"תחטיא": true,
"תחטיף": true,
"תחטפו": true,
"תחטפי": true,
"תחיבה": true,
"תחיות": true,
"תחייב": true,
"תחייג": true,
"תחייה": true,
"תחייט": true,
"תחייך": true,
"תחייל": true,
"תחיית": true,
"תחילה": true,
"תחילו": true,
"תחילי": true,
"תחילת": true,
"תחימה": true,
"תחינה": true,
"תחיקה": true,
"תחישו": true,
"תחישי": true,
"תחכוך": true,
"תחכום": true,
"תחכור": true,
"תחכים": true,
"תחכיר": true,
"תחככו": true,
"תחככי": true,
"תחכרו": true,
"תחכרי": true,
"תחלבו": true,
"תחלבי": true,
"תחלוב": true,
"תחלוט": true,
"תחלום": true,
"תחלוף": true,
"תחלוץ": true,
"תחלוק": true,
"תחלוש": true,
"תחלחל": true,
"תחלטו": true,
"תחלטי": true,
"תחליא": true,
"תחליב": true,
"תחליד": true,
"תחליט": true,
"תחלים": true,
"תחליף": true,
"תחליק": true,
"תחליש": true,
"תחללו": true,
"תחללי": true,
"תחלמו": true,
"תחלמי": true,
"תחלנה": true,
"תחלפו": true,
"תחלפי": true,
"תחלצו": true,
"תחלצי": true,
"תחלקו": true,
"תחלקי": true,
"תחלשו": true,
"תחלשי": true,
"תחמדו": true,
"תחמדי": true,
"תחמוד": true,
"תחמול": true,
"תחמון": true,
"תחמוס": true,
"תחמוק": true,
"תחמיא": true,
"תחמיץ": true,
"תחמיר": true,
"תחמיש": true,
"תחמלו": true,
"תחמלי": true,
"תחממו": true,
"תחממי": true,
"תחמנה": true,
"תחמנו": true,
"תחמני": true,
"תחמנת": true,
"תחמסו": true,
"תחמסי": true,
"תחמצן": true,
"תחמקו": true,
"תחמקי": true,
"תחמשו": true,
"תחמשי": true,
"תחמתי": true,
"תחמתם": true,
"תחמתן": true,
"תחנוט": true,
"תחנוך": true,
"תחנון": true,
"תחנוק": true,
"תחנות": true,
"תחנטו": true,
"תחנטי": true,
"תחניף": true,
"תחניק": true,
"תחנכו": true,
"תחנכי": true,
"תחננו": true,
"תחנני": true,
"תחנקו": true,
"תחנקי": true,
"תחנתה": true,
"תחנתו": true,
"תחנתי": true,
"תחנתך": true,
"תחנתם": true,
"תחנתן": true,
"תחסוך": true,
"תחסום": true,
"תחסיר": true,
"תחסכו": true,
"תחסכי": true,
"תחסלו": true,
"תחסלי": true,
"תחסמו": true,
"תחסמי": true,
"תחסנה": true,
"תחסנו": true,
"תחסני": true,
"תחספס": true,
"תחסרו": true,
"תחסרי": true,
"תחפון": true,
"תחפוף": true,
"תחפור": true,
"תחפנו": true,
"תחפני": true,
"תחפפו": true,
"תחפפי": true,
"תחפצו": true,
"תחפצי": true,
"תחפרו": true,
"תחפרי": true,
"תחפשו": true,
"תחפשי": true,
"תחצבו": true,
"תחצבי": true,
"תחצוב": true,
"תחצוץ": true,
"תחצין": true,
"תחציף": true,
"תחצנה": true,
"תחצצו": true,
"תחצצי": true,
"תחצצר": true,
"תחקוק": true,
"תחקור": true,
"תחקיר": true,
"תחקקו": true,
"תחקקי": true,
"תחקרה": true,
"תחקרו": true,
"תחקרי": true,
"תחקרת": true,
"תחרבו": true,
"תחרבי": true,
"תחרבן": true,
"תחרגו": true,
"תחרגי": true,
"תחרדו": true,
"תחרדי": true,
"תחרוב": true,
"תחרוג": true,
"תחרוז": true,
"תחרוט": true,
"תחרוך": true,
"תחרוף": true,
"תחרוץ": true,
"תחרוק": true,
"תחרוש": true,
"תחרות": true,
"תחרזו": true,
"תחרזי": true,
"תחרחר": true,
"תחרטו": true,
"תחרטי": true,
"תחריב": true,
"תחריג": true,
"תחריד": true,
"תחריט": true,
"תחרים": true,
"תחריף": true,
"תחריר": true,
"תחריש": true,
"תחרכו": true,
"תחרכי": true,
"תחרמן": true,
"תחרפו": true,
"תחרפי": true,
"תחרצו": true,
"תחרצי": true,
"תחרקו": true,
"תחרקי": true,
"תחרשו": true,
"תחרשי": true,
"תחרתה": true,
"תחרתו": true,
"תחרתי": true,
"תחרתך": true,
"תחרתם": true,
"תחרתן": true,
"תחשבו": true,
"תחשבי": true,
"תחשדו": true,
"תחשדי": true,
"תחשוב": true,
"תחשוד": true,
"תחשוף": true,
"תחשוק": true,
"תחשוש": true,
"תחשיב": true,
"תחשיד": true,
"תחשיה": true,
"תחשיו": true,
"תחשיי": true,
"תחשיך": true,
"תחשים": true,
"תחשכו": true,
"תחשכי": true,
"תחשכם": true,
"תחשכן": true,
"תחשלו": true,
"תחשלי": true,
"תחשמל": true,
"תחשנה": true,
"תחשנו": true,
"תחשפו": true,
"תחשפי": true,
"תחשקו": true,
"תחשקי": true,
"תחששו": true,
"תחששי": true,
"תחתוך": true,
"תחתום": true,
"תחתון": true,
"תחתור": true,
"תחתיה": true,
"תחתיו": true,
"תחתיי": true,
"תחתיך": true,
"תחתים": true,
"תחתית": true,
"תחתכו": true,
"תחתכי": true,
"תחתלו": true,
"תחתלי": true,
"תחתמו": true,
"תחתמי": true,
"תחתנה": true,
"תחתנו": true,
"תחתני": true,
"תחתרו": true,
"תחתרי": true,
"תטאטא": true,
"תטבול": true,
"תטבחו": true,
"תטבחי": true,
"תטביל": true,
"תטביע": true,
"תטבלו": true,
"תטבלי": true,
"תטבנה": true,
"תטבעו": true,
"תטבעי": true,
"תטגנה": true,
"תטגנו": true,
"תטגני": true,
"תטהרו": true,
"תטהרי": true,
"תטובע": true,
"תטוגן": true,
"תטוהר": true,
"תטווה": true,
"תטווח": true,
"תטווי": true,
"תטוחו": true,
"תטוחי": true,
"תטויב": true,
"תטויח": true,
"תטויל": true,
"תטומא": true,
"תטונף": true,
"תטוסו": true,
"תטוסי": true,
"תטופח": true,
"תטופל": true,
"תטופס": true,
"תטופף": true,
"תטזזו": true,
"תטזזי": true,
"תטחנה": true,
"תטחנו": true,
"תטחני": true,
"תטיבו": true,
"תטיבי": true,
"תטיחו": true,
"תטיחי": true,
"תטייב": true,
"תטייח": true,
"תטייל": true,
"תטילו": true,
"תטילי": true,
"תטינה": true,
"תטיסו": true,
"תטיסי": true,
"תטיפו": true,
"תטיפי": true,
"תטלטל": true,
"תטליא": true,
"תטלנה": true,
"תטלפן": true,
"תטמאו": true,
"תטמאי": true,
"תטמון": true,
"תטמטם": true,
"תטמין": true,
"תטמיע": true,
"תטמנה": true,
"תטמנו": true,
"תטמני": true,
"תטמעו": true,
"תטמעי": true,
"תטנפו": true,
"תטנפי": true,
"תטסנה": true,
"תטעים": true,
"תטעין": true,
"תטעמו": true,
"תטעמי": true,
"תטענה": true,
"תטענו": true,
"תטעני": true,
"תטפול": true,
"תטפחו": true,
"תטפחי": true,
"תטפטף": true,
"תטפלו": true,
"תטפלי": true,
"תטפנה": true,
"תטפסו": true,
"תטפסי": true,
"תטרדו": true,
"תטרדי": true,
"תטרוד": true,
"תטרוף": true,
"תטרוק": true,
"תטרחו": true,
"תטרחי": true,
"תטרטר": true,
"תטריד": true,
"תטריח": true,
"תטריל": true,
"תטריף": true,
"תטרפד": true,
"תטרפו": true,
"תטרפי": true,
"תטרקו": true,
"תטרקי": true,
"תטשטש": true,
"תיאבק": true,
"תיאגר": true,
"תיאהב": true,
"תיאהד": true,
"תיאום": true,
"תיאור": true,
"תיאות": true,
"תיאזק": true,
"תיאזר": true,
"תיאחז": true,
"תיאטם": true,
"תיאטר": true,
"תיאכל": true,
"תיאכף": true,
"תיאלם": true,
"תיאלץ": true,
"תיאמד": true,
"תיאמה": true,
"תיאמו": true,
"תיאמן": true,
"תיאמר": true,
"תיאמת": true,
"תיאנח": true,
"תיאנס": true,
"תיאנק": true,
"תיאסף": true,
"תיאסר": true,
"תיאפה": true,
"תיאפו": true,
"תיאפי": true,
"תיאצר": true,
"תיארג": true,
"תיארה": true,
"תיארו": true,
"תיארז": true,
"תיארת": true,
"תיאשם": true,
"תיבאש": true,
"תיבגד": true,
"תיבדל": true,
"תיבדק": true,
"תיבהל": true,
"תיבוך": true,
"תיבול": true,
"תיבון": true,
"תיבות": true,
"תיבזז": true,
"תיבזק": true,
"תיבחן": true,
"תיבחר": true,
"תיבחש": true,
"תיבלה": true,
"תיבלו": true,
"תיבלי": true,
"תיבלל": true,
"תיבלם": true,
"תיבלע": true,
"תיבלת": true,
"תיבנה": true,
"תיבנו": true,
"תיבני": true,
"תיבעה": true,
"תיבעו": true,
"תיבעט": true,
"תיבעי": true,
"תיבעל": true,
"תיבעת": true,
"תיבצע": true,
"תיבצר": true,
"תיבקע": true,
"תיברא": true,
"תיבשו": true,
"תיבשי": true,
"תיבתה": true,
"תיבתו": true,
"תיבתי": true,
"תיבתך": true,
"תיבתם": true,
"תיבתן": true,
"תיגאל": true,
"תיגבה": true,
"תיגבו": true,
"תיגבי": true,
"תיגדע": true,
"תיגהו": true,
"תיגהי": true,
"תיגוז": true,
"תיגול": true,
"תיגזז": true,
"תיגזל": true,
"תיגזם": true,
"תיגזר": true,
"תיגחו": true,
"תיגחי": true,
"תיגלה": true,
"תיגלו": true,
"תיגלי": true,
"תיגלל": true,
"תיגמל": true,
"תיגמע": true,
"תיגמר": true,
"תיגנב": true,
"תיגנז": true,
"תיגעו": true,
"תיגעי": true,
"תיגעל": true,
"תיגרב": true,
"תיגרם": true,
"תיגרס": true,
"תיגרע": true,
"תיגרף": true,
"תיגרר": true,
"תיגשו": true,
"תיגשי": true,
"תידבק": true,
"תידבר": true,
"תידגם": true,
"תידהם": true,
"תידום": true,
"תידון": true,
"תידוף": true,
"תידור": true,
"תידחה": true,
"תידחו": true,
"תידחי": true,
"תידחס": true,
"תידחף": true,
"תידחק": true,
"תידלה": true,
"תידלו": true,
"תידלי": true,
"תידלל": true,
"תידלק": true,
"תידמה": true,
"תידמו": true,
"תידמי": true,
"תידפו": true,
"תידפי": true,
"תידפס": true,
"תידפק": true,
"תידקר": true,
"תידרו": true,
"תידרי": true,
"תידרך": true,
"תידרס": true,
"תידרש": true,
"תיהגה": true,
"תיהגו": true,
"תיהגי": true,
"תיהדף": true,
"תיהנה": true,
"תיהנו": true,
"תיהני": true,
"תיהפך": true,
"תיהרג": true,
"תיהרס": true,
"תיובא": true,
"תיובש": true,
"תיוגה": true,
"תיוגו": true,
"תיוגי": true,
"תיוגך": true,
"תיוגם": true,
"תיוגן": true,
"תיודע": true,
"תיוהד": true,
"תיווך": true,
"תיוזע": true,
"תיוחד": true,
"תיוחל": true,
"תיוחם": true,
"תיוחס": true,
"תיולד": true,
"תיונה": true,
"תיונו": true,
"תיוני": true,
"תיונך": true,
"תיונם": true,
"תיונן": true,
"תיוסד": true,
"תיוסף": true,
"תיוסר": true,
"תיועד": true,
"תיועל": true,
"תיוער": true,
"תיופה": true,
"תיופו": true,
"תיופי": true,
"תיוצא": true,
"תיוצב": true,
"תיוצג": true,
"תיוצר": true,
"תיוקה": true,
"תיוקו": true,
"תיוקי": true,
"תיוקך": true,
"תיוקם": true,
"תיוקן": true,
"תיורה": true,
"תיורו": true,
"תיורט": true,
"תיורי": true,
"תיורך": true,
"תיורם": true,
"תיורן": true,
"תיושב": true,
"תיושם": true,
"תיושן": true,
"תיושר": true,
"תיותר": true,
"תיזבח": true,
"תיזהר": true,
"תיזוז": true,
"תיזום": true,
"תיזון": true,
"תיזזה": true,
"תיזזו": true,
"תיזזת": true,
"תיזכר": true,
"תיזלו": true,
"תיזלי": true,
"תיזלל": true,
"תיזמו": true,
"תיזמי": true,
"תיזמן": true,
"תיזמר": true,
"תיזנח": true,
"תיזעק": true,
"תיזקף": true,
"תיזקק": true,
"תיזרע": true,
"תיזרק": true,
"תיחבא": true,
"תיחבט": true,
"תיחבל": true,
"תיחבש": true,
"תיחגג": true,
"תיחגר": true,
"תיחוג": true,
"תיחוו": true,
"תיחוח": true,
"תיחום": true,
"תיחור": true,
"תיחות": true,
"תיחזה": true,
"תיחזו": true,
"תיחזי": true,
"תיחחה": true,
"תיחחו": true,
"תיחחת": true,
"תיחטף": true,
"תיחכר": true,
"תיחלב": true,
"תיחלם": true,
"תיחלץ": true,
"תיחלק": true,
"תיחלש": true,
"תיחמד": true,
"תיחמה": true,
"תיחמו": true,
"תיחמץ": true,
"תיחמת": true,
"תיחנט": true,
"תיחנך": true,
"תיחנק": true,
"תיחסך": true,
"תיחסם": true,
"תיחפז": true,
"תיחפף": true,
"תיחפר": true,
"תיחצב": true,
"תיחצה": true,
"תיחצו": true,
"תיחצי": true,
"תיחצץ": true,
"תיחקק": true,
"תיחקר": true,
"תיחרב": true,
"תיחרד": true,
"תיחרט": true,
"תיחרך": true,
"תיחרץ": true,
"תיחרש": true,
"תיחרת": true,
"תיחשב": true,
"תיחשד": true,
"תיחשף": true,
"תיחשק": true,
"תיחתך": true,
"תיחתם": true,
"תיטבו": true,
"תיטבח": true,
"תיטבי": true,
"תיטבל": true,
"תיטבע": true,
"תיטהר": true,
"תיטוו": true,
"תיטול": true,
"תיטור": true,
"תיטוש": true,
"תיטחן": true,
"תיטיב": true,
"תיטלו": true,
"תיטלי": true,
"תיטמא": true,
"תיטמן": true,
"תיטמע": true,
"תיטעו": true,
"תיטעי": true,
"תיטעם": true,
"תיטען": true,
"תיטפל": true,
"תיטרד": true,
"תיטרו": true,
"תיטרי": true,
"תיטרף": true,
"תיטרק": true,
"תיטשו": true,
"תיטשי": true,
"תייאש": true,
"תייבא": true,
"תייבב": true,
"תייבם": true,
"תייבש": true,
"תייגה": true,
"תייגו": true,
"תייגי": true,
"תייגע": true,
"תייגת": true,
"תיידה": true,
"תיידו": true,
"תיידי": true,
"תיידע": true,
"תייהד": true,
"תייזע": true,
"תייחד": true,
"תייחל": true,
"תייחם": true,
"תייחס": true,
"תיילד": true,
"תיילל": true,
"תיימה": true,
"תיימו": true,
"תיימת": true,
"תיינן": true,
"תייסד": true,
"תייסף": true,
"תייסר": true,
"תייעד": true,
"תייעל": true,
"תייעץ": true,
"תייער": true,
"תייפה": true,
"תייפו": true,
"תייפי": true,
"תייצא": true,
"תייצב": true,
"תייצג": true,
"תייצר": true,
"תייקה": true,
"תייקו": true,
"תייקי": true,
"תייקר": true,
"תייקת": true,
"תיירה": true,
"תיירו": true,
"תיירט": true,
"תיירי": true,
"תיירך": true,
"תיירם": true,
"תיירן": true,
"תיירת": true,
"תיישב": true,
"תיישם": true,
"תיישן": true,
"תיישר": true,
"תייתר": true,
"תיכבה": true,
"תיכבו": true,
"תיכבי": true,
"תיכבל": true,
"תיכבש": true,
"תיכוו": true,
"תיכון": true,
"תיכות": true,
"תיכזב": true,
"תיכחד": true,
"תיכחו": true,
"תיכחי": true,
"תיכלא": true,
"תיכלל": true,
"תיכלם": true,
"תיכמר": true,
"תיכנה": true,
"תיכנו": true,
"תיכנס": true,
"תיכנע": true,
"תיכנת": true,
"תיכסס": true,
"תיכסף": true,
"תיכפה": true,
"תיכפו": true,
"תיכפי": true,
"תיכפל": true,
"תיכפת": true,
"תיכרה": true,
"תיכרו": true,
"תיכרי": true,
"תיכרך": true,
"תיכרת": true,
"תיכשל": true,
"תיכתב": true,
"תיכתש": true,
"תילאה": true,
"תילאו": true,
"תילאי": true,
"תילבל": true,
"תילבש": true,
"תילגם": true,
"תילוו": true,
"תילחם": true,
"תילחץ": true,
"תילחש": true,
"תילכד": true,
"תילכם": true,
"תילכן": true,
"תילמד": true,
"תילנו": true,
"תילעס": true,
"תילפת": true,
"תילקח": true,
"תימאס": true,
"תימדד": true,
"תימהל": true,
"תימוג": true,
"תימוח": true,
"תימול": true,
"תימור": true,
"תימות": true,
"תימזג": true,
"תימחא": true,
"תימחה": true,
"תימחו": true,
"תימחי": true,
"תימחל": true,
"תימחץ": true,
"תימחק": true,
"תימין": true,
"תימכר": true,
"תימלא": true,
"תימלט": true,
"תימלך": true,
"תימלק": true,
"תימנה": true,
"תימנו": true,
"תימני": true,
"תימנע": true,
"תימסו": true,
"תימסי": true,
"תימסך": true,
"תימסק": true,
"תימסר": true,
"תימעך": true,
"תימען": true,
"תימצא": true,
"תימצץ": true,
"תימקו": true,
"תימקי": true,
"תימרה": true,
"תימרח": true,
"תימרט": true,
"תימשה": true,
"תימשו": true,
"תימשח": true,
"תימשי": true,
"תימשך": true,
"תימשל": true,
"תימתח": true,
"תינאם": true,
"תינבט": true,
"תינגח": true,
"תינגס": true,
"תינגע": true,
"תינגף": true,
"תינגר": true,
"תינוי": true,
"תינוק": true,
"תינזף": true,
"תינזק": true,
"תינזר": true,
"תינחם": true,
"תינחת": true,
"תינטל": true,
"תינטע": true,
"תינטש": true,
"תיניק": true,
"תינית": true,
"תינעל": true,
"תינעץ": true,
"תינצל": true,
"תינצר": true,
"תינקו": true,
"תינקט": true,
"תינקי": true,
"תינקם": true,
"תינקע": true,
"תינשא": true,
"תינשך": true,
"תינשף": true,
"תינתה": true,
"תינתז": true,
"תינתך": true,
"תינתן": true,
"תינתק": true,
"תיסבו": true,
"תיסבי": true,
"תיסבל": true,
"תיסגר": true,
"תיסדו": true,
"תיסדי": true,
"תיסדק": true,
"תיסוג": true,
"תיסוד": true,
"תיסוף": true,
"תיסחב": true,
"תיסחט": true,
"תיסחף": true,
"תיסחר": true,
"תיסכם": true,
"תיסכר": true,
"תיסלח": true,
"תיסלל": true,
"תיסמך": true,
"תיסעו": true,
"תיסעי": true,
"תיספג": true,
"תיספה": true,
"תיספו": true,
"תיספח": true,
"תיספי": true,
"תיספר": true,
"תיסקל": true,
"תיסקר": true,
"תיסרג": true,
"תיסרק": true,
"תיסתם": true,
"תיסתר": true,
"תיעבה": true,
"תיעבו": true,
"תיעבר": true,
"תיעבת": true,
"תיעגן": true,
"תיעדה": true,
"תיעדו": true,
"תיעדר": true,
"תיעדת": true,
"תיעוב": true,
"תיעוד": true,
"תיעול": true,
"תיעור": true,
"תיעוש": true,
"תיעזב": true,
"תיעזר": true,
"תיעטף": true,
"תיעכר": true,
"תיעלב": true,
"תיעלה": true,
"תיעלו": true,
"תיעלם": true,
"תיעלת": true,
"תיעמד": true,
"תיענב": true,
"תיענד": true,
"תיענה": true,
"תיענו": true,
"תיעני": true,
"תיענש": true,
"תיעצב": true,
"תיעצם": true,
"תיעצר": true,
"תיעקד": true,
"תיעקף": true,
"תיעקץ": true,
"תיעקר": true,
"תיערך": true,
"תיערם": true,
"תיערף": true,
"תיערץ": true,
"תיעשה": true,
"תיעשו": true,
"תיעשי": true,
"תיעשק": true,
"תיעשת": true,
"תיעתק": true,
"תיעתר": true,
"תיפגם": true,
"תיפגע": true,
"תיפגש": true,
"תיפדה": true,
"תיפדו": true,
"תיפדי": true,
"תיפול": true,
"תיפוף": true,
"תיפוץ": true,
"תיפחו": true,
"תיפחי": true,
"תיפטר": true,
"תיפלא": true,
"תיפלו": true,
"תיפלט": true,
"תיפלי": true,
"תיפנה": true,
"תיפנו": true,
"תיפני": true,
"תיפסל": true,
"תיפסק": true,
"תיפעם": true,
"תיפער": true,
"תיפצע": true,
"תיפקד": true,
"תיפקח": true,
"תיפרד": true,
"תיפרט": true,
"תיפרם": true,
"תיפרס": true,
"תיפרע": true,
"תיפרץ": true,
"תיפרק": true,
"תיפרש": true,
"תיפתח": true,
"תיפתר": true,
"תיצבט": true,
"תיצבע": true,
"תיצבר": true,
"תיצוד": true,
"תיצוק": true,
"תיצור": true,
"תיצלב": true,
"תיצלה": true,
"תיצלו": true,
"תיצלי": true,
"תיצמד": true,
"תיצמת": true,
"תיצפה": true,
"תיצפו": true,
"תיצפי": true,
"תיצפר": true,
"תיצקו": true,
"תיצקי": true,
"תיצרב": true,
"תיצרד": true,
"תיצרו": true,
"תיצרי": true,
"תיצרך": true,
"תיצרר": true,
"תיקבו": true,
"תיקבי": true,
"תיקבע": true,
"תיקבץ": true,
"תיקבר": true,
"תיקדו": true,
"תיקדח": true,
"תיקדי": true,
"תיקהל": true,
"תיקוב": true,
"תיקוד": true,
"תיקוו": true,
"תיקול": true,
"תיקום": true,
"תיקון": true,
"תיקחו": true,
"תיקחי": true,
"תיקטל": true,
"תיקטם": true,
"תיקטע": true,
"תיקטף": true,
"תיקיה": true,
"תיקיו": true,
"תיקיי": true,
"תיקיך": true,
"תיקים": true,
"תיקכם": true,
"תיקכן": true,
"תיקלה": true,
"תיקלו": true,
"תיקלט": true,
"תיקלי": true,
"תיקלע": true,
"תיקלת": true,
"תיקמו": true,
"תיקמי": true,
"תיקנה": true,
"תיקנו": true,
"תיקני": true,
"תיקנס": true,
"תיקנת": true,
"תיקצב": true,
"תיקצץ": true,
"תיקצר": true,
"תיקרא": true,
"תיקרה": true,
"תיקרו": true,
"תיקרי": true,
"תיקרע": true,
"תיקרש": true,
"תיקשר": true,
"תיראה": true,
"תיראו": true,
"תיראי": true,
"תירגם": true,
"תירגע": true,
"תירדם": true,
"תירדף": true,
"תירוץ": true,
"תירוש": true,
"תירות": true,
"תירחץ": true,
"תירטב": true,
"תירטט": true,
"תירכס": true,
"תירכש": true,
"תירמז": true,
"תירמס": true,
"תירסה": true,
"תירסו": true,
"תירסי": true,
"תירסך": true,
"תירסם": true,
"תירסן": true,
"תירעש": true,
"תירפא": true,
"תירצה": true,
"תירצו": true,
"תירצח": true,
"תירצי": true,
"תירצץ": true,
"תירצת": true,
"תירקב": true,
"תירקו": true,
"תירקח": true,
"תירקי": true,
"תירקם": true,
"תירשו": true,
"תירשי": true,
"תירשם": true,
"תירתם": true,
"תירתע": true,
"תישאב": true,
"תישאג": true,
"תישאו": true,
"תישאי": true,
"תישאל": true,
"תישאף": true,
"תישאר": true,
"תישבה": true,
"תישבו": true,
"תישבי": true,
"תישבע": true,
"תישבר": true,
"תישגב": true,
"תישדד": true,
"תישוב": true,
"תישוך": true,
"תישזר": true,
"תישחט": true,
"תישחק": true,
"תישטף": true,
"תישיר": true,
"תישכב": true,
"תישכו": true,
"תישכח": true,
"תישכי": true,
"תישכם": true,
"תישכן": true,
"תישכר": true,
"תישלה": true,
"תישלו": true,
"תישלח": true,
"תישלט": true,
"תישלי": true,
"תישלל": true,
"תישלם": true,
"תישלף": true,
"תישלק": true,
"תישמד": true,
"תישמט": true,
"תישמע": true,
"תישמר": true,
"תישנה": true,
"תישנו": true,
"תישני": true,
"תישנק": true,
"תישען": true,
"תישפט": true,
"תישפך": true,
"תישקו": true,
"תישקי": true,
"תישקל": true,
"תישקף": true,
"תישרט": true,
"תישרך": true,
"תישרף": true,
"תישרק": true,
"תישתל": true,
"תיתבע": true,
"תיתום": true,
"תיתחב": true,
"תיתכן": true,
"תיתלה": true,
"תיתלו": true,
"תיתלי": true,
"תיתלש": true,
"תיתמו": true,
"תיתמי": true,
"תיתמך": true,
"תיתמם": true,
"תיתמר": true,
"תיתנה": true,
"תיתנו": true,
"תיתני": true,
"תיתפס": true,
"תיתפר": true,
"תיתפש": true,
"תיתקל": true,
"תיתקע": true,
"תיתקף": true,
"תיתרם": true,
"תיתשו": true,
"תיתשי": true,
"תכאבו": true,
"תכאבי": true,
"תכאיב": true,
"תכבדו": true,
"תכבדי": true,
"תכבול": true,
"תכבוש": true,
"תכביד": true,
"תכביר": true,
"תכבלו": true,
"תכבלי": true,
"תכבסו": true,
"תכבסי": true,
"תכבשו": true,
"תכבשי": true,
"תכדרר": true,
"תכהנה": true,
"תכהנו": true,
"תכהני": true,
"תכובד": true,
"תכובה": true,
"תכובו": true,
"תכובי": true,
"תכובס": true,
"תכוון": true,
"תכווץ": true,
"תכויל": true,
"תכויס": true,
"תכויר": true,
"תכולה": true,
"תכולו": true,
"תכולי": true,
"תכולת": true,
"תכונה": true,
"תכונו": true,
"תכוני": true,
"תכונן": true,
"תכונס": true,
"תכונת": true,
"תכוסה": true,
"תכוסו": true,
"תכוסח": true,
"תכוסי": true,
"תכוער": true,
"תכופה": true,
"תכופף": true,
"תכופר": true,
"תכושף": true,
"תכותב": true,
"תכותר": true,
"תכותת": true,
"תכזבו": true,
"תכזבי": true,
"תכזיב": true,
"תכחדו": true,
"תכחדי": true,
"תכחיד": true,
"תכחיל": true,
"תכחיש": true,
"תכחכח": true,
"תכחשו": true,
"תכחשי": true,
"תכייל": true,
"תכייס": true,
"תכייף": true,
"תכייר": true,
"תכילו": true,
"תכילי": true,
"תכינה": true,
"תכינו": true,
"תכיני": true,
"תכירו": true,
"תכירי": true,
"תכישו": true,
"תכישי": true,
"תככבו": true,
"תככבי": true,
"תככיה": true,
"תככיו": true,
"תככיי": true,
"תככיך": true,
"תככים": true,
"תככני": true,
"תכלאו": true,
"תכלאי": true,
"תכלול": true,
"תכליא": true,
"תכליב": true,
"תכליל": true,
"תכלים": true,
"תכלית": true,
"תכלכל": true,
"תכללו": true,
"תכללי": true,
"תכלנה": true,
"תכלתה": true,
"תכלתו": true,
"תכלתי": true,
"תכלתך": true,
"תכלתם": true,
"תכלתן": true,
"תכמהו": true,
"תכמהי": true,
"תכמוש": true,
"תכמין": true,
"תכמנה": true,
"תכמשו": true,
"תכמשי": true,
"תכנון": true,
"תכנוס": true,
"תכנות": true,
"תכניה": true,
"תכניו": true,
"תכניי": true,
"תכניך": true,
"תכנים": true,
"תכניס": true,
"תכניע": true,
"תכנית": true,
"תכננה": true,
"תכננו": true,
"תכנני": true,
"תכננת": true,
"תכנסו": true,
"תכנסי": true,
"תכנתה": true,
"תכנתו": true,
"תכנתי": true,
"תכנתך": true,
"תכנתם": true,
"תכנתן": true,
"תכסוס": true,
"תכסחו": true,
"תכסחי": true,
"תכסיס": true,
"תכסיף": true,
"תכסית": true,
"תכססו": true,
"תכססי": true,
"תכספו": true,
"תכספי": true,
"תכעיס": true,
"תכעסו": true,
"תכעסי": true,
"תכערו": true,
"תכערי": true,
"תכפול": true,
"תכפוף": true,
"תכפור": true,
"תכפות": true,
"תכפיל": true,
"תכפיף": true,
"תכפיש": true,
"תכפלו": true,
"תכפלי": true,
"תכפפו": true,
"תכפפי": true,
"תכפרו": true,
"תכפרי": true,
"תכפתו": true,
"תכפתי": true,
"תכפתר": true,
"תכרבל": true,
"תכרוך": true,
"תכרות": true,
"תכרטס": true,
"תכריז": true,
"תכריח": true,
"תכריך": true,
"תכריע": true,
"תכרית": true,
"תכרכו": true,
"תכרכי": true,
"תכרכר": true,
"תכרנה": true,
"תכרסם": true,
"תכרעו": true,
"תכרעי": true,
"תכרתו": true,
"תכרתי": true,
"תכשול": true,
"תכשיט": true,
"תכשיל": true,
"תכשיר": true,
"תכשכש": true,
"תכשלו": true,
"תכשלי": true,
"תכשנה": true,
"תכשפו": true,
"תכשפי": true,
"תכתבו": true,
"תכתבי": true,
"תכתוב": true,
"תכתוש": true,
"תכתיב": true,
"תכתים": true,
"תכתיף": true,
"תכתיר": true,
"תכתרו": true,
"תכתרי": true,
"תכתשו": true,
"תכתשי": true,
"תכתתו": true,
"תכתתי": true,
"תלאות": true,
"תלאטו": true,
"תלאטי": true,
"תלאים": true,
"תלאתה": true,
"תלאתו": true,
"תלאתי": true,
"תלאתך": true,
"תלאתם": true,
"תלאתן": true,
"תלבבו": true,
"תלבבי": true,
"תלבין": true,
"תלביש": true,
"תלבלב": true,
"תלבנה": true,
"תלבנו": true,
"תלבני": true,
"תלבשו": true,
"תלבשי": true,
"תלגום": true,
"תלגלג": true,
"תלגמו": true,
"תלגמי": true,
"תלדנה": true,
"תלהטו": true,
"תלהטט": true,
"תלהטי": true,
"תלהיב": true,
"תלהיט": true,
"תלהקו": true,
"תלהקי": true,
"תלובה": true,
"תלובו": true,
"תלובי": true,
"תלובן": true,
"תלוהק": true,
"תלווה": true,
"תלווו": true,
"תלווי": true,
"תלוחח": true,
"תלוחך": true,
"תלוטף": true,
"תלוטש": true,
"תלויה": true,
"תלוכד": true,
"תלולה": true,
"תלונה": true,
"תלונו": true,
"תלוני": true,
"תלופף": true,
"תלוקט": true,
"תלוקק": true,
"תלושה": true,
"תלושו": true,
"תלושי": true,
"תלותה": true,
"תלותו": true,
"תלותי": true,
"תלותך": true,
"תלותם": true,
"תלותן": true,
"תלחחו": true,
"תלחחי": true,
"תלחים": true,
"תלחין": true,
"תלחיץ": true,
"תלחכו": true,
"תלחכי": true,
"תלחלח": true,
"תלחמו": true,
"תלחמי": true,
"תלחנה": true,
"תלחצו": true,
"תלחצי": true,
"תלחשו": true,
"תלחשי": true,
"תלחשש": true,
"תלטוש": true,
"תלטנה": true,
"תלטפו": true,
"תלטפי": true,
"תלטשו": true,
"תלטשי": true,
"תליהם": true,
"תליהן": true,
"תליום": true,
"תליון": true,
"תליות": true,
"תליטו": true,
"תליטי": true,
"תלייה": true,
"תלייך": true,
"תליין": true,
"תליכם": true,
"תליכן": true,
"תלינה": true,
"תלינו": true,
"תליני": true,
"תלישה": true,
"תליתי": true,
"תליתם": true,
"תליתן": true,
"תלכדו": true,
"תלכדי": true,
"תלכוד": true,
"תלכיד": true,
"תלכלך": true,
"תלכנה": true,
"תלכסן": true,
"תלמדו": true,
"תלמדי": true,
"תלמוד": true,
"תלמיד": true,
"תלמיה": true,
"תלמיו": true,
"תלמיי": true,
"תלמיך": true,
"תלמים": true,
"תלמכם": true,
"תלמכן": true,
"תלמנו": true,
"תלעגו": true,
"תלעגי": true,
"תלעיג": true,
"תלעיז": true,
"תלעיט": true,
"תלעסו": true,
"תלעסי": true,
"תלפות": true,
"תלפפו": true,
"תלפפי": true,
"תלפתו": true,
"תלפתי": true,
"תלקטו": true,
"תלקטי": true,
"תלקקו": true,
"תלקקי": true,
"תלשין": true,
"תלשנה": true,
"תלשנו": true,
"תלשתי": true,
"תלשתם": true,
"תלשתן": true,
"תלתול": true,
"תלתלה": true,
"תלתלו": true,
"תלתלי": true,
"תלתלך": true,
"תלתלם": true,
"תלתלן": true,
"תלתלת": true,
"תלתנה": true,
"תלתנו": true,
"תלתני": true,
"תלתנך": true,
"תלתנם": true,
"תלתנן": true,
"תמאיס": true,
"תמאנה": true,
"תמאנו": true,
"תמאני": true,
"תמאסו": true,
"תמאסי": true,
"תמגנה": true,
"תמגנו": true,
"תמגנט": true,
"תמגני": true,
"תמגרו": true,
"תמגרי": true,
"תמדבר": true,
"תמדדו": true,
"תמדדי": true,
"תמדוד": true,
"תמדרו": true,
"תמדרי": true,
"תמהון": true,
"תמהות": true,
"תמהיל": true,
"תמהים": true,
"תמהלו": true,
"תמהלי": true,
"תמהנה": true,
"תמהנו": true,
"תמהני": true,
"תמהרו": true,
"תמהרי": true,
"תמהתי": true,
"תמהתם": true,
"תמהתן": true,
"תמוגן": true,
"תמוגר": true,
"תמודר": true,
"תמוהה": true,
"תמוזג": true,
"תמוטט": true,
"תמוין": true,
"תמוכה": true,
"תמוכן": true,
"תמולא": true,
"תמולו": true,
"תמולי": true,
"תמולל": true,
"תמומן": true,
"תמומש": true,
"תמונה": true,
"תמונו": true,
"תמוני": true,
"תמונף": true,
"תמונת": true,
"תמוסד": true,
"תמוסה": true,
"תמוסו": true,
"תמוסי": true,
"תמוסך": true,
"תמוסס": true,
"תמוען": true,
"תמופה": true,
"תמופו": true,
"תמופי": true,
"תמוצה": true,
"תמוצו": true,
"תמוצי": true,
"תמוצע": true,
"תמוקד": true,
"תמוקם": true,
"תמוקש": true,
"תמורה": true,
"תמורק": true,
"תמורת": true,
"תמושו": true,
"תמושי": true,
"תמושש": true,
"תמותג": true,
"תמותה": true,
"תמותו": true,
"תמותי": true,
"תמותם": true,
"תמותן": true,
"תמזגו": true,
"תמזגי": true,
"תמזוג": true,
"תמזמז": true,
"תמזער": true,
"תמחאו": true,
"תמחאי": true,
"תמחוי": true,
"תמחור": true,
"תמחזר": true,
"תמחיז": true,
"תמחיר": true,
"תמחיש": true,
"תמחלו": true,
"תמחלי": true,
"תמחצו": true,
"תמחצי": true,
"תמחקו": true,
"תמחקי": true,
"תמחרה": true,
"תמחרו": true,
"תמחרי": true,
"תמחרת": true,
"תמחשב": true,
"תמטיר": true,
"תמטנה": true,
"תמידי": true,
"תמיהה": true,
"תמיהם": true,
"תמיהן": true,
"תמיטו": true,
"תמיטי": true,
"תמייך": true,
"תמיין": true,
"תמיכה": true,
"תמיכם": true,
"תמיכן": true,
"תמיכת": true,
"תמימה": true,
"תמימי": true,
"תמינו": true,
"תמיסה": true,
"תמיסת": true,
"תמירה": true,
"תמירו": true,
"תמירי": true,
"תמיתו": true,
"תמיתי": true,
"תמכור": true,
"תמכנה": true,
"תמכנו": true,
"תמכני": true,
"תמכרו": true,
"תמכרי": true,
"תמכתי": true,
"תמכתם": true,
"תמכתן": true,
"תמלאו": true,
"תמלאי": true,
"תמלוך": true,
"תמלול": true,
"תמלוק": true,
"תמלטו": true,
"תמלטי": true,
"תמליא": true,
"תמליח": true,
"תמליט": true,
"תמליך": true,
"תמליל": true,
"תמליץ": true,
"תמלכד": true,
"תמלכו": true,
"תמלכי": true,
"תמללה": true,
"תמללו": true,
"תמללי": true,
"תמללת": true,
"תמלמל": true,
"תמלצר": true,
"תמלקו": true,
"תמלקי": true,
"תממנה": true,
"תממנו": true,
"תממני": true,
"תממשו": true,
"תממשי": true,
"תמנון": true,
"תמנית": true,
"תמנעו": true,
"תמנעי": true,
"תמנפו": true,
"תמנפי": true,
"תמסגר": true,
"תמסדו": true,
"תמסדי": true,
"תמסוך": true,
"תמסוק": true,
"תמסור": true,
"תמסחה": true,
"תמסחו": true,
"תמסחי": true,
"תמסחך": true,
"תמסחם": true,
"תמסחן": true,
"תמסחר": true,
"תמסטל": true,
"תמסכו": true,
"תמסכי": true,
"תמסמס": true,
"תמסמר": true,
"תמסנה": true,
"תמספר": true,
"תמסקו": true,
"תמסקי": true,
"תמסרו": true,
"תמסרי": true,
"תמעדו": true,
"תמעדי": true,
"תמעטו": true,
"תמעטי": true,
"תמעיד": true,
"תמעיט": true,
"תמעכו": true,
"תמעכי": true,
"תמעלו": true,
"תמעלי": true,
"תמענה": true,
"תמענו": true,
"תמעני": true,
"תמצאו": true,
"תמצאי": true,
"תמצוץ": true,
"תמצות": true,
"תמציא": true,
"תמצית": true,
"תמצמץ": true,
"תמצעו": true,
"תמצעי": true,
"תמצצו": true,
"תמצצי": true,
"תמצקו": true,
"תמצקי": true,
"תמצתה": true,
"תמצתו": true,
"תמצתי": true,
"תמצתם": true,
"תמצתן": true,
"תמקדו": true,
"תמקדי": true,
"תמקמו": true,
"תמקמי": true,
"תמקשו": true,
"תמקשי": true,
"תמרדו": true,
"תמרדי": true,
"תמרוד": true,
"תמרוט": true,
"תמרון": true,
"תמרוץ": true,
"תמרוק": true,
"תמרור": true,
"תמרחו": true,
"תמרחי": true,
"תמרטו": true,
"תמרטי": true,
"תמריא": true,
"תמריד": true,
"תמריה": true,
"תמריו": true,
"תמריי": true,
"תמריך": true,
"תמרים": true,
"תמריץ": true,
"תמרכז": true,
"תמרכם": true,
"תמרכן": true,
"תמרנה": true,
"תמרנו": true,
"תמרני": true,
"תמרנת": true,
"תמרפק": true,
"תמרצה": true,
"תמרצו": true,
"תמרצי": true,
"תמרצת": true,
"תמרקו": true,
"תמררה": true,
"תמררו": true,
"תמררי": true,
"תמררת": true,
"תמשוך": true,
"תמשול": true,
"תמשחו": true,
"תמשחי": true,
"תמשיח": true,
"תמשיך": true,
"תמשיל": true,
"תמשים": true,
"תמשכו": true,
"תמשכי": true,
"תמשכן": true,
"תמשלו": true,
"תמשלי": true,
"תמשמש": true,
"תמששו": true,
"תמששי": true,
"תמתגו": true,
"תמתגי": true,
"תמתחו": true,
"תמתחי": true,
"תמתין": true,
"תמתיק": true,
"תמתכם": true,
"תמתכן": true,
"תמתנה": true,
"תמתנו": true,
"תמתני": true,
"תנאיה": true,
"תנאיו": true,
"תנאיי": true,
"תנאיך": true,
"תנאים": true,
"תנאכם": true,
"תנאכן": true,
"תנאמו": true,
"תנאמי": true,
"תנאנה": true,
"תנאנו": true,
"תנאפו": true,
"תנאפי": true,
"תנאצו": true,
"תנאצי": true,
"תנאקו": true,
"תנאקי": true,
"תנבאו": true,
"תנבאי": true,
"תנבוט": true,
"תנבור": true,
"תנבחו": true,
"תנבחי": true,
"תנבטו": true,
"תנבטי": true,
"תנביט": true,
"תנבלו": true,
"תנבלי": true,
"תנבנה": true,
"תנבעו": true,
"תנבעי": true,
"תנברו": true,
"תנברי": true,
"תנגבו": true,
"תנגבי": true,
"תנגדו": true,
"תנגדי": true,
"תנגוס": true,
"תנגחו": true,
"תנגחי": true,
"תנגיד": true,
"תנגנה": true,
"תנגנו": true,
"תנגני": true,
"תנגסו": true,
"תנגסי": true,
"תנגשו": true,
"תנגשי": true,
"תנדבו": true,
"תנדבי": true,
"תנדדו": true,
"תנדדי": true,
"תנדוד": true,
"תנדור": true,
"תנדנד": true,
"תנדנה": true,
"תנדרו": true,
"תנדרי": true,
"תנהגו": true,
"תנהגי": true,
"תנהיג": true,
"תנהלו": true,
"תנהלי": true,
"תנהמו": true,
"תנהמי": true,
"תנהרו": true,
"תנהרי": true,
"תנואץ": true,
"תנובה": true,
"תנוגב": true,
"תנוגד": true,
"תנוגח": true,
"תנוגן": true,
"תנודב": true,
"תנודה": true,
"תנודו": true,
"תנודי": true,
"תנוהג": true,
"תנוהל": true,
"תנווט": true,
"תנוון": true,
"תנוחה": true,
"תנוחו": true,
"תנוחי": true,
"תנוחם": true,
"תנוחש": true,
"תנוטר": true,
"תנוכה": true,
"תנוכו": true,
"תנוכי": true,
"תנוכך": true,
"תנוכם": true,
"תנוכן": true,
"תנוכס": true,
"תנוכש": true,
"תנומה": true,
"תנומו": true,
"תנומי": true,
"תנומק": true,
"תנוסה": true,
"תנוסו": true,
"תנוסח": true,
"תנוסי": true,
"תנוסר": true,
"תנועה": true,
"תנועו": true,
"תנועי": true,
"תנוער": true,
"תנועת": true,
"תנופה": true,
"תנופו": true,
"תנופח": true,
"תנופי": true,
"תנופף": true,
"תנופץ": true,
"תנופק": true,
"תנוצח": true,
"תנוצל": true,
"תנוקב": true,
"תנוקד": true,
"תנוקה": true,
"תנוקו": true,
"תנוקז": true,
"תנוקי": true,
"תנוקר": true,
"תנורה": true,
"תנורו": true,
"תנורי": true,
"תנורך": true,
"תנורם": true,
"תנורן": true,
"תנושל": true,
"תנושק": true,
"תנותב": true,
"תנותח": true,
"תנותץ": true,
"תנותק": true,
"תנזוף": true,
"תנזים": true,
"תנזפו": true,
"תנזפי": true,
"תנחיל": true,
"תנחית": true,
"תנחלו": true,
"תנחלי": true,
"תנחמו": true,
"תנחמי": true,
"תנחנה": true,
"תנחרו": true,
"תנחרי": true,
"תנחשו": true,
"תנחשי": true,
"תנחתו": true,
"תנחתי": true,
"תנטוף": true,
"תנטוש": true,
"תנטפו": true,
"תנטפי": true,
"תנטרו": true,
"תנטרי": true,
"תנטרל": true,
"תנטשו": true,
"תנטשי": true,
"תניאו": true,
"תניאי": true,
"תניבו": true,
"תניבי": true,
"תנידו": true,
"תנידי": true,
"תניהם": true,
"תניהן": true,
"תניחו": true,
"תניחי": true,
"תנייד": true,
"תנייה": true,
"תנייך": true,
"תניית": true,
"תניכם": true,
"תניכן": true,
"תנינה": true,
"תנינו": true,
"תניני": true,
"תנינך": true,
"תנינם": true,
"תנינן": true,
"תניסו": true,
"תניסי": true,
"תניעו": true,
"תניעי": true,
"תניפו": true,
"תניפי": true,
"תניקו": true,
"תניקי": true,
"תנכסו": true,
"תנכסי": true,
"תנכרו": true,
"תנכרי": true,
"תנכשו": true,
"תנכשי": true,
"תנמוך": true,
"תנמיך": true,
"תנמכו": true,
"תנמכי": true,
"תנמנם": true,
"תנמקו": true,
"תנמקי": true,
"תנסוך": true,
"תנסוק": true,
"תנסחו": true,
"תנסחי": true,
"תנסכו": true,
"תנסכי": true,
"תנסנה": true,
"תנסקו": true,
"תנסקי": true,
"תנסרו": true,
"תנסרי": true,
"תנעיה": true,
"תנעיו": true,
"תנעיי": true,
"תנעיך": true,
"תנעים": true,
"תנעכם": true,
"תנעכן": true,
"תנעלו": true,
"תנעלי": true,
"תנעמו": true,
"תנעמי": true,
"תנענה": true,
"תנענו": true,
"תנענע": true,
"תנעצו": true,
"תנעצי": true,
"תנערו": true,
"תנערי": true,
"תנפוש": true,
"תנפחו": true,
"תנפחי": true,
"תנפיק": true,
"תנפיש": true,
"תנפנה": true,
"תנפנף": true,
"תנפצו": true,
"תנפצי": true,
"תנפקו": true,
"תנפקי": true,
"תנפשו": true,
"תנפשי": true,
"תנצוץ": true,
"תנצור": true,
"תנצחו": true,
"תנצחי": true,
"תנציח": true,
"תנציל": true,
"תנצלו": true,
"תנצלי": true,
"תנצנה": true,
"תנצנץ": true,
"תנצצו": true,
"תנצצי": true,
"תנצרו": true,
"תנצרי": true,
"תנקבו": true,
"תנקבי": true,
"תנקדו": true,
"תנקדי": true,
"תנקוט": true,
"תנקוף": true,
"תנקור": true,
"תנקוש": true,
"תנקזו": true,
"תנקזי": true,
"תנקטו": true,
"תנקטי": true,
"תנקנה": true,
"תנקעו": true,
"תנקעי": true,
"תנקפו": true,
"תנקפי": true,
"תנקרו": true,
"תנקרי": true,
"תנקשו": true,
"תנקשי": true,
"תנרמל": true,
"תנשבו": true,
"תנשבי": true,
"תנשוך": true,
"תנשום": true,
"תנשוף": true,
"תנשור": true,
"תנשים": true,
"תנשכו": true,
"תנשכי": true,
"תנשלו": true,
"תנשלי": true,
"תנשמו": true,
"תנשמי": true,
"תנשמת": true,
"תנשנש": true,
"תנשפו": true,
"תנשפי": true,
"תנשקו": true,
"תנשקי": true,
"תנשרו": true,
"תנשרי": true,
"תנתבו": true,
"תנתבי": true,
"תנתחו": true,
"תנתחי": true,
"תנתצו": true,
"תנתצי": true,
"תנתקו": true,
"תנתקי": true,
"תנתרו": true,
"תנתרי": true,
"תסאבו": true,
"תסאבי": true,
"תסאנה": true,
"תסאנו": true,
"תסאני": true,
"תסבאו": true,
"תסבאי": true,
"תסבבו": true,
"תסבבי": true,
"תסבול": true,
"תסבור": true,
"תסביך": true,
"תסביר": true,
"תסבכו": true,
"תסבכי": true,
"תסבלו": true,
"תסבלי": true,
"תסבנה": true,
"תסבנו": true,
"תסבני": true,
"תסבסד": true,
"תסברו": true,
"תסברי": true,
"תסגדו": true,
"תסגדי": true,
"תסגוד": true,
"תסגור": true,
"תסגיר": true,
"תסגלו": true,
"תסגלי": true,
"תסגנה": true,
"תסגנן": true,
"תסגרו": true,
"תסגרי": true,
"תסדוק": true,
"תסדיר": true,
"תסדקו": true,
"תסדקי": true,
"תסדרו": true,
"תסדרי": true,
"תסואב": true,
"תסובב": true,
"תסובו": true,
"תסובי": true,
"תסובך": true,
"תסובן": true,
"תסוגל": true,
"תסוגר": true,
"תסודר": true,
"תסווג": true,
"תסווה": true,
"תסווי": true,
"תסויג": true,
"תסויד": true,
"תסוים": true,
"תסוכו": true,
"תסוכי": true,
"תסוכך": true,
"תסוכל": true,
"תסוכם": true,
"תסולא": true,
"תסולף": true,
"תסולק": true,
"תסומא": true,
"תסומל": true,
"תסומם": true,
"תסומן": true,
"תסומר": true,
"תסונן": true,
"תסונף": true,
"תסופח": true,
"תסופק": true,
"תסופר": true,
"תסוקר": true,
"תסורב": true,
"תסורו": true,
"תסורי": true,
"תסורס": true,
"תסורק": true,
"תסותת": true,
"תסחבו": true,
"תסחבי": true,
"תסחטו": true,
"תסחטי": true,
"תסחיף": true,
"תסחנה": true,
"תסחפו": true,
"תסחפי": true,
"תסחרו": true,
"תסחרי": true,
"תסחרר": true,
"תסטור": true,
"תסטנה": true,
"תסטרו": true,
"תסטרי": true,
"תסיגו": true,
"תסיגי": true,
"תסיחו": true,
"תסיחי": true,
"תסיטו": true,
"תסיטי": true,
"תסייג": true,
"תסייד": true,
"תסיים": true,
"תסייע": true,
"תסייר": true,
"תסיסה": true,
"תסיעו": true,
"תסיעי": true,
"תסיקו": true,
"תסיקי": true,
"תסירו": true,
"תסירי": true,
"תסיתו": true,
"תסיתי": true,
"תסכוך": true,
"תסכול": true,
"תסכום": true,
"תסכור": true,
"תסכים": true,
"תסכין": true,
"תסכית": true,
"תסככו": true,
"תסככי": true,
"תסכלה": true,
"תסכלו": true,
"תסכלי": true,
"תסכלת": true,
"תסכמו": true,
"תסכמי": true,
"תסכנה": true,
"תסכנו": true,
"תסכני": true,
"תסכסך": true,
"תסכרו": true,
"תסכרי": true,
"תסלדו": true,
"תסלדי": true,
"תסלוד": true,
"תסלול": true,
"תסלחו": true,
"תסלחי": true,
"תסלים": true,
"תסליק": true,
"תסללו": true,
"תסללי": true,
"תסלסל": true,
"תסלפו": true,
"תסלפי": true,
"תסלקו": true,
"תסלקי": true,
"תסמאו": true,
"תסמאי": true,
"תסמוך": true,
"תסמור": true,
"תסמיך": true,
"תסמין": true,
"תסמיק": true,
"תסמכו": true,
"תסמכי": true,
"תסמלו": true,
"תסמלי": true,
"תסמלץ": true,
"תסממו": true,
"תסממי": true,
"תסמנה": true,
"תסמנו": true,
"תסמני": true,
"תסמרו": true,
"תסמרי": true,
"תסנגר": true,
"תסנדל": true,
"תסנוט": true,
"תסנטו": true,
"תסנטי": true,
"תסניף": true,
"תסננה": true,
"תסננו": true,
"תסנני": true,
"תסנפו": true,
"תסנפי": true,
"תסנתז": true,
"תססנו": true,
"תססתי": true,
"תססתם": true,
"תססתן": true,
"תסעדו": true,
"תסעדי": true,
"תסעיר": true,
"תסענה": true,
"תסערו": true,
"תסערי": true,
"תספגו": true,
"תספגי": true,
"תספדו": true,
"תספדי": true,
"תספוג": true,
"תספוד": true,
"תספוק": true,
"תספור": true,
"תספחו": true,
"תספחי": true,
"תספיג": true,
"תספיד": true,
"תספיק": true,
"תספסר": true,
"תספקו": true,
"תספקי": true,
"תספרו": true,
"תספרי": true,
"תספרר": true,
"תסקול": true,
"תסקור": true,
"תסקיר": true,
"תסקלו": true,
"תסקלי": true,
"תסקנה": true,
"תסקרו": true,
"תסקרי": true,
"תסקרן": true,
"תסרבו": true,
"תסרבי": true,
"תסרבל": true,
"תסרגו": true,
"תסרגי": true,
"תסרוג": true,
"תסרוק": true,
"תסרור": true,
"תסרחו": true,
"תסרחי": true,
"תסרטה": true,
"תסרטו": true,
"תסרטי": true,
"תסרטן": true,
"תסרטת": true,
"תסריח": true,
"תסריט": true,
"תסרנה": true,
"תסרסו": true,
"תסרסי": true,
"תסרסר": true,
"תסרקו": true,
"תסרקי": true,
"תסררו": true,
"תסררי": true,
"תסתאב": true,
"תסתבך": true,
"תסתבן": true,
"תסתבר": true,
"תסתגל": true,
"תסתגף": true,
"תסתגר": true,
"תסתדר": true,
"תסתוו": true,
"תסתום": true,
"תסתור": true,
"תסתיר": true,
"תסתכל": true,
"תסתכם": true,
"תסתכן": true,
"תסתלק": true,
"תסתמא": true,
"תסתמו": true,
"תסתמי": true,
"תסתמך": true,
"תסתמם": true,
"תסתמן": true,
"תסתמר": true,
"תסתנה": true,
"תסתנן": true,
"תסתעף": true,
"תסתער": true,
"תסתפח": true,
"תסתפק": true,
"תסתפר": true,
"תסתרו": true,
"תסתרי": true,
"תסתרק": true,
"תסתתו": true,
"תסתתי": true,
"תסתתם": true,
"תסתתר": true,
"תעבדו": true,
"תעבדי": true,
"תעבוד": true,
"תעבוט": true,
"תעבור": true,
"תעבטו": true,
"תעבטי": true,
"תעביד": true,
"תעביר": true,
"תעבנה": true,
"תעברו": true,
"תעברי": true,
"תעגבו": true,
"תעגבי": true,
"תעגוב": true,
"תעגון": true,
"תעגלו": true,
"תעגלי": true,
"תעגנה": true,
"תעגנו": true,
"תעגני": true,
"תעדוף": true,
"תעדור": true,
"תעדיף": true,
"תעדכן": true,
"תעדנה": true,
"תעדנו": true,
"תעדני": true,
"תעדפה": true,
"תעדפו": true,
"תעדפי": true,
"תעדפת": true,
"תעדרו": true,
"תעדרי": true,
"תעובד": true,
"תעובה": true,
"תעובו": true,
"תעובי": true,
"תעובר": true,
"תעוגל": true,
"תעוגן": true,
"תעודד": true,
"תעודה": true,
"תעודן": true,
"תעודת": true,
"תעווה": true,
"תעווי": true,
"תעוור": true,
"תעוות": true,
"תעוזה": true,
"תעוטו": true,
"תעוטי": true,
"תעוטר": true,
"תעוכב": true,
"תעוכל": true,
"תעולל": true,
"תעומד": true,
"תעומם": true,
"תעומת": true,
"תעונג": true,
"תעונה": true,
"תעונו": true,
"תעוני": true,
"תעוסה": true,
"תעוסו": true,
"תעוסי": true,
"תעופה": true,
"תעופו": true,
"תעופי": true,
"תעופף": true,
"תעופש": true,
"תעוצב": true,
"תעוצו": true,
"תעוצי": true,
"תעוקה": true,
"תעוקל": true,
"תעוקם": true,
"תעוקר": true,
"תעורב": true,
"תעורר": true,
"תעזבו": true,
"תעזבי": true,
"תעזוב": true,
"תעזור": true,
"תעזיב": true,
"תעזנה": true,
"תעזרו": true,
"תעזרי": true,
"תעטוף": true,
"תעטיר": true,
"תעטפו": true,
"תעטפי": true,
"תעטרו": true,
"תעטרי": true,
"תעיבו": true,
"תעיבי": true,
"תעידו": true,
"תעידי": true,
"תעיות": true,
"תעייה": true,
"תעיין": true,
"תעייף": true,
"תעינה": true,
"תעינו": true,
"תעיפו": true,
"תעיפי": true,
"תעיקו": true,
"תעיקי": true,
"תעירו": true,
"תעירי": true,
"תעיתי": true,
"תעיתם": true,
"תעיתן": true,
"תעכבו": true,
"תעכבי": true,
"תעכור": true,
"תעכיר": true,
"תעכלו": true,
"תעכלי": true,
"תעכסו": true,
"תעכסי": true,
"תעכרו": true,
"תעכרי": true,
"תעלבו": true,
"תעלבי": true,
"תעלוב": true,
"תעלוז": true,
"תעלול": true,
"תעלוץ": true,
"תעלות": true,
"תעלזו": true,
"תעלזי": true,
"תעליב": true,
"תעליל": true,
"תעלים": true,
"תעלנה": true,
"תעלעל": true,
"תעלצו": true,
"תעלצי": true,
"תעלתה": true,
"תעלתו": true,
"תעלתי": true,
"תעלתך": true,
"תעלתם": true,
"תעלתן": true,
"תעמדו": true,
"תעמדי": true,
"תעמוד": true,
"תעמול": true,
"תעמיד": true,
"תעמיס": true,
"תעמיק": true,
"תעמלו": true,
"תעמלי": true,
"תעמלן": true,
"תעממו": true,
"תעממי": true,
"תעמנה": true,
"תעמעם": true,
"תעמתו": true,
"תעמתי": true,
"תענבו": true,
"תענבי": true,
"תענגו": true,
"תענגי": true,
"תענדו": true,
"תענדי": true,
"תענוב": true,
"תענוג": true,
"תענוד": true,
"תענטז": true,
"תעניק": true,
"תעניש": true,
"תענית": true,
"תעסוק": true,
"תעסיק": true,
"תעסקו": true,
"תעסקי": true,
"תעפיל": true,
"תעפנה": true,
"תעפעף": true,
"תעפשו": true,
"תעפשי": true,
"תעצבב": true,
"תעצבו": true,
"תעצבי": true,
"תעצבן": true,
"תעצום": true,
"תעצור": true,
"תעציב": true,
"תעצים": true,
"תעצמו": true,
"תעצמי": true,
"תעצרו": true,
"תעצרי": true,
"תעקבו": true,
"תעקבי": true,
"תעקדו": true,
"תעקדי": true,
"תעקוב": true,
"תעקוד": true,
"תעקוף": true,
"תעקוץ": true,
"תעקור": true,
"תעקלו": true,
"תעקלי": true,
"תעקמו": true,
"תעקמי": true,
"תעקנה": true,
"תעקפו": true,
"תעקפי": true,
"תעקצו": true,
"תעקצי": true,
"תעקצץ": true,
"תעקרו": true,
"תעקרי": true,
"תערבב": true,
"תערבו": true,
"תערבי": true,
"תערבל": true,
"תערגו": true,
"תערגי": true,
"תערגל": true,
"תערוב": true,
"תערוג": true,
"תערוך": true,
"תערום": true,
"תערוף": true,
"תערוק": true,
"תערטל": true,
"תעריב": true,
"תעריה": true,
"תעריו": true,
"תעריי": true,
"תעריך": true,
"תערים": true,
"תעריף": true,
"תעריץ": true,
"תערכו": true,
"תערכי": true,
"תערכם": true,
"תערכן": true,
"תערמו": true,
"תערמי": true,
"תערנה": true,
"תערנו": true,
"תערער": true,
"תערפו": true,
"תערפי": true,
"תערפל": true,
"תערקו": true,
"תערקי": true,
"תעשבו": true,
"תעשבי": true,
"תעשוק": true,
"תעשיה": true,
"תעשיר": true,
"תעשנה": true,
"תעשנו": true,
"תעשני": true,
"תעשקו": true,
"תעשקי": true,
"תעתוע": true,
"תעתוק": true,
"תעתור": true,
"תעתיק": true,
"תעתיר": true,
"תעתעה": true,
"תעתעו": true,
"תעתעי": true,
"תעתעת": true,
"תעתקה": true,
"תעתקו": true,
"תעתקי": true,
"תעתקת": true,
"תעתרו": true,
"תעתרי": true,
"תפארה": true,
"תפארו": true,
"תפארי": true,
"תפארת": true,
"תפברק": true,
"תפגום": true,
"תפגוש": true,
"תפגיז": true,
"תפגין": true,
"תפגיש": true,
"תפגלו": true,
"תפגלי": true,
"תפגמו": true,
"תפגמי": true,
"תפגנה": true,
"תפגעו": true,
"תפגעי": true,
"תפגרו": true,
"תפגרי": true,
"תפגשו": true,
"תפגשי": true,
"תפדרו": true,
"תפדרי": true,
"תפהקו": true,
"תפהקי": true,
"תפואר": true,
"תפוגג": true,
"תפוגה": true,
"תפוגו": true,
"תפוגי": true,
"תפוגל": true,
"תפודה": true,
"תפודו": true,
"תפודי": true,
"תפודך": true,
"תפודם": true,
"תפודן": true,
"תפוזה": true,
"תפוזו": true,
"תפוזי": true,
"תפוזך": true,
"תפוזם": true,
"תפוזן": true,
"תפוזר": true,
"תפוחה": true,
"תפוחו": true,
"תפוחי": true,
"תפוחך": true,
"תפוחם": true,
"תפוחן": true,
"תפוחת": true,
"תפוטם": true,
"תפוטר": true,
"תפויח": true,
"תפויס": true,
"תפולג": true,
"תפולס": true,
"תפונה": true,
"תפונו": true,
"תפוני": true,
"תפונק": true,
"תפוסה": true,
"תפוסו": true,
"תפוסי": true,
"תפוסל": true,
"תפוסק": true,
"תפוצה": true,
"תפוצו": true,
"תפוצח": true,
"תפוצי": true,
"תפוצל": true,
"תפוצץ": true,
"תפוקה": true,
"תפוקח": true,
"תפוקת": true,
"תפורה": true,
"תפורז": true,
"תפורט": true,
"תפורק": true,
"תפורר": true,
"תפורש": true,
"תפושה": true,
"תפושו": true,
"תפושט": true,
"תפושי": true,
"תפושל": true,
"תפושק": true,
"תפושר": true,
"תפותה": true,
"תפותו": true,
"תפותח": true,
"תפותי": true,
"תפותל": true,
"תפזול": true,
"תפזזו": true,
"תפזזי": true,
"תפזלו": true,
"תפזלי": true,
"תפזמו": true,
"תפזמי": true,
"תפזרו": true,
"תפזרי": true,
"תפחדו": true,
"תפחדי": true,
"תפחיד": true,
"תפחית": true,
"תפחלץ": true,
"תפחמו": true,
"תפחמי": true,
"תפחנה": true,
"תפחנו": true,
"תפחתו": true,
"תפחתי": true,
"תפחתם": true,
"תפחתן": true,
"תפטור": true,
"תפטיר": true,
"תפטמו": true,
"תפטמי": true,
"תפטפט": true,
"תפטרו": true,
"תפטרי": true,
"תפטרל": true,
"תפיגו": true,
"תפיגי": true,
"תפיחה": true,
"תפיחו": true,
"תפיחי": true,
"תפייח": true,
"תפייט": true,
"תפייס": true,
"תפילה": true,
"תפילו": true,
"תפילי": true,
"תפילת": true,
"תפיסה": true,
"תפיסו": true,
"תפיסי": true,
"תפיסת": true,
"תפיצו": true,
"תפיצי": true,
"תפיקו": true,
"תפיקי": true,
"תפירה": true,
"תפישה": true,
"תפישת": true,
"תפכור": true,
"תפכפך": true,
"תפכרו": true,
"תפכרי": true,
"תפלגו": true,
"תפלגי": true,
"תפלוט": true,
"תפלוש": true,
"תפלות": true,
"תפלחו": true,
"תפלחי": true,
"תפלטו": true,
"תפלטי": true,
"תפליא": true,
"תפליג": true,
"תפליט": true,
"תפליל": true,
"תפלים": true,
"תפליץ": true,
"תפליק": true,
"תפללו": true,
"תפללי": true,
"תפלמר": true,
"תפלנה": true,
"תפלסו": true,
"תפלסי": true,
"תפלפל": true,
"תפלצת": true,
"תפלשו": true,
"תפלשי": true,
"תפמפם": true,
"תפנוק": true,
"תפנטז": true,
"תפנים": true,
"תפנית": true,
"תפנקו": true,
"תפנקי": true,
"תפסול": true,
"תפסוק": true,
"תפסחו": true,
"תפסחי": true,
"תפסטר": true,
"תפסיד": true,
"תפסיק": true,
"תפסלו": true,
"תפסלי": true,
"תפסנה": true,
"תפסנו": true,
"תפסעו": true,
"תפסעי": true,
"תפספס": true,
"תפסקו": true,
"תפסקי": true,
"תפסתי": true,
"תפסתם": true,
"תפסתן": true,
"תפעול": true,
"תפעיל": true,
"תפעים": true,
"תפעלה": true,
"תפעלו": true,
"תפעלי": true,
"תפעלת": true,
"תפעמו": true,
"תפעמי": true,
"תפענח": true,
"תפעפע": true,
"תפערו": true,
"תפערי": true,
"תפצור": true,
"תפצחו": true,
"תפצחי": true,
"תפציע": true,
"תפציץ": true,
"תפציר": true,
"תפצלו": true,
"תפצלי": true,
"תפצנה": true,
"תפצעו": true,
"תפצעי": true,
"תפצפץ": true,
"תפצרו": true,
"תפצרי": true,
"תפקדה": true,
"תפקדו": true,
"תפקדי": true,
"תפקדת": true,
"תפקוד": true,
"תפקוק": true,
"תפקחו": true,
"תפקחי": true,
"תפקטר": true,
"תפקיד": true,
"תפקיע": true,
"תפקיר": true,
"תפקנה": true,
"תפקסס": true,
"תפקעו": true,
"תפקעי": true,
"תפקפק": true,
"תפקקו": true,
"תפקקי": true,
"תפקשש": true,
"תפרגן": true,
"תפרוט": true,
"תפרום": true,
"תפרוס": true,
"תפרוף": true,
"תפרוץ": true,
"תפרוק": true,
"תפרוש": true,
"תפרזו": true,
"תפרזי": true,
"תפרזל": true,
"תפרחו": true,
"תפרחי": true,
"תפרחת": true,
"תפרטו": true,
"תפרטי": true,
"תפריד": true,
"תפריה": true,
"תפריו": true,
"תפריז": true,
"תפריח": true,
"תפריט": true,
"תפריי": true,
"תפריך": true,
"תפרים": true,
"תפריס": true,
"תפריע": true,
"תפריש": true,
"תפרכם": true,
"תפרכן": true,
"תפרכס": true,
"תפרמו": true,
"תפרמט": true,
"תפרמי": true,
"תפרנה": true,
"תפרנו": true,
"תפרנס": true,
"תפרסו": true,
"תפרסי": true,
"תפרסם": true,
"תפרעו": true,
"תפרעי": true,
"תפרפו": true,
"תפרפי": true,
"תפרפר": true,
"תפרצו": true,
"תפרצי": true,
"תפרקו": true,
"תפרקי": true,
"תפרשו": true,
"תפרשי": true,
"תפרשן": true,
"תפרתי": true,
"תפרתם": true,
"תפרתן": true,
"תפשוט": true,
"תפשטו": true,
"תפשטי": true,
"תפשיט": true,
"תפשיל": true,
"תפשיר": true,
"תפשלו": true,
"תפשלי": true,
"תפשנו": true,
"תפשעו": true,
"תפשעי": true,
"תפשפש": true,
"תפשקו": true,
"תפשקי": true,
"תפשרו": true,
"תפשרי": true,
"תפשתי": true,
"תפשתם": true,
"תפשתן": true,
"תפתור": true,
"תפתות": true,
"תפתחו": true,
"תפתחי": true,
"תפתיע": true,
"תפתלו": true,
"תפתלי": true,
"תפתרו": true,
"תפתרי": true,
"תצאנה": true,
"תצבאו": true,
"תצבאי": true,
"תצבוט": true,
"תצבור": true,
"תצבטו": true,
"תצבטי": true,
"תצביע": true,
"תצבנה": true,
"תצבעו": true,
"תצבעי": true,
"תצברו": true,
"תצברח": true,
"תצברי": true,
"תצגנה": true,
"תצדדו": true,
"תצדדי": true,
"תצדיע": true,
"תצדיק": true,
"תצדקו": true,
"תצדקי": true,
"תצהיב": true,
"תצהיר": true,
"תצהלו": true,
"תצהלי": true,
"תצוגה": true,
"תצוגת": true,
"תצודד": true,
"תצודו": true,
"תצודי": true,
"תצווה": true,
"תצווו": true,
"תצווח": true,
"תצווי": true,
"תצוות": true,
"תצוטט": true,
"תצויד": true,
"תצוין": true,
"תצויר": true,
"תצוית": true,
"תצולם": true,
"תצומד": true,
"תצומו": true,
"תצומי": true,
"תצומק": true,
"תצונן": true,
"תצופה": true,
"תצופו": true,
"תצופי": true,
"תצופף": true,
"תצוצו": true,
"תצוצי": true,
"תצורה": true,
"תצורו": true,
"תצורי": true,
"תצורף": true,
"תצותת": true,
"תצחין": true,
"תצחיק": true,
"תצחנה": true,
"תצחצח": true,
"תצחקו": true,
"תצחקי": true,
"תצחקק": true,
"תצטבע": true,
"תצטבר": true,
"תצטדק": true,
"תצטוו": true,
"תצטטו": true,
"תצטטי": true,
"תצטלב": true,
"תצטלל": true,
"תצטלם": true,
"תצטלק": true,
"תצטמג": true,
"תצטמק": true,
"תצטנן": true,
"תצטנע": true,
"תצטנף": true,
"תצטעף": true,
"תצטער": true,
"תצטפד": true,
"תצטרד": true,
"תצטרך": true,
"תצטרף": true,
"תציבו": true,
"תציבי": true,
"תציגו": true,
"תציגי": true,
"תצייד": true,
"תציין": true,
"תצייץ": true,
"תצייר": true,
"תציית": true,
"תצילו": true,
"תצילי": true,
"תציעו": true,
"תציעי": true,
"תציפו": true,
"תציפי": true,
"תציצו": true,
"תציצי": true,
"תציקו": true,
"תציקי": true,
"תציתו": true,
"תציתי": true,
"תצלבו": true,
"תצלבי": true,
"תצלוב": true,
"תצלול": true,
"תצלום": true,
"תצלוף": true,
"תצלחו": true,
"תצלחי": true,
"תצליב": true,
"תצליח": true,
"תצליל": true,
"תצליף": true,
"תצללו": true,
"תצללי": true,
"תצלמו": true,
"תצלמי": true,
"תצלנה": true,
"תצלעו": true,
"תצלעי": true,
"תצלפו": true,
"תצלפי": true,
"תצלצל": true,
"תצלקו": true,
"תצלקי": true,
"תצמאו": true,
"תצמאי": true,
"תצמדו": true,
"תצמדי": true,
"תצמחו": true,
"תצמחי": true,
"תצמיא": true,
"תצמיד": true,
"תצמיח": true,
"תצמית": true,
"תצמצם": true,
"תצמקו": true,
"תצמקי": true,
"תצמרר": true,
"תצנזר": true,
"תצנחו": true,
"תצנחי": true,
"תצניח": true,
"תצניע": true,
"תצננה": true,
"תצננו": true,
"תצנני": true,
"תצנתר": true,
"תצעדו": true,
"תצעדי": true,
"תצעיד": true,
"תצעיר": true,
"תצענה": true,
"תצעקו": true,
"תצעקי": true,
"תצערו": true,
"תצערי": true,
"תצפון": true,
"תצפור": true,
"תצפין": true,
"תצפית": true,
"תצפנה": true,
"תצפנו": true,
"תצפני": true,
"תצפצף": true,
"תצפרו": true,
"תצפרי": true,
"תצפתה": true,
"תצפתו": true,
"תצפתי": true,
"תצפתם": true,
"תצפתן": true,
"תצצנה": true,
"תצקנה": true,
"תצקצק": true,
"תצרבו": true,
"תצרבי": true,
"תצרוב": true,
"תצרוך": true,
"תצרום": true,
"תצרוף": true,
"תצרור": true,
"תצרחו": true,
"תצרחי": true,
"תצריד": true,
"תצריח": true,
"תצריך": true,
"תצרכו": true,
"תצרכי": true,
"תצרמו": true,
"תצרמי": true,
"תצרנה": true,
"תצרפה": true,
"תצרפו": true,
"תצרפי": true,
"תצרפך": true,
"תצרפם": true,
"תצרפן": true,
"תצרצר": true,
"תצררו": true,
"תצררי": true,
"תצתנה": true,
"תקאנה": true,
"תקבול": true,
"תקבור": true,
"תקביל": true,
"תקבלו": true,
"תקבלי": true,
"תקבעו": true,
"תקבעי": true,
"תקבצו": true,
"תקבצי": true,
"תקברו": true,
"תקברי": true,
"תקדחו": true,
"תקדחי": true,
"תקדיח": true,
"תקדים": true,
"תקדיש": true,
"תקדמו": true,
"תקדמי": true,
"תקדרו": true,
"תקדרי": true,
"תקדשו": true,
"תקדשי": true,
"תקהיל": true,
"תקובע": true,
"תקובץ": true,
"תקודד": true,
"תקודו": true,
"תקודי": true,
"תקודם": true,
"תקודש": true,
"תקווה": true,
"תקווי": true,
"תקוות": true,
"תקוזז": true,
"תקוטב": true,
"תקוטע": true,
"תקוים": true,
"תקולח": true,
"תקולל": true,
"תקולף": true,
"תקומה": true,
"תקומו": true,
"תקומח": true,
"תקומט": true,
"תקומי": true,
"תקומם": true,
"תקומר": true,
"תקונח": true,
"תקונן": true,
"תקועה": true,
"תקופד": true,
"תקופה": true,
"תקופח": true,
"תקופל": true,
"תקופת": true,
"תקוצו": true,
"תקוצי": true,
"תקוצץ": true,
"תקוצר": true,
"תקורב": true,
"תקורה": true,
"תקורץ": true,
"תקורר": true,
"תקושט": true,
"תקושר": true,
"תקושש": true,
"תקזזו": true,
"תקזזי": true,
"תקזנה": true,
"תקטבו": true,
"תקטבי": true,
"תקטול": true,
"תקטום": true,
"תקטוף": true,
"תקטין": true,
"תקטיר": true,
"תקטלג": true,
"תקטלו": true,
"תקטלי": true,
"תקטמו": true,
"תקטמי": true,
"תקטנה": true,
"תקטנו": true,
"תקטני": true,
"תקטעו": true,
"תקטעי": true,
"תקטפו": true,
"תקטפי": true,
"תקטרג": true,
"תקטרו": true,
"תקטרי": true,
"תקיאו": true,
"תקיאי": true,
"תקיזו": true,
"תקיזי": true,
"תקיים": true,
"תקייץ": true,
"תקימו": true,
"תקימי": true,
"תקינה": true,
"תקיעה": true,
"תקיפה": true,
"תקיפו": true,
"תקיפי": true,
"תקיפת": true,
"תקיצו": true,
"תקיצי": true,
"תקישו": true,
"תקישי": true,
"תקלדו": true,
"תקלדי": true,
"תקלוד": true,
"תקלוט": true,
"תקלוף": true,
"תקלות": true,
"תקלחו": true,
"תקלחי": true,
"תקלטה": true,
"תקלטו": true,
"תקלטי": true,
"תקלטר": true,
"תקלטת": true,
"תקליד": true,
"תקליט": true,
"תקליק": true,
"תקליש": true,
"תקללו": true,
"תקללי": true,
"תקלנה": true,
"תקלסו": true,
"תקלסי": true,
"תקלעו": true,
"תקלעי": true,
"תקלפו": true,
"תקלפי": true,
"תקלקל": true,
"תקלתה": true,
"תקלתו": true,
"תקלתי": true,
"תקלתך": true,
"תקלתם": true,
"תקלתן": true,
"תקמול": true,
"תקמוץ": true,
"תקמחו": true,
"תקמחי": true,
"תקמטו": true,
"תקמטי": true,
"תקמלו": true,
"תקמלי": true,
"תקמנה": true,
"תקמצו": true,
"תקמצי": true,
"תקמרו": true,
"תקמרי": true,
"תקנאו": true,
"תקנאי": true,
"תקנון": true,
"תקנוס": true,
"תקנות": true,
"תקנחו": true,
"תקנחי": true,
"תקנטר": true,
"תקניה": true,
"תקניו": true,
"תקניט": true,
"תקניי": true,
"תקניך": true,
"תקנים": true,
"תקנית": true,
"תקנכם": true,
"תקנכן": true,
"תקננה": true,
"תקננו": true,
"תקנני": true,
"תקנסו": true,
"תקנסי": true,
"תקנתה": true,
"תקנתו": true,
"תקנתי": true,
"תקנתך": true,
"תקנתם": true,
"תקנתן": true,
"תקסום": true,
"תקסים": true,
"תקסמו": true,
"תקסמי": true,
"תקעיה": true,
"תקעיו": true,
"תקעיי": true,
"תקעיך": true,
"תקעים": true,
"תקעכם": true,
"תקעכן": true,
"תקענה": true,
"תקענו": true,
"תקעקע": true,
"תקעתי": true,
"תקעתם": true,
"תקעתן": true,
"תקפאו": true,
"תקפאי": true,
"תקפדו": true,
"תקפדי": true,
"תקפוץ": true,
"תקפות": true,
"תקפחו": true,
"תקפחי": true,
"תקפיא": true,
"תקפיד": true,
"תקפים": true,
"תקפיץ": true,
"תקפלו": true,
"תקפלי": true,
"תקפנה": true,
"תקפנו": true,
"תקפצו": true,
"תקפצי": true,
"תקפתי": true,
"תקפתם": true,
"תקפתן": true,
"תקצבה": true,
"תקצבו": true,
"תקצבי": true,
"תקצבת": true,
"תקצוב": true,
"תקצוף": true,
"תקצוץ": true,
"תקצור": true,
"תקציב": true,
"תקצין": true,
"תקציע": true,
"תקציף": true,
"תקציר": true,
"תקצנה": true,
"תקצפו": true,
"תקצפי": true,
"תקצצו": true,
"תקצצי": true,
"תקצרה": true,
"תקצרו": true,
"תקצרי": true,
"תקצרת": true,
"תקראו": true,
"תקראי": true,
"תקרבו": true,
"תקרבי": true,
"תקרום": true,
"תקרון": true,
"תקרוס": true,
"תקרוץ": true,
"תקרות": true,
"תקרטע": true,
"תקריא": true,
"תקריב": true,
"תקריה": true,
"תקריו": true,
"תקריח": true,
"תקריי": true,
"תקריך": true,
"תקרים": true,
"תקרין": true,
"תקריש": true,
"תקרית": true,
"תקרכם": true,
"תקרכן": true,
"תקרמו": true,
"תקרמי": true,
"תקרנה": true,
"תקרנו": true,
"תקרני": true,
"תקרסו": true,
"תקרסי": true,
"תקרעו": true,
"תקרעי": true,
"תקרצו": true,
"תקרצי": true,
"תקרצף": true,
"תקרקע": true,
"תקרקף": true,
"תקרקר": true,
"תקרקש": true,
"תקררו": true,
"תקררי": true,
"תקרתה": true,
"תקרתו": true,
"תקרתי": true,
"תקרתך": true,
"תקרתם": true,
"תקרתן": true,
"תקשוב": true,
"תקשור": true,
"תקשטו": true,
"תקשטי": true,
"תקשיב": true,
"תקשיח": true,
"תקשיר": true,
"תקשנה": true,
"תקשקש": true,
"תקשרה": true,
"תקשרו": true,
"תקשרי": true,
"תקשרת": true,
"תקתוק": true,
"תקתקה": true,
"תקתקו": true,
"תקתקי": true,
"תקתקת": true,
"תרבבו": true,
"תרבבי": true,
"תרבדו": true,
"תרבדי": true,
"תרבוץ": true,
"תרבוש": true,
"תרבות": true,
"תרביע": true,
"תרביץ": true,
"תרבית": true,
"תרבנה": true,
"תרבעו": true,
"תרבעי": true,
"תרבצו": true,
"תרבצי": true,
"תרבתה": true,
"תרבתו": true,
"תרבתי": true,
"תרבתם": true,
"תרבתן": true,
"תרגוז": true,
"תרגול": true,
"תרגום": true,
"תרגון": true,
"תרגוש": true,
"תרגזו": true,
"תרגזי": true,
"תרגיז": true,
"תרגיל": true,
"תרגיע": true,
"תרגיש": true,
"תרגלה": true,
"תרגלו": true,
"תרגלי": true,
"תרגלת": true,
"תרגמה": true,
"תרגמו": true,
"תרגמי": true,
"תרגמת": true,
"תרגנו": true,
"תרגני": true,
"תרגעו": true,
"תרגעי": true,
"תרגשו": true,
"תרגשי": true,
"תרדדו": true,
"תרדדי": true,
"תרדוף": true,
"תרדיה": true,
"תרדיו": true,
"תרדיי": true,
"תרדיך": true,
"תרדים": true,
"תרדכם": true,
"תרדכן": true,
"תרדמה": true,
"תרדמת": true,
"תרדנה": true,
"תרדנו": true,
"תרדפו": true,
"תרדפי": true,
"תרהטו": true,
"תרהטי": true,
"תרהיב": true,
"תרובב": true,
"תרובד": true,
"תרובע": true,
"תרוגש": true,
"תרודד": true,
"תרוהט": true,
"תרווד": true,
"תרווה": true,
"תרווח": true,
"תרווי": true,
"תרוטש": true,
"תרוכז": true,
"תרוכך": true,
"תרומה": true,
"תרומו": true,
"תרומי": true,
"תרומם": true,
"תרומת": true,
"תרונה": true,
"תרונו": true,
"תרוני": true,
"תרוסן": true,
"תרוסס": true,
"תרוסק": true,
"תרועה": true,
"תרופא": true,
"תרופד": true,
"תרופה": true,
"תרופף": true,
"תרופת": true,
"תרוצה": true,
"תרוצו": true,
"תרוצי": true,
"תרוצף": true,
"תרוצץ": true,
"תרוקן": true,
"תרוקע": true,
"תרושש": true,
"תרושת": true,
"תרותך": true,
"תרותק": true,
"תרחיב": true,
"תרחיף": true,
"תרחיק": true,
"תרחיש": true,
"תרחמו": true,
"תרחמי": true,
"תרחנה": true,
"תרחפו": true,
"תרחפי": true,
"תרחצו": true,
"תרחצי": true,
"תרחקו": true,
"תרחקי": true,
"תרחרח": true,
"תרחשו": true,
"תרחשי": true,
"תרטוט": true,
"תרטון": true,
"תרטטו": true,
"תרטטי": true,
"תרטיב": true,
"תרטיט": true,
"תרטנו": true,
"תרטני": true,
"תרטשו": true,
"תרטשי": true,
"תריבו": true,
"תריבי": true,
"תריחו": true,
"תריחי": true,
"תרימו": true,
"תרימי": true,
"תריסה": true,
"תריסו": true,
"תריסי": true,
"תריסך": true,
"תריסם": true,
"תריסן": true,
"תריסר": true,
"תריעו": true,
"תריעי": true,
"תריצו": true,
"תריצי": true,
"תריקו": true,
"תריקי": true,
"תרכבו": true,
"תרכבי": true,
"תרכון": true,
"תרכוס": true,
"תרכוש": true,
"תרכזו": true,
"תרכזי": true,
"תרכיב": true,
"תרכיז": true,
"תרכין": true,
"תרככו": true,
"תרככי": true,
"תרכלו": true,
"תרכלי": true,
"תרכנה": true,
"תרכנו": true,
"תרכני": true,
"תרכסו": true,
"תרכסי": true,
"תרכשו": true,
"תרכשי": true,
"תרמוז": true,
"תרמוס": true,
"תרמזו": true,
"תרמזי": true,
"תרמזר": true,
"תרמיל": true,
"תרמית": true,
"תרמנה": true,
"תרמנו": true,
"תרמסו": true,
"תרמסי": true,
"תרמתי": true,
"תרמתם": true,
"תרמתן": true,
"תרניה": true,
"תרניו": true,
"תרניי": true,
"תרניך": true,
"תרנים": true,
"תרנין": true,
"תרננה": true,
"תרננו": true,
"תרנני": true,
"תרסיס": true,
"תרסנה": true,
"תרסנו": true,
"תרסני": true,
"תרססו": true,
"תרססי": true,
"תרסקו": true,
"תרסקי": true,
"תרעבו": true,
"תרעבי": true,
"תרעדו": true,
"תרעדי": true,
"תרעיב": true,
"תרעיד": true,
"תרעיל": true,
"תרעים": true,
"תרעיף": true,
"תרעיש": true,
"תרעלה": true,
"תרעמו": true,
"תרעמי": true,
"תרענה": true,
"תרענן": true,
"תרעשו": true,
"תרעשי": true,
"תרפאו": true,
"תרפאי": true,
"תרפדו": true,
"תרפדי": true,
"תרפיה": true,
"תרפרף": true,
"תרצדו": true,
"תרצדי": true,
"תרצוץ": true,
"תרצחו": true,
"תרצחי": true,
"תרצין": true,
"תרצנה": true,
"תרצעו": true,
"תרצעי": true,
"תרצפו": true,
"תרצפי": true,
"תרצצו": true,
"תרצצי": true,
"תרקבו": true,
"תרקבי": true,
"תרקדו": true,
"תרקדי": true,
"תרקוד": true,
"תרקום": true,
"תרקוק": true,
"תרקחו": true,
"תרקחי": true,
"תרקיב": true,
"תרקיד": true,
"תרקיע": true,
"תרקמו": true,
"תרקמי": true,
"תרקנה": true,
"תרקעו": true,
"תרקעי": true,
"תרקקו": true,
"תרקקי": true,
"תרשום": true,
"תרשוף": true,
"תרשים": true,
"תרשיע": true,
"תרשיש": true,
"תרשמו": true,
"תרשמי": true,
"תרשפו": true,
"תרשפי": true,
"תרשרש": true,
"תרשתו": true,
"תרשתי": true,
"תרתום": true,
"תרתחו": true,
"תרתחי": true,
"תרתיח": true,
"תרתיע": true,
"תרתכו": true,
"תרתכי": true,
"תרתמו": true,
"תרתמי": true,
"תרתעו": true,
"תרתעי": true,
"תרתקו": true,
"תרתקי": true,
"תשאבו": true,
"תשאבי": true,
"תשאגו": true,
"תשאגי": true,
"תשאול": true,
"תשאיל": true,
"תשאיף": true,
"תשאיר": true,
"תשאלה": true,
"תשאלו": true,
"תשאלי": true,
"תשאלת": true,
"תשאנה": true,
"תשאפו": true,
"תשאפי": true,
"תשבוק": true,
"תשבור": true,
"תשבות": true,
"תשבחה": true,
"תשבחו": true,
"תשבחי": true,
"תשבטו": true,
"תשבטי": true,
"תשביח": true,
"תשביע": true,
"תשביר": true,
"תשבית": true,
"תשבנה": true,
"תשבעו": true,
"תשבעי": true,
"תשבצה": true,
"תשבצו": true,
"תשבצי": true,
"תשבצך": true,
"תשבצם": true,
"תשבצן": true,
"תשבקו": true,
"תשבקי": true,
"תשברו": true,
"תשברי": true,
"תשבשו": true,
"תשבשי": true,
"תשבתו": true,
"תשבתי": true,
"תשגגו": true,
"תשגגי": true,
"תשגוג": true,
"תשגיח": true,
"תשגנה": true,
"תשגעו": true,
"תשגעי": true,
"תשגרו": true,
"תשגרי": true,
"תשגשג": true,
"תשדדו": true,
"תשדדי": true,
"תשדוד": true,
"תשדיר": true,
"תשדכו": true,
"תשדכי": true,
"תשדלו": true,
"תשדלי": true,
"תשדרג": true,
"תשדרו": true,
"תשדרי": true,
"תשהקו": true,
"תשהקי": true,
"תשואה": true,
"תשובב": true,
"תשובה": true,
"תשובו": true,
"תשובח": true,
"תשובט": true,
"תשובי": true,
"תשובץ": true,
"תשובר": true,
"תשובש": true,
"תשובת": true,
"תשוגע": true,
"תשוגר": true,
"תשודד": true,
"תשודך": true,
"תשודל": true,
"תשודר": true,
"תשווה": true,
"תשווי": true,
"תשווע": true,
"תשווק": true,
"תשוחד": true,
"תשוחו": true,
"תשוחח": true,
"תשוחי": true,
"תשוחק": true,
"תשוטו": true,
"תשוטט": true,
"תשוטי": true,
"תשויך": true,
"תשויף": true,
"תשוכך": true,
"תשוכל": true,
"תשוכן": true,
"תשולב": true,
"תשולו": true,
"תשולח": true,
"תשולם": true,
"תשולש": true,
"תשומה": true,
"תשומן": true,
"תשומר": true,
"תשומת": true,
"תשונה": true,
"תשונו": true,
"תשוני": true,
"תשונן": true,
"תשונע": true,
"תשוסה": true,
"תשוסו": true,
"תשוסי": true,
"תשוסע": true,
"תשוסף": true,
"תשועה": true,
"תשוער": true,
"תשועת": true,
"תשופד": true,
"תשופה": true,
"תשופו": true,
"תשופי": true,
"תשופע": true,
"תשופץ": true,
"תשופר": true,
"תשוקה": true,
"תשוקם": true,
"תשוקע": true,
"תשוקף": true,
"תשוקץ": true,
"תשורה": true,
"תשורש": true,
"תשושה": true,
"תשושו": true,
"תשושי": true,
"תשותי": true,
"תשותך": true,
"תשותם": true,
"תשותן": true,
"תשותף": true,
"תשותק": true,
"תשזוף": true,
"תשזור": true,
"תשזפו": true,
"תשזפי": true,
"תשזרו": true,
"תשזרי": true,
"תשחבר": true,
"תשחדו": true,
"תשחדי": true,
"תשחזר": true,
"תשחטו": true,
"תשחטי": true,
"תשחיז": true,
"תשחיל": true,
"תשחים": true,
"תשחיר": true,
"תשחית": true,
"תשחלף": true,
"תשחנה": true,
"תשחצה": true,
"תשחצו": true,
"תשחצי": true,
"תשחצך": true,
"תשחצם": true,
"תשחצן": true,
"תשחקו": true,
"תשחקי": true,
"תשחרו": true,
"תשחרי": true,
"תשחרר": true,
"תשחתו": true,
"תשחתי": true,
"תשטום": true,
"תשטוף": true,
"תשטחו": true,
"תשטחי": true,
"תשטמו": true,
"תשטמי": true,
"תשטנה": true,
"תשטפו": true,
"תשטפי": true,
"תשיאו": true,
"תשיאי": true,
"תשיבו": true,
"תשיבי": true,
"תשיגו": true,
"תשיגי": true,
"תשיחו": true,
"תשיחי": true,
"תשיטו": true,
"תשיטי": true,
"תשייט": true,
"תשייך": true,
"תשייף": true,
"תשילו": true,
"תשילי": true,
"תשימו": true,
"תשימי": true,
"תשיעי": true,
"תשיקו": true,
"תשיקי": true,
"תשירו": true,
"תשירי": true,
"תשישו": true,
"תשישי": true,
"תשיתו": true,
"תשיתי": true,
"תשכבו": true,
"תשכבי": true,
"תשכול": true,
"תשכון": true,
"תשכור": true,
"תשכחו": true,
"תשכחי": true,
"תשכיב": true,
"תשכיח": true,
"תשכיל": true,
"תשכים": true,
"תשכין": true,
"תשכיר": true,
"תשככו": true,
"תשככי": true,
"תשכלו": true,
"תשכלי": true,
"תשכלל": true,
"תשכנה": true,
"תשכנו": true,
"תשכני": true,
"תשכנע": true,
"תשכפל": true,
"תשכרו": true,
"תשכרי": true,
"תשכשך": true,
"תשכתב": true,
"תשלבו": true,
"תשלבי": true,
"תשלהב": true,
"תשלוט": true,
"תשלול": true,
"תשלום": true,
"תשלוף": true,
"תשלוק": true,
"תשלחו": true,
"תשלחי": true,
"תשלטו": true,
"תשלטט": true,
"תשלטי": true,
"תשליט": true,
"תשליך": true,
"תשליל": true,
"תשלים": true,
"תשללו": true,
"תשללי": true,
"תשלמו": true,
"תשלמי": true,
"תשלנה": true,
"תשלפו": true,
"תשלפי": true,
"תשלקו": true,
"תשלקי": true,
"תשלשו": true,
"תשלשי": true,
"תשלשל": true,
"תשמוט": true,
"תשמור": true,
"תשמחו": true,
"תשמחי": true,
"תשמטו": true,
"תשמטי": true,
"תשמיד": true,
"תשמיט": true,
"תשמין": true,
"תשמיע": true,
"תשמיץ": true,
"תשמיש": true,
"תשממו": true,
"תשממי": true,
"תשמנה": true,
"תשמנו": true,
"תשמני": true,
"תשמעו": true,
"תשמעי": true,
"תשמרו": true,
"תשמרי": true,
"תשמשו": true,
"תשמשי": true,
"תשנאו": true,
"תשנאי": true,
"תשניא": true,
"תשננה": true,
"תשננו": true,
"תשנני": true,
"תשנסו": true,
"תשנסי": true,
"תשנעו": true,
"תשנעי": true,
"תשסעו": true,
"תשסעי": true,
"תשספו": true,
"תשספי": true,
"תשעבד": true,
"תשעטו": true,
"תשעטי": true,
"תשעים": true,
"תשעין": true,
"תשעמם": true,
"תשענה": true,
"תשערו": true,
"תשערי": true,
"תשערך": true,
"תשעשע": true,
"תשעתם": true,
"תשעתן": true,
"תשעתק": true,
"תשפדו": true,
"תשפדי": true,
"תשפוט": true,
"תשפוך": true,
"תשפול": true,
"תשפות": true,
"תשפטו": true,
"תשפטי": true,
"תשפיל": true,
"תשפיע": true,
"תשפכו": true,
"תשפכי": true,
"תשפלו": true,
"תשפלי": true,
"תשפעו": true,
"תשפעי": true,
"תשפעל": true,
"תשפצו": true,
"תשפצי": true,
"תשפצר": true,
"תשפרו": true,
"תשפרי": true,
"תשפשף": true,
"תשפתו": true,
"תשפתי": true,
"תשצוף": true,
"תשצפו": true,
"תשצפי": true,
"תשקדו": true,
"תשקדי": true,
"תשקוד": true,
"תשקוט": true,
"תשקול": true,
"תשקטו": true,
"תשקטי": true,
"תשקיט": true,
"תשקיע": true,
"תשקיף": true,
"תשקלו": true,
"תשקלי": true,
"תשקלל": true,
"תשקמו": true,
"תשקמי": true,
"תשקנה": true,
"תשקעו": true,
"תשקעי": true,
"תשקפו": true,
"תשקפי": true,
"תשקצו": true,
"תשקצי": true,
"תשקקו": true,
"תשקקי": true,
"תשקרו": true,
"תשקרי": true,
"תשקשק": true,
"תשרבב": true,
"תשרבט": true,
"תשרדו": true,
"תשרדי": true,
"תשרוד": true,
"תשרוט": true,
"תשרוך": true,
"תשרוף": true,
"תשרוץ": true,
"תשרוק": true,
"תשרור": true,
"תשרטו": true,
"תשרטט": true,
"תשרטי": true,
"תשריה": true,
"תשריו": true,
"תשריי": true,
"תשריך": true,
"תשרים": true,
"תשריץ": true,
"תשריש": true,
"תשרכו": true,
"תשרכי": true,
"תשרכם": true,
"תשרכן": true,
"תשרנה": true,
"תשרנו": true,
"תשרפו": true,
"תשרפי": true,
"תשרצו": true,
"תשרצי": true,
"תשרקו": true,
"תשרקי": true,
"תשררו": true,
"תשררי": true,
"תשרשו": true,
"תשרשי": true,
"תשרשר": true,
"תשרתו": true,
"תשרתי": true,
"תששנה": true,
"תשתאה": true,
"תשתאו": true,
"תשתאי": true,
"תשתבח": true,
"תשתבט": true,
"תשתבץ": true,
"תשתבר": true,
"תשתבש": true,
"תשתגע": true,
"תשתדך": true,
"תשתדל": true,
"תשתהה": true,
"תשתהו": true,
"תשתהי": true,
"תשתוו": true,
"תשתול": true,
"תשתוק": true,
"תשתות": true,
"תשתזף": true,
"תשתחל": true,
"תשתחץ": true,
"תשתטה": true,
"תשתטו": true,
"תשתטח": true,
"תשתטי": true,
"תשתיל": true,
"תשתין": true,
"תשתיק": true,
"תשתית": true,
"תשתכו": true,
"תשתכח": true,
"תשתכי": true,
"תשתכן": true,
"תשתכר": true,
"תשתלב": true,
"תשתלו": true,
"תשתלח": true,
"תשתלט": true,
"תשתלי": true,
"תשתלם": true,
"תשתמד": true,
"תשתמט": true,
"תשתמע": true,
"תשתמר": true,
"תשתמש": true,
"תשתנה": true,
"תשתנו": true,
"תשתני": true,
"תשתנק": true,
"תשתעל": true,
"תשתפד": true,
"תשתפו": true,
"תשתפי": true,
"תשתפך": true,
"תשתפל": true,
"תשתפן": true,
"תשתפר": true,
"תשתקו": true,
"תשתקי": true,
"תשתקם": true,
"תשתקע": true,
"תשתקף": true,
"תשתרג": true,
"תשתרך": true,
"תשתרע": true,
"תשתרר": true,
"תשתרש": true,
"תשתתה": true,
"תשתתו": true,
"תשתתי": true,
"תשתתף": true,
"תשתתק": true,
"תתאבד": true,
"תתאבך": true,
"תתאבל": true,
"תתאבן": true,
"תתאבק": true,
"תתאגד": true,
"תתאדה": true,
"תתאדו": true,
"תתאדי": true,
"תתאהב": true,
"תתאוו": true,
"תתאזן": true,
"תתאזר": true,
"תתאחד": true,
"תתאחה": true,
"תתאחו": true,
"תתאחי": true,
"תתאחר": true,
"תתאים": true,
"תתאכל": true,
"תתאמו": true,
"תתאמי": true,
"תתאמן": true,
"תתאמץ": true,
"תתאמת": true,
"תתאסף": true,
"תתאפס": true,
"תתאפק": true,
"תתאפר": true,
"תתארו": true,
"תתארח": true,
"תתארי": true,
"תתארך": true,
"תתארס": true,
"תתאשר": true,
"תתבאס": true,
"תתבאר": true,
"תתבגר": true,
"תתבדה": true,
"תתבדו": true,
"תתבדח": true,
"תתבדי": true,
"תתבדל": true,
"תתבדר": true,
"תתבהם": true,
"תתבהר": true,
"תתבזה": true,
"תתבזו": true,
"תתבזי": true,
"תתבטא": true,
"תתבטל": true,
"תתבלה": true,
"תתבלו": true,
"תתבלט": true,
"תתבלי": true,
"תתבסם": true,
"תתבסס": true,
"תתבעו": true,
"תתבעי": true,
"תתבצע": true,
"תתבצר": true,
"תתבקע": true,
"תתבקש": true,
"תתברג": true,
"תתברך": true,
"תתברר": true,
"תתבשל": true,
"תתבשם": true,
"תתבשר": true,
"תתגאה": true,
"תתגאו": true,
"תתגאי": true,
"תתגבה": true,
"תתגבו": true,
"תתגבי": true,
"תתגבר": true,
"תתגבש": true,
"תתגדר": true,
"תתגלה": true,
"תתגלו": true,
"תתגלח": true,
"תתגלי": true,
"תתגלם": true,
"תתגלע": true,
"תתגלש": true,
"תתגמד": true,
"תתגמל": true,
"תתגמש": true,
"תתגנב": true,
"תתגפף": true,
"תתגרד": true,
"תתגרה": true,
"תתגרו": true,
"תתגרי": true,
"תתגרש": true,
"תתגשם": true,
"תתדלק": true,
"תתדמה": true,
"תתדמו": true,
"תתדמי": true,
"תתדפק": true,
"תתדרך": true,
"תתהדק": true,
"תתהדר": true,
"תתהוו": true,
"תתהלך": true,
"תתהלל": true,
"תתהפך": true,
"תתואם": true,
"תתואר": true,
"תתובל": true,
"תתווה": true,
"תתווי": true,
"תתווך": true,
"תתוזז": true,
"תתוחח": true,
"תתוחם": true,
"תתויג": true,
"תתויק": true,
"תתויר": true,
"תתוכן": true,
"תתועד": true,
"תתועל": true,
"תתועש": true,
"תתופף": true,
"תתוקל": true,
"תתוקן": true,
"תתורו": true,
"תתורי": true,
"תתורץ": true,
"תתזזו": true,
"תתזזי": true,
"תתזמן": true,
"תתזמר": true,
"תתזנה": true,
"תתחבא": true,
"תתחבב": true,
"תתחבו": true,
"תתחבט": true,
"תתחבי": true,
"תתחבל": true,
"תתחבק": true,
"תתחבר": true,
"תתחדד": true,
"תתחדש": true,
"תתחום": true,
"תתחזה": true,
"תתחזו": true,
"תתחזי": true,
"תתחזק": true,
"תתחחו": true,
"תתחחי": true,
"תתחיל": true,
"תתחכך": true,
"תתחכם": true,
"תתחלה": true,
"תתחלו": true,
"תתחלי": true,
"תתחלף": true,
"תתחלק": true,
"תתחמו": true,
"תתחמי": true,
"תתחמם": true,
"תתחמן": true,
"תתחמק": true,
"תתחמש": true,
"תתחנך": true,
"תתחנן": true,
"תתחנף": true,
"תתחסד": true,
"תתחסל": true,
"תתחסן": true,
"תתחפף": true,
"תתחפר": true,
"תתחפש": true,
"תתחצף": true,
"תתחקה": true,
"תתחקו": true,
"תתחקי": true,
"תתחקר": true,
"תתחרד": true,
"תתחרה": true,
"תתחרו": true,
"תתחרז": true,
"תתחרט": true,
"תתחרי": true,
"תתחרש": true,
"תתחשב": true,
"תתחשל": true,
"תתחשק": true,
"תתחתן": true,
"תתחתר": true,
"תתיזו": true,
"תתיזי": true,
"תתייג": true,
"תתייק": true,
"תתייר": true,
"תתיכו": true,
"תתיכי": true,
"תתירו": true,
"תתירי": true,
"תתישו": true,
"תתישי": true,
"תתכבד": true,
"תתכבס": true,
"תתכדר": true,
"תתכהה": true,
"תתכהו": true,
"תתכהי": true,
"תתכחש": true,
"תתכלה": true,
"תתכלו": true,
"תתכלי": true,
"תתכנה": true,
"תתכנו": true,
"תתכני": true,
"תתכנן": true,
"תתכנס": true,
"תתכנת": true,
"תתכסה": true,
"תתכסו": true,
"תתכסח": true,
"תתכסי": true,
"תתכער": true,
"תתכפר": true,
"תתכתב": true,
"תתכתש": true,
"תתלבט": true,
"תתלבן": true,
"תתלבש": true,
"תתלהב": true,
"תתלהט": true,
"תתלהם": true,
"תתלוו": true,
"תתלוש": true,
"תתלחש": true,
"תתלטף": true,
"תתלטש": true,
"תתלכד": true,
"תתלמד": true,
"תתלפף": true,
"תתלקח": true,
"תתלקק": true,
"תתלשו": true,
"תתלשי": true,
"תתלתל": true,
"תתמגן": true,
"תתמהו": true,
"תתמהי": true,
"תתמוך": true,
"תתמזג": true,
"תתמזל": true,
"תתמחה": true,
"תתמחו": true,
"תתמחי": true,
"תתמחר": true,
"תתמיד": true,
"תתמיה": true,
"תתמיר": true,
"תתמכו": true,
"תתמכי": true,
"תתמכן": true,
"תתמכר": true,
"תתמלא": true,
"תתמלל": true,
"תתממש": true,
"תתמנה": true,
"תתמנו": true,
"תתמני": true,
"תתמנף": true,
"תתמסד": true,
"תתמסה": true,
"תתמסו": true,
"תתמסי": true,
"תתמסר": true,
"תתמעט": true,
"תתמצא": true,
"תתמצה": true,
"תתמצו": true,
"תתמצי": true,
"תתמצק": true,
"תתמצת": true,
"תתמקד": true,
"תתמקח": true,
"תתמקם": true,
"תתמרד": true,
"תתמרח": true,
"תתמרן": true,
"תתמרץ": true,
"תתמרק": true,
"תתמרר": true,
"תתמשך": true,
"תתמתח": true,
"תתמתן": true,
"תתנאה": true,
"תתנאו": true,
"תתנאי": true,
"תתנבא": true,
"תתנגב": true,
"תתנגד": true,
"תתנגח": true,
"תתנגן": true,
"תתנגש": true,
"תתנדב": true,
"תתנדף": true,
"תתנהג": true,
"תתנהל": true,
"תתנזל": true,
"תתנזר": true,
"תתנחל": true,
"תתנחם": true,
"תתניע": true,
"תתנכל": true,
"תתנכר": true,
"תתנסה": true,
"תתנסו": true,
"תתנסח": true,
"תתנסי": true,
"תתנער": true,
"תתנפח": true,
"תתנפל": true,
"תתנפץ": true,
"תתנצח": true,
"תתנצל": true,
"תתנצר": true,
"תתנקה": true,
"תתנקו": true,
"תתנקז": true,
"תתנקי": true,
"תתנקם": true,
"תתנקש": true,
"תתנשא": true,
"תתנשם": true,
"תתנשף": true,
"תתנשק": true,
"תתנתב": true,
"תתנתק": true,
"תתסוס": true,
"תתסיס": true,
"תתסכל": true,
"תתססו": true,
"תתססי": true,
"תתסרט": true,
"תתעבה": true,
"תתעבו": true,
"תתעבי": true,
"תתעבר": true,
"תתעגל": true,
"תתעדו": true,
"תתעדי": true,
"תתעדן": true,
"תתעדף": true,
"תתעטף": true,
"תתעטש": true,
"תתעכב": true,
"תתעכל": true,
"תתעלה": true,
"תתעלו": true,
"תתעלי": true,
"תתעלל": true,
"תתעלם": true,
"תתעלס": true,
"תתעלף": true,
"תתעלק": true,
"תתעמל": true,
"תתעמם": true,
"תתעמק": true,
"תתעמר": true,
"תתעמת": true,
"תתענג": true,
"תתענה": true,
"תתענו": true,
"תתעני": true,
"תתענן": true,
"תתעסק": true,
"תתעפש": true,
"תתעצב": true,
"תתעצל": true,
"תתעצם": true,
"תתעקל": true,
"תתעקם": true,
"תתעקר": true,
"תתעקש": true,
"תתערב": true,
"תתערה": true,
"תתערו": true,
"תתערי": true,
"תתעשו": true,
"תתעשי": true,
"תתעשר": true,
"תתעשת": true,
"תתעתד": true,
"תתעתע": true,
"תתעתק": true,
"תתפאר": true,
"תתפגל": true,
"תתפגר": true,
"תתפוס": true,
"תתפור": true,
"תתפוש": true,
"תתפזם": true,
"תתפזר": true,
"תתפחו": true,
"תתפחי": true,
"תתפחם": true,
"תתפחס": true,
"תתפטם": true,
"תתפטר": true,
"תתפיח": true,
"תתפיל": true,
"תתפים": true,
"תתפכח": true,
"תתפלא": true,
"תתפלג": true,
"תתפלח": true,
"תתפלל": true,
"תתפלץ": true,
"תתפלש": true,
"תתפנה": true,
"תתפנו": true,
"תתפני": true,
"תתפנק": true,
"תתפסו": true,
"תתפסי": true,
"תתפעל": true,
"תתפעם": true,
"תתפצח": true,
"תתפצל": true,
"תתפקד": true,
"תתפקע": true,
"תתפקר": true,
"תתפרו": true,
"תתפרי": true,
"תתפרס": true,
"תתפרע": true,
"תתפרץ": true,
"תתפרק": true,
"תתפרש": true,
"תתפשו": true,
"תתפשט": true,
"תתפשי": true,
"תתפשל": true,
"תתפשר": true,
"תתפתה": true,
"תתפתו": true,
"תתפתח": true,
"תתפתי": true,
"תתפתל": true,
"תתצפת": true,
"תתקבל": true,
"תתקבע": true,
"תתקבץ": true,
"תתקדם": true,
"תתקדר": true,
"תתקדש": true,
"תתקהל": true,
"תתקוף": true,
"תתקזז": true,
"תתקיל": true,
"תתקין": true,
"תתקיף": true,
"תתקלו": true,
"תתקלח": true,
"תתקלט": true,
"תתקלי": true,
"תתקלס": true,
"תתקלף": true,
"תתקמט": true,
"תתקמר": true,
"תתקנא": true,
"תתקנה": true,
"תתקנו": true,
"תתקני": true,
"תתקעו": true,
"תתקעי": true,
"תתקפד": true,
"תתקפו": true,
"תתקפי": true,
"תתקפל": true,
"תתקצב": true,
"תתקצץ": true,
"תתקצר": true,
"תתקרא": true,
"תתקרב": true,
"תתקרח": true,
"תתקרר": true,
"תתקרש": true,
"תתקשה": true,
"תתקשו": true,
"תתקשח": true,
"תתקשט": true,
"תתקשי": true,
"תתקשר": true,
"תתקתק": true,
"תתראה": true,
"תתראו": true,
"תתראי": true,
"תתרבד": true,
"תתרבה": true,
"תתרבו": true,
"תתרבי": true,
"תתרבת": true,
"תתרגז": true,
"תתרגל": true,
"תתרגם": true,
"תתרגע": true,
"תתרגש": true,
"תתרדד": true,
"תתרהב": true,
"תתרום": true,
"תתרחב": true,
"תתרחץ": true,
"תתרחק": true,
"תתרחש": true,
"תתרטב": true,
"תתרים": true,
"תתריס": true,
"תתריע": true,
"תתרכב": true,
"תתרכז": true,
"תתרכך": true,
"תתרמו": true,
"תתרמי": true,
"תתרנה": true,
"תתרסן": true,
"תתרסק": true,
"תתרעם": true,
"תתרפא": true,
"תתרפה": true,
"תתרפו": true,
"תתרפט": true,
"תתרפי": true,
"תתרפס": true,
"תתרפק": true,
"תתרצה": true,
"תתרצו": true,
"תתרצי": true,
"תתרקם": true,
"תתרשל": true,
"תתרשם": true,
"תתרתח": true,
"תתשאל": true,
"תתשנה": true,
}

export default words